<template>
  <div class="asset-variables">
    <div
      v-for="[fileType, values] of Object.entries(filteredVariables)"
      :key="`variables-${fileType}`"
      class="variables-table"
    >
      <h5 class="title is-5" :data-cy-test="`${fileType}-variable-title`">{{ variableTypeTitle(fileType) }} Variables</h5>
      <table class="table is-bordered is-hoverable is-fullwidth is-narrow" :data-cy-test="`${fileType}-variable-table`">
        <thead>
          <tr>
            <th colspan="2"><span class="heading" style="margin-bottom: 0;">Variables</span></th>
          </tr>
        </thead>
        <tr
          v-for="[varName, varValue] of Object.entries(values)"
          :key="varName"
          :data-cy-test="`${fileType}-variable-${varName}`"
        >
          <th :data-cy-test="`${fileType}-variable-${varName}-name`">{{ varName }}</th>
          <td :data-cy-test="`${fileType}-variable-${varName}-value`">
            {{ varValue }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VariableTable',
  props: {
    variables: {
      type: Object,
      required: false
    },
    conditionals: {
      type: Object,
      required: false
    }
  },
  computed: {
    filteredVariables() {
      let allOptionalSectionVariables = [];
      let filtered = {};


      for (const fileType in this.conditionals) {
        if (!this.conditionals) return;

        for (const section of Object.values(this.conditionals[fileType])) {
          allOptionalSectionVariables = allOptionalSectionVariables.concat(section.variablesUsed);
        }
      }

      const uniqueSectionVariables = [ ...new Set(allOptionalSectionVariables) ];

      for (const fileType in this.variables) {
        const filteredVariables = Object.entries(this.variables[fileType]).reduce((acc, [varName, varValue]) => {
          if (!uniqueSectionVariables.includes(varName)) {
            acc[fileType] = {
              ...acc[fileType],
              [varName]: varValue
            };
          }
          return acc;
        }, {});

        filtered = {
          ...filtered,
          ...filteredVariables
        };
      }
      return filtered;
    }
  },
  methods: {
    variableTypeTitle(fileType) {
      if (fileType === 'metadata') {
          return 'JSON';
      }

      return fileType.toUpperCase();
    }
  }
};
</script>

<style scoped lang="scss">
  .asset-variables {
    display: flex;
    column-gap: 24px;
    columns: 2;
  }
  .variables-table {
    flex: 0 1 50%;
    align-items: flex-start;
    max-width: calc(50% - 12px);
  }
</style>
