<template>
  <b-collapse
    class="card is-expandable experience has-card-box"
    :open="isExpanded"
    :class="{'experience--control': experience.isControl}"
  >
    <div
      slot="trigger"
      class="card-header level"
    >
      <div class="level-left">
        <div class="level-item">
          <p class="card-header-title is-relative no-padding">
            <span class="card-header-box">
              {{ experience.experienceNumber }}
            </span>

            <span>
              {{ (experience.name) ? experience.name : `Experience #${experience.experienceNumber}` }}
            </span>
          </p>
        </div>
      </div><!-- /.level-left -->

      <div
        class="level-right"
        style="align-items: center;"
      >
        <div
          v-if="isEditing"
          class="level-item"
        >
          <NumberInput
            :min="0"
            :max="100"
            :points="points"
            :initial="initialPoints"
            :is-static="false"
            :auto="false"
            @number-change="handleWeightChange"
          ></NumberInput>
        </div>

        <div
          v-if="isEditing && showControl"
          class="level-item control-checkbox"
        >
          <span>Control &nbsp;</span>

          <b-checkbox
            v-model="experience.isControl"
            type="is-info"
            @input="handleControlChange"
          ></b-checkbox>
        </div>

        <div
          v-if="isEditing && showTrashIcon"
          class="level-item"
        >
          <button
            :disabled="!isDeletable"
            class="button"
            @click.stop="handleRemoveExperience"
          >
            <span class="icon has-text-danger">
              <i class="fa fa-trash"></i>
            </span>
          </button>
        </div><!-- /.level-item -->

        <a class="card-header-icon">
          <span
            class="icon"
            :class="{ 'is-rotated': isExpanded }"
          >
            <i class="fa fa-angle-down"></i>
          </span>
        </a>
      </div><!-- /.level-right -->
    </div><!-- /.card-header -->

    <div class="card-content">
      <table
        v-if="experience !== undefined"
        class="table is-hoverable is-fullwidth"
      >
        <thead>
          <tr>
            <th>Placement</th>
            <th>Asset</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(group, index) in experience.assetPlacements"
            :key="index"
          >
            <td>
              <router-link
                v-if="!isEditing"
                :to="`/placements/${group.placement ? group.placement.placementId : group.placementId}`"
              >
                {{ group.placement ? group.placement.name : group.placementId }}
              </router-link>
              <span v-else>
                {{ group.placement ? group.placement.name : group.placementId }}
              </span>
            </td>

            <td>
              <span>
                {{ group.asset ? group.asset.name : group.assetId }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div><!-- /.card-content -->
  </b-collapse>
</template>

<script>
import has from 'lodash.has';
import NumberInput from '@/components/NumberInput';

export default {
  name: 'ExperienceAdjust',
  components: {
    NumberInput
  },
  props: {
    initialExperience: {
      type: Object,
      required: true
    },
    maxWeightIncrease: {
      type: Number
    },
    points: {
      type: Number
    },
    isDeletable: {
      type: Boolean,
      required: true,
      default: false
    },
    isEditing: {
      type: Boolean,
      required: true
    },
    showControl: {
      type: Boolean,
      default() {
        return true;
      }
    },
    showTrashIcon: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  data() {
    return {
      isExpanded: false,
      experience: this.initialExperience
    };
  },
  computed: {
    siteId() {
      return this.$store.state.siteId;
    },
    hasAssetPlacementData() {
      return has(this.initialExperience, 'assetPlacements[0].placement.name');
    },
    initialPoints() {
      return parseInt(this.experience.weight, 10);
    }
  },
  watch: {
    initialExperience() {
      if (this.experience.experienceId !== this.initialExperience.experienceId) {
        this.experience = this.initialExperience;
      }
    }
  },
  created() {
    if (!this.hasAssetPlacementData && !this.isEditing && this.siteId) {
      this.getExperienceData();
    }
  },
  methods: {
    handleWeightChange(val) {
      this.experience.weight = val;
      this.$emit('weight-change', this.experience.weight);
      this.$emit('experience-change', this.experience);
    },
    handleControlChange() {
      this.$emit('experience-change', this.experience);
    },
    handleRemoveExperience() {
      this.$emit('remove-experience', this.experience);
    },
    async getExperienceData() {
      try {
        const res = await this.$axios.get(`/sites/${this.siteId}/experiences/${this.initialExperience.experienceId}`);
        this.experience = res.data.experience;
      } catch (error) {
        error.title = 'There was a problem retrieving experience data';
        this.$store.commit('error', error);
      }
    }
  }
};
</script>
