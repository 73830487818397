import moment from 'moment-timezone';

// Globally register all filters for convenience.
export default {
  install(Vue) {
    const filters = {
      filterArchiveDateTime: function(date) {
        return moment(date).format('Do, MMM YYYY h:mm a');
      },
      filterCapitalize: function (str) {
        if (typeof str !== 'string') {
          console.error(`Vue.filter('capitalize'): expected string but received`, str);
          return '';
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
      },
      filterLocalTimeString: function (date) {
        const utc = moment.utc(date).toDate();
        return moment(utc).local().format('YYYY-MM-DD HH:mm:ss');
      },
      filterSecondsToReadableTime: function (seconds) {
        const MINUTES_IN_SECONDS = 60;
        const HOURS_IN_SECONDS = MINUTES_IN_SECONDS * 60;
        const DAYS_IN_SECONDS = HOURS_IN_SECONDS * 24;
        const WEEKS_IN_SECONDS = DAYS_IN_SECONDS * 7;
        const CONVERSIONS = {
          seconds: 1,
          minutes: MINUTES_IN_SECONDS,
          hours: HOURS_IN_SECONDS,
          days: DAYS_IN_SECONDS,
          weeks: WEEKS_IN_SECONDS
        };
        const segments = [];
        let s = seconds;
        for (const measurement of ['weeks', 'days', 'hours', 'minutes', 'seconds']) {
          const num = Math.floor(s / CONVERSIONS[measurement]);
          if (num === 1) {
            segments.push(`${num} ${measurement.slice(0, measurement.length - 1)}`);
          }
          if (num > 1) {
            segments.push(`${num} ${measurement}`);
          }
          s %= CONVERSIONS[measurement];
        }
        return segments.join(', ');
      }
    };

    Object.keys(filters).forEach(function (filter) {
      Vue.filter(filter, filters[filter]);
    });
  }
};
