<template>
  <div class="variable-input" :data-cy-test="`${variableName}-config`">
    <div class="field">
      <label class="label variable-label">
        {{ variableName }}
        <b-tooltip
          :label="value.description"
          type="is-info"
          position="is-top"
          :data-cy-test="`${variableName}-tooltip`"
        >
          <b-icon
            icon="commenting"
            class="dev-comment__icon"
            :data-cy-test="`${variableName}-comment`"
          ></b-icon>
        </b-tooltip>
      </label>
      <b-select
        v-model="editableValue"
        :data-cy-test="`${variableName}-input`"
        placeholder="Select an option"
        expanded
      >
        <option
          v-for="option in value.options"
          :key="option"
          :value="option"
          :disabled="disabled"
        >{{ option }}</option>
      </b-select>
    </div>
  </div>
</template>

  <script>
    import { bus } from '@/main';
    export default {
      name: 'configure-enum',
      props: {
        value: {
          type: Object,
          required: true
        },
        variableName: {
          type: String,
          required: true
        },
        disabled: {
          type: Boolean,
          required: false,
          default: false
        }
      },
      computed: {
        editableValue: {
          get() {
            return this.value.value;
          },
          set(value) {
            this.$emit('input', {...this.value, value});
            bus.$emit('variablesChange');
          }
        }
      }
    };
    </script>

    <style scoped>
    .variable-input {
      margin: 0px 0px 10.5px 0px;
    }
    </style>


