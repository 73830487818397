export const navigation = [
  {
    group: 'Insights',
    isUSAccount: true,
    links: [
      {
        path: '/insights',
        label: 'General',
        icon: 'pie-chart'
      },
      {
        path: '/insights/testing',
        label: 'Testing',
        icon: 'list-ol'
      },
      {
        isSuper: true,
        path: '/insights/usage',
        label: 'Usage',
        icon: 'dashboard'
      }
    ]
  },
  {
    group: 'Website',
    links: [
      {
        path: '/traffic-flows',
        label: 'Traffic Flows',
        icon: 'filter'
      },
      {
        path: '/audience-rule-lists',
        label: 'Rule Lists',
        icon: 'th-list'
      },
      {
        path: '/history',
        label: 'History',
        icon: 'history'
      }
    ]
  },
  {
    group: 'User Experience',
    links: [
      {
        path: '/experiences',
        label: 'Experiences',
        icon: 'desktop',
        childRouteNames: ['Experience']
      },
      {
        path: '/placements',
        label: 'Placements',
        icon: 'object-group',
        childRouteNames: ['Placement']
      },
      {
        path: '/assets',
        label: 'Assets',
        icon: 'file-code-o',
        childRouteNames: ['Asset']
      },
      {
        path: '/add-ons',
        label: 'Add-Ons',
        icon: 'cubes',
        childRouteNames: ['AddOn']
      },
      {
        path: '/integrations',
        label: 'Integrations',
        icon: 'plug',
        childRouteNames: ['Integrations']
      }
    ]
  },
  {
    group: 'Testing',
    links: [
      {
        path: '/tests',
        label: 'All Tests',
        icon: 'flask'
      }
    ]
  },
  {
    group: 'Manage',
    links: [
      {
        isAdmin: true,
        path: '/accounts',
        label: 'Accounts',
        icon: 'suitcase'
      },
      {
        isAdmin: true,
        path: '/users',
        label: 'Users',
        icon: 'users'
      },
      {
        isSuper: true,
        path: '/regions',
        label: 'Regions',
        icon: 'globe'
      },
      {
        isSuper: true,
        path: '/user-audit',
        label: 'User Audit',
        icon: 'user-secret'
      }
    ]
  },
  {
    group: 'Support',
    links: [
      {
        path: '/account-help',
        label: 'Account Help',
        icon: 'user-circle-o'
      },
      {
        link: 'https://redventures.atlassian.net/l/c/HpSTLBoL',
        label: 'Documentation',
        icon: 'book'
      },
      {
        link: 'https://docs.google.com/forms/d/e/1FAIpQLSc4oLHWIEtY_pazk4fzwKTY9g2odcctNET0iiQHHYBQ0O4dEw/viewform',
        label: 'Feedback',
        icon: 'comments-o'
      }
    ]
  }
];

export default navigation;
