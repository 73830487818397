/**
 * Get the application environment based on hostname.
 * @param {String} hostname
 * @returns {String} environment name
 */
export default function getAppEnv(hostname) {
  switch (hostname) {
    case 'localhost:4001':
    case 'localhost:8050':
    case 'localhost:8080':
    case 'localhost:8081':
      return 'LOCAL';
    case 'admin.development.preamp.io':
      return 'DEV';
    case 'admin.staging.preamp.io':
      return 'STAGING';
    case 'admin.preamp.io':
      return 'PROD';
    case 'admin.preamp-nonprod.rvohealth.com':
      return 'HEALTH_NONPROD';
    case 'admin.preamp.rvohealth.com':
      return 'HEALTH_PROD';
    default:
      return 'PROD';
  }
}
