<template>
  <div
    class="columns"
    :class="{ 'is-multiline': stacked }"
  >
    <div
      class="column"
      :class="{ 'is-full': stacked }"
    >
      <b-field label="CSV File">
        <b-field
          v-if="file === null"
          class="file"
          data-cy-test="drop-csv-file"
        >
          <b-upload
            accept=".csv"
            type="is-info"
            drag-drop
            @input="selected"
          >
            <section class="upload-content">
              <div class="content has-text-centered">
                <p>
                  <span class="icon is-medium"><i class="fa fa-upload"></i></span>
                  <span data-cy-test="csv-file-message">Drop a .csv file here or Click to upload.</span>
                </p>
              </div>
            </section>
          </b-upload>
        </b-field>

        <b-field v-else>
          <div
            v-if="editable"
            class="notification is-info has-text-centered"
          >
            <button
              class="delete"
              data-cy-test="delete-icon"
              @click="removed"
            />
            <span class="icon is-medium"><i class="fa fa-file"></i></span>
            <span data-cy-test="file-name">{{ file.name }}</span>
          </div>
          <div v-else>
            <div
              class="download notification is-info has-text-centered"
              @click="download"
            >
              <span class="icon is-medium"><i class="fa fa-download"></i></span>
              <span data-cy-test="file-name">{{ file.name }}</span>
            </div>
          </div>
        </b-field>
      </b-field>
    </div>

    <div
      class="column"
      :class="{ 'is-full': stacked }"
      data-cy-test="file-preview"
    >
      <b-field
        label="File Preview"
        message="Preview of the first 20 rows."
      >
        <div class="content">
          <b-taglist>
            <b-tag
              v-for="(p, i) in preview"
              :key="i"
              data-cy-test="zip-code"
            >{{ p }}</b-tag>
          </b-taglist>
        </div>
      </b-field>
    </div>

    <b-loading
      :active.sync="isLoading"
      :is-full-page="false"
    ></b-loading>
  </div>
</template>

<script>
export default {
  name: 'AudienceRuleListUpload',
  props: ['file', 'valid', 'stacked', 'editable', 'listId', 'siteId'],
  data() {
    return {
      preview: [],
      isLoading: false
    };
  },
  watch: {
    file(file) {
      if (this.file !== null) {
        this.createPreview(file);
      } else {
        this.preview = [];
      }
    }
  },
  methods: {
    // The file was removed.
    removed() {
      this.preview = [];
      this.$emit('update:file', null);
      this.$emit('update:valid', false);
    },
    /**
     * Get a preview of the data for a selected file.
     * If we successfully preview it, consider it a valid file.
     * @param {File} file
     */
    async createPreview(file) {
      this.isLoading = true;
      try {
        const data = new FormData();
        data.append('csv', file);

        const res = await this.$axios.post('/preview-audience-rule-list?limit=20', data);
        this.preview = res.data;

        this.$emit('update:valid', true);
        this.$emit('update:file', file);
      } catch (err) {
        err.title = `There was a problem creating a preview for ${file.name}`;
        this.$store.commit('error', err);

        this.$emit('update:valid', false);
      }
      this.isLoading = false;
    },
    /**
     * When a file is selected, update that file in the parent.
     * @param {File} file
     */
    selected(file) {
      this.$emit('update:file', file);
    },
    // Download the csv locally.
    async download() {
      const a = document.createElement('a');
      const url = URL.createObjectURL(this.file);
      a.href = url;
      a.download = this.file.name;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }
};
</script>

<style lang="scss">
.upload {
  width: 100% !important;
}

.upload-draggable {
  width: 100% !important;
}

.upload-content {
  padding-top: 18px;
  padding-bottom: 18px;
}

.download {
  cursor: pointer;

  &:hover {
    background-color: #1496ed;
  }
}
</style>
