<template>
  <div>
    <!-- ISO Date Inputs -->
    <b-field v-if="showDatePicker">
      <b-field v-if="hasEmptyRuleValue && isEditing">
        <p class="control">
          <button
            class="button is-info is-fullwidth"
            @click="handleModalOpen"
          >
            <b-icon
              size="is-small"
              icon="clock-o"
            ></b-icon>
            <span>Choose a Date</span>
          </button>
        </p>
      </b-field>

      <b-field v-if="!hasEmptyRuleValue">
        <b-input
          :disabled="!isEditing"
          :value="dateDisplay"
          :readonly="true"
          expanded
        ></b-input>

        <p class="control">
          <button
            v-if="!isEditing && needsTimeZone && timeZoneAbbr"
            class="button u-no-pointer"
          >{{ timeZoneAbbr }}</button>

          <button
            v-if="isEditing"
            class="button is-info"
            @click="handleModalOpen"
          >
            <b-icon
              size="is-small"
              icon="pencil"
            ></b-icon>
          </button>
        </p>
      </b-field>
    </b-field>

    <!-- Month, Hour, Day Inputs -->
    <b-field v-else-if="showRuleOptions">
      <b-field v-if="currentOptionSelected">
        <b-input
          :disabled="!isEditing"
          :value="currentOptionSelected.label"
          expanded
          :readonly="true"
        ></b-input>

        <div
          v-if="!isEditing && needsTimeZone && timeZoneAbbr"
          class="control"
        >
          <button class="button u-no-pointer">{{ timeZoneAbbr }}</button>
        </div>

        <p
          v-if="isEditing"
          class="control"
        >
          <button
            class="button is-info"
            @click="handleModalOpen"
          >
            <b-icon
              size="is-small"
              icon="pencil"
            ></b-icon>
          </button>
        </p>
      </b-field>

      <b-field v-else-if="isEditing">
        <p class="control">
          <button
            class="button is-info is-fullwidth"
            @click="handleModalOpen"
          >
            <b-icon
              size="is-small"
              icon="clock-o"
            ></b-icon>
            <span>Click to select a value</span>
          </button>
        </p>
      </b-field>
    </b-field>

    <!-- Minute, Year, UTC Offset Inputs -->
    <b-field v-else-if="showNumberInput">
      <b-field v-if="!hasEmptyRuleValue">
        <b-input
          :disabled="!isEditing"
          :value="rule.value"
          expanded
          :readonly="true"
        ></b-input>

        <div
          v-if="!isEditing && needsTimeZone && timeZoneAbbr"
          class="control"
        >
          <button class="button u-no-pointer">{{ timeZoneAbbr }}</button>
        </div>

        <p
          v-if="isEditing"
          class="control"
        >
          <button
            class="button is-info"
            @click="handleModalOpen"
          >
            <b-icon
              size="is-small"
              icon="pencil"
            ></b-icon>
          </button>
        </p>
      </b-field>

      <b-field v-else-if="isEditing">
        <p class="control">
          <button
            class="button is-info is-fullwidth"
            @click="handleModalOpen"
          >
            <b-icon
              size="is-small"
              icon="clock-o"
            ></b-icon>
            <span>Click to select a value</span>
          </button>
        </p>
      </b-field>
    </b-field>

    <b-field v-else>
      <b-input
        :disabled="!isEditing"
        :value="rule.value"
        expanded
        :readonly="true"
        placeholder="rule.value"
      ></b-input>
    </b-field>

    <b-modal
      v-if="isEditing"
      :active.sync="isModalActive"
    >
      <!-- v-if here so that child components are reset on modal close -->
      <div
        v-if="isModalActive"
        class="card"
      >
        <header class="card-header">
          <span class="card-header-title">{{ rule.path }}</span>
        </header>

        <div class="card-content">
          <DateTimePicker
            v-if="showDatePicker"
            :rule="rule"
            :is-editing="isEditing"
            :needs-time-zone="needsTimeZone"
            @date-selection="handleDateSelection"
          />

          <DateTimeOptions
            v-if="showRuleOptions"
            :rule="rule"
            :rule-options="pathData.options"
          />

          <DateNumberInput
            v-if="showNumberInput"
            :rule="rule"
            :is-editing="isEditing"
          />

          <TimeZonePicker
            v-if="needsTimeZone"
            :selectedTz.sync="selectedTz"
            :tz-options="tzOptions"
            @reset="selectedTz = null"
          />

          <div v-if="showDatePicker">
            <hr>
            <b-field
              label="Result"
              label-position="inside"
            >
              <b-input
                type="text"
                disabled
                :value="datePreview"
              ></b-input>
            </b-field>
          </div>
        </div><!-- /.card-content -->

        <footer class="card-footer">
          <div class="level">
            <div class="level-left">
              <div
                v-if="needsTimeZone && !selectedTz"
                class="level-item"
              >
                <p class="help is-danger">A time zone must be selected for `serverTime` rules</p>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <button
                  :disabled="hasInvalidValues"
                  class="button is-success"
                  @click="handleSaveDate"
                >Save</button>
              </div>
              <div class="level-item">
                <button
                  class="button"
                  @click="handleCancel"
                >Cancel</button>
              </div>
            </div>
          </div>
        </footer><!-- /.card-footer -->
      </div><!-- /.card -->
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment-timezone';

import { clone } from '@/modules/utilities';
import DateTimePicker from '@/components/Rules/DateTimeRules/DateTimePicker';
import DateTimeOptions from '@/components/Rules/DateTimeRules/DateTimeOptions';
import DateNumberInput from '@/components/Rules/DateTimeRules/DateNumberInput';
import TimeZonePicker from '@/components/Rules/DateTimeRules/TimeZonePicker';

export default {
  name: 'DateTimeRules',
  components: {
    DateTimePicker,
    DateTimeOptions,
    TimeZonePicker,
    DateNumberInput
  },
  props: {
    rule: {
      type: Object
    },
    pathData: {
      type: Object
    },
    isEditing: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      // initial rule values that allow cancel to reset a rule's value
      initialRule: null,
      initialTz: null,

      // selectedTz is the currently selected time zone option
      selectedTz: null,
      tzOptions: null,

      // dateDisplay is the input date when a user is NOT editing
      dateDisplay: null,

      // modal controls
      isModalActive: false,
      modalWidth: 700
    };
  },
  computed: {
    rulePath() {
      return this.rule.path;
    },
    // hasInvalidValues - controls whether the user is able to save in the modal
    hasInvalidValues() {
      if (this.rule.value === undefined || this.rule.value === null || this.rule.value === '') {
        return true;
      }

      if (this.needsTimeZone && !this.selectedTz) {
        return true;
      }

      return false;
    },
    // currentOptionSelected - the currently selected full rule object from modules/ruleList
    currentOptionSelected() {
      if (!this.showRuleOptions || this.rule.value === '') return null;

      return this.pathData.options.find(o => o.value === this.rule.value);
    },
    // needsTimeZone - controls whether time zone values and components are displayed
    needsTimeZone() {
      return this.rule.path.match('serverTime') !== null;
    },
    hasEmptyRuleValue() {
      return !this.rule.value || this.rule.value === '';
    },
    showDatePicker() {
      return this.rule.path.match(/\.iso$/g) !== null;
    },
    showRuleOptions() {
      return this.rule.path.match(/\.(month|hour|dayOfWeek|day)$/g) !== null && this.pathData.options !== undefined;
    },
    showNumberInput() {
      return this.rule.path.match(/\.(minute|year|utcOffset)$/g) !== null;
    },
    // datePreview - the results date in the modal for ISO dates
    datePreview() {
      if (!this.showDatePicker || this.hasEmptyRuleValue) return null;

      return `${this.dateDisplay} ${this.timeZoneAbbr || ''}`;
    },
    // timeZoneAbbr - gets the time zone abbreviation for serverTime rules (ex: EST)
    timeZoneAbbr() {
      if (!this.needsTimeZone || this.hasEmptyRuleValue || !this.rule.options || !this.rule.options.timeZone) return null;

      return moment(this.rule.value).tz(this.rule.options.timeZone).zoneAbbr();
    }
  },
  watch: {
    // set the selected time zone, even if it's null, as the rule's time zone option
    selectedTz(val) {
      if (this.needsTimeZone) {
        this.rule.options.timeZone = val;
      }
    },
    // reset the rule's value if the rule's path changes
    rulePath() {
      this.rule.value = '';
    },
    // copy the rule's initial value so that cancel can reset the rule value
    isModalActive(active) {
      if (active) {
        this.initialRule = clone(this.rule);
        this.initialTz = this.selectedTz;
      } else {
        this.initialRule = null;
        this.initialTz = null;
      }
    }
  },
  created() {
    this.setDateDisplay();

    if (this.needsTimeZone) {
      this.tzOptions = moment.tz.names();

      if (!this.rule.options) {
        this.rule.options = {};
      }

      if (!this.rule.options.timeZone) {
        this.rule.options.timeZone = null;
      }

      this.setInitialTimeZone();
    }
  },
  methods: {
    setInitialTimeZone() {
      let initialSelection = null;

      // if the rule has a timeZone. parse it and use it as the initial selection
      if (this.rule.options && this.rule.options.timeZone) {
        const isValidTimeZone = moment.tz.zone(this.rule.options.timeZone);

        if (isValidTimeZone !== null) {
          initialSelection = moment.tz.zone(this.rule.options.timeZone).name;
        }
      }

      // the rule has no timeZone. set the initial dropdown selection to users current time zone
      if (!initialSelection) {
        this.rule.options.timeZone = null;
        initialSelection = moment.tz.guess(true);
      }

      this.selectedTz = initialSelection;
    },
    setDateDisplay() {
      if (this.showDatePicker && this.hasEmptyRuleValue === false) {
        this.dateDisplay = moment(this.rule.value).format('lll');
      }
    },
    // event/interaction handlers
    handleDateSelection(date, timeZone) {
      this.rule.value = moment(date).toISOString();

      if (timeZone) {
        if (this.rule.options) this.rule.options.timeZone = timeZone;
      }

      this.setDateDisplay();
    },
    handleSaveDate() {
      if (this.needsTimeZone && this.selectedTz) {
        this.rule.options.timeZone = this.selectedTz;
      }

      if (!this.needsTimeZone) delete this.rule.options;

      if (this.showNumberInput && typeof this.rule.value === 'string') {
        this.rule.value = parseInt(this.rule.value, 10);
      }

      this.handleModalClose();
    },
    handleCancel() {
      if (this.needsTimeZone) {
        this.rule.options.timeZone = this.initialTz;
        this.selectedTz = this.initialTz;
      }

      if (this.initialRule) {
        this.rule.value = this.initialRule.value;
      } else {
        this.rule.value = '';
      }

      this.handleModalClose();
    },
    handleModalOpen() {
      this.isModalActive = true;
    },
    handleModalClose() {
      this.isModalActive = false;
    }
  }
};
</script>
