import { render, staticRenderFns } from "./Audience.vue?vue&type=template&id=acf7dc88&scoped=true"
import script from "./Audience.vue?vue&type=script&lang=js"
export * from "./Audience.vue?vue&type=script&lang=js"
import style0 from "./Audience.vue?vue&type=style&index=0&id=acf7dc88&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acf7dc88",
  null
  
)

export default component.exports