<template>
  <section class="users">
    <div class="main-view-header is-bordered">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h4 class="title is-4">History</h4>
          </div>
        </div><!-- /.level-left -->
        <div class="level-right">
          <div class="level-item">
            <FilterMenu v-model="filterParams" />
          </div>
        </div><!-- /.level-right -->
      </div><!-- /.level -->
    </div>

    <div class="main-view-content has-pagination-footer">
      <Log
        v-for="(log, index) in logs"
        v-if="Array.isArray(logs) && logs.length"
        :key="index"
        :log="log"
      />

      <article
        v-else-if="Array.isArray(logs)"
        class="message is-warning"
      >
        <div class="message-body">
          No Logs were found for this site.
        </div>
      </article>
    </div><!-- /.main-view-content -->

    <footer class="main-view-footer">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <button
              class="button"
              :disabled="page === 0"
              @click.stop="getPage(-1)"
            >Previous</button>
          </div>
          <div class="level-item">
            <button
              class="button"
              :disabled="pages[page+1] === undefined"
              @click.stop="getPage(1)"
            >Next</button>
          </div>
        </div>
      </div>
    </footer>

    <b-loading
      :active.sync="isLoading"
      :is-full-page="false"
    ></b-loading>
  </section>
</template>

<script>
import Log from '@/components/Log';
import FilterMenu from '@/components/History/FilterMenu';
import { bus } from '@/main.js';
import { formatTime } from '@/modules/utilities';

export default {
  name: 'History',
  components: {
    Log,
    FilterMenu
  },
  data() {
    return {
      logs: null,
      page: 0,
      pages: {
        0: false
      },
      isLoading: false,
      filterParams: {
        users: [],
        actions: []
      }
    };
  },
  computed: {
    // Get the site id
    siteId() {
      return this.$store.state.siteId;
    }
  },
  watch: {
    filterParams: {
      handler() {
        this.page = 0;
        this.pages = {
          0:false
        };
        this.getSiteLogs();
      },
      deep: true
    }
  },
  created() {
    if (this.siteId) this.getSiteLogs();
    bus.$on('site-changed', () => {
      this.reset();
      this.getSiteLogs();
    });
  },
  methods: {
    /**
     * Get the next page of data from the API
     * @param {Number} integer - postive or negative 1
     */
    getPage(integer) {
      const startKey = this.pages[this.page + integer];
      this.getSiteLogs(startKey);

      this.page += integer;
    },
    /**
     * Request site logs from the API
     * @param {Object} lastEvaluated - key that was last evaluated
     */
    async getSiteLogs(lastEvaluated) {
      this.isLoading = true;

      try {
        const res = await this.$axios.get(`/sites/${this.siteId}/logs`, {
          params: {
            lastEvaluated,
            filterParams: this.filterParams
          }
        });

        this.pages[this.page + 1] = res.data.lastEvaluatedKey;

        // Format the logs for rendering
        this.logs = res.data.logs.map(function (log) {
          const logDiff = log.action.diff;

          // Set Timestamp
          log.createdAt = formatTime(log.createdAt);

          // If it's not a diff we are done here
          if (!logDiff) return log;

          // Parse previous diff & new diff
          log.action.diff.previous = (logDiff.previous) ? JSON.parse(logDiff.previous) : { data: 'No Data' };
          log.action.diff.new = (logDiff.new) ? JSON.parse(logDiff.new) : { data: 'No Data' };

          return log;
        });
      } catch (err) {
        err.title = `There was an issue getting the logs for ${this.siteId}`;
        this.$store.commit('error', err);
      }

      this.isLoading = false;
    },
    // When a site changes, reset the pagination tracking and filtering params
    reset() {
      this.page = 0;
      this.pages = {
        0: false
      };
      this.filterParams = {
        users: [],
        actions: []
      };
    }
  }
};
</script>
