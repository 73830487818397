<template>
  <div style="margin: 15px 0;">
    <label class="label">Select the Time Zone</label>
    <b-field>
      <b-select
        :value="selectedTz"
        expanded
        @input="handleSelection"
      >
        <option
          disabled
          :value="null"
        >Select a value</option>
        <option
          v-for="tz in tzOptions"
          :key="tz"
          :value="tz"
        >{{ tz }}</option>
      </b-select>

      <p class="control">
        <button
          class="button button"
          @click="$emit('reset')"
        >
          <b-icon
            icon="ban"
            size="is-small"
          ></b-icon>
        </button>
      </p>
    </b-field>
  </div>
</template>

<script>
export default {
  name: 'TimeZonePicker',
  props: ['selectedTz', 'tzOptions'],
  methods: {
    handleSelection(value) {
      this.$emit('update:selectedTz', value);
    }
  }
};
</script>
