<template>
  <div class="level">
    <div class="level-left">
      <div class="level-item">
        <FilterMultiDropdown
          v-model="filterParams.users"
          :filterOptions="users"
          filterItem="User"
        />
      </div>
      <div class="level-item">
        <FilterMultiDropdown
          v-model="filterParams.actions"
          :filterOptions="actions"
          filterItem="Action"
        />
      </div>
    </div><!-- /.level-left -->
  </div><!-- /.level -->
</template>

<script>
import { bus } from '@/main.js';
import FilterMultiDropdown from '@/components/History/FilterMultiDropdown';

export default {
  name: 'FilterMenu',
  components: {
    FilterMultiDropdown
  },
  props: ['value'],
  data() {
    return {
      users: null,
      actions: [
        'CDK Sync',
        'CLI Sync',
        'Created an experience',
        'Edited an experience',
        'Duplicated an asset',
        'Deleted an experience',
        'Created a test',
        'Edited a test',
        'Edited many tests',
        'Paused all Tests',
        'Un-Paused all tests',
        'Ended a test',
        'Iterated a test',
        'Iteration Created',
        'Created a test iteration',
        'Updated a Smart Experience test',
        'Ended a Smart Experience test',
        'Created a Rule Group',
        'Edited a Rule Group',
        'Deleted a Rule Group',
        'Edited a Traffic Flow',
        'Published a Draft',
        'Updated Asset Variables',
        'Fast forward asset'
      ]
    };
  },
  computed: {
    // Get the account id
    accountId() {
      return this.$store.state.activeAccount;
    },
    // Abstraction over v-model prop
    filterParams: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      }
    }
  },
  created() {
    if (this.accountId) this.initialize();
    bus.$on('site-changed', () => {
      this.initialize();
    });
  },
  methods: {
    /**
     * Get all filter data
     */
    initialize() {
      this.getUsers();
    },
    /**
     * Get all users with logs
     */
    async getUsers() {
      try {
        const res = await this.$axios.get(`/account/${this.accountId}/emails`);
        this.users = res.data.sort();
      } catch (err) {
        err.title = `There was an issue getting the users for ${this.accountId}`;
        this.$store.commit('error', err);
      }
    }
  }
};
</script>
