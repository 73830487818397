<template>
  <div class="card experience experience--default has-card-box">
    <header class="card-header">
      <p
        class="card-header-title is-relative"
      >
        <span class="card-header-box">
          0
        </span>
        <span data-cy-test="default-exp">
          Default (Base Site)
        </span>
      </p>

      <div
        v-if="!editing"
        class="card-header-item"
        data-cy-test="default-exp-card-header"
      >
        <span class="tags has-addons">
          <span
            class="tag is-info"
            data-cy-test="default-exp-visitor-icon"
          >Visitors</span>
          <span
            class="tag is-percentage"
            data-cy-test="default-exp-percentage"
          >{{ points }}%</span>
        </span>
      </div>

      <div
        v-if="editing"
        class="card-header-item card-header-padding"
        :class="{ 'card-header-padding--small': hasLessPadding }"
      >
        <div class="number-input">
          <b-field
            grouped
            class="number-input__group"
          ></b-field>
          <div class="field has-addons">
            <p class="control number-input__control">
              <input
                type="text"
                readonly
                class="input input-static"
                :value="points"
                disabled
              >
            </p>
            <p class="control">
              <button class="button is-static">%</button>
            </p>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'DefaultExperience',
  props: {
    points: {
      required: true,
      default: 0
    },
    editing: {
      required: false,
      default: false
    },
    hasLessPadding: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="css" scoped>
  /* These adjust the card-header-item positions in different states */
  .card-header-item {
    padding: 0 108px 0 0;
  }

  /* Handles allignment when isControl-checkbox, experience-weight-input, and trash icon are active */
  .card-header-padding {
    padding-right: 211px;
  }

  /* Handles allignment when only the isControl-checkbox and experience-weight-input are active */
  .card-header-padding--small {
    padding-right: 164px;
  }
</style>
