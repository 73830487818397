<template>
  <div class="card bottom10">
    <header class="card-header">
      <div class="card-header-title">
        Add New Account
      </div>
    </header><!-- /.card-header -->

    <div class="card-content">
      <section>
        <div class="field is-grouped">
          <div class="control is-expanded">
            <label class="label">Account Name</label>
            <input
              v-model="accountName"
              type="text"
              class="input"
              placeholder="My Account"
              maxlength="64"
            >
          </div>

          <div class="control is-expanded">
            <label class="label">Site Name</label>
            <input
              v-model="siteName"
              type="text"
              class="input"
              placeholder="Example Site"
            >
          </div>

          <div class="control is-expanded">
            <label class="label">Site URL</label>
            <input
              v-model="siteUrl"
              type="text"
              class="input"
              placeholder="https://www.example.com"
            >
          </div>
        </div><!-- /.field -->
      </section>
    </div>

    <div class="card-footer">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <base-button-save
              :loading="isSavingAccount"
              :disabled="!isValid.valid"
              @click="save"
            />
          </div>

          <div class="level-item">
            <button
              class="button"
              @click.stop="cancel"
            >Cancel</button>
          </div>

          <div
            v-if="!isValid.valid"
            class="level-item"
          >
            <p class="has-text-danger">
              <span class="icon">
                <i class="fa fa-exclamation-circle"></i>
              </span>
              {{ isValid.errMsg }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div><!-- /.card -->
</template>

<script>
import { isValidUrl } from '@/modules/utilities';

export default {
  name: 'NewAccount',
  props: {
    accountNames: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      accountName: '',
      siteName: '',
      siteUrl: '',
      isSavingAccount: false
    };
  },
  computed: {
    // Validate new account form fields
    isValid() {
      let errMsg;
      let valid = false;

      switch (true) {
        case this.accountNames.indexOf(this.accountName) >= 0:
          errMsg = 'This account is already taken.';
          break;
        case this.accountName.trim().length === 0:
          errMsg = 'Please enter a valid account name.';
          break;
        case this.siteName.trim().length === 0:
          errMsg = 'Please enter a valid site name.';
          break;
        case this.siteUrl && !isValidUrl(this.siteUrl):
          errMsg = 'Please enter a valid site URL.';
          break;
        default:
          valid = true;
      }

      return {
        errMsg,
        valid
      };
    }
  },
  methods: {
    // Reset the add account form and close
    cancel() {
      this.accountName = '';
      this.siteName = '';
      this.siteUrl = '';
      this.$emit('addAccountCancel');
    },
    // Post the new account to Preamp
    async save() {
      this.isSavingAccount = true;

      const payload = {
        accountName: this.accountName,
        siteName: this.siteName,
        siteUrl: this.siteUrl
      };

      try {
        const res = await this.$axios.post('/account', payload);
        this.$buefy.toast.open({
          message: `Successfully created ${res.data.account.name}`,
          type: 'is-success'
        });

        this.$emit('addedAccount');
        this.cancel();
      } catch (err) {
        err.title = `Could not create ${this.accountName}`;
        this.$store.commit('error', err);
      }

      this.isSavingAccount = false;
    }
  }
};
</script>
