<template>
  <section>
    <div class="main-view-header">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title is-4" data-cy-test="title">
              Usage Insights
            </h1>
          </div>
        </div><!-- /.level-left -->

        <div class="level-right"></div><!-- /.level-right -->
      </div><!-- /.level -->
    </div>

    <div class="main-view-sub-header">
      <div class="tabs is-boxed">
        <ul>
          <li
            v-for="(tab, index) in tabs"
            :id="`tab-tests-item-${tab.id}`"
            :key="index"
            :class="{ 'is-active': activeTab === tab.id }"
          >
            <a
              href="javascript:void(0)"
              :data-cy-test="`tab-${tab.id}`"
              :title="tab.tip"
              @click="activeTab = tab.id"
            >
              {{ tab.title }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="main-view-content">
      <!--  GLOBAL ASSET METRICS TABEL -->
      <div class="kpi-container">
        <div>
          <b-table
            v-if="activeTab === 'global'"
            :data="assetsMetricsToAnalyze"
            detailed
            custom-detail-row
            hoverable
            show-detail-icon
            :columns="columns"
          >
            <template>
              <div class="header-column">
                <div class="title-column">
                  <h3 class="table-title">KPI Summary - Global</h3>
                </div>
                <div class="search-column">
                  <b-input v-model="assetsFilterQuery" placeholder="Search..." icon="search" />
                </div>
              </div>
            </template>

            <template v-if="activeTab === 'global'" slot="detail" slot-scope="props">
              <tr v-for="item in matchAccountWithSites(props.row.id)" :key="item.name">
                <td></td>
                <td class="has-text-left">
                  {{ item.name }}
                </td>
                <td class="has-text-centered">
                  {{ item.value }}
                </td>
              </tr>
            </template>

            <template #footer>
              <th></th>
              <th>Total</th>
              <th class="has-text-centered">
                {{
                  allAssetsMetrics.globalCount
                }}
              </th>
            </template>
            <template #empty>
              <div class="has-text-centered" data-cy-test="empty-state-message">
                No results found
              </div>
            </template>
          </b-table>

          <!-- ACCOUNT AND SITE TABLE -->
          <b-table
            v-if="activeTab === 'account' || activeTab === 'site'"
            :data="assetsMetricsToAnalyze"
            custom-detail-row
            hoverable
            :columns="columns"
          >
            <template>
              <div class="header-column">
                <div v-if="activeTab === 'account'" class="title-column">
                  <h3 class="table-title">KPI Summary - {{ activeAccount.name }}</h3>
                </div>
                <div v-else class="title-column">
                  <h3 class="table-title">KPI Summary - {{ activeSite.name }}</h3>
                </div>

                <div class="search-column">
                  <b-input v-model="assetsFilterQuery" placeholder="Search..." icon="search" />
                </div>
              </div>
            </template>

            <template v-if="activeTab === 'account'" slot="detail" slot-scope="props">
              <tr v-for="item in props" :key="item.name">
                <th class="has-text-centered">{{ item }}</th>
              </tr>
            </template>

            <template v-else slot="detail" slot-scope="props">
              <tr v-for="item in props" :key="item.name">
                <td class="has-text-centered">{{ item }}</td>
              </tr>
            </template>

            <template v-if="activeTab === 'account'" #footer>
              <th>Total</th>
              <th class="has-text-centered">
                {{
                  totalAssetCount
                }}
              </th>
            </template>
            <template #empty>
              <div class="has-text-centered" data-cy-test="empty-state-message">
                No results found
              </div>
            </template>
          </b-table>
        </div>
      </div>

      <div class="table-container">
        <!-- Table -->
        <div class="column">
          <div class="audience-container">
            <b-table
              ref="table"
              :data="audienceRulesTable.data"
              detailed
              detail-key="path"
              custom-detail-row
              hoverable
              :default-sort="['totalAudiences', 'desc']"
              :show-detail-icon="true"
            >
              <template>
                <div class="header-column">
                  <div class="title-column">
                    <h3 class="table-title">Audience Rules Summary</h3>
                  </div>
                  <template>
                    <div class="search-column">
                      <b-input v-model="trafficFilterQuery" placeholder="Search..." icon="search" />
                    </div>
                  </template>
                </div>
              </template>

              <b-table-column
                field="path"
                :label="audienceRulesTable.columns['path'].title"
                sortable
              >
                <template v-slot="props">
                  {{ props.row.path }}
                </template>
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="totalAudiences"
                :label="audienceRulesTable.columns['totalAudiences'].title"
                numeric
                sortable
                centered
              >
                {{ props.row.totalAudiences }}
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="totalRules"
                :label="audienceRulesTable.columns['totalRules'].title"
                numeric
                sortable
                centered
              >
                {{ props.row.totalRules }}
              </b-table-column>

              <template slot="detail" slot-scope="props">
                <template v-if="activeTab === 'global'">
                  <tr
                    v-for="item in props.row.accountCounts"
                    :key="`${props.row.path}-${item.accountId}`"
                  >
                    <td></td>
                    <td>
                      &nbsp;&nbsp;&nbsp;&nbsp;{{
                        accountsById[item.accountId]?.name || item.accountId
                      }}
                    </td>
                    <td class="has-text-centered">{{ item.totalAudiences }}</td>
                    <td class="has-text-centered">{{ item.totalRules }}</td>
                  </tr>
                </template>
                <template v-else-if="activeTab === 'account'">
                  <tr
                    v-for="item in props.row.siteCounts"
                    :key="`${props.row.path}-${item.siteId}`"
                  >
                    <td></td>
                    <td>
                      &nbsp;&nbsp;&nbsp;&nbsp;{{ sitesById[item.siteId]?.name || item.siteId }}
                    </td>
                    <td class="has-text-centered">{{ item.totalAudiences }}</td>
                    <td class="has-text-centered">{{ item.totalRules }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="item in props.row.pathCounts" :key="item.path">
                    <td></td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;{{ item.path }}</td>
                    <td class="has-text-centered">{{ item.totalAudiences }}</td>
                    <td class="has-text-centered">{{ item.totalRules }}</td>
                  </tr>
                </template>
              </template>

              <template #footer>
                <th></th>
                <th>Total</th>
                <th class="has-text-centered">
                  {{
                    $refs.table?.visibleData?.reduce((sum, data) => sum + data.totalAudiences, 0)
                  }}
                </th>
                <th class="has-text-centered">
                  {{ $refs.table?.visibleData?.reduce((sum, data) => sum + data.totalRules, 0) }}
                </th>
              </template>
              <template #empty>
                <div class="has-text-centered" data-cy-test="empty-state-message">
                  No results found
                </div>
              </template>
            </b-table>

            <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
          </div>
        </div>

        <!-- Pie Chart -->
        <div class="column">
          <pie-chart :data="audienceRulesPieChart.data"></pie-chart>
        </div>
      </div><!-- /.columns -->
    </div> <!-- /.main-view-content -->
  </section>
</template>

<script>
import { AudienceRuleCounter } from '@/modules/usage/AudienceRuleCounter';

export default {
  name: 'Usage',
  data() {
    return {
      isLoading: false,
      hasRequestedAllTrafficFlows: false,
      hasRequestedAllSites: false,
      hasRequestedAllAssets: false,
      activeTab: 'site',
      tabs: [
        {
          id: 'global',
          title: 'Global',
          tip: 'Show global data for all of Preamp.'
        },
        {
          id: 'account',
          title: 'Account',
          tip: 'Show data for all sites in the current account.'
        },
        {
          id: 'site',
          title: 'Site',
          tip: 'Only show data for the current site.'
        }
      ],
      columnHeader: 'sites',
      trafficFlows: [],
      allSites: [],
      allAssetsMetrics: [],
      assetsFilterQuery: '',
      trafficFilterQuery: '',
      totalAssetCount: 0
    };
  },
  computed: {
    assetsMetricsToAnalyze() {
      let assetMetrics = [];
      const assetArray = [];
      if (this.activeTab === 'global') {
        assetMetrics = this.allAssetsMetrics.accounts;
      } else {
        assetMetrics = this.allAssetsMetrics.sites;
      }
      if (this.assetsFilterQuery) {
        const filteredSearchObj = {};
        Object.entries(assetMetrics).filter(([key, value]) => {
          const keyName = key.split('__')[0];
          if (keyName.toLowerCase().includes(this.assetsFilterQuery.toLocaleLowerCase())) {
            filteredSearchObj[key] = value;
          }
        });
        assetMetrics = filteredSearchObj;
      }
      for (const asset in assetMetrics) {
        const [name, id] = asset.split('__');
        if (this.activeTab === 'account' && !this.activeAccountSiteIds.includes(id)) {
          continue;
        }
        if (this.activeTab === 'site' && this.activeSite.siteId !== id) {
          continue;
        }
        const obj = {
          account: name,
          value: assetMetrics[asset],
          id: id
        };
        assetArray.push(obj);
      }
      return assetArray;
    },
    columns() {
      return [
        {
          field: 'account',
          label: 'Accounts',
          sortable: true
        },
        {
          field: 'value',
          label: 'Assets Created (Last Month)',
          sortable: true,
          centered: true,
          width: '50%'
        },
        {
          field: 'id',
          label: 'ID',
          visible: false
        }
      ];
    },


    isSuper() {
      return this.$store.getters.isSuperUser;
    },
    accounts() {
      return this.$store.state.user.accounts;
    },
    sites() {
      if (this.isSuper) {
        return this.allSites;
      }

      return this.$store.state.activeAccountSites;
    },
    activeAccount() {
      return (
        this.$store.state.user.accounts.find(
          account => account.accountId === this.$store.state.activeAccount
        ) || {}
      );
    },
    activeAccountSites() {
      return this.$store.state.activeAccountSites;
    },
    activeAccountSiteIds() {
      return this.$store.state.activeAccountSites.map(site => site.siteId);
    },
    activeSite() {
      return (
        this.$store.state.activeAccountSites.find(
          site => site.siteId === this.$store.state.siteId
        ) || {}
      );
    },
    accountIdsBySiteId() {
      return this.sites.reduce((result, site) => {
        result[site.siteId] = site.accountId;
        return result;
      }, {});
    },

    accountsById() {
      return this.accounts.reduce((result, account) => {
        result[account.accountId] = account;
        return result;
      }, {});
    },
    sitesById() {
      return this.sites.reduce((result, site) => {
        result[site.siteId] = site;
        return result;
      }, {});
    },
    trafficFlowsToAnalyze() {
      if (this.activeTab === 'global') {
        return this.trafficFlows;
      }

      if (this.activeTab === 'account') {
        return this.trafficFlows.filter(site => this.activeAccountSiteIds.includes(site.siteId));
      }

      return this.trafficFlows.filter(site => site.siteId === this.$store.state.siteId);
    },
    audienceRuleCounts() {
      const audienceRuleCounter = new AudienceRuleCounter(this.trafficFlowsToAnalyze, {
        accountIdsBySiteId: this.accountIdsBySiteId
      });
      let audienceRuleCounterResults = audienceRuleCounter.getAll();
      if (this.trafficFilterQuery) {
        const filteredArray = {};
        Object.entries(audienceRuleCounter.getAll()).filter(([key, value]) => {
          if (key.toLowerCase().includes(this.trafficFilterQuery.toLocaleLowerCase())) {
            filteredArray[key] = value;
          }
        });
        audienceRuleCounterResults = filteredArray;
      }
      return audienceRuleCounterResults;
    },
    audienceRulesTable() {
      return {
        columns: {
          path: { title: 'Path' },
          totalAudiences: { title: 'Audiences' },
          totalRules: { title: 'Rules' }
        },
        // Format data for Buefy table: https://buefy.org/documentation/table
        data: Object.entries(this.audienceRuleCounts).map(([pathBase, pathBaseData]) => ({
          path: pathBase,
          totalAudiences: pathBaseData.totalAudiences,
          totalRules: pathBaseData.totalRules,
          pathCounts: Object.entries(pathBaseData.pathCounts).map(([path, pathData]) => ({
            path,
            ...pathData
          })),
          siteCounts: Object.entries(pathBaseData.siteCounts).map(([siteId, pathData]) => ({
            siteId,
            ...pathData
          })),
          accountCounts: Object.entries(pathBaseData.accountCounts).map(
            ([accountId, pathData]) => ({ accountId, ...pathData })
          )
        }))
      };
    },

    audienceRulesPieChart() {
      return {
        // Format data for Chartkick pie chart: https://chartkick.com/vue#:~:text=Pie%20chart
        data: Object.entries(this.audienceRuleCounts).map(([pathBase, pathBaseData]) => [
          pathBase,
          pathBaseData.totalAudiences
        ])
      };
    }
  },
  watch: {
    activeAccountSites() {
      this.getTrafficFlowsForSites(this.activeAccountSites);
      this.countAssetMetrics(this.allAssetsMetrics);
    }
  },
  created() {
    if (!this.isSuper) {
      this.$router.push('/traffic-flows');
    }
    this.getSites();
    this.getAssetsMetrics();
    this.getTrafficFlowsForSites(this.activeAccountSites);
  },
  methods: {
    matchAccountWithSites(accountId) {
      const matchList = [];
      const allAssetsMetricsSites = this.allAssetsMetrics.sites;
      for (const key in this.allSites) {
        const site = this.allSites[key];
        const fullName = site.name + '__' + site.siteId;

        if (site.accountId === accountId && fullName in this.allAssetsMetrics.sites) {
          const obj = {
            name: site.name,
            value: allAssetsMetricsSites[fullName]
          };
          matchList.push(obj);
        }
      }
      return matchList;
    },

    handleSearch(event) {
      this.searchTerm = event.target.value;
    },
    async getSites() {
      // Only request ALL site data for super admins.
      if (!this.isSuper) return;

      // If we've already requested ALL sites, skip secondary requests.
      if (this.hasRequestedAllSites) return;

      try {
        this.hasRequestedAllSites = true;
        const sitesRes = await this.$axios.get('/accounts/all/sites');
        const { data } = sitesRes;
        this.allSites = data;
      } catch (error) {
        error.title = 'There was a problem retrieving site data';
        this.$store.commit('error', error);
      }
    },
    async getAssetsMetrics() {
      // Only request ALL site data for super admins.
      if (!this.isSuper) return;

      // If we've already requested ALL sites, skip secondary requests.
      if (this.hasRequestedAllAssets) return;

      try {
        this.hasRequestedAllAssets = true;
        const assetsRes = await this.$axios.get('/metrics/assetsCreatedByMonth');
        const { data } = assetsRes;
        this.allAssetsMetrics = data;
        this.countAssetMetrics(data);
      } catch (error) {
        error.title = 'There was a problem retrieving assets data';
        this.$store.commit('error', error);
      }
    },
    async getTrafficFlowsForSites(sites = []) {
      // If we've already requested ALL traffic flows, skip secondary requests.
      if (this.hasRequestedAllTrafficFlows) return;

      this.isLoading = true;

      try {
        if (this.isSuper) {
          // Super Admin: Get ALL traffic flows.
          this.hasRequestedAllTrafficFlows = true;
          const trafficFlowsRes = await this.$axios.get(`/sites/all/traffic-flows`);

          const { data } = trafficFlowsRes;
          this.trafficFlows = data;
        } else {
          // Otherwise, get traffic flows for all sites in current account.
          const trafficFlowsRes = await Promise.all(
            sites.map(site => this.$axios.get(`/sites/${site.siteId}/traffic-flows`))
          );
          const data = trafficFlowsRes.flatMap(res => res.data);
          this.trafficFlows = data;
        }
      } catch (error) {
        error.title = 'There was a problem retrieving traffic flow data';
        this.$store.commit('error', error);
      }

      this.isLoading = false;
    },
    countAssetMetrics(data){
      for (const account in data.accounts) {
        const id = account.split('__')[1];
        if (this.activeAccount.accountId === id) {
          this.totalAssetCount = data.accounts[account];
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main-view-content {
  height: calc(100vh - 166px);
}

.asset-number {
  font-size: 24px;
  color: black;
}

.table-container {
  display: flex;
}

.kpi-container {
  width: 100%;
  background-color: white;
  border: 2px solid #dbdbdb;
  margin-bottom: 1.5vw;
  margin-left: 11px;
  margin-top: 1vw;
}

.audience-container {
  background-color: white;
  border: 2px solid #dbdbdb;
}

.table-title {
  font-size: medium;
  font-weight: bold;
}

.title-column {
  width: 50%;
  display: flex;
  justify-content: left;
}

.search-column {
  width: 50%;
  display: flex;
  justify-content: right;
}

.header-column {
  margin-top: 20px;
  margin-left: 11px;
  margin-right: 20px;
  margin-bottom: 10px;
  display: flex;
}

.bold-text {
  font-weight: 600;
}
</style>
