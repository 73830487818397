module.exports = {
  ElementClicked(data) {
    return {
      '@type': 'redventures.usertracking.v3.ElementClicked',
      webElement: {
        elementType: data.elementType,
        location: data.location,
        position: '',
        text: data.text,
        htmlId: data.htmlId || ''
      },
      actionOutcome: data.actionOutcome
    };
  },
  FormSubmitted(data) {
    const { test, notes, testOutcome, user, siteName, accountName } = data.payload;
    return {
      '@type': 'redventures.usertracking.v3.FormSubmitted',
      field:
        [
          {
            fieldName: 'Test',
            fieldValue: `${test}`
          },
          {
            fieldName: 'Test Outcome',
            fieldValue: `${testOutcome}`
          },
          {
            fieldName: 'Notes',
            fieldValue: `${notes}`
          },
          {
            fieldName: 'Account name',
            fieldValue: `${accountName}`
          },
          {
            fieldName: 'Site name',
            fieldValue: `${siteName}`
          },
          {
            fieldName: 'User',
            fieldValue: `${user}`
          }
      ]
    };
  }
};
