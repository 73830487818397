<template>
  <section>
    <div class="main-view-header is-bordered">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h4 class="title is-4">Insights</h4>
          </div>
        </div><!-- /.level-left -->

        <div class="level-right"></div><!-- /.level-right -->
      </div><!-- /.level -->
    </div>

    <div
      v-if="siteId && isInsightsAvailable"
      class="main-view-content"
    >
      <div class="insights__chart">
        <TrafficOverTimeChart />
      </div>

      <div class="insights__breakdowns">

        <!-- Channel Breakdown -->
        <div class="insights__breakdown-chart">
          <BreakdownChart
            :site-id="siteId"
            :payload="payloads.channel"
            title="Channel"
            table-heading="Channel Type"
          />
        </div>

        <!-- Device Breakdown -->
        <div class="insights__breakdown-chart">
          <DeviceBrowserParentChart
            :site-id="siteId"
          />
        </div>

      </div><!-- /.insights__breakdowns -->

      <div class="insights__chart">
        <AttributionChart
          title="Audiences"
          y-axis-title="Audience Name"
          :payload="payloads.audiences"
        />
      </div>

      <div class="insights__chart">
        <AttributionChart
          title="Experiences"
          y-axis-title="Experience Name"
          :payload="payloads.experiences"
          :colors="['#e3835a']"
        />
      </div>

      <div class="insights__chart">
        <AttributionChart
          title="Tests"
          y-axis-title="Test Name"
          :payload="payloads.tests"
          :colors="['#3eae9d']"
        />
      </div>

    </div><!-- /.main-view-content -->

    <!-- no siteId selected message -->
    <div
      v-if="!siteId"
      class="main-view-content"
    >
      <article class="message is-warning">
        <header class="message-header">Both Account and Site Selections Required</header>
        <p class="message-body">
          To access Insights and other areas in the admin, use the two dropdowns in the top navigation bar to select an account and an associated site.
        </p>
      </article>
    </div><!-- /.main-view-content -->

    <!-- message for HIPAA and non-US sites -->
    <div
      v-if="siteId && !isInsightsAvailable"
      class="main-view-content"
    >
      <article class="message is-warning">
        <header class="message-header" data-cy-test="message-header">Feature Unavailable For This Site</header>
        <p
          v-if="isSiteHIPAA"
          class="message-body"
        >
          Data visualizations are currently unavailable for HIPAA sites.
          <br>
          If this does not apply to the site you have selected, please reach out to the Cohesion team for assistance.
        </p>
        <p
          v-else
          class="message-body"
        >
          Data visualizations are currently only available for sites operating in the US.
          <br>
          If this applies to the site you have selected, please reach out to the Cohesion team for assistance.
        </p>
      </article>
    </div><!-- /.main-view-content -->

  </section>
</template>

<script>

import AttributionChart from '@/components/Insights/AttributionChart';
import TrafficOverTimeChart from '@/components/Insights/TrafficOverTimeChart';
import BreakdownChart from '@/components/Insights/BreakdownChart';
import DeviceBrowserParentChart from '@/components/Insights/DeviceBrowserParentChart';
import payloads from '@/modules/insightsPayloads';

export default {
  name: 'Insights',
  components: {
    AttributionChart,
    TrafficOverTimeChart,
    BreakdownChart,
    DeviceBrowserParentChart
  },
  data() {
    return {
      payloads
    };
  },
  computed: {
    siteId() {
      return this.$store.state.siteId;
    },
    isInsightsAvailable() {
      return this.$store.getters.isInsightsAvailable;
    },
    isSiteHIPAA() {
      return this.$store.getters.isSiteHIPAA;
    }
  }
};
</script>

<style lang="scss" scoped>
.insights {
  &__chart {
    padding: 5px;
    margin-bottom: 15px;
  }

  &__breakdowns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__breakdown-chart {
    flex: 1;
    min-width: 430px;
    padding: 5px;
  }
}

</style>
