<template>
  <div
    id="app"
    class="app"
  >
    <Maintenance v-if="maintenanceMode" />

    <!-- Login -->
    <Login v-else-if="userRequested && !user && !userNotFound" />

    <!-- Main app -->
    <main
      v-else-if="userRequested"
      class="application"
    >
      <!-- Side Navigation Aside -->
      <SideNav
        v-show="!isExperienceBuilderActive"
        :siteId="siteId"
      />

      <div class="view-container">
        <section class="main-view">

          <!-- Top Navigation -->
          <TopNav
            v-show="!isExperienceBuilderActive"
            v-if="user"
            :user="user"
          />

          <LoadingNotifier
            v-show="!isExperienceBuilderActive"
            v-if="(user || isLoading)"
            :is-active="isLoading"
          ></LoadingNotifier>

          <!-- App Welcome Message -->
          <AppWelcome
            v-if="(!user || !accounts.length) && !isLoading"
            :user="user"
            :accounts="accounts"
          />

          <!-- Router View -->
          <router-view
            v-if="user && accounts.length > 0"
            :user="user"
          />
        </section>
      </div>

      <!-- Error Manager -->
      <ErrorManager />
      <!-- Site Lock Modal -->
      <SiteLockModal />
    </main>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';
import Login from '@/Login';
import TopNav from '@/AppTopNav';
import LoadingNotifier from '@/components/LoadingNotifier';
import ErrorManager from '@/AppErrors';
import SideNav from '@/AppSideNav';
import AppWelcome from '@/AppWelcome';
import Maintenance from '@/Maintenance';
import { bus } from '@/main';
import { events } from '@/events/events';
import { ElementClicked } from '@/events/ui';
import SiteLockModal from '@/components/SiteLockModal';
import auth from './modules/auth';

export default {
  name: 'app',
  components: {
    Login,
    TopNav,
    LoadingNotifier,
    ErrorManager,
    Maintenance,
    SideNav,
    AppWelcome,
    SiteLockModal
  },
  computed: {
    // Get the user
    user() {
      return this.$store.state.user;
    },
    userNotFound() {
      return this.$store.state.userNotFound;
    },
    userRequested: sync('userRequested'),
    accounts() {
      return (this.$store.state.user !== null) ? this.user.accounts : [];
    },
    // Get the current site
    siteId() {
      return this.$store.state.siteId;
    },
    // Check if we are making any requests
    isLoading() {
      return this.$store.state.axiosActive;
    },
    // Check maintenance mode
    maintenanceMode() {
      return this.$store.state.maintenanceMode;
    },
    isExperienceBuilderActive() {
      return this.$store.getters.isExperienceBuilder(this.$route.name);
    }
  },
  async created() {
    bus.$on('elem-click', (e) => {
      if (e && e.currentTarget.id) {
        const data = events[e.currentTarget.id];
        if (data) {
          const htmlId = e.currentTarget.id;
          data.htmlId = htmlId;
          if (window.tagular) {
            window.tagular('beam', ElementClicked(data));
          }
        }
      }
    });
    await this.authUser();
    this.userRequested = true;
  },
  methods: {
    async authUser() {
      try {
        // Check for authorization on startup.
        auth.checkAuth();
        await this.$store.dispatch('getCurrentUser');

        // At this point, there are three scenarios
        // 1. The user authorized successfully, finish app init and route to main app
        if (this.user) {
          this.addCohesionSnippet();
          this.setUpFS();

          // Get hash route from local storage
          const hashRouteKey = 'tmp_auth_redirect_hash';
          let redirect = localStorage.getItem(hashRouteKey);
          localStorage.removeItem(hashRouteKey);

          if (redirect === '/' || this.$route.path === '/') {
            redirect = '/traffic-flows';
          }

          if (redirect) this.$router.push(redirect);
          return;
        }
        // 2. The token was authenticated but the user does not exist and is unauthorized
        // Route to main app, the welcome page will handle this case
        if (this.userNotFound) {
          if (this.$route.path !== '/traffic-flows') {
            this.$router.push('/traffic-flows');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    addCohesionSnippet() {
      const cohesionScript = document.createElement('script');
      cohesionScript.innerHTML = `!function(co,h,e,s,i,o,n){var d='documentElement';var a='className';h[d][a]+=' preampjs fusejs';
      n.k=e;co._Cohesion=n;co._Preamp={k:s,start:new Date};co._Fuse={k:i};co._Tagular={k:o};
      [e,s,i,o].map(function(x){co[x]=co[x]||function(){(co[x].q=co[x].q||[]).push([].slice.call(arguments))}});
      h.addEventListener('DOMContentLoaded',function(){co.setTimeout(function(){
      var u=h[d][a];h[d][a]=u.replace(/ ?preampjs| ?fusejs/g,'')},3e3);
      co._Preamp.docReady=co._Fuse.docReady=!0});var z=h.createElement('script');
      z.async=1;z.src='https://cdn.cohesionapps.com/cohesion/cohesion-latest.min.js';h.head.appendChild(z);}
      (window,document,'cohesion','preamp','fuse','tagular',{
        tagular: {
          apiKey: 'b1f589d1-e4f4-4fa3-a50a-1919846ac48f',
          sourceKey: 'src_1NT29TFjMcVUv5crTRGj1kry8ru',
          writeKey: 'wk_1NT29QxZAriEgUr1E1iVHJGVyuo',
          apiVersion: 'v2/t',
          domainWhitelist: ['redventures.okta.com', 'redventures-dev.auth0.com', 'redventures-prod.auth0.com'],
          taggy: {
            enabled: true
          }
        },
        preamp: {
          siteId: window.location.href.indexOf('-staging') !== -1
            ? '0V4gjHAOSMyOmsLsX1RRFB'
            : '0KwzFv10I7Qpnu9JpPe0qH'
        },
        monarch: {
          sourceId: '2db4721f-9f9d-4fc7-a27e-d0de7f703341',
          token: '1c930dfd-ca44-4f49-920a-3f4c5a88643b',
          autoRequest: {
            enabled: true,
            timeout: 15000,
          },
        },
        hooks: {
          beforeRun:  function(config, done) {
            // Make changes to the global configuration before the client library initializes.
            // Disable Preamp changes to the site for tests and local development.
            const { preamp } = config;
            preamp.enabled = window.location.hostname !== 'localhost';
            done();
          }
        }
      });`;
      document.querySelector('head').appendChild(cohesionScript);
    },
    setUpFS() {
      window.cohesion('monarch:ready', () => {
        window.monarch('rule', 'fullstory', { user: this.$store.get('user') }, {}, (error, result) => {
          if (result.fullstory) {
            // Load FullStory snippet.
            const script = document.createElement('script');
            script.innerHTML = `
              window['_fs_debug'] = false;
              window['_fs_host'] = 'fullstory.com';
              window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
              window['_fs_org'] = 'F36P';
              window['_fs_namespace'] = 'FS';
              (function(m,n,e,t,l,o,g,y){
                if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
                g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
                o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
                y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
                g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
                g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
                g.log = function(a,b) { g("log", [a,b]) };
                g.consent=function(a){g("consent",!arguments.length||a)};
                g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
                g.clearUserCookie=function(){};
              })(window,document,window['_fs_namespace'],'script','user');
            `;
            document.querySelector('head').appendChild(script);

            // Identify our user for FullStory tracking.
            if (window.FS && window.FS.identify !== undefined) {
              window.FS.identify(this.$store.get('user@email'), {
                displayName: `${this.$store.get('user@firstName')} ${this.$store.get('user@lastName')}`,
                application_str: 'Preamp', // eslint-disable-line
                sessionId_str: window._Cohesion && window._Cohesion.sessionId ? window._Cohesion.sessionId : 'N/A' // eslint-disable-line
              });
            }
          }
        });
      });
    }
  }
};
</script>

<style lang="scss">
  @import './assets/scss/project.scss';

  .welcome-container {
    padding: 15px 30px;
  }
</style>
