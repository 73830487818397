<template>
  <!-- Site Lock Error Modal -->
  <b-modal
    :active="isVisible"
    class="site-lock-modal"
  >
    <div class="box">
      <div class="content">
        <p>{{ message }}</p>
      </div>
      <hr>
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <button
              class="button"
              @click="handleLeave"
            >Leave</button>
          </div>
        </div><!-- /.level-left -->

        <div class="level-right">
          <div class="level-item">
            <button
              class="button is-info"
              :class="{ 'is-loading': isAttemptingNewLock }"
              @click="attemptNewLock"
            >Retry</button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { bus } from '@/main';

export default {
  name: 'SiteLockModal',
  data() {
    return {
      isAttemptingNewLock: false,
      isVisible: false,
      message: 'Your site lock is invalid. Click "Retry" below to attempt retrieving another lock or click "Leave" to lose your changes and be redirected.'
    };
  },
  computed: {
    siteLock() {
      return this.$store.state.siteLock;
    }
  },
  created() {
    bus.$on('site-lock-lost-poll', () => {
      this.isVisible = true;
    });
  },
  methods: {
    attemptNewLock() {
      this.isAttemptingNewLock = true;
      this.$root.establishSiteLock().then(() => {
        this.isAttemptingNewLock = false;
        this.isVisible = false;
      });
    },
    handleLeave() {
      // Emit event for views to handle leaving
      bus.$emit('site-lock-lost-user');
      this.isVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.site-lock-modal {
  // Arbitrary z index to ensure it's on top
  z-index: 1000;
}
</style>
