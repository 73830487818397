<template>
  <div class="card-content">
    <h4 class="title" data-cy-test="ff-update-title">
      Update Asset
    </h4>

    <article
      v-if="hasLiveExperiences"
      class="message is-warning"
    >
      <div class="message-body">
        <p>This asset is live in an experience or test. By saving these changes, you will be modifying live experiences.</p>
      </div>
    </article>

    <div class="asset-name-wrapper" data-cy-test="ff-name-section">
      <h5 class="section-title" data-cy-test="ff-name-section-title">Variable Updates</h5>
      <p class="section-description" data-cy-test="ff-name-section-description">Changes have been made for the following asset.</p>
      <b-field
        :type="nameError.isError ? 'is-danger' : ''"
        :message="nameError.msg"
      >
        <b-input
          v-model="editingAsset.name"
          data-cy-test="ff-name-input"
          expanded
          placeholder="Enter a value"
          @input="resetNameErr"
          @blur="handleBlurName"
        />
      </b-field>
    </div>

    <div>
      <div v-if="showAdded" class="ff-variable-section" data-cy-test="added-variables">
        <h5 class="section-title" data-cy-test="added-title">New Variables ({{ assetsDiff.variables.added.length }})</h5>
        <div class="added-wrapper">
          <article
            class="message is-success"
          >
            <div class="message-body">
              <p data-cy-test="added-message">The following variables have been added from the base asset.</p>
            </div>
          </article>
          <div
            v-for="name of assetsDiff.variables.added"
            :key="name"
            class="variable-configuration"
          >
            <component
              :is="`configure-${addedVars[name].type}`"
              v-model="addedVars[name]"
              :variable-name="name"
            />
          </div>
        </div>
      </div>

      <div v-if="showUpdated" class="ff-variable-section" data-cy-test="updated-variables">
        <h5 class="section-title" data-cy-test="updated-title">Updated Variables ({{ assetsDiff.variables.updated.length }})</h5>
        <div class="updated-wrapper">
          <article
            class="message is-info"
          >
            <div class="message-body">
              <p data-cy-test="updated-message">The following variables have been updated from the base asset.</p>
            </div>
          </article>
          <div
            v-for="name of assetsDiff.variables.updated"
            :key="name"
            class="variable-configuration"
          >
            <component
              :is="`configure-${updatedVars[name].type}`"
              v-model="updatedVars[name]"
              :variable-name="name"
            />
          </div>
        </div>
      </div>

      <div v-if="showRemoved" class="ff-variable-section" data-cy-test="removed-variables">
        <h5 class="section-title" data-cy-test="removed-title">Removed Variables ({{ assetsDiff.variables.removed.length }})</h5>
        <div class="removed-wrapper">
          <article
            class="message is-danger"
          >
            <div class="message-body">
              <p data-cy-test="removed-message">The following variables have been removed from the base asset.</p>
            </div>
          </article>
          <div
            v-for="name of assetsDiff.variables.removed"
            :key="name"
            class="variable-configuration"
          >
            <component
              :is="`configure-${duplicateAssetVars[name].type}`"
              v-model="duplicateAssetVars[name]"
              :variable-name="name"
              :disabled="true"
            />
          </div>
        </div>
      </div>

      <div v-if="Object.keys(carryOverVars).length" class="ff-variable-section" data-cy-test="carryOver-variables">
        <h5 class="section-title" data-cy-test="carryOver-title">Carried Over Variables ({{ Object.keys(carryOverVars).length }})</h5>
        <div class="carry-over-wrapper">
          <article
            class="message carry-over-message"
          >
            <div class="message-body">
              <p data-cy-test="carryOver-message">The following variables have been carried over from the base asset.</p>
            </div>
          </article>
          <div
            v-for="name of Object.keys(carryOverVars)"
            :key="name"
            class="variable-configuration"
          >
            <component
              :is="`configure-${carryOverVars[name].type}`"
              v-model="carryOverVars[name]"
              :variable-name="name"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="level">
      <div class="level-left"></div>
      <div class="level-right">
        <div class="level-item">
          <div class="level-item">
            <b-button
              type="is-ghost"
              data-cy-test="ff-update-button-cancel"
              @click="$emit('cancel')"
            >
              Cancel
            </b-button>
          </div>
          <b-button
            :disabled="isMissingValues"
            data-cy-test="ff-update-button-preview"
            type="is-info"
            @click="$emit('forward')"
          >
            Preview Update
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BooleanVariable from '../VariableInputs/BooleanVariable.vue';
import EnumVariable from '../VariableInputs/EnumVariable.vue';
import NumberVariable from '../VariableInputs/NumberVariable.vue';
import StringVariable from '../VariableInputs/StringVariable.vue';

  export default {
    name: 'UpdateStep',
    components: {
      'configure-boolean': BooleanVariable,
      'configure-string': StringVariable,
      'configure-number': NumberVariable,
      'configure-enum': EnumVariable
    },
    props: {
      baseAsset: {
        type: Object,
        required: true
      },
      duplicateAsset: {
        type: Object,
        required: true
      },
      allAssets: {
        type: Array,
        required: true
      },
      assetsDiff: {
        type: Object,
        required: true
      },
      hasLiveExperiences: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        nameError: {
          isError: false,
          msg: ''
        },
        editingAsset: {},
        addedVars: {},
        updatedVars: {},
        carryOverVars: {}
      };
    },
    computed: {
      isMissingValues() {
        this.handleBlurName();
        return this.nameError.isError;
      },
      proposedVariables() {
      // these are the values that will be used when the asset is updated
      let proposed = {};
        for (const [variable, values] of Object.entries(this.addedVars)) {
          proposed = {
            ...proposed,
            [variable]: values
          };
        }
        for (const [variable, values] of Object.entries(this.updatedVars)) {
          proposed = {
            ...proposed,
            [variable]: values
          };
        }

        for (const [variable, values] of Object.entries(this.carryOverVars)) {
          proposed = {
            ...proposed,
            [variable]: values
          };
        }

        return proposed;
      },
      duplicateAssetVars() {
        const dupeVars = Object.keys(this.duplicateAsset.variables) ? this.duplicateAsset.variables : {};
        return JSON.parse(dupeVars);
      },
      baseAssetVars() {
        const baseVars = Object.keys(this.baseAsset.variables) ? this.baseAsset.variables : {};
        return JSON.parse(baseVars);
      },
      showDiff() {
        return this.showAdded || this.showRemoved || this.showUpdated;
      },
      showAdded() {
        return this.assetsDiff && this.assetsDiff.variables && this.assetsDiff.variables.added.length;
      },
      showRemoved() {
        return this.assetsDiff && this.assetsDiff.variables && this.assetsDiff.variables.removed.length;
      },
      showUpdated() {
        return this.assetsDiff && this.assetsDiff.variables && this.assetsDiff.variables.updated.length;
      }
    },
    watch: {
      proposedVariables(val) {
        this.$emit('change', val);
      },
      'editingAsset.name': function (name) {
        this.$emit('nameUpdate', name);
      }
    },
    created() {
      this.initEditingAsset();
    },
    methods: {
      initEditingAsset() {
        this.editingAsset = {...this.duplicateAsset};
        this.editingVariables = JSON.parse(this.duplicateAsset.variables);

        this.assetsDiff.variables.added.forEach((varName) => {
            this.addedVars = {
                ...this.addedVars,
              [varName]: {
                ...this.baseAssetVars[varName]
              }
            };
        });
        this.assetsDiff.variables.updated.forEach((varName) => {
            this.updatedVars = {
              ...this.updatedVars,
              [varName]: {
                ...this.baseAssetVars[varName]
              }
            };
        });

        // get unchanged variables from the base if the base still has variables
        if (this.baseAsset.variables !== undefined) {
            const duplicateVars = Object.keys(this.duplicateAsset.variables) ? JSON.parse(this.duplicateAsset.variables) : {};
            Object.entries(duplicateVars).forEach(([varName, varValue]) => {
                // only list carry over vars if they aren't one of the other states
                if (!Object.keys(this.updatedVars).includes(varName) && !Object.keys(this.addedVars).includes(varName) && !this.assetsDiff.variables.removed.includes(varName)) {
                    this.carryOverVars = {
                        ...this.carryOverVars,
                        [varName]: varValue
                    };
                }
            });
        }
      },
      handleBlurName() {
        if (!this.editingAsset.name) {
          this.nameError = {
            isError: true,
            msg: 'Name cannot be blank'
          };
          return;
        }
        if (this.editingAsset.name.length >= 128) {
          this.nameError = {
            isError: true,
            msg: 'Name must be fewer than 128 characters'
          };
        }
        const duplicateName = this.allAssets
          .find(a => (a.name !== this.duplicateAsset.name) && a.name.toLowerCase().trim() === this.editingAsset.name.toLowerCase().trim());
        if (duplicateName) {
          this.nameError = {
            isError: true,
            msg: `${duplicateName.name} already exists`
          };
        }
      },
      resetNameErr() {
        this.nameError = {
          isError: false,
          msg: ''
        };
      }
    }
  };
  </script>

  <style scoped lang="scss">
  .ff-variable-section {
   display: flex;
   flex-direction: column;
   background-color: #F6F7F9;
   padding: 16px;
   border-radius: 5px;
  }

  .asset-name-wrapper {
    background-color: #F6F7F9;
    padding: 16px;
    border-radius: 5px;
    margin-bottom: 24px;
  }
  .added-wrapper {
    border: 1px solid #14B889BF;
    border-radius: 5px;
    padding: 16px;
    margin-top: 24px;
  }
  .updated-wrapper {
    border: 1px solid #0696EF;
    border-radius: 5px;
    padding: 16px;
    margin-top: 24px;
  }
  .removed-wrapper {
    border: 1px solid #FF6666;
    border-radius: 5px;
    padding: 16px;
    margin-top: 24px;
  }
  .carry-over-wrapper {
    border: 1px solid #DEE4EA;
    border-radius: 5px;
    padding: 16px;
    margin-top: 24px;
  }
  .carry-over-message {
    background-color: #DEE4EA;

    :deep(.message-body) {
        color: #333D47;
        border-color: #AEB7C2;
    }
  }

  .variable-configuration:not(:last-child) {
    margin-bottom: 24px;
  }
  </style>

