<template>
  <section>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-9">
        <article class="tile is-child box chart-parent">
          <nav class="level">
            <div class="level-left">
              <div class="level-item">
                <h5 class="title is-5">
                  Decisions Created With This {{ itemType }} {{ lookbackText.phrase }}
                </h5>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <LookbackRangePicker
                  :options="lookbackOptions"
                  :formatter="false"
                  :is-loading="isLoading"
                  tip-position="is-left"
                  @selection="val => lookbackRange = val"
                  @refresh="getInsights"
                />
              </div>
            </div>
          </nav><!-- /.level -->

          <div class="content is-relative chart-parent">
            <line-chart
              v-if="chartData"
              :data="chartData"
              label="Decisions"
              :messages="{ 'empty': 'No data found for this time range.' }"
              :library="chartOptions"
              thousands=","
            />

            <b-loading
              :active.sync="isLoading"
              :is-full-page="false"
            ></b-loading>
          </div><!-- /.content -->
        </article>
      </div><!-- /.tile .is-9 -->

      <div class="tile is-vertical is-parent">
        <article class="tile is-child notification is-info">
          <div class="content">
            <h2 class="title is-2">{{ numberWithCommas(totalTraffic) }}</h2>
            <p class="subtitle is-6">
              Total Decisions Created Over {{ lookbackText.range }}
            </p>

            <hr>

            <p>
              Only decisions containing <strong>{{ itemName }}</strong> are shown in this visualization.
            </p>

            <hr>

            <p>
              These numbers are based on decisions created and are <strong>not</strong> 1:1 with sessions.
            </p>

            <p>
              How close are sessions to decisions created for <strong>{{ siteDomain }}</strong>?
              <a
                href="https://redventures.atlassian.net/wiki/spaces/COHSN/pages/74429352/Audience+Insights"
                target="_blank"
              >See Here</a>
            </p>
          </div><!-- /.content -->
        </article>
      </div><!-- /.tile -->
    </div><!-- /.tile .is-ancestor -->
  </section>
</template>

<script>
import { DateTime } from 'luxon';
import { fireInsightsEvent } from '@/events/events';
import { numberWithCommas } from '@/modules/utilities';
import LookbackRangePicker from '@/components/Insights/LookbackRangePicker';

export default {
  name: 'IndividualHitsOverTime',
  components: {
    LookbackRangePicker
  },
  props: ['siteId', 'payload', 'itemName', 'itemType'],
  data() {
    return {
      lookbackOptions: ['1h', '1d', '7d', '30d'],
      lookbackRange: '1d',
      chartData: null,
      totalTraffic: 0,
      // flags
      isLoading: false,
      hasNumbersViewSelected: false
    };
  },
  computed: {
    siteDomain() {
      return this.$store.getters.siteDomain;
    },
    lookbackText() {
      let interval = '';
      let range = '';

      switch (this.lookbackRange) {
        case '1h':
          interval = 'Per Minute';
          range = '1 Hour';
          break;
        case '1d':
          interval = 'Per Hour';
          range = '1 Day';
          break;
        case '7d':
          interval = 'Per Day';
          range = '7 Days';
          break;
        case '30d':
          interval = 'Per Day';
          range = '30 Days';
          break;
        default:
          // return early
          return { range, interval, phrase: 'Over Time' };
      }

      return {
        range,
        interval,
        phrase: `${interval} Since ${range} Ago`
      };
    },
    chartOptions() {
      let unit = 'day';

      if (this.lookbackRange === '1d') {
        unit = 'hour';
      } else if (this.lookbackRange === '1h') {
        unit = 'minute';
      }

      return {
        responsive: true,
        scales: {
          x: {
            type: 'time',
            time: {
              unit
            }
          }
        }
      };
    },
    interval() {
      let result = '1d';

      if (this.lookbackRange === '1d') {
        result = '1h';
      } else if (this.lookbackRange === '1h') {
        result = '1m';
      }

      return result;
    },
    // used for tagular tracking
    trackingText() {
      if (!this.lookbackRange) return '';

      return `${this.interval.toUpperCase()} | ${this.lookbackRange.toUpperCase()} | ${this.hasNumbersViewSelected ? '#' : '%'} | PER DAY`;
    },
    hasBadPayload() {
      return !this.payload || (!this.payload['audienceId.keyword'] && !this.payload['experienceId.keyword'] && !this.payload['testId.keyword']);
    }
  },
  watch: {
    lookbackRange(newRange) {
      if (newRange) {
        this.getInsights();
      }
    },
    trackingText(newText) {
      fireInsightsEvent(newText);
    }
  },
  created() {
    if (!this.chartData) {
      this.getInsights();
    }
  },
  methods: {
    numberWithCommas,
    async getInsights() {
      // stop here if payload is invalid
      if (this.hasBadPayload) {
        return this.$store.commit('error', new Error('The necessary properties to fetch insights data was not found. If this problem persists, reach out to the Cohesion team for assistance.'));
      }

      this.isLoading = true;

      try {
        const res = await this.$axios.post(`/sites/${this.siteId}/insights/interval/${this.interval}/${this.lookbackRange}`, {
          term: this.payload
        });
        const chartData = {};
        for (const d of Object.keys(res.data.counts)) {
          const dt = DateTime.fromISO(d).toISO();

          chartData[dt] = res.data.counts[d];
        }
        this.chartData = chartData;
        this.totalTraffic = res.data.total;
      } catch (error) {
        this.$store.commit('error', error);
      }

      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.notification {
  padding-right: 1.5rem; // fixes bulma's extra padding

  .chart-parent {
    height: 100%;
    width: 100%;
  }

  &.is-info {
    .content {
      strong {
        color: white;
      }
    }
  }
}
</style>
