<template>
  <article class="media">
    <div class="media-content">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            {{ server }}
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-tooltip
              :label="`Refresh the currently selected site's cache on ${server}`"
              type="is-dark"
              animated
            >
              <b-button
                size="is-small"
                :type="(isRefreshSuccess) ? 'is-success' : 'is-info'"
                :loading="isRefreshLoading"
                :icon-left="(isRefreshSuccess) ? 'check' : 'refresh'"
                @click="refreshDecisionServer"
              >Refresh Current Site</b-button>
            </b-tooltip>
          </div>

          <div v-if="isSuper" class="level-item">
            <b-tooltip
              :label="`Reset site cache for all sites on ${server}`"
              type="is-dark"
              animated
            >
              <b-button
                size="is-small"
                :type="(isResetSuccess) ? 'is-success' : 'is-warning'"
                :loading="isResetLoading"
                :icon-left="(isResetSuccess) ? 'check' : 'undo'"
                @click="resetDecisionServer"
              >Reset Full Site Cache</b-button>
            </b-tooltip>
          </div>

          <div class="level-item">
            <b-button
              :disabled="isDisabled"
              size="is-small"
              type="is-danger"
              icon-left="trash"
              @click="$emit('remove-server', server)"
            >Delete</b-button>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'RegionServer',
  props: {
    server: {
      type: String,
      required: true
    },
    siteId: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isRefreshLoading: false,
      isRefreshSuccess: false,
      isResetLoading: false,
      isResetSuccess: false
    };
  },
  computed: {
    // Is the user a super user
    isSuper() {
      return this.$store.getters.isSuperUser;
    }
  },
  methods: {
    async refreshDecisionServer() {
      this.isRefreshLoading = true;
      try {
        await this.$axios.post(`/sites/${this.siteId}/refresh-cache`, { decisionServer: this.server });
        // stop button spinner
        this.isRefreshLoading = false;
        // change button color
        this.isRefreshSuccess = true;
        // reset button color after 700ms
        setTimeout(() => { this.isRefreshSuccess = false; }, 700);
      } catch (err) {
        this.isRefreshLoading = false;
        this.$store.commit('error', err, `Could not refresh decision server: ${this.server}`);
      }
    },
    async resetDecisionServer() {
      this.isResetLoading = true;
      try {
        await this.$axios.post(`/decisionServer/reset-site-cache`, { decisionServer: this.server });
        // change button color
        this.isResetSuccess = true;
        // reset button color after 700ms
        setTimeout(() => { this.isResetSuccess = false; }, 700);
      } catch (err) {
        this.$store.commit('error', err, `Could not reset decision server site cache: ${this.server}`);
      } finally {
        this.isResetLoading = false;
      }
    }
  }
};
</script>
