/* eslint-disable */

// List out the hours manually to make assignments very clear.
// Start with 12AM so it's easy for the user
const hours = [
  { value: 0, label: '12 AM' },
  { value: 1,  label:  '1 AM' },
  { value: 2,  label:  '2 AM' },
  { value: 3,  label:  '3 AM' },
  { value: 4,  label:  '4 AM' },
  { value: 5,  label:  '5 AM' },
  { value: 6,  label:  '6 AM' },
  { value: 7,  label:  '7 AM' },
  { value: 8,  label:  '8 AM' },
  { value: 9,  label:  '9 AM' },
  { value: 10, label: '10 AM' },
  { value: 11, label: '11 AM' },
  { value: 12, label: '12 PM' },
  { value: 13, label:  '1 PM' },
  { value: 14, label:  '2 PM' },
  { value: 15, label:  '3 PM' },
  { value: 16, label:  '4 PM' },
  { value: 17, label:  '5 PM' },
  { value: 18, label:  '6 PM' },
  { value: 19, label:  '7 PM' },
  { value: 20, label:  '8 PM' },
  { value: 21, label:  '9 PM' },
  { value: 22, label: '10 PM' },
  { value: 23, label: '11 PM' }
];

const days = [...Array(32).keys()].slice(1, 32).map(d => ({ value: d, label: d }));
const daysOfWeek = [
  { value: 0, label: 'Sunday'},
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday'},
  { value: 3, label: 'Wednesday'},
  { value: 4, label: 'Thursday'},
  { value: 5, label: 'Friday'},
  { value: 6, label: 'Saturday'}
];

const months = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' }
];

const specialPaths = ['request.query.', 'currentRequest.query.', 'referrer.query.', 'currentReferrer.query.', 'cookies.', 'custom.', 'dmp.', 'dna.', 'dnaxs.', 'segment.', 'segmentxs.', 'jarvis.'];
const specialLabels = ['firstRequest.query.', 'currentRequest.query.', 'firstReferrer.query.', 'currentReferrer.query.', 'cookies.', 'custom.', 'dmp.', 'dna.', 'dnaxs.', 'segment.', 'segmentxs.', 'jarvis.'];

const comparators = {
  eq: { value: 'eq', label: 'equals', id: 'comparator-eq' },
  neq: { value: 'neq', label: 'does not equal' },
  gt: { value: 'gt', label: 'is greater than' },
  gte: { value: 'gte', label: 'is greater than or equal to' },
  lt: { value: 'lt', label: 'is less than' },
  lte: { value: 'lte', label: 'is less than or equal to' },
  contains: { value: 'contains', label: 'contains' },
  ncontains: { value: 'ncontains', label: 'does not contain' },
  exists: { value: 'exists', label: 'is true' },
  nexists: { value: 'nexists', label: 'is false' },
  matches: { value: 'matches', label: 'regex matches', id: 'comparator-eq' },
  in: { value: 'in', label: 'is in' },
  nin: { value: 'nin', label: 'is not in' }
};

const allComparators = Object.values(comparators);

const groupedComparators = {
  'String': [comparators.eq, comparators.neq, comparators.contains, comparators.ncontains, comparators.in, comparators.nin],
  'Number': [comparators.eq, comparators.neq, comparators.gt, comparators.gte, comparators.lt, comparators.lte, comparators.in, comparators.nin],
  'Boolean': [comparators.exists, comparators.nexists],
  'Date': [comparators.eq, comparators.neq, comparators.gt, comparators.gte, comparators.lt, comparators.lte],
  'RegExp': [comparators.matches],
  'Array': [comparators.contains, comparators.ncontains]
};

const allRules = [
  { value: 'channel.isPaid', label: 'channel.isPaid', boolean: true },
  { value: 'channel.isNatural', label: 'channel.isNatural', boolean: true },
  { value: 'channel.isReferrer', label: 'channel.isReferrer', boolean: true },
  { value: 'channel.isDirectEntry', label: 'channel.isDirectEntry', boolean: true },
  { value: 'channel.isGoogle', label: 'channel.isGoogle', boolean: true },
  { value: 'channel.isBing', label: 'channel.isBing', boolean: true },
  { value: 'channel.isYahoo', label: 'channel.isYahoo', boolean: true },

  { value: 'custom.<ATTRIBUTE_HERE>', label: 'custom.<ATTRIBUTE_HERE>', invalid: 'custom.<ATTRIBUTE_HERE>' },

  { value: 'dmp.<PROJECTOR_NAME_HERE>.<ATTRIBUTE_NAME_HERE>', label: 'dmp.<PROJECTOR_NAME_HERE>.<ATTRIBUTE_NAME_HERE>', invalid: 'dmp.<PROJECTOR_NAME_HERE>.<ATTRIBUTE_NAME_HERE>' },

  { value: 'dna.<AUDIENCE_OR_TRAIT_NAME>', label: 'dna.<AUDIENCE_OR_TRAIT_NAME>', invalid: 'dna.<AUDIENCE_OR_TRAIT_NAME>' },
  { value: 'dnaxs.<AUDIENCE_OR_TRAIT_NAME>', label: 'dnaxs.<AUDIENCE_OR_TRAIT_NAME>', invalid: 'dnaxs.<AUDIENCE_OR_TRAIT_NAME>' },

  { value: 'segment.<SEGMENT_TRAIT>', label: 'segment.<SEGMENT_TRAIT>', invalid: 'segment.<SEGMENT_TRAIT>' },
  { value: 'segmentxs.<SEGMENT_TRAIT>', label: 'segmentxs.<SEGMENT_TRAIT>', invalid: 'segmentxs.<SEGMENT_TRAIT>' },

  { value: 'content.currentRequest.tags', label: 'content.currentRequest.tags', array: true },
  { value: 'content.currentReferrer.tags', label: 'content.currentReferrer.tags', array: true },
  { value: 'content.firstRequest.tags', label: 'content.firstRequest.tags', array: true },
  { value: 'content.firstReferrer.tags', label: 'content.firstReferrer.tags', array: true },

  { value: 'jarvis.<JARVIS_PIPELINE>', label: 'jarvis.<JARVIS_PIPELINE>', invalid: 'jarvis.<JARVIS_PIPELINE>' },

  { value: 'request.url', label: 'firstRequest.url' },
  { value: 'request.protocol', label: 'firstRequest.protocol' },
  { value: 'request.hostname', label: 'firstRequest.hostname' },
  { value: 'request.port', label: 'firstRequest.port' },
  { value: 'request.path', label: 'firstRequest.path' },
  { value: 'request.queryString', label: 'firstRequest.queryString' },
  { value: 'request.query.<PARAM_NAME_HERE>', label: 'firstRequest.query.<PARAM_NAME_HERE>', invalid: 'request.query.<PARAM_NAME_HERE>' },
  { value: 'request.hash', label: 'firstRequest.hash' },
  { value: 'request.query.utm_mcid', label: 'firstRequest.query.utm_mcid'},

  { value: 'currentRequest.url', label: 'currentRequest.url' },
  { value: 'currentRequest.protocol', label: 'currentRequest.protocol' },
  { value: 'currentRequest.hostname', label: 'currentRequest.hostname' },
  { value: 'currentRequest.port', label: 'currentRequest.port' },
  { value: 'currentRequest.path', label: 'currentRequest.path' },
  { value: 'currentRequest.queryString', label: 'currentRequest.queryString' },
  { value: 'currentRequest.query.<PARAM_NAME_HERE>', label: 'currentRequest.query.<PARAM_NAME_HERE>', invalid: 'currentRequest.query.<PARAM_NAME_HERE>' },
  { value: 'currentRequest.hash', label: 'currentRequest.hash' },
  { value: 'currentRequest.query.utm_mcid', label: 'currentRequest.query.utm_mcid'},

  { value: 'referrer.url', label: 'firstReferrer.url' },
  { value: 'referrer.protocol', label: 'firstReferrer.protocol' },
  { value: 'referrer.hostname', label: 'firstReferrer.hostname' },
  { value: 'referrer.port', label: 'firstReferrer.port' },
  { value: 'referrer.path', label: 'firstReferrer.path' },
  { value: 'referrer.queryString', label: 'firstReferrer.queryString' },
  { value: 'referrer.query.<YOUR_PARAM_HERE>', label: 'firstReferrer.query.<YOUR_PARAM_HERE>', invalid: 'referrer.query.<YOUR_PARAM_HERE>' },
  { value: 'referrer.hash', label: 'firstReferrer.hash' },

  { value: 'currentReferrer.url', label: 'currentReferrer.url' },
  { value: 'currentReferrer.protocol', label: 'currentReferrer.protocol' },
  { value: 'currentReferrer.hostname', label: 'currentReferrer.hostname' },
  { value: 'currentReferrer.port', label: 'currentReferrer.port' },
  { value: 'currentReferrer.path', label: 'currentReferrer.path' },
  { value: 'currentReferrer.queryString', label: 'currentReferrer.queryString' },
  { value: 'currentReferrer.query.<PARAM_NAME_HERE>', label: 'currentReferrer.query.<PARAM_NAME_HERE>', invalid: 'currentReferrer.query.<PARAM_NAME_HERE>' },
  { value: 'currentReferrer.hash', label: 'currentReferrer.hash' },
  { value: 'currentReferrer.query.utm_mcid', label: 'currentReferrer.query.utm_mcid'},

  { value: 'cookies.<COOKIE_NAME_HERE>', label: 'cookies.<COOKIE_NAME_HERE>', invalid: 'cookies.<COOKIE_NAME_HERE>' },

  { value: 'geo.city', label: 'geo.city' },
  { value: 'geo.region', label: 'geo.region' },
  { value: 'geo.postalCode', label: 'geo.postalCode' },
  { value: 'geo.country', label: 'geo.country' },
  { value: 'geo.latitude', label: 'geo.latitude' },
  { value: 'geo.longitude', label: 'geo.longitude' },
  { value: 'geo.ispName', label: 'geo.ispName' },
  { value: 'geo.ip', label: 'geo.ip' },

  { value: 'userAgent.isDesktop', label: 'userAgent.isDesktop', boolean: true },
  { value: 'userAgent.isMobile', label: 'userAgent.isMobile', boolean: true },
  { value: 'userAgent.isTablet', label: 'userAgent.isTablet', boolean: true },
  { value: 'userAgent.isBot', label: 'userAgent.isBot', boolean: true },
  { value: 'userAgent.browserName', label: 'userAgent.browserName' },
  { value: 'userAgent.browserVersion', label: 'userAgent.browserVersion' },
  { value: 'userAgent.osName', label: 'userAgent.osName' },
  { value: 'userAgent.marketingName', label: 'userAgent.marketingName' },
  { value: 'userAgent.value', label: 'userAgent.value' },

  { value: 'isReturnVisitor', label: 'isReturnVisitor', boolean: true },

  { value: 'wasRedirected', label: 'wasRedirected', boolean: true },

  { value: 'clientTime.year', label: 'clientTime.year' },
  { value: 'clientTime.month', label: 'clientTime.month', options: months },
  { value: 'clientTime.day', label: 'clientTime.day', options: days },
  { value: 'clientTime.hour', label: 'clientTime.hour', options: hours },
  { value: 'clientTime.minute', label: 'clientTime.minute' },
  { value: 'clientTime.dayOfWeek', label: 'clientTime.dayOfWeek', options: daysOfWeek },
  { value: 'clientTime.utcOffset', label: 'clientTime.utcOffset' },
  { value: 'clientTime.iso', label: 'clientTime.iso' },

  { value: 'serverTime.year', label: 'serverTime.year' },
  { value: 'serverTime.month', label: 'serverTime.month', options: months },
  { value: 'serverTime.day', label: 'serverTime.day', options: days },
  { value: 'serverTime.hour', label: 'serverTime.hour', options: hours },
  { value: 'serverTime.minute', label: 'serverTime.minute' },
  { value: 'serverTime.dayOfWeek', label: 'serverTime.dayOfWeek', options: daysOfWeek },
  { value: 'serverTime.utcOffset', label: 'serverTime.utcOffset' },
  { value: 'serverTime.iso', label: 'serverTime.iso' }
];

const invalidRulePaths = allRules.filter(rules => rules.invalid).map(rule => rule.invalid);

export { allRules, specialPaths, specialLabels, allComparators, groupedComparators, invalidRulePaths, hours };
