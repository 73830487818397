<template>
  <b-modal
    :active.sync="isVisible"
    :can-cancel="['escape']"
  >
    <div class="card bottom10" data-cy-test="modal">
      <header class="card-header">
        <div class="card-header-title" data-cy-test="card-header-title">
          {{ formTitle }}
        </div>
      </header>

      <div class="card-content">
        <section>
          <b-field label="Add-On Name">
            <b-input
              v-model="addOn.name"
              type="text"
              maxlength="500"
              placeholder="Main Add-On"
              data-cy-test="add-on-name"
            />
          </b-field>
        </section>

        <section class="bottom20">
          <b-field label="Add-On Tags">
            <b-taginput
              v-model="addOn.tags"
              :data="filteredTags"
              open-on-focus
              allow-new
              autocomplete
              placeholder="Add a tag"
              data-cy-test="add-on-tag"
              icon="tag"
              @typing="(text) => tagText = text"
              @add="tagText = ''"
              @remove="tagText = ''"
            />
          </b-field>
        </section>

        <section
          v-if="isCreating"
          class="config"
        >
          <section class="bottom20">
            <b-field grouped>
              <b-field label="Add-On Type" />
            </b-field>
            <b-dropdown
              v-model="addOn.type"
              aria-role="list"
            >
              <button
                slot="trigger"
                slot-scope="{ active }"
                class="button is-info"
                data-cy-test="select-type"
              >
                <span>{{ addOn.type ? addOnTypes[addOn.type] : 'Select Type' }}</span>
                <b-icon :icon="active ? 'caret-up' : 'caret-down'"></b-icon>
              </button>

              <b-dropdown-item
                v-for="(name, val, index) in addOnTypes"
                :key="index"
                :value="val"
                aria-role="listitem"
                data-cy-test="dropdown-item"
              >
                {{ name }}
              </b-dropdown-item>
            </b-dropdown>
          </section>

          <section
            v-if="addOn.type === 'filo' && addOn.config"
            class="filo-config"
          >
            <section>
              <b-field label="Filo Context Key">
                <b-input
                  v-model="addOn.config.contextId"
                  type="text"
                  maxlength="500"
                  placeholder="context_key"
                  data-cy-test="filo-context-key"
                />
              </b-field>
            </section>
          </section>

          <section
            v-if="addOn.type === 'routing' && addOn.config"
            class="routing-config"
          >
            <section>
              <b-field label="Destination URL">
                <b-input
                  v-model="addOn.config.destinationURL"
                  type="text"
                  maxlength="500"
                  placeholder="Destination URL"
                  data-cy-test="destination-url"
                />
              </b-field>

              <b-field
                :type="wouldCauseInfiniteRedirect ? 'is-danger' : ''"
                :message="wouldCauseInfiniteRedirect ? 'Destination URL must not match URL match. This will result in an infinite redirect.' : ''"
              >
                <template slot="label">
                  URL Match
                  <b-tooltip
                    position="is-right"
                    type="is-light"
                    size="is-large"
                    animated
                    multilined
                  >
                    <span class="icon has-text-info">
                      <i class="fa fa-question-circle"></i>
                    </span>
                    <template v-slot:content>
                      <p>
                        URL Match determines when the routing add-on will redirect to your Destination URL based on the current URL.
                      </p>
                      <br>
                      <p>URL's will be tested against a pattern you provide here. If the pattern matches, your routing add-on will execute.</p>
                    </template>
                  </b-tooltip>

                  <span
                    class="is-size-7 has-text-weight-light"
                    style="float: right; position: relative; top: 1em"
                  >
                    Wildcards allowed. Use * to match any character for an indeterminate length.
                  </span>
                </template>
                <b-input
                  v-model="addOn.config.urlMatch"
                  type="text"
                  maxlength="500"
                  placeholder="https://*.example.com/blog/*"
                  data-cy-test="url-match"
                />
              </b-field>
            </section>
          </section>
        </section>
      </div>

      <div class="card-footer">
        <div class="level">
          <div class="level-left">
            <div class="level-item" data-cy-test="save-button">
              <base-button-save
                :loading="isSavingAddOn"
                :disabled="!validity.isValid"
                @click="saveAddOn"
              />
            </div>

            <div class="level-item">
              <button
                class="button"
                data-cy-test="cancel-button"
                @click="cancelForm"
              >Cancel</button>
            </div>

            <div
              v-if="!validity.isValid"
              class="level-item"
            >
              <p class="has-text-danger" data-cy-test="error-message">
                <span class="icon">
                  <i class="fa fa-exclamation-circle"></i>
                </span>
                {{ validity.errMsg }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { sync, get } from 'vuex-pathify';
import { hasLength, clone } from '@/modules/utilities';
import esc from 'escape-string-regexp';

export default {
  name: 'Add-Ons-Form',
  data() {
    return {
      wouldCauseInfiniteRedirect: false,
      addOn: {
        name: '',
        config: {},
        type: ''
      },
      tagText: '',
      isSavingAddOn: false,
      addOnTypes: {
        filo: 'Filo',
        routing: 'Routing'
      },
      configTemplates: {
        filo: {
          contextId: ''
        },
        routing: {
          destinationURL: '',
          urlMatch: '',
          regexp: ''
        }
      }
    };
  },
  watch: {
    'addOn.type'(type) {
      this.addOn.config = {
        ...this.configTemplates[type]
      };
    },
    'addOn.config.destinationURL' (destinationURL) {
      this.checkInfiniteRedirect();
    },
    'addOn.config.urlMatch' (pattern) {
      this.updateRegexp();
      this.checkInfiniteRedirect();
    },
    isVisible(bool) {
      if (bool && this.isEditing) {
        this.addOn = {
          ...this.focused
        };
      }
    }
  },
  created() {
    this.$store.dispatch('addOns/getList');
  },
  computed: {
    focused: sync('addOns/focused'),
    isEditing: sync('modals@editAddOn'),
    isCreating: sync('modals@createAddOn'),
    allAddOnTags: get('addOns/allAddOnTags'),
    allAddOnNames: get('addOns/allAddOnNames'),
    filteredTags() {
      return this.allAddOnTags.filter((tag) => {
        if (this.addOn.tags && this.addOn.tags.includes(tag)) return false;
        if (this.tagText && !tag.includes(this.tagText)) return false;
        return true;
      });
    },
    isVisible: {
      get() {
        return this.isEditing || this.isCreating;
      },
      set(bool) {
        if (!bool) {
          this.isEditing = bool;
          this.isCreating = bool;
        }
      }
    },
    formTitle() {
      return this.isCreating ?
        'Create a New Add-On' :
        'Edit an Add-On';
    },
    validity() {
      let errMsg;

      if (this.isCreating) {
        switch (this.addOn.type) {
          case 'filo':
            if (!this.addOn.config.contextId) errMsg = 'Please enter a Filo context key';
            break;
          case 'routing':
            if (!this.addOn.config.urlMatch) errMsg = 'Please enter a valid url match';
            if (!this.addOn.config.destinationURL) errMsg = 'Please enter a destination URL';
            else {
              try {
                new URL(this.addOn.config.destinationURL);
              } catch (err) {
                errMsg = 'Please enter a valid destination URL';
              }
            }
            if (this.wouldCauseInfiniteRedirect) errMsg = 'Destination URL must not match URL match.';
            break;
        }
      }

      if (!this.addOn.type) errMsg = 'Please select an add-on type';
      if (
        this.isCreating && this.allAddOnNames.includes(this.addOn.name.toLowerCase()) ||
        this.isEditing && this.allAddOnNames.filter(name => name !== this.focused.name.toLowerCase()).includes(this.addOn.name.toLowerCase())
      ) errMsg = 'Names must be unique';
      if (!this.addOn.name) errMsg = 'Please provide a name for your add-on';


      return {
        errMsg,
        isValid: !errMsg
      };
    }
  },
  beforeDestroy() {
    this.resetAddOn();
  },
  methods: {
    updateRegexp() {
      if (this.addOn.config.urlMatch) {
        // Escape regexp special characters
        const escaped = esc(this.addOn.config.urlMatch);

        // Replace escaped analyst wildcards '*' with regexp equivalent '.*'
        let regexp = escaped.replace(/\\\*/g, '.*');

        // Require an exact match and allow for an optional trailing slash
        regexp = `^${regexp}${regexp.charAt(regexp.length-1) === '/' ? '?$' : '\\/?$'}`;
        this.addOn.config.regexp = regexp;
      }
    },
    checkInfiniteRedirect() {
      if (this.addOn.type !== 'routing' || !this.addOn.config.destinationURL || !this.addOn.config.urlMatch) return;
      // If our destination url matches our regexp pattern, then we'll have an infinite redirect
      const pattern = RegExp(this.addOn.config.regexp);
      this.wouldCauseInfiniteRedirect = RegExp(pattern).test(this.addOn.config.destinationURL);
    },
    async saveAddOn() {
      if (this.isCreating) {
        const addOn = await this.$store.dispatch('addOns/createAddOn', this.addOn);
        await this.$store.dispatch('addOns/getList');
        this.resetAddOn();
        this.isVisible = false;
      } else if (this.isEditing) {
        delete this.addOn.config;
        const addOn = await this.$store.dispatch('addOns/updateAddOn', this.addOn);
        await this.$store.dispatch('addOns/getList');
        this.resetAddOn();
        this.isVisible = false;
      }
    },
    cancelForm() {
      this.resetAddOn();
      this.isVisible = false;
    },
    resetAddOn() {
      this.addOn = {
        name: '',
        config: {},
        type: ''
      };
    }
  }
};
</script>
