<template>
  <div>
    <h2 class="integration-modal__title">
      Integrations
    </h2>
    <p class="integration-modal__description">
      Choose an integration below to configure it for use in audience rules
    </p>
    <IntegrationOption
      v-for="option in allIntegrationTypes"
      :key="option"
      :isSelected="integrationType === option"
      :integrationType="option"
      @clickIntegrationType="handleIntegrationOptionClick(option)"
    >
    </IntegrationOption>
    <div class="integration-modal__buttons level-right">
      <button
        class="button cancel"
        @click.stop="handleCancelClick"
      >
        Cancel
      </button>

      <button
        class="button is-info"
        :disabled="!integrationType"
        @click="handleConfigureClick"
      >
        Configure Integration
      </button>
    </div>
  </div>
</template>

<script>
import { getSupportedIntegrationTypes } from '../../modules/integrations';
import IntegrationOption from './IntegrationOption.vue';

export default {
  name: 'IntegrationModalStep1',
  components: {
    IntegrationOption
  },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      allIntegrationTypes: getSupportedIntegrationTypes()
    };
  },
   computed: {
    integrationType: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    handleIntegrationOptionClick(integrationType) {
      this.integrationType = integrationType;
    },
    handleCancelClick() {
      this.$emit('clickCancel');
    },
    handleConfigureClick() {
      if (this.integrationType) {
        this.$emit('clickConfigure');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.integration-modal__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 12px;
}

.integration-modal__description {
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 20px;
}

.integration-modal__buttons {
  margin-top: 24px;
}

.cancel {
  margin-right: 12px;
}
</style>
