<template>
  <div>
    <label
      v-if="hasLabel && attrs"
      class="label"
    >{{ attrs.label }}</label>
    <b-field v-if="attrs">
      <b-input
        v-model="rule.value"
        type="number"
        :min="attrs.min || undefined"
        :max="attrs.max || undefined"
        :placeholder="attrs.placeholder"
        :disabled="!isEditing"
        expanded
      ></b-input>

      <div
        v-if="showRangeAddon"
        class="control"
      >
        <button class="button is-static">{{ attrs.min }}-{{ attrs.max }}</button>
      </div>
    </b-field>
    <b-field v-else>
      <b-icon
        icon="exclamation-triangle"
        size="is-small"
      ></b-icon>
      <em>Invalid Rule Path</em>
    </b-field>
    <p
      v-if="attrs.help"
      class="help"
    ><b-icon
      size="is-small"
      icon="exclamation-triangle"
    ></b-icon> {{ attrs.help }}</p>
  </div>
</template>

<script>
export default {
  name: 'DateNumberInput',
  props: {
    rule: {
      type: Object,
      required: true
    },
    isEditing: {
      type: Boolean,
      required: true
    },
    hasLabel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      yearAttrs: {
        min: 1970,
        max: 3000,
        placeholder: 2019,
        label: 'Input the Year'
      },
      minuteAttrs: {
        min: 0,
        max: 59,
        placeholder: new Date().getMinutes(),
        label: 'Input the Minute'
      },
      utcOffsetAttrs: {
        min: null,
        max: null,
        placeholder: -240,
        label: 'Input the UTC Offset',
        help: 'Only specify this value if you are familiar with UTC and how offset\'s function.'
      }
    };
  },
  computed: {
    attrs() {
      switch (true) {
        case this.rule.path.indexOf('.year') !== -1:
          return this.yearAttrs;
        case this.rule.path.indexOf('.minute') !== -1:
          return this.minuteAttrs;
        case this.rule.path.indexOf('.utcOffset') !== -1:
          return this.utcOffsetAttrs;
        default:
          return null;
      }
    },
    showRangeAddon() {
      return this.isEditing && typeof this.attrs.min === 'number' && typeof this.attrs.max === 'number';
    }
  }
};
</script>
