<template>
  <div class="rules bottom12">
    <slot name="header">
      <h5
        class="title is-5"
        data-cy-test="title"
      >Audience Rules</h5>
    </slot>

    <div
      v-for="(ruleBlock, blockIndex) in rules"
      :key="`${keyId}.${blockIndex}`"
    >
      <div
        v-if="blockIndex > 0"
        class="rule-group__separator"
      >
        <span class="rule-group__separator-text">AND</span>
      </div>

      <RuleBlock
        :rule-block="ruleBlock"
        :position="blockIndex"
        :key-id="`${keyId}.${blockIndex}`"
        :is-editing="isEditing"
        @delete-block="deleteBlock"
      />
    </div>

    <div v-if="isEditing">
      <div class="rule-group__separator bottom0">
        <span class="rule-group__separator-text">AND</span>
      </div>

      <button
        class="button is-info top5"
        style="margin-left: 5px;"
        data-cy-test="add-block"
        @click="addBlock"
      >
        <b-icon
          size="small"
          icon="plus"
        ></b-icon>
        <span>
          Add <strong>AND</strong> Block
        </span>
      </button>
    </div>
  </div><!-- /.rules -->
</template>

<script>
import RuleBlock from '@/components/Rules/RuleBlock';

export default {
  name: 'Rules',
  components: {
    RuleBlock
  },
  props: {
    rules: {
      type: Array,
      required: true
    },
    isEditing: {
      type: Boolean,
      required: true
    },
    keyId: {
      type: String,
      required: true
    }
  },
  methods: {
    // Push a new rule to the block of rules
    addBlock() {
      this.rules.push([{
        path: '',
        comparator: '',
        value: ''
      }]);
    },
    deleteBlock(index) {
      this.rules.splice(index, 1);
    }
  }
};
</script>
