<template>
  <div class="optional-variables">
    <div v-for="fileType of Object.keys(conditionals)" :key="`conditionals-${fileType}`" class="optional-variables__table">
      <div v-if="!!(optional.optionalSections && optional.optionalSections[fileType])">
        <h5 class="title is-5" :data-cy-test="`${fileType}-optional-title`">{{ variableTypeTitle(fileType) }} Optional Variables</h5>

        <div
          v-for="(section, sectionName) of optional.optionalSections[fileType]"
          :key="sectionName"
        >
          <article class="media optional-section" :class="{ 'optional-section__disabled': !section.enabled }" :data-cy-test="`${fileType}-optional-section-${sectionName}`">
            <div class="media-content">
              <div class="level is-mobile">
                <div class="level-left">
                  <div class="level-item">
                    <strong :data-cy-test="`${fileType}-optional-section-${sectionName}`">{{ sectionName }}</strong>
                  </div>
                </div>
                <div class="level-right">
                  <div class="level-item">
                    <strong>Enabled:</strong>
                    <p class="icon ml-2">
                      <i
                        class="fa fa-lg"
                        :class="section.enabled ? 'fa-check-circle' : 'fa-times-circle'"
                        :data-cy-test="`${fileType}-section-enabled-${sectionName}`"
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div class="content">
                <div>
                  <table class="table is-bordered is-hoverable is-fullwidth is-narrow">
                    <thead>
                      <tr>
                        <th colspan="2"><span class="heading" style="margin-bottom: 0;">Variables Used</span></th>
                      </tr>
                    </thead>
                    <tr
                      v-for="varName in section.variablesUsed"
                      :key="varName"
                    >
                      <th :data-cy-test="`${fileType}-section-variable-${sectionName}-name`">{{ varName }}</th>
                      <td :data-cy-test="`${fileType}-section-variable-${sectionName}-${varName}-value`">
                        {{ variables[fileType][varName] }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OptionalVariableTable',
  props: {
    variables: {
      type: Object,
      required: false
    },
    conditionals: {
      type: Object,
      required: false
    }
  },
  computed: {
    optional() {
      let formatted = {};

      for (const fileType in this.conditionals) {
        for (const sectionName in this.conditionals[fileType]) {
          const section = this.conditionals[fileType][sectionName];

          // if this fileType has already been used, spread the values and continue through the loop
          if (formatted.optionalSections && formatted.optionalSections[fileType]) {
            formatted = {
              ...formatted,
              optionalSections: {
                ...formatted.optionalSections,
                [fileType]: {
                  ...formatted.optionalSections[fileType],
                  [sectionName]: {
                    enabled: section.value,
                    variablesUsed: []
                  }
                }
              }
            };
          } else { // otherwise, on first loop create the fileType as a property
            formatted = {
              ...formatted,
              optionalSections: {
                ...formatted.optionalSections,
                [fileType]: {
                  [sectionName]: {
                    enabled: section.value,
                    variablesUsed: []
                  }
                }
              }
            };
          }

          // add variables used to the optional section object
          for (const variable of section.variablesUsed) {
            const [ formattedVariable ] = this.formatAssetVariableValue(variable);
            formatted.optionalSections[fileType][sectionName].variablesUsed.push(formattedVariable);
          }
        }
      }
      return formatted;
    }
  },
  methods: {
    formatAssetVariableValue(value) {
      return value.split('<br/>').map(val => val.replaceAll('&lt;br/&gt;', '<br/>'));
    },
    variableTypeTitle(fileType) {
      if (fileType === 'metadata') {
        return 'JSON';
      }

      return fileType.toUpperCase();
    }
  }
};
</script>

<style lang="scss" scoped>
  .fa-check-circle {
    color: #30cc71;
  }
  .fa-times-circle {
    color: #da1039;
  }
  .optional-variables {
    display: flex;
    column-gap: 24px;
    columns: 2;
  }
  .optional-variables__table {
    max-width: calc(50% - 12px);
    flex: 0 1 50%;
  }
  .optional-section {
    margin-bottom: 16px;

    &__disabled {
      opacity: .5;
    }
  }
</style>
