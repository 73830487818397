<template>
  <article
    class="card test"
    :class="{ 'is-expandable': isMVT && !draftMode, 'bottom10': !isMVT || draftMode }"
  >
    <header
      class="card-header"
      :class="{ 'is-expanded': isExpanded }"
      @click="toggleAudience"
    >
      <div class="card-header-title">{{ audienceTest.name }}</div>

      <!-- Test Type Tag -->
      <div
        v-if="isMVT"
        class="card-header-item"
      >
        <!-- MVT Tag -->
        <div
          v-if="isMVT"
          class="tags has-addons"
        >
          <span class="tag">
            <span class="icon">
              <i
                class="fa fa-sitemap"
                aria-hidden="true"
              ></i>
            </span>
          </span><!-- /.tag -->
          <span class="tag is-primary">MVT</span>
        </div>
      </div><!-- /.card-header-item -->

      <div class="card-header-item">
        <NumberInput
          :min="0"
          :max="100"
          :points="points"
          :initial="audienceTest.visitorsPct"
          :is-static="audienceTest.isPaused === 1"
          @number-change="handleTestPercentChange"
        />
      </div>

      <!-- Delete Button -->
      <div
        v-show="isDeletable"
        class="card-header-item"
      >
        <b-tooltip
          position="is-left"
          type="is-light"
          label="This will only remove the test from the audience, not delete the test entirely"
        >
          <button
            class="button"
            data-cy-test="remove-test-button"
            @click="$emit('delete-audience-test')"
          >
            <span class="icon has-text-danger">
              <i class="fa fa-times"></i>
            </span>
          </button>
        </b-tooltip>
      </div>

      <span
        v-show="isMVT && !draftMode"
        class="card-header-icon"
      >
        <span
          class="icon"
          :class="{ 'is-rotated': isExpanded}"
        >
          <i class="fa fa-angle-down"></i>
        </span>
      </span>
    </header>

    <div
      v-if="isMVT && isExpanded"
      class="card-content"
    >
      <div class="level top15">
        <div class="level-left">
          <h6 class="title is-6">Experiences</h6>
        </div>
        <div class="level-right">
          <h6 class="title is-6">
            Experience Allocation
            <span :class="{ 'has-text-danger': totalExperiencePoints > 100 }">{{ totalExperiencePoints }}%</span>
          </h6>
        </div>
      </div>

      <ExperienceAdjust
        v-for="experience in testExperiences"
        :key="experience.experienceId"
        :points="experiencePoints"
        :initial-experience="experience"
        :is-deletable="audienceTest.strategy.mvt.experiences.length > 1"
        :is-editing="true"
        @remove-experience="removeExperience"
        @experience-change="updateExperience"
      />

      <DefaultExperience
        v-if="totalExperiencePoints < 100"
        :points="100 - totalExperiencePoints"
        :editing="true"
      />
    </div>
  </article>
</template>

<script>
import { clone } from '@/modules/utilities';
import NumberInput from '@/components/NumberInput';
import ExperienceAdjust from '@/components/TestForm/ExperienceAdjust';
import DefaultExperience from '@/components/DefaultExperience';

export default {
  name: 'AudienceTestAdjust',
  components: {
    NumberInput,
    ExperienceAdjust,
    DefaultExperience
  },
  props: {
    audienceTest: {
      type: Object,
      required: true
    },
    experiences: {
      type: Array,
      required: true
    },
    points: {
      type: Number,
      default: 100
    },
    isDeletable: {
      type: Boolean,
      default: false
    },
    draftMode: {
      type: Boolean
    }
  },
  data() {
    return {
      isExpanded: false
    };
  },
  computed: {
    // Get the siteId
    siteId() {
      return this.$store.state.siteId;
    },
    // Get the available points that can be distributed to experiences
    experiencePoints() {
      const usedPoints = this.testExperiences
        .map(test => test.visitorsPct)
        .reduce((acc, curr) => acc + curr);

      return 100 - usedPoints > -1 ? 100 - usedPoints : 0;
    },
    totalExperiencePoints() {
      return this.testExperiences
        .map(test => test.weight)
        .reduce((acc, curr) => acc + curr);
    },
    isMVT() {
      return (
        this.audienceTest.strategy
        && this.audienceTest.strategy.type === 'mvt'
      );
    },
    testExperiences() {
      let result = [];
      if (this.isMVT) {
        result = this.audienceTest.strategy.mvt.experiences
          .filter(exp => exp.experienceId !== '0')
          .map((exp) => {
            let fullExp = this.experiences
              .find(e => e.experienceId === exp.experienceId);

            fullExp = clone(fullExp);
            fullExp.weight = exp.weight || 0;
            fullExp.isControl = exp.isControl || false;
            return fullExp;
          });
      }
      return result;
    },
    hasExperienceData() {
      return this.audienceTest.strategy.mvt.experiences;
    }
  },
  created() {
    if (!this.audienceTest.isPaused) {
      this.audienceTest.isPaused = 0;
    }
  },
  methods: {
    /**
     * Remove an experience from the test
     * @param {Object} experience - exp to be removed
     */
    removeExperience(experience) {
      this.audienceTest.strategy.mvt.experiences = this.audienceTest.strategy.mvt.experiences
        .filter(function (exp) {
          return exp.experienceId !== experience.experienceId;
        });
      this.$emit('audience-test-change', this.audienceTest);
    },
    /**
     * Update an existing experience
     * @param {Object} experience - exp to be updated
     */
    updateExperience(experience) {
      const experiencesLength = this.audienceTest.strategy.mvt.experiences.length;

      for (let i = 0; i < experiencesLength; i++) {
        const exp = this.audienceTest.strategy.mvt.experiences[i];

        if (exp.experienceId === experience.experienceId) {
          this.$set(this.audienceTest.strategy.mvt.experiences, i, experience);
        }

        if (exp.experienceId === '0') {
          exp.weight = 100 - this.totalExperiencePoints;
        }
      }
      this.$emit('audience-test-change', this.audienceTest);
    },
    // Open/Close Audience
    toggleAudience() {
      if (this.draftMode) {
        this.isExpanded = false;
      } else {
        this.isExpanded = !this.isExpanded;
      }
    },
    handleTestPercentChange(val) {
      this.$emit('test-weight-change', parseInt(val, 10));
      this.audienceTest.visitorsPct = val;
    },
    formatExperiences(experiences) {
      // mvt tests will have full experience data,
      // return only props that DB will accept
      return experiences.map(function (exp) {
        return {
          experienceId: exp.experienceId,
          weight: exp.weight,
          isControl: exp.isControl || false
        };
      });
    },
    handleUpdateSuccess() {
      // go ahead and switch off edit state
      this.isEditing = false;
      // check to see if test status has changed
      if (this.initialTest.isPaused !== this.audienceTest.isPaused) {
        // check which it's changed to and notify
        if (this.audienceTest.isPaused === 0) {
          this.$buefy.toast.open({
            message: `${this.audienceTest.name || 'Your test'} was successfully updated and is now running.`,
            type: 'is-success'
          });
        } else if (this.audienceTest.isPaused === 1) {
          this.$buefy.toast.open({
            message: `${this.audienceTest.name || 'Your test'} was successfully updated and is now paused.`,
            type: 'is-success'
          });
        }
      } else {
        // status wasn't changed, notify of general update success
        this.$buefy.toast.open({
          message: `${this.audienceTest.name || 'Your test'} was successfully updated.`,
          type: 'is-success'
        });
      }
    },
    async getTestData(testId) {
      try {
        const res = await this.$axios.get(`/sites/${this.siteId}/tests/${testId}`);
        this.audienceTest = res.data.test;
      } catch (err) {
        err.title = 'There was a problem retrieving audience test data';
        this.$store.commit('error', err);
      }
    },
    async saveTest() {
      try {
        const test = this.formatTest();
        await this.$axios.post(`/sites/${this.siteId}/tests/${test.testId}`, { test });
        this.getTestData(test.testId);
        this.handleUpdateSuccess();
      } catch (error) {
        error.title = 'There was a problem updating the test';
        this.$store.commit('error', error);
      }
    },
    formatTest() {
      const test = clone(this.audienceTest);
      if (test.strategy.mvt !== undefined) {
        test.strategy.mvt.experiences = this.formatExperiences(test.strategy.mvt.experiences);
      }
      if (test.versionId) delete test.versionId;
      return test;
    }
  }
};
</script>

<style lang="scss" scoped>
  .card-header-title,
  .card-header-item {
    padding: 0.5rem;
  }
</style>
