<template>
  <div class="welcome-container">
    <section class="hero is-info is-bold bottom15">
      <div class="hero-body">
        <h1 class="title">
          Welcome to Preamp
        </h1>
        <h2 class="subtitle">
          An audience-based creative testing platform
        </h2>
      </div><!-- /.hero-body -->
    </section>

    <UserAccountHelp
      :user="user"
      :accounts="accounts"
    />
  </div>
</template>

<script>
import UserAccountHelp from '@/components/Support/UserAccountHelp';

export default {
  name: 'AppWelcome',
  components: {
    UserAccountHelp
  },
  props: {
    user: {
      type: Object
    },
    accounts: {
      type: Array
    }
  }
};
</script>
