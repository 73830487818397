<template>
  <b-field :label="fieldLabel || ''">
    <b-select
      v-model="rule.value"
      placeholder="Click to select..."
      expanded
    >
      <option
        :value="''"
        disabled
      >Select a value</option>
      <option
        v-for="opt of ruleOptions"
        :key="`${opt.value}-${opt.label}`"
        :value="opt.value"
      >
        {{ dateTimeField === 'hour' ? `${opt.label} • (${opt.value})` : opt.label }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
export default {
  name: 'DateTimeOptions',
  props: {
    rule: {
      type: Object,
      required: true
    },
    ruleOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      // dateTimeField represents which piece of a date object these options apply to
      dateTimeField: null
    };
  },
  computed: {
    fieldLabel() {
      switch (this.dateTimeField) {
        case 'hour':
          return 'Select the Hour';
        case 'month':
          return 'Select the Month';
        case 'dayOfWeek':
          return 'Select the Day of Week';
        case 'day':
          return 'Select the Day';
        default:
          return null;
      }
    }
  },
  created() {
    // 'serverTime.dayOfWeek' => ['serverTime', 'dayOfWeek'] => 'dayOfWeek'
    this.dateTimeField = this.rule.path.split('.').pop();
  }
};
</script>
