<template>
  <b-modal
    v-model="isActive"
    data-cy-test="abandon-experience"
    width="500px"
    :can-cancel="[]"
  >
    <div class="content-block">
      <p class="title mb-2 is-size-4">Abandon Changes</p>
      <p class="bottom10">Are you sure you want to abandon configuring this Experience? All progress will be lost.</p>
      <div class="buttons is-pulled-right move-right">
        <button
          class="button"
          @click="handleCancel"
        >
          Cancel
        </button>
        <button
          class="button is-danger"
          data-cy-test="abandon-experience-button"
          @click="routing"
        >
          Exit
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { bus } from '../../main';

export default {
	name: 'AbandonExperienceModal',
	props: {
		isActive: {
			required: true,
			type: Boolean
		},
		isCreating: {
			required: true,
			type: Boolean
		},
		experienceId: {
			required: false,
			type: String
		}
	},
	methods: {
		routing() {
			this.isCreating ? this.$router.push(`/experiences/`) : this.$router.push(`/experiences/${this.experienceId}`);
		},
		handleCancel() {
			bus.$emit('closeExperienceModal');
		}
	}
};
</script>

<style lang="scss" scoped>
.content-block {
	padding: 20px;
	padding-bottom: 50px;
	background-color: white;
	border-radius: 5px;
	.button {
		margin: 5px;
	}
}
</style>
