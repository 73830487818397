import { JWT_KEY } from '@/config';
import auth from '@/modules/auth';
import axios from 'axios';
import Vue from 'vue';

let activeRequests = 0;

const isLocalEnv = window.location.hostname.startsWith('localhost');

const config = {
  baseURL: (isLocalEnv) ? 'http://localhost:4001/api' : '/api',
  timeout: process.env.CLIENT_AXIOS_TIMEOUT || 120000
};

if (process.env.VUE_APP_API_BASE_URL) {
  config.baseURL = process.env.VUE_APP_API_BASE_URL;
}

export const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use(function (cfg) {
  // Add Authorization Headers with JWT for all requests
  const requestHeaders = {
    Authorization: `Bearer ${localStorage.getItem(JWT_KEY)}`
  };

  cfg.headers = requestHeaders;

  // Trigger the loading indicator
  activeRequests += 1;
  window.vm.$emit('axios-active', true);

  return cfg;
});

axiosInstance.interceptors.response.use(function (response) {
  // Test to see if we are active
  activeRequests = (activeRequests > 0) ? activeRequests - 1 : 0;
  window.vm.$emit('axios-active', activeRequests);

  return response;
}, function (err) {
  // Test to see if we are active
  activeRequests = (activeRequests > 0) ? activeRequests - 1 : 0;
  window.vm.$emit('axios-active', activeRequests);

  // Require the user to authenticate again whenever a 401 Unauthorized response is received
  if (err.response && err.response.status === 401) {
    // only have the user auth if the 401 response was from the admin api and not from the auth route
    const apiOrigin = `${isLocalEnv ? 'http://localhost:4001/api' : `${window.location.origin}/api`}`;
    const { responseURL } = err.response.request;
    if (responseURL.match(apiOrigin) !== null && !responseURL.match('/info/me')) {
      auth.loginUser(window.vm.$route.path);
    }
  }

  // If we receive a maintenance mode response, display the view
  if (err.response && err.response.status === 503) {
    window.vm.$store.commit('setMaintenanceMode', 1);
  }

  return Promise.reject(err);
});

Plugin.install = function (vueInstance) {
  vueInstance.axios = axiosInstance;
  vueInstance.$axios = axiosInstance;
  window.axios = axiosInstance;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axiosInstance;
      }
    },
    $axios: {
      get() {
        return axiosInstance;
      }
    }
  });
};

Vue.use(Plugin);

export default Plugin;
