import { IntegrationTypeEnum, SegmentIdTypes } from './integrationTypeEnum';

class SegmentIntegration {
  get integrationType() {
    return IntegrationTypeEnum.SEGMENT;
  }

  get displayName() {
    return 'Segment';
  }

  get externalIdDisplay() {
    return 'Anonymous ID';
  }

  get description() {
    return 'Enable using Segment responses in audience rules';
  }

  get imageName() {
    return 'segment.svg';
  }

  get componentName() {
    return 'SegmentIntegrationForm';
  }

  get externalIdType() {
    return SegmentIdTypes[IntegrationTypeEnum.SEGMENT];
  }

  initNewIntegration() {
    return {
      segment: {
        spaceId: null,
        authToken: null,
        idType: SegmentIdTypes[IntegrationTypeEnum.SEGMENT]
      }
    };
  }

  getEditIntegration(integration) {
    return {
      segment: {
        spaceId: integration.data.segment.spaceId,
        authToken: integration.data.segment.authToken,
        idType: integration.data.segment.idType
      }
    };
  }

  validateIntegration(integration) {
    const { data } = integration || {};
    const { segment } = data || {};
    const { spaceId, authToken, idType } = segment || {};

    if(!spaceId || !authToken || !idType) return false;

    return true;
  }
}

const segmentInstance = new SegmentIntegration();

export default segmentInstance;
