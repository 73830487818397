<template>
  <b-modal v-model="isModalActive" data-cy-test="edit/duplicate-modal">
    <div class="card">
      <div class="card-content">
        <h4 class="title is-4" data-cy-test="edit/duplicate-modal-title">
          {{ headerText }}
        </h4>
        <article
          v-if="modalType === 'edit' && hasLiveExperiences"
          class="message is-warning top15"
        >
          <div class="message-body">
            <p>This asset is live in an experience or test. By saving these changes, you will be modifying live experiences.</p>
          </div>
        </article>
        <span v-if="currentStep === 1">
          <b-field
            label="Name"
            :type="nameError.isError ? 'is-danger' : ''"
            :message="nameError.msg"
          >
            <b-input
              v-model="editingAsset.name"
              expanded
              placeholder="Enter a value"
              data-cy-test="name-input"
              @input="resetNameErr"
              @blur="handleBlurName"
            />
          </b-field>
          <b-field label="Asset Tags">
            <b-taginput
              v-model="editingAsset.tags"
              placeholder="Add a tag"
              data-cy-test="tag-input"
            />
          </b-field>
          <b-collapse
            v-model="showAdvancedOptions"
            aria-id="asset-model-advanced-options-toggle"
          >
            <template #trigger>
              <h5 class="title is-5" aria-id="asset-model-advanced-options-toggle">
                <b-icon :icon="(showAdvancedOptions) ? 'caret-down' : 'caret-right'"></b-icon>
                {{ !showAdvancedOptions ? 'Show Advanced Options' : 'Hide Advanced Options' }}
              </h5>
            </template>
            <div class="my-4 pl-4">
              <b-field label="Variant Key"></b-field>
              <b-message>
                <p class="block">If you are unfamiliar with variant keys, the input below can be left empty. More information about variant keys can be found <a target="_blank" href="https://redventures.atlassian.net/wiki/spaces/COHSN/pages/99357033117/Variant+Keys">here</a>.</p>

                <b-field :message="variantKeyValidationMessage" :type="variantKeyValidity.valid === false ? 'is-danger' : ''">
                  <b-input
                    v-model="editingAsset.variantKey"
                    data-cy-test="variant-key-input"
                    placeholder="example_Variant_Key01"
                    maxlength="128"
                    show-counter
                  ></b-input>
                </b-field>
              </b-message>
            </div>

            <div v-if="modalType === 'duplicate'" class="my-4 pl-4">
              <b-field
                label="Duplicate as a base asset?"
                position="is-left"
              />
              <b-message>
                <p class="block">Do you want to duplicate this asset as a base asset?  This will show the duplicated asset as a parent asset and will be able to contain child assets.</p>

                <b-switch
                  v-model="duplicateAsBase"
                  data-cy-test="pseudo-base-switch"
                  type="is-info"
                  :disabled="modalType !== 'duplicate'"
                >
                  {{ duplicateAsBase ? 'Yes' : 'No' }}
                </b-switch>
              </b-message>
            </div>

          </b-collapse>
          <hr>
        </span>

        <VariableStep
          v-if="hasHtmlVariables && currentStep === 1"
          fileType="html"
          :preampVars="preampVars"
          :editingVariables="editingVariables"
          :globalVariables="globalVariables"
        />
        <VariableStep
          v-else-if="(hasJsonVariables && !hasHtmlVariables) || currentStep === 2"
          fileType="metadata"
          :preampVars="preampVars"
          :editingVariables="editingVariables"
          :globalVariables="globalVariables"
        />

        <b-message
          v-if="modalType === 'duplicate'"
          type="is-info"
          class="is-narrow"
          data-cy-test="edit/duplicate-modal-QA-message"
        >
          Preview this asset after it is created for proper QA
        </b-message>
        <div class="level">
          <div class="level-left">
            <!-- Only one of these will be shown at a time -->
            <!-- Create Asset is shown if it is the duplicate modal -->
            <!-- Save is show if it is the edit modal -->
            <!-- One will show if step 1 and only one type of variable -->
            <!-- if both types of variables are on the asset, then it will only show on step 2 -->
            <span v-if="showSaveButtons" class="level-item">
              <b-button
                v-if="modalType === 'duplicate'"
                type="is-info"
                :disabled="!isAssetValid"
                data-cy-test="edit/duplicate-modal-button-create"
                @click="handleDuplicateAssetClick"
              >
                Create Asset
              </b-button>
              <b-button
                v-else
                type="is-info"
                icon-left="save"
                :disabled="!isAssetValid"
                data-cy-test="edit/duplicate-modal-button-save"
                @click="handleEditAssetClick"
              >
                Save
              </b-button>
            </span>

            <!-- Back/Next buttons are shown if there are both HTML and JSON variables -->
            <!-- next is shown on step 1 with HTML variables -->
            <!-- back is shown on step 2 with JSON variables -->
            <div v-if="hasJsonVariables" class="level-item">
              <b-button
                v-if="currentStep === 2"
                data-cy-test="edit/duplicate-modal-button-back"
                @click="handleClickBack"
              >
                Back
              </b-button>
              <b-button
                v-else-if="hasHtmlVariables && currentStep === 1"
                type="is-info"
                data-cy-test="edit/duplicate-modal-button-next"
                @click="handleClickNext"
              >
                Next
              </b-button>
            </div>
            <!-- Cancel button is on every step -->
            <div class="level-item">
              <b-button
                data-cy-test="edit/duplicate-modal-button-cancel"
                @click="isModalActive = false"
              >
                Cancel
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { setValues, validateAsset } from '@redventures/preamp-variables';
import VariableStep from './VariableStep.vue';

export default {
  name: 'AssetModal',
  components: {
    VariableStep
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    modalType: {
      type: String,
      required: true
    },
    asset: {
      type: Object,
      required: true
    },
    allAssets: {
      type: Array,
      required: true
    },
    preampVars: {
      type: Object,
      required: true
    },
    globalVariables: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      showAdvancedOptions: false,
      hasLiveExperiences: false,
      editingAsset: {
      },
      duplicateAsBase: false,
      editingVariables: {
        variables: {},
        conditionals: {}
      },
      nameError: {
        isError: false,
        msg: ''
      },
      currentStep: 1
    };
  },
  computed: {
    isModalActive: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    headerText() {
      switch (this.modalType) {
        case 'duplicate':
          return 'Duplicate Asset';
        case 'edit':
          return 'Edit Asset';
      }
      return '';
    },
    showSaveButtons() {
      return (!this.hasJsonVariables || !this.hasHtmlVariables && this.currentStep == 1) // show if there isn't one of the types AND it's on step 1
        || (this.hasJsonVariables && this.currentStep === 2) // show if there are JSON variables and we're on step 2
        || (this.currentStep == 1 && this.hasJsonVariables && !this.hasHtmlVariables); // show if there are JSON variables, NO HTML variables, AND we're on step 1
    },
    isAssetValid() {
      this.handleBlurName();

      if (this.nameError.isError) return false;
      if (this.variantKeyValidity.valid === false) return false;

      const proposedAsset = setValues(this.asset, this.editingVariables);
      const { isValid } = validateAsset(proposedAsset);

      return isValid;
    },
    baseAssetId() {
      // we are either duplicating off of a base (no duplicatedFrom) or another duplicate.
      // if we are duplicating off of another duplicate, use the duplicatedFrom property to determine the true base asset
      return this.asset.duplicatedFrom || this.asset.assetId || null;
    },
    hasJsonVariables() {
      let hasJsonVars = false;
      for (const varsByFileType of Object.values(this.preampVars)){
        if (Object.prototype.hasOwnProperty.call(varsByFileType, 'metadata')) {
          hasJsonVars = true;
        }
      }
      return hasJsonVars;
    },
    hasHtmlVariables() {
      let hasHtmlVars = false;
      for (const varsByFileType of Object.values(this.preampVars)){
        if (Object.prototype.hasOwnProperty.call(varsByFileType, 'html')) {
          hasHtmlVars = true;
        }
      }
      return hasHtmlVars;
    },
    variantKeyValidity() {
      const status = {
        valid: false,
        // hasValue is for value information only. variantKey can still be valid whether hasValue is true or false.
        hasValue: false,
        // if any of the below properties are true, variantKey is invalid
        patternMismatch: false,
        notUnique: false,
        characterLimitExceeded: false
      };

      // no value for variantKey is accepted
      if (!this.editingAsset.variantKey) {
        status.valid = true;
        status.hasValue = false;
        return status;
      }

      if (typeof this.editingAsset.variantKey === 'string' && this.editingAsset.variantKey.length) {
        status.hasValue = true;

        const match = this.editingAsset.variantKey.match(/^\w+$/g);

        if (!match) {
          status.patternMismatch = true;
          return status;
        }

        status.characterLimitExceeded = this.editingAsset.variantKey.length > 128;
        if (status.characterLimitExceeded) {
          return status;
        }
      }

      if (!this.isVariantKeyUnique) {
        status.notUnique = true;
        return status;
      }

      status.valid = true;
      return status;
    },
    isVariantKeyUnique() {
      if (!this.editingAsset.variantKey) return true;

      if (this.siblingVariantKeys.includes(this.editingAsset.variantKey)) {
        return false;
      }

      return true;
    },
    variantKeyValidationMessage() {
      let msg = 'This field can be left empty. If a value is provided, only letters, numbers, and underscores are accepted. A variant key must also be unique among other variant keys for a given base asset.';

      if (this.variantKeyValidity.notUnique) {
        msg = 'This variant key is not unique';
      } else if (this.variantKeyValidity.patternMismatch) {
        msg = 'Only letters, numbers, and underscores are accepted. Spaces, hyphens, and special characters are not permitted.';
      } else if (this.variantKeyValidity.valid && this.variantKeyValidity.hasValue) {
        msg = 'This variant key is valid';
      }

      return msg;
    },
    siblingVariantKeys() {
      let variants = this.allAssets.filter(a => a.duplicatedFrom === this.baseAssetId && a.variantKey);

      // if an existing asset is being edited, remove it's own variant key from being considered
      if (this.modalType === 'edit') variants = variants.filter(a => a.assetId !== this.asset.assetId);

      return variants.map(a => a.variantKey);
    }
  },
  created() {
    this.initEditingAsset();
  },
  methods: {
    initEditingAsset() {
      this.editingAsset = {
        ...this.asset
      };
      this.duplicateAsBase = this.asset.path === '/';

      this.getIsAssetinLiveExperiences();
      if (this.modalType === 'duplicate') {
        this.editingAsset.name = this.asset.name + ' (Copy)';
        this.duplicateAsBase = false; // reset if this is the duplicate modal
      }
      if (this.asset.tags) {
        this.editingAsset.tags = [...this.asset.tags];
      }
      if (this.asset.variantKey) {
        // auto-expand advanced options if a variant key already exists
        this.showAdvancedOptions = true;

        // if we are duplicating an asset that has an existing variant key, append the variant key with a suffix
        if (this.modalType === 'duplicate') {
          this.editingAsset.variantKey = `${this.editingAsset.variantKey}_copy`;
          this.editingAsset.isPseudoBase = 0;
        }
      }

      for (const varType in this.preampVars) {
        for (const values of Object.values(this.preampVars[varType])) {
          for (const [key, value] of Object.entries(values)) {
            this.editingVariables[varType] = {
              ...this.editingVariables[varType],
              [key]: value
            };
          }
        }
      }
    },
    resetNameErr() {
      this.nameError = {
        isError: false,
        msg: ''
      };
    },
    handleBlurName() {
      if (!this.editingAsset.name) {
        this.nameError = {
          isError: true,
          msg: 'Name cannot be blank'
        };
        return;
      }
      if (this.editingAsset.name.length >= 128) {
        this.nameError = {
          isError: true,
          msg: 'Name must be fewer than 128 characters'
        };
      }
      const duplicateName = this.allAssets
        .find(a => (this.modalType === 'duplicate' || a.name !== this.asset.name) // Do not check for duplicate against original name if editing
          && a.name.toLowerCase().trim() === this.editingAsset.name.toLowerCase().trim());
      if (duplicateName) {
        this.nameError = {
          isError: true,
          msg: `${duplicateName.name} already exists`
        };
      }
    },
    handleDuplicateAssetClick() {
      if (!this.isAssetValid) {
        return;
      }
      this.$emit('duplicateAsset', this.editingAsset, this.editingVariables, this.duplicateAsBase);
    },
    handleEditAssetClick() {
      if (!this.isAssetValid) {
        return;
      }
      this.$emit('editAsset', this.editingAsset, this.editingVariables);
    },
    async getIsAssetinLiveExperiences() {
      try {
        // return all experiences for current site
        const res = await this.$axios.get(`/sites/${this.asset.siteId}/experiences`);
        const expList = res.data.experiences;
        // filter for live experiences
        const isLive = expList.filter(exp => exp.isActive || exp.isChampion);
        // determine if asset is in a live experience
        const usesAsset = isLive.some((exp) => {
          if (exp.assetPlacements) {
            return exp.assetPlacements.map(p => p.asset.assetId).includes(this.asset.assetId);
          }
          return false;
        });
        this.hasLiveExperiences = usesAsset;
      } catch (e) {
        e.title = 'There was a problem retrieving asset\'s experience data.';
        this.$store.commit('error', e);
      }
    },
    handleClickNext() {
      this.handleBlurName();

      if (!this.nameError.isError) {
        this.currentStep += 1;
      }
    },
    handleClickBack() {
      this.handleBlurName();

      if (!this.nameError.isError) {
        this.currentStep -= 1;
      }
    }
  }
};
</script>


