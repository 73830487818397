<template>
  <div class="card is-expandable">
    <header
      class="card-header"
      :class="{ 'is-expanded': isExpanded }"
      @click.stop="toggleUser"
    >
      <div class="card-header-title no-padding">

        <div
          class="card-header-item"
          style="margin-left: 12px;"
        >
          {{ user.email }}
        </div>

        <div
          v-if="user.isSuper"
          class="card-header-item"
        >
          <span class="tag is-info">Super</span>
        </div>

        <div
          v-else-if="isAdmin"
          class="card-header-item"
        >
          <span class="tag is-success">Admin</span>
        </div>
      </div>

      <div
        v-if="isSuper && flaggedForDeletion"
        class="card-header-icon"
      >
        <span class="icon has-text-danger">
          <i class="fa fa-exclamation-circle"></i>
        </span>
      </div>

      <div class="card-header-icon">
        <span class="icon">
          <i
            class="fa"
            :class="(isExpanded) ? 'fa-angle-up' : 'fa-angle-down'"
          ></i>
        </span>
      </div>
    </header><!-- /.card-header -->

    <div
      v-if="isExpanded"
      class="card-content"
    >
      <div class="columns">
        <div class="column is-two-fifths">
          <div class="content">
            <h4>User Details</h4>
          </div>
        </div>

        <div class="column">
          <div class="content">
            <h4>Account Details</h4>
          </div>
        </div>
      </div><!-- /.columns -->

      <div class="columns">
        <div class="column is-two-fifths">
          <table class="table is-bordered is-hoverable is-fullwidth is-narrow">
            <tr>
              <th>Email</th>
              <td>
                {{ user.email }}
              </td>
            </tr>
            <tr>
              <th>Super User</th>
              <td>{{ user.isSuper }}</td>
            </tr>
            <tr>
              <th>Created At</th>
              <td>{{ user.createdAt | formatTime }}</td>
            </tr>
            <tr v-if="isSuper">
              <th>Last Login</th>
              <td v-if="hasLoggedIn">{{ user.lastLoginDate | formatDate }}</td>
              <td v-else>Never</td>
            </tr>
          </table>

          <div
            v-if="isSuper"
            class="field is-inline-block"
          >
            <div class="control is-expanded">
              <label class="label">Super User</label>
              <b-switch
                v-model="userSuper"
                class="top4"
                type="is-success"
              />
            </div>
          </div><!-- /.field -->

          <div
            v-if="isSuper"
            class="field is-inline-block"
          >
          </div><!-- /.field -->
        </div><!-- /.column -->

        <div class="column">
          <div
            v-if="isSuper || !isUser"
            class="bottom15"
          >
            <NewUserRole
              :accounts="availableAccounts"
              @addRole="addRole"
            />
          </div>

          <div>
            <UserRoles
              :accounts="userAccounts"
              :roles="userRoles"
              :isUser="isUser"
              @removeRole="removeRole"
            />
          </div>
        </div><!-- /.column -->
      </div><!-- /.columns -->
    </div><!-- /.card-content -->

    <div
      v-if="isExpanded"
      class="card-footer"
    >
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <base-button-save
              :loading="isSavingUser"
              :disabled="!readyToUpdate"
              @click="updateUser"
            />
          </div>

          <div class="level-item">
            <button
              class="button"
              :disabled="!readyToUpdate"
              @click.stop="reset"
            >Cancel</button>
          </div>
        </div><!-- /.level-left -->

        <div
          v-if="isSuper"
          class="level-right"
        >
          <base-button-delete
            :loading="isDeletingUser"
            @click="confirmRemoveUser"
          />
        </div><!-- /.level-right -->
      </div><!-- /.level -->
    </div>
  </div><!-- /.card -->
</template>

<script>
import { diffJson } from 'diff';
import moment from 'moment-timezone';
import { formatTime, countDaysBetweenDates } from '@/modules/utilities';
import UserRoles from '@/components/Users/UserRoles';
import NewUserRole from '@/components/Users/NewUserRole';

export default {
  name: 'User',
  components: {
    UserRoles,
    NewUserRole
  },
  filters: {
    formatTime,
    formatDate: datetime => moment(datetime).format('ddd, MMM D, Y')
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    accounts: {
      type: Array,
      required: true
    },
    isUser: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isExpanded: false,
      userRoleIds: [],
      userRoles: {},
      userSuper: false,
      isSavingUser: false,
      isDeletingUser: false
    };
  },
  computed: {
    // Check your privilege
    isSuper() {
      return this.$store.getters.isSuperUser;
    },
    // Accounts specific to the user
    userAccounts() {
      return this.accounts.filter((account) => {
        return this.userRoleIds.includes(account.accountId);
      });
    },
    // Accounts the user does not have
    availableAccounts() {
      return this.accounts.filter((account) => {
        return !this.userRoleIds.includes(account.accountId);
      });
    },
    // Is an Admin of at least one account
    isAdmin() {
      for (const account in this.userRoles) {
        if (this.userRoles[account].match(/(admin)/i)) {
          return true;
        }
      }
      return false;
    },
    // Check that changes have actually been made
    readyToUpdate() {
      const { userRoleIds } = this;
      const existingRoles = this.user.accountRoles || {};
      const existingRoleIds = Object.keys(existingRoles);
      const changedRoles = diffJson(this.userRoles, existingRoles);

      switch (true) {
        case userRoleIds.length !== existingRoleIds.length:
        case changedRoles.length > 1:
        case this.userSuper !== this.user.isSuper:
          return true;
        default:
          return false;
      }
    },
    // Identify users that need to be deleted after 90 days of inactivity.
    // - Never logged in, but created > 90 days ago.
    // - Have logged in, but inactive for > 90 days.
    flaggedForDeletion() {
      const now = Date.now();
      const date = !this.hasLoggedIn
        ? this.user.createdAt
        : this.user.lastLoginDate;

      return countDaysBetweenDates(now, date) > 90;
    },
    hasLoggedIn() {
      const res = this.user.lastLoginDate != null;
      return res;
    }
  },
  watch: {
    // Have to do this due to Vue not re-rendering when an existing user is saved from Create User.
    user: {
      handler() {
        this.reset();
      },
      deep: true
    }
  },
  created() {
    // Set initial User Details
    this.reset();
  },
  methods: {
    /**
     * Add a new role from the NewUserRole component
     * @param {Object} payload - contains account id and role
     */
    addRole(payload) {
      const { id, role } = payload;
      this.userRoles[id] = role;
      this.userRoleIds.push(id);
    },
    /**
     * Remove a role from the UserRoles component
     * @param {String} accountId - id to be removed
     */
    removeRole(accountId) {
      delete this.userRoles[accountId];
      this.userRoleIds = this.userRoleIds.filter(function (id) {
        return id !== accountId;
      });
    },
    // Open and close the user view
    toggleUser() {
      this.isExpanded = !this.isExpanded;
    },
    // Confirmation modal for deleting a user
    confirmRemoveUser() {
      this.$buefy.dialog.confirm({
        title: `Deleting ${this.user.email}`,
        message: `Are you sure you want to delete ${this.user.email}?`,
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.deleteUser();
        }
      });
    },
    // Delete the selected user
    async deleteUser() {
      this.isDeletingUser = true;

      const { email } = this.user;

      try {
        await this.$axios.delete(`/user/${email}`);

        this.$buefy.toast.open({
          message: `Successfully deleted ${email}`,
          type: 'is-success'
        });

        this.$emit('deletedUser', this.user.email);
      } catch (error) {
        error.title = `Could not remove ${email}`;
        this.$store.commit('error', error);
      }

      this.isDeletingUser = false;
    },
    // Update the selected user
    async updateUser() {
      this.isSavingUser = true;

      const user = {
        email: this.user.email,
        accountRoles: this.userRoles,
        isSuper: this.userSuper
      };

      try {
        const updatedUser = await this.$store.dispatch('updateUser', user);
        this.$emit('updatedUser', updatedUser);
      } catch (err) {
        err.title = `Could not update ${user.email}`;
        this.$store.commit('error', err);
      } finally {
        this.isSavingUser = false;
      }
    },
    // Reset User Details
    reset() {
      this.userSuper = this.user.isSuper;
      this.userRoles = {};
      this.userRoleIds = [];

      if (this.user.accountRoles !== undefined) {
        this.userRoles = { ...this.user.accountRoles };
        this.userRoleIds = Object.keys(this.user.accountRoles);
      }
    }
  }
};
</script>
