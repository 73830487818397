<template>
  <div class="card add-on">
    <div class="card-content">
      <section>
        <div class="columns">
          <div class="column is-two-fifths">
            <h6 class="title is-6" data-cy-test="title">Add-On Details</h6>

            <table class="table is-bordered is-hoverable is-fullwidth is-narrow">
              <tr data-cy-test="name">
                <th>Name</th>
                <td>
                  <span>{{ (addOn.name) ? addOn.name : 'N/A' }}</span>
                </td>
              </tr>
              <tr data-cy-test="id">
                <th>
                  <span>ID</span>
                </th>
                <td>
                  {{ addOn.addOnId }}
                </td>
              </tr>
              <tr data-cy-test="type">
                <th>
                  <span>Type</span>
                </th>
                <td>
                  {{ addOn.type }}
                </td>
              </tr>
              <tr data-cy-test="created-at">
                <th>Created At</th>
                <td>{{ formatTime(addOn.createdAt) }}</td>
              </tr>
              <tr v-if="addOn.archivedAt" data-cy-test="archived-at">
                <th>Archived At</th>
                <td>{{ formatTime(addOn.archivedAt) }}</td>
              </tr>
              <tr v-if="addOn.archivedBy" data-cy-test="archived-by">
                <th>Archived By</th>
                <td>{{ addOn.archivedBy }}</td>
              </tr>
            </table>

            <section v-if="addOn.tags && addOn.tags.length">
              <b-field label="Tags">
                <b-taginput
                  v-model="addOn.tags"
                  disabled
                />
              </b-field>
            </section>
          </div><!-- /.column -->

          <div class="column">
            <h6 class="title is-6" data-cy-test="title">Related Experiences</h6>
            <div v-if="relatedExperiences.length">
              <table class="table is-bordered is-fullwidth is-narrow">
                <thead>
                  <th>
                    <span>Name</span>
                    <b-tooltip
                      label="Click on an active experience name to view experience details."
                      position="is-right"
                      type="is-light"
                      animated
                    >
                      <span class="icon has-text-info">
                        <i class="fa fa-info-circle"></i>
                      </span>
                    </b-tooltip>
                  </th>
                  <th>Status</th>
                </thead>
                <tbody>
                  <tr
                    v-for="experience in relatedExperiences"
                    :key="experience.experienceId"
                  >
                    <td>
                      <router-link
                        v-if="!experience.archived"
                        :to="`/experiences/${experience.experienceId}`"
                      >
                        {{ `${experience.experienceNumber}. ${experience.name || ''}` }}
                      </router-link>
                      <span
                        v-else
                        class="is-disabled"
                      >
                        <span class="icon has-text-warning">
                          <i class="fa fa-archive"></i>
                        </span>
                        {{ experience.name || `Experience #${experience.experienceNumber}` }}
                      </span>
                    </td>
                    <td>
                      <span
                        class="tag"
                        :class="{
                          'is-warning': experience.archived,
                          'is-success': !experience.archived
                        }"
                      >
                        {{ experience.archived ? 'Archived' : 'Active' }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <article class="message top15">
                <div class="message-body" data-cy-test="message-body">
                  <p>
                    This add-on is not part of any experiences.
                  </p>
                </div>
              </article>
            </div>

            <article
              v-if="!addOnArchivable"
              class="message is-warning top15"
            >
              <div class="message-body">
                <strong>Archiving</strong>
                <p>
                  This add-on belongs to one or more unarchived experiences. To archive it, you'll need to archive those experiences first.
                </p>
              </div>
            </article>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <h6 class="title is-6" data-cy-test="title">Add-On Configuration</h6>
            <pre><code>{{ code }}</code></pre>
          </div>
        </div>
      </section>
    </div>

    <!-- Edit Controls -->
    <footer class="card-footer">
      <div class="level">
        <div class="level-left">
          <div
            v-if="addOn.isArchived === 0"
            class="level-item"
            data-cy-test="edit-button"
          >
            <base-button-edit @click="handleEdit" />
          </div>
        </div><!-- /.level-left -->

        <div class="level-right">
          <div class="level-item">
            <button
              v-if="addOn.isArchived === 0"
              id='btn-experience-archive'
              :disabled="!userCanEdit || !addOnArchivable"
              class="button is-warning"
              data-cy-test="archive-button"
              @click="handleArchiveAddOn"
            >
              <span class="icon"><i class="fa fa-archive"></i></span>
              <span>Archive</span>
            </button>
            <button
              v-else
              :disabled="!userCanEdit"
              class="button is-warning"
              data-cy-test="unarchive-button"
              @click="handleUnarchiveAddOn"
            >
              <span class="icon"><i class="fa fa-archive"></i></span>
              <span>Unarchive</span>
            </button>
          </div>
        </div><!-- /.level-right -->
      </div><!-- /.level -->
    </footer>
    <AddOnForm v-if="addOn" />
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';
import { formatTime } from '@/modules/utilities';
import beautify from 'js-beautify';
import AddOnForm from './AddOnForm';

export default {
  name: 'Add-On-Card',
  components: {
    AddOnForm
  },
  computed: {
    addOn: sync('addOns/focused'),
    allExperiences: sync('experiences/list'),
    userCanEdit() {
      return this.$store.getters.userCanEdit;
    },
    relatedExperiences() {
      return this.allExperiences.filter(exp => exp.addOns && Object.values(exp.addOns).includes(this.addOn.addOnId))
        .sort((a, b) => a.experienceNumber - b.experienceNumber);
    },
    addOnArchivable() {
      return !this.relatedExperiences.length ||
      this.relatedExperiences.reduce((isUnarchived, exp) => isUnarchived ? isUnarchived : exp.archived, false);
    },
    code() {
      const formatted = beautify(JSON.stringify(this.addOn.config), {
        indent_size: 2, // eslint-disable-line camelcase
        indent_level: 0, // eslint-disable-line camelcase
        type: 'js'
      });
      return formatted;
    }
  },
  created() {
    this.$store.dispatch('experiences/getList', ['experienceNumber', 'name', 'experienceId', 'archived', 'addOns']);
  },
  methods: {
    formatTime,
    handleEdit() {
      this.$root.establishSiteLock().then(() => {
        this.$store.set('modals@editAddOn', true);
      });
    },
    handleUnarchiveAddOn() {
      this.$root.establishSiteLock().then(() => {
        const addOn = Object.assign({}, this.addOn, { isArchived: 0 });
        this.$store.dispatch('addOns/updateAddOn', addOn);
      });
    },
    handleArchiveAddOn() {
      this.$root.establishSiteLock().then(() => {
        const addOn = Object.assign({}, this.addOn, { isArchived: 1 });
        this.$store.dispatch('addOns/updateAddOn', addOn);
      });
    }
  }
};
</script>
