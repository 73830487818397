<template>
  <section>
    <div class="main-view-header is-bordered">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h4
              class="title is-4"
              data-cy-test="title"
            >Accounts</h4>
          </div>
        </div><!-- /.level-left -->

        <div
          v-if="isSuper"
          class="level-right"
        >
          <div class="level-item">
            <button
              data-cy-test="create-account-button"
              class="button is-info"
              :disabled="addingAccount"
              @click.stop="toggleNewAccount"
            >
              <span class="icon">
                <i class="fa fa-plus"></i>
              </span>
              <span>Create Account</span>
            </button>
          </div><!-- /.level-item -->
        </div><!-- /.level-right -->
      </div><!-- /.level -->
    </div>

    <div class="main-view-content has-pagination-footer">
      <NewAccount
        v-if="addingAccount"
        :accountNames="accountNames"
        @addAccountCancel="toggleNewAccount"
        @addedAccount="getAccounts"
      />

      <template v-if="accounts.length">
        <Account
          v-for="(account) in accountsPage"
          :key="`account-${account.accountId}`"
          :data-cy-test="`account-${account.name}`"
          :account="account"
          :accountNames="accountNames"
          :regions="regions"
          @updatedAccount="getAccounts"
        />
      </template>

      <b-loading
        :active.sync="isLoading"
        :is-full-page="false"
      ></b-loading>
    </div><!-- /.main-view-content -->

    <footer class="main-view-footer">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <button
              class="button"
              :disabled="page === 0"
              @click.stop="getPage(-1)"
            >Previous</button>
          </div>
          <div class="level-item">
            <button
              class="button"
              :disabled="nextPage >= accounts.length"
              @click.stop="getPage(1)"
            >Next</button>
          </div>
        </div>

        <div class="level-right">
          <div class="level-item">
            <strong>{{ accountsRangeText }} of {{ accounts.length }} Accounts</strong>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
import Account from '@/components/Accounts/Account';
import NewAccount from '@/components/Accounts/NewAccount';

export default {
  name: 'Accounts',
  components: {
    Account,
    NewAccount
  },
  data() {
    return {
      accounts: [],
      regions: [],
      addingAccount: false,
      page: 0,
      offset: 20,
      isLoading: false
    };
  },
  computed: {
    // Return account names array
    accountNames() {
      if (this.accounts.length === 0) return [];
      return this.accounts.map(function (account) {
        return account.name.toLowerCase();
      });
    },
    // Is the user a super user
    isSuper() {
      return this.$store.getters.isSuperUser;
    },
    // Paginate
    accountsPage() {
      if (this.accounts.length > this.offset && this.page * this.offset < this.accounts.length) {
        return this.accounts.slice(this.page * this.offset, this.nextPage);
      } else {
        return this.accounts;
      }
    },
    // What's the next page
    nextPage() {
      if (this.page === 0) return this.offset;
      const nextOffset = (this.page * this.offset) + this.offset;
      return nextOffset > this.accounts.length ? this.accounts.length : nextOffset;
    },
    // Display Text
    accountsRangeText() {
      if (this.accounts.length < this.offset) {
        return this.accounts.length;
      } else {
        return `${this.page * this.offset} - ${this.nextPage}`;
      }
    }
  },
  created() {
    // Get Accounts
    this.getAccounts();

    // Get Regions
    this.getRegions();
  },
  methods: {
    /**
     * Get the next page of data from the API
     * @param {Number} integer - postive or negative 1
     */
    getPage(integer) {
      this.page += integer;
    },
    // Get and assign accounts data
    async getAccounts() {
      this.isLoading = true;

      try {
        const res = await this.$axios.get('/accounts');
        let accounts = res.data;

        // If super admin, only get those accounts
        if (this.$store.state.user && !this.$store.state.user.isSuper) {
          const roles = this.$store.state.user.accountRoles;
          accounts = accounts.filter(function (account) {
            return roles[account.accountId].toLowerCase() === 'admin';
          });
        }

        this.accounts = accounts;
      } catch (err) {
        err.title = 'There was a problem getting your accounts.';
        this.$store.commit('error', err);
      }

      this.isLoading = false;
    },
    // Get and assign region data
    async getRegions() {
      try {
        const res = await this.$axios.get('/accounts/regions');
        this.regions = res.data.map(function (region) {
          return region.name;
        });
      } catch (err) {
        err.title = 'There was a problem getting account regions.';
        this.$store.commit('error', err);
      }
    },
    // Toggle new account form
    toggleNewAccount() {
      this.addingAccount = !this.addingAccount;
    }
  }
};
</script>
