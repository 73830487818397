<template functional>
  <button
    :data-cy-test="props.dataCyTest"
    class="button"
    :disabled="props.disabled"
    :class="{
      'is-loading': props.loading,
      'is-success': props.type === 'success',
      'is-danger': props.type === 'danger',
      'is-info': props.type === 'info'
    }"
    @click="listeners.click"
  >
    <span
      v-if="props.icon"
      class="icon"
    >
      <i :class="`fa fa-${props.icon}`"></i>
    </span>
    <span>{{ props.text }}</span>
  </button>
</template>

<script>
export default {
  name: 'BaseButtonDefault',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      required: true
    },
    dataCyTest: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>

<style lang="scss">
.button.is-text {
  text-decoration: none;
}
</style>
