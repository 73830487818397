<template>
  <div class="card-content">
    <h4 class="title" data-cy-test="ff-preview-title">
      Preview Asset
    </h4>

    <article
      class="message is-warning"
    >
      <div class="message-body">
        <p>Preview the asset for proper QA before updating.</p>
      </div>
    </article>

    <b-field
      label="Placement"
    >
      <b-select
        v-model="selectedPlacement"
        placeholder="Select a placement"
        expanded
        :disabled="assetPlacements.length < 2"
      >
        <option
          v-for="placement in assetPlacements"
          :key="placement.placementId"
          :value="placement"
        >{{ placement.name }}</option>
      </b-select>
    </b-field>

    <b-field
      label="Preview URL"
      grouped
    >
      <b-input
        :disabled="!selectedPlacement"
        :value="previewLink"
        expanded
        placeholder="Enter a value"
      />
      <p class="control">
        <b-button
          :disabled="!selectedPlacement"
          icon-left="external-link"
          data-cy-test="ff-preview-open-preview"
          @click="openPreviewTab"
        >
          Preview
        </b-button>
      </p>
    </b-field>

    <div class="level button-row">
      <div class="level-left"></div>
      <div class="level-right">
        <div class="level-item">
          <b-button
            type="is-ghost"
            data-cy-test="ff-preview-back"
            @click="$emit('back')"
          >
            Back
          </b-button>
        </div>
        <b-tooltip
          label="You must preview this asset before saving."
          :active="!hasPreviewed"
          type="is-info"
          position="is-left"
        >
          <b-button
            :disabled="!hasPreviewed"
            type="is-info"
            data-cy-test="ff-preview-save"
            @click="fastForwardAsset"
          >
            Save
          </b-button>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { encode as base64Encode } from 'js-base64';

export default {
  name: 'AssetPreviewModal',
  props: {
    duplicateAsset: {
      type: Object,
      required: true
    },
    allAssets: {
      type: Array,
      required: true
    },
    assetPlacements: {
      type: Array,
      required: true
    },
    fastForwardVariables: {
      type: Object,
      required: true
    },
    assetNameChange: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedPlacement: null,
      hasPreviewed: false
    };
  },
  computed: {
    previewLink() {
      if (!this.selectedPlacement) return 'Select a placement to generate preview link';
      let previewURL = this.selectedPlacement.previewUrl || this.$store.getters.siteDomain;

      if (previewURL.indexOf('http') === -1) {
        previewURL = `http://${previewURL}`;
      }

      previewURL = (previewURL.indexOf('?') !== -1)
        ? previewURL += '&'
        : previewURL += '?';

      previewURL = `${previewURL}!preamp=${this.selectedPlacement.placementId}.${this.duplicateAsset.assetId}.${this.encodedPayload}`;

      return previewURL;
    },
    encodedPayload() {
      const payload = { fastForward: true, variables: this.fastForwardVariables.variables };
      const stringPayload = JSON.stringify(payload);

      return base64Encode(stringPayload, true);
    }
  },
  watch: {
    previewLink() {
      this.hasPreviewed = false;
    }
  },
  created() {
    [ this.selectedPlacement ] = this.assetPlacements;
  },
  methods: {
    openPreviewTab() {
      this.hasPreviewed = true;
      window.open(this.previewLink);
    },
    async fastForwardAsset() {
      this.$emit('close');
      const fastForwardPayload = {
        assetId: this.duplicateAsset.assetId,
        variables: this.fastForwardVariables,
        name: this.assetNameChange
      };
      await this.$store.dispatch('assets/fastForwardAsset', fastForwardPayload);
      await this.$store.dispatch('assets/getFocused', this.duplicateAsset.assetId);
    }
  }
};
</script>

<style scoped lang="scss">
.button-row {
  margin-top: 24px;
}
</style>
