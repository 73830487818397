<template>
  <b-modal
    :active="true"
    :width="800"
    @close="handleFormCancel"
  >
    <div
      class="card test-form-modal"
      data-cy-test="card"
    >
      <header class="card-header"><span
        class="card-header-title"
        data-cy-test="card-header-title"
      >Create New Test</span></header>
      <div class="card-content test-form test-form-modal__padding-sides">
        <TestDetailsStep
          :test="commonTest"
          :valid.sync="stepsValid.common.testDetailsStep"
          :isEditing="isEditing"
        />
        <div class="test-form-modal__divider"></div>
        <ExperiencesStep
          v-model="experiences"
          :commonTest="commonTest"
          :mabExp="mabExp"
          :mvt="mvt"
          :clickOptimization="clickOptimization"
          :valid.sync="stepsValid.common.experiencesStep"
          :isEditing="isEditing"
        />
        <template v-if="commonTest.strategy.type === 'mabExp'">
          <div class="test-form-modal__divider"></div>
          <RewardStep
            :mabExp="mabExp"
            :isEditing="isEditing"
            :testId="test && test.testId"
            :valid.sync="stepsValid.mabExp.rewardStep"
          />
        </template>
        <template v-if="commonTest.strategy.type === 'clickOptimization'">
          <div class="test-form-modal__divider"></div>
          <RewardConfiguration
            v-model="rewards"
            :siteId="siteId"
            :clickOptimization="clickOptimization"
            :isEditing="isEditing"
            :valid.sync="stepsValid.clickOptimization.rewardStep"
            @set-existing-reward-ids="rewardIds => existingRewardIds = rewardIds"
          />
        </template>
      </div>
      <TestFormFooter
        :commonTest="commonTest"
        :mvt="mvt"
        :mabExp="mabExp"
        :clickOptimization="clickOptimization"
        :experiences="experiences"
        :rewards="rewards"
        :external="external"
        :stepsValid="stepsValid"
        :trafficFlows="trafficFlows"
        :isEditing="isEditing"
        @cancel="handleFormCancel"
        @test-created="test => showRewardReminder('created', test)"
        @test-updated="test => showRewardReminder('updated', test)"
      />
      <!-- need to ask austin why showRewardReminder('created') was not allowing test properites to be read higher up-->
    </div>
    <ClickOptimizationReminder
      v-if="rewardReminder.shouldShowRewardReminder"
      :unimplemented="rewardReminder.unimplemented"
      @close="notifyTestChange"
    />
  </b-modal>
</template>

<script>
import TestDetailsStep from '@/components/TestForm/TestFormSteps/common/TestDetailsStep';
import ExperiencesStep from '@/components/TestForm/TestFormSteps/common/ExperiencesStep';
import TestFormFooter from '@/components/TestForm/TestFormSteps/common/TestFormFooter';
import RewardStep from '@/components/TestForm/TestFormSteps/mab-exp/RewardStep';
import RewardConfiguration from './TestFormSteps/click-optimization/RewardConfiguration.vue';
import ClickOptimizationReminder from '@/components/TestForm/TestFormSteps/click-optimization/ClickOptimizationReminder';

const mabExpDefaultExpirationSeconds = 45 * 60; // 45 minutes

export default {
  name: 'TestFormModal',
  components: {
    TestDetailsStep,
    ExperiencesStep,
    TestFormFooter,
    RewardStep,
    RewardConfiguration,
    ClickOptimizationReminder
  },
  props: {
    test: Object,
    external: Object
  },
  data() {
    return {
      trafficFlows: null,
      isEditing: false,
      stepsValid: {
        common: {
          testDetailsStep: false,
          experiencesStep: false
        },
        mabExp: {
          rewardStep: false
        },
        clickOptimization: {
          rewardStep: false
        }
      },
      commonTest: {
        trafficFlowId: null,
        name: null,
        tags: [],
        description: '',
        isPersisted: 0,
        strategy: {
          type: 'clickOptimization'
        }
      },
      mabExp: {
        minTrafficPct: 0,
        dapi: {
          options: [],
          clientToken: null,
          expiration: mabExpDefaultExpirationSeconds
        }
      },
      mvt: {
        experiences: []
      },
      clickOptimization: {
        rewardStrategy: 'session',
        agentId: 0,
        tenantId: '',
        minTrafficPct: 0,
        options: []
      },
      experiences: [],
      rewards: [],
      existingRewardIds: [],
      rewardReminder: {
        shouldShowRewardReminder: false,
        unimplemented: [],
        formAction: null,
        finalizedTest: null
      }
    };
  },
  computed: {
    siteId() {
      return this.$store.state.siteId;
    },
    isSiteHIPAA() {
      return this.$store.state.isSiteHIPAA;
    },
    accountId() {
      return this.$store.state.activeAccount;
    },
    tenantId() {
      return this.$store.state.activeSite.tenantId;
    },
    canCreateMabExpTest() {
      return this.$store.getters.canCreateMabExpTest;
    }
  },
  created() {
    this.getTrafficFlows();

    if (this.test && this.test.testId) {
      this.isEditing = true;
      this.commonTest = {
        testId: this.test.testId,
        siteId: this.test.siteId,
        trafficFlowId: this.test.trafficFlowId,
        name: this.test.name,
        tags: this.test.tags || [],
        description: this.test.description,
        isPersisted: this.test.isPersisted,
        strategy: {
          type: this.test.strategy.type
        }
      };

      if (this.test.strategy.type === 'mabExp') {
        this.mabExp = {
          minTrafficPct: this.test.strategy.mabExp.minTrafficPct,
          dapi: {
            options: [],
            clientToken: this.test.strategy.mabExp.dapi.clientToken ?? null,
            expiration: this.test.strategy.mabExp.dapi.expiration ?? mabExpDefaultExpirationSeconds
          }
        };
        if (this.test.strategy.mabExp.dapi.tenantId) {
          this.mabExp.dapi.tenantId = this.test.strategy.mabExp.dapi.tenantId;
        }
        this.experiences = this.test.strategy.mabExp.dapi.options.map(exp => ({
          experienceId: exp.experienceId,
          isControl: false,
          weight: 0
        }));
      } else if (this.test.strategy.type === 'mvt') {
        // Filter out the default base site if it exists
        this.experiences = this.test.strategy.mvt.experiences
          .filter(exp => exp.experienceId !== '0')
          .map(exp => ({
            experienceId: exp.experienceId,
            isControl: exp.isControl,
            weight: exp.weight
          }));
      } else if (this.test.strategy.type === 'clickOptimization') {
        const { clickOptimization: co } = this.test.strategy;

        // copy the strategy properties onto existing state while breaking reference
        // to avoid vuex mutation errors
        this.clickOptimization = { ...co };
        this.experiences = this.clickOptimization.options.map(({ experienceId }) => ({ experienceId }));
        const agentRewards = this.external?.dapi?.agent?.rewards;
        // only if we have pre-existing rewards, turn them into an array
        if (typeof agentRewards === 'object' && Object.keys(agentRewards).length) {
          this.rewards = Object.entries(agentRewards).map(function ([rewardId, value]) {
            return { rewardId, value };
          });
        }
      }
    }

    if (this.isSiteHIPAA) {
      this.commonTest.strategy.type = 'mvt';
    }
  },
  beforeDestroy() {
    // If confirmation prompt is open when parent component hides modal, close the prompt
    if (this.cancelPrompt) this.cancelPrompt.close();
  },
  methods: {
    handleFormCancel() {
      // Store prompt to make programatic cancelling possible
      this.cancelPrompt = this.$buefy.dialog.confirm({
        title: 'Form Cancel Warning',
        type: 'is-warning',
        cancelText: 'Stay',
        confirmText: 'Leave',
        message: 'Are you sure you want to leave? All form values will be reset and any progress will be lost.',
        onConfirm: () => this.$emit('cancel')
      });
    },
    async getTrafficFlows() {
      try {
        const res = await this.$axios.get(`/sites/${this.siteId}/traffic-flows`);

        // if an array of traffic flows are returned, use the first traffic flow
        this.trafficFlows = Array.isArray(res.data) ? res.data[0] : res.data;
      } catch (err) {
        err.title = 'There was a problem getting traffic flows';
        this.$store.commit('error', err);
      }
    },
    showRewardReminder(formAction, test) {
      //Will call to function to test if rewards have been seen before
      if (this.commonTest.strategy.type === 'clickOptimization') {
        this.rewardReminder.unimplemented = this.findUnimplementedRewards(this.rewards);
        if (this.rewardReminder.unimplemented.length > 0 && !localStorage.getItem('rewardReminderOptOut')) {
          this.rewardReminder.shouldShowRewardReminder = true;
        }
      }
      this.rewardReminder.formAction = formAction;
      this.rewardReminder.finalizedTest = test;
      if (!this.rewardReminder.shouldShowRewardReminder) {
        this.notifyTestChange();
      }
    },
    findUnimplementedRewards(rewards) {
      let unimplemented = [];
      rewards.forEach((value) => {
        if (!this.existingRewardIds.includes(value.rewardId)) {
          unimplemented.push(value.rewardId);
        }
      });
      return unimplemented;
    },
    notifyTestChange() {
      this.$emit(`test-${this.rewardReminder.formAction}`, this.rewardReminder.finalizedTest);
      this.rewardReminder.formAction = null;
      this.rewardReminder.finalizedTest = null;
    }
  }
};
</script>

<style lang="scss">
.test-form-modal__padding-sides {
  padding: 0.75rem 2.5rem;
}

.test-form-modal__divider {
  background-color: lightgrey;
  height: 1px;
  margin: 1.5rem 0 2rem 0;
}
</style>
