<template>
  <div class="add-ons">
    <div class="main-view-header">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h4 class="title is-4" data-cy-test="title">Add-Ons</h4>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-field>
              <p class="control">
                <b-dropdown>
                  <button
                    slot="trigger"
                    class="button"
                    data-cy-test="search-dropdown-button"
                  >
                    <span>{{ filterBy ? filterBy.name : 'Filters' }}</span>
                    <span class="icon"><i class="fa fa-caret-down"></i></span>
                  </button>

                  <b-dropdown-item
                    v-for="option in filterOptions"
                    :key="option.val"
                    :data-cy-test="option.name"
                    @click="filterBy = option"
                  >{{ option.name }}</b-dropdown-item>
                </b-dropdown>
              </p>
              <b-input
                v-model="filterQuery"
                placeholder="Search..."
                data-cy-test="search-input"
              ></b-input>

            </b-field>
          </div>

          <div class="level-item">
            <button
              v-if="userCanEdit"
              class="button is-info"
              :disabled="!true"
              data-cy-test="create-add-on-button"
              @click.stop="openCreateModal"
            >
              <span class="icon">
                <i class="fa fa-plus"></i>
              </span>
              <span>Create Add-On</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="main-view-sub-header">
      <div class="tabs is-boxed">
        <ul>
          <li
            v-for="tab in ['Active', 'Inactive', 'Archived']"
            :id="`tab-assets-item-${tab.toLowerCase()}`"
            :key="tab"
            :class="{ 'is-active': tabSelection === tab }"
            :data-cy-test="`tab-${tab.toLowerCase()}`"
            @click="(evt) => handleTabSelection(evt, tab)"
          >
            <a :title="getTabTip(tab)">{{ tab }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="main-view-content is-white has-pagination-footer">
      <AddOnForm />

      <div
        v-if="filtered.length > 0"
        class="level ml-4"
      >
        <div class="level-left">
          <div class="level-item">
            <b-checkbox
              v-model="masterCheckboxModel"
              type="is-info"
              :indeterminate="isMasterCheckboxIndeterminate"
            />
          </div>
          <div class="level-item">
            <b-button
              icon-left="download"
              type="is-info"
              :disabled="!canDownload"
              :loading="isDownloadingCsv"
              data-cy-test="download-csv-button"
              @click="handleCsvDownload"
            >
              Download CSV
            </b-button>
          </div>
          <div
            v-if="tabSelection === 'Active' || tabSelection === 'Inactive'"
            class="level-item"
          >
            <b-button
              icon-left="archive"
              type="is-info"
              :disabled="!canArchive"
              :loading="isArchiving"
              data-cy-test="archive-button"
              @click="handleBulkArchive"
            >
              Archive Selected
            </b-button>
          </div>
        </div>
      </div>

      <b-table
        :data="filtered"
        :loading="isLoading"
        :paginated="true"
        :pagination-simple="true"
        :current-page.sync="currentPage"
        :per-page="pageSize"
        pagination-size="is-hidden"
        sort-icon="caret-up"
        hoverable
      >

        <b-table-column
          v-slot="props"
          width="50"
        >
          <b-checkbox
            :value="selectedIds.includes(props.row.addOnId)"
            type="is-info"
            data-cy-test="select-checkbox"
            @input="handleAddOnChecked(props.row.addOnId)"
          />
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="name"
          sortable
          label="Add-On Name"
        >
          <router-link :to="`/add-ons/${props.row.addOnId}`" data-cy-test="add-on-name-in-table">
            {{ props.row.name }}
          </router-link>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="type"
          sortable
          label="Add-On Type"
        >
          {{ props.row.type }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          :visible="tabSelection === 'Archived'"
          field="archivedBy"
          sortable
          label="Archived By"
        >
          {{ props.row.archivedBy }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          :visible="tabSelection === 'Archived'"
          field="archivedAt"
          sortable
          label="Archived At"
        >
          {{ props.row.archivedAt | filterArchiveDateTime }}
        </b-table-column>

        <template
          v-if="!isLoading"
          slot="empty"
        >
          <article class="message is-warning">
            <div class="message-body" data-cy-test="message-body">
              No {{ tabSelection.toLowerCase() }} add-ons were found for this site.
            </div>
          </article>
        </template>
      </b-table>
    </div><!-- /.main-view-content -->

    <footer class="main-view-footer">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <button
              class="button"
              :disabled="currentPage === 1"
              @click.stop="currentPage--"
            >Previous</button>
          </div>
          <div class="level-item">
            <button
              class="button"
              :disabled="(pageSize * currentPage) >= filtered.length"
              @click.stop="currentPage++"
            >Next</button>
          </div>
        </div>

        <div class="level-right">
          <div
            v-if="filtered.length"
            class="level-item"
          >
            <strong>{{ rangeText }} Add-Ons</strong>
          </div>
        </div>
      </div>
    </footer><!-- /.main-view-footer -->

    <b-loading
      :active.sync="isLoading"
      :is-full-page="false"
    />
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';
import AddOnForm from '@/components/AddOns/AddOnForm';
import { bus } from '@/main';
import { downloadCsv } from '@/modules/utilities';

export default {
  name: 'Add-Ons',
  components: {
    AddOnForm
  },
  data: function() {
    return {
      filterBy: {
        name: 'Name',
        val: 'name'
      },
      filterOptions: [{name: 'Name', val: 'name'}, {name: 'ID', val: 'addOnId'}, {name: 'Type', val: 'type'}, {name: 'Tags', val: 'tags'}],
      tabSelection: 'Active',
      filterQuery: '',
      currentPage: 1,
      pageSize: 20,
      isDownloadingCsv: false,
      isArchiving: false,
      selectedIds: [],
      csvAttributes: [
        'siteId',
        'addOnId',
        'createdAt',
        'isArchived',
        'name',
        'type',
        'config'
      ]
    };
  },
  computed: {
    addOns: sync('addOns/list'),
    experiences: sync('experiences/list'),
    isLoading: sync('addOns/isLoading'),
    filtered() {
      const queryFiltered = this.addOns.filter((addOn) => {
        switch (this.filterBy.val) {
          case 'name':
          case 'addOnId':
          case 'type':
            return addOn[this.filterBy.val].toLowerCase().includes(this.filterQuery.toLowerCase());
          case 'tags':
            return addOn.tags && addOn.tags.map(tag => tag.toLowerCase()).reduce((acc, cur) => {
              if (acc) return acc;
              return cur.toLowerCase().includes(this.filterQuery.toLowerCase());
            }, false);
          case 'default':
            return true;
        }
      });

      const statusFiltered = queryFiltered.filter((addOn) => {
        switch (this.tabSelection) {
          case 'Archived':
            return addOn.isArchived;
          case 'Active':
            return !addOn.isArchived && addOn.isActive;
          default:
            return !addOn.isArchived && !addOn.isActive;
        }
      });

      return statusFiltered;
    },
    rangeText() {
      if (this.pageSize > this.filtered.length) return this.filtered.length;
      return `${(this.pageSize * this.currentPage) - (this.pageSize - 1)} - `
      + `${this.pageSize * this.currentPage > this.filtered.length ? this.filtered.length : this.pageSize * this.currentPage} of ${ this.filtered.length }`;
    },
    // Get the site id
    siteId() {
      return this.$store.state.siteId;
    },
    userCanEdit() {
      return this.$store.getters.userCanEdit;
    },
    canDownload() {
      return this.selectedIds.length > 0 && !this.isDownloadingCsv && !this.isArchiving;
    },
    canArchive() {
      return this.userCanEdit && this.selectedIds.length > 0 && !this.isDownloadingCsv && !this.isArchiving;
    },
    masterCheckboxModel: {
      get() {
        return this.selectedIds.length === this.filtered.length;
      },
      set(val) {
        if (val) {
          this.selectedIds = this.filtered.map(addOn => addOn.addOnId);
        } else {
          this.selectedIds = [];
        }
      }
    },
    isMasterCheckboxIndeterminate() {
      return this.selectedIds.length > 0 && this.selectedIds.length !== this.filtered.length;
    }
  },
  created() {
    this.getDependencies();
    bus.$on('site-changed', this.getDependencies);
  },
  beforeDestroy() {
    bus.$off('site-changed', this.getDependencies);
  },
  methods: {
    getDependencies() {
      this.clearSelected();
      if (this.siteId) this.$store.dispatch('addOns/getList');
    },
    /**
     * Emit element click event
     * @param {Object} e - element click event
     */
    elementClick(e) {
      bus.$emit('elem-click', e);
    },
    /**
     * Get tooltip message when hovering over the tab options
     * @param {String} tab - 'All, Active, Inactive, Archived
     */
    getTabTip(tab) {
      switch (tab) {
        case 'Active':
          return 'Add-Ons enrolled in an active experience.';
        case 'Inactive':
          return 'Add-Ons enrolled in an inactive experience.';
        case 'Archived':
          return 'Add-Ons that have been archived and are not eligible to be in an experience unless unarchived.';
        default:
          return '';
      }
    },
    handleTabSelection(clickEvent, tab) {
      this.selectedIds = [];
      this.elementClick(clickEvent);
      this.tabSelection = tab;
    },
    openCreateModal() {
      this.$root.establishSiteLock().then(() => {
        this.$store.set('modals@createAddOn', true);
      });
    },
    handleAddOnChecked(id) {
      if (this.selectedIds.includes(id)) {
        this.selectedIds = this.selectedIds.filter(selectedId => selectedId !== id);
      } else {
        this.selectedIds.push(id);
      }
    },
    handleCsvDownload() {
      if (this.selectedIds.length === 0) {
        return;
      }
      this.isDownloadingCsv = true;

      const csvItems = this.addOns.filter(addOn => this.selectedIds.includes(addOn.addOnId));

      const filename =
        this.$store.state.activeAccountSites.find(s => s.siteId === this.siteId).name.replace(/\./g, '-')
        + '_addons_'
        + new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-')
        + '.csv';

      downloadCsv(csvItems, this.csvAttributes, filename);

      this.isDownloadingCsv = false;
    },
    async handleBulkArchive() {
      if (this.selectedIds.length === 0) {
        return;
      }
      this.isArchiving = true;
      // Get experiences to test archivability of add-ons
      await this.$store.dispatch('experiences/getList',
        ['experienceNumber', 'name', 'experienceId', 'archived', 'tags', 'hash', 'archivedBy', 'archivedAt', 'addOns']);
      const addOns = [];
      for (const addOnId of this.selectedIds) {
        addOns.push(this.addOns.find(ao => ao.addOnId === addOnId));
      }
      // Make sure all add-ons are archivable
      for (const addOn of addOns) {
        // Check for unarchived experiences which include this add-on
        const relatedExperiences = this.experiences.filter(exp =>
          !exp.archived && exp.addOns && Object.values(exp.addOns).includes(addOn.addOnId));
        if (relatedExperiences.length > 0) {
          this.$buefy.toast.open({
            message: `Archive failed. ${addOn.name} is part of at least one unarchived experience.`,
            type: 'is-danger',
            position: 'is-bottom',
            duration: 2500
          });
          this.isArchiving = false;
          return;
        }
      }

      // At this point, we know that all add-ons can be archived
      this.$root.establishSiteLock().then(() => {
        const updatedAddOns = addOns.map((addOn) => {
          const a = {
            ...addOn,
            isArchived: 1
          };
          delete a.isActive;
          return a;
        });
        let message;
        if (updatedAddOns.length === 1) {
          message = `Are you sure you want to archive ${updatedAddOns[0].name}?`;
        } else {
          message = `Are you sure you want to archive all ${updatedAddOns.length} add-ons?`;
        }
        this.archivePrompt = this.$buefy.dialog.confirm({
          title: `Archiving Add-Ons`,
          message,
          confirmText: 'Archive',
          type: 'is-warning',
          hasIcon: true,
          onConfirm: async () => {
            await this.$store.dispatch('addOns/updateAddOns', updatedAddOns);
            this.selectedIds = [];
            this.isArchiving = false;
          },
          onCancel: () => {
            this.isArchiving = false;
          }
        });
      });
    },
    clearSelected() {
      this.selectedIds = [];
    }
  }
};
</script>
