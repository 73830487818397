<template>
  <section>
    <div class="main-view-header is-bordered">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h4 class="title is-4">Account Help</h4>
          </div>
        </div><!-- /.level-left -->

        <div class="level-right"></div><!-- /.level-right -->
      </div><!-- /.level -->
    </div>

    <div class="main-view-content">
      <UserAccountHelp
        :user="user"
        :accounts="(user && user.accounts) ? user.accounts : []"
      />
    </div><!-- /.main-view-content -->
  </section>
</template>

<script>
import UserAccountHelp from '@/components/Support/UserAccountHelp';

export default {
  name: 'AccountHelp',
  components: {
    UserAccountHelp
  },
  props: ['user']
};
</script>
