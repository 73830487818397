<template>
  <div class="variable-input" :data-cy-test="`${variableName}-config`">
    <div class="field">
      <label class="label variable-label">
        {{ variableName }}
        <b-tooltip
          :label="value.description"
          type="is-info"
          position="is-top"
          :data-cy-test="`${variableName}-tooltip`"
        >
          <b-icon
            icon="commenting"
            class="dev-comment__icon"
            :data-cy-test="`${variableName}-comment`"
          ></b-icon>
        </b-tooltip>
      </label>
      <div class="field-body">
        <div class="field has-addons">
          <b-radio-button
            v-model="value.value"
            :native-value="true"
            type="is-info"
            :disabled="disabled"
            :data-cy-test="`${variableName}-input`"
          >
            <span>True</span>
          </b-radio-button>
          <b-radio-button
            v-model="value.value"
            :native-value="false"
            type="is-info"
            :disabled="disabled"
            :data-cy-test="`${variableName}-input`"
          >
            <span>False</span>
          </b-radio-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from '@/main';

export default {
  name: 'configure-boolean',
  props: {
    value: {
      type: Object,
      required: true
    },
    variableName: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    editableValue: {
      get() {
        return Boolean(this.value.value);
      },
      set(value) {
        this.$emit('input', {...this.value, value: Boolean(value)});
        bus.$emit('variablesChange');
      }
    }
  }
};
</script>

<style scoped>
.variable-input {
  margin: 0px 0px 10.5px 0px;
}
:deep(.radio.button.is-info.is-focused:not(:active)) {
  box-shadow: none;
}
:deep(.radio.button) {
 background-color: #EDEFF3;
 border-color: #EDEFF3;
 color: #626D7A;
 font-weight: 600;

}
:deep(.radio.button:hover) {
  background-color: #DEE4EA;
  border-color: #DEE4EA;
  color: #0B0F14;
}
:deep(.radio.button.is-info.is-selected) {
 background-color: #4CBBFF;
}

:deep(.radio.button.is-info.is-selected:focus) {
 box-shadow: none;
}

:deep(.radio.button.is-info.is-selected span) {
  color: #0B0F14;
  font-weight: 600;
}
</style>


