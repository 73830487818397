import { make } from 'vuex-pathify';
import router from '@/router';
import { axiosInstance as axios } from '@/plugins/axios';
import { hasLength, hasProp, clone } from '@/modules/utilities';

const state = {
  list: [],
  focused: {},
  isLoading: true
};

const mutations = make.mutations(state);

const actions = {
  async getList({ dispatch, commit, rootState }) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'addOns/getList' }, { root: true });
      return;
    }

    try {
      commit('SET_IS_LOADING', true);
      const res = await axios.get(`/sites/${rootState.siteId}/add-ons`);
      commit('SET_LIST', res.data);
    } catch (e) {
      e.title = 'There was a problem retrieving add-ons data.';
      commit('error', e, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async getFocused({ commit, rootState, dispatch }) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'addOns/getFocused' }, { root: true });
      return;
    }

    try {
      commit('SET_IS_LOADING', true);
      const { addOnId } = router.history.current.params;
      const res = await axios.get(`/sites/${rootState.siteId}/add-ons/${addOnId}`);
      commit('SET_FOCUSED', res.data);
    } catch (e) {
      e.title = 'There was a problem retrieving details for this add-on.';
      commit('error', e, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async createAddOn({ dispatch, commit, rootState }, addOn) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'addOns/createAddOn', payload: addOn }, { root: true });
      return;
    }

    addOn.siteId = rootState.siteId;

    try {
      commit('SET_IS_LOADING', true);
      const res = await axios.post(`/sites/${rootState.siteId}/add-ons`, { addOn });
      return res.data;
    } catch (e) {
      e.title = 'There was a problem creating your add-on.';
      commit('error', e, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async updateAddOn({ dispatch, commit, rootState }, addOn) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'addOns/updateAddOn', payload: addOn }, { root: true });
      return;
    }

    try {
      commit('SET_IS_LOADING', true);
      const url = `sites/${rootState.siteId}/add-ons/${addOn.addOnId}`;
      const res = await axios.put(url, { addOn });
      window.vm.$buefy.toast.open({
        message: `${res.data.name} successfully updated.`,
        type: 'is-success'
      });
    } catch (e) {
      e.title = 'There was a problem updating your add-on.';
      commit('error', e, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
      dispatch('getFocused');
    }
  },
  async updateAddOns({ dispatch, commit, rootState }, addOns) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'addOns/updateAddOns', payload: addOns }, { root: true });
      return;
    }

    try {
      const url = `sites/${rootState.siteId}/add-ons/batch`;
      const res = await axios.put(url, { addOns });
      window.vm.$buefy.toast.open({
        message: `${res.data.addOns.length} add-ons successfully updated`,
        type: 'is-success'
      });
    } catch (e) {
      e.title = 'There was a problem updating your add-ons.';
      commit('error', e, { root: true });
    } finally {
      dispatch('getList');
    }
  }
};

const getters = {
  allAddOnTags(state) {
    const addOnTags = [];
    if (hasLength(state.list)) {
      for (const addOn of state.list) {
        if (hasLength(addOn.tags)) {
          addOn.tags.forEach(function (tag) {
            if (!addOnTags.includes(tag)) {
              addOnTags.push(tag);
            }
          });
        }
      }
    }
    return addOnTags;
  },
  allAddOnNames(state) {
    let allAddOnNames = [];
    if (hasLength(state.list)) {
      allAddOnNames = state.list.map(addOn => addOn.name.toLowerCase());
    }
    return allAddOnNames;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
