<template>
  <div class="card bottom10">
    <header class="card-header">
      <div class="card-header-title">
        Add New User
      </div>
    </header><!-- /.card-header -->

    <div class="card-content">
      <div class="columns">
        <div class="column">
          <div class="content">
            <h4>User Details</h4>
          </div>
        </div>

        <div class="column">
          <div class="content">
            <h4>Account Details</h4>
          </div>
        </div>
      </div><!-- /.columns -->

      <div class="columns">
        <div class="column">
          <b-field label="Email">
            <b-autocomplete
              v-model.trim="userEmail"
              placeholder="jsmith@redventures.com"
              :data="filteredEmail"
              field="email"
              expanded
              @select="option => handleUserSelection(option)"
              @blur="handleUserBlur"
            >
            </b-autocomplete>
          </b-field>

          <div
            v-if="isSuper"
            class="field is-inline-block"
          >
            <div class="control is-expanded">
              <label class="label">Super User</label>
              <b-switch
                v-model="userSuper"
                class="top4"
                type="is-success"
              />
            </div>
          </div><!-- /.field -->

          <div
            v-if="isSuper"
            class="field is-inline-block"
          >
          </div><!-- /.field -->
        </div><!-- /.columns -->

        <div class="column">
          <label
            class="label"
            for="AddAccountRoles"
          >Add Roles</label>
          <NewUserRole
            :accounts="availableAccounts"
            @addRole="addRole"
          />

          <div class="top15">
            <UserRoles
              :accounts="userAccounts"
              :roles="userRoles"
              @removeRole="removeRole"
            />
          </div>
        </div><!-- /.column -->
      </div><!-- /.columns -->
    </div><!-- /.card-content -->

    <div class="card-footer">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <base-button-save
              :loading="isSavingUser"
              :disabled="!isValid.valid"
              @click="save"
            />
          </div>

          <div class="level-item">
            <button
              class="button"
              @click.stop="cancel"
            >
              Cancel
            </button>
          </div>

          <div
            v-if="!isValid.valid"
            class="level-item"
          >
            <p class="has-text-danger">
              <span class="icon">
                <i class="fa fa-exclamation-circle"></i>
              </span>
              {{ isValid.errMsg }}
            </p>
          </div>
        </div><!-- /.level-left -->
      </div><!-- /.level -->
    </div>
  </div><!-- /.card -->
</template>

<script>
import UserRoles from '@/components/Users/UserRoles';
import NewUserRole from '@/components/Users/NewUserRole';

const emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  name: 'NewUser',
  components: {
    UserRoles,
    NewUserRole
  },
  props: {
    users: {
      type: Array,
      required: true
    },
    accounts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      userEmail: '',
      userRoles: {},
      userRoleIds: [],
      userSuper: false,
      isSavingUser: false
    };
  },
  computed: {
    // Check permissions
    isSuper() {
      return this.$store.getters.isSuperUser;
    },
    // Validate new account form fields
    isValid() {
      let errMsg;
      let valid = false;

      switch (true) {
        case !emailRe.test(this.userEmail):
          errMsg = 'Please enter a valid email address.';
          break;
        default:
          valid = true;
      }

      return {
        errMsg,
        valid
      };
    },
    // Accounts specific to the user
    userAccounts() {
      return this.accounts.filter((account) => {
        return this.userRoleIds.includes(account.accountId);
      });
    },
    // Accounts the user does not have
    availableAccounts() {
      return this.accounts.filter((account) => {
        return !this.userRoleIds.includes(account.accountId);
      });
    },
    // Email accounts for auto complete
    filteredEmail() {
      return this.users.filter((user) => {
        return user.email.toString().toLowerCase().indexOf(this.userEmail.toLowerCase()) >= 0;
      });
    }
  },
  methods: {
    /**
     * Add a new role from the NewUserRole component
     * @param {Object} payload - contains account id and role
     */
    addRole(payload) {
      const { id, role } = payload;
      this.userRoles[id] = role;
      this.userRoleIds.push(id);
    },
    /**
     * Remove a role from the UserRoles component
     * @param {String} accountId - id to be removed
     */
    removeRole(accountId) {
      delete this.userRoles[accountId];
      this.userRoleIds = this.userRoleIds.filter(function (id) {
        return id !== accountId;
      });
    },
    /**
     * Handle user selection even if the component select event is not triggered
     * This can occur when an email is written, but not explicitly clicked from the dropdown
     * to trigger handleUserSelection
     */
    handleUserBlur() {
      const foundUser = this.users.find(u => u.email === this.userEmail);
      if (foundUser) {
        this.handleUserSelection(foundUser);
      }
    },
    /**
     * On user selection from autocomplete, populate their related data
     * @param {Object} user - data object
     */
    handleUserSelection(option) {
      if (option === null) return;

      this.userRoles = option.accountRoles || {};
      this.userRoleIds = (option.accountRoles) ? Object.keys(option.accountRoles) : [];
      this.userSuper = option.isSuper;
    },
    // Reset the add user form and close
    cancel() {
      this.userRoles = {};
      this.userRoleIds = [];
      this.userEmail = '';
      this.userSuper = false;
      this.$emit('addUserCancel');
    },
    // Post the new User to Preamp
    async save() {
      this.isSavingUser = true;

      const payload = {
        user: {
          accountRoles: this.userRoles,
          email: this.userEmail,
          isSuper: this.userSuper
        }
      };

      const isUpdatingUser = this.users.some(u => u.email === this.userEmail);
      const msgVerb = isUpdatingUser ? 'update' : 'create';

      try {
        if (isUpdatingUser) {
          const updatedUser = await this.$store.dispatch('updateUser', payload.user);
          this.$emit('updatedUser', updatedUser);
        } else {
          const res = await this.$axios.post('/user', payload);
          this.$buefy.toast.open({
            message: `Successfully ${msgVerb}d ${res.data.user.email}`,
            type: 'is-success'
          });
          this.$emit('addedUser', res.data.user);
        }

        this.cancel();
      } catch (err) {
        err.title = `Could not ${msgVerb} ${this.userEmail}`;
        this.$store.commit('error', err);
      }

      this.isSavingUser = false;
    }
  }
};
</script>
