<template>
  <fieldset>
    <!-- Name -->
    <b-field
      :type="message.type"
      :message="message.text"
      class="mb-0"
      data-cy-test="name-field"
    >
      <template slot="label">
        Name<sup class="has-text-danger">&ast;</sup>
      </template>
      <b-input
        v-model.trim="test.name"
        type="text"
        required
        maxlength="64"
        placeholder="What unique name would describe your test?"
        data-cy-test="test-name"
      ></b-input>
    </b-field>

    <!-- Description toggle -->
    <div :class="{ 'mb-3': states.isDescriptionActive || states.isTagInputActive }">
      <a
        href="javascript:void(0)"
        class="mr-5"
        @click="states.isDescriptionActive = !states.isDescriptionActive"
      >
        <span class="mr-3">{{ states.isDescriptionActive ? '-' : '+' }}</span>
        <span
          style="text-decoration: underline;"
          data-cy-test="add-description-link"
        >Add Description</span>
      </a>

      <!-- Tags toggle -->
      <a
        href="javascript:void(0)"
        @click="states.isTagInputActive = !states.isTagInputActive"
      >
        <span class="mr-3">{{ states.isTagInputActive ? '-' : '+' }}</span>
        <span
          style="text-decoration: underline;"
          data-cy-test="add-tag-link"
        >Add Tags</span>
      </a>
    </div>

    <!-- Description field -->
    <b-field
      v-if="states.isDescriptionActive"
      label="Description"
      class="mb-0"
    >
      <b-input
        v-model="test.description"
        maxlength="512"
        type="textarea"
        placeholder="What is the purpose of this test?"
        data-cy-test="test-description"
        :rows="!test.description || test.description.length === 0 ? '1' : '3'"
      ></b-input>
    </b-field>

    <!-- Tags field -->
    <b-field
      v-if="states.isTagInputActive"
      label="Tags"
      class="bottom10"
    >
      <b-taginput
        v-model="testTags"
        :data="existingTestTags"
        :allow-new="true"
        autocomplete
        icon="tag"
        placeholder="What new or existing tags categorize your test?"
        data-cy-test="test-tags"
        @typing="filterTagsByText"
      ></b-taginput>
    </b-field>

    <!-- Persistence -->
    <b-field
      v-if="test.testId"
      label="Persistence"
      position="is-left"
      class="top10"
    >
      <b-message size="is-small">
        <p class="bottom10">
          Enabling test persistence will instruct Preamp to diverge from standard testing behavior and forcefully
          carry a previous decision across sessions.
        </p>

        <p>
          Users who receive a persistent test will continue to get the same test and experience for that audience
          until the test is ended, paused, or persistence is disabled. <a
            target="_blank"
            href="https://redventures.atlassian.net/wiki/spaces/COHSN/pages/74429861/Persistent+Tests"
          >More information</a>.
        </p>
        <hr class="bottom10 top10">
        <b-switch
          v-model="test.isPersisted"
          data-cy-test="persistence-toggle"
          :true-value="1"
          :false-value="0"
          :type="test.isPersisted === 1 ? 'is-success' : ''"
        >
          {{ test.isPersisted ? 'Enabled' : 'Disabled' }}
        </b-switch>
      </b-message>
    </b-field>
  </fieldset>
</template>

<script>
import { hasLength } from '@/modules/utilities';

export default {
  name: 'TestDetailsStep',
  props: {
    // named generic "test" to re-use for edit state
    test: {
      type: Object,
      required: true
    },
    valid: {
      type: Boolean,
      required: true
    },
    isEditing: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      existingTestNames: null,
      existingTestTags: null,
      states: {
        isDescriptionActive: false,
        isTagInputActive: false
      }
    };
  },
  computed: {
    siteId() {
      return this.$store.state.siteId;
    },
    isTestNameDefined() {
      return typeof this.test.name === 'string' && this.test.name.length > 0;
    },
    isTestNameUnique() {
      return Array.isArray(this.existingTestNames) ? this.existingTestNames.indexOf(this.test.name) === -1 : null;
    },
    isTestNameValid() {
      return this.isTestNameDefined === true && this.isTestNameUnique === true;
    },
    message() {
      const result = {
        type: '',
        text: ''
      };

      // only show error/success if user has begun to type
      if (this.isTestNameDefined) {
        if (this.isTestNameUnique === true) {
          result.type = 'is-success';
          result.text = 'This name is available';
        }

        if (this.isTestNameUnique === false) {
          result.type = 'is-danger';
          result.text = 'This name is unavailable. Another test exists with this name.';
        }
      }

      return result;
    },
    testTags: {
      get() {
        return this.test.tags;
      },
      set(tags) {
        this.test.tags = tags;
      }
    }
  },
  watch: {
    isTestNameValid(bool) {
      // this tells the parent whether the name and other details are valid
      this.$emit('update:valid', bool);
    }
  },
  async created() {
    await this.getAllTestNamesAndTags();

    // if we are editing, expand the collapsed sections for field values that exist
    if (this.test.description) {
      this.states.isDescriptionActive = true;
    }
    if (hasLength(this.test.tags)) {
      this.states.isTagInputActive = true;
    }

    // Tell parent that name is valid at the beginning if editing
    if (this.isEditing) {
      this.$emit('update:valid', true);
    }
  },
  methods: {
    async getAllTestNamesAndTags() {
      try {
        const tests = await this.$store.dispatch('tests/getAllTests', ['testId', 'name', 'ended', 'tags']);

        // if we have tests, process them
        if (hasLength(tests)) {
          return this.processTestNamesAndTags(tests, this.test.testId || null);
        }

        // no tests returned in request. Use an empty array to indicate that all names are available
        this.existingTestNames = [];
        this.existingTestTags = [];
      } catch (err) {
        // what should happen if we can't determine
        // whether a test name is unique?
        this.$store.commit('error', err, 'Unexpected error occurred while attempting to fetch all test names and tags');
      }
    },
    processTestNamesAndTags(allTests, editingTestId) {
      // initialize the lists before pushing items to them
      this.existingTestNames = [];
      this.existingTestTags = [];

      for (let i = 0, len = allTests.length; i < len; i++) {
        const testObj = allTests[i];

        // the names of ended tests are available since tests cannot be un-ended
        if (testObj.ended === 1) continue;

        // we are editing an existing test, skip over it's own testObj in the list
        if (editingTestId && testObj.testId === editingTestId) continue;

        // save the test name
        this.existingTestNames.push(testObj.name);

        // now process any tags
        if (hasLength(testObj.tags)) {
          for (const tag of testObj.tags) {
            if (this.existingTestTags.indexOf(tag) === -1) {
              this.existingTestTags.push(tag);
            }
          }
        }
      }
    },
    filterTagsByText(text) {
      this.existingTestTags = this.existingTestTags.filter(function (tagName) {
        return tagName.toString().toLowerCase().indexOf(text.toLowerCase()) !== -1;
      });
    }
  }
};
</script>
