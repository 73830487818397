<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <b-modal
    :active="true"
    :width="600"
    @close="$emit('close')"
  >
    <div class="card test-form-modal">
      <header
        class="modal-card-head"
        data-cy-test="reward-reminder-header"
      >
        <p class="modal-card-title">Reminder: Contact Your Developer</p>
        <button
          type="button"
          class="delete"
          @click="$emit('close')"
        />
      </header>
      <section class="modal-card-body">
        <b-field label="If you haven't done so, please contact your developer to have them attach the information below to the HTML asset of the element being rewarded. This step is needed for reward information to be reported.">
        </b-field>
        <b-field
          v-for="rewardID in unimplemented"
          :key="rewardID"
        >
          <div style="position: relative;">
            <b-input
              type="text"
              :value="'data-chsn-reward-click=&quot;'+rewardID+'&quot;'"
              placeholder="data-chsn-reward-click="
              class="reminder-modal__input"
            >
            </b-input>
            <span @click="copyToClipbiard([rewardID])">
              <b-icon
                class="reminder-modal__copy"
                icon="paste"
              />
            </span>
          </div>
        </b-field>
      </section>
      <div class="card-footer">
        <div class="level">
          <div class="level-left">
            <b-checkbox
              @input="optOut= !optOut;"
            >Do not show again</b-checkbox>
          </div>  <!-- Needs to be here to force level-right to do its job -->
          <div class="level-right">
            <div class="level-item">
              <b-button
                label="Copy"
                @click="copyToClipbiard(unimplemented)"
              />
            </div>
            <div class="level-item">
              <b-button
                data-cy-test="close-reward-reminder"
                label="Close"
                type="is-success"
                @click="checkOptOut"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ClickOptimizationReminder',
  props: {
    unimplemented: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      optOut: false
    };
  },
  methods: {
    copyToClipbiard(rewardIds) {
      let reminderMsg = '';
      rewardIds.forEach((text) => {
        reminderMsg += `data-chsn-reward-click="${text}"\n`;
      });
      navigator.clipboard.writeText(reminderMsg);
      this.$buefy.toast.open({
        message: 'Copied!',
        type: 'is-success'
      });
    },
    checkOptOut() {
      if (this.optOut) {
        localStorage.setItem('rewardReminderOptOut', true);
      }
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
  .reminder-modal__input {
    border: none;
    box-sizing: border-box;
    display: block;
    width: 100%;
  }
  .reminder-modal__copy {
    height: 25px;
    cursor: pointer;
    position: absolute;
    top: 2px;
    right: 5px;
  }
</style>
