<template>
  <b-modal
    v-model="isModalActive"
    width="600px"
    :canCancel="false"
    @close="handleCancel"
  >
    <div class="card">
      <IntegrationModalStep1
        v-if="!isEditing && step === 1"
        v-model="integration.integrationType"
        @clickCancel="handleCancel"
        @clickConfigure="handleConfigureIntegration"
      >
      </IntegrationModalStep1>
      <IntegrationModalStep2
        v-else-if="isEditing || step === 2"
        :integration="integration"
        :isEditing="isEditing"
        :userCanEdit="userCanEdit"
        :disableDelete="disableDelete"
        @clickCancel="handleCancel"
        @clickBack="handleBack"
        @clickDelete="handleConfirmDelete"
        @clickSubmit="handleSubmitForm"
      >
      </IntegrationModalStep2>
      <div
        v-if="!formValidation.isValid"
        class="integration-modal__errors"
      >
        <p class="has-text-danger">
          <span class="icon">
            <i class="fa fa-exclamation-circle"></i>
          </span>
          {{ formValidation.error }}
        </p>
      </div>
    </div>
  </b-modal>
</template>

<script>
import IntegrationModalStep1 from '@/components/Integrations/IntegrationModalStep1';
import IntegrationModalStep2 from '@/components/Integrations/IntegrationModalStep2';
import { getIntegrationInstance, newIntegrationSchema, validateIntegration } from '@/modules/integrations';
import { bus } from '@/main';

export default {
  name: 'IntegrationModal',
  components: {
    IntegrationModalStep1,
    IntegrationModalStep2
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    isEditing: {
      type: Boolean,
      required: true
    },
    userCanEdit: {
      type: Boolean,
      required: true
    },
    existingIntegration: {
      type: Object,
      required: false
    },
    liveIntegrationIds: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      step: 1,
      integration: {},
      confirmCancel: null,
      confirmDelete: null,
      formValidation: {
        isValid: true,
        error: ''
      }
    };
  },
  computed: {
    isModalActive: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    integrationInstance() {
      if (!this.integration.integrationType) {
        return {};
      }
      return getIntegrationInstance(this.integration.integrationType, this.integration);
    },
    disableDelete() {
      return this.liveIntegrationIds.includes(this.integration.integrationId);
    }
  },
  watch: {
    // Reset modal when opened
    isModalActive: {
      immediate: true,
      handler(val) {
        if (!val) {
          if (this.confirmCancel) {
            this.confirmCancel.close();
            this.confirmCancel = null;
          }
          return;
        }
        if (this.existingIntegration) {
          // We are editing an existing integration, copy it and start at step 2
          const integrationInstance = getIntegrationInstance(this.existingIntegration.integrationType);
          const urlMatch = (this.existingIntegration.options && this.existingIntegration.options.urlMatch) ? [...this.existingIntegration.options.urlMatch] : [];

          this.integration = {
            integrationId: this.existingIntegration.integrationId,
            name: this.existingIntegration.name,
            integrationType: this.existingIntegration.integrationType,
            data: integrationInstance.getEditIntegration(this.existingIntegration),
            options: {
              ...this.existingIntegration.options,
              urlMatch
            }
          };

          this.step = 2;
          this.isEditing = true;
        } else {
          // We are creating a new integration, copy the base template
          this.integration = {
            ...newIntegrationSchema,
            data: { ...newIntegrationSchema.data },
            options: {
              ...newIntegrationSchema.options
            }
          };

          this.step = 1;
          this.isEditing = false;
        }
      }
    }
  },
  created () {
    bus.$on('site-lock-lost-user', this.handleSiteLockLost);
  },
  beforeDestroy() {
    bus.$off('site-lock-lost-user', this.handleSiteLockLost);
  },
  methods: {
    handleConfigureIntegration() {
      this.isEditing = false;
      this.integration = {
        ...newIntegrationSchema,
        integrationType: this.integration.integrationType,
        data: this.integrationInstance.initNewIntegration(),
        options: {
          ...newIntegrationSchema.options
        }
      };
      this.step = 2;
    },
    handleCancel() {
      this.confirmCancel = this.$buefy.dialog.confirm({
        title: 'Abandon Integration',
        type: 'is-danger',
        cancelText: 'Cancel',
        confirmText: 'Exit',
        message: 'Are you sure you want to abandon configuring this integration? All progress will be lost.',
        onConfirm: () => {
          this.isModalActive = false;
        }
      });
    },
    handleBack() {
      this.integration.integrationType = '';
      this.step = 1;
      this.confirmCancel = null;
    },
    async handleSubmitForm() {
      const isIntegrationValid = validateIntegration(this.integration);

      this.formValidation = {
        isValid: isIntegrationValid,
        error: isIntegrationValid ? '' : 'All fields are required.'
      };

      if (!isIntegrationValid) return false;

      if (this.isEditing) this.handleSave();

      else this.handleCreate();
    },
    async handleDelete() {
      await this.$root.establishSiteLock();
      await this.$store.dispatch('integrations/deleteIntegration', this.integration);

      this.isModalActive = false;
    },
    async handleSave() {
      await this.$root.establishSiteLock();
      await this.$store.dispatch('integrations/updateIntegration', this.integration);

      this.isModalActive = false;
    },
    async handleCreate() {
      await this.$root.establishSiteLock();
      await this.$store.dispatch('integrations/createIntegration', this.integration);

      this.isModalActive = false;
    },
    handleConfirmDelete() {
      this.$root.establishSiteLock().then(() => {
        // Store the prompt for programmatic close
        this.confirmDelete = this.$buefy.dialog.confirm({
          title: `Deleting "${this.integration.name}"`,
          message: `
            Are you sure you want to delete the integration "${this.integration.name}"?
            This action cannot be reversed.
          `,
          confirmText: 'Delete',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.handleDelete();
          }
        });
      });
    },
    handleSiteLockLost() {
      if (this.confirmDelete) this.confirmDelete.close();
    }
  }
};
</script>

<style lang="scss" scoped>
  .card {
    padding: 20px;
  }
</style>

