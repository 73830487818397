<template>
  <div class="is-relative">
    <pie-chart
      :data="chartData"
      :colors="colors"
      :suffix="hasNumbersViewSelected ? ' Decisions' : '%'"
      :messages="{ 'empty': 'No data was found within the lookback range selected.' }"
      thousands=","
    ></pie-chart>

    <table
      v-if="tableData && tableData.length > 0"
      class="table is-narrow is-fullwidth"
      style="margin-top: 10px;"
    >
      <thead>
        <tr>
          <th>{{ tableHeading }}</th>
          <th class="has-text-right">{{ hasNumbersViewSelected ? '' : '% of' }} Decisions Created</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in tableData"
          :key="index"
        >
          <td>
            <span v-if="hasDisabledLinks">{{ item.prop }}</span>
            <a
              v-else
              href="javascript:void(0)"
              @click="$emit('selection', item.prop)"
            >
              {{ item.prop }}
            </a>
          </td>
          <td class="has-text-right">
            <span v-if="item.value < 1">&lt;1</span>
            <span v-else>{{ numberWithCommas(item.value) }}</span>
            <span v-if="!hasNumbersViewSelected">%</span>
          </td>
        </tr>
      </tbody>
    </table>

    <slot name="help"></slot>

    <b-loading
      :active.sync="isLoading"
      :is-full-page="false"
    ></b-loading>
  </div>
</template>

<script>
import { numberWithCommas } from '@/modules/utilities';

export default {
  name: 'ChildPieChart',
  props: [
    'chartData',
    'hasNumbersViewSelected',
    'tableHelpMessage',
    'tableHeading',
    'tableData',
    'hasDisabledLinks',
    'isLoading'
  ],
  data() {
    return {
      colors: ['#324d5a', '#42b09d', '#f0cc61', '#e37f4f', '#df6153', '#a965c6', '#668ec4', '#7bce79', '#e26abc', '#6d4141']
    };
  },
  methods: {
    numberWithCommas
  }
};
</script>

<!-- <style lang="scss">

</style> -->
