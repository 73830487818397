<template>
  <div class="login-view full-height">
    <div class="content-block full-height">
      <div class="content-container">
        <img
          src="./assets/preamp-p.svg"
          class="logo"
        />
        <h1 class="title" data-cy-test="title">Welcome to Preamp</h1>
        <b-button
          class="login-btn"
          data-cy-test="login-button"
          type="is-info"
          expanded
          :loading="isRedirecting"
          :disabled="isRedirecting"
          @click="logIn"
        >
          Log In With Auth0
        </b-button>
      </div>
    </div>
    <b-message
      v-if="userFailed"
      class="user-failed-msg"
      type="is-danger"
    >
      Please ensure you are on the VPN before logging in. If you are on the VPN and still cannot access Preamp, contact us in #cohesion-support.
    </b-message>
  </div>
</template>

<script>
import auth from './modules/auth';

export default {
  name: 'Login',
  data() {
    return {
      isRedirecting: false
    };
  },
  computed: {
    userFailed() {
      return this.$store.state.userFailed;
    }
  },
  methods: {
    logIn() {
      this.isRedirecting = true;
      auth.loginUser(this.$route.path);
    }
  }
};
</script>

<style lang="scss" scoped>
.login-view {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 8%;

  .content-block {
    background-color: white;
    height: auto;
    max-width: 768px;

    .content-container {
      padding: 48px 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      .logo {
        width: 40%;
        margin-bottom: 24px;
      }

      .login-btn {
        width: 100%;

        ::v-deep button {
          height: 48px;
          font-size: 16px;
        }
      }
    }
  }

  .user-failed-msg {
    margin-top: 24px;
  }
}

.full-height {
  height: 100%;
}
</style>
