import { make } from 'vuex-pathify';
import { axiosInstance as axios } from '@/plugins/axios';

const state = {
  list: [],
  focused: {},
  isLoading: false
};

const mutations = make.mutations(state);

const actions = {
  async getAllTests({ commit, dispatch, rootState }, attributes) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'tests/getAllTests', payload: attributes }, { root: true });
      return;
    }
    commit('SET_IS_LOADING', true);

    try {
      const res = await axios.get(`/sites/${rootState.siteId}/tests`, {
        params: {
          status: 'all',
          attributes: attributes ? attributes : ['testId', 'name', 'isPaused', 'ended', 'strategy']
        }
      });

      commit('SET_LIST', res.data.tests);
      return res.data.tests;
    } catch (err) {
      err.title = 'There was a problem retrieving test data.';
      commit('error', err, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }

  },
  async createMVT({ commit, state, rootState }, test) {
    commit('SET_IS_LOADING', true);

    delete test.testId;
    if (test.tags && !test.tags.length) delete test.tags;
    test.siteId = rootState.siteId;

    if (!test.trafficFlowId) {
      const trafficFlowsRes = await axios.get(`/sites/${rootState.siteId}/traffic-flows`);
      const trafficFlows = trafficFlowsRes.data;
      if (trafficFlows.length) {
        test.trafficFlowId = trafficFlows[0].trafficFlowId;
      }
    }

    try {
      const res = await axios.post(`/sites/${rootState.siteId}/tests`, {
        siteId: rootState.siteId,
        test: test
      });

      return res.data.test;
    } catch (err) {
      err.title = 'There was a problem creating your test.';
      commit('error', err, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async updateMVT({ commit, state, rootState }, test) {
    commit('SET_IS_LOADING', true);

    delete test.isActive;
    if (test.tags && !test.tags.length) delete test.tags;

    try {
      const res = await axios.post(`/sites/${rootState.siteId}/tests/${test.testId}`, { test });

      return res.data.test;
    } catch (err) {
      err.title = 'There was a problem updating your test.';
      commit('error', err, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async createMabExp({ commit, state, rootState }, payload) {
    commit('SET_IS_LOADING', true);

    delete payload.test.isActive;
    if (payload.test.tags && !payload.test.tags.length) delete payload.test.tags;

    try {
      const res = await axios.post(`/sites/${rootState.siteId}/tests`, payload);

      return res.data.test;
    } catch (err) {
      err.title = 'There was a problem creating your test.';
      commit('error', err, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async updateMabExp({ commit, state, rootState }, payload) {
    commit('SET_IS_LOADING', true);

    delete payload.test.isActive;
    if (payload.test.tags && !payload.test.tags.length) delete payload.test.tags;

    try {
      const res = await axios.put(`/sites/${rootState.siteId}/tests/mabExp/${payload.test.testId}`, payload);

      return res.data.test;
    } catch (err) {
      err.title = 'There was a problem updating your test.';
      commit('error', err, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async createClickOptimization({ commit, state, rootState }, { test, external = {} }) {
    commit('SET_IS_LOADING', true);

    delete test.testId;
    if (test.tags && !test.tags.length) delete test.tags;
    test.siteId = rootState.siteId;

    try {
      const res = await axios.post(`/sites/${rootState.siteId}/tests`, {
        siteId: rootState.siteId,
        test,
        external
      });

      return res.data.test;
    } catch (err) {
      commit('error', err, { root: true }, `Error creating click optimization test: ${test.name}.`);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async updateClickOptimization({ commit, state, rootState }, { test, external = {} }) {
    commit('SET_IS_LOADING', true);

    delete test.isActive;
    if (test.tags && !test.tags.length) delete test.tags;

    try {
      const res = await axios.post(`/sites/${rootState.siteId}/tests/${test.testId}`, { test, external });

      return res.data.test;
    } catch (err) {
      commit('error', err, { root: true }, `Error updating click optimization test: ${test.testId}.`);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async getFocused({ commit, rootState, dispatch }, testId) {
    if (!rootState.siteId) {
      dispatch('deferActionsForSiteId', 'test/getFocused', { root: true });
      return;
    }
    try {
      commit('SET_IS_LOADING', true);
      const res = await axios.get(`/sites/${rootState.siteId}/tests/${testId}`);
      commit('SET_FOCUSED', res.data);
    } catch (e) {
      e.title = 'There was a problem retrieving details for this test.';
      commit('error', e, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
