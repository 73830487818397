<template>
  <div class="box">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h5 class="title is-5">{{ smartTitle }}</h5>
        </div>
      </div>

      <div class="level-right">
        <div class="level-item">
          <b-tooltip
            type="is-dark"
            :label="`Return ${size} Results`"
          >
            <b-field>
              <b-select v-model="size">
                <option
                  v-for="option in sizeOptions"
                  :key="option"
                  :value="option"
                >{{ option }}</option>
              </b-select>
            </b-field>
          </b-tooltip>
        </div>
        <div class="level-item">
          <LookbackRangePicker
            @format-selection="val => hasNumbersViewSelected = val"
            @selection="handleRangeChange"
            @refresh="getInsights"
          />
        </div>
      </div>
    </div>

    <div class="is-relative">
      <bar-chart
        v-if="insights"
        :data="chartData"
        :suffix="hasNumbersViewSelected ? '' : '%'"
        :colors="colors"
        thousands=","
        :ytitle="yAxisTitle"
        :xtitle="xAxisTitle"
        :height="size === 25 ? '700px' : undefined"
        :messages="{ 'empty': 'No data found for this time range.' }"
      ></bar-chart>

      <b-loading
        :active.sync="isLoading"
        :is-full-page="false"
      ></b-loading>
    </div>
  </div>
</template>

<script>
import { fireInsightsEvent } from '@/events/events';
import LookbackRangePicker from '@/components/Insights/LookbackRangePicker';

export default {
  name: 'AttributionChart',
  components: {
    LookbackRangePicker
  },
  props: {
    title: {
      type: String,
      required: true
    },
    yAxisTitle: {
      type: String,
      required: true
    },
    payload: {
      type: Object,
      required: true
    },
    colors: {
      type: Array
    }
  },
  data() {
    return {
      insights: null,
      totalHits: 0,
      lookbackRange: '1d',
      size: 10,
      sizeOptions: [10, 25],
      // flags
      isLoading: false,
      hasNumbersViewSelected: false
    };
  },
  computed: {
    siteId() {
      return this.$store.state.siteId;
    },
    // returns insights data with percent or numbers based on what user has selected
    chartData() {
      let result = {};

      if (!this.insights) return result;

      if (this.hasNumbersViewSelected === false) {
        for (const audienceName in this.insights) {
          result[audienceName] = this.getPercent(this.insights[audienceName], this.totalHits);
        }
      } else {
        result = Object.assign(result, this.insights);
      }

      return result;
    },
    lookbackPhrase() {
      switch (this.lookbackRange) {
        case '1h':
          return 'Over The Last Hour';
        case '1d':
          return 'Over The Last Day';
        case '7d':
          return 'Over The Last 7 Days';
        case '30d':
          return 'Over The Last 30 Days';
        default:
          return 'Over Time';
      }
    },
    xAxisTitle() {
      let result = '% of Total Decisions Created';

      if (this.hasNumbersViewSelected) {
        result = 'Decisions Created';
      }

      return result;
    },
    smartTitle() {
      return `The Top ${this.size} ${this.title} ${this.lookbackPhrase}`;
    },
    trackingText() {
      if (!this.lookbackRange) return '';

      return `${this.size} | ${this.lookbackRange.toUpperCase()} | ${this.hasNumbersViewSelected ? '#' : '%'} | ${this.title.toUpperCase()}`;
    }
  },
  watch: {
    siteId(val) {
      if (val) {
        this.getInsights();
      }
    },
    size(val) {
      if (val) {
        this.getInsights();
      }
    },
    trackingText(newText) {
      fireInsightsEvent(newText);
    }
  },
  created() {
    this.getInsights();
  },
  methods: {
    handleRangeChange(newRange) {
      if (newRange) {
        this.lookbackRange = newRange;
        this.getInsights();
      }
    },
    handleSizeChange(newSize) {
      if (newSize) {
        this.size = newSize;
        this.getInsights();
      }
    },
    async getInsights() {
      this.isLoading = true;

      try {
        // we can dynamically modify the return count size here
        this.payload.terms.size = parseInt(this.size, 10);

        const res = await this.$axios.post(`/sites/${this.siteId}/insights/aggregates/${this.lookbackRange}`, this.payload);

        this.insights = res.data.counts;
        this.totalHits = res.data.total;
      } catch (error) {
        error.title = 'There was a problem retrieving insights data';
        this.$store.commit('error', error);
      }

      this.isLoading = false;
    },
    getPercent(num, total) {
      return Math.round((num / total) * 100);
    }
  }
};
</script>
