<template>
  <section class="insights__traffic-over-time is-flex">
    <div class="box insights__traffic-over-time-chart">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h5 class="title is-5">Decisions Created {{ intervalPhrase }}</h5>
          </div>
        </div>

        <div class="level-right">
          <div class="level-item">
            <LookbackRangePicker
              :options="['1h', '1d', '7d', '30d']"
              :is-loading="isLoading"
              :formatter="false"
              @selection="handleRangeChange"
              @refresh="getInsights"
            />
          </div>
        </div>
      </div><!-- /.level -->

      <div class="is-relative chart-parent">
        <line-chart
          v-if="chartData"
          :data="chartData"
          thousands=","
          :library="chartOptions"
          :messages="{ 'empty': 'No data found for this time range.' }"
        ></line-chart>

        <b-loading
          :active.sync="isLoading"
          :is-full-page="false"
        ></b-loading>
      </div>
    </div>

    <article class="notification is-info insights__traffic-over-time-info">
      <div class="content">
        <h2 class="title is-2 is-relative">
          {{ (totalTraffic && !isLoading) ? numberWithCommas(totalTraffic) : 'Loading...' }}
        </h2>
        <p class="subtitle is-6">
          Total Decisions Created {{ rangePhrase }}
        </p>

        <hr>

        <div>
          Data in this section is:
          <br>
          <b-icon icon="check"></b-icon> Real-Time<br>
          <b-icon icon="check"></b-icon> Accurate<br>
          <b-icon icon="check"></b-icon> From Decisions Created<br>
          <br>
          <p>This data is <strong>not</strong> 1:1 with sessions. While they can be close, Preamp does not create a new decision for every session.</p>
          <p><a
            href="https://redventures.atlassian.net/wiki/spaces/COHSN/pages/74429352/Audience+Insights"
            target="_blank"
          >Read More &amp; See Translation Here</a></p>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import { DateTime } from 'luxon';
import { numberWithCommas } from '@/modules/utilities';
import { fireInsightsEvent } from '@/events/events';
import LookbackRangePicker from '@/components/Insights/LookbackRangePicker';

export default {
  name: 'TrafficOverTimeChart',
  components: {
    LookbackRangePicker
  },
  data() {
    return {
      chartData: null,
      totalTraffic: 0,
      lookbackRange: '1d',
      isLoading: false
    };
  },
  computed: {
    siteId() {
      return this.$store.state.siteId;
    },
    intervalPhrase() {
      switch (this.lookbackRange) {
        case '1h':
          return 'Per Minute Over The Last Hour';
        case '1d':
          return 'Per Hour Over The Last Day';
        case '7d':
          return 'Per Day Over The Last 7 Days';
        case '30d':
          return 'Per Day Over The Last 30 Days';
        default:
          return 'Over Time';
      }
    },
    rangePhrase() {
      switch (this.lookbackRange) {
        case '1h':
          return 'Over The Last Hour';
        case '1d':
          return 'Over The Last Day';
        case '7d':
          return 'Over The Last 7 Days';
        case '30d':
          return 'Over The Last 30 Days';
        default:
          return 'Over Time';
      }
    },

    // https://www.chartjs.org/docs/latest/axes/
    chartOptions() {
      let unit = 'day';

      if (this.lookbackRange === '1d') {
        unit = 'hour';
      } else if (this.lookbackRange === '1h') {
        unit = 'minute';
      }

      return {
        responsive: true,
        scales: {
          x: {
            type: 'time',
            time: {
              unit
            }
          }
        }
      };
    },

    // we determine this for the user
    // An interval of 1h would be traffic per hour over 1 day
    interval() {
      let result = '1d';

      if (this.lookbackRange === '1d') {
        result = '1h';
      } else if (this.lookbackRange === '1h') {
        result = '1m';
      }

      return result;
    },

    // used for tagular tracking
    trackingText() {
      if (!this.lookbackRange) return '';

      return `${this.interval.toUpperCase()} | ${this.lookbackRange.toUpperCase()} | ${this.hasNumbersViewSelected ? '#' : '%'} | PER DAY`;
    }
  },
  watch: {
    // this watcher will update charts if the site changes
    siteId(val) {
      if (val) {
        this.getInsights();
      } else {
        this.chartData = null;
        this.totalTraffic = false;
      }
    },
    trackingText(newText) {
      fireInsightsEvent(newText);
    }
  },
  methods: {
    numberWithCommas,
    handleRangeChange(newRange) {
      if (newRange) {
        this.lookbackRange = newRange;
        this.getInsights();
      }
    },
    async getInsights() {

      this.isLoading = true;
      try {
        const res = await this.$axios.post(`/sites/${this.siteId}/insights/interval/${this.interval}/${this.lookbackRange}`);
        const chartData = {};
        for (const d of Object.keys(res.data.counts)) {
          const dt = DateTime.fromISO(d).toISO();

          chartData[dt] = res.data.counts[d];
        }
        this.chartData = chartData;
        this.totalTraffic = res.data.total;
      } catch (error) {
        this.$store.commit('error', error);
      }
      this.isLoading = false;

    }
  }
};
</script>

<style lang="scss" scoped>
.insights__traffic-over-time {
  align-items: stretch;
  flex-wrap: wrap-reverse;

  &-chart {
    flex: 3;
    margin: 5px;
    min-width: 680px;
  }

  &-info {
    flex: 1;
    margin: 5px;
    min-width: 350px;
  }
}
.notification.is-info {
  hr {
    margin: .75em 0;
  }

  blockquote {
    background-color: darken(#2398e8, 4%);
    border-color: whitesmoke;
    padding: 1em;
  }
}
</style>
