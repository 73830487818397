<template>
  <section class="test">

    <!-- Test Details -->
    <section v-if="isCO">
      <TestDetails
        :siteId="siteId"
        :test="test"
        :testTypeText="testTypeText"
      />

      <TestMetrics
        v-if="agentAnalytics"
        :experiences="clickOpExperiences"
        :agentAnalytics="agentAnalytics"
      />

      <!-- CO Display -->
      <section>
        <div class="card is-section-block test-card">
          <header class="card-header">
            <span class="card-header-title" data-cy-test="experience-title">Experiences</span>

            <b-field>
              <b-input
                v-model="experienceSearch"
                size="small"
                type="search"
                placeholder="Search by experience"
                icon="search"
                expanded
              ></b-input>
            </b-field>
          </header><!-- /.card-header -->

          <div class="card-content">
            <div v-if="hasLength(clickOpExperiences)">
              <TestExperienceMetrics
                v-for="experience in clickOpExperiences"
                :key="`co-exp-${experience.experienceId}`"
                :experience="experience"
                :optionRewards="(agentDetails?.optionRewardIds?.[experience.experienceId]) ? agentDetails.optionRewardIds[experience.experienceId] : null"
                :rewards="(agentDetails?.options?.[experience.experienceId]) ? agentDetails.options[experience.experienceId] : null"
                :winProbability="(agentDetails?.analytics?.winProb) ? agentDetails.analytics.winProb[experience.experienceId] : undefined"
                :isBestOption="agentAnalytics?.bestOption === experience.experienceId"
              />
            </div>

            <!-- Holdout Option: WIP -->
            <TestExperienceMetrics
              v-if="clickOpHoldoutExperience && agentDetails"
              :test="test"
              :experience="clickOpHoldoutExperience"
            />
          </div><!-- /.card-content -->
        </div><!-- /.test-card -->

        <!-- C.O. Reward IDs -->
        <div class="card is-section-block">
          <header class="card-header">
            <span class="card-header-title">Reward IDs</span>
          </header>

          <div class="card-content">
            <b-table
              v-if="clickOpRewards"
              narrowed
              :columns="[
                {
                  field: 'rewardId',
                  label: 'Reward ID'
                },
                {
                  field: 'value',
                  label: 'Reward Value',
                  numeric: true,
                  width: '150'
                },
                {
                  field: 'total',
                  label: 'Reward Sum Across Options',
                  numeric: true,
                  centered: true
                }
              ]"
              :data="clickOpRewards"
            >
              <template slot="empty">
                No reward data found for this test
              </template>
            </b-table>
          </div>
        </div>
      </section>
    </section>
    <div v-else class="card">
      <div class="card-content">
        <div class="tabs">
          <ul>
            <li :class="{ 'is-active': activeTab === 0}"><a
              href="javascript:void(0)"
              data-cy-test="details-tab"
              @click="activeTab = 0;"
            >Details</a></li>
            <li
              v-if="hasInsights"
              :class="{ 'is-active': activeTab === 1}"
            ><a
              href="javascript:void(0)"
              data-cy-test="insights-tab"
              @click="activeTab = 1;"
            >Insights</a></li>
          </ul>
        </div>

        <section v-if="hasTestData && activeTab === 0">
          <div
            v-if="isEnded"
            class="columns"
          >
            <div class="column">
              <article class="message is-success">
                <div class="message-body">
                  <strong>End test notes</strong>
                  <p
                    data-cy-test="end-test-notes"
                    v-html="notes"
                  ></p>
                </div>
              </article>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <label
                class="label"
                data-cy-test="test-details-title"
              >Test Details</label>

              <table class="table is-bordered is-hoverable is-narrow is-fullwidth">
                <tr>
                  <th>Name</th>
                  <td>
                    <span data-cy-test="name">{{ test.name }}</span>
                  </td>
                </tr>
                <tr>
                  <th data-cy-test="id">ID</th>
                  <td>{{ test.testId }}</td>
                </tr>
                <tr>
                  <th data-cy-test="created-at">Created At</th>
                  <td>{{ formatTime(test.createdAt) }}</td>
                </tr>
                <tr v-if="isEnded">
                  <th data-cy-test="ended-at">Ended At</th>
                  <td>{{ formatTime(test.endedAt) }}</td>
                </tr>
                <tr v-if="test.strategy.type">
                  <th>Type</th>
                  <td data-cy-test="type"> {{ testTypeText }}</td>
                </tr>
                <tr v-if="isEnded && test.testOutcome != null">
                  <th>Test Outcome</th>
                  <td>{{ test.testOutcome }}</td>
                </tr>
                <tr v-if="isEditable && !isEnded">
                  <th>Status</th>
                  <td>
                    <b-field>
                      <span data-cy-test="status">{{ status }}</span>
                    </b-field>
                  </td>
                </tr>
                <tr v-if="test.strategy.type === 'mabExp' && test.strategy.mabExp.dapi.expiration">
                  <th>Expiration</th>
                  <td>{{ test.strategy.mabExp.dapi.expiration | filterSecondsToReadableTime }}</td>
                </tr>
                <tr v-if="isEditable && !isEnded">
                  <th>Persistent</th>
                  <td>
                    <b-field>
                      <span data-cy-test="persistent">{{ test.isPersisted === 1 ? 'True' : 'False' }}</span>
                    </b-field>
                  </td>
                </tr>
                <tr v-if="minTrafficPct">
                  <th>Mininum Traffic Percentage</th>
                  <td>{{ minTrafficPct }}%</td>
                </tr>
              </table>
            </div><!-- /.column -->
            <div class="column">
              <section class="bottom20">
                <b-field label="Description">
                  <b-input
                    v-if="test.description"
                    readonly
                    :value="test.description"
                    type="textarea"
                    data-cy-test="description"
                  ></b-input>
                  <em v-else>No description was found</em>
                </b-field>
              </section>

              <section
                class="bottom20"
                data-cy-test="tags"
              >
                <b-field label="Tags">
                  <b-taginput
                    v-if="test.tags && test.tags.length"
                    v-model="test.tags"
                    disabled
                  ></b-taginput>
                  <em v-else>No tags were found</em>
                </b-field>
              </section>

              <section>
                <button
                  v-if="isMABExp"
                  class="button is-info"
                  data-cy-test="dapi-dashboard-button"
                  @click="handleDapi"
                >
                  <span class="icon"><i class="fa fa-external-link"></i></span>
                  <span>DAPI Dashboard</span>
                </button>
              </section>
            </div>
          </div><!-- /.columns -->

          <div class="columns">
            <!-- MAB EXP Display -->
            <div
              v-if="isMABExp"
              class="column is-full"
            >
              <MabExpDisplay
                v-if="test.strategy.mabExp"
                :testId="test.testId"
                :dapi="test.strategy.mabExp.dapi"
                :hold-out="test.strategy.mabExp.holdOut"
                :minTrafficPct="test.strategy.mabExp.minTrafficPct"
              />
            </div>

            <!-- MVT Display -->
            <div
              v-if="isMVT"
              class="column"
            >
              <div class="level">
                <div class="level-left">
                  <h6
                    class="title is-6"
                    data-cy-test="experience-title"
                  >Experiences</h6>
                </div>
                <div class="level-right">
                  <h6 class="title is-6">
                    Experience Allocation
                    <span :class="{ 'has-text-danger': totalExperienceWeight > 100 }">{{ totalExperienceWeight }}%</span>
                  </h6>
                </div>
              </div>

              <div v-if="hasLength(mvtExperiences)">
                <NestedExperience
                  v-for="experience in mvtExperiences"
                  :key="`mvt-exp-${experience.experienceId}`"
                  :experience="experience"
                  :allTests="allTests"
                  :allAddOns="addOns"
                  :experienceToTests="experienceToTests"
                  :is-editable="false"
                />

                <DefaultExperience
                  v-if="totalExperienceWeight < 100"
                  :points="100 - totalExperienceWeight"
                  :editing="false"
                />
              </div>
            </div><!-- /.column -->
          </div><!-- /.columns -->
        </section>

        <!-- Insights Tab -->
        <section v-if="hasInsights && activeTab === 1">
          <IndividualHitsOverTime
            :item-name="test.name"
            item-type="Test"
            :payload="{ 'testId.keyword': test.testId }"
            :siteId="siteId"
          />
        </section>
      </div><!-- /.card-content -->

      <footer
        v-if="shouldShowFooter && !isCO"
        class="card-footer"
      >
        <div class="level">
          <div class="level-left">
            <div
              class="level-item"
              data-cy-test="edit-button"
            >
              <base-button-edit @click="handleEdit" />
            </div>

            <div
              v-if="totalExperienceWeight > 100"
              class="level-item"
            >
              <p class="has-text-danger">
                <span class="icon">
                  <i class="fa fa-exclamation-circle"></i>
                </span>
                Experience allocation cannot exceed 100%
              </p>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <button
                id="btn-test-in-end'"
                :disabled="!userCanEdit"
                class="button is-danger"
                data-cy-test="end-test-button"
                aria-label="end test"
                @click="handleEndTestClick"
              >
                <span class="icon">
                  <i class="fa fa-stop"></i>
                </span>
                <span>End Test</span>
              </button>
            </div>
          </div>
        </div><!-- /.level -->
      </footer><!-- /.card-footer -->
    </div><!-- /.card -->

    <b-modal :active.sync="endTestModalActive">
      <form @submit.prevent>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">End Test "{{ test.name }}"</p>
          </header>

          <section class="modal-card-body">
            <div class="test-outcome-section">
              <b-field
                label="What was the outcome of this test?"
                :type="isTestOutcomeInvalid ? 'is-danger' : ''"
                :message="testOutcomeError.msg"
              >
                <template #label>
                  {{ testOutcomeLabel }} <span v-if="!!isTestOutcomeInvalid" class="has-text-danger">*</span>
                </template>
                <b-radio-button
                  v-model="testOutcome"
                  native-value="Down"
                  type="is-info is-outlined"
                  data-cy-test="end-test-outcome-radio-btn"
                >
                  Down
                </b-radio-button>
                <b-radio-button
                  v-model="testOutcome"
                  native-value="Flat"
                  type="is-info is-outlined"
                >
                  Flat
                </b-radio-button>
                <b-radio-button
                  v-model="testOutcome"
                  native-value="Up"
                  type="is-info is-outlined"
                >
                  Up
                </b-radio-button>
              </b-field>
            </div>
            <b-field
              label="End Test Notes"
              :type="!areTestNotesPresent ? 'is-danger' : ''"
            >
              <template #label>
                {{ testNotesLabel }}  <span v-if="!areTestNotesPresent" class="has-text-danger">*</span>
              </template>
              <b-input
                ref="notesInput"
                v-model="test.notes"
                placeholder="Please add any details about this test. For example, estimated impact (on a KPI or revenue) or next steps."
                type="textarea"
                required
                validation-message="Notes must be present to end the test."
                data-cy-test="end-test-notes"
              ></b-input>
            </b-field>

          </section>

          <footer class="modal-card-foot">
            <button
              class="button"
              @click="endTestModalClose"
            >
              Cancel
            </button>
            <b-tooltip
              position="is-right"
              type="is-light"
              label="Permanently remove this test from all associated audiences and as an option for future audiences"
            >
              <button
                class="button is-danger"
                data-cy-test="end-test"
                :disabled="!hasTestInfo"
                @click="handleEndTest"
              >
                <span class="icon">
                  <i class="fa fa-stop"></i>
                </span>
                <span>End Test</span>
              </button>
            </b-tooltip>
          </footer>
        </div>
      </form>
    </b-modal>

    <TestFormModal
      v-if="isNewTestModalActive"
      :test="editingTest"
      :external="external"
      @test-created="test => handleTestCreated(test, false)"
      @test-updated="test => handleTestCreated(test, true)"
      @cancel="handleCancelTestForm"
    />
  </section><!-- /.test -->
</template>

<script>
import { sync } from 'vuex-pathify';
import hash from 'object-hash';
import has from 'lodash.has';
import { DateTime } from 'luxon';
import {
  hasLength,
  clone,
  formatTime,
  formatTest
} from '@/modules/utilities';

import { bus } from '@/main';
import NestedExperience from '@/components/Experiences/NestedExperience';
import TestFormModal from '@/components/TestForm/TestFormModal';
import DefaultExperience from '@/components/DefaultExperience';
import IndividualHitsOverTime from '@/components/Insights/IndividualHitsOverTime';
import MabExpDisplay from '@/components/Tests/MabExpDisplay';
import TestExperienceMetrics from '@/components/Tests/TestExperienceMetrics';
import TestDetails from '@/components/Tests/TestDetails';
import TestMetrics from '@/components/Tests/TestMetrics';
import { sendTestData } from '../../events/events';

export default {
  name: 'Test',
  components: {
    DefaultExperience,
    NestedExperience,
    IndividualHitsOverTime,
    MabExpDisplay,
    TestFormModal,
    TestDetails,
    TestMetrics,
    TestExperienceMetrics
  },
  props: {
    holdOutOptions: {
      type: Array,
      required: true
    },
    initialTest: {
      type: Object,
      required: true
    },
    visitorsPct: {
      type: Number
    },
    experiences: {
      type: Array
    },
    addOns: {
      type: Array
    },
    allTests: {
      type: Array
    },
    experienceToTests: {
      type: Object
    },
    isEditable: {
      type: Boolean,
      required: true
    },
    hasInsights: {
      type: Boolean,
      default: false
    },
    showInsights: {
      type: Boolean,
      default: false
    },
    routeToTab: {
      type: String,
      required: false
    },
    insights: {
      type: Object,
      default() {
        return {
          percentage: 0,
          precisePercentage: 0
        };
      }
    },
    // external control for editing and ending
    actionTriggers: {
      type: Object,
      default() {
        return {
          editing: false,
          ending: false
        };
      }
    },
    external: {
      type: Object,
      default() {
        return {
          dapi: null
        };
      }
    }
  },

  data() {
    return {
      activeTab: 0,
      experienceSearch: '',
      endTestModalActive: false,
      isNewTestModalActive: false,
      test: clone(this.initialTest),
      agentExperiences: [],
      agentAnalytics: null,
      agentBestOption: null,
      totalRewardsAcrossOptions: {},
      experienceLookup: {},
      baseChecksum: null,
      isSavingTest: false,
      isLoading: {
        dapi: false
      },
      testNotesLabel: 'End test notes',
      testOutcome: null,
      testOutcomeLabel: 'What was the outcome of this test?',
      testOutcomeError: {
        isError: false,
        msg: ''
      }
    };
  },
  computed: {
    editingTest: sync('editing@test'),
    shouldShowFooter() {
      return this.isEditable
      && !this.isEnded
      && this.activeTab === 0
      && !this.editingTest;
    },
    hasTestInfo() {
      this.handleTestOutcomeError();
      if (this.notes === null || this.testOutcome === null) return false;
      else {
        return this.notes.length && this.testOutcome.length ? true : false;
      }
    },
    siteId() {
      return this.$store.state.siteId;
    },
    isPaused() {
      return this.test.isPaused === 1;
    },
    hasUnsavedChanges() {
      return this.baseChecksum !== hash(this.test);
    },
    userCanEdit() {
      return this.$store.getters.userCanEdit;
    },
    isSuperUser() {
      return this.$store.getters.isSuperUser;
    },
    isMABExp() {
      return has(this.test, 'strategy.mabExp');
    },
    isMVT() {
      return has(this.test, 'strategy.mvt');
    },
    isCO() {
      return has(this.test, 'strategy.clickOptimization');
    },
    isEnded() {
      return this.test.ended === 1;
    },
    notes() {
      if (this.test.notes !== undefined) {
        return this.test.notes.replace(/\n/g, '<br/>');
      }
      return null;
    },
    status() {
      if (this.isEnded) return 'Ended';
      if (this.isPaused) return 'Paused';
      if (this.test.isActive) return 'Active';
      return 'Inactive';
    },
    hasMvtExperiences() {
      return (
        this.isMVT
        && has(this.test, 'strategy.mvt.experiences')
        && hasLength(this.test.strategy.mvt.experiences)
      );
    },
    minTrafficPct() {
      if (this.isMABExp) {
        return this.test.strategy.mabExp.minTrafficPct;
      } return 0;
    },
    mvtExperiences() {
      let result = [];
      if (this.hasMvtExperiences) {
        result = this.test.strategy.mvt.experiences
          .filter(exp => exp.experienceId !== '0');
      }
      return result;
    },
    hasTestData() {
      return has(this.test, 'strategy');
    },
    totalExperienceWeight() {
      if (!this.hasMvtExperiences) return 0;
      return this.mvtExperiences
        .map(exp => exp.weight)
        .reduce((acc, curr) => acc + curr);
    },
    testTypeText() {
      switch (this.test.strategy.type) {
        case 'mvt':
          return 'Multi-Variate';
        case 'mabExp':
          return 'Smart Experience';
        case 'clickOptimization':
          return 'Click Optimization';
        default:
          return `Unsupported Type: ${this.test.strategy.type}`;
      }
    },
    isUsingBaseExperience() {
      const exps = this.test?.strategy?.clickOptimization?.options;

      return (exps) ? exps.map(exp => exp.experienceId).indexOf('0') !== -1 : false;
    },
    agentDetails() {
      return this.external?.dapi?.agent || null;
    },
    clickOpHoldoutExperience() {
      return (this.test?.strategy?.clickOptimization?.holdout?.option) ? this.experienceLookup[this.test.strategy.clickOptimization.holdout.option] : null;
    },
    clickOpExperiences() {
      const exps = this.test?.strategy?.clickOptimization?.options;

      return (exps) ? exps.filter(exp => exp && exp.name.indexOf(this.experienceSearch) !== -1) : [];
    },
    agentRewards() {
      if (this.isCO === false || !this.agentDetails || typeof this.agentDetails?.rewards !== 'object') return undefined;

      return Object.entries(this.agentDetails.rewards).map(function ([rewardId, value]) {
        return { rewardId, value };
      });
    },
    // create a rewards active computed based off optionRewardIds to get active/inactive
    clickOpRewards() {
      if (Array.isArray(this.agentRewards) === false) return [];

      return this.agentRewards.map(({ rewardId, value }) => {
        return { rewardId, value, total: this.totalRewardsAcrossOptions[rewardId] || 0 };
      }).sort(function (a, b) {
        if (a.value > b.value) return -1;
        if (a.value < b.value) return 1;
        return 0;
      });
    },
    activeRewards() {
      let result = {};

      if (typeof this.agentDetails?.optionRewardIds === 'object' && !!this.agentDetails.optionRewardIds) {
        result = Object.keys(this.agentDetails.optionRewardIds).reduce((obj, experienceId) => {
          const optionRewards = Object.keys(this.agentDetails.optionRewardIds[experienceId]);
          for (const rewardId of optionRewards) {
            if (!obj[rewardId]) obj[rewardId] = [];

            if (obj[rewardId].indexOf(experienceId) === -1) obj[rewardId].push(experienceId);
          }
          return obj;
        }, {});
      }

      return result;
    },
    isTestOutcomeInvalid() {
      if (this.testOutcome === null) return true;
      else return false;
    },
    areTestNotesPresent() {
      if (this.notes === null || this.notes === '') return false;
      else return true;
    }
  },
  watch: {
    hasUnsavedChanges(bool) {
      this.$store.commit('setHasUnsavedChanges', bool);
    },
    editingTest(test) {
      if (test?.strategy?.mabExp || test?.strategy?.mvt || test?.strategy?.clickOptimization) {
        this.isNewTestModalActive = true;
      } else if (!test) {
        this.$emit('reset-action-triggers');
      }
    },
    'actionTriggers.editing'(newVal) {
      if (newVal === true && !this.editingTest) {
        this.handleEdit();
      }
    },
    'actionTriggers.ending'(newVal) {
      if (newVal && !this.endTestModalActive) {
        this.endTestModalLaunch();
      }
    },
    endTestModalActive(nowActive, wasActive) {
      if (wasActive && !nowActive) this.$emit('reset-action-triggers');
    }
  },
  created() {
    this.$emit('get-test');
    this.registerEventListeners();
    if (this.test.testId) {
      this.getExternalTestData();
    }
    this.checkForRequestedTab();
  },
  beforeDestroy() {
    bus.$off('site-lock-lost-user', this.handleSiteLockLost);
  },
  updated() {
    if (this.test.testId && !this.test.name) {
      this.$emit('get-test');
    }
  },
  methods: {
    handleEndTestClick() {
      this.$root.establishSiteLock().then(() => {
        this.endTestModalLaunch();
        this.elementClick();
      });
    },
    /**
     * Emit element click event
     * @param {Object} e - element click event
     */
    elementClick(e) {
      bus.$emit('elem-click', e);
    },
    async saveTest() {
      this.isSavingTest = true;

      try {
        const test = formatTest(this.test);

        switch (this.test?.strategy?.type) {
          case 'mvt':
          case 'clickOptimization':
            await this.$axios.post(`/sites/${this.siteId}/tests/${test.testId}`, { test });
            break;
          case 'mabExp':
            await this.$axios.put(`/sites/${this.siteId}/tests/mabExp/${test.testId}`, { test });
            break;
          default:
            this.$buefy.toast.open({
              message: 'Cannot save a test with an unknown or unspecified testing strategy',
              type: 'is-warning'
            });
            break;
        }

        // handle success for either test type
        this.handleUpdateSuccess();
      } catch (error) {
        this.$store.commit('error', error, 'There was a problem updating the test. Please try again later.');
      }

      this.isSavingTest = false;
    },
    handleUpdateSuccess() {
      this.editingTest = null;
      this.notifyUpdateSuccessAndPauseStatus();
      this.$emit('test-updated');
      this.$emit('get-test');
      this.getExternalTestData(true);
    },
    handleTestCreated(test, updated = false) {
      this.isNewTestModalActive = false;

      if (updated) {
        if (this.isCO) {
          window.location.reload();
        } else {
          this.handleUpdateSuccess();
        }
      }

      this.$buefy.toast.open({
        message: `${test.name || 'Test'} was successfully ${updated ? 'updated' : 'created'}. Refreshing test...`,
        type: 'is-success'
      });
    },
    handleCancelTestForm() {
      this.isNewTestModalActive = false;
      this.editingTest = null;
    },
    handleTestOutcomeError() {
      if (this.testOutcome === null) {
        this.testOutcomeError = {
          isError: true,
          msg: 'A test outcome must be chosen to end the test.'
        };
      } else if (this.testOutcome.length) {
        this.testOutcomeError = {
        isError: false,
        msg: ''
        };
      }
    },
    notifyUpdateSuccessAndPauseStatus() {
      let successMsg = `${this.test.name} was successfully updated`;
      if (this.initialTest.isPaused !== this.test.isPaused) {
        const newStatus = (this.test.isPaused === 1) ? 'paused' : 'running';
        successMsg = `${successMsg} and is now ${newStatus}`;
      }
      this.$buefy.toast.open({
        message: successMsg,
        type: 'is-success'
      });
    },
    resetTest() {
      this.editingTest = null;
      this.$emit('get-test');
    },
    endTestModalLaunch() {
      this.endTestModalActive = true;
    },
    endTestModalClose() {
      this.endTestModalActive = false;
      this.endTestNotes = '';
    },
    handleEndTest() {
      if (this.isMABExp) {
        this.endMabExpTest(this.test.notes, this.testOutcome);
      } else if (this.isCO) {
        this.endCOTest(this.test.notes, this.testOutcome);
      } else {
        this.test.ended = 1;
        this.testOutcome;
        this.prepareAndSendTestOutcomeTrackingPayload();
        this.saveTest();
      }

      this.endTestModalActive = false;
      this.endTestNotes = '';
    },
    async endCOTest(notes, testOutcome) {
      console.log(testOutcome);
      this.isSavingTest = true;

      const test = clone(this.initialTest);
      test.ended = 1;
      test.strategy.clickOptimization.options = test.strategy.clickOptimization.options.map(exp => exp.experienceId || exp);
      test.notes = notes;
      test.testOutcome = testOutcome;

      try {
        await this.$axios.post(`/sites/${this.siteId}/tests/${this.test.testId}`, {
          test
        });

        await this.prepareAndSendTestOutcomeTrackingPayload();

        this.$buefy.toast.open({
          message: `${this.test.name} has been ended`,
          type: 'is-success'
        });

        // not ideal, but many moving pieces needing updating in place here
        window.location.reload();
      } catch (err) {
        this.$store.commit('error', err, `An error occurred while ending the test: ${this.test.name}`);
      }

      this.isSavingTest = false;
    },
    async prepareAndSendTestOutcomeTrackingPayload() {
        //user name
      const { user } = this.$store.state;
        //site name
      const siteName = this.$store.getters.siteDomain;
        //account ID
      const accountId = this.$store.state.activeAccount;
        // accounts
      const { accounts } = this.$store.state.user;

      const accountMatch = accounts.find(a => a.accountId === accountId);
      const accountName = accountMatch.name;

        const payload = {
          test: this.test.name,
          testOutcome: this.testOutcome,
          user: user.email,
          siteName: siteName,
          accountName: accountName,
          notes: this.test.notes
        };

        sendTestData(payload);
    },
    async endMabExpTest(notes, testOutcome) {
      this.isSavingTest = true;

      try {
        await this.$axios.put(`/sites/${this.siteId}/tests/mabExp/${this.test.testId}/end`, {
          notes,
          testOutcome
        });

        await this.prepareAndSendTestOutcomeTrackingPayload();

        this.$buefy.toast.open({
          message: `${this.test.name} has been ended`,
          type: 'is-success'
        });
        this.$emit('test-updated');
        this.$emit('get-test');
        // TODO: Look into why emits aren't working properly for CO & MabExp tests
      } catch (err) {
        this.$store.commit('error', err, `An error occurred while ending the test: ${this.test.name}`);
      }

      this.isSavingTest = false;
    },
    async getAgentDetails(testId) {
      this.isLoading.dapi = true;

      try {
        const res = await this.$axios.get(`/sites/${this.siteId}/tests/${testId}/agent-details`);

        this.external.dapi = {
          agent: res.data.agent
        };

        if (res.data.agent.optionRewardIds && Object.keys(res.data.agent.optionRewardIds).length) {
          const rewardTotals = {};

          for (const expId in res.data.agent.optionRewardIds) {
            for (const rId in res.data.agent.optionRewardIds[expId]) {
              if (!rewardTotals[rId]) rewardTotals[rId] = 0;

              rewardTotals[rId] += res.data.agent.optionRewardIds[expId][rId];
            }
          }

          this.totalRewardsAcrossOptions = rewardTotals;
        }

        this.agentExperiences = res.data.experiences;
        this.experienceLookup = res.data.experiences.reduce(function (obj, exp) {
          obj[exp.experienceId] = exp;

          return obj;
        }, {});
        this.agentAnalytics = res.data.analytics;

        const csvResult = this.formatClickOpCSVData(this.test, res.data.experiences, res.data.agent, res.data.analytics);
        this.$emit('set-csv-data', csvResult);
      } catch (err) {
        this.$store.commit('error', err, 'Could not get agent details for this test');
      }

      this.isLoading.dapi = false;
    },
    formatClickOpCSVData(test, experienceOptions, agent, analytics) {
      const fileNameDate = DateTime.now().toISODate();
      // fileName is testName-ended/running-report_YYYY-MM-DD
      const fileName = `${test.name}-${(test.ended === 1) ? 'ended' : 'running'}-report_${fileNameDate}`;
      const headers = ['test', 'agent', 'experience', 'experienceNumber', 'isCurrentTestOption', 'isHoldout', 'successes', 'failures', 'inferredFailures', 'unreported', 'revenue', 'revenuePerSession', 'winProbability'];
      const csvData = [];

      if (!test || (!agent.options && !agent.holdoutOptions) || !analytics) return { csvData, headers, fileName };

      const experienceMap = experienceOptions.reduce(function (obj, exp) {
        obj[exp.experienceId] = exp;

        return obj;
      }, {});

      function createRow(expId, isCurrentOption, isHoldout) {
        const exp = experienceMap[expId];
        const optionDetails = agent.options[expId];

        const row = {};

        row.test = test.name;
        row.agent = test?.strategy?.clickOptimization?.agentId || 'N/A';
        row.experience = exp.name || 'N/A';
        row.experienceNumber = exp.experienceNumber;
        row.isCurrentTestOption = isCurrentOption;
        row.isHoldout = isHoldout;
        row.successes = optionDetails.success;
        row.failures = optionDetails.inferredFailure;
        row.unreported = optionDetails.unreported;
        row.revenue = optionDetails.value;
        row.revenuePerSession = (optionDetails.value / (optionDetails.success + optionDetails.failure + optionDetails.inferredFailure)).toFixed(2);
        row.winProbability = (analytics.winProb[expId] * 100).toFixed(2);

        return row;
      }

      for (const expId in agent.options) {
        const isCurrentOption = this.clickOpExperiences.find(e => e.experienceId === expId) !== undefined;
        const row = createRow(expId, isCurrentOption, false);

        csvData.push(row);
      }

      if (agent.holdoutOptions) {
        for (const expId in agent.holdoutOptions) {
          const isCurrentOption = this.clickOpHoldoutExperience?.experienceId === expId;
          const row = createRow(expId, isCurrentOption, true);

          csvData.push(row);
        }
      }

      return { csvData, headers, fileName };
    },
    getExternalTestData(force) {
      if (
        this.isCO
        && has(this.test, 'strategy.clickOptimization.agentId')
        && (!this.external.dapi || force === true)
      ) {
        this.getAgentDetails(this.test.testId);
      }
    },
    handleEdit() {
      this.$root.establishSiteLock().then(() => {
        this.editingTest = clone(this.test);
        this.isNewTestModalActive = true;
      });
    },
    handleSiteLockLost() {
      this.resetTest();
      this.endTestModalActive = false;
    },
    registerEventListeners() {
      bus.$on('site-lock-lost-user', this.handleSiteLockLost);
    },
    // creates a baseline checksum that can be used to detect if changes are made to the test
    setBaseChecksum() {
      this.baseChecksum = hash(this.test);
    },
    // this function will open a specified test, and subsequently a specified tab,
    // by checking for props on the route
    checkForRequestedTab() {
      // don't open a tab if this test wasn't the intended destination
      if (!this.autoExpand) return;
    },
    handleDapi() {
      window.open(`https://cohesion-dapi-dash.datascience.redventures.io/app/?_inputs_&client="11"&tenant="${this.$store.getters.siteTenantId}"`);
    },
    // ------------------------
    // Utility Methods
    // ------------------------
    formatTime,
    hasLength
  }
};
</script>

<style scoped lang="scss">
.test-outcome-section {
  margin-bottom: 5%;
}
</style>

