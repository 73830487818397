<template>
  <div
    class="rule-form"
    :class="{ 'has-single-rule': !isEditing && rulesInBlock === 1 }"
  >
    <div
      v-if="!isEditing"
      class="rule-form__or-text"
    >OR</div>

    <div class="columns is-variable is-1">
      <div class="column is-4">
        <b-field grouped>
          <b-field
            v-if="isEditing && ruleIndex >= 1"
            class="rule-group__or-container"
          >
            <div class="rule-group__or-text">OR</div>
          </b-field>

          <b-field
            :type="{ 'is-danger': !pathValidation.valid }"
            :message="(!pathValidation.valid) ? pathValidation.msg : ''"
            expanded
          >
            <b-autocomplete
              v-model.trim="rulePathDisplay"
              :data="filteredData"
              open-on-focus
              :disabled="!isEditing"
              field="label"
              placeholder="Choose a Path"
              data-cy-test="choose-path"
              :icon-right="showIntegrationConfig ? 'cog' : ''"
              :icon-right-clickable="showIntegrationConfig"
              @select="handlePathAutoSelection"
              @blur="validatePath"
              @icon-right-click.stop="handleOpenIntegrationModal"
            >
              <template slot="empty">No results found</template>
            </b-autocomplete>
          </b-field>
        </b-field>
      </div><!-- /.column -->

      <div class="column is-3">
        <b-field
          :type="{ 'is-danger': !validateComparator.valid }"
          :message="(!validateComparator.valid) ? validateComparator.msg: ''"
        >
          <b-select
            :id="`new-comparator-select-${rule.comparator}`"
            v-model="rule.comparator"
            expanded
            name="new-comparator-select"
            data-cy-test="choose-comparator"
            :disabled="!isEditing"
            @input="handleComparatorSelection"
            @blur="interactedInputs.comparator = true"
          >
            <option
              value
              disabled="disabled"
              hidden="hidden"
            >Choose a Comparator</option>
            <option
              v-for="(comparator, index) in comparators"
              :id="`option-rule-comparator-${comparator.value}`"
              :key="`comparator-${index}`"
              :data-cy-test="`option-rule-comparator-${comparator.value}`"
              :value="comparator.value"
            >
              {{ comparator.label }}
            </option>
          </b-select>
        </b-field>
      </div><!-- /.column -->

      <div
        class="column"
        :class="isEditing ? 'is-4' : 'is-5'"
      >
        <!-- Date Time rules -->
        <DateTimeRules
          v-if="isDateTimeRule === true"
          :rule="rule"
          :path-data="rulePathData"
          :is-editing="isEditing"
        />

        <!-- ARL Rules -->
        <ARLRules
          v-else-if="isARLRule === true"
          :rule.sync="rule"
          :is-editing="isEditing"
        />

        <!-- All other input rules -->
        <b-field v-else>
          <div class="control">
            <b-input
              v-model="rule.value"
              :disabled="hasDisabledValueInput"
              type="text"
              placeholder="Rule Value"
              data-cy-test="rule-value"
              @blur="interactedInputs.value = true"
            />
            <!-- Have to do this in order to properly position help message. -->
            <p
              v-if="validateValue.msg"
              class="help"
              :class="{ 'is-danger': !validateValue.valid, 'is-info': validateValue.type === 'warn' }"
            >
              {{ validateValue.msg }}
            </p>
          </div>
          <p
            v-if="rule.comparator === 'matches'"
            class="control"
          >
            <button
              id="btn-regex-match-test"
              class="button"
              :class="{ 'is-info': showRegexTest }"
              title="Test Regex"
              @click="showRegexTest = !showRegexTest"
            >
              <strong>.*</strong>
            </button>
          </p>
        </b-field>
      </div><!-- /.column -->

      <div
        v-if="isEditing && hasDeletableRules"
        class="column is-1"
      >
        <b-field>
          <button
            class="button"
            @click="$emit('delete-rule')"
          >
            <span class="icon has-text-danger"><i class="fa fa-times"></i></span>
          </button>
        </b-field>
      </div><!-- /.column -->
    </div><!-- /.columns -->

    <div
      v-if="rule.comparator === 'matches' && showRegexTest"
      class="columns rule-form"
    >
      <div class="column is-11">
        <b-field :type="(regexValidation) ? 'is-success' : 'is-danger'">
          <b-input
            v-model="validationTest"
            expanded
            placeholder="Test a value against your Regex"
          />
        </b-field>
      </div>
    </div>
    <IntegrationConfigMessage
      v-if="showIntegrationConfig"
      class="rule-integration-select"
      :integrationName="selectedIntegrationName"
      :integrationType="selectedIntegrationType"
    ></IntegrationConfigMessage>
    <IntegrationSelectModal
      v-if="isIntegrationModalActive"
      v-model="isIntegrationModalActive"
      :ruleIndex="ruleIndex"
      :filteredIntegrations="filteredIntegrations"
      :integrationId="selectedIntegrationId"
      :integrationType="selectedIntegrationType"
      @updateIntegrationName="handleUpdateIntegrationName"
    ></IntegrationSelectModal>
  </div>
</template>

<script>
import { hasLength } from '@/modules/utilities';
import { allRules, specialPaths, specialLabels, allComparators, invalidRulePaths } from '@/modules/ruleList';
import { ElementClicked } from '@/events/ui';
import { events } from '@/events/events';
import { bus } from '@/main';
import DateTimeRules from '@/components/Rules/DateTimeRules';
import ARLRules from '@/components/Rules/ARLRules';
import IntegrationSelectModal from './IntegrationConfig/IntegrationSelectModal.vue';
import IntegrationConfigMessage from './IntegrationConfig/IntegrationConfigMessage.vue';
import { filterIntegrationsByType, getAllIntegrationTypes } from '@/modules/integrations';

export default {
  name: 'RuleForm',
  components: {
    DateTimeRules,
    ARLRules,
    IntegrationSelectModal,
    IntegrationConfigMessage
  },
  props: {
    rule: {
      type: Object,
      default() {
        return {
          path: '',
          comparator: '',
          value: '',
          options: {
            integrationId: null
          }
        };
      }
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    blockPosition: {
      type: Number,
      required: true
    },
    ruleIndex: {
      type: Number,
      required: true
    },
    rulesInBlock: {
      type: Number,
      required: true
    },
    allIntegrations: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      ruleList: allRules.filter((item) => {
        if (!this.$store.getters.isDMPEnabled && item.value.startsWith('dmp.')) {
          return false;
        }
        if (!this.$store.getters.isMakeEnabled && (item.value.startsWith('make.') || item.value.startsWith('dna.') || item.value.startsWith('dnaxs.'))) {
          return false;
        }
        if (!this.$store.getters.isSegmentEnabled && (item.value.startsWith('segment.') || item.value.startsWith('segmentxs.'))) {
          return false;
        }
        if (!this.$store.getters.isJarvisEnabled && item.value.startsWith('jarvis.')) {
          return false;
        }
        if (!this.$store.getters.isTIPEnabled && (item.value.startsWith('geo.') || (item.value.startsWith('userAgent.')))) {
          return false;
        }
        if (!this.$store.getters.isContentEnabled && (item.value.startsWith('content.'))) {
          return false;
        }
        return true;
      }),
      validSpecialPaths: specialPaths.filter((item) => {
        if (!this.$store.getters.isDMPEnabled && item === 'dmp.') {
          return false;
        }
        if (!this.$store.getters.isMakeEnabled && (item === 'make.' || item === 'dna.')) {
          return false;
        }
        if (!this.$store.getters.isSegmentEnabled && (item === 'segment.' || item === 'segmentxs.')) {
          return false;
        }
        if (!this.$store.getters.isJarvisEnabled && item === 'jarvis.') {
          return false;
        }
        return true;
      }),
      interactedInputs: {
        path: false,
        comparator: false,
        value: false
      },
      validationTest: '',
      showRegexTest: false,
      pathValidation: {
        valid: true,
        msg: null
      },
      isIntegrationModalActive: false,
      selectedIntegrationName: '',
      selectedIntegrationId: null,
      invalidRulePaths
    };
  },
  computed: {
    siteId() {
      return this.$store.state.siteId;
    },
    rulePaths() {
      return this.ruleList.map(rule => rule.value);
    },
    allIntegrationTypes () {
      return getAllIntegrationTypes();
    },
    filteredIntegrations() {
      return filterIntegrationsByType(this.selectedIntegrationType, this.allIntegrations);
    },
    selectedIntegrationType() {
      // eslint-disable-next-line
      const [rootPath,custom] = this.rule.path.split('.');

      if (this.allIntegrationTypes.includes(rootPath)){
        return rootPath;
      } else {
        return null;
      }
    },
    isIntegrationSubject() {
      return this.selectedIntegrationType ? true : false;
    },
    showIntegrationConfig() {
      return this.$store.getters['integrations/isIntegrationConfigEnabled']
        && this.isEditing
        && this.isIntegrationSubject
        && this.filteredIntegrations.length > 0;
    },
    // Convert value to label when getting rule path
    // and label to value when setting rule path
    rulePathDisplay: {
      get() {
        const foundRule = this.ruleList.find(obj => obj.value === this.rule.path);
        if (foundRule) {
          return foundRule.label;
        }
        const specialPathMatch = this.validSpecialPaths.find(specialPath => this.rule.path.startsWith(specialPath));
        if (specialPathMatch) {
          // We need to take the beginning of the found rule label and attach the custom part
          // Get the label of the special path value
          const specialLabel = this.ruleList.find(obj => obj.value.startsWith(specialPathMatch)).label;
          // Extract the beginning of the label before the custom part
          // ex. firstRequest.query.PARAM_NAME_HERE -> firstRequest.query.
          const [,specialLabelBeginning] = /(.+\.)[^.]+$/.exec(specialLabel);
          // ex. request.query.stuff -> stuff
          const [,customPart] = this.rule.path.split(specialPathMatch);
          // ex. firstRequest.query. + stuff -> firstRequest.query.stuff

          return specialLabelBeginning + customPart;
        }
        return this.rule.path;
      },
      set(val) {
        const foundRule = this.ruleList.find(obj => obj.label === val);
        if (foundRule) {
          this.rule.path = foundRule.value;
          return;
        }
        const specialLabelMatch = specialLabels.find(specialPath => val.startsWith(specialPath));
        if (specialLabelMatch) {
          // We need to take the beginning of the found rule value and attach the custom part
          // Get the value of the special path label
          const specialValue = this.ruleList.find(obj => obj.label.startsWith(specialLabelMatch)).value;
          // Extract the beginning of the label before the custom part
          // ex. request.query.PARAM_NAME_HERE -> firstRequest.query.
          const [,specialValueBeginning] = /(.+\.)[^.]+$/.exec(specialValue);
          // ex. request.query.PARAM_NAME_HERE -> firstRequest.query
          // ex. firstRequest.query.stuff -> stuff
          const [,customPart] = val.split(specialLabelMatch);
          // ex. request.query. + stuff -> request.query.stuff
          this.rule.path = specialValueBeginning + customPart;
          return;
        }
        this.rule.path = val;
      }
    },
    // Validate that a comparison is selected
    validateComparator() {
      if (!this.interactedInputs.comparator) return { valid: true };
      const validation = { msg: null, valid: false };
      if (!this.rule.comparator || !this.rule.comparator.length) {
        validation.msg = 'Select a comparison type.';
        return validation;
      }

      validation.valid = true;
      return validation;
    },
    // Validate that a value exists and warn if it's got white space
    validateValue() {
      if (!this.interactedInputs.value) return { valid: true };

      const validation = { msg: null, type: 'err', valid: false };

      // Convert value for validation
      const value = (typeof this.rule.value === 'number')
        ? this.rule.value.toString()
        : this.rule.value;

      if (!value.trim().length) {
        validation.msg = 'This rule needs a value.';
        return validation;
      }

      if (this.rule.comparator === 'matches') {
        try {
          const re = new RegExp(`${this.rule.value}`); // eslint-disable-line
        } catch (err) {
          validation.msg = 'Invalid regular expression.';
          return validation;
        }
      }

      if (value.endsWith(' ')) {
        validation.type = 'warn';
        validation.msg = 'Your string contains a trailing space.';
      }

      if (value.startsWith(' ')) {
        validation.type = 'warn';
        validation.msg = 'Your string contains a leading space.';
      }

      validation.valid = true;
      return validation;
    },
    // Check if the comparator is a boolean, to disable value input
    isBooleanRule() {
      return !hasLength(this.rule.comparator) ? false : this.rule.comparator.match('exists');
    },
    // Check if the path is a date/time path, replace value input with date/time picker
    isDateTimeRule() {
      const rulePathRE = /(clientTime|serverTime)\.(year|month|day|hour|minute|dayOfWeek|utcOffset|iso)/g;
      return this.rule.path.length > 0 && this.rule.path.match(rulePathRE) !== null;
    },
    // Check if the comparator is an ARL comparison.
    isARLRule() {
      const { comparator } = this.rule;
      return comparator === 'in' || comparator === 'nin';
    },
    // Filter data for autocomplete
    filteredData() {
      if (!hasLength(this.rulePathDisplay)) return this.ruleList;
      return this.ruleList.filter(obj => obj.label.match(new RegExp(this.rulePathDisplay, 'gi')));
    },
    comparators() {
      let result = allComparators;
      if (hasLength(this.rule.path)) {
        if (this.filteredData.length === 1) {
          if (this.filteredData[0].boolean) {
            result = result.filter(comp => comp.value === 'exists' || comp.value === 'nexists');
          } else if ((this.filteredData[0].array)) {
            result = result.filter(comp => comp.value === 'contains' || comp.value === 'ncontains');
          } else {
            result = result.filter(comp => !comp.value.match('exists'));
          }
        }
      }
      return result;
    },
    /**
     * Since this happens as the user types, errors will be thrown for invalid regex
     * lets catch those and just return an invalid match
     */
    regexValidation() {
      if (this.rule.comparator !== 'matches') return false;
      try {
        const re = new RegExp(`${this.rule.value}`);
        return re.test(this.validationTest);
      } catch (err) {
        return false;
      }
    },
    rulePathData() {
      if (!this.rule.path || !this.rule.path.length) return null;

      return this.ruleList.find(r => r.value === this.rule.path);
    },
    hasDisabledValueInput() {
      return this.isBooleanRule || !this.isEditing || !hasLength(this.rule.path) || !hasLength(this.rule.comparator);
    },
    hasDeletableRules() {
      return this.blockPosition === 0 ? this.rulesInBlock > 1 : true;
    }
  },
  watch: {
    selectedIntegrationType(oldVal, newVal) {
      // if we have a new type, either a new integration type or none, clear the integration details
      // and set integrationId on the rule to null
      if (newVal !== oldVal) {
        this.selectedIntegrationId = null;
        this.selectedIntegrationName = null;

        bus.$emit('updateRuleIntegrationId', {ruleIndex: this.ruleIndex, integrationId: null});
      }
    }
  },
  created () {
    const { options } = this.rule || {};
    const { integrationId } = options || {};

    // get integration details if one exists on rule already
    if (integrationId && this.filteredIntegrations.length) {
      const selectedIntegration = this.filteredIntegrations.find((int) => { return int.integrationId === integrationId;});
      const { name } = selectedIntegration || {};

      this.selectedIntegrationId = integrationId;
      this.selectedIntegrationName = name;
    }
  },
  methods: {
    /**
     * Emit element click event
     * @param {Object} e - element click event
     */
    elementClick(e) {
      bus.$emit('elem-click', e);
    },
    /**
     * Set the path based on selected path object
     * @param {Object} option - path object
     */
    handlePathAutoSelection(option) {
      if (option && hasLength(option.value)) {
        this.rule.path = option.value.trim();
        this.validatePath();
      }
    },
    /**
     * Check and set value if comparator is a boolean
     * @param {String} val - selected comparator value
     */
    handleComparatorSelection(val) {
      // if val matches 'exists', it is a boolean, set value as 'n/a' for db
      if (val && val.match('exists')) this.rule.value = 'n/a';
      if (val && val.match('matches')) {
        const htmlId = 'new-comparator-select-matches';
        const data = events[htmlId];
        data.htmlId = htmlId;
        if (window.tagular) {
          window.tagular('beam', ElementClicked(data));
        }
      }
    },
    // Validate that a path exists and it is allowable
    async validatePath() {
      this.interactedInputs.path = true;
      // Make sure the field is filled out
      if (!this.rule.path.length) {
        this.pathValidation.valid = false;
        this.pathValidation.msg = 'Select a valid path.';
        return;
      }

      // Validate the paths/custom paths to make sure they exist
      let match = false;
      if (this.rulePaths.indexOf(this.rule.path) >= 0) {
        match = true;
      } else {
        // Check if it's a custom type
        for (let i = 0; i < this.validSpecialPaths.length; i++) {
          const path = this.validSpecialPaths[i];
          const re = new RegExp(`^${path.replace(/\./g, '\\.')}`, 'g');
          if (this.rule.path.match(re)) {
            match = true;
            break;
          }
        }
      }

      if (match === false  || this.invalidRulePaths.includes(this.rule.path)) {
        this.pathValidation.valid = false;
        this.pathValidation.msg = `${this.rule.path} is an invalid path.`;
        return;
      }

      if (this.rule.path.startsWith('make.')) {
        const isAuthorized = await this.checkMakeWorkspaceConfiguration();
        if (!isAuthorized) {
          this.pathValidation.valid = false;
          this.pathValidation.msg = 'You must install the Preamp application on your workspace before creating Make audiences.';
          return;
        }
      }

      this.pathValidation.valid = true;
      this.pathValidation.msg = null;
      return;
    },
    /**
     * Check if the current site's make destination is properly configured for preamp
     * @return {Promise<void>}
     */
    async checkMakeWorkspaceConfiguration() {
      try {
        const res = await this.$axios.get(`sites/${this.siteId}/make/status`);
        return res.data.isAuthorized;
      } catch (err) {
        this.$store.commit('error', err, 'There was a problem checking the Make authorization of your site');
      }
    },
    handleOpenIntegrationModal() {
      this.isIntegrationModalActive = true;
    },
    handleUpdateIntegrationName(integrationName) {
      this.selectedIntegrationName = integrationName;
    }
  }
};
</script>
