<template>
  <section class="chart__container">
    <div class="chart chart--rp">
      <article class="chart-parent">
        <nav class="level">
          <div class="level-left">
            <div class="level-item">
              <h2 class="title is-5">Rewards Per Decision Over Time</h2>
            </div>
          </div>
        </nav><!-- /.level -->

        <div class="content is-relative chart-parent">
          <canvas ref="chart" class="aggregate-chart"></canvas>
        </div><!-- /.content -->
      </article>
    </div>

    <div v-if="!isLoading && !hasOptionData" class="content has-text-centered chart__empty">
      <img
        src="../../assets/empty-graph.svg"
        alt="No chart data available placeholder icon"
        class="chart__empty-image"
      >
      <h4 class="title is-4 top15">Not Enough Data Points</h4>
      <p>Preamp doesn't have enough data to populate the Over Time chart, which is normal for tests recently launched.</p>
      <p>The data that powers this chart is aggregated every 2 hours and is kept for 90 days.</p>
    </div>

    <p v-if="!isLoading && hasOptionData" class="help has-text-right chart__help-text">
      <span class="icon"><i class="fa fa-info-circle"></i></span>The data powering this chart is aggregated every 2 hours and is kept for 90 days
    </p>
  </section>
</template>

<script>
import Chart from 'chart.js/auto';
import { DateTime } from 'luxon';
import pattern from 'patternomaly';
import { numberWithCommas } from '@/modules/utilities';
import chartColors from '@/modules/chartColors';

export default {
  name: 'RewardsOverTimeByOption',
  props: {
    siteId: {
      type: String,
      required: true
    },
    testId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      chartCtx: null,
      chart: null,
      chartData: null,
      test: null,
      agent: null,
      experiences: [],
      currentOptions: [],
      currentHoldout: null,
      // flags
      isLoading: false,
      hasInsightsSelected: false,
      hasNumbersViewSelected: false
    };
  },
  computed: {
    siteDomain() {
      return this.$store.getters.siteDomain;
    },
    chartOptions() {
      return {
        responsive: true,
        radius: 0,
        hoverRadius: 4,
        hitRadius: 30,
        plugins: {
          tooltip: {
            callbacks: {
              title: function(context) {
                return new Date(context[0].label).toLocaleDateString();
              }
            },
            mode: 'index'
          },
          legend: {
            position: 'bottom',
            labels: {
              usePointStyle: true,
              pointStyle: 'circle',
              boxWidth: 7.5,
              boxHeight: 7
            }
          }
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false
        },
        scales: {
          y: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              precision: 2
            }
          },
          x: {
            type: 'time',
            time: {
              unit: 'day'
            }
          }
        }
      };
    },
    hasOptionData() {
      return Array.isArray(this.experiences) && this.experiences.length !== 0;
    },
    experienceLookup() {
      if (this.hasOptionData === false) return null;

      return this.experiences.reduce(function expArrToObj(obj, experience) {
        obj[experience.experienceId] = experience;

        return obj;
      }, {});
    }
  },
  mounted() {
    this.chartCtx = this.$refs.chart.getContext('2d');

    if (!this.chartData) {
      this.getRewards();
    }
  },
  methods: {
    numberWithCommas,
    attachOptionLabels(agent, test) {
      const { options } = agent;
      const allOptions = {};

      this.currentOptions = test.strategy.clickOptimization.options;

      if (test.strategy.clickOptimization?.holdout?.option) {
        this.currentHoldout = test.strategy.clickOptimization.holdout.option;
      }

      for (const optionName in options) {
        let label = `E:${this.experienceLookup[optionName].experienceNumber} • ${this.experienceLookup[optionName].name}`;

        // if this is an older option, add the (PREV) prefix
        if (this.currentOptions.indexOf(optionName) === -1) {
          label = `(PREV) ${label}`;
        }

        // allOptions['<experience number> - <experience name>'] = [{ created_on, total_reward, ... }]
        allOptions[label] = options[optionName];
      }

      if (agent.holdoutOptions) {
        for (const optionName in agent.holdoutOptions) {
          let label = `[Holdout] • E:${this.experienceLookup[optionName].experienceNumber} • ${this.experienceLookup[optionName].name}`;

          // if this is a previous holdout option, add the (PREV) prefix
          if (!this.currentHoldout || optionName !== this.currentHoldout) {
            label = `(PREV) ${label}`;
          }

          allOptions[label] = agent.holdoutOptions[optionName];
        }
      }

      return allOptions;
    },
    prepareChartData(allOptions, allUniqueOptionDates) {
      const chartData = [];

      // make sure all options have a value for every date so the time scale lines up
      const dateSyncedOptions = this.assignMissingOptionDates(allOptions, allUniqueOptionDates);

      for (const optionKey of Object.keys(dateSyncedOptions)) {
        const optionDateValues = dateSyncedOptions[optionKey];
        chartData.push({
          name: optionKey,
          data: optionDateValues
        });
      }

      return chartData;
    },
    getDatesFromOptions(allOptions) {
      let allUniqueOptionDates = [];

      // turn each option array item from:
      // { created_on: 'YYYY-MM-DD', total_reward: 789, decision_count: 456, total_success: 123 } => { 'YYYY-MM-DD': 789 }
      for (const ok in allOptions) {
        allOptions[ok] = allOptions[ok].reduce(function (obj, row) {
          if (!allUniqueOptionDates.includes(row.created_on)) {
            allUniqueOptionDates.push(row.created_on);
          }

          // { 'YYYY-MM-DD': 789 }
          if (!row.total_reward || !row.decision_count) {
            obj[row.created_on] = 0;
          } else {
            obj[row.created_on] = parseFloat((row.total_reward / row.decision_count).toFixed(3));
          }

          return obj;
        }, {});
      }

      allUniqueOptionDates = allUniqueOptionDates.sort(function (a, b) {
        return new Date(a) - new Date(b);
      });

      return allUniqueOptionDates;
    },
    async getRewards() {
      this.isLoading = true;

      try {
        const res = await this.$axios.get(`/sites/${this.siteId}/tests/${this.testId}/rewards`);
        const { test, external, experiences } = res.data;
        const { agent } = external.dapi;

        this.test = test;
        this.experiences = experiences;

        if (Array.isArray(experiences) && experiences.length !== 0) {
          const allOptions = this.attachOptionLabels(agent, test);
          const allUniqueOptionDates = this.getDatesFromOptions(allOptions);
          const chartData = this.prepareChartData(allOptions, allUniqueOptionDates);
          this.chartData = chartData;
          this.hydrateChart(chartData, allUniqueOptionDates);
        }

      } catch (err) {
        this.$store.commit('error', err, 'An error occurred fetching rewards for this test');
      }

      this.isLoading = false;
    },
    hydrateChart(chartData, dateLabels = []) {
      let labels = dateLabels;
      let datasets = [];

      for (let i = 0, len = chartData.length; i < len; i++) {
        const option = chartData[i];
        const ds = {
          label: option.name,
          fill: 'origin',
          tension: 0.3,
          data: Object.values(option.data)
        };

        if (String(option.name).indexOf('PREV') !== -1) {
          ds.backgroundColor = pattern.draw('diagonal', '#EFF0F9');
          ds.borderColor = '#C5CAE9';
          ds.borderDash = [7, 4];
          ds.borderWidth = 2;
        } else {
          ds.backgroundColor = (chartColors[i]) ? chartColors[i] : chartColors[Math.floor(Math.random() * chartColors.length)];
          ds.borderColor = ds.backgroundColor;
        }

        datasets.push(ds);
      }

      // turn labels into ISO dates for the chart axis
      labels = labels.map(ymd => DateTime.fromISO(ymd).toISO()).filter(d => !!d);

      if (this.chartCtx && chartData) {
        if (this.chart) {
          this.chart.destroy();
        }

        this.chart = new Chart(this.chartCtx, {
          type: 'line',
          data: {
            labels,
            datasets
          },
          options: this.chartOptions
        });
      }
    },
    assignMissingOptionDates(allOptions, uniqueDates) {
      for (const d of uniqueDates) {
        for (const opk of Object.keys(allOptions)) {
          if (typeof allOptions[opk][d] !== 'number' || isNaN(allOptions[opk][d])) {
            allOptions[opk][d] = 0;
          }
        }
      }

      return allOptions;
    }
  }
};
</script>
