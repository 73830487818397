<template>
  <div
    class="rule-group-container"
    :class="{ 'has-or-rules': !isEditing && ruleBlock.length > 1 }"
  >
    <div class="rule-group">
      <RuleForm
        v-for="(rule, ruleIndex) in ruleList"
        :key="`${keyId}.${ruleIndex}`"
        :rule="rule"
        :is-editing="isEditing"
        :block-position="position"
        :rule-index="ruleIndex"
        :rules-in-block="ruleBlock.length"
        :all-integrations="allIntegrations"
        @delete-rule="deleteRule(ruleIndex)"
      />
    </div>

    <div
      v-if="isEditing"
      class="rule-group__add-or-rule rule-form"
    >
      <div class="columns is-variable is-1">
        <div class="column is-4">
          <b-field grouped>
            <b-field style="margin: 0; padding-left: 7px; margin-right: 7px;">
              <div class="rule-group__or-text">OR</div>
            </b-field>

            <b-field expanded>
              <button
                class="button is-info is-small"
                @click="addRule"
              >
                <span class="icon">
                  <i class="fa fa-plus"></i>
                </span>
                <span>Add <strong>OR</strong> Rule</span>
              </button>
            </b-field>
          </b-field>
        </div>
      </div>
    </div>
  </div><!-- /.rule-group -->
</template>

<script>
import { sync, get } from 'vuex-pathify';
import { bus } from '@/main';
import { hasLength } from '@/modules/utilities';
import RuleForm from '@/components/Rules/RuleForm';

export default {
  name: 'RuleBlock',
  components: {
    RuleForm
  },
  props: {
    ruleBlock: {
      type: Array,
      required: true
    },
    isEditing: {
      type: Boolean,
      required: true
    },
    position: {
      type: Number,
      required: true
    },
    keyId: {
      type: String,
      required: true
    }
  },
  computed: {
    allIntegrations: sync('integrations/list'),
    enrichmentServiceToken: get('integrations/enrichmentServiceToken'),
    ruleList() {
      return this.ruleBlock.map(function (rule) {
        if (hasLength(rule.comparator) && rule.comparator.match('exists')) {
          // set old boolean rule block values to n/a if needed
          rule.value = 'n/a';
        }
        return rule;
      });
    }
  },
  created() {
    this.getAllIntegrations();

    bus.$on('updateRuleIntegrationId', this.handleUpdateIntegrationId);
  },
  beforeDestroy() {
    bus.$off('updateRuleIntegrationId', this.handleUpdateIntegrationId);
  },
  methods: {
    async getAllIntegrations() {
      await this.$store.dispatch('integrations/getList', []);
    },
    /**
     * Deleted rule at the given index
     * @param {Number} index - position in rule block
     */
    deleteRule(index) {
      // empty rule, delete by index
      this.ruleBlock.splice(index, 1);

      if (this.ruleBlock.length === 0) {
        this.$emit('delete-block', this.position);
      }
    },
    // Toggle adding a new rule form
    addRule() {
      this.ruleBlock.push({
        path: '',
        comparator: '',
        value: '',
        options: {
          integrationId: null
        }
      });
    },
    handleUpdateIntegrationId({ruleIndex, integrationId}) {
      this.ruleBlock.splice(ruleIndex, 1,  {
        ...this.ruleBlock[ruleIndex],
        options: {
          ...this.ruleBlock[ruleIndex].options,
          integrationId
        }
      });
    }
  }
};
</script>
