<template>
  <div class="chart chart--gauge">
    <div :id="titleKey" :ref='titleKey'></div>
  </div>
</template>

<script>
import bb, {gauge} from 'billboard.js';

export default {
  name: 'GaugeChart',
  props: {
    value: {
      type: Number,
      required: true
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    title: {
      type: String,
      required: true
    },
    unitDescriptor: {
      type: String,
      required: true
    },
    colors: {
      type: Array,
      default() {
        return ['#448AFF'];
      }
    },
    // colorThresholds will correspond to the colors array
    // if thresholds are [30, 60, 90, 100], colors[0] will apply < 30, colors[1] 30-60, etc.
    colorThresholds: {
      type: Array
    }
  },
  data() {
    return {
      chart: null
    };
  },
  computed: {
    titleKey() {
      return `gauge-chart-${String(this.title).toLowerCase().replace(' ', '-')}`;
    }
  },
  mounted() {
    const gaugeConfig = {
      data: {
        columns: [
          [this.unitDescriptor, this.value]
        ],
        type: gauge()
      },
      interaction: {
        enabled: false
      },
      gauge: {
        background: true,
        title: `${this.value}%\n-\n${this.unitDescriptor}`,
        min: this.min,
        max: this.max
      },
      padding: false,
      legend: {
        hide: true
      },
      color: {
        pattern: this.colors
      },
      size: {
        height: 110,
        width: 230
      },
      bindto: `#${this.titleKey}`
    };

    // if thresholds are set, assign them in the config
    if (Array.isArray(this.colorThresholds) && this.colorThresholds.length) {
      gaugeConfig.color.threshold = {
        values: this.colorThresholds
      };
    }

    this.chart = bb.generate(gaugeConfig);
  }
};
</script>
