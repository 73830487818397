import { make } from 'vuex-pathify';
import { axiosInstance as axios } from '@/plugins/axios';

const state = {
  placements: [],
  assets: [],
  focused: {},
  isLoading: false
};

const mutations = make.mutations(state);

const actions = {
  async getAllPlacementsAndAssets({ commit, dispatch, rootState }, getArchived = false) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'placements/getAllPlacementsAndAssets', payload: getArchived }, { root: true });
      return;
    }
    commit('SET_IS_LOADING', true);
    const params = {};
    try {
      if (getArchived) {
        params.archived = true;
      }
      const res = await axios.get(`/sites/${rootState.siteId}/placements`, {
        params
      });
      commit('SET_PLACEMENTS', res.data.placements);
      commit('SET_ASSETS', res.data.assets);
    } catch (e) {
      e.title = 'There was a problem retrieving placement data.';
      commit('error', e, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async getFocused({ commit, rootState, dispatch }, placementId) {
    if (!rootState.siteId) {
      dispatch('deferActionsForSiteId', 'placement/getFocused', { root: true });
      return;
    }
    try {
      commit('SET_IS_LOADING', true);
      const res = await axios.get(`/sites/${rootState.siteId}/placements/${placementId}`);
      commit('SET_FOCUSED', res.data);
    } catch (e) {
      e.title = 'There was a problem retrieving details for this placement.';
      commit('error', e, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
