<template>
  <div class="test" :class="{ 'test--rp': isCO }">
    <div class="main-view-header">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div
              class="breadcrumb is-medium"
              aria-label="breadcrumbs"
            >
              <ul>
                <li><router-link
                  class="has-text-link"
                  to="/tests"
                  data-cy-test="tests-link"
                >Tests
                </router-link>
                </li>
                <li class="is-active">
                  <router-link
                    v-if="test
                      && test.testId
                      && test.name"
                    :to="`/tests/${test.testId}`"
                  >
                    {{ test.name }}
                  </router-link>
                  <a
                    v-else
                    href="javascript:void(0)"
                  >{{ isLoading ? 'Loading...' : 'Unknown Test' }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-if="test && isCO && test.ended !== 1" class="level-right">
          <div class="level-item">
            <b-button
              class="is-hover-bg is-hover-bg-light"
              :disabled="!clickOpCSVData.data.length"
              icon-left="download"
              @click="downloadCsv(clickOpCSVData.data, clickOpCSVData.headers, clickOpCSVData.fileName)"
            >
              Download Reporting Data
            </b-button>
          </div>

          <div v-if="test.ended !== 1" class="level-item">
            <b-button
              id="btn-test-in-end"
              icon-left="square"
              :disabled="!userCanEdit"
              class="is-hover-bg is-hover-bg-danger"
              data-cy-test="end-test-button"
              @click="triggerTestAction.ending = true"
            >End Test</b-button>
          </div>

          <div class="level-item" data-cy-test="edit-button">
            <b-button class="is-hover-bg is-hover-bg-info" icon-left="pencil-square-o" @click="triggerTestAction.editing = true">Edit</b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="main-view-content">

      <Test
        v-if="gotTest"
        :siteId="siteId"
        :initialTest="test"
        :experiences="experiences"
        :addOns="addOns"
        :experienceToTests="experienceToTests"
        :is-editable="true"
        :allTests="tests"
        :holdOutOptions="holdOutOptions"
        :hasUnsavedChanges="false"
        :hasInsights="isInsightsAvailable"
        :actionTriggers="triggerTestAction"
        :insights="getTestHitData(test.testId)"
        @set-csv-data="setClickOpCSVData"
        @get-test="getDependencies"
        @reset-action-triggers="resetTestActionTriggers"
      />

      <b-loading
        :active.sync="isLoading"
        :is-full-page="false"
      />

    </div>
  </div>
</template>

<script>
import { bus } from '@/main';
import { downloadCsv, hasLength } from '@/modules/utilities';
import { sync } from 'vuex-pathify';
import Test from '../components/Tests/Test.vue';
export default {
  name: 'TestWrapper',
  components: {
    Test
  },
  data() {
    return {
      isLoading: false,
      experiences: [],
      activeTab: 0,
      insights: null,
      external: {
        dapi: null
      },
      clickOpCSVData: {
        fileName: '',
        data: [],
        headers: []
      },
      isLoadingInsights: false,
      triggerTestAction: {
        editing: false,
        ending: false
      },
      gotTest: false
    };
  },
  computed: {
    test: sync('tests/focused'),
    tests: sync('tests/list'),
    addOns: sync('addOns/list'),
    experienceToTests: sync('experiences/experienceToTests'),
    siteId() {
      return this.$store.state.siteId;
    },
    testId() {
      return this.$route.params.testId;
    },
    holdOutOptions() {
      const options = this.experiences;

      options.push({
        experienceId: '0',
        experienceNumber: 0,
        name: 'Default'
      });
      return options;
    },
    isInsightsAvailable() {
      return this.$store.getters.isInsightsAvailable;
    },
    userCanEdit() {
      return this.$store.getters.userCanEdit;
    },
    isCO() {
      return !!this.test && this.test?.strategy?.type === 'clickOptimization';
    }
  },
  watch: {
    testId(id, oldId) {
      if (id !== oldId) {
        this.getDependencies();
      }
    },
    siteId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getDependencies();
      }
    }
  },
  async created() {
    await this.getDependencies();
    bus.$on('site-changed', this.getDependencies);
  },
  beforeDestroy() {
    this.test = {};
    bus.$off('site-changed', this.getDependencies);
  },
  methods: {
    async getDependencies() {
      await Promise.all([
        this.$store.dispatch('tests/getAllTests'),
        this.$store.dispatch('addOns/getList'),
        this.getTestData()
      ]);
      this.$store.commit('experiences/updateExperienceTestMap', this.tests);

      // if it's not the correct test or bad test, redirect to tests page
      if (!this.test || this.siteId !== this.siteId) {
        this.test = {};
        if (this.$route.path !== '/tests') {
          this.$router.push('/tests');
        }
      }
    },
    async getTestData() {
      this.isLoading = true;
      if (!this.siteId) {
        return;
      }
      try {
        const res = await this.$axios.get(`/sites/${this.siteId}/tests/${this.testId}`);
        this.test = res.data.test;

        // depending on test type, we may have external data included in the response
        if (res.data.external) {
          this.external = res.data.external;
        }

        this.gotTest = true;
      } catch (e) {
        e.title = 'There was a problem retrieving test data or here.';
        this.$store.commit('error', e);
      }
      this.isLoading = false;
    },
    async getExperiences() {
      try {
        const res = await this.$axios.get(`/sites/${this.siteId}/experiences`);
        this.experiences = res.data.experiences;
      } catch (error) {
        error.title = 'There was a problem retrieving experience data.';
        this.$store.commit('error', error);
      }
    },
    async getTestInsights() {
      if (!hasLength(this.test)) return;

      const payload = {
        filters: {}
      };

      // Dynamically build the payload for elasticsearch
      for (const test of this.test) {
        payload.filters[test.test.testId] = {
          match: {
            testId: test.testId
          }
        };
      }

      this.isLoadingInsights = true;

      try {
        const res = await this.$axios.post(`/sites/${this.siteId}/insights/breakdowns/${this.lookbackRange}`, payload);
        this.insights = res.data;
      } catch (err) {
        this.$store.commit('error', err);
      }

      this.isLoadingInsights = false;
    },
    getTestHitData(testId) {
      const result = {
        percentage: 0,
        precisePercentage: 0
      };

      // if able, return the hit percentage for the test
      if (this.insights && this.insights.counts[testId]) {
        const rawPercent = this.insights.counts[testId] / this.insights.total;
        result.percentage = Math.round(rawPercent * 100);
        result.precisePercentage = Math.round(rawPercent * 10000) / 100;
      }

      return result;
    },
    resetTestActionTriggers() {
      this.triggerTestAction = {
        editing: false,
        ending: false
      };
    },
    setClickOpCSVData({ csvData, headers, fileName }) {
      this.clickOpCSVData = {
        fileName,
        data: csvData,
        headers
      };
    },
    downloadCsv
  }
};
</script>

