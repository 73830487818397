/* eslint-disable import/prefer-default-export */
import { v4 as uuidv4 } from 'uuid';

export class AudienceRuleCounter {
  /**
   * Create a new AudienceRuleCounter.
   *
   * NOTE: In order to query rules by accountId, we need to pass
   * in an object of account IDs grouped by site ID:
   * `{ siteId: accountId }`
   *
   * @param {Array}  trafficFlows
   * @param {Object} options
   * @param {Object} options.accountIdsBySiteId
   */
  constructor(trafficFlows = [], options = {}) {
    this.items = [];
    this.accountIdsBySiteId = options.accountIdsBySiteId || {};
    this.addTrafficFlows(trafficFlows);
  }

  /**
   * Add an array of Rules to count.
   * @param  {Array} trafficFlows
   * @return {void}
   */
  addTrafficFlows(trafficFlows = []) {
    trafficFlows.forEach(trafficFlow => this.addTrafficFlow(trafficFlow));
  }

  /**
   * Add a Rule to count.
   * @param  {Object} trafficFlow
   * @param  {String} trafficFlow.siteId
   * @param  {String} trafficFlow.trafficFlowId
   * @param  {Array}  trafficFlow.audiences
   * @param  {String} trafficFlow.audiences[].audienceId
   * @param  {Array}  trafficFlow.audiences[].rules
   * @param  {String} trafficFlow.audiences[].rules[].path
   * @return {void}
   */
  addTrafficFlow(trafficFlow = {}) {
    // Flatten trafficFlow into "spreadsheet" of easily queryable values.
    const { siteId, trafficFlowId } = trafficFlow;

    trafficFlow.audiences.forEach((audience) => {
      const { audienceId } = audience;

      audience.rules.flat().forEach((rule) => {
        const ruleId = `tmp-rule-id-${uuidv4()}`;
        const { path } = rule;
        const pathBase = path.split('.')[0];

        // Save items with all queryable properties in a single entry.
        this.items.push({
          accountId: this.accountIdsBySiteId[siteId],
          siteId,
          trafficFlowId,
          audienceId,
          ruleId,
          pathBase,
          path
        });
      });
    });
  }

  /**
   * Get a summary count of all rules.
   * @return {Object}
   */
  getAll() {
    // Get a list of all the unique paths.
    let uniquePathBases = new Set();
    let uniquePaths = new Set();
    this.items.forEach((item) => {
      uniquePathBases.add(item.pathBase);
      uniquePaths.add(item.path);
    });
    uniquePathBases = Array.from(uniquePathBases);
    uniquePaths = Array.from(uniquePaths);

    // Group items into a summary object.
    return uniquePathBases.reduce((result, pathBase) => {
      const pathBaseItems = this.items.filter(item => item.pathBase === pathBase);
      const pathBaseCount = this.getCount(pathBaseItems);

      // Count paths for each pathBase.
      const paths = uniquePaths.filter(path => path.startsWith(pathBase));
      const pathCounts = paths.reduce((pathResult, path) => {
        const pathItems = pathBaseItems.filter(item => item.path === path);
        const pathCount = this.getCount(pathItems);
        pathResult[path] = pathCount;
        return pathResult;
      }, {});

      // Count paths for each site.
      const siteIds = this.getSiteIds(pathBaseItems);
      const siteCounts = siteIds.reduce((siteResult, siteId) => {
        const siteItems = pathBaseItems.filter(item => item.siteId === siteId);
        const siteCount = this.getCount(siteItems);
        siteResult[siteId] = siteCount;
        return siteResult;
      }, {});

      // Count paths for each account.
      const accountIds = this.getAccountIds(pathBaseItems);
      const accountCounts = accountIds.reduce((accountResult, accountId) => {
        const accountItems = pathBaseItems.filter(item => item.accountId === accountId);
        const accountCount = this.getCount(accountItems);
        accountResult[accountId] = accountCount;
        return accountResult;
      }, {});

      // Return pathBaseCount, counts for each site, and counts for each child path.
      result[pathBase] = {
        ...pathBaseCount,
        pathCounts,
        siteCounts,
        accountCounts
      };

      return result;
    }, {});
  }

  /**
   * Count up totals for a specific array of rule items.
   * @param  {Array}  items
   * @param  {String} items[].audienceId
   * @param  {String} items[].ruleId
   * @return {Object}
   */
  getCount(items = []) {
    const audienceIds = new Set();
    const ruleIds = new Set();

    items.forEach((item) => {
      audienceIds.add(item.audienceId);
      ruleIds.add(item.ruleId);
    });

    return {
      totalAudiences: audienceIds.size,
      totalRules: ruleIds.size
    };
  }

  /**
   * Get an array of unique site IDs from an array of rule items.
   * @param  {Array}  items
   * @param  {String} items[].siteId
   * @return {Array}
   */
  getSiteIds(items = []) {
    const siteIds = new Set();
    items.forEach(item => siteIds.add(item.siteId));
    return Array.from(siteIds);
  }

  /**
   * Get an array of unique account IDs from an array of rule items.
   * @param  {Array}  items
   * @param  {String} items[].siteId
   * @return {Array}
   */
  getAccountIds(items = []) {
    const accountIds = new Set();
    items.forEach(item => accountIds.add(item.accountId));
    return Array.from(accountIds);
  }
}
