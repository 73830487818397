<template>
  <section>
    <div class="main-view-header">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h4 class="title is-4">User Audit</h4>
          </div>
        </div> <!-- end-level-left -->
        <div class="level-right">
          <div class="level-item">
            <b-button
              icon-left="trash"
              type="is-info"
              :disabled="isLoading"
              @click="deleteUsers"
            >
              Delete All
            </b-button>
          </div>
        </div>
      </div>

      <div class="main-view-content">
        <b-table
          :data="auditUsers"
          :columns="tableColumns"
          :loading="isLoading"
        >
          <template #empty>
            <b-message
              class="has-text-centered"
              type="is-warning"
            >
              <strong>No users to delete</strong>
            </b-message>
          </template>

        </b-table>
      </div> <!-- end-main-view-content -->
    </div>
  </section>
</template>

<script>
import { downloadCsv } from '@/modules/utilities';

export default {
  name: 'Audit',
  data () {
    return {
      auditUsers: [],
      isLoading: false,
      isDownloadingCsv: false,
      csvAttributes: [
          'email',
          'lastLoginDate',
          'deletedStatus',
          'failedStatus'
        ],
        tableColumns:
        [{
            field: 'email',
            label: 'Email'
          },
          {
            field: 'lastLoginDate',
            label: 'Last Login'
        }]
    };
  },
  created() {
      // Get list of users to delete
      this.getAuditedUsers();
    },
    methods: {
      /**
       * Get Audited Users
       */
      async getAuditedUsers() {
        this.isLoading = true;

        try {
          const res = await this.$axios.get('/users', {
            params: {
              audit: true
            }
          });
          this.auditUsers = res.data.users;
        } catch (err) {
          err.title = 'There was a problem loading users to delete';
          this.$store.commit('error', err);
        }
        this.isLoading = false;
      },

      // Delete users
      async deleteUsers() {
        this.isLoading = true;

        try {
          const deletedResults = await this.$axios.delete('/users', {
            data: {
              users: this.auditUsers
            }
          });
          this.csvDownload(deletedResults.data);
          // resetting audited users post deletion
          this.getAuditedUsers();
        } catch (err) {
          err.title = 'Could not delete users';
          this.$store.commit('error', err);
        }
        this.isLoading = false;
      },

      // CSV download
      async csvDownload(delResults) {
        this.isDownloadingCsv = true;

        const csvItems = this.auditUsers.map((auditUser) => {
          const userResults = delResults.find((result) => {
            const didDelete = result.value;

            if (didDelete) {
              return result.value.email === auditUser.email;
            } else {
              return result.reason.email === auditUser.email;
            }
          });
          return {
            email: auditUser.email,
            lastLoginDate: auditUser.lastLoginDate,
            deletedStatus: userResults.status === 'fulfilled',
            failedStatus: userResults.reason
          };
        });

        const date = new Date().toISOString().replace(/(:|\.)/g, '-');
        const filename = `delete-user-audit_${date}.csv`;

        downloadCsv(csvItems, this.csvAttributes, filename);

        this.isDownloadingCsv = false;
      }
    }
  };

</script>
