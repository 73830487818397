<template>
  <b-modal v-model="isModalActive" data-cy-test="edit/duplicate-modal">
    <div class="card">
      <div class="card-content">
        <h2 class="title" data-cy-test="edit/duplicate-modal-title">
          {{ headerText }}
        </h2>
        <div class="section-content">
          <h5 class="section-title" data-cy-test="edit/duplicate-modal-info-title">
            General Information
          </h5>
          <p class="section-description" data-cy-test="edit/duplicate-modal-info-description">Please provide basic information about the asset you want to duplicate.</p>
          <article
            v-if="modalType === 'edit' && hasLiveExperiences"
            class="message is-warning top15"
          >
            <div class="message-body">
              <p>This asset is live in an experience or test. By saving these changes, you will be modifying live experiences.</p>
            </div>
          </article>
          <b-field
            label="Name"
            :type="nameError.isError ? 'is-danger' : ''"
            :message="nameError.msg"
          >
            <b-input
              v-model="editingAsset.name"
              expanded
              placeholder="Enter a value"
              data-cy-test="name-input"
              @input="resetNameErr"
              @blur="handleBlurName"
            />
          </b-field>
          <b-field label="Asset Tags">
            <b-taginput
              v-model="editingAsset.tags"
              placeholder="Add a tag"
              data-cy-test="tag-input"
            />
          </b-field>

          <b-field
            data-cy-test="smart-asset-toggle"
            label="Will this new asset be used as a Smart Asset in Monarch?"
            class="smart-asset__toggle"
          >
            <b-switch v-model="showAdvancedOptions" data-cy-test="smart-asset-switch"></b-switch>
          </b-field>
          <div
            v-if="showAdvancedOptions"
            data-cy-test="advanced-asset-options"
            class="smart-asset__options"
            aria-id="asset-model-advanced-options-toggle"
          >
            <div v-if="modalType === 'duplicate'" class="">
              <b-field
                class="pseudo-base__toggle"
                data-cy-test="pseudo-base-toggle"
                label="Do you want to duplicate this asset as a base asset?"
              >
                <b-switch
                  v-model="duplicateAsBase"
                  data-cy-test="pseudo-base-switch"
                  type="is-info"
                  :disabled="modalType !== 'duplicate'"
                >
                </b-switch>
              </b-field>
            </div>

            <div class="">
              <b-field
                label="Variant Key"
                :message="variantKeyValidationMessage"
                :type="variantKeyValidity.valid === false ? 'is-danger' : ''"
              >
                <b-input
                  v-model="editingAsset.variantKey"
                  data-cy-test="variant-key-input"
                  placeholder="example_Variant_Key01"
                  maxlength="128"
                  show-counter
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>

        <EditVariables
          class="variables section-content"
          :variables="editingVariables"
          @variablesChange="validateAssetFields"
        />

        <b-message
          v-if="modalType === 'duplicate'"
          type="is-warning"
          class="is-narrow"
          data-cy-test="edit/duplicate-modal-QA-message"
        >
          Preview this asset after it is created for proper QA
        </b-message>
        <div class="level">
          <div class="level-left"></div>
          <div class="level-right">
            <div class="level-item">
              <b-button
                class="button is-ghost"
                data-cy-test="edit/duplicate-modal-button-cancel"
                @click="isModalActive = false"
              >
                Cancel
              </b-button>
            </div>
            <b-button
              v-if="modalType === 'duplicate'"
              type="is-info"
              :disabled="!isAssetValid"
              data-cy-test="edit/duplicate-modal-button-create"
              @click="handleDuplicateAssetClick"
            >
              Duplicate Asset
            </b-button>
            <b-button
              v-else
              type="is-info"
              icon-left="lock"
              :disabled="!isAssetValid"
              data-cy-test="edit/duplicate-modal-button-save"
              @click="handleEditAssetClick"
            >
              Save
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { bus } from '@/main';
import { preampVarsV2 } from '@redventures/preamp-variables';

import EditVariables from './EditVariables.vue';

export default {
  name: 'AssetModal',
  components: {
    EditVariables
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    modalType: {
      type: String,
      required: true
    },
    asset: {
      type: Object,
      required: true
    },
    allAssets: {
      type: Array,
      required: true
    },
    hasLiveExperiences: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      showAdvancedOptions: false,
      editingAsset: {},
      duplicateAsBase: false,
      editingVariables: {},
      nameError: {
        isError: false,
        msg: ''
      }
    };
  },
  computed: {
    isModalActive: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    headerText() {
      switch (this.modalType) {
        case 'duplicate':
          return 'Duplicate Asset';
        case 'edit':
          return 'Edit Asset';
      }
      return '';
    },
    isAssetValid() {
      return this.validateAssetFields();
    },
    baseAssetId() {
      // we are either duplicating off of a base (no duplicatedFrom) or another duplicate.
      // if we are duplicating off of another duplicate, use the duplicatedFrom property to determine the true base asset
      return this.asset.duplicatedFrom || this.asset.assetId || null;
    },
    variantKeyValidity() {
      const status = {
        valid: false,
        // hasValue is for value information only. variantKey can still be valid whether hasValue is true or false.
        hasValue: false,
        // if any of the below properties are true, variantKey is invalid
        patternMismatch: false,
        notUnique: false,
        characterLimitExceeded: false
      };

      // no value for variantKey is accepted
      if (!this.editingAsset.variantKey) {
        status.valid = true;
        status.hasValue = false;
        return status;
      }

      if (typeof this.editingAsset.variantKey === 'string' && this.editingAsset.variantKey.length) {
        status.hasValue = true;

        const match = this.editingAsset.variantKey.match(/^\w+$/g);

        if (!match) {
          status.patternMismatch = true;
          return status;
        }

        status.characterLimitExceeded = this.editingAsset.variantKey.length > 128;
        if (status.characterLimitExceeded) {
          return status;
        }
      }

      if (!this.isVariantKeyUnique) {
        status.notUnique = true;
        return status;
      }

      status.valid = true;
      return status;
    },
    isVariantKeyUnique() {
      if (!this.editingAsset.variantKey) return true;

      if (this.siblingVariantKeys.includes(this.editingAsset.variantKey)) {
        return false;
      }

      return true;
    },
    variantKeyValidationMessage() {
      let msg = `This field can be left empty. Only letters, numbers, and underscores are accepted.
        A variant key must be unique among all variants for a base asset.`;

      if (this.variantKeyValidity.notUnique) {
        msg = 'This variant key is not unique';
      } else if (this.variantKeyValidity.patternMismatch) {
        msg = 'Only letters, numbers, and underscores are accepted. Spaces, hyphens, and special characters are not permitted.';
      } else if (this.variantKeyValidity.valid && this.variantKeyValidity.hasValue) {
        msg = 'This variant key is valid';
      }

      return msg;
    },
    siblingVariantKeys() {
      let variants = this.allAssets.filter(a => a.duplicatedFrom === this.baseAssetId && a.variantKey);

      // if an existing asset is being edited, remove it's own variant key from being considered
      if (this.modalType === 'edit') variants = variants.filter(a => a.assetId !== this.asset.assetId);

      return variants.map(a => a.variantKey);
    }
  },
  watch: {
    editingVariables() {
      this.validateAssetFields();
    }
  },
  created() {
    this.initEditingAsset();
    bus.$on('variablesChange', this.validateAssetFields);
  },
  beforeDestroy() {
    bus.$off('variablesChange', this.validateAssetFields);
  },
  methods: {
    initEditingAsset() {
      this.editingAsset = {
        ...this.asset
      };
      this.duplicateAsBase = this.asset.path === '/';

      if (this.modalType === 'duplicate') {
        this.editingAsset.name = this.asset.name + ' (Copy)';
        this.duplicateAsBase = false; // reset if this is the duplicate modal
      }
      if (this.asset.tags) {
        this.editingAsset.tags = [...this.asset.tags];
      }
      if (this.asset.variantKey) {
        // auto-expand advanced options if a variant key already exists
        this.showAdvancedOptions = true;

        // if we are duplicating an asset that has an existing variant key, append the variant key with a suffix
        if (this.modalType === 'duplicate') {
          this.editingAsset.variantKey = `${this.editingAsset.variantKey}_copy`;
          this.editingAsset.isPseudoBase = 0;
        }
      }

      this.editingVariables = JSON.parse(this.asset.variables);
    },
    resetNameErr() {
      this.nameError = {
        isError: false,
        msg: ''
      };
    },
    handleBlurName() {
      if (!this.editingAsset.name) {
        this.nameError = {
          isError: true,
          msg: 'Name cannot be blank'
        };
        return;
      }
      if (this.editingAsset.name.length >= 128) {
        this.nameError = {
          isError: true,
          msg: 'Name must be fewer than 128 characters'
        };
      }
      const duplicateName = this.allAssets
        .find(a => (this.modalType === 'duplicate' || a.name !== this.asset.name) // Do not check for duplicate against original name if editing
          && a.name.toLowerCase().trim() === this.editingAsset.name.toLowerCase().trim());
      if (duplicateName) {
        this.nameError = {
          isError: true,
          msg: `${duplicateName.name} already exists`
        };
      }
    },
    handleDuplicateAssetClick() {
      if (!this.isAssetValid) {
        return;
      }
      this.$emit('duplicateAsset', this.editingAsset, this.editingVariables, this.duplicateAsBase);
    },
    handleEditAssetClick() {
      if (!this.isAssetValid) {
        return;
      }
      this.$emit('editAsset', this.editingAsset, this.editingVariables);
    },
    validateAssetFields() {
      this.handleBlurName();

      if (this.nameError.isError) return false;
      if (this.variantKeyValidity.valid === false) return false;

      const proposedAsset = {
        ...this.asset,
        variables: this.editingVariables
      };
      const { isValid } = preampVarsV2.validateAsset(proposedAsset);

      return isValid;
    }
  }
};
</script>

<style scoped lang="scss">
:deep(.tag) {
  background-color: white;
}

.variables {
  margin-top: 24px;
}
.section-content {
  background-color: #F6F7F9;
  padding: 16px;
  border-radius: 5px;
}
.smart-asset__options {
  padding: 12px;
  background-color: white;
  border-radius: 5px;
  margin-top: 24px;
}

.smart-asset__toggle {
  display: flex;
  column-gap: 16px;
  margin-top: 24px;

  & :deep(.label) {
    margin-bottom: 0;
  }
}
.pseudo-base__toggle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  & :deep(.label) {
    margin-bottom: 0;
  }
}
</style>
