<template>
  <div class="card bottom10">
    <header class="card-header">
      <div class="card-header-title">
        Add New Region
      </div>
    </header><!-- /.card-header -->

    <div class="card-content">
      <section>
        <div class="field">
          <div class="control is-expanded">
            <label class="label">Region Name</label>
            <input
              v-model.trim="regionName"
              type="text"
              class="input"
              placeholder="Input a region name. Ex: US"
              maxlength="64"
            />
          </div>
        </div><!-- /.field -->

        <div class="field">
          <div class="control is-expanded">
            <label class="label">Decision Server Domains</label>

            <b-taginput
              v-model="regionDecisionServers"
              :create-tag="(newTag) => newTag.trim()"
              icon="server"
              placeholder="Input a domain name and press Enter"
            ></b-taginput>
          </div>
        </div>
      </section>
    </div>

    <div class="card-footer">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <base-button-save
              :loading="isSavingRegion"
              :disabled="!isValid.valid"
              @click="save"
            />
          </div>

          <div class="level-item">
            <button class="button" @click.stop="cancel">Cancel</button>
          </div>

          <div v-if="!isValid.valid" class="level-item">
            <p class="has-text-danger">
              <span class="icon">
                <i class="fa fa-exclamation-circle"></i>
              </span>
              <span>{{ isValid.errMsg }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div><!-- /.card -->
</template>

<script>
export default {
  name: 'NewRegion',
  props: {
    regionNames: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      regionName: '',
      regionDecisionServers: [],
      isSavingRegion: false
    };
  },
  computed: {
    // Validate new region form fields
    isValid() {
      let errMsg;
      let valid = false;

      switch (true) {
        case this.regionNames.indexOf(this.regionName) >= 0:
          errMsg = 'That region name is already taken.';
          break;
        case this.regionName.trim().length === 0:
          errMsg = 'Please enter a valid region name.';
          break;
        case this.regionDecisionServers.length === 0:
          errMsg = 'A region must have at least one decision server domain.';
          break;
        default:
          valid = true;
      }

      return {
        errMsg,
        valid
      };
    }
  },
  methods: {
    // Reset the add region form and close
    cancel() {
      this.regionName = '';
      this.regionDecisionServers = [];
      this.$emit('addRegionCancel');
    },
    // Post the new region to Preamp
    async save() {
      this.isSavingRegion = true;

      const payload = {
        name: this.regionName,
        decisionServers: this.regionDecisionServers
      };

      try {
        const res = await this.$axios.post('/regions', payload);
        this.$buefy.toast.open({
          message: `Successfully created ${res.data.name}`,
          type: 'is-success'
        });

        this.$emit('addedRegion');
        this.cancel();
      } catch (err) {
        this.$store.commit('error', err, `Could not create ${this.regionName}`);
      }

      this.isSavingRegion = false;
    }
  }
};
</script>
