<template>
  <div class="variable-input" :data-cy-test="`${variableName}-config`">
    <div class="field" :class="{'is-danger': errorMessage}">
      <div class="control">
        <label class="label variable-label">
          {{ variableName }}
          <b-tooltip
            :label="value.description"
            type="is-info"
            position="is-top"
            :data-cy-test="`${variableName}-tooltip`"
          >
            <b-icon
              icon="commenting"
              class="dev-comment__icon"
              :data-cy-test="`${variableName}-comment`"
            ></b-icon>
          </b-tooltip>
        </label>
        <b-input
          v-model="editableValue"
          :data-cy-test="`${variableName}-input`"
          type="text"
          placeholder="Enter a value"
          :disabled="disabled"
        />
      </div>
      <p class="help is-danger">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import { bus } from '@/main';
  export default {
    name: 'configure-string',
    props: {
      value: {
        type: Object,
        required: true
      },
      variableName: {
        type: String,
        required: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data: () => ({
      errorMessage: ''
    }),
    computed: {
      editableValue: {
        get() {
          return this.value.value;
        },
        set(value) {
          this.$emit('input', {...this.value, value});
          bus.$emit('variablesChange');
        }
      }
    },
    watch: {
      value() {
        this.validate();
      }
    },
    created() {
      this.validate();
    },
    methods: {
      resetError() {
        this.errorMessage = '';
      },
      validate() {
        const isEmptyWhiteSpace = this.editableValue && !this.editableValue.trim();
        if (!this.editableValue || isEmptyWhiteSpace) {
          this.errorMessage = `${this.variableName} cannot be blank`;
        } else this.errorMessage = '';
      }
    }
  };
  </script>
