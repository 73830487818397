<template>
  <div>
    <b-field label="Name">
      <b-input
        v-model="integration.name"
        type="text"
        placeholder="Enter integration name (ex. space name)"
        :disabled="!userCanEdit"
        required
      ></b-input>
    </b-field>
    <b-field label="Segment Space ID">
      <b-input
        v-model="segmentData.spaceId"
        type="text"
        placeholder="Enter Segment space ID"
        :disabled="!userCanEdit"
        required
      ></b-input>
    </b-field>
    <b-field label="Segment Auth Token">
      <b-input
        v-model="segmentData.authToken"
        type="text"
        placeholder="Enter Segment auth token"
        :disabled="!userCanEdit"
        required
      ></b-input>
    </b-field>
    <b-field label="External Id Type">
      <b-radio-button
        v-model="segmentData.idType"
        class="segment-id-radio"
        native-value="anonymous_id"
        type="is-info"
        :disabled="isEditing"
      >
        Anonymous Id
      </b-radio-button>
      <b-radio-button
        v-model="segmentData.idType"
        native-value="cross_site_id"
        class="segment-id-radio"
        type="is-info"
        :disabled="isEditing"
      >
        Cross Site Id
      </b-radio-button>
    </b-field>
  </div>
</template>

<script>
import { IntegrationTypeEnum } from '@/modules/integrations/integrationTypeEnum';

export default {
  name: 'SegmentIntegrationForm',
  props: {
    integration: {
      type: Object,
      required: false
    },
    userCanEdit: {
      type: Boolean,
      required: true
    },
    isEditing: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    segmentData() {
      return this.integration.data[IntegrationTypeEnum.SEGMENT];
    }
  }
};
</script>

<style lang="scss" scoped>
  .segment-id-radio ::v-deep .is-info.is-disabled {
    color: white;
  }
</style>
