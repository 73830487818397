<template>
  <div class="card is-expandable">
    <header
      class="card-header"
      :class="{ 'is-expanded': isExpanded }"
      @click.stop="toggleAccount"
    >
      <div class="card-header-title">
        {{ account.name }}
      </div>

      <div
        v-if="account.archived"
        class="card-header-icon"
      >
        <span class="tag is-danger">
          Archived
        </span>
      </div>

      <div class="card-header-icon">
        <span class="icon">
          <i
            class="fa"
            :class="(isExpanded) ? 'fa-angle-up' : 'fa-angle-down'"
          ></i>
        </span>
      </div>
    </header><!-- /.card-header -->

    <div
      v-if="isExpanded"
      class="card-content"
    >
      <div class="columns">
        <div class="column is-half">
          <h6 class="title is-6">Account Details</h6>
        </div>
        <div class="column is-half">
          <h6 class="title is-6">Manage Slack Notifications</h6>
        </div>
      </div>

      <div class="columns">
        <div class="column is-half">
          <table class="table is-bordered is-hoverable is-narrow is-fullwidth">
            <tr>
              <th>Name</th>
              <td>
                <input
                  v-model.trim="name"
                  type="text"
                  class="input"
                  maxlength="64"
                >
              </td>
            </tr>
            <tr>
              <th>ID</th>
              <td>{{ account.accountId }}</td>
            </tr>
            <tr>
              <th>Status</th>
              <td>{{ (account.archived) ? 'Archived' : 'Live' }}</td>
            </tr>
            <tr>
              <th>Created At</th>
              <td>{{ createdAt }}</td>
            </tr>
          </table>

          <section
            v-if="isSuper"
            class="bottom15"
          >
            <b-field
              label="Archive Account"
              position="is-left"
            >
              <b-switch
                v-model="archived"
                type="is-danger"
              >
                Archived
              </b-switch>
            </b-field>
          </section>
        </div><!-- /.column -->

        <div class="column is-half">
          <b-field label="Channel">
            <b-input
              v-model="slack.channel"
              placeholder="#slack-channel"
              maxlength="80"
            ></b-input>
          </b-field>

          <b-field label="Notification Types">
            <b-taginput
              v-model="slack.scopes"
              placeholder="Add Notifications"
              :data="notificationScopes"
              autocomplete
            >
            </b-taginput>

            <template #message>
              Allow Preamp to send notifications to the specified slack channel. A list of notification types can be found
              <a
                href="https://redventures.atlassian.net/wiki/spaces/COHSN/pages/836272600/Preamp+-+Account+Notifications"
                target="_blank"
              >here.</a>
            </template>
          </b-field>
        </div>
      </div><!-- /.columns -->

      <hr>

      <div class="level">
        <div class="level-left">
          <h5 class="title is-5">Sites</h5>
        </div>

        <div class="level-right">
          <button
            data-cy-test="create-site-button"
            class="button is-info"
            :disabled="addNewSiteActive"
            @click.stop="toggleAddSite"
          >
            <span class="icon">
              <i class="fa fa-plus"></i>
            </span>
            <span>Add Site</span>
          </button>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <section>
            <NewSite
              v-if="addNewSiteActive"
              :accountId="account.accountId"
              :siteNames="siteNames"
              :availableRegions="regions"
              @updatedSite="getSites"
              @addSiteCancel="toggleAddSite"
            />
          </section>

          <section>
            <Site
              v-for="(site) in sites"
              :key="`site-${site.siteId}`"
              :data-cy-test="`site-${site.name}`"
              :site="site"
              :siteNames="siteNames"
              :availableRegions="regions"
              :accountId="account.accountId"
              @updatedSite="getSites"
            />
            <b-loading
              :active.sync="isLoadingSite"
              :is-full-page="false"
            ></b-loading>
          </section>
        </div><!-- /.column -->
      </div><!-- /.columns -->
    </div><!-- /.card-content -->

    <div
      v-if="isExpanded"
      class="card-footer"
    >
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <base-button-save
              :loading="isSavingSite"
              :disabled="!isValid.valid || !readyToUpdate"
              @click="updateAccount"
            />
          </div>

          <div class="level-item">
            <button
              class="button"
              :disabled="!readyToUpdate"
              @click.stop="reset"
            >Cancel</button>
          </div>

          <div
            v-if="!isValid.valid"
            class="level-item"
          >
            <p class="has-text-danger">
              <span class="icon">
                <i class="fa fa-exclamation-circle"></i>
              </span>
              {{ isValid.errMsg }}
            </p>
          </div>
        </div><!-- /.level-left -->
      </div><!-- /.level -->
    </div>
  </div><!-- /.card -->
</template>

<script>
import { formatTime } from '@/modules/utilities';
import Site from './Site.vue';
import NewSite from './NewSite.vue';
import { sync } from 'vuex-pathify';

export default {
  name: 'Account',
  components: {
    Site,
    NewSite
  },
  props: {
    account: {
      type: Object,
      required: true
    },
    regions: {
      type: Array,
      required: true
    },
    accountNames: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      addNewSiteActive: false,
      archived: false,
      name: '',
      isExpanded: false,
      sites: [],
      isLoadingSite: false,
      isSavingSite: false,
      slack: {
        channel: '',
        scopes: []
      }
    };
  },
  computed: {
    user: sync('user'),
    // Check user status
    isSuper() {
      return this.$store.getters.isSuperUser;
    },
    // Check that the account has changes
    readyToUpdate() {
      const changedArchive = this.archived !== Boolean(this.account.archived);
      const changedName = this.name !== this.account.name;

      const changedSlackChannel = (this.account.slack)
        ? this.slack.channel !== this.account.slack.channel
        : this.slack.channel !== '';

      const editScopes = [...this.slack.scopes].sort();
      const prevScopes = (this.account.slack) ? [...this.account.slack.scopes].sort() : [];
      const changedSlackScopes = JSON.stringify(editScopes) !== JSON.stringify(prevScopes);

      const changedSlack = changedSlackChannel || changedSlackScopes;

      return changedArchive || changedName || changedSlack;
    },
    // Validate the account changes
    isValid() {
      let errMsg;
      let valid = false;

      switch (true) {
        case this.name.length === 0:
          errMsg = 'Please enter a valid account name.';
          break;
        case this.accountNames.includes(this.name.toLowerCase()) && this.name !== this.account.name:
          errMsg = 'This account name is already in use.';
          break;
        case this.slack.channel.length > 80:
          errMsg = 'Slack Channel length must be less than or equal to 80 characters.';
          break;
        default:
          valid = true;
      }

      return {
        errMsg,
        valid
      };
    },
    // Array of site names
    siteNames() {
      return this.sites.map(function (site) {
        return site.name.toLowerCase();
      });
    },
    // Return time stamp format
    createdAt() {
      return formatTime(this.account.createdAt);
    },
    globalSelectedAccount() {
      return this.$store.state.activeAccount;
    },
    notificationScopes() {
      const scopes = ['site:sync:*'];
      for (let i = 0; i < this.sites.length; i++) {
        scopes.push(`site:sync:${this.sites[i].name}`);
      }
      return scopes;
    }
  },
  watch: {
    // Get sites on opening an account
    isExpanded(val) {
      if (!val || this.sites.length !== 0) return;
      this.getSites();
    }
  },
  created() {
    // Set initial details
    this.reset();
  },
  methods: {
    // Get all sites for an account
    async getSites() {
      this.isLoadingSite = true;

      try {
        const res = await this.$axios.get(`/accounts/${this.account.accountId}/sites`);
        this.sites = [];
        this.$nextTick(function () {
          this.sites = res.data;
        });

        // if account expanded is the same account they have selected in the top nav, update site data in store
        if (this.account.accountId === this.globalSelectedAccount) {
          this.$store.commit('setAccountSites', res.data);
        }
      } catch (err) {
        err.title = `Could not get sites for ${this.account.name}`;
        this.$store.commit('error', err);
      }

      this.isLoadingSite = false;
    },
    // Update an account when archive is toggled
    async updateAccount() {
      this.isSavingSite = true;

      const payload = { ...this.account };
      payload.archived = (this.archived) ? 1 : 0;
      payload.name = this.name;

      if (this.slack.channel !== '' || this.account.slack !== undefined) {
        const { channel, scopes } = this.slack;

        payload.slack = {
          channel,
          scopes
        };
      }


      try {
        const res = await this.$axios.post(`/account/${this.account.accountId}`, payload);

        this.$buefy.toast.open({
          message: `Successfully updated ${res.data.name}`,
          type: 'is-success'
        });

        this.$emit('updatedAccount');
      } catch (err) {
        err.title = `Could not update ${this.account.name}`;
        this.$store.commit('error', err);
        this.reset();
      }

      this.isSavingSite = false;
    },
    // Display add site form
    toggleAddSite() {
      this.addNewSiteActive = !this.addNewSiteActive;
    },
    // show/hide account information
    toggleAccount() {
      this.isExpanded = !this.isExpanded;
    },
    // Reset account details
    reset() {
      this.name = this.account.name;
      this.archived = Boolean(this.account.archived);

      if (this.account.slack) {
        this.slack = { ...this.account.slack };
      } else {
        this.slack.channel = '';
        this.slack.scopes = [];
      }
    }
  }
};
</script>
