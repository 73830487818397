<template>
  <div class="variable-input">
    <b-field
      :label="name"
      :type="errorMessage ? 'is-danger' : ''"
      :message="errorMessage"
    >
      <b-input
        v-model="editableValue"
        type="text"
        placeholder="Enter a value"
        :disabled="disabled"
      />
    </b-field>
  </div>
</template>

<script>
export default {
  name: 'variable-input',
  props: {
    value: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: false
    },
    nullable: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    errorMessage: ''
  }),
  computed: {
    editableValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  watch: {
    value() {
      this.validate();
    },
    nullable() {
      this.validate();
    }
  },
  created() {
    this.validate();
  },
  methods: {
    resetError() {
      this.errorMessage = '';
    },
    validate() {
      const isEmptyWhiteSpace = this.editableValue && !this.editableValue.trim();
      if (!this.nullable && (!this.value || isEmptyWhiteSpace)) {
        this.errorMessage = `${this.name} cannot be blank`;
      } else this.errorMessage = '';
    }
  }
};
</script>

<style scoped>
.variable-input {
  margin: 0px 0px 10.5px 0px;
}
</style>
