<template>
  <section>
    <div class="main-view-header">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h4
              class="title is-4"
              data-cy-test="title"
            >
              {{ (inDraftMode) ? 'Draft ' : '' }}Traffic Flows
            </h4>
          </div>
        </div><!-- /.level-left -->

        <div class="level-right">
          <div
            v-if="isInsightsAvailable"
            class="level-item"
          >
            <transition
              name="custom-classes-transition"
              leave-active-class="animated fadeOut fast"
            >
              <span v-if="isLoadingInsights">Loading...</span>
            </transition>
          </div>


          <div
            v-if="isInsightsAvailable"
            class="level-item"
          >
            <LookbackRangePicker
              :options="['1d', '7d', '30d']"
              :has-refresh="false"
              :formatter="false"
              :is-loading="isLoadingInsights"
              @selection="val => lookbackRange = val"
            />
          </div>

          <div class="mr-2">
            <b-input
              v-model="filterQuery"
              type="search"
              placeholder="Search..."
              data-cy-test="audience-search"
            />
          </div>

          <div class="level-item">
            <button
              id="btn-traffic-create-audience"
              class="button is-info"
              data-cy-test="create-audience-button"
              :disabled="audienceFormActive || !userCanEdit"
              @click="handleCreateAudience"
            >
              <span class="icon">
                <i class="fa fa-plus"></i>
              </span>
              <span>Create Audience</span>
            </button>
          </div>

          <div
            v-if="hasLock && inDraftMode"
            class="level-item"
          >
            <button
              class="button is-success"
              data-cy-test="publish-draft-button"
              @click="draftDiffModalActive = true"
            >
              <span class="icon"><i class="fa fa-cloud-upload"></i></span>
              <span>Publish Draft</span>
            </button>
          </div>
        </div><!-- /.level-right -->
      </div><!-- /.level -->
    </div>

    <div v-if="userCanEdit" class="main-view-sub-header">
      <div class="tabs is-boxed">
        <ul>
          <li
            :class="{ 'is-active' : !inDraftMode }"
            data-cy-test="live-tab"
            @click="exitDraftMode"
          >
            <a :class="{ 'active-live': hasLock && !inDraftMode }">Live</a>
          </li>
          <li
            id="btn-traffic-draft"
            :class="{ 'is-active' : inDraftMode }"
            :title="(!hasLock) ? 'Must be Editing Site to Enter Draft' : 'Enter Draft Mode'"
            data-cy-test="draft-tab"
            @click="enterDraftMode"
          >
            <a :class="{ 'active-draft': hasLock && inDraftMode }">
              Draft
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div
      class="main-view-content expanded-header"
      :class="{ 'editing-draft': hasLock && inDraftMode, 'editing-live': hasLock && !inDraftMode }"
    >
      <!-- Audience List -->
      <ul v-if="siteId && trafficFlowView">
        <li
          v-for="tf in trafficFlowView"
          :key="tf.trafficFlowId"
        >
          <div class="level">
            <div class="level-left"></div>

            <div class="level-right">
              <p v-if="hasLock">
                <span class="icon has-text-info">
                  <i class="fa fa-info-circle"></i>
                </span>
                <span v-if="!inDraftMode">All edits made to the live traffic flow will be reflected immediately.</span>
                <span v-if="inDraftMode">All edits while made in draft mode will be saved to the current draft.</span>
              </p>
            </div>
          </div>

          <div
            v-if="audienceFormActive"
            class="box bottom15"
          >
            <AudienceForm
              :all-audiences="trafficFlowView[0].audiences"
              :experiences="experiences"
              @create-audience="createAudience"
              @reset-audience="resetAudience"
            />
          </div>

          <div class="columns">
            <div class="column is-narrow is-relative audience-tree"></div>

            <div class="column">
              <draggable
                id="tf-audience-col"
                v-model="tf.audiences"
                :options="{ handle: '.audience-move-handle', forceFallback: true }"
                :move="checkMove"
                @start="isDragging = true"
                @end="moveAudience"
              >

                <Audience
                  v-for="audience in tf.audiences"
                  :key="audience.audienceId"
                  :all-audiences="trafficFlowView[0].audiences"
                  :audience="audience"
                  :insights="getAudienceHitData(audience.audienceId)"
                  :has-insights="isInsightsAvailable"
                  :is-dragging="isDragging"
                  :experiences="experiences"
                  :addOns="addOns"
                  :tests="tests"
                  :experienceToTests="experienceToTests"
                  :site-id="siteId"
                  :draft-mode="inDraftMode"
                  :fadeChange="hasMatchedAudienceIdToFade.get(audience.audienceId)"
                  @update-audience="updateAudience"
                  @delete-audience="deleteAudience"
                  @duplicate-audience="duplicateAudience"
                />
              </draggable>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <b-modal :active.sync="isDuplicateModalActive">
      <div
        v-if="audienceToDuplicate"
        id="btn-traffic-audience-duplicate"
        class="card"
        @click="elementClick"
      >
        <div class="card-header">
          <header class="card-header-title" data-cy-test="duplicate-audience-header">Duplicating an Audience</header>
        </div>
        <div class="card-content">
          <AudienceForm
            :initial-audience="audienceToDuplicate"
            :all-audiences="trafficFlowView[0].audiences"
            :experiences="experiences"
            @create-audience="handleDuplicateCreate"
            @reset-audience="handleDuplicateCancel"
          />
        </div>
      </div>
    </b-modal>

    <DraftModal
      :active="draftModalActive"
      :draft="draft"
      @draft-modal-create="handleDraftCreate"
      @draft-modal-edit="handleDraftEdit"
      @draft-modal-cancel="handleDraftCancel"
    />

    <DraftDiff
      v-if="draftDiffModalActive"
      :draft="draft"
      :production="trafficFlows"
      @draft-diff-publish="handleDraftDiffPublish"
      @draft-diff-cancel="handleDraftDiffCancel"
    />

    <b-loading
      :active.sync="isLoading"
      :is-full-page="false"
    ></b-loading>
  </section>
</template>

<script>
import shortUUID from 'short-uuid';
import draggable from 'vuedraggable'; // https://github.com/SortableJS/Vue.Draggable
import { sync } from 'vuex-pathify';
import { hasLength, clone } from '@/modules/utilities';
import AudienceForm from '@/components/Audiences/AudienceForm';
import Audience from '@/components/Audiences/Audience';
import DraftModal from '@/components/DraftModal';
import DraftDiff from '@/components/DraftDiff';
import LookbackRangePicker from '@/components/Insights/LookbackRangePicker';
import { bus } from '@/main';

export default {
  name: 'TrafficFlows',
  components: {
    AudienceForm,
    Audience,
    draggable,
    DraftModal,
    DraftDiff,
    LookbackRangePicker
  },
  data() {
    return {
      insights: null,
      trafficFlows: null,
      audienceToDuplicate: null,
      draft: null,
      trafficFlowView: null,
      audienceFormActive: false,
      isDragging: false,
      isLoading: false,
      isLoadingInsights: false,
      isDuplicateModalActive: false,
      inDraftMode: false,
      draftModalActive: false,
      draftDiffModalActive: false,
      lookbackRange: '1d',
      filterQuery: ''
    };
  },
  computed: {
    siteId() {
      return this.$store.state.siteId;
    },
    experiences: sync('experiences/list'),
    addOns: sync('addOns/list'),
    tests: sync('tests/list'),
    experienceToTests: sync('experiences/experienceToTests'),
    // Check the user currently has the site lock
    hasLock() {
      return this.$store.getters.siteLockOwner;
    },
    userCanEdit() {
      return this.$store.getters.userCanEdit;
    },
    // Count of current audiences
    totalAudiences() {
      return this.trafficFlows[0].audiences.length || 0;
    },
    hasEditedAudiences() {
      return this.$store.state.edits.find(function (edit) {
        return edit.indexOf('audience') > -1;
      }) !== undefined;
    },
    isInsightsAvailable() {
      return this.$store.getters.isInsightsAvailable;
    },
    hasMatchedAudienceIdToFade() {
      const audienceToFade = new Map();
      const [ trafficFlow ] = this.trafficFlowView;

      for (const audience of trafficFlow.audiences) {
        const shouldFade = !audience.name.toLowerCase().includes(this.filterQuery.toLowerCase());
        audienceToFade.set(audience.audienceId, shouldFade);
      }
      return audienceToFade;
    }
  },
  watch: {
    hasLock(bool) {
      if (bool) {
        // user wants to edit, get newest data
        this.getDependencies();
      }
    },
    isInsightsAvailable(bool) {
      if (bool && !this.insights && this.trafficFlows) {
        this.getAudienceInsights();
      }
    },
    trafficFlows(array) {
      if (this.isInsightsAvailable && hasLength(array)) {
        this.getAudienceInsights();
      }
    },
    lookbackRange() {
      this.getAudienceInsights();
    }
  },
  created() {
    if (this.siteId !== null) this.getDependencies();
    this.registerEventListeners();
  },
  methods: {
    /**
     * Emit element click event
     * @param {Object} e - element click event
     */
    elementClick(e) {
      bus.$emit('elem-click', e);
    },
    async getDependencies() {
      try {
        await Promise.all([
          this.getTrafficFlows(),
          this.$store.dispatch('addOns/getList'),
          this.$store.dispatch('tests/getAllTests'),
          this.$store.dispatch('experiences/getList', null)
        ]);
        this.$store.commit('experiences/updateExperienceTestMap', this.$store.get('tests/list'));
      } catch (err) {
        err.title = 'Failed to get all dependencies';
        this.$store.commit('error', err);
      }
    },
    handleCreateAudience() {
      this.$root.establishSiteLock().then(() => {
        this.audienceFormActive = !this.audienceFormActive;
      });
    },
    async getTrafficFlows() {
      this.isLoading = true;

      try {
        const res = await this.$axios.get(`/sites/${this.siteId}/traffic-flows`);

        this.trafficFlows = res.data;

        // Prevent switching views if in draft mode
        if (!this.inDraftMode) {
          this.trafficFlowView = this.trafficFlows;
        }

        bus.$emit('got-new-traffic-flow-data');
      } catch (error) {
        error.title = 'There was a problem retrieving traffic flow data';
        this.$store.commit('error', error);
      }

      this.isLoading = false;
    },
    /**
     * Format traffic flow to be displayed
     * @param {Object} tf - traffic flow
     * @return {Object} updated traffic flow
     */
    formatTrafficFlow(tf) {
      tf.audiences = tf.audiences.map(function (audience) {
        if (audience.championExperienceId === null) delete audience.championExperienceId;
        if (hasLength(audience.tests)) {
          audience.tests = audience.tests.map(function (test) {
            return {
              name: test.name,
              siteId: test.siteId,
              strategy: test.strategy,
              testId: test.testId,
              trafficFlowId: test.trafficFlowId,
              visitorsPct: test.visitorsPct,
              isPaused: test.isPaused
            };
          });
        }
        return audience;
      });
      return tf;
    },
    /**
     * Post the updated traffic flow to the API
     * @param {Object} tf - traffic flow
     */
    async updateTrafficFlow(tf) {
      this.isLoading = true;
      const data = this.formatTrafficFlow(tf);
      let url;
      let payload;
      let updateType;

      if (this.inDraftMode) {
        // Create parameters for drafts
        url = `/sites/${this.siteId}/draft`;
        payload = { draft: data };
        updateType = 'Draft Traffic Flows';
      } else {
        // Create parameters for production
        url = `/sites/${this.siteId}/traffic-flows/${data.trafficFlowId}`;
        payload = { trafficFlow: data };
        updateType = 'Traffic Flows';
      }

      try {
        const res = await this.$axios.put(url, payload);
        this.$buefy.toast.open({
          message: `Successfully updated ${updateType}`,
          type: 'is-success'
        });

        if (this.inDraftMode) {
          this.draft[0] = res.data.draft;
        } else {
          this.$set(this.trafficFlows, 0, res.data.trafficFlow);
        }
      } catch (error) {
        error.title = `There was a problem updating ${updateType}`;
        this.$store.commit('error', error);
      }

      this.isLoading = false;
    },
    /**
     * Create a new audience
     * @param {Object} data - new audience
     */
    createAudience(data) {
      const tf = (this.inDraftMode) ? this.draft[0] : this.trafficFlows[0];

      if (!data.audienceId) {
        data.audienceId = shortUUID.generate();
      }

      tf.audiences.unshift(data);
      this.audienceFormActive = false;
      this.updateTrafficFlow(tf);
    },
    /**
     * Duplicate an audience
     * @param {Object} audience - aud being duplicated
     */
    duplicateAudience(audience) {
      const newAudience = clone(audience);
      delete newAudience.audienceId;
      newAudience.name = `${newAudience.name} Copy`;
      this.audienceToDuplicate = newAudience;
      this.isDuplicateModalActive = true;
    },
    /**
     * Update an existing audience
     * @param {Object} newAudience - updates
     */
    updateAudience(newAudience) {
      const tf = (this.inDraftMode) ? this.draft[0] : this.trafficFlows[0];

      tf.audiences = tf.audiences.map(function (audience) {
        return (audience.audienceId === newAudience.audienceId) ? newAudience : audience;
      });

      this.updateTrafficFlow(tf);
    },
    /**
     * Remove an Audience by ID and update traffic flows
     * @param {String} audienceId - audience UUID
     */
    deleteAudience(audienceId) {
      const tf = (this.inDraftMode) ? this.draft[0] : this.trafficFlows[0];

      tf.audiences = tf.audiences.filter(audience => audience.audienceId !== audienceId);
      this.updateTrafficFlow(tf);
    },
    resetAudience() {
      this.audienceFormActive = false;
    },
    /**
     * Rearrange the audience array
     * @param {Object} evt - event emitted
     */
    moveAudience(evt) {
      const tf = (this.inDraftMode) ? this.draft[0] : this.trafficFlows[0];

      // check if index has actually changed and update the tf
      if (evt.oldIndex !== evt.newIndex) {
        this.updateTrafficFlow(tf);
      }

      this.isDragging = false;
    },
    /**
     * Duplicate an audience and close the duplicate modal
     * @param {Object} audience - new duplicate audience
     */
    handleDuplicateCreate(audience) {
      this.createAudience(audience);
      this.handleDuplicateCancel();
    },
    // Reset duplicate related props
    handleDuplicateCancel() {
      this.isDuplicateModalActive = false;
      this.audienceToDuplicate = null;
    },
    /**
     * Validate a re-order before allowing update
     * @param {Object} evt - event
     */
    checkMove(evt) {
      const tf = (this.inDraftMode) ? this.draft[0] : this.trafficFlows[0];

      return (
        this.hasLock // user has lock
        && evt.draggedContext.index !== tf.audiences.length - 1 // dragged item is not traffic endpoint
        && evt.relatedContext.index !== tf.audiences.length - 1 // target position is not traffic endpoint (last index)
      );
    },
    /**
     * Launch the draft mode modal and get a draft if there is one,
     * then prompr the user to edit, create, or cancel
     */
    async enterDraftMode(clickEvent) {
      if (this.inDraftMode) return;

      if (clickEvent) {
        this.elementClick(clickEvent);
      }

      this.isLoading = true;

      try {
        await this.$root.establishSiteLock();
        const res = await this.$axios.get(`/sites/${this.siteId}/draft`);
        this.draft = res.data.draft;
        this.draftModalActive = true;
      } catch (err) {
        err.title = 'There was a problem with getting the draft for this site.';
        this.$store.commit('error', err);
      }

      this.isLoading = false;
    },
    // Reset our viewed traffic flow to production ane exit draft state
    exitDraftMode() {
      this.inDraftMode = false;
      this.trafficFlowView = this.trafficFlows;
    },
    /**
     * Create a new draft from the production traffic flow
     * then enter the draft mode state
     */
    async handleDraftCreate() {
      this.isLoading = true;
      const { siteId } = this;
      const { trafficFlowId } = this.trafficFlows[0];

      try {
        const res = await this.$axios.post(`/sites/${siteId}/draft`, { trafficFlowId });
        this.draft = res.data.draft;

        this.$buefy.toast.open({
          message: 'Successfully created a new draft.',
          type: 'is-success'
        });
      } catch (err) {
        err.title = 'There was a problem creating a new draft for this site.';
        this.$store.commit('error', err);
      }

      this.trafficFlowView = this.draft;
      this.inDraftMode = true;
      this.draftModalActive = false;
      this.isLoading = false;
    },
    // Launch the diff modal and allow for publishing
    async handleDraftDiffPublish() {
      const { trafficFlowId } = this.trafficFlows[0];
      const { trafficFlowDraftId } = this.draft[0];
      const { siteId } = this;

      this.isLoading = true;

      try {
        const res = await this.$axios.post(`/sites/${siteId}/draft/publish`, { trafficFlowDraftId, trafficFlowId });

        this.trafficFlows[0] = res.data.trafficFlow;

        this.$buefy.toast.open({
          message: 'Successfully published your draft',
          type: 'is-success'
        });

        this.trafficFlowView = this.trafficFlows;
        this.draft = null;
        this.inDraftMode = false;
        this.draftDiffModalActive = false;
      } catch (err) {
        err.title = 'There was a problem publshing your draft.';
        this.$store.commit('error', err);
      }
      this.isLoading = false;
    },
    // Since we grab the draft in order to check if one exists, edit will just switch to draft view
    handleDraftEdit() {
      this.trafficFlowView = this.draft;
      this.inDraftMode = true;
      this.draftModalActive = false;
    },
    handleDraftCancel() {
      this.trafficFlowView = this.trafficFlows;
      this.inDraftMode = false;
      this.draftModalActive = false;
    },
    // Cancel draft diff modal
    handleDraftDiffCancel() {
      this.draftDiffModalActive = false;
    },
    async getAudienceInsights() {
      if (!this.trafficFlows || !this.trafficFlows[0] || !this.trafficFlows[0].audiences) return;

      const payload = {
        filters: {}
      };

      // Dynamically build the payload for elasticsearch
      for (const audience of this.trafficFlows[0].audiences) {
        payload.filters[audience.audienceId] = {
          match: {
            'audienceId.keyword': audience.audienceId
          }
        };
      }

      this.isLoadingInsights = true;
      try {
        // the more data we look at, the more accurate the resulting percentages will be. 1d default.
        const res = await this.$axios.post(`/sites/${this.siteId}/insights/breakdowns/${this.lookbackRange}`, payload);
        this.insights = res.data;
      } catch (err) {
        this.$store.commit('error', err);
      }
      this.isLoadingInsights = false;

    },
    getAudienceHitData(audienceId) {
      const result = {
        percentage: 0,
        precisePercentage: 0
      };

      // if able, create
      if (this.insights && this.insights.counts[audienceId]) {
        const rawPercent = this.insights.counts[audienceId] / this.insights.total;
        result.percentage = Math.round(rawPercent * 100);
        result.precisePercentage = Math.round(rawPercent * 10000) / 100;
      }

      return result;
    },
    // Perform any logic (often form resets) when site lock is lost
    handleLostSiteLock() {
      this.audienceFormActive = false;
      this.draftDiffModalActive = false;
      this.handleDraftCancel();
      this.handleDuplicateCancel();
    },
    registerEventListeners() {
      bus.$on('site-lock-lost-user', this.handleLostSiteLock);
      bus.$on('site-changed', this.getDependencies);
      bus.$on('get-test-data', this.getTrafficFlows);
    }
  }
};
</script>

<style>
  /* TODO: Move this when merged into cohesion entry point */
  .active-live {
    background-color: #30cc71 !important;
    color: white !important;
    border-color: transparent !important;
  }
  .editing-live {
    border: 5px solid #30cc71;
  }
  .active-draft {
    background-color: #ffdd57 !important;
    color: black !important;
    border-color: transparent !important;
  }
  .editing-draft {
    border: 5px solid #ffdd57;
  }
</style>
