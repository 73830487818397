<template functional>
  <base-button-default
    :data-cy-test="props.dataCyTest"
    :loading="props.loading"
    :disabled="props.disabled"
    type="success"
    icon="cloud-upload"
    text="Save"
    @click="listeners.click"
  />
</template>

<script>
export default {
  name: 'BaseButtonSave',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dataCyTest: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>
