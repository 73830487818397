const chartColors = [
  '#000066',
  '#9999FF',
  '#33BBFF',
  '#99FFCC',
  '#FFFF66',
  '#FF8000',
  '#FF3300',
  '#660000',
  '#FF4D88',
  '#FFB3FF',
  '#E6CCFF',
  '#7A0099',
  '#3333FF',
  '#2F4B7C',
  '#665191',
  '#A05195',
  '#D45087',
  '#F95D6A',
  '#FF7C43',
  '#FFA600',
  '#FFFF00',
  '#471919'
];

export default chartColors;
