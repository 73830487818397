import config from '@/config';
import getAppEnv from './getAppEnv';

const { LOGIN_URL, LOGIN_CLIENT_ID, LOGIN_AUDIENCE } = config[getAppEnv(location.host)];
const { JWT_KEY } = config;

const auth0Endpoint =
  `https://${LOGIN_URL}/authorize?scope=openid profile email&response_type=token&client_id=${LOGIN_CLIENT_ID}&audience=${LOGIN_AUDIENCE}&redirect_uri=${window.location.href}`;
const hashRouteKey = 'tmp_auth_redirect_hash';

export default {
  logout() {
    localStorage.removeItem(JWT_KEY);
  },
  getJwt() {
    return localStorage.getItem(JWT_KEY);
  },
  async loginUser(redirect) {
    if (this.hasToken()) this.logout();

    // Store current path in local storage for redirect
    localStorage.setItem(hashRouteKey, redirect);
    window.location.assign(auth0Endpoint);
  },
  checkAuth() {
    const jwt = this.checkUrlForToken();
    if (jwt) {
      window.history.replaceState({}, document.title, window.location.pathname);
      localStorage.setItem(JWT_KEY, jwt);
    }
  },
  hasToken() {
    return localStorage.getItem(JWT_KEY) !== null;
  },
  getAccessToken() {
    const reg = new RegExp('access_token=([^&#]*)', 'i');
    const string = reg.exec(window.location.href);
    return string ? string[1] : null;
  },
  checkUrlForToken() {
    if (window.location.href.includes('access_token=')) {
      const jwt = this.getAccessToken();
      return jwt;
    } else {
      return null;
    }
  }
};
