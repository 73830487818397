<template>
  <article class="message" :class="messageClass">
    <div class="message-body">
      <template v-if="integrationName">
        Selected Integration: {{ integrationName }}
      </template>
      <template v-else>
        Click the gear to configure the {{ displayName }} integration
      </template>
    </div>
  </article>
</template>

<script>
import { getIntegrationInstance } from '@/modules/integrations';

export default {
  name: 'IntegrationConditionMessage',
  props: {
    integrationName: {
      type: String,
      required: false
    },
    integrationType: {
      type: String,
      required: true
    }
  },
  computed: {
    integrationInstance() {
      return getIntegrationInstance(this.integrationType);
    },
    displayName() {
      return this.integrationInstance.displayName;
    },
    messageClass() {
      return this.integrationName ? 'is-info' : 'is-warning';
    }
  }
};
</script>

<style lang="scss" scoped>
  .message {
    margin-right: 50px;
  }
  .message-body {
    padding: 12px;
    margin-bottom: 24px;
  }
</style>
