<template>
  <div>
    <div class="main-view-header">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h4 class="title is-4">Placements</h4>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-field>
              <b-dropdown>
                <button
                  slot="trigger"
                  class="button"
                >
                  <span>Sort By: {{ sortBy.name }}</span>
                  <span class="icon"><i class="fa fa-caret-down"></i></span>
                </button>

                <b-dropdown-item
                  v-for="option of sortOptions"
                  :key="option.val"
                  @click="sortBy = option"
                >{{ option.name }}</b-dropdown-item>
              </b-dropdown>
            </b-field>
          </div>
          <div class="level-item">
            <b-field>
              <p class="control">
                <b-dropdown>
                  <button
                    slot="trigger"
                    class="button"
                  >
                    <span>{{ filterBy ? filterBy.name : 'Filters' }}</span>
                    <span class="icon"><i class="fa fa-caret-down"></i></span>
                  </button>

                  <b-dropdown-item
                    v-for="(option, optIndex) in filterOptions"
                    :key="optIndex"
                    @click="filterBy = option"
                  >{{ option.name }}</b-dropdown-item>
                </b-dropdown>
              </p>
              <b-input
                v-model="filterQuery"
                type="search"
                placeholder="Search..."
              ></b-input>
            </b-field>
          </div>
        </div>
      </div>
    </div>

    <div class="main-view-sub-header">
      <div class="tabs is-boxed">
        <ul>
          <li
            v-for="(tab, index) in ['Active', 'Inactive', 'Archived']"
            :id="`tab-placements-item-${tab.toLowerCase()}`"
            :key="index"
            :class="{ 'is-active': tabSelection === tab }"
            @click="(evt) => handleTabSelection(evt, tab)"
          >
            <a :title="getTabTip(tab)">{{ tab }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="main-view-content has-pagination-footer is-white">

      <div
        v-if="filtered.length > 0"
        class="level ml-4"
      >
        <div class="level-left">
          <div class="level-item">
            <b-checkbox
              v-model="masterCheckboxModel"
              type="is-info"
              :indeterminate="isMasterCheckboxIndeterminate"
            />
          </div>
          <div class="level-item">
            <b-button
              icon-left="download"
              type="is-info"
              :disabled="selectedIds.length === 0 || isDownloadingCsv"
              :loading="isDownloadingCsv"
              @click="handleCsvDownload"
            >
              Download CSV
            </b-button>
          </div>
        </div>
      </div>

      <b-table
        :data="filtered"
        :loading="isLoading"
        paginated
        pagination-simple
        :current-page.sync="currentPage"
        :per-page="pageSize"
        pagination-size="is-hidden"
        sort-icon="caret-up"
        hoverable
      >
        <b-table-column
          v-slot="props"
          width="50"
        >
          <b-checkbox
            :value="selectedIds.includes(props.row.placementId)"
            type="is-info"
            @input="handlePlacementChecked(props.row.placementId)"
          />
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="name"
          sortable
          label="Placement Name"
        >
          <router-link :to="`/placements/${props.row.placementId}`">
            {{ props.row.name }}
          </router-link>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="name"
          sortable
          label="Placement ID"
        >
          {{ props.row.placementId }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          :visible="tabSelection === 'Archived'"
          field="archivedBy"
          sortable
          label="Archived By"
        >
          {{ props.row.archivedBy }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          :visible="tabSelection === 'Archived'"
          field="archivedAt"
          sortable
          label="Archived At"
        >
          {{ props.row.archivedAt | filterArchiveDateTime }}
        </b-table-column>

        <template
          v-if="!isLoading"
          slot="empty"
        >
          <article class="message is-warning">
            <div class="message-body">
              No {{ tabSelection.toLowerCase() }} placements were found for this site. Placements must be created through the CDK.
            </div>
          </article>
        </template>
      </b-table>
    </div><!-- /.main-view-content -->

    <footer class="main-view-footer">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <button
              class="button"
              :disabled="currentPage === 1"
              @click.stop="getPage(-1)"
            >Previous</button>
          </div>
          <div class="level-item">
            <button
              class="button"
              :disabled="(pageSize * currentPage) >= filtered.length"
              @click.stop="getPage(1)"
            >Next</button>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <strong>Total Placements: {{ placements.length }}</strong>
          </div>
        </div>
      </div>
    </footer><!-- /.main-view-footer -->

    <b-loading
      :active.sync="isLoading"
      :is-full-page="false"
    ></b-loading>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';
import { hasLength, downloadCsv } from '@/modules/utilities';
import { bus } from '@/main.js';

export default {
  name: 'Placements',
  data() {
    return {
      tabSelection: 'Active',
      sortBy: { name: 'Last Edited', val: 'dateCreated' },
      sortOptions: [
        { name: 'Last Edited', val: 'dateCreated' },
        { name: 'Alphabetical', val: 'nameAsc' }
      ],
      filterQuery: '',
      filterBy: { name: 'Placement Name', val: 'name' },
      filterOptions: [
        { name: 'Placement ID', val: 'placementId' },
        { name: 'Placement Name', val: 'name' },
        { name: 'Asset Name', val: 'assets' },
        { name: 'Asset Tag', val: 'tags' }
      ],
      currentPage: 1,
      pageSize: 20,
      isLoading: false,
      isDownloadingCsv: false,
      selectedIds: [],
      csvAttributes: [
        'siteId',
        'placementId',
        'urlMatch',
        'archived',
        'archivedAt',
        'archivedBy',
        'createdAt',
        'previewUrl',
        'name',
        'assetIds',
        'selector',
        'location'
      ]
    };
  },
  computed: {
    placements: sync('placements/placements'),
    siteId() {
      return this.$store.state.siteId;
    },
    filtered() {
      return this.sortPlacements(this.filterPlacements(this.placements));
    },
    masterCheckboxModel: {
      get() {
        return this.selectedIds.length === this.filtered.length;
      },
      set(val) {
        if (val) {
          this.selectedIds = this.filtered.map(placement => placement.placementId);
        } else {
          this.selectedIds = [];
        }
      }
    },
    isMasterCheckboxIndeterminate() {
      return this.selectedIds.length > 0 && this.selectedIds.length !== this.filtered.length;
    }
  },
  created() {
    this.getDependencies();
    bus.$on('site-changed', this.getDependencies);
  },
  mounted() {
    // check route for placement ID to filter by
    if (this.$route.params.placementId) {
      this.filterBy = this.filterOptions.find(obj => obj.val === 'placementId');
      this.filterQuery = this.$route.params.placementId;
    }
  },
  methods: {
    async getDependencies() {
      this.isLoading = true;
      if (this.siteId) this.$store.dispatch('placements/getAllPlacementsAndAssets', true);
      this.isLoading = false;
    },
    filterPlacements(placements) {
      let filtered;

      // Handle Tab Filtering
      switch (this.tabSelection) {
        case 'Archived':
          filtered = placements.filter(placement => placement.archived === 1);
          break;
        case 'Active':
          filtered = placements.filter(placement => placement.isActive && placement.archived === 0);
          break;
        default:
          filtered = placements.filter(placement => !placement.isActive && placement.archived === 0);
      }

      if (!this.filterBy || !hasLength(this.filterQuery)) {
        return filtered;
      }
      const query = this.filterQuery.toLowerCase();
      return filtered.filter((placement) => {
        if (this.filterBy.val !== 'tags' && placement[this.filterBy.val] === undefined) return false;
        let result = true;
        switch (this.filterBy.name) {
          case 'Asset Name':
            result = (
              hasLength(placement.assets)
              && placement.assets.find(a => a.name.toLowerCase().indexOf(query) > -1) !== undefined
            );
            break;
          case 'Asset Tag':
            result = (
              hasLength(placement.assets)
              && placement.assets.find(function (asset) {
                // Check if asset has tags property
                return hasLength(asset.tags) ? asset.tags.find(tag => tag.includes(query)) : false;
              }));
            break;
          default:
            result = placement[this.filterBy.val].toLowerCase().indexOf(query) > -1;
            break;
        }
        return result;
      });
    },
    sortPlacements(placements) {
      const clonedPlacements = [ ...placements ];
      if (this.sortBy.val === 'dateCreated') {
        clonedPlacements.sort(this.sortPlacementsByDateCreated);
      } else if (this.sortBy.val === 'nameAsc') {
        clonedPlacements.sort(this.sortPlacementsByNameAsc);
      }

      return clonedPlacements;
    },
    sortPlacementsByDateCreated(placement1, placement2) {
      if (placement1.createdAt === placement2.createdAt) {
        return 0;
      }
      if (placement1.createdAt > placement2.createdAt) {
        return -1;
      }
      return 1;
    },
    sortPlacementsByNameAsc(placement1, placement2) {
      if (placement1.name.toLowerCase() === placement2.name.toLowerCase()) {
        return 0;
      }
      if (placement1.name.toLowerCase() < placement2.name.toLowerCase()) {
        return -1;
      }
      return 1;
    },
    /**
     * Emit element click event
     * @param {Object} e - element click event
     */
    elementClick(e) {
      bus.$emit('elem-click', e);
    },
    getPage(int) {
      this.currentPage += int;
    },
    /**
     * Switch tabs on tab click
     * @param {Object} clickEvent - element click event
     * @param {String} tab - tab clicked
     */
    handleTabSelection(clickEvent, tab) {
      this.selectedIds = [];
      this.elementClick(clickEvent);
      this.tabSelection = tab;
    },
    /**
     * Get tooltip message when hovering over the tab options
     * @param {String} tab - 'All, Active, Inactive, Archived
     */
    getTabTip(tab) {
      switch (tab) {
        case 'Active':
          return 'Placements that are assigned to an active experience.';
        case 'Inactive':
          return 'Placements that are assigned to an inactive experience.';
        case 'Archived':
          return 'Placements that have been archived and are not eligible to be in an experience unless unarchived.';
        default:
          return '';
      }
    },
    handlePlacementChecked(id) {
      if (this.selectedIds.includes(id)) {
        this.selectedIds = this.selectedIds.filter(selectedId => selectedId !== id);
      } else {
        this.selectedIds.push(id);
      }
    },
    handleCsvDownload() {
      if (this.selectedIds.length === 0) {
        return;
      }
      this.isDownloadingCsv = true;

      const csvItems = this.placements.filter(placement =>
        this.selectedIds.includes(placement.placementId));

      const filename =
        this.$store.state.activeAccountSites.find(s => s.siteId === this.siteId).name.replace(/\./g, '-')
        + '_placements_'
        + new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-')
        + '.csv';

      downloadCsv(csvItems, this.csvAttributes, filename);

      this.isDownloadingCsv = false;
    }
  }
};
</script>

<style lang="scss">
.main-view-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
