<template>
  <div class="add-on">
    <div class="main-view-header" data-cy-test="main-view-header">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div
              class="breadcrumb is-medium"
              aria-label="breadcrumbs"
            >
              <ul>
                <li><router-link to="/add-ons" data-cy-test="header-add-ons">Add-Ons</router-link></li>
                <li class="is-active">
                  <router-link
                    v-if="addOn
                      && addOn.addOnId
                      && addOn.name"
                    :to="`/add-ons/${addOn.addOnId}`"
                    data-cy-test="header-add-ons-name"
                  >
                    {{ addOn.name }}
                  </router-link>
                  <a
                    v-else
                    href="javascript:void(0)"
                  >{{ isLoading ? 'Loading...' : 'Unknown Add-On' }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main-view-content expanded-header has-pagination-footer">

      <AddOn
        v-if="$store.state.siteId"
        :is-editable="true"
      />

      <b-loading
        :active.sync="isLoading"
        :is-full-page="false"
      />
    </div><!-- /.main-view-content -->
  </div>
</template>

<script>
import AddOn from '@/components/AddOns/AddOn';
import { sync } from 'vuex-pathify';

export default {
  name: 'Add-On',
  components: {
    AddOn
  },
  computed: {
    isLoading: sync('addOns/isLoading'),
    addOn: sync('addOns/focused')
  },
  created() {
    this.$store.dispatch('addOns/getFocused');
  }
};
</script>
