<template>
  <div
    class="card audience is-expandable"
    :class="{ 'has-insights' : hasInsights }"
  >
    <header
      class="card-header"
      :class="{ 'card-header--faded': fadeChange }"
      @click="isExpanded = !isExpanded"
    >
      <div class="card-header-title">
        <span
          class="card-header-box"
          :class="{ 'no-margin': isMovable }"
        >
          <b-tooltip
            v-if="hasInsights"
            type="is-dark"
            position="is-right"
            :label="`${insights.precisePercentage}% of Decisions Created`"
          >
            <span v-if="insights.precisePercentage > 0 && insights.precisePercentage < 1">&lt;1%</span>
            <span v-else>{{ insights.percentage }}%</span>
          </b-tooltip>
        </span>

        <div
          v-if="isMovable"
          class="card-header-icon audience-move-handle"
        >
          <span class="icon has-text-info">
            <i class="fa fa-arrows"></i>
          </span>
        </div>

        <span data-cy-test="audience-name-in-table">{{ audience.name }}</span>
      </div><!-- /.card-header-title -->

      <div class="card-header-item">
        <div class="tags has-addons">
          <span
            class="tag"
            data-cy-test="tests-icon"
            :class="{
              'is-success': testCount !== 0,
              'is-warning': testCount === 0
            }"
          >
            Tests
          </span>
          <span class="tag" data-cy-test="test-count">{{ testCount }}</span>
        </div>
      </div>

      <!-- Expand/Collapse Header Icon -->
      <a
        href="javascript:void(0)"
        class="card-header-icon"
        aria-label="expand card"
      >
        <span
          class="icon"
          :class="{ 'is-rotated': isExpanded }"
        >
          <i
            class="fa fa-angle-down"
            aria-hidden="true"
          ></i>
        </span>
      </a><!-- /.card-header-icon -->
    </header><!-- /.card-header -->

    <div
      v-if="isExpanded"
      class="card-content is-relative"
    >
      <div class="audience-id">
        <span class="tag is-light">Audience ID: {{ audience.audienceId }}</span>
      </div>
      <div class="tabs">
        <ul>
          <li :class="{ 'is-active': activeTab === 0}"><a
            href="javascript:void(0)"
            data-cy-test="details-tab"
            @click="activeTab = 0;"
          >Details</a></li>
          <li
            v-if="hasInsights"
            :class="{ 'is-active': activeTab === 1}"
          ><a
            :disabled="isEditing"
            href="javascript:void(0)"
            data-cy-test="insights-tab"
            @click="activeTab = 1;"
          >Insights</a></li>
        </ul>
      </div>
      <section v-if="activeTab === 0">
        <AudienceForm
          v-if="isEditing"
          :initial-audience="audience"
          :all-audiences="allAudiences"
          :experiences="experiences"
          :draft-mode="draftMode"
          @reset-audience="handleAudienceReset"
          @update-audience="handleAudienceUpdate"
        />

        <div v-else>
          <div
            v-if="!audience.isEndpoint"
            class="card-content-section"
          >
            <article
              v-if="audience.description && audience.description.length"
              class="message is-info"
            >
              <div class="message-body">
                <strong>Description</strong>
                <p
                  style="white-space:pre-wrap"
                  data-cy-test="audience-description-text"
                  v-html="audience.description"
                ></p>
              </div>
            </article>

            <Rules
              :rules="audience.rules"
              :key-id="audience.audienceId"
              :is-editing="false"
            />
          </div>

          <!-- Test List -->
          <div class="card-content-section">
            <h5 class="title is-5">Audience Tests</h5>

            <div v-if="hasTests && showTests">
              <AudienceTest
                v-for="test in testList"
                :key="test.testId"
                :initial-test="test"
                :experiences="experiences"
                :addOns="addOns"
                :tests="tests"
                :experienceToTests="experienceToTests"
                :visitors-pct="test.visitorsPct"
                :site-id="siteId"
                :is-editable="false"
              />

              <div class="columns select-add-tests">
                <div class="column is-half"></div>
                <div class="column is-half has-text-right">
                  <strong class="pr60">Total Allocation: {{ totalTestAllocation }}%</strong>
                </div>
              </div>
            </div>

            <!-- No Tests Detected -->
            <div
              v-else
              class="content top15"
            >
              <em>No tests were found for this audience.</em>
            </div>
          </div>

          <!-- Champion Section -->
          <div class="card-content-section" data-cy-test="champion-experience-section">
            <h5 class="title is-5" data-cy-test="title">Champion Experience</h5>

            <NestedExperience
              v-if="championExperience"
              :experience="championExperience"
              :allAddOns="addOns"
              :allTests="tests"
              :experienceToTests="experienceToTests"
            />
            <div
              v-else
              class="content"
            >
              <em>No champion experience was found for this audience.</em>
            </div>
          </div>
        </div><!-- v-else -->
      </section>

      <section v-if="hasInsights && activeTab === 1">
        <IndividualHitsOverTime
          :item-name="audience.name"
          item-type="Audience"
          :payload="{ 'audienceId.keyword': audience.audienceId }"
          :siteId="siteId"
        />
      </section>
    </div><!-- /.card-content -->

    <footer
      v-if="!isEditing && isExpanded && activeTab === 0"
      class="card-footer"
    >
      <div class="level">
        <div class="level-left">
          <div
            class="level-item"
            data-cy-test="edit-button"
          >
            <base-button-edit
              @click="handleEdit"
            />
          </div>

          <div
            v-if="!audience.isEndpoint"
            class="level-item"
            data-cy-test="duplicate-button"
          >
            <base-button-duplicate @click="duplicateAudience" />
          </div>
        </div>

        <div
          v-if="!audience.isEndpoint"
          class="level-right"
          data-cy-test="delete-button"
        >
          <div class="level-item">
            <base-button-delete
              :loading="isDeletingAudience"
              @click="confirmDeleteAudience"
            />
          </div>
        </div><!-- /.level-right -->
      </div><!-- /.level -->
    </footer><!-- /.card-footer -->
  </div><!-- /.card -->
</template>

<script>
import { bus } from '@/main';
import { hasLength, numberWithCommas } from '@/modules/utilities';
import AudienceForm from '@/components/Audiences/AudienceForm';
import Rules from '@/components/Rules/Rules';
import AudienceTest from '@/components/Audiences/AudienceTest';
import IndividualHitsOverTime from '@/components/Insights/IndividualHitsOverTime';
import NestedExperience from '@/components/Experiences/NestedExperience';

export default {
  name: 'Audience',
  components: {
    AudienceForm,
    Rules,
    AudienceTest,
    NestedExperience,
    IndividualHitsOverTime
  },
  props: {
    siteId: {
      type: String,
      required: true
    },
    audience: {
      type: Object,
      required: true
    },
    fadeChange: {
      type: Boolean,
      required: true
    },
    insights: {
      type: Object,
      default() {
        return {
          percentage: 0,
          precisePercentage: 0
        };
      }
    },
    allAudiences: {
      type: Array
    },
    experiences: {
      type: Array
    },
    addOns: {
      type: Array
    },
    tests: {
      type: Array
    },
    experienceToTests: {
      type: Object
    },
    isDragging: {
      type: Boolean
    },
    draftMode: {
      type: Boolean
    },
    hasInsights: {
      type: Boolean
    }
  },
  data() {
    return {
      isEditing: false,
      isExpanded: false,
      showTests: true,
      activeTab: 0,
      isDeletingAudience: false
    };
  },
  computed: {
    hasLock() {
      return this.$store.getters.siteLockOwner;
    },
    isSuperUser() {
      return this.$store.getters.isSuperUser;
    },
    // testList maps over all test objects and returns
    // the full test object with visitorsPct included
    testList() {
      if (!hasLength(this.audience.tests)) return [];
      return this.audience.tests;
    },
    // Get the amount of allocated traffic
    totalTestAllocation() {
      if (!hasLength(this.audience.tests)) return 0;
      return this.audience.tests
        .map(test => test.isPaused ? 0 : test.visitorsPct)
        .reduce((curr, next) => curr + next);
    },
    hasEditableRules() {
      return this.audience.rules.length > 1;
    },
    hasTests() {
      return hasLength(this.testList);
    },
    championExperience() {
      if (!this.audience.championExperienceId || !hasLength(this.experiences)) return null;

      return this.experiences.find(exp => exp.experienceId === this.audience.championExperienceId);
    },
    isMovable() {
      return this.hasLock && !this.audience.isEndpoint;
    },
    testCount() {
      return this.testList.length;
    }
  },
  watch: {
    isEditing(editing) {
      if (editing && this.activeTab === 1) {
        this.activeTab = 0;
      }
    }
  },
  created() {
    this.handleAudienceReset();
    this.registerEventListeners();
  },
  methods: {
    numberWithCommas,
    handleEdit() {
      this.$root.establishSiteLock().then(() => {
        this.isEditing = true;
      });
    },
    handleLostSiteLock() {
      this.handleAudienceReset();
      // If a beufy delete prompt is open close it
      if (this.deleteDialog) {
        this.deleteDialog.close();
      }
    },
    handleAudienceReset() {
      this.isEditing = false;
    },
    handleAudienceUpdate(audience) {
      this.isEditing = false;

      // filter out empty rule groups
      audience.rules = audience.rules.filter(rg => rg.length > 0);

      // Check for Champion Experience
      if (audience.championExperienceId === null) {
        delete audience.championExperienceId;
      }

      // Check for Description
      if (audience.description === undefined || audience.description.length === 0) {
        delete audience.description;
      }

      if (audience.audienceId) {
        this.$emit('update-audience', audience);
      }
    },
    // Delete audience confirmation modal
    confirmDeleteAudience() {
      this.$root.establishSiteLock().then(() => {
        // Open a dialog to confirm deletion.
        // Store the dialog component in deleteDialog so it can be programatically closed as needed
        this.deleteDialog = this.$buefy.dialog.confirm({
          title: `Deleting ${this.audience.name}`,
          message: `
            Are you sure you want to delete ${this.audience.name}?
            You will need to reconstruct this audience in order to bring it back.
          `,
          confirmText: 'Delete',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.deleteAudience();
          }
        });
      });
    },
    /**
     * Force a re-render, editing arrays have issues with triggering re-renders
     * @luowenxing https://github.com/vuejs/Discussion/issues/356
     */
    reRenderTests() {
      this.showTests = false;
      this.$nextTick(() => {
        this.showTests = true;
      });
    },
    registerEventListeners() {
      bus.$on('site-changed', this.handleAudienceReset);
      bus.$on('got-new-traffic-flow-data', this.reRenderTests);
      bus.$on('site-lock-lost-user', this.handleLostSiteLock);
    },
    // Emit delete audience to TrafficFlows
    async deleteAudience() {
      this.isDeletingAudience = true;
      this.$emit('delete-audience', this.audience.audienceId);
      this.isDeletingAudience = false;
    },
    duplicateAudience() {
      this.$root.establishSiteLock().then(() => {
        this.$emit('duplicate-audience', this.audience);
        this.isExpanded = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>

.card-header--faded {
  opacity: .3;
}

.audience {
  overflow: visible;

  .card-header-box {
    display: none;
  }

  &.has-insights {
    & > .card-header > .card-header-title {
      margin-bottom: 0;
      padding: 0;
    }

    .card-header-box {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
    }
  }

  .pr60 {
    padding-right: 60px !important;
  }
}
</style>
