import { make } from 'vuex-pathify';
import { axiosInstance as axios } from '@/plugins/axios';

const state = {
  list: [],
  focused: {},
  isLoading: false
};

const mutations = make.mutations(state);

const actions = {
  async getList({ dispatch, commit, rootState }) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'assets/getList' }, { root: true });
      return;
    }

    try {
      commit('SET_IS_LOADING', true);
      const res = await axios.get(`/sites/${rootState.siteId}/assets`);
      commit('SET_LIST', res.data);
    } catch (e) {
      e.title = 'There was a problem retrieving assets data.';
      commit('error', e, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async getFocused({ commit, rootState, dispatch }, assetId) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'asset/getFocused', payload: assetId }, { root: true });
      return;
    }

    try {
      commit('SET_IS_LOADING', true);
      const res = await axios.get(`/sites/${rootState.siteId}/assets/${assetId}`);
      commit('SET_FOCUSED', res.data);
    } catch (e) {
      e.title = 'There was a problem retrieving details for this asset.';
      commit('error', e, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async getAssetById({ commit, rootState, dispatch }, assetId) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'asset/getAssetById', payload: assetId }, { root: true });
      return;
    }

    try {
      commit('SET_IS_LOADING', true);
      const { data: asset } = await axios.get(`/sites/${rootState.siteId}/assets/${assetId}`);
      return asset;
    } catch (e) {
      e.title = 'There was a problem retrieving details for this asset.';
      commit('error', e, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async fastForwardAsset({ commit, rootState, dispatch }, { assetId, variables, name }) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'asset/fastForwardAsset', payload: { assetId, variables, name } }, { root: true });
      return;
    }

    try {
      commit('SET_IS_LOADING', true);
      const { data: asset } = await axios.patch(`/sites/${rootState.siteId}/assets/${assetId}`, { variables, name });
      return asset;
    } catch (e) {
      e.title = 'There was a problem updating the asset.';
      commit('error', e, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
