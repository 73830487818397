import { hasLength } from '@/modules/utilities';
import { axiosInstance as axios } from '@/plugins/axios';
import router from '@/router';
import { make } from 'vuex-pathify';

const state = {
  list: [],
  focused: {},
  isLoading: true,
  insights: [],
  showInsights: false,
  lookbackRange: '1d',
  isLoadingInsights: false,
  experienceToTests: {}
};

const mutations = {
  ...make.mutations(state),
  updateExperienceTestMap(state, tests) {
    const experienceToTests = {};

    if (tests) {
      for (let i = 0; i < tests.length; i++) {
        const test = tests[i];
        if (test.strategy.mvt) {
          const { experiences } = test.strategy.mvt;
          if (experiences.length) {
            for (let j = 0; j < experiences.length; j++) {
              const experience = experiences[j];
              // Don't process the default experience
              if (experience.experienceId === '0') continue;
              if (!experienceToTests[experience.experienceId]) {
                experienceToTests[experience.experienceId] = [];
              }
              // Create array of experienceId: [testId, testId]
              experienceToTests[experience.experienceId].push(test.testId);
            }
          }
        } else if (test.strategy.mabExp) {
          const experienceIds = [...test.strategy.mabExp.dapi.options];
          if (experienceIds.length) {
            for (let experienceId of experienceIds) {
              // Don't process the default experience
              if (experienceId === '0') continue;
              if (!experienceToTests[experienceId]) {
                experienceToTests[experienceId] = [];
              }

              // Create array of experienceId: [testId, testId]
              experienceToTests[experienceId].push(test.testId);
            }
          }
        }
      }
    }
    state.experienceToTests = experienceToTests;
  }
};

const actions = {
  async getList({ dispatch, commit, rootState }, attrs ) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'experiences/getList', payload: attrs }, { root: true });
      return;
    }
    try {
      commit('SET_IS_LOADING', true);
      const params = { status: 'all' };
      // Don't set attributes if null is explicitly passed for attrs
      if (attrs !== null) {
        params.attributes = attrs || ['experienceNumber', 'name', 'experienceId', 'archived', 'tags', 'hash', 'archivedBy', 'archivedAt'];
      }
      const res = await axios.get(`/sites/${rootState.siteId}/experiences`, {
        params
      });
      res.data.experiences.forEach(exp => exp.assetPlacements = exp.assetPlacements || []);
      commit('SET_LIST', res.data.experiences);
    } catch (e) {
      e.title = 'There was a problem retrieving experience data.';
      commit('error', e, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async getFocused({ dispatch, commit, rootState }) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'experiences/getFocused' }, { root: true });
      return;
    }
    try {
      commit('SET_IS_LOADING', true);
      const res = await axios.get(`/sites/${rootState.siteId}/experiences/${router.history.current.params.experienceId}`);
      res.data.experience.assetPlacements = res.data.experience.assetPlacements || [];
      commit('SET_FOCUSED', res.data.experience);
    } catch (e) {
      e.title = 'There was a problem retrieving details for this experience.';
      commit('error', e, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async createExperience({ commit, rootState, dispatch }, experience) {
    try {
      const res = await axios.post(`/sites/${rootState.siteId}/experiences`, { experience });
      if (res.data.exists) {
        const error = new Error(`
          An experience with these combinations
          already exists Experience Number: ${res.data.experience.experienceNumber}
        `);
        commit('error', error, { root: true });
      } else {
        // notify
        window.vm.$buefy.toast.open({
          message: `Experience #${res.data.experience.experienceNumber} created`,
          type: 'is-success'
        });
        router.push('/experiences');
        dispatch('getList');
      }
    } catch (error) {
      error.title = `There was a problem creating a new experience. ${error.message ? error.message : ''}`;
      commit('error', error, { root: true });
    }
  },
  async updateExperience({ commit, rootState, dispatch }, experience) {
    // Remove props not allowed to be changed
    delete experience.isActive;
    delete experience.isChampion;
    delete experience.isHoldOut;

    try {
      const url = `/sites/${rootState.siteId}/experiences/${experience.experienceId}`;
      const res = await axios.post(url, { experience });
      window.vm.$buefy.toast.open({
        message: `Experience ${res.data.experience.experienceNumber} successfully updated.`,
        type: 'is-success'
      });
    } catch (error) {
      error.title = `Could not update experience #${experience.experienceNumber}`;
      error.message = error.response.data || null;
      commit('error', error, { root: true });
    } finally {
      dispatch('getFocused');
    }
  },
  async updateExperiences({ commit, rootState, dispatch }, experiences) {
    // Remove props not allowed to be changed
    experiences.forEach((exp) => {
      delete exp.isActive;
      delete exp.isChampion;
      delete exp.isHoldOut;
    });

    try {
      const url = `/sites/${rootState.siteId}/experiences/batch`;
      const res = await axios.put(url, { experiences });
      window.vm.$buefy.toast.open({
        message: `${res.data.experiences.length} experiences successfully updated.`,
        type: 'is-success'
      });
    } catch (error) {
      error.title = `Could not update experiences`;
      error.message = error.response.data || null;
      commit('error', error, { root: true });
    } finally {
      dispatch('getList');
    }
  },
  async getInsights({
    commit, state, rootState, dispatch
  }) {
    await dispatch('getList');
    if (!hasLength(state.list)) return;

    const payload = {
      filters: {}
    };

    // Dynamically build the payload for elasticsearch
    for (const experience of state.list) {
      payload.filters[experience.experienceId] = {
        match: {
          experienceId: experience.experienceId
        }
      };
    }

    commit('SET_IS_LOADING_INSIGHTS', true);
    try {
      const res = await axios.post(`/sites/${rootState.siteId}/insights/breakdowns/${state.lookbackRange}`, payload);
      commit('SET_INSIGHTS', res.data);
    } catch (e) {
      commit('error', e, { root: true });
    } finally {
      commit('SET_IS_LOADING_INSIGHTS', false);
    }
  }
};

const getters = {
  allExperienceNames(state) {
    const expNames = [];
    if (hasLength(state.list)) {
      for (const exp of state.list) {
        if (exp.name && !expNames.includes(exp.name)) expNames.push(exp.name);
      }
    }
    return expNames;
  },
  allExperienceTags(state) {
    const expTags = [];
    if (hasLength(state.list)) {
      for (const exp of state.list) {
        if (hasLength(exp.tags)) {
          exp.tags.forEach(function (tag) {
            if (!expTags.includes(tag)) {
              expTags.push(tag);
            }
          });
        }
      }
    }
    return expTags;
  },
  allExperienceHashes(state) {
    const expHashes = [];
    if (hasLength(state.list)) {
      for (const exp of state.list) {
        expHashes.push({
          number: exp.experienceNumber,
          hash: exp.hash,
          archived: exp.archived
        });
      }
    }
    return expHashes;
  },
  allHitData(state) {
    const results = {};
    // if able, create
    if (state.insights && state.insights.counts) {
      for (const [experienceId, count] of Object.entries(state.insights.counts)) {
        const rawPercent = count / state.insights.total;
        results[experienceId] = {
          percentage: Math.round(rawPercent * 100),
          precisePercentage: Math.round(rawPercent * 10000) / 100
        };
      }
    }
    return results;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
