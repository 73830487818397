<template>
  <div
    class="number-input"
    @click.stop
  >
    <b-field
      grouped
      class="number-input__group"
    >
      <div class="field has-addons">
        <p class="control number-input__control">
          <input
            class="input"
            :class="{ 'input-static': isStatic }"
            :readonly="isStatic"
            type="tel"
            pattern="[0-100]"
            :value="value"
            :disabled="isStatic"
            @input="handleInput"
            @keyup.enter="handleInput"
          >
        </p>
        <div v-if="showPercentage">
          <p class="control"><button class="button is-static">%</button></p>
        </div>
      </div>
    </b-field>
  </div>
</template>

<script>
export default {
  name: 'NumberInput',
  props: {
    auto: {
      type: Boolean
    },
    initial: {
      required: false
    },
    isStatic: {
      type: Boolean
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    points: {
      type: Number
    },
    showPercentage: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      value: 0
    };
  },
  watch: {
    points(val) {
      if (this.auto) {
        this.value = val;
      }
    }
  },
  created() {
    if (this.initial) this.value = this.initial;
  },
  methods: {
    handleInput(evt) {
      if (evt.target && evt.target.value) {
        this.value = parseInt(evt.target.value, 10);
      }
      this.$emit('number-change', this.value);
      this.$emit('update:points', this.value);
    }
  }
};
</script>

<style lang="scss">
  /* Remove controls from Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  /* Re-applies the controls on :hover and :focus */
  input[type="number"]:hover,
  input[type="number"]:focus {
    -moz-appearance: number-input;
  }

  /* Remove controls from Safari and Chrome */
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; /* Removes leftover margin */
  }

  .number-input {
    display: inline-flex;

    .is-grouped {
      align-items: stretch;

      .number-input__action-group {
        align-items: center;
        display: inline-flex;
        margin-bottom: 0;
        position: relative;
        width: 75px;

        .button {
          &:focus {
            outline: none;
            box-shadow: inset 0 0 1px 0 #3273dc;
          }
        }
      }
    }

    &__control {
      width: 55px;

      .input.input-static {
        cursor: initial;
      }
    }
  }
</style>
