<template>
  <div class="variables-step" :data-cy-test="`${fileType}-variable-step`">
    <span v-if="hasVariables">
      <h5 class="title is-5" :data-cy-test="`${fileType}-variable-title`">{{ variableTypeTitle }} Variables</h5>
      <div
        v-for="varName in globalVariables[fileType]"
        :key="`${fileType}-${varName}`"
      >
        <div class="mb-3 mt-5"></div>
        <variable-input
          v-model="editingVariables.variables[varName]"
          :data-cy-test="`${fileType}-variable-input-${varName}`"
          :name="varName"
          :nullable="false"
          class="is-flex-grow-3"
        />
      </div>
    </span>

    <div v-if="hasConditionals" class="mb-5">
      <h5 class="title is-5" :data-cy-test="`${fileType}-optional-title`">{{ variableTypeTitle }} Optional Variables</h5>
      <span v-for="[sectionName, section] in Object.entries(preampVars.conditionals[fileType])" :key="`conditional-${fileType}-${sectionName}`">
        <optional-variable-section
          :key="sectionName"
          :data-cy-test="`${fileType}-optional-section-${sectionName}`"
          :sectionName="sectionName"
          :section="section"
          :assetVariables="editingVariables"
          class="my-3"
        />
      </span>
    </div>
  </div>
</template>

<script>
import VariableInput from '../VariableInput.vue';
import OptionalVariableSection from '../OptionalVariableSection.vue';

export default {
  name: 'VariableStep',
  components: {
    'variable-input': VariableInput,
    'optional-variable-section': OptionalVariableSection
  },
  props: {
    fileType: {
      type: String,
      required: true
    },
    editingVariables: {
      type: Object,
      required: true
    },
    globalVariables: {
      type: Object,
      required: true
    },
    preampVars: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasVariables() {
      return this.preampVars.variables[this.fileType] && this.globalVariables[this.fileType].length > 0;
    },
    hasConditionals() {
      // if no conditionals object at all, return false
      if (!this.preampVars.conditionals || !this.preampVars.conditionals[this.fileType]) return false;

      const conditionalsUsed = Object.values(this.preampVars.conditionals[this.fileType]).flatMap(x => x.variablesUsed);
      if (!conditionalsUsed.length) return false;

      return true;
    },
    variableTypeTitle() {
      if (this.fileType === 'metadata') {
        return 'JSON';
      }
      return this.fileType.toUpperCase();
    }
  }
};
</script>

<style scoped lang="scss">
  .variables-step {
    margin-bottom: 24px;
  }
</style>

