<template>
  <div class="folders-wrapper" data-cy-test="folders-card">
    <div class="card folders-tree">
      <div class="folders-header" data-cy-test="folders-card-header">
        Folders
      </div>
      <ul class="folders-list">
        <li
          class="folders-item"
          :class="{ 'folders-item__focused': isOpenFolder('root') }"
          data-cy-test="folders-item-Unassigned"
          @click="handleClickFolder(folderTree.root)"
        >
          <div>Unassigned</div>
          <div class="folders-item_icons">
            <i class="fa fa-thumb-tack folders-item_icon--pinned" data-cy-test="pinned-icon"></i>
          </div>
        </li>
        <li
          v-for="folder in folderList"
          :key="folder.folderId"
          class="folders-item"
          :data-cy-test="`folders-item-${folder.name}`"
          :class="{ 'folders-item__focused': isOpenFolder(folder.folderId) }"
          @click="handleClickFolder(folder)"
        >
          <span class="folders-item__name">{{ folder.name }}</span>
          <div class="folders-item_icons">
            <i class="fa fa-edit folders-item__icon folders-item__icon--edit" :data-cy-test="`folder-icon-edit-${folder.name}`" @click.stop="handleEditFolder(folder)"></i>
            <i class="fa fa-trash folders-item__icon" :data-cy-test="`folder-icon-delete-${folder.name}`" @click.stop="handleOpenDeleteFolderModal(folder)"></i>
          </div>
        </li>
      </ul>
    </div>

    <CreateEditFolderModal
      v-if="isCreateEditFolderModalActive"
      v-model="isCreateEditFolderModalActive"
      :folder="editingFolder"
      :isEditing="isEditingFolder"
      class="folder-modal"
      @updateFolder="handleUpdateFolder"
      @createFolder="handleCreateFolder"
    />
    <DeleteFolderModal
      v-if="isDeleteFolderModalActive"
      v-model="isDeleteFolderModalActive"
      :folder="editingFolder"
      class="folder-modal"
      @deleteFolder="handleDeleteFolder"
    />
    <MoveSelectedModal
      v-if="isMoveSelectedModalActive"
      v-model="isMoveSelectedModalActive"
      :folders="allFolders"
      :openFolder="editingFolder"
      :selectedContents="selectedEntityIds"
      class="folder-modal"
      @moveSelected="handleUpdateFolder"
    />
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';
import { bus } from '@/main';
import CreateEditFolderModal from '@/components/Folders/CreateEditFolderModal';
import DeleteFolderModal from '@/components/Folders/DeleteFolderModal';
import MoveSelectedModal from '@/components/Folders/MoveSelectedModal';

export default {
  name: 'Folders',
  components: {
    CreateEditFolderModal,
    DeleteFolderModal,
    MoveSelectedModal
  },
  data() {
    return {
      editingFolder: {},
      isCreateEditFolderModalActive: false,
      isDeleteFolderModalActive: false,
      isMoveSelectedModalActive: false,
      allFolders: [],
      selectedEntityIds: [],
      isEditingFolder: false
    };
  },
  computed: {
    folderTree: sync('folders/tree'),
    openFolder: sync('folders/openFolder'),
    isLoading: sync('folders/isLoading'),
    siteId() {
      return this.$store.state.siteId;
    },
    hasLock() {
      return this.$store.getters.siteLockOwner;
    },
    folderList() {
      return this.folderTree.root && this.folderTree.root.folders
        ? this.folderTree.root.folders : [];
    }
  },
  watch: {
    siteId() {
      this.$store.set('folders/openFolder', {});
      this.getFolderTree();
    },
    'folderTree': {
      deep: true,
      handler(oldVal, newVal) {
        this.allFolders = newVal.root && newVal.root.folders
          ? this.addRootFolderToFoldersList(this.folderTree.root) : [];
      }
    }
  },
  async created() {
    await this.getFolderTree();

    bus.$on('site-changed', this.getFolderTree);
    bus.$on('newFolderButton', this.handleNewFolder);
    bus.$on('moveSelectedButton', this.handleOpenMoveSelectedModal);
  },
  beforeDestroy() {
    //reset the open folder
    this.$store.set('folders/openFolder', {});

    bus.$off('site-changed', this.getFolderTree);
    bus.$off('newFolderButton', this.handleNewFolder);
    bus.$off('moveSelectedButton', this.handleOpenMoveSelectedModal);
  },
  methods: {
    async getFolderTree() {
      this.isLoading = true;

      if (this.siteId) {
        await this.$store.dispatch('folders/getTree');

        this.isLoading = false;
        this.$emit('foldersLoaded', this.folderTree.root);

        this.allFolders = this.folderTree.root && this.folderTree.root.folders ? this.addRootFolderToFoldersList(this.folderTree.root) : [];

        return;
      }

      this.isLoading = false;
    },
    handleClickFolder(folder) {
      this.editingFolder = folder;
      this.$store.set('folders/openFolder', folder);
      this.$emit('clickFolder', folder);
    },
    async handleCreateFolder(folderName) {
      const newFolderResponse = await this.$store.dispatch('folders/createFolder', folderName);

      // if the folder is created successfully, report to the view with the new tree
      if (newFolderResponse.data && newFolderResponse.data.root) {
        this.$emit('folderCreated', newFolderResponse.data.root);
      }

      return newFolderResponse;
    },
    async handleDeleteFolder(folder) {
      const updatedFolderTree = await this.$store.dispatch('folders/deleteFolder', folder);

      // update assets for the folder
      this.$store.dispatch('folders/getFolderContents', updatedFolderTree.root.folderId);
      this.$emit('foldersLoaded', updatedFolderTree.root);
      this.editingFolder = {};
    },
    async handleUpdateFolder(params) {
      const { newContents, originalFolder } = params;

      // if we have newContents, we're moving items between folders
      if (newContents && newContents.length > 0) {
        // check the contents that we're moving to make sure that there aren't
        // any archived assets being moved, and remove them if so
        newContents.forEach((entityId, index) => {
          if(!originalFolder.contents.includes(entityId)) {
            newContents.splice(index, 1);
          }
        });
      }

      const updatedFolderResponse = await this.$store.dispatch('folders/updateFolder', params);

      // if the response isn't a 200, just return it to the modal caller
      if (updatedFolderResponse.status && updatedFolderResponse.status !== 200) {
        return updatedFolderResponse;
      } else {
        // if its successful, update assets for the folder
        this.$store.dispatch('folders/getFolderContents', updatedFolderResponse.folderId);
        this.editingFolder = {};

        // let the caller know that the move is done
        this.$emit('entitiesMoved');
        return {};
      }
    },
    isOpenFolder(folderId) {
      return this.openFolder.folderId === folderId;
    },
    handleOpenCreateEditFolderModal() {
      this.isCreateEditFolderModalActive = true;
    },
    handleOpenDeleteFolderModal(folder) {
      this.isDeleteFolderModalActive = true;
      this.editingFolder = folder;
    },
    handleOpenMoveSelectedModal(selectedIds) {
      this.editingFolder = this.openFolder;
      this.isMoveSelectedModalActive = true;
      this.selectedEntityIds = selectedIds;
    },
    handleNewFolder() {
      this.isEditingFolder = false;
      this.editingFolder = {};

      this.handleOpenCreateEditFolderModal();
    },
    handleEditFolder(folder) {
      this.isEditingFolder = true;
      this.editingFolder = folder;

      this.handleOpenCreateEditFolderModal();
    },
    addRootFolderToFoldersList(root) {
      return root.folders.concat(root);
    }
  }
};
</script>

<style lang="scss" scoped>
.folders-wrapper, .folders-tree{
  flex: 0 0 276px;
  height: 100%;
}

.folders-list {
  max-width: 276px;
  height: calc(100% - 50px);
  overflow-y: auto;
}

.folders-header {
  border-bottom: 1px solid #dce2ea;
  padding: 14px 16px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

li.folders-item {
  padding: 16px;
  font-size: 14px;
  line-height: 18px;
  color: #5c6f84;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-content: center;
  cursor: pointer;
  border-bottom: 1px solid #edf0f3;

  &:hover {
    background-color: rgba(6, 150, 239, 0.15);
    border-bottom: 1px solid #4cbbff;

    & .folders-item__icon {
      visibility: visible;
    }
  }

  &__focused {
    background-color: rgba(6, 150, 239, 0.15);
    border-bottom: 1px solid #4cbbff;

    & .folders-item__icon {
      visibility: visible;
    }
  }
}

.folders-item__name {
  align-self: flex-start;
  width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.folders-item__icon {
  align-self: flex-end;
  visibility: hidden;
  padding-left: 14px;
  z-index: 10;

  &--edit {
    position: relative;
    top: 1px;
  }

  &:hover {
    color: black;
  }
}

.folder-modal ::v-deep {
  & .card {
    padding: 20px;
  }

  & h2.folder-modal__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 12px;
  }

  & p.folder-modal__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
  }
}
</style>
