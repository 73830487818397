// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Buefy from 'buefy';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-luxon';
import Vue from 'vue';
import App from './App.vue';
import vIcon from './components/Icons/v-icon.vue';
import Clipboard from './directives/clipboard';
import './plugins/axios';
import baseComponents from './plugins/baseComponents';
import baseFilters from './plugins/baseFilters';
import VueChartkick from './plugins/chartkick';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

// Enable Vue Dev Tools and debugging in staging
if (['prod', 'production'].indexOf(process.env.NODE_ENV) === -1) {
  Vue.config.devtools = true;
  Vue.config.debug = true;
  Vue.config.silent = false;
}

// Register component libraries and plugins.
Vue.use(VueChartkick, { adapter: Chart });
Vue.use(Buefy, { defaultIconPack: 'fa' });
Vue.use(baseFilters);
Vue.use(baseComponents);
Vue.use(Clipboard);

// Register global icons for SVG icon use
Vue.component('v-icon', vIcon);

// Re-usable component for cross component communication.
// eslint-disable-next-line vue/require-name-property
export const bus = new Vue({}); // eslint-disable-line import/prefer-default-export

// eslint-disable-next-line vue/require-name-property
window.vm = new Vue({
  router,
  store,
  computed: {
    siteLock() {
      return this.$store.state.siteLock;
    },
    hasLock() {
      return this.$store.getters.siteLockOwner;
    }
  },
  watch: {
    /**
     * Emit an event when a lock is lost.
     * 'site-lock-lost-user' for when lock is lost due to user action
     * 'site-lock-lock-poll' for when lock is lost due to polling
     *
     * Additionally, reroute the user if they are on a locked view
     */
    siteLock(newSiteLock, oldSiteLock) {
      if (!this.hasLock
        && oldSiteLock.owner
        === this.$store.state.user.email
      ) {
        if (newSiteLock.source === 'poll') {
          bus.$emit('site-lock-lost-poll');
        } else {
          bus.$emit('site-lock-lost-user');
        }

        if (this.$route.meta.requiresSiteLock) {
          this.$router.push({ path: '/tests' });
        }
      }
    }
  },
  mounted() {
    // Axios Requests for loading indicator
    this.$on('axios-active', function (val) {
      this.$store.commit('setAxiosActive', val);
    });

    // Poll for site lock status
    this.siteLockPoll();
  },
  methods: {
    // Establish a site lock for making edits to the current site
    async establishSiteLock() {
      return new Promise(async (resolve, reject) => { // eslint-disable-line no-async-promise-executor
        if (this.hasLock) {
          resolve();
          return;
        }

        const { siteId } = this.$store.state;
        try {
          const res = await this.$axios.post(`/sites/${siteId}/lock`);
          this.$store.commit('setSiteLock', {
            owner: res.data.owner,
            siteId,
            source: 'userAction'
          });
          this.$buefy.toast.open({
            message: 'Successfully acquired lock.',
            type: 'is-success'
          });
          resolve();
        } catch (err) {
          err.title = `Could not acquire lock: ${err.response.data}`;
          this.$store.commit('error', err);
          reject();
        }
      });
    },
    /**
     * Release a site lock either by ID or the current site
     * @param {String} id - site to release
     * @param {String} source - source of release ('userAction' or 'poll')
     */
    async releaseSiteLock(
      id = this.$store.state.siteId,
      source = 'userAction',
      site = this.$store.getters.siteDomain
    ) {
      try {
        await this.$axios.delete(`/sites/${id}/lock`);
        this.$buefy.toast.open({
          message: `Released site lock for ${site}`,
          type: 'is-success'
        });
        this.$store.commit('setSiteLock', {
          owner: null,
          siteId: null,
          source
        });
      } catch (err) {
        err.title = 'Could not remove the site lock.';
        this.$store.commit('error', err);
      }
    },
    // Check for the status of the current sites lock
    async checkSiteLock() {
      const { siteId } = this.$store.state;
      if (siteId === null || this.$store.state.maintenanceMode === 1) return;

      try {
        const res = await this.$axios.get(`/sites/${siteId}/lock`);
        let status = {
          source: 'poll',
          owner: null,
          siteId: null
        };

        if (res.data.owner !== null) {
          status = {
            owner: res.data.owner,
            siteId,
            source: 'poll'
          };
        }

        this.$store.commit('setSiteLock', status);
      } catch (err) {
        if (err.message !== 'Network Error' && document.hidden === false) {
          err.title = 'There was an issue checking the site lock.';
          this.$store.commit('error', err);
        }
      }
    },
    // Poll for Site lock
    siteLockPoll() {
      // Check to see if the site is locked
      this.checkSiteLock();

      // Start Polling
      setInterval(async () => {
        this.checkSiteLock();
      }, 20000); // Every 20 seconds
    }
  },
  render: h => h(App)
}).$mount('#app');
