<template>
  <div
    class="modal"
    :class="{ 'is-active': active }"
  >
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" data-cy-test="draft-mode-header">Entering Draft Mode</p>
          <button
            class="delete"
            aria-label="close"
            @click="cancel"
          ></button>
        </header>

        <section class="modal-card-body">
          <div class="content">
            <div v-if="draft !== null">
              <h4 class="title">Edit the existing draft</h4>
              <p>
                There is currently a draft for your site that was last updated on: <br />
                <strong>
                  {{ (draft[0].updatedAt)
                    ? formatTime(draft[0].updatedAt)
                    : formatTime(draft[0].createdAt)
                  }}
                </strong>
              </p>
              <button
                class="button is-info"
                @click="edit"
              >
                <span class="icon">
                  <i class="fa fa-pencil-square-o"></i>
                </span>
                <span>Edit Draft</span>
              </button>
            </div>

            <hr v-if="draft !== null">

            <div>
              <h4 class="title">Create a new draft</h4>
              <p v-if="draft !== null">This will overwrite the existing draft with a copy of the live traffic flow.</p>
              <p v-else data-cy-test="draft-description">This will copy the live traffic flow into a new draft to be edited.</p>
              <button
                class="button is-info"
                data-cy-test="create-draft-button"
                @click="create"
              >
                <span class="icon">
                  <i class="fa fa-plus"></i>
                </span>
                <span>Create Draft</span>
              </button>
            </div>
          </div>
        </section>

        <footer class="modal-card-foot"></footer>
      </div>
    </div>
  </div>
</template>

<script>
import { formatTime } from '@/modules/utilities';

export default {
  name: 'DraftModal',
  props: ['active', 'draft'],
  methods: {
    // Emit create for new draft to trafficFlow
    create() {
      this.$emit('draft-modal-create');
    },
    // Emit edit to trafficFlow
    edit() {
      this.$emit('draft-modal-edit');
    },
    // Emit cancel to trafficFlow
    cancel() {
      this.$emit('draft-modal-cancel');
    },
    formatTime
  }
};
</script>
