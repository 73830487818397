<template>
  <div class="card is-expandable">
    <header
      class="card-header"
      :class="{ 'is-expanded': isExpanded }"
      @click.stop="toggleAccount"
    >
      <div :data-cy-test="`site-name-header-${site.name}`" class="card-header-title">
        {{ site.name }}
      </div>

      <div
        v-if="site.isHIPAA"
        class="card-header-icon"
      >
        <span class="tag is-warning">
          HIPAA
        </span>
      </div>

      <div
        v-if="site.archived"
        class="card-header-icon"
      >
        <span class="tag is-danger">
          Archived
        </span>
      </div>

      <div class="card-header-icon">
        <span class="icon">
          <i
            class="fa"
            :class="(isExpanded) ? 'fa-angle-up' : 'fa-angle-down'"
          ></i>
        </span>
      </div>
    </header><!-- /.card-header -->

    <div
      v-if="isExpanded"
      class="card-content"
    >
      <h6 class="title is-6">Site Details</h6>

      <table class="table is-bordered is-hoverable is-fullwidth is-narrow">
        <tr>
          <th>Name</th>
          <td>
            <input
              v-model.trim="name"
              :data-cy-test="`site-name-input-${site.name}`"
              type="text"
              class="input"
              placeholder="Example Site"
            >
          </td>
        </tr>
        <tr>
          <th>URL</th>
          <td>
            <input
              v-model.trim="url"
              :data-cy-test="`site-url-input-${site.url}`"
              type="text"
              class="input"
              placeholder="https://www.example.com"
            >
          </td>
        </tr>
        <tr v-if="site.isHIPAA">
          <th>HIPAA Site</th>
          <td :data-cy-test="`site-name-hipaa-yes-${site.name}`">Yes</td>
        </tr>
        <tr>
          <th>ID</th>
          <td>{{ site.siteId }}</td>
        </tr>
        <tr>
          <th>Last Updated</th>
          <td>{{ updatedAt }}</td>
        </tr>
      </table><!-- /.table -->

      <section class="bottom15">
        <b-field label="Event Destination">
          <b-select v-model="destination.type" :data-cy-test="`site-destination-type-${site.name}`" :disabled="isSiteHIPAA" expanded>
            <option value="make">Make Ingest</option>
            <option v-if="isSegmentEnabled" value="segment">Segment</option>
          </b-select>

          <p slot="message">Your event destination determines where server-side Preamp events are sent during the decision process.</p>
        </b-field>
      </section>

      <section v-if="destination.type === 'make'" :data-cy-test="`site-make-tenant-section-${site.name}`" class="bottom15">
        <b-field label="Make Source UUID or Legacy Tenant ID">
          <b-input
            id="siteTenantInput"
            v-model="tenantId"
            placeholder="Add Site Tenant ID"
          ></b-input>

          <p slot="message">
            Used when creating Smart Experience tests. If no key is defined, Key is obtained by the Cohesion team or found in <a href="https://app.make.rvapps.io">Make</a>.
          </p>
        </b-field>
      </section>

      <section v-if="destination.type === 'segment'" class="bottom15">
        <b-field label="Segment Write Key">
          <b-input
            v-model="destination.segment.writeKey"
            :data-cy-test="`site-destination-segment-writekey-${site.name}`"
            placeholder="Add Segment write key"
          ></b-input>

          <p slot="message">
            Write key from your Segment source for server-side eventing.
          </p>
        </b-field>
      </section>

      <section class="bottom15">
        <b-field
          label="Manage Domains Allowed To Preview"
          message="Both Experience (?exp=) and CDK (!preamp=) Previews are enabled only on the domains listed below. If no domains are specified, no preview restrictions are enforced."
        >
          <b-taginput
            v-model="whitelistedDomains"
            placeholder="*.*-example.com | An asterisk (*) can be used as a wildcard"
          ></b-taginput>
        </b-field>
      </section>

      <section class="bottom15">
        <b-field
          label="Manage Regions"
          message="If you need to use the decision servers for a specific region, add them above. (Press the arrow-down key for a list of all available regions)"
        >
          <b-taginput
            v-model="regions"
            placeholder="Add a Region"
            :data="availableRegions"
            autocomplete
          ></b-taginput>
        </b-field>
      </section>

      <section
        v-if="site.makeApiKey"
        class="bottom15"
      >
        <b-field label="MAKE API Key">
          <b-input
            v-model="makeApiKey"
            placeholder="Enter an API key"
          ></b-input>
        </b-field>
        <p class="help">
          <span class="has-text-warning"><b-icon
            icon="wrench"
            size="is-small"
          ></b-icon> In Development!</span> &middot; <a
            href="https://app.make.rvapps.io"
            target="_blank"
          >Make's</a> API-key support is currently limited as we work on re-integration. Any updates can be found in the <a href="slack://channel?team=T029AV3QX&id=C718VV878">#cohesion-support</a> Slack channel.
        </p>
      </section>

      <section v-if="site.makeDestinationUid" class="bottom15">
        <b-field
          label="Make Destination UID"
          message="Your destinationUid can be found within the Make admin under the profile destination settings tab"
        >
          <b-input
            v-model.trim="makeDestinationUid"
            placeholder="dst_XXXXXXXXXXXXXXXXXXXXXXX"
          ></b-input>
        </b-field>
      </section>

      <section class="bottom15">
        <b-field
          label="Monarch Source IDs"
          message="Whitelist Monarch sources to enable the Preamp integration in Monarch"
        >
          <b-taginput
            v-model="monarchSourceIds"
            placeholder="Add a Monarch source ID"
          ></b-taginput>
        </b-field>
      </section>

      <section v-if="!isSiteHIPAA" class="bottom15">
        <b-field
          label="Programmatic Access Client IDs"
          message="Allow programmatic access by pasting in an Auth0 client ID from Marvin - NOT the client secret"
        >
          <b-taginput
            v-model="programmaticAccessClientIds"
            placeholder="Add an Auth0 client ID"
          ></b-taginput>
        </b-field>
      </section>

      <section class="bottom15">
        <enrichment-client
          :enrichment-service-token="enrichmentServiceToken"
          :enrichment-service-client-id="enrichmentServiceClientId"
          :site-name="site.name"
          :site-id="site.siteId"
          @client-created="$emit('updatedSite')"
        />
      </section>

      <section class="bottom15">
        <b-field
          label="DAPI Client Token"
          message="Set a default DAPI client token for creating smart experiences tests"
        >
          <b-input v-model="dapiClientToken" placeholder="Add DAPI client token" />
        </b-field>
      </section>

      <hr>

      <div class="columns">
        <div class="column is-half">
          <b-field label="Hide Payload">
            <b-switch
              v-model="hidePayload"
              style="top: 5px; position:relative;"
              type="is-success"
            >
              {{ hidePayload ? 'Hidden' : '' }}
            </b-switch>
          </b-field>
          <p class="help">
            Hide the following fields in Decision response payloads:<br>
            <em>testId, testName, audienceId, audienceName, experienceId, experienceNumber</em>
          </p>
        </div>

        <div class="column is-half">
          <b-field grouped>
            <b-field :label="cli.enabled ? 'Manage CLI Syncing' : 'Enable CLI Syncing'">
              <b-switch
                v-model="cli.enabled"
                style="top: 5px; position:relative;"
                type="is-success"
                :true-value="1"
                :false-value="0"
              >
                {{ cli.enabled ? 'Enabled' : '' }}
              </b-switch>
            </b-field>
            <b-field
              v-if="cli.enabled"
              label="Marvin ClientId"
              expanded
            >
              <b-input
                v-model="cli.token"
                placeholder="Marvin ClientId"
              ></b-input>
            </b-field>
          </b-field>
          <p class="help">Enable the preamp-cli for automated syncing with CI/CD processes. This will disable the CDK from being able to sync.<br>See our <a href="https://redventures.atlassian.net/wiki/spaces/COHSN/pages/583663818/Preamp-Publisher+CLI" target="_blank">Installation Docs</a> for more information.</p>
        </div><!-- /.column.is-half -->
      </div><!-- /.columns -->

      <div v-if="!isSiteHIPAA" class="columns">
        <div class="column is-half">
          <b-field label="Manage Insights">
            <b-switch
              v-model="hasInsightsEnabled"
              type="is-success"
              :true-value="1"
              :false-value="0"
              :disabled="isInsightsAvailableForThisSite === false"
            >
              Insights are <strong>{{ hasInsightsEnabled ? 'Enabled' : 'Disabled' }}</strong>
            </b-switch>
          </b-field>
          <p class="help">
            Insights functionality is enabled by default for non-HIPAA sites that operate in Preamp's standard region. This setting provides an additional control mechanism to disable Insights data storage and access.
          </p>
        </div>

        <div class="column is-half"></div>
      </div><!-- /.columns -->

      <hr>

      <div class="columns">
        <div class="column is-half">
          <b-field label="Disable Site">
            <b-switch
              v-model="disabled"
              style="top: 5px; position:relative;"
              type="is-warning"
              :true-value="1"
              :false-value="0"
            >
              {{ disabled ? 'Disabled' : '' }}
            </b-switch>
          </b-field>
          <p class="help">
            A disabled site will stop running all tests across all audiences until the site is re-enabled. Use with caution.
          </p>
        </div>

        <div class="column is-half">
          <b-field label="Archive Site">
            <b-switch
              v-model="archived"
              type="is-danger"
            >
              {{ archived ? 'Archived' : '' }}
            </b-switch>
          </b-field>
          <p class="help">
            Preamp does not make decisions for archived sites and archived sites only appear in account settings.
          </p>
        </div>
      </div>
    </div><!-- /.card-content -->

    <div
      v-if="isExpanded"
      class="card-footer"
    >
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <base-button-save
              :data-cy-test="`site-update-site-button-${site.name}`"
              :loading="isSavingSite"
              :disabled="!isValid.valid || !readyToUpdate"
              @click="updateSite"
            />
          </div>

          <div class="level-item">
            <button
              class="button"
              :disabled="!readyToUpdate"
              @click.stop="reset"
            >Cancel</button>
          </div>

          <div class="level-item">
            <button
              class="button is-info"
              @click="resetSiteLock"
            >
              <span class="icon">
                <i class="fa fa-lock"></i>
              </span>
              <span>Reset Lock</span>
            </button>
          </div>

          <div
            v-if="!isValid.valid"
            class="level-item"
          >
            <p :data-cy-test="`site-validation-error-message-${site.name}`" class="has-text-danger">
              <span class="icon">
                <i class="fa fa-exclamation-circle"></i>
              </span>
              {{ isValid.errMsg }}
            </p>
          </div>
        </div><!-- /.level-left -->
      </div><!-- /.level -->
    </div>
  </div><!-- /.card -->
</template>

<script>
import { formatTime, hasLength, isValidUrl } from '@/modules/utilities';
import EnrichmentClient from '@/components/Accounts/EnrichmentClient';

export default {
  name: 'Site',
  components: { EnrichmentClient },
  props: {
    site: {
      type: Object,
      required: true
    },
    accountId: {
      type: String,
      required: true
    },
    siteNames: {
      type: Array,
      required: true
    },
    availableRegions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isExpanded: false,
      name: '',
      url: '',
      destination: {
        type: ''
      },
      whitelistedDomains: [],
      regions: [],
      hidePayload: false,
      archived: false,
      disabled: false,
      hasInsightsEnabled: true,
      makeApiKey: null,
      makeDestinationUid: null,
      segmentCipherKey: null,
      segmentSpaceId: null,
      segmentApiKey: null,
      tenantId: null,
      enrichmentServiceToken: null,
      enrichmentServiceClientId: null,
      cli: {
        enabled: 0,
        token: ''
      },
      monarchSourceIds: [],
      programmaticAccessClientIds: [],
      dapiClientToken: '',
      isSavingSite: false,
      siteTrafficFlow: null
    };
  },
  computed: {
    // Check if any changes have been made to the site
    readyToUpdate() {
      // Whitelist
      const editWhiteList = [...this.whitelistedDomains].sort();
      const prevWhiteList = this.site.whitelistedDomains ? [...this.site.whitelistedDomains] : [];
      const changedWhiteList = JSON.stringify(editWhiteList) !== JSON.stringify(prevWhiteList.sort());

      // Regions
      const editRegions = [...this.regions].sort(function (a, b) {
        return a.label > b.label ? 1 : -1;
      });
      const prevRegions = this.site.regions ? [...this.site.regions] : [];
      prevRegions.sort(function (a, b) {
        return a.label > b.label ? 1 : -1;
      });
      const changedRegions = JSON.stringify(editRegions) !== JSON.stringify(prevRegions);

      // Name
      const changedName = this.name !== this.site.name;

      // URL
      const changedUrl = this.url !== this.site.url;

      // Disabled
      const changedDisabled = this.disabled !== this.site.disabled;

      // Payload
      const changedPayload = this.hidePayload !== Boolean(this.site.hidePayload);

      // Archived
      const changedArchived = this.archived !== Boolean(this.site.archived);

      // hasInsightsEnabled
      const changedInsightsEnabled = this.hasInsightsEnabled !== this.site.hasInsightsEnabled;

      // CLI Enabled
      const changedCLIEnabled = (this.site.cli)
        ? this.cli.enabled !== this.site.cli.enabled
        : this.cli.enabled !== 0;

      // CLI Token Updated
      const changedCLIToken = (this.site.cli)
        ? this.cli.token !== '' && this.cli.token !== this.site.cli.token
        : false;

      // Did the Make API key change
      const changedMakeApiKey = (this.site.makeApiKey)
        ? this.makeApiKey !== this.site.makeApiKey
        : this.makeApiKey !== '';

      const changedMakeDestinationUid = (this.site.makeDestinationUid)
        ? this.makeDestinationUid !== this.site.makeDestinationUid
        : this.makeDestinationUid !== '';

        // Did the Segment Space ID change
      const changedSegmentSpaceID = (this.site.segmentSpaceId)
        ? this.segmentSpaceId !== this.site.segmentSpaceId
        : this.segmentSpaceId !== '';

        // Did the Segment API key change
      const changedSegmentApiKey = (this.site.segmentApiKey)
        ? this.segmentApiKey !== this.site.segmentApiKey
        : this.segmentApiKey !== '';

      // Check if tenantId has changed
      const changedTenantId = (this.site.tenantId)
        ? this.tenantId !== this.site.tenantId
        : this.tenantId !== null;

      // Check if enrichmentServiceToken has changed
      const changedEnrichmentServiceToken = (this.site.enrichmentServiceToken)
        ? this.enrichmentServiceToken !== this.site.enrichmentServiceToken
        : this.enrichmentServiceToken !== '';

      // Check if the DAPI client token has changed
      const changedDapiClientToken = (this.site.dapiClientToken)
        ? this.dapiClientToken !== this.site.dapiClientToken
        : this.dapiClientToken !== '';

      // Did the monarchSourceIds change
      const changedMonarchSourceIds = (this.site.monarchSourceIds)
        ? !this.monarchSourceIds.every(id => this.site.monarchSourceIds.includes(id))
          || !this.site.monarchSourceIds.every(id => this.monarchSourceIds.includes(id))
        : this.monarchSourceIds.length > 0;

      // Did the programmatic access client IDs change
      const changedProgrammaticAccessClientIds = (this.site.programmaticAccessClientIds != null)
        ? !this.programmaticAccessClientIds.every(id => this.site.programmaticAccessClientIds.includes(id))
          || !this.site.programmaticAccessClientIds.every(id => this.programmaticAccessClientIds.includes(id))
        : this.programmaticAccessClientIds.length > 0;

      // Did destination configuration change
      const changedDestinationData = (() => {
        if (!this.site.destination) return true;
        if (this.site.destination.type !== this.destination.type) return true;
        if (this.site.destination.type === 'make') return false;
        if (this.site.destination.type === 'segment') {
          return this.site.destination.segment.writeKey !== this.destination.segment.writeKey;
        }
        return false;
      })();

      const readyToUpdate = changedWhiteList
        || changedName
        || changedUrl
        || changedRegions
        || changedArchived
        || changedPayload
        || changedCLIEnabled
        || changedCLIToken
        || changedMakeApiKey
        || changedMakeDestinationUid
        || changedSegmentSpaceID
        || changedSegmentApiKey
        || changedTenantId
        || changedEnrichmentServiceToken
        || changedDapiClientToken
        || changedMonarchSourceIds
        || changedProgrammaticAccessClientIds
        || changedDestinationData
        || changedDisabled
        || changedInsightsEnabled;

      return readyToUpdate;
    },
    // Return time stamp format
    updatedAt() {
      return (this.site.updatedAt) ? formatTime(this.site.updatedAt) : 'N/A';
    },
    hasMakeDNAAudiences() {
      return this.siteTrafficFlow?.audiences.some((audience) => {
        return audience.rules.some((ruleBlock) => {
          return ruleBlock.some((rule) => {
            return rule.path.startsWith('dna.') || rule.path.startsWith('dnaxs.');
          });
        });
      }) ?? false;
    },
    // Validate the site data
    isValid() {
      let errMsg;
      let valid = false;

      switch (true) {
        case this.name.trim().length === 0:
          errMsg = 'Please enter a valid site name.';
          break;
        case this.siteNames.includes(this.name.toLowerCase()) && this.name !== this.site.name:
          errMsg = 'This site name is already in use.';
          break;
        case this.url && !isValidUrl(this.url):
          errMsg = 'Please enter a valid site URL.';
          break;
        case this.cli.enabled && this.cli.token === '':
          errMsg = 'Please enter your Marvin ClientId.';
          break;
        case !this.monarchSourceIds.every(id => /^[a-z0-9]{8}[-](?:[a-z0-9]{4}[-]){3}[a-z0-9]{12}$/.test(id)):
          errMsg = 'There is an invalid Monarch source ID.';
          break;
        case this.destination.type !== 'make' && this.hasMakeDNAAudiences:
          errMsg = 'Site cannot change event destination from Make until all dna rules are removed from audiences.';
          break;
        case this.destination.type === 'segment' && this.destination.segment.writeKey === '':
          errMsg = 'Please enter a valid Segment write key.';
          break;
        default:
          valid = true;
      }

      return {
        errMsg,
        valid
      };
    },
    isSuper() {
      return this.$store.getters.isSuperUser;
    },
    isSiteHIPAA() {
      return !!this.site.isHIPAA;
    },
    isSegmentEnabled() {
      return !this.site.isHIPAA;
    },
    // differs from isInsightsAvailable which uses the currently selected site
    isInsightsAvailableForThisSite() {
      if (
        !Array.isArray(this.site.regions)
        || this.site.regions.length === 0
        || this.site.regions.includes('US')
      ) {
        return true;
      }

      return false;
    }
  },
  watch: {
    // Lazy load dependencies when expanded, reset when closed
    isExpanded(val) {
      if (val) return this.getDependencies();
      this.reset();
    },
    'destination.type': function (newVal, oldVal) {
      if (newVal === oldVal || oldVal === '') return;
      if (newVal === 'make') {
        this.destination = { type: 'make' };
      } else if (newVal === 'segment') {
        this.destination = { type: 'segment', segment: { writeKey: '' } };
      }
    }
  },
  created() {
    // Set initial site config
    this.reset();
  },
  methods: {
    async getDependencies() {
      await this.getSiteTrafficFlow();
    },
    async getSiteTrafficFlow() {
      try {
        const res = await this.$axios.get(`/sites/${this.site.siteId}/traffic-flows`);
        this.siteTrafficFlow = res.data[0];
      } catch (err) {
        err.title = 'There was a problem getting the traffic flow for the expanded site.';
        this.$store.commit('error', err);
      }
    },
    // Show/hide site information
    toggleAccount() {
      this.isExpanded = !this.isExpanded;
    },
    // Update data for selected site
    async updateSite() {
      this.isSavingSite = true;

      // Make a copy of the site data and replace with new whitelist
      const payload = { ...this.site };
      payload.name = this.name;
      payload.url = this.url;
      payload.destination = this.destination;
      payload.whitelistedDomains = hasLength(this.whitelistedDomains) ? this.whitelistedDomains : null;
      payload.regions = hasLength(this.regions) ? this.regions : null;
      payload.archived = (this.archived) ? 1 : 0;
      payload.hidePayload = (this.hidePayload) ? 1 : 0;
      payload.disabled = this.disabled;
      payload.hasInsightsEnabled = this.hasInsightsEnabled;

      if (this.makeApiKey === '') {
        payload.makeApiKey = null;
        this.makeApiKey = null;
      } else {
        payload.makeApiKey = this.makeApiKey;
      }

      if (this.makeDestinationUid === '') {
        payload.makeDestinationUid = null;
        this.makeDestinationUid = null;
      } else {
        payload.makeDestinationUid = this.makeDestinationUid;
      }

      if (this.segmentSpaceId === '') {
        payload.segmentSpaceId = null;
        this.segmentSpaceId = null;
      } else {
        payload.segmentSpaceId = this.segmentSpaceId;
      }

      if (this.segmentApiKey === '') {
        payload.segmentApiKey = null;
        this.segmentApiKey = null;
      } else {
        payload.segmentApiKey = this.segmentApiKey;
      }

      payload.tenantId = this.tenantId;

      if (this.enrichmentServiceToken === '') {
        payload.enrichmentServiceToken = null;
        this.enrichmentServiceToken = null;
      } else {
        payload.enrichmentServiceToken = this.enrichmentServiceToken;
      }

      if (this.dapiClientToken === '') {
        payload.dapiClientToken = null;
      } else {
        payload.dapiClientToken = this.dapiClientToken;
      }

      delete payload.createdAt;
      delete payload.updatedAt;

      if (this.cli.enabled === 1 || this.site.cli !== undefined) {
        const { token } = this.cli;

        payload.cli = {
          enabled: this.cli.enabled,
          token: this.cli.enabled ? token : ''
        };
      }

      if (this.monarchSourceIds.length === 0) {
        payload.monarchSourceIds = null;
      } else {
        payload.monarchSourceIds = this.monarchSourceIds;
      }

      payload.programmaticAccessClientIds = this.programmaticAccessClientIds;

      try {
        const url = `/sites/${this.site.siteId}`;
        const res = await this.$axios.put(url, payload);

        this.$buefy.toast.open({
          message: `Successfully updated ${res.data.name}`,
          type: 'is-success'
        });

        this.$emit('updatedSite');
      } catch (err) {
        err.title = `Could not update ${this.site.name}`;
        this.$store.commit('error', err);
      }

      this.isSavingSite = false;
    },
    // Reset the site lock for the site
    async resetSiteLock() {
      const { siteId } = this.site;
      this.$root.releaseSiteLock(siteId, 'userAction', this.site.name);
    },
    // reset site details
    reset() {
      this.whitelistedDomains = [];
      this.regions = [];

      // Init destination with default of make
      this.destination.type = this.site.destination?.type ?? 'make';
      if (this.destination.type === 'segment') {
        this.$set(this.destination, 'segment', {
          writeKey: this.site.destination.segment.writeKey
        });
      }

      if (this.site.whitelistedDomains) {
        this.whitelistedDomains = [...this.site.whitelistedDomains];
      }

      if (this.site.regions) {
        this.regions = [...this.site.regions];
      }

      this.name = this.site.name;
      this.url = this.site.url;
      this.disabled = this.site.disabled;
      this.archived = this.site.archived === 1;
      this.hidePayload = this.site.hidePayload === 1;

      if (this.site.cli) {
        this.cli = { ...this.site.cli };
      }

      this.makeApiKey = this.site.makeApiKey ? this.site.makeApiKey : '';
      this.makeDestinationUid = this.site.makeDestinationUid ? this.site.makeDestinationUid : '';

      this.segmentSpaceId = this.site.segmentSpaceId ? this.site.segmentSpaceId : '';
      this.segmentApiKey = this.site.segmentApiKey ? this.site.segmentApiKey : '';

      this.enrichmentServiceToken = this.site.enrichmentServiceToken ? this.site.enrichmentServiceToken : '';
      this.enrichmentServiceClientId = this.site.enrichmentServiceClientId ? this.site.enrichmentServiceClientId : '';

      this.dapiClientToken = this.site.dapiClientToken ?? '';

      if (this.site.tenantId) {
        this.tenantId = this.site.tenantId;
      }

      this.monarchSourceIds = this.site.monarchSourceIds ?? [];
      this.programmaticAccessClientIds = this.site.programmaticAccessClientIds ?? [];

      this.hasInsightsEnabled = (this.site.hasInsightsEnabled === 0 || this.site.isHIPAA) ? 0 : 1;
    }
  }
};
</script>
