export const JWT_KEY = 'pajwt';
export const LS_KEY = 'pa_storage';

export default {
  JWT_KEY,
  LS_KEY,
  LOCAL: {
    PREAMP_LIMITED_ACCESS_TOKEN: 'test',
    LOGIN_URL: 'redventures-dev.auth0.com',
    LOGIN_CLIENT_ID: 'LaGQzfAxJIBuVTFl7VPtEiKYPBIZgj6G',
    LOGIN_AUDIENCE: 'development-preamp-admin-api-resource',
    MONARCH_URL: 'https://admin.development.monarch.cohesionapps.com/'
  },
  DEV: {
    PREAMP_LIMITED_ACCESS_TOKEN: '0h3SaoU8bxpgP7qdAnOx4x',
    LOGIN_URL: 'redventures-dev.auth0.com',
    LOGIN_CLIENT_ID: 'LaGQzfAxJIBuVTFl7VPtEiKYPBIZgj6G',
    LOGIN_AUDIENCE: 'development-preamp-admin-api-resource',
    MONARCH_URL: 'https://admin.development.monarch.cohesionapps.com/'
  },
  STAGING: {
    PREAMP_LIMITED_ACCESS_TOKEN: '0h3SaoU8bxpgP7qdAnOx4x',
    LOGIN_URL: 'redventures-dev.auth0.com',
    LOGIN_CLIENT_ID: 'oShwOvmHOq8gVsiz5tNT3rDLOrqgtI2W',
    LOGIN_AUDIENCE: 'staging-preamp-admin-api-resource',
    MONARCH_URL: 'https://admin.staging.monarch.cohesionapps.com/'
  },
  PROD: {
    PREAMP_LIMITED_ACCESS_TOKEN: '4e63b2d1-21a3-4fc2-bba5-056275a6b6c2',
    LOGIN_URL: 'redventures-prod.auth0.com',
    LOGIN_CLIENT_ID: 'L8OTR0QB198UgS1Z3A6D5VT1XiAK6bLz',
    LOGIN_AUDIENCE: 'production-preamp-admin-api-resource',
    MONARCH_URL: 'https://admin.monarch.cohesionapps.com/'
  },
  HEALTH_NONPROD: {
    PREAMP_LIMITED_ACCESS_TOKEN: 'L35joFg7Yj9VgC5h4W4mKn',
    LOGIN_URL: 'redventures-dev.auth0.com',
    LOGIN_CLIENT_ID: 'bDr4O8S8pppxZMl6x8eRn8EEPGJ67bO8',
    LOGIN_AUDIENCE: 'health-nonprod-preamp-admin-api-resource',
    MONARCH_URL: 'https://admin.monarch-nonprod.rvohealth.com/'
  },
  HEALTH_PROD: {
    PREAMP_LIMITED_ACCESS_TOKEN: 'p90GKa5l3tEOjH0xX7p2Zh',
    LOGIN_URL: 'redventures-prod.auth0.com',
    LOGIN_CLIENT_ID: '0ug5MoH3r1rMXWXIgIl1vCICozVO1Y8t',
    LOGIN_AUDIENCE: 'health-prod-preamp-admin-api-resource',
    MONARCH_URL: 'https://admin.monarch.rvohealth.com/'
  }
};
