<template functional>
  <base-button-default
    type="info"
    icon="pencil"
    text="Edit"
    :disabled="props.disabled || !parent.$store.getters.userCanEdit"
    @click="listeners.click"
  />
</template>

<script>
export default {
  name: 'BaseButtonEdit',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>
