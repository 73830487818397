<template>
  <div>
    <div class="main-view-header">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h4 data-cy-test="page-title" class="title is-4">Assets</h4>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-field>
              <p class="control">
                <b-dropdown>
                  <button
                    slot="trigger"
                    class="button"
                    data-cy-test="assets-filters-button"
                  >
                    <span>{{ filterBy ? filterBy.name : 'Filters' }}</span>
                    <span class="icon"><i class="fa fa-caret-down"></i></span>
                  </button>

                  <b-dropdown-item
                    v-for="(option, optIndex) in filterOptions"
                    :key="optIndex"
                    data-cy-test="filter-option"
                    @click="filterBy = option"
                  >{{ option.name }}</b-dropdown-item>
                </b-dropdown>
              </p>
              <b-input
                v-model="filterQuery"
                type="search"
                placeholder="Search..."
                data-cy-test="assets-search-input"
              ></b-input>
            </b-field>
          </div>
          <div class="level-item">
            <b-button
              type="is-info"
              :disabled="moveSelectedButtonDisabled"
              data-cy-test="move-selected-button"
              @click="handleMoveAssets(selectedIds)"
            >
              Move Selected
            </b-button>
          </div>
          <div class="level-item">
            <b-button
              icon-left="plus"
              type="is-info"
              :disabled="newFolderButtonDisabled"
              data-cy-test="new-folder-button"
              @click="handleCreateNewFolderClick"
            >
              New Folder
            </b-button>
          </div>
          <div class="level-item">
            <b-button
              icon-left="download"
              type="is-info"
              :disabled="selectedIds.length === 0 || isDownloadingCsv"
              :loading="isDownloadingCsv"
              data-cy-test="download-csv-button"
              @click="handleCsvDownload"
            >
              Download CSV
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div class="main-view-sub-header">
      <div class="tabs">
        <ul>
          <li
            v-for="(tab, index) in ['Live', 'Archived']"
            :id="`tab-assets-item-${tab.toLowerCase()}`"
            :key="index"
            :class="{ 'is-active': tabSelection === tab }"
            :data-cy-test="`page-tab-${tab}`"
            @click="(evt) => handleTabSelection(evt, tab)"
          >
            <a :title="getTabTip(tab)">{{ tab }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="main-view-content has-pagination-footer">
      <Folders
        v-if="!filterQuery && tabSelection === 'Live'"
        @clickFolder="handleClickFolder"
        @foldersLoaded="handleFoldersLoaded"
        @folderCreated="handleSetMoveSelectedButtonEnabled"
        @entitiesMoved="handleResetSelectedIds"
      />

      <div class="card assets-table__wrapper" data-cy-test="assets-table">
        <div
          v-if="!filterQuery && tabSelection === 'Live'"
          class="assets-table__header level ml-4"
        >
          <div class="level-left">
            <div class="level-item">
              <i class="fa fa-folder-open assets-table__header--icon"></i>
              <h3 data-cy-test="assets-table-header-folderName" class="assets-table__header--text">{{ openFolder.name }}</h3>
            </div>
          </div>
        </div>
        <div
          v-else-if="!filterQuery && tabSelection === 'Archived'"
          class="assets-table__header level ml-4"
        >
          <div class="level-left">
            <div class="level-item">
              <i class="fa fa-archive assets-table__header--icon"></i>
              <h3 data-cy-test="assets-table-header-archived" class="assets-table__header--text">Archived</h3>
            </div>
          </div>
        </div>
        <div
          v-if="filterQuery"
          class="assets-table__header level ml-4"
        >
          <div class="level-left">
            <div class="level-item">
              <i class="fa fa-search assets-table__header--icon"></i>
              <h3 data-cy-test="assets-table-header-searchResults" class="assets-table__header--text">Search Results</h3>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item mr-4">
              <button class="button is-light" data-cy-test="clear-search-button" @click.stop="handleClearSearch">
                Clear Search
              </button>
            </div>
          </div>
        </div>
        <b-table
          ref="assets-table"
          class="assets-table"
          :data="filtered"
          :loading="isLoading"
          paginated
          pagination-simple
          :current-page.sync="currentPage"
          :per-page="pageSize"
          pagination-size="is-hidden"
          sort-icon="caret-up"
          hoverable
          detailed
          detail-key="assetId"
          custom-detail-row
          :show-detail-icon="false"
          sticky-header
        >
          <b-table-column
            width="50"
            :th-attrs="() => {return {'data-cy-test': 'table-column-checkbox'}}"
          >
            <template v-slot:header>
              <b-checkbox
                v-if="filtered.length > 0"
                v-model="masterCheckboxModel"
                type="is-info"
                :indeterminate="isMasterCheckboxIndeterminate"
                class="assets-table__master-checkbox"
                data-cy-test="master-checkbox"
              />
            </template>
            <template v-slot="props">
              <b-checkbox
                :value="selectedIds.includes(props.row.assetId)"
                type="is-info"
                class="assets-table__row-checkbox"
                :data-cy-test="`row-checkbox-${props.row.assetId}`"
                @input="handleAssetChecked(props.row.assetId)"
              />
            </template>
          </b-table-column>
          <b-table-column
            v-slot="props"
            sortable
            field="name"
            label="Asset Name"
            :th-attrs="() => {return {'data-cy-test': 'table-column-assetName'}}"
          >
            <router-link :to="`/assets/${props.row.assetId}`" :data-cy-test="`asset-link-${props.row.assetId}`">
              {{ props.row.name }}
            </router-link>
            <span v-if="props.row.duplicatedAssets && props.row.duplicatedAssets.length > 0" :data-cy-test="`duplicate-asset-count-${props.row.assetId}`" class="duplicate-asset-count">({{ props.row.duplicatedAssets.length }})</span>
            <button
              v-if="props.row.duplicatedAssets && props.row.duplicatedAssets.length > 0"
              class="invisible-button"
              @click="toggleRowDetails(props.row)"
            >
              <b-icon
                :icon="expandedRows[props.row.assetId] ? 'caret-down' : 'caret-up'"
                type="is-info"
                size="is-medium"
                :data-cy-test="`row-expand-button-${props.row.assetId}`"
              />
            </button>
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="assetId"
            label="Asset ID"
            :th-attrs="() => {return {'data-cy-test': 'table-column-assetId'}}"
          >
            {{ props.row.assetId }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :visible="!isFoldersViewActive"
            field="folders"
            label="Folder"
            :th-attrs="() => {return {'data-cy-test': 'table-column-folderName'}}"
          >
            {{ assignAssetFolderName(props.row) }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="status"
            label="Status"
            :th-attrs="() => {return {'data-cy-test': 'table-column-status'}}"
          >
            <b-tag v-if="props.row.archived === 1" class="asset-status-tag is-warning">
              Archived
            </b-tag>
            <b-tag v-else-if="isAssetActive(props.row)" class="asset-status-tag">
              Active
            </b-tag>
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="type"
            label="Type"
            :th-attrs="() => {return {'data-cy-test': 'table-column-editable'}}"
          >
            <b-tag v-if="isAssetEditable(props.row)">
              Editable Asset
              <span v-if="props.row.assetType === 'PSEUDO'" class="icon">
                <i
                  class="fa"
                  :class="`fa-link`"
                  style="color:grey"
                ></i>
              </span>
            </b-tag>
          </b-table-column>
          <b-table-column
            v-slot="props"
            :visible="tabSelection === 'Archived'"
            field="archivedBy"
            label="Archived By"
            :th-attrs="() => {return {'data-cy-test': 'table-column-archivedBy'}}"
          >
            {{ props.row.archivedBy }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :visible="tabSelection === 'Live'"
            field="createdAt"
            label="Last Edited"
            sortable
            :th-attrs="() => {return {'data-cy-test': 'table-column-lastEdited'}}"
          >
            {{ props.row.createdAt | filterArchiveDateTime }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :visible="tabSelection === 'Archived'"
            field="archivedAt"
            label="Archived At"
            sortable
            :th-attrs="() => {return {'data-cy-test': 'table-column-archivedAt'}}"
          >
            {{ props.row.archivedAt | filterArchiveDateTime }}
          </b-table-column>

          <template #detail="props">
            <template v-if="props.row.duplicatedAssets">
              <tr
                v-for="duplicate of props.row.duplicatedAssets"
                :key="duplicate.assetId"
                class="duplicate-asset-row"
                :data-cy-test="`duplicate-asset-${duplicate.assetId}`"
              >
                <td></td>
                <td class="duplicate-asset-row__name">
                  <router-link :to="`/assets/${duplicate.assetId}`" :data-cy-test="`duplicate-asset-link-${duplicate.assetId}`">
                    {{ duplicate.name }}
                  </router-link>
                </td>
                <td>{{ duplicate.assetId }}</td>
                <td v-if="!isFoldersViewActive">{{ assignAssetFolderName(duplicate) }}</td>
                <td>
                  <b-tag v-if="duplicate.archived === 1" class="asset-status-tag is-warning">Archived</b-tag>
                  <b-tag v-if="isAssetActive(duplicate)" class="asset-status-tag">Active</b-tag></td>
                <td><b-tag>Variant</b-tag></td>
                <td v-show="tabSelection === 'Live'">{{ duplicate.createdAt | filterArchiveDateTime }}</td>
                <td v-show="tabSelection === 'Archived'">{{ duplicate.archivedBy }}</td>
                <td v-show="tabSelection === 'Archived'">{{ duplicate.archivedAt | filterArchiveDateTime }}</td>
              </tr>
            </template>
          </template>

          <template
            v-if="!isLoading"
            slot="empty"
          >
            <article v-if="!siteId" class="message is-warning" data-cy-test="table-message-noSite">
              <div class="message-body">
                Select a site from the dropdown to view any available assets.
              </div>
            </article>
            <article v-else-if="allAssets.length < 1" class="message is-warning" data-cy-test="table-message-noAssets">
              <div class="message-body">
                No {{ tabSelection.toLowerCase() }} assets were found for this site. Assets must be created through the CDK.
              </div>
            </article>
            <article v-else-if="folderContents.length < 1" class="message is-warning" data-cy-test="table-message-emptyFolder">
              <div class="message-body">
                No assets are associated with this folder. You can add assets by selecting and moving them to this folder.
              </div>
            </article>
          </template>
        </b-table>
        <div class="table-pagination" data-cy-test="assets-table-pagination">
          <div class="level-left">
            <div class="level-item">
              <button
                class="button"
                :disabled="currentPage === 1"
                data-cy-test="pagination-previous-button"
                @click.stop="getPage(-1)"
              >Previous</button>
            </div>
            <div class="level-item">
              <button
                class="button"
                data-cy-test="pagination-next-button"
                :disabled="(pageSize * currentPage) >= filtered.length"
                @click.stop="getPage(1)"
              >Next</button>
            </div>
          </div>
          <div class="level-right">
            <div
              v-if="filtered.length"
              class="level-item"
              data-cy-test="pagination-range"
            >
              <strong>{{ assetRangeText }} of {{ filtered.length }} Assets</strong>
            </div>
          </div>
        </div>
      </div>
    </div><!-- /.main-view-content -->

    <MoveSelectedModal
      v-if="isMoveSelectedModalActive"
      v-model="isMoveSelectedModalActive"
      :folders="allFolders"
      :selectedContents="selectedIds"
      class="folder-modal"
      @moveSelected="handleMoveSelectedAssets"
    />

    <b-loading
      :active.sync="isLoading"
      :is-full-page="false"
      data-cy-test="page-loader"
    ></b-loading>

  </div>
</template>

<script>
import { sync } from 'vuex-pathify';
import { hasLength, downloadCsv } from '@/modules/utilities';
import { bus } from '@/main';

import Folders from '@/components/Folders/Folders';
import MoveSelectedModal from '@/components/Folders/MoveSelectedModal';

export default {
  name: 'Assets',
  components: {
    Folders,
    MoveSelectedModal
  },
  data() {
    return {
      tabSelection: 'Live',
      filterQuery: '',
      filterBy: { name: 'Asset Name', val: 'name' },
      filterOptions: [
        { name: 'Asset ID', val: 'assetId' },
        { name: 'Asset Name', val: 'name' },
        { name: 'Tag', val: 'tags' }
      ],
      currentPage: 1,
      isLoading: false,
      isDownloadingCsv: false,
      selectedIds: [],
      csvAttributes: [
        'siteId',
        'assetId',
        'assetType',
        'duplicatedFrom',
        'archived',
        'archivedAt',
        'archivedBy',
        'createdAt',
        'name',
        'js',
        'tags',
        'html',
        'css'
      ],
      expandedRows: {},
      moveSelectedButtonEnabled: false,
      isMoveSelectedModalActive: false
    };
  },
  computed: {
    allAssets: sync('assets/list'),
    folderContents: sync('folders/folderContents'),
    openFolder: sync('folders/openFolder'),
    allFolders() {
      return this.$store.getters['folders/allFolders'];
    },
    accountId() {
      return this.$store.state.activeAccount;
    },
    siteId() {
      return this.$store.state.siteId;
    },
    filtered() {
      if (this.filterQuery || this.tabSelection === 'Archived') {
        return this.filterAssets(this.allAssets);
      } else {
        return this.filterAssets(this.folderContents);
      }
    },
    pageSize() {
      return this.tabSelection === 'Archived' ? 20 : 10;
    },
    masterCheckboxModel: {
      get() {
        let count = 0;
        for (const asset of this.filtered) {
          count += 1;
          if (asset.duplicatedAssets && asset.duplicatedAssets.length > 0) {
            count += asset.duplicatedAssets.length;
          }
        }
        return this.selectedIds.length === count;
      },
      set(val) {
        if (val) {
          const idsToSelect = [];
          for (const asset of this.filtered) {
            idsToSelect.push(asset.assetId);
            if (asset.duplicatedAssets && asset.duplicatedAssets.length > 0) {
              idsToSelect.push(...asset.duplicatedAssets.map(a => a.assetId));
            }
          }
          this.selectedIds = [...idsToSelect];
        } else {
          this.selectedIds = [];
        }
      }
    },
    isMasterCheckboxIndeterminate() {
      return this.selectedIds.length > 0 && !this.masterCheckboxModel;
    },
    assetRangeText() {
      return `${(this.pageSize * this.currentPage) - (this.pageSize - 1)} / `
      + `${this.pageSize * this.currentPage > this.filtered.length ? this.filtered.length : this.pageSize * this.currentPage}`;
    },
    moveSelectedButtonDisabled() {
      return !this.moveSelectedButtonEnabled || (this.selectedIds.length === 0 || !this.siteId || this.tabSelection === 'Archived');
    },
    newFolderButtonDisabled() {
      return !this.siteId || this.tabSelection !== 'Live' || !!this.filterQuery;
    },
    isFoldersViewActive() {
      return !this.filterQuery && this.tabSelection === 'Live';
    }
  },
   watch: {
    async filterQuery(newVal) {
      if(!newVal) {
        await this.handleClickFolder({folderId: 'root'});
      }
    }
  },
  created() {
    this.handlePageCreated();

    bus.$on('account-changed', this.handleAccountSiteChanges);
    bus.$on('site-changed', this.handleAccountSiteChanges);
  },
  beforeDestroy() {
    bus.$off('account-changed', this.handleAccountSiteChanges);
    bus.$off('site-changed', this.handleAccountSiteChanges);
  },
  methods: {
    async handlePageCreated() {
      this.isLoading = true;

      if (this.accountId && this.siteId) {
        await this.$store.dispatch('folders/getFolderContents', 'root');
        //get all assets for search, but don't wait for them
        this.$store.dispatch('assets/getList');
      } else {
        this.allAssets = [];
      }

      this.isLoading = false;
    },
    async handleAccountSiteChanges() {
      this.isLoading = true;
      this.folderContents = [];
      this.allAssets = [];
      this.selectedIds = [];
      this.filterQuery = '';

      if (this.accountId && this.siteId) {
        await this.$store.dispatch('folders/getFolderContents', 'root');
        this.$store.dispatch('assets/getList');
      }

      this.isLoading = false;
    },
    filterAssets(assets) {
      // Add list of duplicated assets to template assets
      const duplicatedAssets = [];
      // Clear any previous duplicated assets list
      assets.forEach((a) => {
        if (a.duplicatedAssets) {
          delete a.duplicatedAssets;
        }
      });
      for (const asset of assets) {
        // if this is a parent asset we can skip
        if (asset.path === '/') continue;

        // catch guard: at this point we have a child asset
        // and a child asset should always
        // have a duplicated from, but just in case
        if (!asset.duplicatedFrom) {
          continue;
        }

        duplicatedAssets.push(asset);
        // using the parent id, find the parent asset
        // and add this asset to its duplicatedAssets array
        const assetTemplate = assets.find(a => a.assetId === asset.duplicatedFrom);
        if (assetTemplate) {
          if (!Object.prototype.hasOwnProperty.call(assetTemplate, 'duplicatedAssets')) {
            assetTemplate.duplicatedAssets = [];
          }
          assetTemplate.duplicatedAssets.push(asset);
        } else {
          console.error('Failed to find base asset', { baseAssetId: asset.duplicatedFrom, variantAssetId: asset.assetId });
        }
      }

      let filtered = [];
      // Handle Tab Filtering
      const includeArchived = (this.tabSelection === 'Archived') ? 1 : 0;

      filtered = assets.filter((ast) => {
        // if a child asset we don't want to include
        // because all the child assets have already
        // been accounted for in their parent's duplicatedAssets array
        if(ast.path !== '/') {
          return false;
        }

        // if asset should be included in tab, remove unmatched children and return asset
        if (ast.archived === includeArchived) {
          if (ast.duplicatedAssets && ast.duplicatedAssets.length) {
            ast.duplicatedAssets = ast.duplicatedAssets.filter(a => a.archived === includeArchived);
          }
          return true;
        }

        // if any children match selected tab, return the asset with matched children attached
        if (ast.duplicatedAssets && ast.duplicatedAssets.length) {
          ast.duplicatedAssets = ast.duplicatedAssets.filter(a => a.archived === includeArchived);
          if (ast.duplicatedAssets.length) {
            return true;
          }
        }

        return false;
      });

      // Filter by query
      if (this.filterBy && hasLength(this.filterQuery)) {
        const query = this.filterQuery.toLowerCase();
        // Function to evaluate if an asset should be shown based on the filter condition
        const shouldShowAsset = (asset) => {
          if (asset[this.filterBy.val] === undefined) return false;
          let result = true;
          switch (this.filterBy.name) {
            case 'Tag':
              result = asset[this.filterBy.val].find(tag => tag.includes(query));
              break;
            default:
              result = asset[this.filterBy.val].toLowerCase().indexOf(query) !== -1;
              break;
          }
          return result;
        };
        // Filter duplicates first
        filtered.forEach((asset) => {
          if (!asset.duplicatedAssets) return;
          asset.duplicatedAssets = asset.duplicatedAssets.filter(shouldShowAsset);
        });

        // Filter main assets
        filtered = filtered.filter((asset) => {
          // If the asset is a template and has duplicates returned by the filter, show the asset
          if (asset.duplicatedAssets && asset.duplicatedAssets.length > 0) {
            if (!Object.prototype.hasOwnProperty.call(this.expandedRows, asset.assetId)) {
              this.toggleRowDetails(asset);
            }
            return true;
          }
          return shouldShowAsset(asset);
        });
      }

      return filtered;
    },
    assignAssetFolderName(asset) {
      return (asset.archived === 1) ? '' : this.getAssetFolderName(asset.assetId);
    },
    getPage(int) {
      this.currentPage += int;
    },
    /**
     * Emit element click event
     * @param {Object} e - element click event
     */
    elementClick(e) {
      bus.$emit('elem-click', e);
    },
    /**
     * Get tooltip message when hovering over the tab options
     * @param {String} tab - 'Live, Archived
     */
    getTabTip(tab) {
      switch (tab) {
        case 'Live':
          return 'Assets that are eligible to be used in an experience.';
        case 'Archived':
          return 'Assets that have been archived and are not eligible to be in an experience unless unarchived.';
        default:
          return '';
      }
    },
    async handleTabSelection(clickEvent, tab) {
      this.selectedIds = [];
      this.elementClick(clickEvent);

      if (this.tabSelection === tab) {
       return;
      }

      this.tabSelection = tab;
    },
    handleAssetChecked(id) {
      if (this.selectedIds.includes(id)) {
        this.selectedIds = this.selectedIds.filter(selectedId => selectedId !== id);
      } else {
        this.selectedIds.push(id);
      }
    },
    async handleCsvDownload() {
      if (this.selectedIds.length === 0) {
        return;
      }
      this.isDownloadingCsv = true;

      const res = await this.$axios.get(`/sites/${this.siteId}/assets`, {
        params: {
          attributes: this.csvAttributes
        }
      });
      const assetsToDownload = this.addAssetDuplicatesToList(this.selectedIds);

      const csvItems = res.data.filter(asset =>
        assetsToDownload.includes(asset.assetId));

      const filename =
        this.$store.state.activeAccountSites.find(s => s.siteId === this.siteId).name.replace(/\./g, '-')
        + '_assets_'
        + new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-')
        + '.csv';

      downloadCsv(csvItems, this.csvAttributes, filename);

      this.isDownloadingCsv = false;
    },
    isAssetEditable(asset) {
      return !!asset.hasVariables;
    },
    isAssetActive(asset) {
      return !!asset.isActive;
    },
    toggleRowDetails(row) {
      if (Object.prototype.hasOwnProperty.call(this.expandedRows, row.assetId)) {
        this.$delete(this.expandedRows, row.assetId);
      } else {
        this.$set(this.expandedRows, row.assetId, true);
      }
      this.$refs['assets-table'].toggleDetails(row); // buefy b-table method to toggle details
    },
    async handleClickFolder(folder) {
      this.selectedIds = [];

      this.folderContents = await this.$store.dispatch('folders/getFolderContents', folder.folderId);
    },
    handleCreateNewFolderClick() {
      bus.$emit('newFolderButton');
    },
    handleMoveAssets(selectedAssets) {
      if (this.isFoldersViewActive) {
        const assetsToMove = this.addAssetDuplicatesToList(selectedAssets, false);

        bus.$emit('moveSelectedButton', assetsToMove);
      } else {
        this.isMoveSelectedModalActive = true;
      }
    },
    handleSetMoveSelectedButtonEnabled(folderRoot) {
      this.moveSelectedButtonEnabled = folderRoot && !folderRoot.folders.length < 1;
    },
    async handleFoldersLoaded(folderRoot) {
      this.handleSetMoveSelectedButtonEnabled(folderRoot);
    },
    async handleClearSearch() {
      this.filterQuery = '';
      await this.handleClickFolder({folderId: 'root'});
    },
    addAssetDuplicatesToList(comparison) {
      const assetsToCombine = comparison;

      for (const asset of this.allAssets) {
        if (asset.assetType !== 'PSEUDO' && comparison.includes(asset.duplicatedFrom) && assetsToCombine.indexOf(asset.assetId) === -1) {
          assetsToCombine.push(asset.assetId);
        }
      }

      return assetsToCombine;
    },
    handleResetSelectedIds() {
      this.selectedIds = [];
    },
    async handleMoveSelectedAssets({ folder, newContents }) {
      // it's important to note that we aren't adding duplicates to the newContents. This is done server-side for this operation.
      await this.$store.dispatch('folders/moveItemsToFolder', { destinationFolder: folder, contents: newContents });
    },
    getAssetFolderName(assetId) {
      // an asset should only ever have one folder, but we also only ever assign the first found containing the assetId
      const folder = this.allFolders.find(f => f.contents && f.contents.includes(assetId));

      // Array.find returns undefined if no matching items are found in an array
      return (folder) ? folder.name : 'N/A';
    }
  }
};
</script>

<style lang="scss" scoped>
.main-view-content {
  display: flex;
  justify-content: space-between;
  align-content: center;
  gap: 16px;
  height: calc(100vh - 167px);
  overflow-y: hidden;
}

.tabs {
  margin-left: 24px;
}

.assets-table__wrapper {
  flex: 3;
  display: flex;
  flex-direction: column;
  position: relative;
}

.table-pagination {
  border-top: 1px solid #e6e6e6;
  background-color: white;
  position: absolute;
  bottom: 0;
  padding: 16px 24px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.duplicate-asset-row {
  background-color: #EDF0F3;

  &__name {
    padding-left: 32px;
  }
}

.asset-name-tag {
  margin-left: 8px;
}

.asset-status-tag {
  color: #14B889;
}

::v-deep .b-table .table {
  border: none;
  padding-bottom: 16px;
}

.assets-table {
  height: 100%;

  ::v-deep td {
    height: 43px;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;

    & > .b-checkbox {
      margin-top: 4px;
    }
  }

  &__master-checkbox {
    position: relative;
    top: 3px;
  }

  ::v-deep & > div.table-wrapper.has-sticky-header {
    & tr:first-child th {
      background: #EDF0F3;
    }
  }
}

.assets-table__header {
  padding-top: 14px;

  // correct too much bottom margin
   &.level:not(:last-child) {
    margin-bottom: 14px;
  }
}

.assets-table__header--icon {
  margin-right: 8px;
  color: #B3BECC;
  font-size: 16px;
}

.assets-table__header--text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.duplicate-asset-count {
  font-weight: 500;
}

.message {
  margin: 24px;
}
</style>
