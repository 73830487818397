<template>
  <article class="box is-relative">
    <div class="columns">
      <div class="column">
        <div class="content">
          <h5>Do you need access to an account?</h5>

          <p v-if="!user">We couldn't find a Preamp user with your email address. Account administrators can create your user record and grant you access to their account.</p>
          <p v-if="user && !accounts.length">Account administrators can give new and existing users access.</p>

          <p>
            Selecting an account from the dropdown on the right will display the
            email addresses for all admins in that account.
          </p>

          <p>
            Contact an administrator using Slack or email and request that they:

            <ol>
              <li v-if="!user">Create a user record for your email</li>
              <li>
                Grant you <strong>Analyst</strong>, <strong>Developer</strong>, or <strong>Guest</strong> access to the desired account, depending on your role in the team
              </li>
            </ol>
          </p>
        </div>
      </div><!-- /.column -->

      <div class="column">
        <AccountAdminLookup />
      </div><!-- /.column -->
    </div><!-- /.columns -->

    <div class="content">
      <hr>

      <p class="help">
        <em>
          If this tool fails to provide a solution for gaining access to an account, please post a message in the
          <a href="slack://channel?team=T029AV3QX&id=C718VV878">#cohesion-support</a> Slack channel with:
        </em>
        <ol>
          <li v-if="!user">your email address</li>
          <li>the account you need access to</li>
          <li>any issues you had using this tool</li>
        </ol>
      </p>
    </div><!-- /.content -->
  </article><!-- /.box -->
</template>

<script>
import AccountAdminLookup from '@/components/Support/AccountAdminLookup';

export default {
  name: 'UserAccountHelp',
  components: {
    AccountAdminLookup
  },
  props: {
    user: {
      type: Object
    },
    accounts: {
      type: Array
    }
  },
  data() {
    return {

    };
  }
};
</script>
