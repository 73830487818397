<template>
  <div class="card">
    <div class="card-content">
      <section class="bottom40">
        <h5 class="title is-5" data-cy-test="asset-details-title">Asset Details</h5>
        <div class="level">
          <table class="table is-bordered is-hoverable is-fullwidth is-narrow">
            <tr>
              <th>Name</th>
              <td data-cy-test="asset-name">{{ asset.name }}</td>
            </tr>
            <tr>
              <th>ID</th>
              <td data-cy-test="asset-id">{{ asset.assetId }}</td>
            </tr>
            <tr v-if="asset.createdAt">
              <th>Created At</th>
              <td data-cy-test="asset-createdAt">{{ formatTime(asset.createdAt) }}</td>
            </tr>
            <tr v-if="asset.archivedAt">
              <th>Archived At</th>
              <td data-cy-test="asset-archivedAt">{{ formatTime(asset.archivedAt) }}</td>
            </tr>
            <tr v-if="asset.archivedBy">
              <th>Archived By</th>
              <td data-cy-test="asset-archivedBy">{{ asset.archivedBy }}</td>
            </tr>
          </table>
          <table class="table is-bordered is-hoverable is-fullwidth is-narrow">
            <tr>
              <th>Type</th>
              <td data-cy-test="asset-type">
                <b-tag v-if="isAssetEditable" style="margin-left:0;">
                  Editable Asset
                  <span v-if="isAssetPseudoBase" class="icon">
                    <i
                      class="fa"
                      :class="`fa-link`"
                      style="color:grey"
                    ></i>
                  </span>
                </b-tag>
                <b-tag v-else style="margin-left:0;">Asset</b-tag>
              </td>
            </tr>
            <tr>
              <th data-cy-test="asset-tags-title">Tags</th>
              <td>
                <span>
                  <b-taglist
                    v-if="tags && tags.length"
                    class="is-inline"
                    data-cy-test="asset-tags"
                  >
                    <b-tag
                      v-for="(tag, i) in tags"
                      :key="i"
                      style="margin-bottom: 0px;"
                    >{{ tag }}</b-tag>
                  </b-taglist>
                  <template v-else>
                    <span data-cy-test="asset-no-tags"> No tags found</span>
                  </template>
                </span>
              </td>
            </tr>
            <tr v-if="asset.variantKey">
              <th>Variant Key</th>
              <td data-cy-test="asset-variant-key"><code>{{ asset.variantKey }}</code></td>
            </tr>
            <tr v-if="baseAsset && baseAsset.name">
              <th>Base Asset</th>
              <td data-cy-test="asset-base-asset"><router-link :to="`/assets/${baseAsset.assetId}`">{{ baseAsset.name }}</router-link></td>
            </tr>
          </table>
        </div>
      </section>

      <section class="bottom40 reference-tabs">
        <b-tabs>
          <b-tab-item
            label="Experiences"
            icon="desktop"
            data-cy-test="experiences-tab"
          >
            <b-table
              v-if="assetExperiences.length > 0"
              :data="assetExperiences"
              paginated
              :pagination-simple="true"
              :per-page="10"
              bordered
              narrowed
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
            >
              <b-table-column
                v-slot="props"
                field="experienceName"
                label="Experience"
              >
                <span v-if="props.row.experienceId">
                  <router-link :to="`/experiences/${props.row.experienceId}`">
                    {{ `${props.row.experienceNumber}. ${props.row.experienceName || ''}` }}
                  </router-link>
                </span>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="placementName"
                label="Placement"
              >
                <router-link :to="{ name: 'Placements', params: { placementId: props.row.placement.placementId } }">
                  {{ props.row.placement.placementName }}
                </router-link>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="previewLink"
                label="Preview"
              >
                <a
                  :href="getExpPreviewLink(props.row)"
                  target="_blank"
                >
                  <b-icon icon="external-link" />
                </a>
              </b-table-column>
            </b-table>
            <em v-else data-cy-test="no-experiences-found">
              No experiences were found
            </em>
          </b-tab-item>

          <b-tab-item
            label="Placements"
            icon="object-group"
            data-cy-test="placements-tab"
          >
            <b-table
              v-if="assetPlacements.length > 0"
              :data="assetPlacements"
              paginated
              :pagination-simple="true"
              :per-page="10"
              bordered
              narrowed
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
            >
              <b-table-column
                v-slot="props"
                field="placementName"
                label="Placement"
              >
                <router-link :to="{ name: 'Placements', params: { placementId: props.row.placementId } }">
                  {{ props.row.name }}
                </router-link>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="previewLink"
                label="Preview"
              >
                <a
                  :href="getPlacementPreviewLink(props.row)"
                  target="_blank"
                >
                  <b-icon icon="external-link" />
                </a>
              </b-table-column>
            </b-table>
            <em v-else data-cy-test="no-placements-found">
              No placements were found
            </em>
          </b-tab-item>

          <b-tab-item
            v-if="asset.hasVariables && asset.duplicatedFrom === undefined"
            label="Variants"
            icon="code-fork"
            data-cy-test="variants-tab"
          >
            <b-table
              v-if="assetVariants.length > 0"
              :data="assetVariants"
              paginated
              :pagination-simple="true"
              :per-page="10"
              narrowed
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
            >
              <b-table-column
                v-slot="props"
                field="AssetName"
                label="Asset Name"
              >
                <router-link :to="`/assets/${props.row.assetId}`">
                  {{ props.row.name }}
                </router-link>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="AssetID"
                label="Asset ID"
              >
                {{ props.row.assetId }}
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="status"
                label="Status"
              >
                <b-tag v-if="props.row.archived === 1" class="asset-status-tag is-warning">
                  Archived
                </b-tag>
                <b-tag v-else-if="props.row.isActive" class="asset-status-tag">
                  Active
                </b-tag>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="LastEdited"
                label="Last Edited"
              >
                {{ props.row.createdAt | filterArchiveDateTime }}
              </b-table-column>
            </b-table>
            <em v-else>
              No variants were found for this asset
            </em>
          </b-tab-item>
        </b-tabs>
      </section>

      <hr>

      <section
        v-if="isAssetEditable"
        class="bottom40 variables-table"
      >
        <div class="level is-align-items-center">
          <div class="level-left">
            <h4 class="title" data-cy-test="editable-asset-header">Editable Asset Content</h4>
          </div>
          <div class="level-right">
            <b-input
              v-model="variableFilterQuery"
              data-cy-test="variable-search-input"
              placeholder="Search for a variable"
              icon-pack="fa"
              icon="search"
            ></b-input>
          </div>
        </div>

        <div class="variables-section" data-cy-test="editable-variables-section">
          <VariableTable
            v-if="asset.variables != null"
            :variables="asset.variables"
            :filterQuery="variableFilterQuery"
            @clearVariableSearch="handleClearVariableSearch"
          />
        </div>
      </section>

      <section class="is-relative">
        <nav
          v-if="selectedCode != null"
          class="tabs file-tabs"
        >
          <ul>
            <li
              v-for="(file, i) in files"
              :key="i"
              :class="{ 'is-active': file === selectedFile }"
            >
              <a :data-cy-test="`${file}-file-tab`" @click.stop="toggleFile(file)">
                {{ getTabName(file) }}
              </a>
            </li>
          </ul>
        </nav>

        <div
          v-if="selectedCode != null"
          class="code-wrap"
        >
          <div class="code-block">
            <code v-html="selectedCode"></code>
          </div>
        </div>
      </section>

      <div v-if="userCanEdit && isAssetEditable" class="horizontal-line-break"></div>

      <div v-if="userCanEdit && isAssetEditable" class="level">
        <div class="level-left">
          <div
            v-if="isAssetDuplicate && asset.archived === 0"
            class="level-item"
          >
            <b-button
              type="is-info"
              icon-left="edit"
              data-cy-test="asset-edit-button"
              @click="openEditAssetModal"
            >
              Edit
            </b-button>
          </div>
          <div
            v-if="isAssetEditable && asset.archived === 0"
            class="level-item"
          >
            <b-button
              icon-left="paste"
              data-cy-test="asset-duplicate-button"
              @click="openDuplicateAssetModal"
            >
              Duplicate
            </b-button>
          </div>
          <div
            v-if="isAssetEditable && asset.archived === 0"
            class="level-item"
          >
            <b-button
              icon-left="list-ol"
              data-cy-test="asset-bulk-duplicate-button"
              @click="openBulkDuplicateAssetModal"
            >
              Bulk Duplicate
            </b-button>
          </div>
          <div
            v-if="isAssetDuplicate && asset.archived === 0"
            class="level-item"
          >
            <b-button
              icon-left="archive"
              :disabled="!isArchivable"
              data-cy-test="asset-archive-button"
              @click="handleArchiveAsset"
            >
              Archive
            </b-button>
          </div>
          <div
            v-else-if="asset.archived === 1"
            class="level-item"
          >
            <b-button
              icon-left="archive"
              data-cy-test="asset-unarchive-button"
              @click="handleUnarchiveAsset"
            >
              Unarchive
            </b-button>
          </div>
        </div>
        <div
          v-if="isAssetDuplicate"
          class="level-right"
        >
          <div
            v-if="isAssetEditable && isFastForwardable && !asset.archived"
            class="level-item"
          >
            <b-button
              class="is-success"
              icon-left="refresh"
              data-cy-test="asset-fastforward-button"
              @click="isFastForwarding = true"
            >
              Update Asset
            </b-button>
          </div>
        </div>
      </div>

      <b-loading
        :is-full-page="false"
        :active.sync="isLoading"
      ></b-loading>
    </div><!-- /.card-content -->

    <AssetModal
      v-if="isModalActive"
      v-model="isModalActive"
      :modalType="isDuplicateAssetModalActive ? 'duplicate' : 'edit'"
      :asset="asset"
      :allAssets="allAssets"
      :hasLiveExperiences="hasLiveExperiences"
      @duplicateAsset="handleDuplicateAsset"
      @editAsset="handleEditAsset"
    />

    <BulkAssetCSVModal
      v-if="isBulkDuplicateAssetModalActive"
      v-model="isBulkDuplicateAssetModalActive"
      :asset="asset"
      :allAssets="allAssets"
      @bulkDuplicateAsset="handleBulkDuplicateAsset"
    />

    <FastForwardModal
      v-if="assetPlacements && assetDiff && isFastForwarding"
      v-model="isFastForwarding"
      :baseAsset="baseAsset"
      :assetsDiff="assetDiff"
      :duplicateAsset="asset"
      :allAssets="allAssets"
      :assetPlacements="assetPlacements"
      :hasLiveExperiences="hasLiveExperiences"
    />
  </div><!-- /.card -->
</template>

<script>
import beautify from 'js-beautify';
import { preampVarsV2 } from '@redventures/preamp-variables';
import { get } from 'vuex-pathify';
import { hljs, formatTime } from '@/modules/utilities';
import VariableTable from './VariableTable.vue';
import AssetModal from './Modals/AssetModal';
import FastForwardModal from './Modals/FastForwardModal.vue';
import BulkAssetCSVModal from '../BulkAssetCSVModal.vue';

export default {
  name: 'Asset',
  components: {
    AssetModal,
    FastForwardModal,
    VariableTable,
    BulkAssetCSVModal
},
  props: {
    asset: {
      type: Object,
      required: true
    },
    allAssets: {
      type: Array,
      required: true
    },
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingCount: 0, // if > 0, asset will display loading indicator
      assetPlacements: [],
      assetExperiences: [],
      selectedFile: null,
      isDuplicateAssetModalActive: false,
      isBulkDuplicateAssetModalActive: false,
      isEditAssetModalActive: false,
      isFastForwarding: false,
      baseAsset: null,
      variableFilterQuery: ''
    };
  },
  computed: {
    userCanEdit() {
      return this.$store.getters.userCanEdit;
    },
    // used to return a boolean value to the loading indicator since our actual value is an int
    isLoading() {
      return this.loadingCount > 0;
    },
    isAssetEditable() {
      return !!this.asset.hasVariables;
    },
    isAssetDuplicate() {
      return !!this.asset.duplicatedFrom;
    },
    isAssetPseudoBase() {
      return this.asset.assetType === 'PSEUDO';
    },
    assetDiff() {
      if (!this.baseAsset) return null;
      return preampVarsV2.diffAssets(this.asset, this.baseAsset);
    },
    isFastForwardable() {
      if (!this.assetDiff) return false;

      return this.assetDiff.hasChanges;
    },
    hasLiveExperiences() {
      const isLive = this.assetExperiences.filter(exp => exp.isActive || exp.isChampion);
        // determine if asset is in a live experience
       return isLive.some((exp) => {
          if (exp.assetPlacements) {
            return exp.assetPlacements.map(p => p.asset.assetId).includes(this.asset.assetId);
          }
          return false;
        });
    },
    assetVariants() {
      const variants = this.allAssets.filter((a) => {
        return a.duplicatedFrom === this.asset.assetId;
      });
      return variants.sort((a,b) => (a.name > b.name) ? 1 : -1);
    },
    isModalActive: {
      get() {
        return this.isDuplicateAssetModalActive || this.isEditAssetModalActive;
      },
      set(val) {
        this.isDuplicateAssetModalActive = val;
        this.isEditAssetModalActive = val;
      }
    },
    archivables: get('archivables'),
    isArchivable() {
      return this.archivables && this.archivables.assetIds.includes(this.asset.assetId);
    },
    files() {
      const files = ['html', 'css', 'js', 'metadata'];
      return files.filter(file => !!this.asset[file]);
    },
    tags() {
      return this.asset.tags || [];
    },
    assetCode() {
      const assetCode = {};
      this.files.forEach(file => assetCode[file] = this.asset[file]);
      return assetCode;
    },
    selectedCode() {
      return this.selectedFile ? this.formatCode(this.assetCode[this.selectedFile]) : null;
    }
  },
  created() {
    this.getDependencies();
  },
  methods: {
    // Handle formatting time
    formatTime,
    startLoading() {
      this.loadingCount += 1;
    },
    stopLoading() {
      if (this.loadingCount > 0) this.loadingCount -= 1;
    },
    getTabName(fileType) {
      if(fileType === 'metadata') {
        return 'JSON';
      }
      return fileType.toUpperCase();
    },
    // Get the experiences and placements an asset belongs to
    async getAssetPlacementsExperiences() {
      try {
        this.startLoading();
        const res = await this.$axios.get(`/sites/${this.siteId}/assets/${this.asset.assetId}/assetPlacementsExperiences`);
        this.assetPlacements = res.data.placements;
        this.assetExperiences = res.data.experiences;
        this.assetExperiences.sort((a, b) => a.experienceNumber - b.experienceNumber);
        this.stopLoading();
      } catch (e) {
        e.title = 'There was a problem retrieving asset\'s experience and placement data.';
        this.$store.commit('error', e);
      }
    },
    // Get the base asset if the current asset is a duplicate
    async getBaseAsset() {
      this.baseAsset = await this.$store.dispatch('assets/getAssetById', this.asset.duplicatedFrom);
    },
    /**
     * Format the code into a readable form
     * @param {String} code - code to format
     * @return {String} formatted code
     */
    formatCode(code) {
      const language = this.selectedFile;
      if (language === 'metadata') {
        return code;
      }
      const formatter = beautify[language];
      let output = formatter(code, {
        type: language,
        indent_size: 2, // eslint-disable-line camelcase
        wrap_line_length: 120 // eslint-disable-line camelcase
      });
      output = hljs.highlight(language, `${output}`);
      return output.value;
    },
    /**
     * Handle file switching
     * @param {String} type - file to render
     */
    toggleFile(type) {
      this.selectedFile = type;
    },
    // Get dependancy data for the asset card
    getDependencies() {
      this.getAssetPlacementsExperiences();
      this.$store.dispatch('getArchivables');
      if (this.asset.duplicatedFrom) this.getBaseAsset();
      this.selectedFile = this.files.length > 0 ? this.files[0] : null;
    },
    async openDuplicateAssetModal() {
      if (!this.isAssetEditable) return;
      await this.$root.establishSiteLock();
      this.isDuplicateAssetModalActive = true;
    },
    async openBulkDuplicateAssetModal() {
      if (!this.isAssetEditable) return;
      await this.$root.establishSiteLock();
      this.isBulkDuplicateAssetModalActive = true;
    },
    async openEditAssetModal() {
      if (!this.isAssetEditable || !this.isAssetDuplicate) return;
      await this.$root.establishSiteLock();
      this.isEditAssetModalActive = true;
    },
    async handleDuplicateAsset(editedAsset, editedVariables, duplicateAsBase) {
      if (!this.isAssetEditable) return;

      const path = duplicateAsBase ? '/' : `/${editedAsset.assetId}`;
      const payload = {
        variables: editedVariables,
        name: editedAsset.name,
        tags: editedAsset.tags,
        path,
        duplicateAsBase
      };

      // if a variant key was supplied, add it to the duplicate's initial data
      if (typeof editedAsset.variantKey === 'string' && editedAsset.variantKey.length !== 0) {
        payload.variantKey = editedAsset.variantKey;
      }

      try {
        await this.$root.establishSiteLock();

        const res = await this.$axios.post(`/sites/${this.siteId}/assets/${editedAsset.assetId}/duplicate`, payload);
        if (res.data) {
          this.$router.push(`/assets/${res.data.assetId}`);
        }
      } catch (err) {
        this.$store.commit('error', err, 'Could not duplicate asset');
      }
    },
    async handleBulkDuplicateAsset(bulkAssets, bulkVars, assetVersion) {
      if (!this.isAssetEditable) return;

      try {
        const res = await this.$axios.post(`/sites/${this.siteId}/assets/${this.asset.assetId}/bulkDuplication`, {
          assets: bulkAssets,
          variables: bulkVars,
          version: assetVersion
        });
        if (res.config.data) {
            this.isBulkDuplicateAssetModalActive = false;
            this.$buefy.toast.open({
            message: `Successfully uploaded bulk assets`,
            type: 'is-success'
          });
        }
        this.$emit('updatedVariants');
      } catch (err) {
        this.$store.commit('error', err, 'Could not duplicate assets');
      }
    },
    async handleEditAsset(editedAsset, editedVariables) {
      if (!this.isAssetEditable || !this.isAssetDuplicate) return;
      try {
        await this.$root.establishSiteLock();

        const editPayload = {
          variables: JSON.stringify(editedVariables),
          name: editedAsset.name,
          tags: editedAsset.tags,
          archived: editedAsset.archived
        };

        if (typeof editedAsset.variantKey === 'string' && editedAsset.variantKey.length !== 0) {
          editPayload.variantKey = editedAsset.variantKey;
        }

        await this.$axios.put(`/sites/${this.siteId}/assets/${editedAsset.assetId}`, editPayload);
        this.$emit('updatedAsset');
      } catch (err) {
        this.$store.commit('error', err, 'Could not update asset');
      }
    },
    async handleArchiveAsset() {
      if (!this.isAssetEditable || !this.isAssetDuplicate) return;
      try {
        await this.$root.establishSiteLock();
        const archivePayload = {
          variables: this.isAssetEditable ? this.asset.variables : {},
          name: this.asset.name,
          tags: this.asset.tags,
          archived: 1
        };

        if (this.asset.variantKey) archivePayload.variantKey = this.asset.variantKey;

        await this.$axios.put(`/sites/${this.siteId}/assets/${this.asset.assetId}`, archivePayload);
        this.$emit('updatedAsset');
      } catch (err) {
        this.$store.commit('error', err, 'Could not archive asset');
      }
    },
    async handleUnarchiveAsset() {
      if (!this.isAssetEditable || !this.isAssetDuplicate) return;
      try {
        await this.$root.establishSiteLock();
        const unarchivePayload = {
          variables: this.asset.variables,
          name: this.asset.name,
          tags: this.asset.tags,
          archived: 0
        };

        if (this.asset.variantKey) unarchivePayload.variantKey = this.asset.variantKey;

        await this.$axios.put(`/sites/${this.siteId}/assets/${this.asset.assetId}`, unarchivePayload);
        this.$emit('updatedAsset');
      } catch (err) {
        this.$store.commit('error', err, 'Could not unarchive asset');
      }
    },
    getExpPreviewLink(experience) {
      let previewUrl = experience.placement.previewUrl || this.$store.getters.siteDomain;

      if (previewUrl.indexOf('http') === -1) {
        previewUrl = `http://${previewUrl}`;
      }

      previewUrl = (previewUrl.indexOf('?') !== -1)
        ? previewUrl += '&'
        : previewUrl += '?';

      return `${previewUrl}exp=${experience.experienceNumber}`;
    },
    getPlacementPreviewLink(placement) {
      let previewUrl = placement.previewUrl || this.$store.getters.siteDomain;

      if (previewUrl.indexOf('http') === -1) {
        previewUrl = `http://${previewUrl}`;
      }

      previewUrl = (previewUrl.indexOf('?') !== -1)
        ? previewUrl += '&'
        : previewUrl += '?';

      return `${previewUrl}!preamp=${placement.placementId}.${this.asset.assetId}`;
    },
    formatAssetVariableValue(value) {
      return value.split('<br/>').map(val => val.replaceAll('&lt;br/&gt;', '<br/>'));
    },
    handleClearVariableSearch() {
      this.variableFilterQuery = '';
    }
  }
};
</script>

<style lang="scss" scoped>
.tag:not(:first-child) {
  margin-left: 15px;
}

.code-wrap {
  background-color: #F6F7F9;
  padding: 20px;
  width: 100%;
}

.code-block {
  font-family: 'monospace';
  max-height: 500px;
  overflow: auto;
  white-space: pre-wrap;
  width: 100%;
}

.level {
  align-items: flex-start;

  & > table:first-child {
    margin-right: 16px;
  }
}

.horizontal-line-break {
  margin: 16px 0;
  border-top: 1px solid #dbdbdb;
}

.skinny-message ::v-deep .message-body {
  padding-top: 8px;
  padding-bottom: 8px;
}

.variables-section {
  display: flex;
  flex-direction: column;
  column-gap: 24px;;
  row-gap: 24px;
  margin-bottom: 24px;
}

// @TODO: Once base styles have been added to make table styling match design system, remove these custom styles
table, table.table {
  border-radius: 5px;

  td, th {
    border-color: #EDF0F3;
    padding: 8px 12px;
    border-radius: inherit;
    height: 42px;
  }

  tr:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  td:first-child, th:first-child {
    border-right-color: white;
    border-radius: inherit;
  }

  th {
    color: #5C6F84;
    font-weight: 500;
  }
}

.asset-status-tag {
  color: #14B889;
}

// @TODO: Once base styles have been added to make table styling match design system, remove these custom styles
:deep(.b-table table.table) {
  border: 1px solid #EDEFF3;

  thead {
    th {
      background-color: #DEE4EA;
      border-color: #DEE4EA;
      color: #626D7A;
      padding: 8px 12px;

      &:first-child {
        border-top-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
      }
    }
  }

  tbody {
    td {
      border-color: #EDEFF3;
      color: #626D7A;
      padding: 8px 12px;
    }
  }
}

//SHARED MODAL STYLES (Edit/Duplicate and FF)
:deep(.title) {
  font-size: 20px;
  line-height: 22px;
  color: #0B0F14;
  font-weight: 500;
}
:deep(.section-title) {
  font-size: 16px;
  line-height: 20px;
  color: #0B0F14;
  font-weight: 500;
}
:deep(.label) {
  color: #626D7A;
  font-weight: 400;
}
:deep(.section-description) {
  margin-top: 4px;
  margin-bottom: 24px;
}
:deep(.button.is-ghost) {
  color: #626D7A;
  font-weight: 600;

  &:hover {
    text-decoration: none;
    color: #0B0F14;
  }
}

:deep(.button.is-info) {
  background-color: #0696EF;
  font-weight: 600;

  &:hover {
    background-color: #016EB2;
  }

  &:disabled {
    background-color: #0696EF;
    opacity: .5;
  }
}

:deep(.message.is-info) {
  background-color: rgba(76, 187, 255, 0.15);
}
:deep(.message.is-success) {
  background-color: rgba(20, 184, 137, 0.15);
}
:deep(.message.is-danger) {
  background-color: rgba(255, 102, 102, 0.15);
}
:deep(.message.is-warning) {
  background-color: rgba(255, 182, 0, 0.15);
}
:deep(.dev-comment__icon) {
  & i {
    color:#AEB7C2;

  }
}
</style>
