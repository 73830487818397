<template>
  <section class="audience-form">
    <div class="columns">
      <div class="column">
        <b-field label="Name">
          <b-input
            v-model="audience.name"
            data-cy-test="audience-name"
            maxlength="64"
            type="text"
            placeholder="Existing Customers"
            required
          ></b-input>
        </b-field>
      </div>

      <div class="column">
        <b-field label="Description">
          <b-input
            v-model="audience.description"
            type="textarea"
            data-cy-test="audience-description"
            placeholder="Any additional information about this audience that would be helpful to other team members"
          ></b-input>
        </b-field>
      </div>
    </div>

    <hr v-if="!audience.isEndpoint">
    <Rules
      v-if="!audience.isEndpoint && showRules"
      :rules="audience.rules"
      :is-editing="true"
      :key-id="audience.audienceId || 'new_audience_id_placeholder'"
    />

    <hr>
    <TestSelect
      :site-id="siteId"
      :audience="audience"
      :experiences="experiences"
      :draft-mode="draftMode"
      @tests-updated="testsUpdated"
    />

    <hr>
    <ChampionSelect
      :experiences="experiences"
      :championId="audience.championExperienceId"
      @champion-selected="expId => audience.championExperienceId = expId"
    />

    <hr>
    <div class="level">
      <div class="level-left">
        <div
          class="level-item"
          data-cy-test="save-button"
        >
          <base-button-save
            :loading="isSavingAudience"
            :disabled="!isValid.valid"
            @click="(isEditing) ? updateAudience() : saveAudience()"
          />
        </div>

        <div class="level-item">
          <button
            class="button"
            data-cy-test="cancel-button"
            @click="resetAudience(true)"
          >
            <span>Cancel</span>
          </button>
        </div>

        <div
          v-if="!isValid.valid"
          class="level-item"
        >
          <p
            class="has-text-danger"
            data-cy-test="validation-message"
          >
            <span class="icon">
              <i class="fa fa-exclamation-circle"></i>
            </span>
            {{ isValid.errMsg }}
          </p>
        </div>
      </div><!-- /.level-left -->
    </div><!-- /.level -->
  </section>
</template>

<script>
import { diffJson } from 'diff';
import { hasLength, getRuleString, clone } from '@/modules/utilities';
import { invalidRulePaths } from '@/modules/ruleList';
import Rules from '@/components/Rules/Rules';
import ChampionSelect from '@/components/Audiences/ChampionSelect';
import TestSelect from '@/components/Audiences/TestSelect';

export default {
  name: 'AudienceForm',
  components: {
    Rules,
    ChampionSelect,
    TestSelect
  },
  props: {
    initialAudience: {
      type: Object,
      default() {
        return {
          name: '',
          isEndpoint: false,
          rules: [[]],
          championExperienceId: null,
          tests: []
        };
      }
    },
    experiences: {
      type: Array,
      default() {
        return [];
      }
    },
    allAudiences: {
      type: Array,
      default() {
        return [];
      }
    },
    draftMode: {
      type: Boolean
    }
  },
  data() {
    return {
      audience: null,
      showRules: true,
      isSavingAudience: false,
      invalidRulePaths
    };
  },
  computed: {
    siteId() {
      return this.$store.state.siteId;
    },
    isEditing() {
      return this.initialAudience && this.initialAudience.audienceId !== undefined;
    },
    totalAudienceWeight() {
      let total = 0;
      if (hasLength(this.audience.tests)) {
        for (const test of this.audience.tests) {
          total += test.visitorsPct;
        }
      }
      return total;
    },
    isValid() {
      let errMsg;
      let valid = false;
      const ruleValidation = this.validateRules(this.audience.rules);
      // check if audience has tests before trying to validate
      let testValidation;
      if (hasLength(this.audience.tests)) {
        testValidation = this.validateTests(this.audience.tests);
      }

      switch (true) {
        case !hasLength(this.audience.name):
          errMsg = 'Audiences must have a valid name';
          break;
        case !this.audience.isEndpoint && !ruleValidation.valid:
          errMsg = ruleValidation.msg;
          break;
        case testValidation !== undefined && !testValidation.valid:
          errMsg = testValidation.msg;
          break;
        case this.totalAudienceWeight > 100:
          errMsg = 'Audience visitor percentage total cannot be above 100%';
          break;
        default:
          valid = true;
          break;
      }
      return {
        errMsg,
        valid
      };
    },
    otherAudienceRuleStrings() {
      return this.allAudiences.filter((audience) => {
        // if it's a new audience (no id) only filter out endpoint
        // if it does have an ID, filter out itself too
        return (
          !this.audience.audienceId
            ? !audience.isEndpoint
            : audience.audienceId !== this.audience.audienceId && !audience.isEndpoint
        );
      }).map(otherAudience => getRuleString(otherAudience.rules));
    }
  },
  created() {
    this.resetAudience();
  },
  methods: {
    /**
     * Set the updated tests on the audience
     */
    testsUpdated() {
      this.audienceChanged();
    },
    /**
     * Validate our rules
     * @param {Array} - collection of rules
     * @return {Object} bool and err message
     */
    validateRules(rules) {
      const validation = { msg: null, valid: false };

      // Validate that there are rules
      if (!rules.length || !rules[0].length) {
        validation.msg = 'Audiences require at least one rule.';
        return validation;
      }
      // Validate individual rules
      for (let i = 0; i < rules.length; i++) {
        const group = rules[i];
        for (let j = 0; j < group.length; j++) {
          const rule = group[j];

        if (!rule.comparator.length || !rule.path.length || !rule.value.toString().length || this.invalidRulePaths.includes(rule.path)) {
          validation.msg = `Group ${i + 1} rule ${j + 1} is an invalid rule, see above.`;
          return validation;
        }
      }
    }

      // Validate uniqueness
      if (this.otherAudienceRuleStrings.includes(getRuleString(rules))) {
        validation.msg = 'Another audience has the same rules.';
        return validation;
      }

      // Passed validation
      validation.valid = true;
      return validation;
    },
    /**
     * Check the total percentages for experiences within a test
     * @param {Array} tests - audience test collection
     */
    validateTests(tests) {
      const validation = { msg: null, valid: false };
      const testCount = tests.length;

      for (let j = 0; j < testCount; j++) {
        const test = tests[j];

        if (test.strategy && test.strategy.mvt !== undefined) {
          const totalExpWeight = test.strategy.mvt.experiences
            .filter(exp => exp.experienceId !== '0')
            .map(exp => exp.weight)
            .reduce((acc, curr) => acc + curr);

          if (totalExpWeight > 100) {
            validation.msg = 'Cannot allocate more than 100% of experiences in a test.';
            return validation;
          }
        }
      }

      validation.valid = true;
      return validation;
    },
    /**
     * Sets the audience edits in state
     * @param {Boolean} force - clear the audience from edits
     */
    audienceChanged(force) {
      const item = (this.audience.audienceId)
        ? `audience-${this.audience.audienceId}`
        : 'audience-new-audience';

      if (force) {
        this.$store.commit('removeEditItem', item);
        return;
      }

      // Check that we actually changed something
      const changed = diffJson(this.initialAudience, this.audience).length > 1;

      if (changed) {
        if (this.$store.state.edits.indexOf(item) === -1) {
          this.$store.commit('addEditItem', item);
        }
      } else {
        this.$store.commit('removeEditItem', item);
      }
    },
    /**
     * Force a re-render, editing arrays have issues with triggering re-renders
     * @luowenxing https://github.com/vuejs/Discussion/issues/356
     */
    reRender() {
      this.showRules = false;
      this.$nextTick(() => {
        this.showRules = true;
      });
    },
    // Reset the audience back to it's original version
    resetAudience(toggled) {
      if (this.audience !== null) this.$emit('reset-audience', true);
      this.audience = clone(this.initialAudience);
      if (!this.audience.tests) this.audience.tests = [];
      if (!this.audience.isEndpoint && this.audience.rules.length === 1 && this.audience.rules[0].length === 0) {
        this.audience.rules[0].push({ path: '', comparator: '', value: '' });
      }

      if (toggled) {
        this.audienceChanged(true);
      }
    },
    formatAudience(audienceData) {
      const audience = { ...audienceData };
      if (typeof audience.description !== 'string' || !audience.description.length) delete audience.description;
      if (audience.championExperienceId === null) delete audience.championExperienceId;
      if (!audience.isEndpoint && Array.isArray(audience.rules) && audience.rules.length) {
        audience.rules = audience.rules.map(function (ruleGroup) {
          if (Array.isArray(ruleGroup) && ruleGroup.length) {
            return ruleGroup.map(function (rule) {
              // remove any custom options with no value
              if (rule.options && rule.options.integrationId === null) {
                delete rule.options.integrationId;
              }

              return rule;
            });
          } else {
            return ruleGroup;
          }
        });
      }
      return audience;
    },
    // Emit the new audience to be saved
    async saveAudience() {
      this.isSavingAudience = true;
      this.$emit('create-audience', this.formatAudience(this.audience));
      this.$store.commit('removeEditItem', 'audience-new-audience');
      this.isSavingAudience = false;
    },
    // Emit the updated audience to be saved
    async updateAudience() {
      this.isSavingAudience = true;
      this.audienceChanged(true);
      this.$emit('update-audience', this.formatAudience(this.audience));
      this.isSavingAudience = false;
    }
  }
};
</script>
