<template>
  <div class="card bottom10">
    <header class="card-header">
      <div class="card-header-title">
        Add New Site
      </div>
    </header>
    <div class="card-content">
      <section class="bottom15">
        <div class="field">
          <label class="label">Site Name</label>
          <div class="control">
            <input
              v-model="name"
              data-cy-test="create-site-name-input"
              type="text"
              class="input"
              placeholder="Example Site"
            >
          </div>
        </div>
        <div class="field">
          <label class="label">Site URL</label>
          <div class="control">
            <input
              v-model="url"
              data-cy-test="create-site-url-input"
              type="text"
              class="input"
              placeholder="https://www.example.com"
            >
          </div>
        </div>
      </section>

      <section class="bottom15">
        <b-field label="Manage Whitelist">
          <b-taginput
            v-model="whitelistedDomains"
            placeholder="Add a Domain"
          ></b-taginput>
        </b-field>
        <p class="help">An asterisk (*) can be used as a wildcard. (m.example.com or *.*-example.com) </p>
      </section>

      <section class="bottom15">
        <b-field label="Manage Regions">
          <b-taginput
            v-model="regions"
            placeholder="Add a Region"
            :data="availableRegions"
            autocomplete
          >
          </b-taginput>
        </b-field>
        <p class="help">If you need to use the decision servers for a specific region, add them above. (Press the down key for a list of all available regions)</p>
      </section>

      <section>
        <b-field label="HIPAA Site">
          <b-switch
            v-model="isHIPAA"
            data-cy-test="create-site-hipaa-switch"
            type="is-info"
          >
          </b-switch>
        </b-field>
      </section>

      <section>
        <hr>
        <div class="level top15">
          <div class="level-left">
            <div class="level-item">
              <base-button-save
                data-cy-test="create-site-save-button"
                :loading="isSavingSite"
                :disabled="!isValid.valid"
                @click="save"
              />
            </div>

            <div class="level-item">
              <button
                class="button"
                @click.stop="cancel"
              >Cancel</button>
            </div>

            <div
              v-if="!isValid.valid"
              class="level-item"
            >
              <p class="has-text-danger">
                <span class="icon">
                  <i class="fa fa-exclamation-circle"></i>
                </span>
                {{ isValid.errMsg }}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { isValidUrl } from '@/modules/utilities';

export default {
  name: 'NewSite',
  props: ['accountId', 'siteNames', 'availableRegions'],
  data() {
    return {
      name: '',
      url: '',
      whitelistedDomains: [],
      regions: [],
      isSavingSite: false,
      isHIPAA: false
    };
  },
  computed: {
    isValid() {
      let errMsg;
      let valid = false;

      switch (true) {
        case this.name.trim().length === 0:
          errMsg = 'Please enter a valid site name.';
          break;
        case this.siteNames.includes(this.name.toLowerCase()) && this.name !== this.site.name:
          errMsg = 'This site name is already in use.';
          break;
        case this.url && !isValidUrl(this.url):
          errMsg = 'Please enter a valid site URL.';
          break;
        default:
          valid = true;
      }

      return {
        errMsg,
        valid
      };
    }
  },
  methods: {
    // Reset the add site form and close
    cancel() {
      this.name = '';
      this.url = '';
      this.whiteListedDomains = [];
      this.regions = [];
      this.$emit('addSiteCancel');
    },
    // Post the new site to Preamp
    async save() {
      this.isSavingSite = true;

      const payload = {
        accountId: this.accountId,
        name: this.name,
        url: this.url,
        whitelistedDomains: this.whitelistedDomains,
        regions: this.regions,
        isHIPAA: this.isHIPAA
      };

      try {
        await this.$axios.post('/site', payload);

        this.$buefy.toast.open({
          message: `Successfully created ${this.name}`,
          type: 'is-success'
        });

        this.$emit('updatedSite');
        this.cancel();
      } catch (err) {
        err.title = `Could not create ${this.name}`;
        this.$store.commit('error', err);
      }

      this.isSavingSite = false;
    }
  }
};
</script>
