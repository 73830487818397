<template>
  <div class="placement">
    <div class="main-view-header">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div
              class="breadcrumb is-medium"
              aria-label="breadcrumbs"
            >
              <ul>
                <li><router-link to="/placements">Placements</router-link></li>
                <li class="is-active">
                  <router-link
                    v-if="placement
                      && placement.placementId
                      && placement.name"
                    :to="`/placements/${placement.placementId}`"
                  >
                    {{ placement.name }}
                  </router-link>
                  <a
                    v-else
                    href="javascript:void(0)"
                  >{{ isLoading ? 'Loading...' : 'Unknown Placement' }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-view-content">

      <Placement
        v-if="siteId && placement && placement.placementId"
        :placement="placement"
        :siteId="siteId"
      />

      <b-loading
        :active.sync="isLoading"
        :is-full-page="false"
      />
    </div>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';
import { bus } from '@/main';
import Placement from '@/components/Placement';
export default {
  name: 'PlacementView',
  components: {
    Placement
  },
  computed: {
    placement: sync('placements/focused'),
    isLoading: sync('placements/isLoading'),
    siteId() {
      return this.$store.state.siteId;
    },
    placementId() {
      return this.$route.params.placementId;
    }
  },
  watch: {
    placementId(id, oldId) {
      if (id !== oldId) {
        this.getDependencies();
      }
    }
  },
  created() {
    this.getDependencies();
    bus.$on('site-changed', this.getDependencies);
  },
  beforeDestroy() {
    // Reset the focused asset
    this.placement = {};
    bus.$off('site-changed', this.getDependencies);
  },
  methods: {
    async getDependencies() {
      if (this.siteId) {
        await this.$store.dispatch('placements/getFocused', this.placementId);
        // If placement isn't found - redirect to placements page
        if (!this.placement || this.placement.siteId !== this.siteId) {
          this.placement = {};
          if (this.$route.path !== '/placements') {
            this.$router.push('/placements');
          }
        }
      }
    }
  }
};
</script>
