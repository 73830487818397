<template>
  <div>
    <div class="field">
      <b-checkbox
        v-model="isEG"
        type="is-info"
        :disabled="numExperiences === 0"
      >
        Set the minimum amount of traffic the lower performing experience will receive
      </b-checkbox>
    </div>
    <div v-if="isEG && numExperiences > 0">
      <b-field expanded>
        <template slot="label">
          Minimum Traffic: {{ minTrafficPct }}%
          <b-tag rounded>{{ minTrafficRating }}</b-tag>
        </template>
        <b-slider
          v-model="minTrafficPct"
          :min="1"
          :max="maxTrafficPct"
          aria-label="Fan"
          :tooltip="false"
        >
          <b-slider-tick :value="1">1</b-slider-tick>
          <b-slider-tick
            v-for="index in 5"
            :key="index"
            :value="Math.round(maxTrafficPct / 5 * index)"
          >
            {{ Math.round(maxTrafficPct / 5 * index) }}
          </b-slider-tick>
        </b-slider>
      </b-field>
      <b-field
        v-if="minTrafficPct"
        class="top25"
      >
        <h6 class="subtitle is-6">
          The lower performing experience{{ numExperiences > 2 ? 's' : '' }} will receive at least {{ minTrafficPct }}% of overall traffic
        </h6>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EpsilonGreedySelection',
  props: {
    value: Number,
    numExperiences: Number,
    egEnabled: Boolean
  },
  computed: {
    minTrafficPct: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    maxTrafficPct() {
      return Math.floor(100 / this.numExperiences);
    },
    minTrafficRating() {
      if (this.minTrafficPct >= this.maxTrafficPct * 0.9) {
        return 'Most Conservative';
      } else if (this.minTrafficPct >= this.maxTrafficPct * 0.62) {
        return 'Conservative';
      } else if (this.minTrafficPct >= this.maxTrafficPct * 0.40) {
        return 'Moderate';
      } else if (this.minTrafficPct >= this.maxTrafficPct * 0.12) {
        return 'Aggressive';
      } else {
        return 'Most Aggressive';
      }
    },
    isEG: {
      get() {
        return this.egEnabled;
      },
      set(val) {
        this.$emit('update:egEnabled', val);
      }
    }
  },
  watch: {
    isEG(bool) {
      if (!bool) {
        this.minTrafficPct = 0;
      }
    }
  },
  created() {
    if (this.minTrafficPct > 0) {
      this.isEG = true;
    }
  }
};
</script>
