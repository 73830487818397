<template>
  <div class="card">

    <div
      class="card-content"
    >
      <div class="is-fullhd columns">
        <div class="column is-two-fifths-fullhd">
          <h6 class="title is-6">Placement Details</h6>

          <table class="table is-bordered is-hoverable is-fullwidth is-narrow">
            <tr>
              <th>Name</th>
              <td>{{ placement.name }}</td>
            </tr>
            <tr>
              <th>ID</th>
              <td>
                {{ placement.placementId }}
              </td>
            </tr>
            <tr v-if="placement.location">
              <th>Location</th>
              <td>{{ placement.location }}</td>
            </tr>
            <tr v-if="placement.previewUrl">
              <th>Preview URL</th>
              <td><a
                :href="placement.previewUrl"
                target="_blank"
              >{{ placement.previewUrl }}</a></td>
            </tr>
            <tr v-if="placement.urlMatch">
              <th>URL Match</th>
              <td><code>{{ placement.urlMatch }}</code></td>
            </tr>
            <tr v-if="placement.selector">
              <th>Selector</th>
              <td><code>{{ placement.selector }}</code></td>
            </tr>
            <tr v-if="placement.createdAt">
              <th>Created At</th>
              <td>{{ formatTime(placement.createdAt) }}</td>
            </tr>
          </table>
        </div>

        <div
          v-if="assets.length"
          class="column"
        >
          <h6 class="title is-6">Asset Details</h6>

          <b-table
            ref="table"
            :data="assets"
            :paginated="(assets.length > 5 ? true : false)"
            per-page="5"
            narrowed
            detailed
            detail-key="assetId"
            :show-detail-icon="true"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            class="box"
          >

            <b-table-column
              v-slot="props"
              field="name"
              label="Asset Name"
              sortable
            >
              <router-link :to="`/assets/${props.row.assetId}`">
                {{ props.row.name }}
              </router-link>
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="previewUrl"
              label="Preview Asset"
              width="123"
              class="has-text-centered"
              centered
            >
              <a
                :href="preview(props.row.assetId)"
                target="_blank"
                aria-label="Preview"
                class="icon has-text-info"
                :title="`Preview ${props.row.name}`"
              >
                <i
                  class="fa fa-external-link"
                  aria-hidden="true"
                ></i>
              </a>
            </b-table-column>

            <template
              slot="detail"
              slot-scope="props"
            >
              <span v-if="props.row.tags && props.row.tags.length">
                <i class="fa fa-tags" />
                <b-taglist class="is-inline">
                  <b-tag
                    v-for="(tag, i) in props.row.tags"
                    :key="i"
                    style="margin-bottom: 0px;"
                  >{{ tag }}</b-tag>
                </b-taglist>
              </span>
              <em v-else>
                No tags were found
              </em>
            </template>
          </b-table>
        </div> <!-- /asset details -->
        <div
          v-else
          class="column"
        >
          <h6 class="title is-6">No Assets For This Placement</h6>
        </div>
      </div><!-- /.columns -->
    </div><!-- /.card-content -->
  </div>
</template>

<script>
import { formatTime } from '@/modules/utilities';

export default {
  name: 'Placement',
  props: {
    placement: {
      type: Object,
      required: true
    },
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      assets: [],
      isExpanded: false
    };
  },
  computed: {
    previewUrl() {
      let previewUrl = this.placement.previewUrl || this.$store.getters.siteDomain;

      if (previewUrl.indexOf('http') === -1) {
        previewUrl = `http://${previewUrl}`;
      }

      previewUrl = (previewUrl.indexOf('?') !== -1)
        ? previewUrl += '&'
        : previewUrl += '?';

      return `${previewUrl}!preamp=${this.placement.placementId}.`;
    }
  },
  created() {
    this.assets = this.placement.assets;
  },
  methods: {
    preview(assetId) {
      return `${this.previewUrl}${assetId}`;
    },
    // Format date time
    formatTime,
    handleChecked() {
      this.$emit('checked', this.placement.placementId);
    }
  }
};
</script>

<style lang="scss" scoped>
.tag {
  margin-left: 15px;
}
</style>
