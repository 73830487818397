<template>
  <b-modal
    v-model="isCreateEditFolderModalActive"
    :width="modalWidth"
    :data-cy-test="isEditing ? 'folders-modal-edit' : 'folders-modal-create'"
  >
    <div class="card">
      <h2 class="folder-modal__title" data-cy-test="folders-modal-title">
        {{ modalTitle }}
      </h2>
      <b-field
        label="Name"
        :type="hasErrors ? 'is-danger' : ''"
        :message="errorMessage"
      >
        <b-input
          v-model="newFolderName"
          placeholder="Folder Name"
          maxlength="64"
          data-cy-test="folders-modal-input-name"
        ></b-input>
      </b-field>
      <div class="level-left">
        <button
          class="button is-info"
          :disabled="!newFolderName"
          data-cy-test="folders-modal-submit-button"
          @click.stop="handleSubmitButton"
        >
          {{ buttonText }}
        </button>
        <button
          class="button cancel is-ghost"
          data-cy-test="folders-modal-cancel-button"
          @click.stop="handleCancel"
        >
          Cancel
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'CreateEditFolderModal',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    folder: {
      type: Object,
      required: false
    },
    isEditing: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      newFolderName: this.folder.name,
      hasErrors: false,
      errorMessage: ''
    };
  },
  computed: {
    isCreateEditFolderModalActive: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    modalTitle() {
      return this.isEditing ? `Edit "${this.truncatedFolderName}" Folder` : 'New Folder';
    },
    buttonText() {
      return this.isEditing ? 'Update Folder' : 'Create Folder';
    },
    truncatedFolderName() {
      if (this.folder && this.folder.name && this.folder.name.length > 32) {
        return this.folder.name.substring(0, 30) + '...';
      }

      return this.folder.name;
    },
    modalWidth() {
      return this.isEditing ? '600px' : '400px';
    }
  },
  methods: {
    handleCancel() {
      this.isCreateEditFolderModalActive = false;
    },
    resetModalErrors() {
      this.hasErrors = false;
      this.errorMessage = '';
    },
    async handleEditFolder() {
      this.resetModalErrors();
      // if the name hasn't changed at all, don't save it just close the modal
      // the admin server will check for duplicates
      if (this.folder.name === this.newFolderName) {
        this.handleCancel();
        return;
      }

      await this.$root.establishSiteLock();

      const response = await this.$listeners.updateFolder({ folder: this.folder, name: this.newFolderName.trim() });

      if (response && response.status && response.status !== 200) {
        this.hasErrors = true;
        this.errorMessage = response.data;
      } else {
        this.handleCancel();
      }
    },
    async handleCreateFolder() {
      this.resetModalErrors();
      await this.$root.establishSiteLock();

      const response = await this.$listeners.createFolder(this.newFolderName.trim());

      if (response && response.status && response.status !== 200) {
        this.hasErrors = true;
        this.errorMessage = response.data;
      } else {
        this.handleCancel();
      }
    },
    handleSubmitButton() {
      if (this.isEditing) {
        this.handleEditFolder();
      } else {
        this.handleCreateFolder();
      }
    }
  }
};
</script>
