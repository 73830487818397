<template>
  <div class="margin-top15">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h5 class="title is-5">Audience Tests</h5>
        </div>
      </div><!-- /.level-left -->

      <div class="level-right">
        <div
          v-if="hasRunningTests"
          class="level-item"
        >
          <button
            class="button is-small is-danger"
            @click="pauseAllAudienceTests"
          >
            <span class="icon"><i class="fa fa-pause"></i></span>
            <span>Pause All</span>
          </button>
        </div>
      </div><!-- /.level-right -->
    </div><!-- /.level -->

    <div
      v-if="hasAudienceTests && hasTestData"
      class="bottom12"
    >
      <AudienceTestAdjust
        v-for="test in audienceTests"
        :key="test.testId"
        :audience-test="test"
        :experiences="experiences"
        :is-deletable="true"
        :draft-mode="draftMode"
        :points="pointsAvailable"
        @audience-test-change="updateTest"
        @test-weight-change="num => handleAudienceTestWeightChange(test.testId, num)"
        @delete-audience-test="handleTestDeletion(test.testId)"
      />
    </div>

    <div class="columns select-add-tests">
      <div class="column is-half">
        <b-field expanded>
          <b-autocomplete
            v-model="testsQuery"
            :disabled="!filtered.length > 0"
            placeholder="Select Another Test"
            :open-on-focus="true"
            :max-results="10"
            :data="filtered"
            field="name"
            data-cy-test="select-audience-tests"
            :loading="!hasTestData"
            @select="handleSelectedTest"
          >
            <template slot="empty">No results found</template>
          </b-autocomplete>
        </b-field>
      </div>

      <div class="column is-half">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Total Allocation:</label>
          </div>
          <div
            class="field-body"
            style="max-width: 93px; margin-right: 60px;"
          >
            <div class="field has-addons is-pulled-right">
              <p class="control">
                <input
                  class="input"
                  readonly
                  type="text"
                  data-cy-test="total-allocation"
                  :value="totalTestAllocation"
                >
              </p>
              <p class="control">
                <span
                  class="button is-static"
                  data-cy-test="allocation-percentage"
                >
                  %
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hasLength } from '@/modules/utilities';
import AudienceTestAdjust from '@/components/TestForm/AudienceTestAdjust';
import { bus } from '@/main';

export default {
  name: 'TestSelect',
  components: {
    AudienceTestAdjust
  },
  props: {
    audience: {
      type: Object,
      required: true
    },
    experiences: {
      type: Array,
      required: true
    },
    siteId: {
      type: String,
      required: true
    },
    draftMode: {
      type: Boolean
    }
  },
  data() {
    return {
      testsQuery: '',
      tests: null
    };
  },
  computed: {
    hasTestData() {
      return hasLength(this.tests);
    },
    hasAudienceTests() {
      return hasLength(this.audience.tests);
    },
    hasRunningTests() {
      return this.hasAudienceTests && hasLength(this.audienceTests.find(t => t.isPaused === 0));
    },
    filtered() {
      // if there is not a query, return all tests
      if (!hasLength(this.testsQuery)) return this.availableTests;
      // create a regex for the query and return any matches
      return this.availableTests.filter(test => test.name.match(new RegExp(this.testsQuery, 'gi')) !== null);
    },
    audienceTests() {
      return !hasLength(this.audience.tests) ? [] : this.audience.tests;
    },
    audienceTestIds() {
      return !hasLength(this.audience.tests) ? [] : this.audience.tests.map(test => test.testId);
    },
    availableTests() {
      let result = [];
      if (this.hasTestData) {
        if (this.hasAudienceTests) {
          result = this.tests.filter((test) => {
            return this.audienceTestIds.includes(test.testId) === false;
          });
        } else {
          result = this.tests;
        }
      }
      return result;
    },
    // Points that can be distributed to tests
    pointsAvailable() {
      return 100 - this.totalTestAllocation > -1 ? 100 - this.totalTestAllocation : 0;
    },
    // Return allocated visitor percentage
    totalTestAllocation() {
      if (!this.audience.tests || !this.audience.tests.length) return 0;

      return this.audience.tests
        .map(test => test.visitorsPct)
        .reduce((curr, next) => curr + next);
    }
  },
  created() {
    this.getTestList();
  },
  updated() {
    if (this.filtered.length === 0) {
      this.testsQuery = '';
    }
  },
  methods: {
    handleSelectedTest(test) {
      // sometimes this handler will fire with no test
      if (test) {
        test.isPaused = 0;
        test.visitorsPct = 0;
        if (!Array.isArray(this.audience.tests)) this.audience.tests = [];
        this.audience.tests.push(test);
        this.$emit('tests-updated', this.audience.tests);
      }
    },
    handleTestDeletion(testId) {
      this.audience.tests = this.audience.tests.filter(function (test) {
        return test.testId !== testId;
      });
      this.$emit('tests-updated', this.audience.tests);
    },
    /**
     * Handle an updated test
     * @param {Object} test - updated test info
     */
    updateTest(test) {
      for (let i = 0; i < this.audience.tests.length; i++) {
        if (this.audience.tests[i].testId === test.testId) {
          this.audience.tests[i] = test;
          break;
        }
      }
      this.$emit('tests-updated', this.audience.tests);
    },
    formatExperiences(experiences) {
      // mvt tests will have full experience data,
      // return only props that DB will accept
      return experiences.map(function (exp) {
        return {
          experienceId: exp.experienceId,
          weight: exp.weight,
          isControl: exp.isControl || false
        };
      });
    },
    formatTest(testObj) {
      // create a copy, don't mutate tests in user's view
      const test = { ...testObj };
      // explicitly set paused to 1 (true)
      test.isPaused = 1;
      if (test.strategy.type === 'mvt') {
        test.strategy.mvt.experiences = this.formatExperiences(test.strategy.mvt.experiences);
      }
      if (test.versionId) delete test.versionId;

      return test;
    },
    pauseAllAudienceTests() {
      this.$buefy.dialog.confirm({
        title: 'Pausing Tests',
        confirmText: 'Yes',
        type: 'is-warning',
        message: 'Are you sure you want to pause all tests in this audience?',
        onConfirm: () => {
          const tests = this.audience.tests.map((test) => {
            // get base test data and format test for db schema
            return this.formatTest(this.tests.find(t => t.testId === test.testId));
          });
          this.updateManyTests(tests);
          this.$buefy.toast.open({
            message: 'Successfully paused all tests for this audience.',
            type: 'is-success'
          });
        }
      });
    },
    async updateManyTests(tests) {
      try {
        await this.$axios.patch(`/sites/${this.siteId}/tests`, { tests });
        bus.$emit('get-test-data');
      } catch (error) {
        error.title = 'There was a problem pausing all tests.';
        this.$store.commit('error', error);
      }
    },
    async getTestList() {
      try {
        const res = await this.$axios.get(`/sites/${this.siteId}/tests`, {
          params: {
            attributes: ['name', 'testId', 'isPaused']
          }
        });
        this.tests = res.data.tests;
        this.populateAudienceTests(res.data.tests);
      } catch (e) {
        e.title = 'There was a problem retrieving test data.';
        this.$store.commit('error', e);
      }
    },
    populateAudienceTests(allTests) {
      this.audience.tests = this.audience.tests.map(({ testId, visitorsPct }) => {
        return {

          ...allTests.find(test => test.testId === testId),
          visitorsPct
        };
      });
    },
    handleAudienceTestWeightChange(testId, weight) {
      this.audience.tests = this.audience.tests.map(function (t) {
        if (t.testId === testId) {
          t.visitorsPct = weight;
        }

        return t;
      });
    }
  }
};
</script>
