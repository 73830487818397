<template>
  <div class="card bottom10">
    <header class="card-header">
      <div
        class="card-header-title"
        data-cy-test="card-header-title"
      >
        Create Audience Rule List
      </div>
    </header>

    <div class="card-content">
      <div class="columns">
        <div class="column">
          <b-field label="Name">
            <b-input
              v-model.trim="name"
              type="text"
              placeholder="Zip List"
              data-cy-test="rule-list-name"
            ></b-input>
          </b-field>
        </div>
      </div>

      <AudienceRuleListUpload
        :file.sync="file"
        :valid.sync="valid"
        :siteId="siteId"
        :stacked="false"
        :editable="true"
      />
    </div>

    <div class="card-footer">
      <div class="level">
        <div class="level-left">
          <div
            class="level-item"
            data-cy-test="save-button"
          >
            <base-button-save
              :loading="isSavingList"
              :disabled="!validation.valid"
              @click="save"
            />
          </div>

          <div class="level-item">
            <button
              class="button"
              data-cy-test="cancel-button"
              @click="handleFormCancel"
            >
              Cancel
            </button>
          </div>

          <div
            v-if="!validation.valid"
            class="level-item"
          >
            <p
              class="has-text-danger"
              data-cy-test="error-message"
            >
              <span
                class="icon"
              >
                <i class="fa fa-exclamation-circle"></i>
              </span>
              {{ validation.message }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudienceRuleListUpload from '@/components/AudienceRuleLists/AudienceRuleListUpload';
import validate from '@/components/AudienceRuleLists/validation';

export default {
  name: 'AudienceRuleListForm',
  components: {
    AudienceRuleListUpload
  },
  data() {
    return {
      name: '',
      file: null,
      valid: false,
      isLoading: false,
      isSavingList: false
    };
  },
  computed: {
    // The site we will be creating this list for.
    siteId() {
      return this.$store.state.siteId;
    },
    // Validate the form to allow saving.
    validation() {
      return validate(this.name, this.file, this.valid);
    }
  },
  created() {
    this.reset();
  },
  methods: {
    // Save the Rule List in the DB.
    async save() {
      this.isSaving = true;
      try {
        const data = new FormData();
        data.append('csv', this.file);
        data.append('name', this.name);

        await this.$axios.post(`/sites/${this.siteId}/audience-rule-list`, data);
        this.$emit('list-created');
      } catch (err) {
        err.title = 'There was a problem saving the list.';
        this.$store.commit('error', err);
      }
      this.isSaving = false;
    },
    // Reset the form back to it's original state.
    reset() {
      this.file = null;
      this.name = '';
      this.valid = false;
    },
    // Canceling the form resets all values.
    handleFormCancel() {
      this.reset();
      this.$emit('cancel');
    }
  }
};
</script>
