import { LS_KEY } from '../config';

const Storage = (function () {
  const ls = window.localStorage;
  if (!ls.getItem(LS_KEY)) {
    ls.setItem(LS_KEY, JSON.stringify({}));
  }

  return {
    exists(key) {
      return JSON.parse(ls.getItem(LS_KEY))[key] !== undefined;
    },
    get(key) {
      if (this.exists(key) === false) throw Error(`${key} does not exist in storage`);
      return JSON.parse(ls.getItem(LS_KEY))[key] || null;
    },
    set(key, val) {
      const obj = JSON.parse(ls.getItem(LS_KEY));
      obj[key] = val;
      ls.setItem(LS_KEY, JSON.stringify(obj));
    },
    remove(...keys) {
      keys.forEach(key => ls.removeItem(key));
    }
  };
}());

export default Storage;
