<template>
  <div
    class="box"
    style="height: 100%; margin-bottom: 0;"
  >
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h5 class="title is-5">{{ title }} Breakdown</h5>
        </div>
      </div><!-- /.level-left -->

      <div class="level-right">
        <div class="level-item">
          <LookbackRangePicker
            @format-selection="val => hasNumbersViewSelected = val"
            @selection="val => lookbackRange = val"
            @refresh="getInsights"
          />
        </div>
      </div><!-- /.level-right -->
    </div>

    <div class="is-relative">
      <pie-chart
        :data="chartData"
        :colors="colors"
        :suffix="hasNumbersViewSelected ? ' Decisions Created' : '%'"
        thousands=","
        :messages="{ 'empty': 'No data found for this time range.' }"
      ></pie-chart>

      <table
        class="table is-narrow is-fullwidth"
        style="margin-top: 10px;"
      >
        <thead>
          <tr>
            <th>{{ tableHeading }}</th>
            <th class="has-text-right">{{ hasNumbersViewSelected ? '' : '% of' }} Decisions Created</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in tableData"
            :key="index"
          >
            <td>{{ item.prop }}</td>
            <td class="has-text-right">
              <span v-if="item.value < 1">&lt;1</span>
              <span v-else>{{ numberWithCommas(item.value) }}</span>
              <span v-if="!hasNumbersViewSelected">%</span>
            </td>
          </tr>
        </tbody>
      </table>

      <b-loading
        :active.sync="isLoading"
        :is-full-page="false"
      ></b-loading>
    </div>
  </div>
</template>

<script>
import { fireInsightsEvent } from '@/events/events';
import { numberWithCommas } from '@/modules/utilities';
import LookbackRangePicker from '@/components/Insights/LookbackRangePicker';

export default {
  name: 'BreakdownChart',
  components: {
    LookbackRangePicker
  },
  props: {
    siteId: {
      type: String,
      required: true
    },
    payload: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    tableHeading: {
      type: String
    }
  },
  data() {
    return {
      lookbackRange: null,
      rawChartData: null,
      totalHits: 0,
      colors: ['#324d5a', '#42b09d', '#f0cc61', '#e37f4f', '#df6153'],
      // flags
      isLoading: false,
      hasNumbersViewSelected: false
    };
  },
  computed: {
    // returns insights data in percent or number format
    tableData() {
      const result = [];

      if (!this.rawChartData) return result;

      for (const key in this.rawChartData) {
        const dataPoint = {
          prop: key,
          value: this.rawChartData[key]
        };

        if (dataPoint.value && this.hasNumbersViewSelected === false) {
          dataPoint.value = this.getPercent(dataPoint.value, this.totalHits);
        }

        result.push(dataPoint);
      }

      return result.sort(function (a, b) {
        return b.value - a.value;
      });
    },
    // modifies the table data to pass to the chart
    chartData() {
      const result = {};

      if (!this.tableData.length) return result;

      for (const item of this.tableData) {
        result[item.prop] = item.value;
      }

      return result;
    },
    trackingText() {
      if (!this.lookbackRange) return '';

      return `${this.lookbackRange.toUpperCase()} | ${this.hasNumbersViewSelected ? '#' : '%'} | ${this.title.toUpperCase()}`;
    }
  },
  watch: {
    siteId(val) {
      if (val) {
        this.getInsights();
      }
    },
    lookbackRange(newRange) {
      if (newRange) {
        this.getInsights();
      }
    },
    trackingText(newText) {
      fireInsightsEvent(newText);
    }
  },
  methods: {
    numberWithCommas,
    async getInsights() {
      if (!this.lookbackRange || !this.payload) return;

      this.isLoading = true;

      try {
        const res = await this.$axios.post(`/sites/${this.siteId}/insights/breakdowns/${this.lookbackRange}`, {
          filters: this.payload
        });

        this.rawChartData = res.data.counts;
        this.totalHits = res.data.total;
      } catch (error) {
        this.$store.commit('error', error);
      }

      this.isLoading = false;
    },
    getPercent(num, total) {
      return Math.round((num / total) * 100);
    }
  }
};
</script>
