<template>
  <b-modal v-model="isModalActive" data-cy-test="fast-forward-modal">
    <div class="card">
      <UpdateStep
        v-show="step === 1"
        :assetsDiff="assetsDiff"
        :baseAsset="baseAsset"
        :duplicateAsset="duplicateAsset"
        :allAssets="allAssets"
        :preampVars="preampVars"
        @nameUpdate="(name) => assetNameChange = name"
        @change="(val) => fastForwardVariables = val"
        @cancel="isModalActive = false"
        @forward="step++"
      />
      <PreviewStep
        v-if="step === 2"
        :assetPlacements="assetPlacements"
        :baseAsset="baseAsset"
        :duplicateAsset="duplicateAsset"
        :allAssets="allAssets"
        :assetNameChange="assetNameChange"
        :fastForwardVariables="fastForwardVariables"
        @back="step--"
        @close="isModalActive = false"
      />
    </div>
  </b-modal>
</template>

<script>
import UpdateStep from './Update';
import PreviewStep from './Preview';

  export default {
    name: 'FastForwardModal',
    components: {
      UpdateStep,
      PreviewStep
    },
    props: {
      value: {
        type: Boolean,
        required: true
      },
      assetsDiff: {
        type: Object,
        required: true
      },
      baseAsset: {
        type: Object,
        required: true
      },
      duplicateAsset: {
        type: Object,
        required: true
      },
      allAssets: {
        type: Array,
        required: true
      },
      preampVars: {
        type: Object,
        required: true
      },
      assetPlacements: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        step: 0,
        fastForwardVariables: {},
        assetNameChange: ''
      };
    },
    computed: {
      isModalActive: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    },
    created() {
      this.step = this.assetsDiff.variables ? 1 : 2;
    }
  };
</script>
