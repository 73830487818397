<template>
  <div class="experience">
    <div class="main-view-header">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div
              class="breadcrumb is-medium"
              aria-label="breadcrumbs"
            >
              <ul>
                <li><router-link to="/experiences" data-cy-test="header-experiences">Experiences</router-link></li>
                <li class="is-active">
                  <router-link
                    v-if="experience
                      && experience.experienceId
                      && (experience.name || experience.experienceNumber)"
                    :to="`/experiences/${experience.experienceId}`"
                    data-cy-test="header-experiences-name"
                  >
                    {{ experience.name || `Experience #${experience.experienceNumber}` }}
                  </router-link>
                  <a
                    v-else
                    href="javascript:void(0)"
                  >{{ isLoading ? 'Loading...' : 'Unknown Experience' }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main-view-content expanded-header has-pagination-footer">

      <Experience
        v-if="$store.state.siteId"
        :is-editable="true"
      />

      <b-loading
        :active.sync="isLoading"
        :is-full-page="false"
      />
    </div><!-- /.main-view-content -->
  </div>
</template>

<script>
import Experience from '@/components/Experiences/Experience';
import { sync } from 'vuex-pathify';

export default {
  name: 'Experiences',
  components: {
    Experience
  },
  computed: {
    experience: sync('experiences/focused'),
    isLoading: sync('experiences/isLoading'),
    hasLock() {
      // Check if the user has a site lock
      return this.$store.getters.siteLockOwner;
    }
  },
  beforeDestroy() {
    // Reset the focused experience
    this.experience = {};
  }
};
</script>
