<template>
  <div class="card is-expandable">
    <header
      class="card-header"
      :class="{ 'is-expanded': isExpanded }"
      @click.stop="toggleRegion"
    >
      <div class="card-header-title">
        {{ region.name }}
      </div>

      <div class="card-header-icon">
        <span class="icon">
          <i class="fa" :class="(isExpanded) ? 'fa-angle-up' : 'fa-angle-down'"></i>
        </span>
      </div>
    </header><!-- /.card-header -->

    <div v-if="isExpanded" class="card-content">
      <div class="columns">
        <div class="column is-half">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <p>
                  <strong>Decision Servers</strong>
                </p>
              </div>

              <RegionServer
                v-for="ds in region.decisionServers"
                :key="`${region.name}-${ds}`"
                :server="ds"
                :site-id="selectedSite"
                :is-disabled="region.decisionServers.length === 1"
                @remove-server="removeDecisionServer"
              />
            </div>
          </article>

          <article class="media">
            <div class="media-content">
              <b-field grouped>
                <b-field expanded>
                  <b-input v-model="newDecisionServer" placeholder="Add a new domain. Ex: api.development.preamp.io"></b-input>
                </b-field>

                <button class="button is-success" :disabled="!isValidServer" @click="addDecisionServer">
                  <i class="fa fa-plus"></i>
                </button>
              </b-field>
            </div>
          </article>
        </div>
      </div>
    </div><!-- /.card-content -->

    <div v-if="isExpanded" class="card-footer">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <base-button-save
              :disabled="!hasChanges"
              :loading="isUpdatingRegion"
              @click="updateRegion"
            />
          </div>

          <div class="level-item">
            <button class="button" @click.stop="reset">Cancel</button>
          </div>
        </div><!-- /.level-left -->
      </div><!-- /.level -->
    </div>
  </div><!-- /.card -->
</template>

<script>
import RegionServer from '@/components/Regions/RegionServer';

export default {
  name: 'Region',
  components: {
    RegionServer
  },
  props: {
    initialRegion: {
      type: Object,
      required: true
    },
    regionNames: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      region: {
        name: '',
        decisionServers: []
      },
      newDecisionServer: null,
      isExpanded: false,
      isUpdatingRegion: false
    };
  },
  computed: {
    // Check user status
    isSuper() {
      return this.$store.getters.isSuperUser;
    },
    selectedSite() {
      return this.$store.state.siteId;
    },
    // hasChanges returns the differences between the initial decision server list and the current list
    // following any edits. It filters out shared items from both lists, leaving deletions and additions
    hasChanges() {
      return this.initialRegion.decisionServers
        .filter(a => !this.region.decisionServers.includes(a))
        .concat(this.region.decisionServers.filter(b => !this.initialRegion.decisionServers.includes(b))).length !== 0;
    },
    isValidServer() {
      return this.newDecisionServer && this.newDecisionServer.length && !this.region.decisionServers.includes(this.newDecisionServer);
    }
  },
  created() {
    this.reset();
  },
  methods: {
    toggleRegion() {
      this.isExpanded = !this.isExpanded;
    },
    async updateRegion() {
      this.isUpdatingRegion = true;

      try {
        await this.$axios.put(`/regions/${this.initialRegion.name}`, {
          name: this.region.name,
          decisionServers: this.region.decisionServers
        });

        // send success notification
        this.$buefy.toast.open({
          message: `Successfully updated the ${this.region.name} region`,
          type: 'is-success'
        });

        // tell parent to refresh region data so updates are shown
        this.$emit('updatedRegion');
      } catch (err) {
        this.$store.commit('error', err, 'Could not update region');
      }

      this.isUpdatingRegion = false;
    },
    addDecisionServer() {
      this.region.decisionServers.push(this.newDecisionServer);
      this.newDecisionServer = null;
    },
    removeDecisionServer(serverName) {
      this.region.decisionServers = this.region.decisionServers.filter(server => server !== serverName);
    },
    reset() {
      this.newDecisionServer = null;
      this.region.name = this.initialRegion.name;
      this.region.decisionServers = this.initialRegion.decisionServers.slice(0);
    }
  }
};
</script>
