<template>
  <div
    class="card experience"
    :class="{ 'experience--control': experience.isControl }"
  >
    <div class="card-content">
      <div class="tabs">
        <ul>
          <li :class="{ 'is-active': activeTab === 0}"><a
            href="javascript:void(0)"
            data-cy-test="details-tab"
            @click="activeTab = 0;"
          >Details</a></li>
          <li
            v-if="hasInsights"
            :class="{ 'is-active': activeTab === 1}"
          ><a
            href="javascript:void(0)"
            data-cy-test="Insights-tab"
            @click="activeTab = 1;"
          >Insights</a></li>
        </ul>
      </div>
      <section v-if="activeTab === 0">
        <div class="columns">
          <div class="column is-two-fifths">
            <h6 class="title is-6" data-cy-test="experience-details-title">Experience Details</h6>

            <table class="table is-bordered is-hoverable is-fullwidth is-narrow">
              <tr>
                <th>Name</th>
                <td>
                  <span data-cy-test="name">{{ (experience.name) ? experience.name : 'N/A' }}</span>
                </td>
              </tr>
              <tr>
                <th data-cy-test="number">Number</th>
                <td>{{ experience.experienceNumber }}</td>
              </tr>
              <tr>
                <th data-cy-test="id">
                  <span>ID</span>
                  <b-tooltip
                    label="Click on the ID to view experience details."
                    position="is-right"
                    type="is-light"
                    animated
                  >
                    <span class="icon has-text-info">
                      <i class="fa fa-info-circle"></i>
                    </span>
                  </b-tooltip>
                </th>
                <td
                  v-clipboard="() => experience.experienceId"
                  v-clipboard:success="handleClipboardSuccess"
                  class="u-pointer"
                >
                  <b-tooltip
                    label="copy to clipboard"
                    type="is-light"
                  >
                    {{ experience.experienceId }}
                  </b-tooltip>
                </td>
              </tr>
              <tr>
                <th data-cy-test="created-at">Created At</th>
                <td>{{ formatTime(experience.createdAt) }}</td>
              </tr>
              <tr>
                <th data-cy-test="preview-url">Preview URL</th>
                <td>
                  <a
                    :href="previewUrl"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {{ previewUrl }}
                  </a>
                </td>
              </tr>
              <tr v-if="experience.archivedAt">
                <th data-cy-test="archived-at">Archived At</th>
                <td>{{ formatTime(experience.archivedAt) }}</td>
              </tr>
              <tr v-if="experience.archivedBy">
                <th>Archived By</th>
                <td data-cy-test="archived-by">{{ experience.archivedBy }}</td>
              </tr>
            </table>

            <section v-if="experience.tags && experience.tags.length">
              <b-field label="Tags">
                <b-taginput
                  v-model="experience.tags"
                  disabled
                />
              </b-field>
            </section>
          </div><!-- /.column -->

          <div class="column">
            <h6 class="title is-6" data-cy-test="test-details-title">Test Details</h6>
            <div v-if="tests.length">
              <table class="table is-bordered is-fullwidth is-narrow">
                <thead>
                  <th>
                    <span>Name</span>
                    <b-tooltip
                      label="Click on an active test name to view test details."
                      position="is-right"
                      type="is-light"
                      animated
                    >
                      <span class="icon has-text-info">
                        <i class="fa fa-info-circle"></i>
                      </span>
                    </b-tooltip>
                  </th>
                  <th>Status</th>
                </thead>
                <tbody>
                  <tr
                    v-for="test in tests"
                    :key="test.testId"
                  >
                    <td>
                      <router-link
                        v-if="!test.ended"
                        :to="`/tests`"
                      >
                        {{ test.name }}
                      </router-link>
                      <span
                        v-else
                        class="is-disabled"
                      >
                        <span class="icon has-text-warning">
                          <i class="fa fa-archive"></i>
                        </span>
                        {{ test.name }}
                      </span>
                    </td>
                    <td>
                      <span
                        class="tag"
                        :class="{
                          'is-warning': test.isPaused && !test.ended,
                          'is-danger': test.ended,
                          'is-success': test.isActive
                        }"
                      >
                        {{ getTestStatus(test).status }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <article class="message top15">
                <div class="message-body" data-cy-test="message-body">
                  <p>
                    This experience is not in any Tests.
                  </p>
                </div>
              </article>
            </div>

            <article
              v-if="!experienceArchivable"
              class="message is-warning top15"
            >
              <div class="message-body">
                <strong>Archiving</strong>
                <p v-if="experience.isChampion">
                  This experience is a champion for an Audience. To archive it, remove it as champion from that audience.
                </p>
                <p v-else>
                  This experience belongs to one or more unresolved tests. To archive it, either remove this experience from those tests, or end the tests. See test list above.
                </p>
              </div>
            </article>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <section
              v-if="experience.assetPlacements && experience.assetPlacements.length"
              id="placement-details"
            >
              <h6 class="title is-6" data-cy-test="placement-details-title">Placement Details</h6>
              <table class="table is-bordered is-fullwidth is-narrow bottom20">
                <thead>
                  <th>
                    <span>Placement</span>
                    <b-tooltip
                      label="Click on the placement name to view placement details."
                      position="is-right"
                      type="is-light"
                      animated
                    >
                      <span class="icon has-text-info">
                        <i class="fa fa-info-circle"></i>
                      </span>
                    </b-tooltip>
                  </th>
                  <th>Asset</th>
                </thead>
                <tbody>
                  <tr
                    v-for="(ap, i) in experience.assetPlacements"
                    :key="i"
                  >
                    <td>
                      <p
                        v-if="ap.placement.archived"
                        class="is-disabled"
                        title="Archived"
                      >
                        <span class="icon has-text-warning">
                          <i class="fa fa-archive"></i>
                        </span>
                        <span>{{ ap.placement.name }}</span>
                      </p>
                      <router-link
                        v-else
                        :to="`/placements/${ap.placement.placementId}`"
                        data-cy-test="placement-name"
                      >
                        {{ ap.placement.name }}
                      </router-link>
                    </td>
                    <td>
                      <p
                        :class="{ 'is-disabled': ap.asset.archived }"
                        title="Archived"
                      >
                        <span
                          v-if="ap.asset.archived"
                          class="icon has-text-warning"
                        >
                          <i class="fa fa-archive"></i>
                        </span>
                        <span data-cy-test="asset-name">{{ ap.asset.name }}</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>

            <section
              v-if="experience.addOns &&
                Object.keys(experience.addOns).length"
              class="add-ons"
            >
              <h6 class="title is-6" data-cy-test="experience-add-ons-title">Experience Add-Ons</h6>
              <table class="table is-bordered is-fullwidth is-narrow bottom0">
                <thead>
                  <th>
                    <span>Add-On Type</span>
                  </th>
                  <th>
                    <span>Add-On Name</span>
                    <b-tooltip
                      label="Click on the add-on name to view it's details."
                      position="is-right"
                      type="is-light"
                      animated
                    >
                      <span class="icon has-text-info">
                        <i class="fa fa-info-circle"></i>
                      </span>
                    </b-tooltip>
                  </th>
                </thead>
                <tbody>
                  <tr
                    v-for="(id, type) in experience.addOns"
                    :key="id"
                  >
                    <td>
                      <p>
                        <span data-cy-test="add-on-type">{{ type }}</span>
                      </p>
                    </td>
                    <td>
                      <router-link :to="`/add-ons/${id}`" data-cy-test="add-on-name">
                        {{ addOnsMap[id].name }}
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>

            <article
              v-if="hasArchivedDependencies > 0"
              class="message is-warning top15"
            >
              <div class="message-body">
                <strong>Unarchiving</strong>
                <p>
                  All placements and assets must be unarchived via the CDK before being able to unarchive this experience.
                </p>
              </div>
            </article>
          </div>
        </div>


      </section>

      <section v-if="hasInsights && activeTab === 1">
        <IndividualHitsOverTime
          :item-name="experience.name"
          item-type="Experience"
          :payload="{ 'experienceId.keyword': experience.experienceId }"
          :siteId="siteId"
        />
      </section>
    </div><!-- /.card-content -->

    <!-- Edit Controls -->
    <footer
      v-if="activeTab === 0"
      class="card-footer"
    >
      <div class="level">
        <div class="level-left">
          <div
            v-if="experience.archived === 0"
            class="level-item"
            data-cy-test="edit-button"
          >
            <base-button-edit @click="handleEdit" />
          </div>

          <div
            v-if="experience.archived === 0"
            class="level-item"
            data-cy-test="duplicate-button"
          >
            <base-button-duplicate @click="duplicateExperience" />
          </div>
        </div><!-- /.level-left -->

        <div class="level-right">
          <div class="level-item">
            <button
              v-if="experience.archived === 0"
              id='btn-experience-archive'
              :disabled="!experienceArchivable || !userCanEdit"
              class="button is-warning"
              data-cy-test="archive-button"
              @click="handleArchiveExperience"
            >
              <span class="icon"><i class="fa fa-archive"></i></span>
              <span>Archive</span>
            </button>
            <button
              v-else
              :disabled="hasArchivedDependencies > 0"
              class="button is-warning"
              data-cy-test="unarchive-button"
              @click="handleUnarchiveExperience"
            >
              <span class="icon"><i class="fa fa-archive"></i></span>
              <span>Unarchive</span>
            </button>
          </div>
        </div><!-- /.level-right -->
      </div><!-- /.level -->
    </footer>
  </div><!-- /.card -->
</template>

<script>
import IndividualHitsOverTime from '@/components/Insights/IndividualHitsOverTime';
import { bus } from '@/main';
import { formatTime } from '@/modules/utilities';
import { sync } from 'vuex-pathify';

export default {
  name: 'Experience',
  components: {
    IndividualHitsOverTime
  },
  data() {
    return {
      activeTab: 0,
      archived: false,
      isExpanded: false,
      isSavingExperience: false,
      testsMap: {}
    };
  },
  computed: {
    experience: sync('experiences/focused'),
    allTests: sync('tests/list'),
    allInsights: sync('experiences/insights'),
    allAddOns: sync('addOns/list'),
    experienceToTests: sync('experiences/experienceToTests'),
    addOnsMap() {
      const map = {};
      this.allAddOns.forEach(addOn => map[addOn.addOnId] = addOn);
      return map;
    },
    tests() {
      const result = [];
      if (this.experienceToTests[this.experience.experienceId]) {
        this.experienceToTests[this.experience.experienceId].forEach((testId) => {
          if (this.testsMap[testId]) {
            result.push(this.testsMap[testId]);
          }
        });
      }
      return result;
    },
    experienceNames() {
      return this.$store.getters['experiences/allExperienceNames'];
    },
    hasInsights() {
      return this.$store.getters.isInsightsAvailable;
    },
    isSuperUser() {
      return this.$store.getters.isSuperUser;
    },
    isArchived() {
      return this.experience.archived === 1;
    },
    siteId() {
      return this.$store.state.siteId;
    },
    userCanEdit() {
      return this.$store.getters.userCanEdit;
    },
    previewUrl() {
      return this.experience.previewUrl ? `${this.experience.previewUrl}?exp=${this.experience.experienceNumber}` : `https://${this.$store.getters.siteDomain}?exp=${this.experience.experienceNumber}`;
    },
    // See if the experience depends on anything that has been archived
    hasArchivedDependencies() {
      if (
        !this.experience
        || !this.experience.assetPlacements
        || !this.experience.assetPlacements.length
        || !this.experience.experienceId
        || this.experience.experienceId === '0'
      ) {
        return false;
      }

      return this.experience.assetPlacements.filter(function (ap) {
        return (ap.asset.archived || ap.placement.archived);
      }).length;
    },
    // Check if the user has a site lock
    hasLock() {
      return this.$store.getters.siteLockOwner;
    },
    // Check if the experience can be archived
    experienceArchivable() {
      return !(this.experience.isActive || this.experience.isChampion || !this.canBeArchived());
    },
    allHitData() {
      return this.$store.getters['experiences/allHitData'];
    },
    insights() {
      return this.allHitData[this.experience.experienceId]
        ? this.allHitData[this.experience.experienceId]
        : { percentage: 0, precisePercentage: 0 };
    }
  },
  watch: {
    allTests(tests) {
      this.$store.commit('experiences/updateExperienceTestMap', tests);

      const testsMap = {};
      for (const test of tests) {
        // Convert array to hash of testId: data
        if (!testsMap[test.testId]) {
          testsMap[test.testId] = test;
        }
      }
      this.testsMap = testsMap;
    }
  },
  created() {
    this.$store.dispatch('tests/getAllTests');
    this.$store.dispatch('experiences/getFocused');
    this.$store.dispatch('addOns/getList');
  },
  methods: {
    handleEdit() {
      this.$root.establishSiteLock().then(() => {
        this.$buefy.dialog.confirm({
          title: 'Editing Experience',
          message: `
            Warning: Editing this experience will impact live tests that include it and may change the statistical outcomes.
            If this experience is used in a JO campaign, read <a href="https://redventures.atlassian.net/wiki/spaces/COHSN/pages/99875487907/Editing+an+Experience">
            this documentation</a> before modifying the experience.
          `,
          confirmText: 'I Understand',
          cancelText: 'Cancel',
          type: 'is-warning',
          hasIcon: true,
          onConfirm: () => {
            this.$router.push(`/experiences/${this.experience.experienceId}/edit`);
          }
        });
      });
    },
    /**
     * Emit element click event
     * @param {Object} e - element click event
     */
    elementClick(e) {
      bus.$emit('elem-click', e);
    },
    /**
     * Sets the experience edits in state
     * @param {Boolean} val - true for add / false for remove
     */
    setExperienceEdits(val) {
      const item = `experience-${this.experience.experienceId}`;
      if (val && this.$store.state.edits.indexOf(item) === -1) {
        this.$store.commit('addEditItem', item);
        return;
      }
      this.$store.commit('removeEditItem', item);
    },
    duplicateExperience() {
      this.$root.establishSiteLock().then(() => {
        this.$router.push(`/experiences/${this.experience.experienceId}/duplicate`);
      });
    },
    handleClipboardSuccess() {
      this.$buefy.toast.open({
        message: 'copied to clipboard',
        type: 'is-success'
      });
    },
    // Archive an Experience
    handleArchiveExperience() {
      this.$root.establishSiteLock().then(() => {
        const identity = (this.experience.name)
          ? this.experience.name
          : this.experience.experienceNumber;

        // break reference to the store state to build payload for action
        const experience = { ...this.experience };
        delete experience.assetPlacements;
        experience.archived = 1;

        // Save prompt to allow for programatic close
        this.archivePrompt = this.$buefy.dialog.confirm({
          title: `Archiving ${identity}`,
          message: `Are you sure you want to archive ${identity}?`,
          confirmText: 'Archive',
          type: 'is-warning',
          hasIcon: true,
          onConfirm: () => {
            this.$store.dispatch('experiences/updateExperience', experience);
          }
        });
      });
    },
    // Unarchive an Experience
    handleUnarchiveExperience() {
      this.$root.establishSiteLock().then(() => {
        const experience = { ...this.experience };
        delete experience.assetPlacements;
        experience.archived = 0;
        this.$store.dispatch('experiences/updateExperience', experience);
      });
    },
    // return test options for status
    getTestStatus(test) {
      if (test.ended) return { status: 'Ended' };
      if (test.isPaused) return { status: 'Paused' };
      if (test.isActive) return { status: 'Active' };
      return { status: 'Inactive' };
    },
    // Test whether an experience is in a test that is not ended
    canBeArchived() {
      for (let i = 0; i < this.tests.length; i++) {
        if (this.tests[i].ended !== 1) {
          return false;
        }
      }
      return true;
    },
    formatTime
  }
};
</script>

<style lang="scss" scoped>
.card-header {

  .card-header-box {
    flex-direction: column;

    .stacked-text-top {
      font-size: 12px;
      line-height: 1;
    }

    .stacked-text-bottom {
      color: darken(#b5b5b5, 10%);
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.6;

      .is-inline-flex {
        align-self: center;
        font-size: 1rem;
      }
    }
  }
}
</style>
