<template>
  <div class="card is-expandable">
    <div
      class="card-header"
      :class="{ 'is-expanded': isExpanded }"
      @click.stop="toggle"
    >
      <div class="card-header-title">
        JSON Diff
      </div>
      <a class="card-header-icon">
        <span class="icon">
          <i
            class="fa"
            :class="(isExpanded) ? 'fa-angle-down' : 'fa-angle-up'"
          ></i>
        </span>
      </a>
    </div>
    <div
      v-if="isExpanded"
      class="card-content"
    >
      <pre
        v-for="(diff, index) in logDiffJson"
        :key="index"
        :class="{
          'added': diff.added,
          'removed': diff.removed
        }"
      ><code>{{ diff.value }}</code></pre>
    </div>
  </div>
</template>

<script>
import { diffJson } from 'diff';

export default {
  name: 'JSON-Diff',
  props: ['diffData'],
  data() {
    return {
      isExpanded: false,
      logDiffJson: null
    };
  },
  watch: {
    // Generate diff when the view is opened
    isExpanded(val) {
      if (!val || this.logDiffJson !== null || this.diffData.next === undefined) return;
      this.logDiffJson = diffJson(this.diffData.prev, this.diffData.next);
    }
  },
  methods: {
    // Open and close the diff
    toggle() {
      this.isExpanded = !this.isExpanded;
    }
  }
};
</script>
