<template>
  <section>
    <div class="main-view-header">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h4
              class="title is-4"
              data-cy-test="title"
            >Audience Rule Lists</h4>
          </div>
        </div>

        <div class="level-right">
          <div class="level-item">
            <button
              class="button is-info"
              :disabled="isCreating || !userCanEdit"
              @click="handleCreateList"
            >
              <span class="icon">
                <i class="fa fa-plus"></i>
              </span>
              <span data-cy-test="create-list-button">Create List</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="main-view-sub-header">
      <div class="tabs is-boxed">
        <ul>
          <li
            v-for="(tab, index) in ['All', 'Active', 'Inactive']"
            :key="index"
            :class="{ 'is-active': tabSelection === tab }"
            :data-cy-test="tab"
            @click="tabSelection = tab"
          >
            <a>{{ tab }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="main-view-content expanded-header has-pagination-footer">
      <AudienceRuleListForm
        v-if="isCreating"
        :siteId="siteId"
        :active="isCreating"
        @list-created="handleListCreated"
        @cancel="isCreating = false"
      />

      <div v-if="ruleLists.length">
        <AudienceRuleList
          v-for="list in filtered"
          :key="list.listId"
          :siteId="siteId"
          :name="list.name"
          :listId="list.listId"
          :audiences="list.audiences"
          @list-updated="getAudienceRuleLists"
          @list-deleted="getAudienceRuleLists"
        />
      </div>

      <article
        v-if="!siteId"
        class="message is-warning"
      >
        <div
          class="message-body"
          data-cy-test="message-body"
        >
          To see Audience Rule Lists, select an account and site in the top navigation.
        </div>
      </article>

      <article
        v-else-if="!isCreating && ruleLists.length === 0"
        class="message is-warning"
      >
        <div
          class="message-body"
          data-cy-test="message-body"
        >
          No Audience Rule Lists were found for this site. Try creating one by entering edit mode.
        </div>
      </article>

      <b-loading
        :active.sync="isLoading"
        :is-full-page="false"
      ></b-loading>
    </div>
  </section>
</template>

<script>
import { bus } from '@/main.js';
import AudienceRuleListForm from '@/components/AudienceRuleLists/AudienceRuleListForm';
import AudienceRuleList from '@/components/AudienceRuleLists/AudienceRuleList';

export default {
  name: 'AudienceRuleLists',
  components: {
    AudienceRuleListForm,
    AudienceRuleList
  },
  data() {
    return {
      isCreating: false,
      tabSelection: 'All',
      isLoading: false,
      ruleLists: []
    };
  },
  computed: {
    siteId() {
      return this.$store.state.siteId;
    },
    hasLock() {
      return this.$store.getters.siteLockOwner;
    },
    userCanEdit() {
      return this.$store.getters.userCanEdit;
    },
    filtered() {
      let rl = this.ruleLists;
      if (rl.length === 0) return rl;

      // Handle tab filtering
      switch (this.tabSelection) {
        case 'Active':
          rl = rl.filter(_rl => _rl.audiences.length > 0);
          break;
        case 'Inactive':
          rl = rl.filter(_rl => _rl.audiences.length === 0);
          break;
        default:
          // no alteration
      }

      return rl;
    }
  },
  created() {
    if (this.siteId) {
      this.getAudienceRuleLists();
    }
    this.registerEventListeners();
  },
  methods: {
    async getAudienceRuleLists() {
      this.isLoading = true;
      try {
        const res = await this.$axios.get(`/sites/${this.siteId}/audience-rule-lists`);
        this.ruleLists = res.data;
      } catch (err) {
        err.title = 'There was a problem getting audience rule lists';
        this.$store.commit('error', err);
      }
      this.isLoading = false;
    },
    handleCreateList() {
      this.$root.establishSiteLock().then(() => {
        this.isCreating = true;
      });
    },
    handleListCreated() {
      this.isCreating = false;
      this.getAudienceRuleLists();
    },
    handleSiteLockLost() {
      this.isCreating = false;
    },
    registerEventListeners() {
      bus.$on('site-lock-lost-user', this.handleSiteLockLost);
      bus.$on('site-changed', this.getAudienceRuleLists);
    }
  }
};
</script>
