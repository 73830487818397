import { render, staticRenderFns } from "./v-icon.vue?vue&type=template&id=721d04e0&scoped=true"
import script from "./v-icon.vue?vue&type=script&lang=js"
export * from "./v-icon.vue?vue&type=script&lang=js"
import style0 from "./v-icon.vue?vue&type=style&index=0&id=721d04e0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "721d04e0",
  null
  
)

export default component.exports