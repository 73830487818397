<template>
  <div class="card is-expandable">
    <div
      class="card-header"
      :class="{ 'is-expanded': isExpanded }"
      @click.stop="isExpanded = !isExpanded"
    >
      <div class="card-header-title no-padding">

        <div
          class="card-header-item right12"
          style="margin-left:12px;"
        >
          {{ log.user }}
        </div>

        <!-- Change Type -->
        <div class="card-header-item">
          <span class="tag">
            {{ log.action.type }}
          </span>
        </div>
      </div>

      <div class="card-header-title card-header-title--end">
        <span>
          {{ log.createdAt }}
        </span>
      </div>

      <!-- Expand Icon -->
      <a class="card-header-icon">
        <span class="icon">
          <i
            class="fa"
            :class="(isExpanded) ? 'fa-angle-up' : 'fa-angle-down'"
          ></i>
        </span>
      </a>
    </div>

    <div
      v-if="isExpanded"
      class="card-content"
    >
      <table class="table is-bordered is-narrow is-fullwidth">
        <tbody>
          <tr
            v-for="(value, prop, index) in next"
            v-if="!prop.match(/createdAt|updatedAt|trafficFlowId|siteId|redirectRulesId|testId|experienceId|hash/)"
            :key="`diff-${index}`"
          >
            <th style="width: 120px">{{ prop }}</th>

            <!-- Traffic Flow Diff -->
            <TrafficFlowDiff
              v-if="prop === 'audiences'"
              :prev="(prev.audiences) ? prev.audiences : []"
              :next="(next.audiences) ? next.audiences : []"
            />

            <!-- Diff Object -->
            <DiffObject
              v-else-if="typeof value === 'object'"
              :prev="prev[prop]"
              :next="next[prop]"
            />

            <!-- New -->
            <td v-else-if="log.action.diff.previous.data">
              <span class="added">{{ value }}</span>
            </td>

            <!-- Diff Properties -->
            <td v-else-if="log.action.diff.previous[prop] !== value">
              <span class="removed">{{ log.action.diff.previous[prop] }}</span>
              <span class="added">{{ value }}</span>
            </td>

            <!-- No Changes -->
            <td v-else>
              {{ value }}
            </td>
          </tr>
        </tbody>
      </table>

      <!-- JSON Diff -->
      <JsonDiff
        :diffData="{
          prev: log.action.diff.previous,
          next: log.action.diff.new
        }"
      />
    </div>
  </div>
</template>

<script>
import JsonDiff from '@/components/History/JsonDiff';
import TrafficFlowDiff from '@/components/History/TrafficFlowDiff';
import DiffObject from '@/components/History/DiffObject';

export default {
  name: 'Log',
  components: {
    JsonDiff,
    TrafficFlowDiff,
    DiffObject
  },
  props: ['log'],
  data() {
    return {
      isExpanded: false
    };
  },
  computed: {
    // Shorthand for Previous Diff
    prev() {
      return this.log.action.diff.previous;
    },
    // Shorthand for Next Diff
    next() {
      return this.log.action.diff.new;
    },
    // Shorthand for type
    type() {
      return this.log.action.entityType;
    }
  }
};
</script>
