<template>
  <div class="card audience-test is-expandable">
    <header
      class="card-header test-header"
      @click="isExpanded = !isExpanded"
    >
      <p class="card-header-title" data-cy-test="audience-test-name">
        {{ test.name }}
      </p><!-- /.card-header-title -->

      <!-- Paused Tag -->
      <div
        v-if="isPaused"
        class="card-header-item"
      >
        <span class="tag is-warning">
          <b-icon
            icon="pause"
            size="is-small"
          ></b-icon>

          <span>Paused</span>
        </span>
      </div>

      <!-- All Tests in a traffic Flow are considered Active -->
      <div class="card-header-item">
        <span class="tag is-success" data-cy-test="test-active-icon">
          Active
        </span>
      </div>

      <!-- Test Type Tag -->
      <div
        v-if="isMVT || isMABExp || isCO"
        class="card-header-item"
      >
        <!-- MVT Tag -->
        <div
          v-if="isMVT"
          class="tags has-addons"
        >
          <span class="tag">
            <b-icon
              icon="sitemap"
              size="is-small"
            ></b-icon>
          </span><!-- /.tag -->
          <span class="tag is-primary" data-cy-test="mvt-exp">MVT</span>
        </div>

        <!-- MAB EXP Tag -->
        <div
          v-if="isMABExp"
          class="tags has-addons"
        >
          <span class="tag">
            <v-icon
              icon="brain"
              class="is-small"
            ></v-icon>
          </span>
          <span class="tag is-dark" data-cy-test="mab-exp">EXP</span>
        </div>

        <!-- Click Optimization Tag -->
        <div
          v-if="isCO"
          class="tags has-addons"
        >
          <span class="tag">
            <v-icon
              icon="bullseye-pointer"
              class="is-small"
            ></v-icon>
          </span>
          <span class="tag is-link" data-cy-test="co-exp">CO</span>
        </div>
      </div><!-- /.card-header-item -->

      <!-- Visitors Percent Tag -->
      <div
        v-if="visitorsPct !== undefined"
        class="card-header-item"
      >
        <span
          class="tags has-addons"
          :disabled="isPaused"
        >
          <span class="tag is-info" data-cy-test="visitor-icon">Visitors</span>
          <span class="tag is-percentage" data-cy-test="visitor-percentage">{{ visitorsPct }}%</span>
        </span>
      </div>

      <!-- Expand/Collapse Arrow -->
      <span
        class="card-header-icon u-pointer"
        aria-label="Expand Test"
      >
        <b-icon
          icon="angle-down"
          size="is-small"
          :class="{ 'is-rotated': isExpanded }"
        ></b-icon>
      </span>
    </header><!-- /.card-header -->

    <div
      v-show="isExpanded"
      class="card-content"
    >
      <section v-if="hasTestData">
        <div class="columns">
          <div class="column">
            <label class="label">Test Details</label>

            <table class="table is-bordered is-hoverable is-narrow is-fullwidth">
              <tr>
                <th>Name</th>
                <td data-cy-test="test-name-in-table">{{ test.name }}</td>
              </tr>
              <tr>
                <th>ID</th>
                <td><router-link :to="`/tests/${test.testId}`">{{ test.testId }}</router-link></td>
              </tr>
              <tr>
                <th>Created At</th>
                <td>{{ formatTime(test.createdAt) }}</td>
              </tr>
              <tr v-if="test.strategy.type">
                <th>Type</th>
                <td data-cy-test="test-type">{{ testTypeText }}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td data-cy-test="test-status">{{ test.isPaused === 1 ? 'Paused' : 'Active' }}</td>
              </tr>
              <tr>
                <th>Persistent</th>
                <td>{{ test.isPersisted === 1 ? 'True' : 'False' }}</td>
              </tr>
              <tr v-if="getMinTrafficPct">
                <th>Minimum Traffic Percentage</th>
                <td> {{ getMinTrafficPct + '%' }}</td>
              </tr>
            </table>
          </div><!-- /.column -->

          <div class="column">
            <section
              v-if="test.description"
              class="bottom20"
            >
              <b-field label="Description">
                <b-input
                  :value="test.description"
                  type="textarea"
                  disabled
                ></b-input>
              </b-field>
            </section>

            <section
              v-if="test.tags && test.tags.length"
              class="bottom20"
            >
              <b-field label="Tags">
                <b-taginput
                  v-model="test.tags"
                  disabled
                ></b-taginput>
              </b-field>
            </section>
          </div>
        </div><!-- /.columns -->

        <div class="columns is-relative">
          <!-- MVT Display -->
          <div
            v-if="isMVT"
            class="column"
          >
            <div class="level">
              <div class="level-left">
                <h6 class="title is-6">Experiences</h6>
              </div>

              <div class="level-right">
                <h6 class="title is-6">
                  Experience Allocation

                  <span :class="{ 'has-text-danger': totalExperienceWeight > 100 }">
                    {{ totalExperienceWeight }}%
                  </span>
                </h6>
              </div>
            </div>

            <NestedExperience
              v-for="experience in mvtExperiences"
              :key="experience.experienceId"
              :experience="experience"
              :allAddOns="addOns"
              :allTests="tests"
              :experienceToTests="experienceToTests"
            />

            <DefaultExperience
              v-if="totalExperienceWeight < 100"
              :points="100 - totalExperienceWeight"
              :editing="false"
            />
          </div><!-- /.column -->

          <!-- Smart Experiences Display -->
          <div
            v-else-if="isMABExp"
            class="column"
          >
            <MabExpDisplay
              :testId="test.testId"
              :dapi="test.strategy.mabExp.dapi"
              :hold-out="test.strategy.mabExp.holdOut"
            />
          </div>

          <!-- Click Optimization Display -->
          <div
            v-else-if="isCO"
            class="column"
          >
            <div class="level">
              <div class="level-left">
                <h6 class="title is-6">Experiences</h6>
              </div>
            </div>

            <NestedExperience
              v-for="experience in coExperiences"
              :key="experience.experienceId"
              :experience="experience"
              :allAddOns="addOns"
              :allTests="tests"
              :experienceToTests="experienceToTests"
              :isExpandable="experience.experienceId === '0' ? false : true"
            />
          </div>

          <b-loading
            :active.sync="isLoading"
            :is-full-page="false"
          ></b-loading>
        </div><!-- /.columns -->
      </section><!-- v-if -->
    </div><!-- /.card-content -->
  </div><!-- /.card -->
</template>

<script>
import has from 'lodash.has';
import { hasLength, formatTime } from '@/modules/utilities';
import NestedExperience from '@/components/Experiences/NestedExperience';
import DefaultExperience from '@/components/DefaultExperience';
import MabExpDisplay from '@/components/Tests/MabExpDisplay';

export default {
  name: 'AudienceTest',
  components: {
    NestedExperience,
    DefaultExperience,
    MabExpDisplay
  },
  props: {
    // initial test contains only: testId, name, strategy.type, isPaused
    initialTest: {
      type: Object,
      required: true
    },
    visitorsPct: {
      type: Number
    },
    experiences: {
      type: Array
    },
    addOns: {
      type: Array
    },
    tests: {
      type: Array
    },
    experienceToTests: {
      type: Object
    }
  },
  data() {
    return {
      test: this.initialTest,
      isExpanded: false,
      isLoading: false
    };
  },
  computed: {
    siteId() {
      return this.$store.state.siteId;
    },
    isPaused() {
      return this.test.isPaused === 1;
    },
    hasTestData() {
      return this.initialTest && this.initialTest.strategy !== undefined;
    },
    isMVT() {
      return this.hasTestData && this.initialTest.strategy.type === 'mvt';
    },
    isMABExp() {
      return this.hasTestData && this.initialTest.strategy.type === 'mabExp';
    },
    isCO() {
      return this.hasTestData && this.initialTest.strategy.type === 'clickOptimization';
    },
    getMinTrafficPct() {
      if (this.isMABExp) {
        return this.initialTest.strategy.mabExp.minTrafficPct;
      }
      if (this.isCO) {
        return this.initialTest.strategy.clickOptimization.minTrafficPct;
      }
      return 0;
    },
    hasMvtExperiences() {
      return (
        this.isMVT
        && has(this.test, 'strategy.mvt.experiences')
        && hasLength(this.test.strategy.mvt.experiences)
      );
    },
    hasCOExperiences() {
      const hasOptions = has(this.test, 'strategy.clickOptimization.options');
      return (
        this.isCO
        // has options or at least one holdout
        && (
          (hasOptions && hasLength(this.test.strategy?.clickOptimization?.options))
        || has(this.test, 'strategy.clickOptimization.holdout.option'))
      );
    },
    mvtExperiences() {
      let result = [];
      if (this.hasMvtExperiences) {
        result = this.test.strategy.mvt.experiences
          .filter(exp => exp.experienceId !== '0')
          .map((exp) => {
            const fullExp = this.experiences.find(e => e.experienceId === exp.experienceId);
            return { ...exp, ...fullExp };
          });
      }
      return result;
    },
    coExperiences() {
      let result = [];
      if (this.hasCOExperiences) {
        result = this.test.strategy.clickOptimization.options
          .map((exp) => {
            const fullExp = this.experiences.find(e => e.experienceId === exp.experienceId);
            return { ...exp, ...fullExp };
          });
      }
      return result;
    },
    totalExperienceWeight() {
      if (!this.hasMvtExperiences) return 0;
      return this.mvtExperiences
        .map(exp => exp.weight)
        .reduce((acc, curr) => acc + curr);
    },
    testTypeText() {
      switch (this.test.strategy.type) {
        case 'clickOptimization':
          return 'Click Optimization';
        case 'mabExp':
          return 'Smart Experience';
        default:
          return 'Multi-Variate';
      }
    }
  },
  watch: {
    isExpanded(expanded) {
      if (expanded === true) {
        this.getTestDetails();
      }
    },
    // any changes to the initial test should reset the local copy
    initialTest(test) {
      this.test = test;
    }
  },
  methods: {
    async getTestDetails() {
      this.isLoading = true;
      try {
        const res = await this.$axios.get(`/sites/${this.siteId}/tests/${this.initialTest.testId}`);

        this.test = res.data.test;
      } catch (error) {
        error.title = 'There was a problem retreiving Test data';
        this.$store.commit('error', error);
      }
      this.isLoading = false;
    },
    formatTime,
    hasLength
  }
};
</script>
