<template>
  <div class="is-relative">
    <b-field>
      <b-select
        v-if="accounts.length > 0"
        v-model="selectedAccount"
        expanded
      >
        <option
          :value="null"
          disabled
        >Which account do you need contact information for?</option>
        <option
          v-for="account in accounts"
          :key="account.accountId"
          :value="account"
        >
          {{ account.name }}
        </option>
      </b-select>
    </b-field>

    <table
      v-if="selectedAccount && adminList.length > 0"
      class="table is-hoverable is-bordered is-fullwidth"
    >
      <thead>
        <tr>
          <th>Administrators for {{ selectedAccount.name }}</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="email in adminList"
          :key="email"
        >
          <td>{{ email }}</td>
        </tr>
      </tbody>
    </table>

    <em v-if="selectedAccount && adminList.length === 0">
      No administrators were found. Please contact the Cohesion team using the instructions in the footer
      of this section for further assistance.
    </em>

    <b-loading
      :active.sync="isLoading"
      :is-full-page="false"
    ></b-loading>
  </div>
</template>

<script>
import axios from 'axios';
import config from '@/config';
import getAppEnv from '@/modules/getAppEnv';

const appEnv = getAppEnv(window.location.host);

const { PREAMP_LIMITED_ACCESS_TOKEN } = config[appEnv];

// create an instance using the limited access token for non-preamp users and users without accounts
const axiosInstance = axios.create({
  baseURL: (appEnv === 'LOCAL') ? 'http://localhost:4001' : window.location.origin,
  headers: { Authorization: `Token ${PREAMP_LIMITED_ACCESS_TOKEN}` }
});

export default {
  name: 'AccountAdminLookup',
  data() {
    return {
      selectedAccount: null,
      accounts: [],
      adminList: [],
      isLoading: false
    };
  },
  watch: {
    selectedAccount(obj) {
      if (!obj) {
        this.adminList = [];
      } else {
        this.getAccountAdmins(obj.accountId);
      }
    }
  },
  mounted() {
    // Get Accounts
    this.getAccounts();
  },
  methods: {
    // Get and assign accounts data
    async getAccounts() {
      this.isLoading = true;

      try {
        const res = await axiosInstance.get('/support/accounts');
        this.accounts = res.data;
      } catch (err) {
        console.log(JSON.stringify(err, null, 2));
        err.title = 'There was a problem getting the list of Preamp accounts.';
        this.$store.commit('error', err);
      }

      this.isLoading = false;
    },
    async getAccountAdmins(accountId) {
      this.isLoading = true;

      try {
        const res = await axiosInstance.get(`/support/admins/${accountId}`);
        this.adminList = res.data;
      } catch (err) {
        err.title = 'There was a problem getting the list of admins for that account';
        this.$store.commit('error', err);
      }

      this.isLoading = false;
    }
  }
};
</script>
