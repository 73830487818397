<template>
  <section class="box columns is-mobile test-metrics">
    <div class="column is-one-third has-text-centered is-flex-direction-column">
      <h6 class="heading is-block">Best Option</h6>

      <div class="is-relative">
        <GaugeChart
          v-if="bestOptionValue !== undefined"
          title="Best Option"
          :min="bestOption.min"
          :max="bestOption.max"
          :value="bestOptionValue"
          unitDescriptor="Win Probability"
        />
        <b-loading
          :active="bestOptionValue === undefined"
          :is-full-page='false'
        ></b-loading>
      </div>

      <h6 class="title">{{ bestOptionExperience ? bestOptionExperience.name : 'N/A' }}</h6>
      <p v-if="bestOptionValue === 0">
        If you have just created this test, it is normal for Best Option to be unavailable for display, as DAPI has not chosen any options yet.
      </p>
    </div>

    <div class="column is-one-third has-text-centered is-flex-direction-column">
      <p class="heading">Should you end this experiment?</p>
      <p class="title has-text-centered top25">{{ recommendation }}</p>
    </div>

    <div class="column is-one-third has-text-centered is-flex-direction-column">
      <h6 class="heading">Value Remaining</h6>
      <div class="is-relative">
        <GaugeChart
          v-if="valueRemainingValue !== undefined"
          title="Value Remaining"
          :min="valueRemaining.min"
          :max="valueRemaining.max"
          :value="(valueRemainingValue > 20) ? 20 : valueRemainingValue"
          unitDescriptor="Value Remaining"
          :colors="['#FFB600', '#448AFF', '#88DD88']"
          :colorThresholds="[5, 10, 15]"
        />
        <b-loading
          :active="valueRemainingValue === undefined"
          :is-full-page='false'
        ></b-loading>
      </div>

      <p v-if="valueRemainingValue" class="help is-italic">
        *As a general rule, statistical risk is sufficiently low and you should feel comfortable ending an experiment when Value Remaining is at 1% or lower.
      </p>
      <p v-else>
        If you have just created this test, it is normal for Value Remaining to begin at 0%, as DAPI has not made any decisions for this test yet.
      </p>
    </div>
  </section>
</template>

<script>
import GaugeChart from '@/components/Insights/GaugeChart.vue';

export default {
  name: 'TestMetrics',
  components: {
    GaugeChart
  },
  props: {
    experiences: {
      type: Array,
      required: true
    },
    agentAnalytics: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      bestOption: {
        experience: null,
        min: 0,
        max: 100
      },
      valueRemaining: {
        min: 0,
        max: 20
      }
    };
  },
  computed: {
    bestOptionExperience() {
      if (!this.agentAnalytics || !this.agentAnalytics.bestOption) return { name: 'Not Available Yet' };

      return this.experiences.find(({ experienceId }) => experienceId === this.agentAnalytics.bestOption);
    },
    bestOptionWinProb() {
      if (!this.agentAnalytics || !this.agentAnalytics.bestOption) return 0;

      return this.agentAnalytics.winProb[this.agentAnalytics.bestOption];
    },
    bestOptionValue() {
      if (!this.agentAnalytics || !this.agentAnalytics.bestOption) return 0;

      return this.formatRatio(this.agentAnalytics.winProb[this.agentAnalytics.bestOption]);
    },
    valueRemainingValue() {
      if (!this.agentAnalytics || typeof this.agentAnalytics.valueRemaining !== 'number') return 0;

      return this.formatRatio(this.agentAnalytics.valueRemaining);
    },
    recommendation() {
      switch (true) {
        case (!this.agentAnalytics || this.agentAnalytics.valueRemaining === undefined):
          return 'No, this experiment has only just begun';
        case this.valueRemainingValue > 20:
          return 'No, this experiment should not be ended';
        case this.valueRemainingValue > 5 && this.valueRemainingValue < 20:
          return 'No, this experiment should not be ended';
        case this.valueRemainingValue >= 1 && this.valueRemainingValue <= 5:
          return 'Possibly. Analysis is needed';
        case this.valueRemainingValue < 1:
          return 'This experiment should be ended';
        default:
          return '';
      }
    }
  },
  methods: {
    formatRatio(ratio) {
      if (ratio === 0) return ratio;

      return Math.round(ratio * 1000) / 10;
    }
  }
};
</script>
