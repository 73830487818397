<template>
  <section>
    <div
      v-for="(account) in accounts"
      :key="`account-${account.accountId}`"
      class="card bottom10"
    >
      <header class="card-header">
        <div class="card-header-title">
          {{ account.name }}
          <span class="tag">{{ roles[account.accountId] }}</span>
        </div>

        <div
          v-if="deleteCheck(account.accountId)"
          class="card-header-icon"
          @click.stop="remove(account.accountId)"
        >
          <span class="icon has-text-danger">
            <i class="fa fa-trash"></i>
          </span>
        </div>
      </header><!-- /.card-header -->
    </div><!-- /.card -->
  </section>
</template>

<script>
export default {
  name: 'UserRoles',
  props: {
    accounts: {
      type: Array,
      required: true
    },
    roles: {
      type: Object,
      required: true
    },
    isUser: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // Check if the user is a super user
    isSuper() {
      return this.$store.getters.isSuperUser;
    },
    // Logged in user account
    user() {
      return this.$store.state.user;
    },
    // Check if the what roles the user is an admin of
    userAdminAccounts() {
      if (!this.user.accountRoles) return [];

      return Object.keys(this.user.accountRoles)
        .filter((account) => {
          return this.user.accountRoles[account].match(/admin/i);
        });
    }
  },
  methods: {
    /**
     * Emit the account to be removed
     * @param {String} id - id of the account to remove
     */
    remove(id) {
      this.$emit('removeRole', id);
    },
    /**
     * Check whether the logged in user can edit this role
     * @param {String} accountId - account to validate
     */
    deleteCheck(accountId) {
      return (
        this.isSuper
        || this.userAdminAccounts.indexOf(accountId) !== -1
        || this.isUser
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.tag {
  margin-left: 10px;
}
</style>
