<template>
  <section>
    <section v-if="!isEditing">
      <h2 class="subtitle is-4">Choose Test Type</h2>
      <b-field>
        <b-radio-button
          v-model="testType"
          native-value="clickOptimization"
          type="is-info"
          :disabled="isSiteHIPAA || isEditing"
          data-cy-test="click-optimization-test-button"
        >
          Click Optimization Test
        </b-radio-button>
        <b-radio-button
          v-model="testType"
          native-value="mabExp"
          type="is-info"
          :disabled="isEditing || !canCreateMabExpTest || isMabExpDisabled"
          data-cy-test="smart-experience-button"
        >
          Smart Experience
        </b-radio-button>
        <b-radio-button
          v-model="testType"
          native-value="mvt"
          type="is-info"
          :disabled="isEditing"
          data-cy-test="multi-variate-test-button"
        >
          Multi-Variate Test
        </b-radio-button>
      </b-field>
      <b-message
        v-if="isMabExpDisabled"
        type="is-danger"
        size="is-small"
      >
        <p class="help">
          <b-icon
            icon="exclamation-triangle"
            size="is-small"
          ></b-icon>&nbsp;
          <strong>[EXP]:</strong>&nbsp;
          <span data-cy-test="error-message">No Tenant I.D. was found in the configuration for this site. This is required to create Smart Experiences.</span>
        </p>
      </b-message>

      <div class="test-form-modal__divider"></div>
    </section>

    <h2
      class="subtitle is-4 bottom0"
      data-cy-test="test-subtitle"
    >Choose Experiences</h2>

    <p class="font13 bottom10"><em>{{ description }}</em></p>

    <div
      v-for="(exp, index) of experiences"
      :key="exp.key"
      :class="{
        'field is-grouped': testType === 'clickOptimization',
        'experience-steps__row-mvt': testType === 'mvt',
        'experience-steps__row-mabExp': testType === 'mabExp'
      }"
    >
      <b-field
        label="Test Experience"
        :expanded="testType === 'clickOptimization'"
        :class="{ 'mr-5': testType !== 'clickOptimization' }"
      >
        <b-autocomplete
          :ref="`autocomplete-${index}`"
          v-model="exp.selected"
          placeholder="Select Test Experience"
          data-cy-test="select-test-experience"
          :data="getExperienceOptions(exp)"
          field="displayName"
          clearable
          expanded
          open-on-focus
          @select="value => changeExperienceId(index, value)"
        >
        </b-autocomplete>
      </b-field>
      <b-field
        :key="testType === 'mvt' ? `mvt-weight.${exp.key}` : `mabExp-weight.${exp.key}`"
        label="Split %"
        data-cy-test="smart-experience-split-percentage"
      >
        <b-input
          v-if="testType === 'mvt'"
          v-model.number="exp.weight"
          :disabled="exp.experienceId === ''"
          type="number"
          required
          min="1"
          max="100"
          placeholder="Percentage"
          data-cy-test="mvt-split-percentage"
          validation-message="Must be a valid percentage"
        />
        <b-input
          v-else-if="testType === 'mabExp' || testType === 'clickOptimization'"
          disabled
          type="text"
          value="Auto"
        />
      </b-field>
      <template v-if="testType === 'mvt'">
        <b-field label="Control">
          <div class="experience-steps__center-contents experience-steps__align-with-fields">
            <b-checkbox
              type="is-info"
              expanded
              :value="exp.isControl"
              data-cy-test="mvt-control-checkbox"
              @input="setControl(index)"
            >
            </b-checkbox>
          </div>
        </b-field>
      </template>
      <b-field v-if="experiences.length > 1">
        <template slot="label">&nbsp;</template>

        <button class="button" @click="removeExperience(index)">
          <b-icon icon="trash" class="is-hover-danger"></b-icon>
        </button>
      </b-field>
    </div>

    <div
      v-if="testType === 'mvt'"
      class="experience-steps__row-mvt"
    >
      <b-field
        label="Default"
        class="mr-5"
      >
        <b-input
          type="text"
          value="Base Site"
          data-cy-test="mvt-default"
          disabled
        />
      </b-field>
      <b-field
        label="Split %"
        class="mr-5"
      >
        <b-input
          type="text"
          :value="defaultExpWeight"
          data-cy-test="mvt-default-split-percentage"
          disabled
        />
      </b-field>
    </div>

    <div>
      <button
        v-if="shouldShowAddExperience"
        class="button is-info is-inverted is-inline-flex"
        data-cy-test="add-experience-hyperlink"
        @click="addExperience"
      >
        <span data-cy-test="add-experience-link">+&nbsp;Add Experience</span>
      </button>
      <div v-else></div> <!-- Account for placing of Total Allocation in next grid position -->
      <div
        v-if="testType === 'mvt'"
        :class="`${totalAllocation > 100 ? 'has-text-danger' : 'has-text-success' }`"
        data-cy-test="mvt-allocation"
      >
        Total Allocation: {{ totalAllocationWithDefault }}%
      </div>
    </div>
    <EpsilonGreedySelection
      v-if="testType === 'mabExp'"
      v-model="mabExp.minTrafficPct"
      class="mt-5 mb-5"
      :numExperiences="experiences.length"
      data-cy-test="smart-exp-checkbox"
      :egEnabled.sync="isEG"
    />
    <EpsilonGreedySelection
      v-if="testType === 'clickOptimization'"
      v-model="clickOptimization.minTrafficPct"
      class="mt-5 mb-5"
      :numExperiences="experiences.length"
      data-cy-test="co-checkbox"
      :egEnabled.sync="isEG"
    />
  </section>
</template>

<script>
import { sync } from 'vuex-pathify';
import EpsilonGreedySelection from '@/components/TestForm/TestFormSteps/mab-exp/EpsilonGreedySelection';

export default {
  name: 'ExperiencesStep',
  components: {
    EpsilonGreedySelection
  },
  props: {
    commonTest: Object,
    mabExp: Object,
    mvt: Object,
    clickOptimization: Object,
    value: Array,
    valid: Boolean,
    egEnabled: Boolean,
    isEditing: Boolean
  },
  data() {
    return {
      isEG: false,
      defaultSiteSEPos: false // Hold the position of selected default site in SE tests
    };
  },
  computed: {
    siteId() {
      return this.$store.state.siteId;
    },
    isSiteHIPAA() {
      return this.$store.getters.isSiteHIPAA;
    },
    allExperiences: sync('experiences/list'),
    experiences: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    tenantId() {
      return this.$store.getters.siteTenantId;
    },
    testType: {
      get() {
        return this.commonTest.strategy.type;
      },
      set(val) {
        this.commonTest.strategy.type = val;
      }
    },
    canCreateMabExpTest() {
      return this.$store.getters.canCreateMabExpTest;
    },
    hasMakeDestination() {
      return this.$store.getters.hasSiteDestination('make');
    },
    description() {
      if (this.testType === 'mabExp' || this.testType === 'clickOptimization') {
        return 'Split volume across a selection of Preamp experiences where a data science model controls the weights.';
      } else if (this.testType === 'mvt') {
        return 'Split volume across a selection of Preamp experiences where you control the weights.';
      } else {
        return '';
      }
    },
    totalAllocation() {
      return this.experiences.reduce((sum, exp) => {
        if (typeof exp.weight !== 'number') return sum;
        return sum + exp.weight;
      }, 0);
    },
    totalAllocationWithDefault() {
      if (this.totalAllocation <= 100) {
        return 100;
      } else {
        return this.totalAllocation;
      }
    },
    defaultExpWeight() {
      if (this.totalAllocation < 100) {
        return 100 - this.totalAllocation;
      } else {
        return 0;
      }
    },
    isValid() {
      const allExperiencesChosen = this.experiences.every(exp => exp.experienceId !== '');

      if (!allExperiencesChosen) {
        return false;
      }

      if (this.testType === 'mvt') {
        if (this.totalAllocationWithDefault > 100) {
          return false;
        }
        for (const experience of this.experiences) {
          if (experience.weight === '' || experience.weight < 1 || experience.weight > 100) {
            return false;
          }
        }
      }

      return true;
    },
    isMabExpDisabled() {
      return this.canCreateMabExpTest && this.hasMakeDestination && !this.tenantId;
    },
    shouldShowAddExperience() {
      if (this.testType === 'mvt') {
        return this.experiences.length < this.allExperiences.length + 1;
      } else if (this.testType === 'mabExp') {
        return this.experiences.length < this.allExperiences.length + 1;
      } else if (this.testType === 'clickOptimization') {
        return this.experiences.length < this.allExperiences.length;
      }
      return false;
    }
  },
  watch: {
    isValid(val) {
      this.$emit('update:valid', val);
    },
    // Remove default site when switching to mvt and add it back if switching back to SE
    testType(val) {
      switch (val) {
        case 'mvt':
        case 'clickOptimization':
          this.experiences = this.experiences.filter(exp => exp.experienceId !== '0');
          break;
        case 'mabExp': {
          if (this.defaultSiteSEPos !== false) {
            this.experiences.splice(this.defaultSiteSEPos, 0, { experienceId: '0', key: this.generateRandomKey() });
          }
          break;
        }
        default:
          break;
      }
    }
  },
  async created() {
    // Cannot use mabExp if cannot create one or if there is no tenant ID for this site
    if (!this.canCreateMabExpTest || this.isSiteHIPAA) {
      this.commonTest.strategy.type = 'mvt';
    }
    if (this.experiences.length === 0) {
      // Add starting experience
      this.experiences.push({ experienceId: '', isControl: true, weight: 0, key: this.generateRandomKey(), selected: '' });
      await this.getDependencies();
    } else {
      // Add keys to experiences so the initial render will include the keys
      this.experiences = this.experiences.map(exp => ({
        ...exp,
        key: this.generateRandomKey()
      }));

      // Handle adding 'selected' property for autocomplete components
      await this.getDependencies();
      let experienceOptions = [...this.allExperiences];
      // Add default site option for SE
      if (this.testType === 'mabExp' || this.testType === 'clickOptimization') {
        experienceOptions.unshift({ experienceId: '0', name: 'Default (Base Site)', experienceNumber: 0 });
      }
      experienceOptions = experienceOptions.map(exp => ({ ...exp, displayName: this.getExpOptionDisplayName(exp) }));
      // Add keys and selected values to existing experiences
      this.experiences = this.experiences.map((exp, i) => {
        const foundExperience = experienceOptions.find(e => e.experienceId === exp.experienceId);
        // Set initial selected values, only way to do this through buefy autocomplete :(
        this.$refs[`autocomplete-${i}`][0].setSelected({ ...foundExperience });

        return {
          ...exp,
          selected: foundExperience.displayName
        };
      });

      // Check starting validity
      this.checkValidity();
    }
  },
  methods: {
    async getDependencies() {
      await this.$store.dispatch('experiences/getList');
    },
    changeExperienceId(index, newValue) {
      // Set experience Id to empty string if unselected experience
      if (!newValue) {
        this.experiences[index].experienceId = '';
        return;
      }
      if (newValue.experienceId === '0') {
        // Set default site position if added
        this.defaultSiteSEPos = index;
      } else if (this.experiences[index].experienceId === '0') {
        // Remove default site position if changed
        this.defaultSiteSEPos = false;
      }
      this.experiences[index].experienceId = newValue.experienceId;
    },
    getExpVal(currentExperience) {
      // Return default site value special case
      if (currentExperience.experienceId === '0') {
        return { experienceId: '0', name: 'Default (Base Site)', experienceNumber: 0 };
      }
      return this.allExperiences.find(exp => exp.experienceId === currentExperience.experienceId);
    },
    getExpOptionDisplayName(experience) {
      let displayName;
      if (experience.name) {
        displayName = `${experience.experienceNumber}. ${experience.name}`;
      } else {
        displayName = `${experience.experienceNumber}. Experience #${experience.experienceNumber}`;
      }
      return displayName;
    },
    getExperienceOptions(currentExperience) {
      const selectedIds = this.experiences.map(exp => exp.experienceId);
      const experienceOptions = [...this.allExperiences];
      // Add default site option for SE
      if (this.testType === 'mabExp' || this.testType === 'clickOptimization') {
        experienceOptions.unshift({ experienceId: '0', name: 'Default (Base Site)', experienceNumber: 0 });
      }
      return experienceOptions
        .map(exp => ({ ...exp, displayName: this.getExpOptionDisplayName(exp) }))
        .filter((exp) => {
          if ((exp.experienceId === currentExperience.experienceId || !selectedIds.includes(exp.experienceId)) && exp.archived !== 1) {
            if (currentExperience.selected != undefined) {
              return exp.displayName.toLowerCase().includes(currentExperience.selected.toLowerCase());
            }
            return true;
          }
          return false;
        }).sort((a, b) => a.experienceNumber - b.experienceNumber);
    },
    addExperience() {
      this.experiences.push({
        experienceId: '',
        isControl: false,
        weight: 0,
        key: this.generateRandomKey(),
        selected: ''
      });
    },
    removeExperience(index) {
      // Remove default site position if removed
      if (this.experiences[index].experienceId === '0') {
        this.defaultSiteSEPos = false;
      }
      this.experiences.splice(index, 1);
    },
    setControl(index) {
      this.experiences = this.experiences.map((exp, i) => {
        if (i === index) {
          return {
            ...exp,
            isControl: !exp.isControl
          };
        } else {
          return {
            ...exp,
            isControl: false
          };
        }
      });
    },
    generateRandomKey() {
      return Math.floor(Math.random() * 100000);
    },
    checkValidity() {
      const allExperiencesChosen =
        this.experiences.every(exp => exp.experienceId !== '');

      if (!allExperiencesChosen) {
        this.$emit('update:valid', false);
        return;
      }

      if (this.testType === 'mvt') {
        if (this.totalAllocationWithDefault > 100) {
          this.$emit('update:valid', false);
          return;
        }
        for (const experience of this.experiences) {
          if (experience.weight === '' || experience.weight < 1 || experience.weight > 100) {
            this.$emit('update:valid', false);
            return;
          }
        }
      }

      this.$emit('update:valid', true);
    }
  }
};
</script>

<style lang="scss">
.experience-steps__no-margin-bottom {
  margin-bottom: 0 !important;
}

.experience-steps__row-mvt {
  display: grid;
  grid-template-columns: 16fr 6fr 2fr 1fr;
  gap: 0.2rem;
}

.experience-steps__row-mabExp {
  display: grid;
  grid-template-columns: 16fr 6fr 1fr;
  gap: 0.2rem;
}

.experience-steps__row-co {
  display: grid;
  grid-template-columns: 16fr 6fr 2fr 1fr;
  gap: 0.2rem;
}

.experience-steps__center-contents {
  display: flex;
  justify-content: center;
  align-items: center;
}

.experience-steps__align-with-fields {
  margin-top: 16px;
}

.experience-steps__negative-margin-right {
  margin-right: -0.2rem !important;
}

.experience-steps__trash-can {
  cursor: pointer;
}

.experience-steps__control_text {
  font-weight: bold;
}

// Hide validation message because it messes up formatting
// The red outline should be enough
.experience-steps__row-mvt .field > .help,
.experience-steps__row-mabExp .field > .help {
  display: none;
}
</style>
