import { ElementClicked, FormSubmitted } from './ui';

const events = {
  'mn-item-insights': {
    text: 'AUDIENCE INSIGHTS',
    description: 'Navigate to Insights via left nav',
    elementType: 'BUTTON',
    location: 'NAVIGATION',
    position: '',
    actionOutcome: 'INTERNALLINK'
  },
  'mn-item-redirect-rules': {
    text: 'REDIRECT RULES',
    description: 'New feature tracking: Navigating to the redirect rules via left nav',
    elementType: 'BUTTON',
    location: 'NAVIGATION',
    position: '',
    actionOutcome: 'INTERNALLINK'
  },
  'mn-item-traffic-flows': {
    text: 'TRAFFIC FLOWS',
    description: 'Button to navigate to traffic flow in left nav',
    elementType: 'BUTTON',
    location: 'NAVIGATION',
    position: '',
    actionOutcome: 'INTERNALLINK'
  },
  'mn-item-history': {
    text: 'HISTORY',
    description: 'Button to navigate to history',
    elementType: 'BUTTON',
    location: 'NAVIGATION',
    position: '',
    actionOutcome: 'INTERNALLINK'
  },
  'mn-item-experiences': {
    text: 'EXPERIENCES',
    description: 'Button to navigate to experiences ',
    elementType: 'BUTTON',
    location: 'NAVIGATION',
    position: '',
    actionOutcome: 'INTERNALLINK'
  },
  'mn-item-placements': {
    text: 'PLACEMENTS',
    description: 'Button to navigate to Placements ',
    elementType: 'BUTTON',
    location: 'NAVIGATION',
    position: '',
    actionOutcome: 'INTERNALLINK'
  },
  'mn-item-all-tests': {
    text: 'ALL TESTS',
    description: 'Button to navigate to All Tests',
    elementType: 'BUTTON',
    location: 'NAVIGATION',
    position: '',
    actionOutcome: 'INTERNALLINK'
  },
  'mn-item-new-test': {
    text: 'NEW TEST',
    description: 'Navigating to the new test page via left navs',
    elementType: 'BUTTON',
    location: 'NAVIGATION',
    position: '',
    actionOutcome: 'INTERNALLINK'
  },
  'mn-item-accounts': {
    text: 'ACCOUNTS',
    description: 'Navigating to the accounts via left nav',
    elementType: 'BUTTON',
    location: 'NAVIGATION',
    position: '',
    actionOutcome: 'INTERNALLINK'
  },
  'mn-item-users': {
    text: 'USERS',
    description: 'Navigating to the users via left nav',
    elementType: 'BUTTON',
    location: 'NAVIGATION',
    position: '',
    actionOutcome: 'INTERNALLINK'
  },
  'mn-item-analytics': {
    text: 'ANALYTICS',
    description: 'Navigating to the analytics overview via left nav',
    elementType: 'BUTTON',
    location: 'NAVIGATION',
    position: '',
    actionOutcome: 'INTERNALLINK'
  },
  'nb-item-docs': {
    text: 'DOCS',
    description: 'New feature tracking: Navigating to Docs via the doc button in the top nav',
    elementType: 'BUTTON',
    location: 'GLOBAL',
    position: '',
    actionOutcome: 'EXPAND'
  },
  'nb-item-feedback': {
    text: 'FEEDBACK',
    description: 'Button in the top nav used to open a feedback form',
    elementType: 'BUTTON',
    location: 'GLOBAL',
    position: '',
    actionOutcome: 'EXPAND'
  },
  'btn-create-user': {
    text: 'CREATE USER',
    description: 'Button in users section used to create a new user',
    elementType: 'BUTTON',
    location: 'HEADER',
    position: '',
    actionOutcome: 'INTERNALLINK'
  },
  'btn-create-experience': {
    text: 'CREATE EXPERIENCE',
    description: 'Button in experience section used to create a new experience',
    elementType: 'BUTTON',
    location: 'HEADER',
    position: '',
    actionOutcome: 'EXPAND'
  },
  'btn-traffic-create-audience': {
    text: 'CREATE AUDIENCE',
    description: 'Button in traffic flow section used to create a new audience',
    elementType: 'BUTTON',
    location: 'HEADER',
    position: '',
    actionOutcome: 'EXPAND'
  },
  'tab-tests-item-all': {
    text: 'ALL',
    description: 'Button in the tests section to view all tests',
    elementType: 'BUTTON',
    location: 'HEADER',
    position: '',
    actionOutcome: 'FILTER'
  },
  'tab-tests-item-active': {
    text: 'ACTIVE',
    description: 'Button in traffic flow section used to create a new audience',
    elementType: 'BUTTON',
    location: 'HEADER',
    position: '',
    actionOutcome: 'FILTER'
  },
  'tab-tests-item-inactive': {
    text: 'INACTIVE',
    description: 'Button in the tests section to view active tests',
    elementType: 'BUTTON',
    location: 'HEADER',
    position: '',
    actionOutcome: 'FILTER'
  },
  'tab-tests-item-ended': {
    text: 'ENDED',
    description: 'Button in the tests section to view archived tests',
    elementType: 'BUTTON',
    location: 'HEADER',
    position: '',
    actionOutcome: 'FILTER'
  },
  'tab-test-analytics': {
    text: 'ANALYTICS',
    description: 'Button in the test card to view test analytics',
    elementType: 'BUTTON',
    location: 'CONTENT',
    position: '',
    actionOutcome: 'FILTER'
  },
  'tab-experiences-item-all': {
    text: 'ALL',
    description: 'Button in the experiences section to view all experiences',
    elementType: 'BUTTON',
    location: 'HEADER',
    position: '',
    actionOutcome: 'FILTER'
  },
  'tab-experiences-item-active': {
    text: 'ACTIVE',
    description: 'Button in the experiences section to view active experiences',
    elementType: 'BUTTON',
    location: 'HEADER',
    position: '',
    actionOutcome: 'FILTER'
  },
  'tab-experiences-item-inactive': {
    text: 'INACTIVE',
    description: 'Button in the experiences section to view inactive experiences',
    elementType: 'BUTTON',
    location: 'HEADER',
    position: '',
    actionOutcome: 'FILTER'
  },
  'tab-experiences-item-archived': {
    text: 'ARCHIVED',
    description: 'Button in the experiences section to view archived experiences',
    elementType: 'BUTTON',
    location: 'HEADER',
    position: '',
    actionOutcome: 'FILTER'
  },
  'btn-tests-all-pause': {
    text: 'PAUSE ALL TESTS',
    description: 'Button used to pause all tests',
    elementType: 'BUTTON',
    location: 'HEADER',
    position: '',
    actionOutcome: 'OPEN'
  },
  'btn-experience-archive': {
    text: 'Archiving Button',
    description: 'Button within experience section used to archive an experience',
    elementType: 'BUTTON',
    location: 'SECTION',
    position: '',
    actionOutcome: 'OPEN'
  },
  'btn-test-in-pause': {
    text: 'Paused',
    description: 'Button within a test used to pause/unpause a test',
    elementType: 'BUTTON',
    location: 'SECTION',
    position: '',
    actionOutcome: 'TOGGLE'
  },
  'btn-test-in-end': {
    text: 'End Test',
    description: 'Button within a test used to end the test',
    elementType: 'BUTTON',
    location: 'SECTION',
    position: '',
    actionOutcome: 'TOGGLE'
  },
  'btn-redirect-audience-duplicate': {
    text: 'Duplicate Audience ',
    description: 'Duplicate button in redirect audience creation ',
    elementType: 'BUTTON',
    location: 'SECTION',
    position: '',
    actionOutcome: 'EXPAND'
  },
  'btn-traffic-audience-duplicate': {
    text: 'Duplicate Audience ',
    description: 'Duplicate button in traffic flow section ',
    elementType: 'BUTTON',
    location: 'SECTION',
    position: '',
    actionOutcome: 'SLIDE'
  },
  'btn-traffic-test-pause': {
    text: 'Pause Test (Traffic Flow)',
    description: 'Button within test section in traffic flow used to pause a test',
    elementType: 'BUTTON',
    location: 'SECTION',
    position: '',
    actionOutcome: 'TOGGLE'
  },
  'btn-traffic-draft': {
    text: 'DRAFT FLOW',
    description: 'New feature tracking: Entering draft mode from traffic flows screen',
    elementType: 'BUTTON',
    location: 'SECTION',
    position: '',
    actionOutcome: 'TOGGLE'
  },
  'btn-test-pause': {
    text: 'Pause Test (Test Tab)',
    description: 'Button within Test tab used to pause a test',
    elementType: 'BUTTON',
    location: 'SECTION',
    position: '',
    actionOutcome: 'TOGGLE'
  },
  'btn-create-test-in-test': {
    text: 'Create Experience (within new test)',
    description: 'Button within a new test used to create an experience',
    elementType: 'BUTTON',
    location: 'SECTION',
    position: '',
    actionOutcome: 'SLIDE'
  },
  'new-comparator-select-matches': {
    text: 'REGEX MATCHES',
    description: 'When user selects the dropdown option of regex matches operator when creating a rule for an audience',
    elementType: 'DROPDOWN',
    location: 'SECTION',
    position: '',
    actionOutcome: 'FILTER'
  },
  'btn-regex-match-test': {
    text: 'TEST REGEX',
    description:
      'New feature tracking: Basic RegEx validation test when editing/creating a rule using the "matches" comparator. Select tool tip',
    elementType: 'BUTTON',
    location: 'SECTION',
    position: '',
    actionOutcome: 'EXPAND'
  },
  'btn-edit-site': {
    text: 'EDIT SITE',
    description: 'Button in top nav to edit site',
    elementType: 'BUTTON',
    location: 'GLOBAL',
    position: '',
    actionOutcome: 'OPEN'
  },
  'btn-start-test': {
    text: 'START TEST',
    description: 'If a user goes into all tests, then restarts a test clicking this button, restarts test',
    elementType: 'BUTTON',
    location: 'SECTION',
    position: '',
    actionOutcome: 'TOGGLE'
  }
};

function fireInsightsEvent(text) {
  // log an error if an invalid text is passed as the value
  if (!text || text === '') {
    return console.log('WARNING: Invalid text passed to fireInsightsEvent function.');
  }

  if (window.tagular) {
    window.tagular('beam', ElementClicked({
      text,
      description: 'Fire a dynamic event depending on top (10 or 25), based on the time variable (1H, 1D, 7D, 30D), percentages or decisions, and graph',
      elementType: 'GRAPH',
      location: 'SECTION',
      position: '',
      actionOutcome: 'FILTER'
    }));
  }
}

function sendTestData(payload) {
  if (window.tagular) {
    window.tagular('beam', FormSubmitted({
      payload
    }));
  }
}

export {
  events,
  fireInsightsEvent,
  sendTestData
};
