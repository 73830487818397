import { render, staticRenderFns } from "./TestingRank.vue?vue&type=template&id=5e252925&scoped=true"
import script from "./TestingRank.vue?vue&type=script&lang=js"
export * from "./TestingRank.vue?vue&type=script&lang=js"
import style0 from "./TestingRank.vue?vue&type=style&index=0&id=5e252925&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e252925",
  null
  
)

export default component.exports