<template>
  <div class="variables-step" data-cy-test="variables-section">
    <h5 class="section-title" data-cy-test="variables-section-title">Variables</h5>
    <p class="section-description" data-cy-test="variables-section-description">
      Variables are editable components in an asset. Variables may configure a visible part of your asset, such as text and color, or other settings related to asset behavior.
    </p>
    <div
      v-for="[varName, varValue] of Object.entries(variables)"
      :key="varName"
      class="variable-configuration"
    >
      <component
        :is="`configure-${varValue.type}`"
        v-model="variables[varName]"
        :variable-name="varName"
      />
    </div>
  </div>
</template>

<script>
  import BooleanVariable from '../VariableInputs/BooleanVariable.vue';
  import EnumVariable from '../VariableInputs/EnumVariable.vue';
  import NumberVariable from '../VariableInputs/NumberVariable.vue';
  import StringVariable from '../VariableInputs/StringVariable.vue';

  export default {
    name: 'EditVariables',
    components: {
      'configure-boolean': BooleanVariable,
      'configure-string': StringVariable,
      'configure-number': NumberVariable,
      'configure-enum': EnumVariable
    },
    props: {
      variables: {
        type: Object,
        required: true
      }
    }
  };
  </script>

  <style scoped lang="scss">
    .variables-step, .variable-configuration:not(:last-child) {
      margin-bottom: 24px;
    }

    :deep(.label label) {
      color: #626D7A;
      font-weight: 400;
    }
  </style>


