<template>
  <div class="top15">
    <h5 class="title is-5">Champion Experience</h5>

    <div
      v-if="championExperience"
      class="bottom15"
    >
      <div class="card test">
        <div class="card-header">
          <p
            class="card-header-title"
            style="padding: 0;"
          >
            <span
              class="card-header-box"
              style="display:flex;align-items:center;"
            >
              {{ championExperience.experienceNumber }}
            </span>
            {{
              (championExperience.name)
                ? championExperience.name
                : `Experience #${championExperience.experienceNumber}`
            }}
          </p>
          <div class="card-header-item">
            <button
              class="button"
              data-cy-test="remove-experience-button"
              @click="removeChampion"
            >
              <span class="icon has-text-danger">
                <i class="fa fa-times"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div><!-- /.bottom15 -->

    <!-- Champion Selection -->
    <div
      v-else
      class="bottom15"
    >
      <b-field class="top15">
        <div class="columns">
          <div class="column is-half">
            <b-field expanded>
              <b-autocomplete
                v-model="experienceQuery"
                placeholder="Select an Experience"
                data-cy-test="select-experience"
                :open-on-focus="true"
                :max-results="10"
                :data="filtered"
                :loading="isLoading"
                field="label"
                @select="option => selectedChampionId = option.experienceId"
              >
                <template slot="empty">No results found</template>
              </b-autocomplete>
            </b-field>
          </div><!-- /.column -->
        </div><!-- /.columns -->
      </b-field>
    </div><!-- /v-else -->
  </div><!-- /.margin-top15 -->
</template>

<script>
import { hasLength } from '@/modules/utilities';

export default {
  name: 'ChampionSelect',
  props: {
    championId: {
      required: true
    }
  },
  data() {
    return {
      experiences: null,
      experienceQuery: '',
      selectedChampionId: this.championId,
      champion: null,
      isLoading: false
    };
  },
  computed: {
    siteId() {
      return this.$store.state.siteId;
    },
    // Get the champion experience details
    championExperience() {
      if (!this.selectedChampionId || !hasLength(this.experiences)) return null;

      return this.experiences.find(exp => exp.experienceId === this.selectedChampionId);
    },
    // Format experience data for autocomplete
    formatted() {
      // autocomplete works best with string matching
      if (!hasLength(this.experiences)) return [];
      // loop through experiences and format each for autocomplete
      return this.experiences.map(function (exp) {
        // return a label with both exp number and name for matching both
        return {
          experienceId: exp.experienceId,
          label: exp.name ? `${exp.experienceNumber} - ${exp.name}` : `${exp.experienceNumber}`
        };
      });
    },
    // Handle filtering for autocomplete
    filtered() {
      // if there is not a query, return all experiences
      if (!hasLength(this.experienceQuery)) return this.formatted;
      // create a regex for the query and return any matches
      return this.formatted.filter(exp => exp.label.match(new RegExp(this.experienceQuery, 'gi')) !== null);
    }
  },
  watch: {
    /**
     * Notify audience of new champion on change
     * @param {String} id - experience id
     */
    selectedChampionId(id) {
      this.$emit('champion-selected', id);
    }
  },
  created() {
    this.getExperienceList();
  },
  methods: {
    // Remove the current champion so another may be chosen
    removeChampion() {
      this.selectedChampionId = null;
    },
    async getExperienceList() {
      this.isLoading = true;

      try {
        const res = await this.$axios.get(`/sites/${this.siteId}/experiences`, {
          params: {
            attributes: ['name', 'experienceNumber', 'experienceId']
          }
        });
        this.experiences = res.data.experiences;
      } catch (error) {
        this.$store.commit('error', error);
      }

      this.isLoading = false;
    }
  }
};
</script>
