<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" data-cy-test="compare-draft">Comparing Draft to Production</p>
          <button
            class="delete"
            aria-label="close"
            @click="cancel"
          ></button>
        </header>

        <section class="modal-card-body">
          <table class="table is-bordered is-narrow is-fullwidth">
            <tbody>
              <tr
                v-for="(value, prop, index) in next"
                v-if="!prop.match(/(updatedAt|trafficFlowDraftId|trafficFlowId|createdAt)/)"
                :key="`diff-${index}`"
              >
                <th>{{ prop }}</th>

                <TrafficFlowDiff
                  v-if="prop === 'audiences'"
                  :prev="prev.audiences"
                  :next="next.audiences"
                />

                <DiffObject
                  v-else-if="typeof value === 'object'"
                  :prev="prev[prop]"
                  :next="next[prop]"
                />

                <td v-else-if="prev[prop] !== value">
                  <span class="removed">{{ prev[prop] }}</span>
                  <span class="added">{{ value }}</span>
                </td>

                <td v-else>
                  {{ value }}
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <footer class="modal-card-foot">
          <button
            class="button is-success"
            data-cy-test="confirm-publish-button"
            @click="publish"
          >
            <span class="icon"><i class="fa fa-cloud-upload"></i></span>
            <span>Confirm Publish</span>
          </button>

          <button
            class="button"
            @click="cancel"
          >
            <span>Cancel</span>
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import TrafficFlowDiff from '@/components/History/TrafficFlowDiff';

export default {
  name: 'DraftDiffModal',
  components: {
    TrafficFlowDiff
  },
  props: ['draft', 'production'],
  data() {
    return {
      next: null,
      prev: null
    };
  },
  mounted() {
    this.formatData();
  },
  methods: {
    /**
     * Strip the irrelevant pieces of a test out of the diff
     * @param {Array} audiences
     * @return {Array} formatted audiences
     */
    formatAudience(audiences) {
      const validProps = ['testId', 'visitorsPct'];
      return audiences.map(function (audience) {
        for (let i = 0; i < audience.tests.length; i++) {
          for (const prop in audience.tests[i]) {
            if (Object.prototype.hasOwnProperty.call(audience.tests[i], prop)) {
              if (validProps.indexOf(prop) === -1) {
                delete audience.tests[i][prop];
              }
            }
          }
        }
        return audience;
      });
    },
    // Assign the data for the diff
    formatData() {
      this.next = JSON.parse(JSON.stringify(this.draft[0]));
      this.$set(this.next, 'audiences', this.formatAudience(this.next.audiences));

      this.prev = JSON.parse(JSON.stringify(this.production[0]));
      this.$set(this.prev, 'audiences', this.formatAudience(this.prev.audiences));
    },
    // Emit an a publish confirmation to trafficFlow
    publish() {
      this.$emit('draft-diff-publish');
    },
    // Emit a cancel to trafficFlow
    cancel() {
      this.$emit('draft-diff-cancel');
    }
  }
};
</script>

<style scoped>
.modal-content,
.modal-card {
  width: 80vw;
}
</style>
