<template>
  <div class="tests">
    <div class="main-view-header">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h4
              class="title is-4"
              data-cy-test="title"
            >
              Tests
            </h4>
          </div>
        </div>

        <div class="level-right">
          <div
            v-if="isInsightsAvailable"
            class="level-item"
          >
            <b-field>
              <b-switch
                v-model="hasInsightsMetricsSelected"
                type="is-success"
              >
                <b-tooltip
                  type="is-dark"
                  position="is-bottom"
                  data-cy-test="insights-radio-button"
                  :label="`Click to ${hasInsightsMetricsSelected ? 'hide' : 'show' } Insights data`"
                >
                  <span v-if="isLoadingInsights">Loading...</span>
                  <span v-else>{{ hasInsightsMetricsSelected ? 'Hide' : 'Show' }} Insights</span>
                </b-tooltip>
              </b-switch>
            </b-field>
          </div>

          <div
            v-if="isInsightsAvailable"
            class="level-item"
          >
            <transition
              name="custom-classes-transition"
              enter-active-class="animated zoomIn faster"
            >
              <LookbackRangePicker
                v-if="hasInsightsMetricsSelected"
                :options="['1d', '7d', '30d']"
                data-cy-test="lookback-range"
                :has-refresh="false"
                :formatter="false"
                @selection="val => lookbackRange = val"
              />
            </transition>
          </div>

          <div class="level-item">
            <b-field>
              <p class="control">
                <b-dropdown>
                  <button
                    slot="trigger"
                    class="button"
                    data-cy-test="search-dropdown-button"
                  >
                    <span>{{ filterBy ? filterBy.name : 'Filters' }}</span>
                    <span class="icon"><i class="fa fa-caret-down"></i></span>
                  </button>

                  <b-dropdown-item
                    v-for="(option, optIndex) in filterOptions"
                    :key="optIndex"
                    :data-cy-test="option.name"
                    @click="filterBy = option"
                  >{{ option.name }}</b-dropdown-item>
                </b-dropdown>
              </p>

              <b-input
                v-model="filterQuery"
                type="search"
                placeholder="Search..."
                data-cy-test="search-input"
              ></b-input>
            </b-field>
          </div>

          <div class="level-item">
            <button
              :disabled="!userCanEdit"
              class="button is-info"
              data-cy-test="new-test-button"
              @click="handleNewTestClick"
            >
              <span class="icon"><i class="fa fa-plus"></i></span>
              <span>New Test</span>
            </button>
          </div>
        </div><!-- /.level-right -->
      </div>
    </div>

    <div class="main-view-sub-header">
      <div class="tabs is-boxed">
        <ul>
          <li
            v-for="(tab, index) in ['All', 'Active', 'Inactive', 'Ended']"
            :id="`tab-tests-item-${tab.toLowerCase()}`"
            :key="index"
            :data-cy-test="`tab-${tab.toLowerCase()}`"
            :class="{ 'is-active': tabSelection === tab }"
            @click="(evt) => handleTabSelection(evt, tab)"
          >
            <a :title="getTabTip(tab)">{{ tab }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="main-view-content expanded-header has-pagination-footer is-white">
      <div
        v-if="tabSelection === 'Ended'"
        class="level-right bottom10"
      >
        <h6 class="subtitle is-6 right12 bottom0">Sort by End Date: </h6>
        <b-field>
          <b-radio-button
            v-model="endDateSortOption"
            native-value="asc"
            size="is-small"
            type="is-info"
          >
            <span><b-icon icon="arrow-up" /></span>
          </b-radio-button>

          <b-radio-button
            v-model="endDateSortOption"
            native-value="des"
            size="is-small"
            type="is-info"
          >
            <span><b-icon icon="arrow-down" /></span>
          </b-radio-button>
        </b-field>
      </div>

      <b-table
        ref="tests-table"
        class="tests-table"
        :data="filtered"
        :loading="isLoading"
        paginated
        pagination-simple
        :current-page.sync="page"
        :per-page="offset"
        pagination-size="is-hidden"
        sort-icon="caret-up"
        hoverable
      >
        <!-- Show Insights Action -->
        <b-table-column
          v-slot="props"
          width="50"
          :visible="hasInsightsMetricsSelected"
        >
          <b-tooltip
            type="is-dark"
            position="is-right"
            :label="`${getTestHitData(props.row.testId).percentage}% of Decisions Created`"
          >
            <span
              v-if="getTestHitData(props.row.testId).precisePercentage > 0 && getTestHitData(props.row.testId).precisePercentage < 1"
              color="red"
            >&lt;1%
            </span>
            <span
              v-else
            >
              {{ getTestHitData(props.row.testId).percentage }}%
            </span>
          </b-tooltip>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="name"
          sortable
          label="Test Name"
        >
          <router-link
            :to="`/tests/${props.row.testId}`"
            data-cy-test="tests-name-in-table"
          >
            {{ props.row.name }}
          </router-link>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="testType"
          label="Test Type"
          width="100"
        >
          <!-- MVT Tag -->
          <div
            v-if="props.row.strategy.type === 'mvt'"
            class="tags has-addons"
          >
            <span
              class="tag"
              data-cy-test="test-type-tag"
            >
              <b-icon
                icon="sitemap"
                size="is-small"
              ></b-icon>
            </span><!-- /.tag -->
            <span
              class="tag is-primary"
              data-cy-test="mvt-tests"
            >MVT</span>
          </div>

          <!-- MABEXP Tag -->
          <div
            v-if="props.row.strategy.type === 'mabExp'"
            class="tags has-addons"
          >
            <span
              class="tag"
              data-cy-test="test-type-tag"
            >
              <v-icon
                icon="brain"
                class="is-small"
              ></v-icon>
            </span>
            <span
              class="tag is-dark"
              data-cy-test="exp-tests"
            >EXP</span>
          </div>

          <!-- Click Optimization Tag -->
          <div
            v-if="props.row.strategy.type === 'clickOptimization'"
            class="tags has-addons"
          >
            <span
              class="tag"
              data-cy-test="test-type-tag"
            >
              <v-icon
                icon="bullseye-pointer"
                class="is-small"
              ></v-icon>
            </span>
            <span
              class="tag is-link"
              data-cy-test="co-tests"
            >CO</span>
          </div>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="testStatus"
          label="Test Status"
          width="100"
          centered
        >
          <!-- Test Active Notifier -->
          <div
            v-if="props.row.isActive"
          >
            <span
              class="tag is-success"
              data-cy-test="active-tag"
            >
              Active
            </span>
          </div>
        </b-table-column>

        <!-- Test Quick-Pause/Play Action -->
        <b-table-column
          v-slot="props"
          label="Paused"
        >
          <b-switch
            id="btn-test-in-pause"
            :value="props.row.isPaused"
            :true-value="1"
            :false-value="0"
            aria-label="Pause/Unpause Test"
            data-cy-test="pause-tests-icon"
            type="is-warning"
            :disabled="isSavingTest"
            class="pause-test-switch"
            @input="togglePause(props.row)"
          >
          </b-switch>
        </b-table-column>
      </b-table>

      <div
        v-if="!isLoading && filtered.length === 0"
        slot="empty"
      >
        <article class="message is-warning">
          <div
            v-if="tabSelection !== 'Ended'"
            class="message-body"
            data-cy-test="message-body"
          >
            No tests were found for this site.
          </div>
          <div
            v-else
            class="message-body"
          >
            No tests have been ended yet. To end a test, obtain the site lock, click the header of the test you wish to end, and click the "End Test" button.
          </div>
        </article>
      </div>
    </div>

    <footer class="main-view-footer">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <button
              class="button"
              :disabled="page === 0"
              @click.stop="getPage(-1)"
            >Previous</button>
          </div>
          <div class="level-item">
            <button
              class="button"
              :disabled="(page+1) * 20 > tests.length"
              @click.stop="getPage(1)"
            >Next</button>
          </div>
        </div>

        <div class="level-right">
          <div class="level-item">
            <strong>{{ testRangeText }} of {{ tests.length }} Tests</strong>
          </div>
        </div>
      </div>
    </footer>

    <TestFormModal
      v-if="isNewTestModalActive"
      :test="editingTest"
      @test-created="test => handleTestCreated(test, false)"
      @test-updated="test => handleTestCreated(test, true)"
      @cancel="handleCancelTestForm"
    />

    <b-loading
      :active.sync="isLoading"
      :is-full-page="false"
    ></b-loading>
  </div>
</template>

<script>
import TestFormModal from '@/components/TestForm/TestFormModal';
import LookbackRangePicker from '@/components/Insights/LookbackRangePicker';
import { hasLength, formatTest } from '@/modules/utilities';
import { bus } from '@/main';
import { sync } from 'vuex-pathify';

export default {
  name: 'Tests',
  components: {
    TestFormModal,
    LookbackRangePicker
  },
  props: {
    routeToTestId: {
      type: String,
      required: false
    },
    routeToTab: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      trafficFlows: [],
      tests: [],
      experiences: [],
      placements: [],
      tabSelection: 'All',
      filterQuery: '',
      filterBy: { name: 'Test Name', val: 'name' },
      filterOptions: [
        { name: 'Test Name', val: 'name' },
        { name: 'Test Type', val: 'type' },
        { name: 'Test ID', val: 'testId' },
        { name: 'Test Status', val: 'status' },
        { name: 'Audience Name', val: 'audience' },
        { name: 'Experience Number', val: 'experienceNumber' }
      ],
      isLoading: false,
      isLoadingInsights: false,
      endDateSortOption: 'asc',
      page: 0,
      offset: 20,
      insights: null,
      hasInsightsMetricsSelected: false,
      lookbackRange: '1d',
      isNewTestModalActive: false,
      isSavingTest: false
    };
  },
  computed: {
    editingTest: sync('editing@test'),
    addOns: sync('addOns/list'),
    isInsightsAvailable() {
      return this.$store.getters.isInsightsAvailable;
    },
    isNewTestFormAvailable() {
      return this.$store.getters.isSuperUser || this.$store.state.activeAccount === '1snmih4VgUSINDwdAR9ZWS';
    },
    userOwnsLock() {
      return this.$store.getters.siteLockOwner;
    },
    userCanEdit() {
      return this.$store.getters.userCanEdit;
    },
    siteId() {
      return this.$store.state.siteId;
    },
    filtered() {
      let result = this.tests;
      if (hasLength(this.tests)) {
        result = this.filterTests(result);
        // paginate results if above max record count (offset)
        if (result.length > this.offset && this.page * this.offset < result.length) {
          result = result.slice(this.page * this.offset, this.nextPage);
        }

        //sort by desc created test date (newer tests at the top)
        if (this.tabSelection !== 'Ended') {
            result.sort((a,b) => new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1);
        }
        // sort by ascending/descending end date
        if (this.tabSelection === 'Ended') {
          if (this.endDateSortOption === 'asc') {
            result.sort((a, b) => a.endedAt > b.endedAt ? 1 : -1);
          } else {
            result.sort((a, b) => a.endedAt > b.endedAt ? -1 : 1);
          }
        }
      }
      return result;
    },
    holdOutOptions() {
      const options = this.experiences;

      options.push({
        experienceId: '0',
        experienceNumber: 0,
        name: 'Default'
      });
      return options;
    },
    nextPage() {
      if (this.page === 0) return this.offset;
      const nextOffset = (this.page * this.offset) + this.offset;
      // if next page has last set of items, just return the last of the array
      return nextOffset > this.tests.length ? this.tests.length : nextOffset;
    },
    testRangeText() {
      let result;
      // if searching or there are < 20 tests, return list length
      if (hasLength(this.filterQuery) || this.tests.length < this.offset) {
        result = this.filtered.length;
      } else {
        result = `${this.page * this.offset} - ${this.nextPage}`;
      }
      return result;
    }
  },
  watch: {
    editingTest(test) {
      if ((test && test.strategy) && (test.strategy.mabExp || test.strategy.mvt)) {
        this.isNewTestModalActive = true;
      }
    },
    siteId() {
      // force close any forms if the site changes
      if (this.isNewTestModalActive) {
        this.isNewTestModalActive = false;
      }
    },
    filterQuery(val) {
      if (val && this.page > 0) {
        this.page = 0;
      }
    },
    filterBy(val) {
      if (val && val.name === 'Audience Name') {
        this.getTrafficFlows();
      }
    },
    /**
     * May need to get new data if switching
     * between ended and not ended
     */
    tabSelection(val, oldVal) {
      if (val === 'Ended' || oldVal === 'Ended') {
        this.getDependencies();
      }
    },
    tests(array) {
      if (this.isInsightsAvailable && hasLength(array)) {
        this.getTestInsights();
      }
    },
    lookbackRange() {
      if (this.isInsightsAvailable) {
        this.getTestInsights();
      }
    }
  },
  created() {
    this.registerEventListeners();
    if (this.siteId) this.getDependencies();
  },
  beforeDestroy() {
    bus.$off('site-changed', this.getDependencies);
  },
  mounted() {
    if (this.$route.params.testId) {
      this.filterBy = this.filterOptions.find(obj => obj.val === 'testId');
      this.filterQuery = this.$route.params.testId;
    }
  },
  methods: {
    /**
     * Emit element click event
     * @param {Object} e - element click event
     */
    elementClick(e) {
      bus.$emit('elem-click', e);
    },
    handleNewTestClick() {
      this.$root.establishSiteLock().then(() => {
        this.isNewTestModalActive = true;
      });
    },
    async getDependencies() {
      await this.getTestData();
      this.$store.commit('experiences/updateExperienceTestMap', this.tests);
      this.getExperiences();
      this.getPlacementData();
      this.$store.dispatch('addOns/getList');

      if (this.routeToTestId) {
        this.filterBy = this.filterOptions.find(opt => opt.val === 'testId');
        this.filterQuery = this.routeToTestId;
      }
    },

    async getTestData() {
      this.isLoading = true;
      try {
        const opts = {};
        opts.params = { attributes: ['testId', 'name', 'description', 'ended', 'isPaused', 'isPersisted', 'strategy', 'trafficFlowId', 'createdAt', 'updatedAt', 'siteId', 'tags'] };
        if (this.tabSelection === 'Ended') opts.params.status = 'ended';

        const res = await this.$axios.get(`/sites/${this.siteId}/tests`, opts);
        this.tests = res.data.tests;
      } catch (e) {
        e.title = 'There was a problem retrieving test data.';
        this.$store.commit('error', e);
      }
      this.isLoading = false;
    },
    async getTrafficFlows() {
      try {
        const res = await this.$axios.get(`/sites/${this.siteId}/traffic-flows`);
        this.trafficFlows = res.data;
      } catch (error) {
        error.title = 'There was a problem retrieving traffic flow data.';
        this.$store.commit(error);
      }
    },
    togglePause(test) {
      this.$root.establishSiteLock().then(() => {
        const updatedTest = {
          ...test,
          isPaused: test.isPaused ? 0 : 1
        };
        this.saveTest(updatedTest);
      });
    },
    async saveTest(chosenTest) {
      this.isSavingTest = true;
      const test = formatTest(chosenTest);

      try {
        if (test.strategy && test.strategy.type === 'mabExp') {
          await this.$axios.put(`/sites/${this.siteId}/tests/mabExp/${test.testId}`, { test });
        } else {
          let updatedTest = {...test};
            // clickOptimizatin tests need options to be an array of experienceIds and not the entire experience
            if (test.strategy && test.strategy.type === 'clickOptimization') {
              const modifiedOptions = test.strategy.clickOptimization.options.map(o => o.experienceId);
              updatedTest =  {
                ...updatedTest,
                strategy: {
                  ...updatedTest.strategy,
                  clickOptimization: {
                    ...updatedTest.strategy.clickOptimization,
                    options: modifiedOptions
                  }
                }
              };
            }

          await this.$axios.post(`/sites/${this.siteId}/tests/${test.testId}`, { test: updatedTest });
        }
        // handle success for either test type
      } catch (error) {
        error.title = 'There was a problem updating the test. Please try again later.';
        this.$store.commit('error', error);
      }

      this.handleUpdateSuccess(test);
      this.isSavingTest = false;
    },
    handleUpdateSuccess(test) {
      this.editingTest = null;
      this.notifyUpdateSuccessAndPauseStatus(test);
    },
    notifyUpdateSuccessAndPauseStatus(test) {
      let successMsg = `${test.name} was successfully updated`;

      this.$buefy.toast.open({
        message: successMsg,
        type: 'is-success'
      });
    },
    async getExperiences() {
      try {
        const res = await this.$axios.get(`/sites/${this.siteId}/experiences`);
        this.experiences = res.data.experiences;
      } catch (error) {
        error.title = 'There was a problem retrieving experience data.';
        this.$store.commit('error', error);
      }
    },
    async getPlacementData() {
      try {
        const response = await this.$axios.get(`/sites/${this.siteId}/placements`);
        this.placements = response.data.placements;
      } catch (error) {
        error.title = 'There was a problem getting Placement data';
        this.$store.commit('error', error);
      }
    },
    doesValueMatchQuery(value, query) {
      return value.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    },
    filterTests(testsArr) {
      let tests = testsArr;
      // Filter by Tabs first
      if (this.tabSelection === 'Active') {
        tests = tests.filter(test => test.isActive === true);
      }
      if (this.tabSelection === 'Inactive') {
        tests = tests.filter(test => test.isActive === false);
      }
      // Filter by Query
      if (!this.filterBy || !hasLength(this.filterQuery)) {
        return tests;
      }
      return tests.filter((test) => {
        let result = true;
        switch (true) {
          case this.filterBy.name === 'Experience Number':
            result = this.findMatchingExperiences(test);
            break;
          case this.filterBy.name === 'Audience Name':
            result = this.findMatchingAudienceTestIds(test);
            break;
          case this.filterBy.name === 'Test Type':
            result = this.doesValueMatchQuery(test.strategy.type, this.filterQuery);
            break;
          case test[this.filterBy.val] === undefined:
            result = false;
            break;
          default:
            result = this.doesValueMatchQuery(test[this.filterBy.val], this.filterQuery);
            break;
        }
        return result;
      });
    },
    findMatchingAudienceTestIds(test) {
      // find which audience they are searching by
      const matchingAudience = this.trafficFlows[0].audiences.find((audience) => {
        return this.doesValueMatchQuery(audience.name, this.filterQuery);
      });
      // get array of testIds to easily check inclusion
      const testIds = matchingAudience.tests.map(testObj => testObj.testId);
      return testIds.includes(test.testId);
    },
    findMatchingExperiences(test) {
      const cleanQuery = parseInt(this.filterQuery, 10);
      const experienceIdMatch = this.experiences.find(exp => exp.experienceNumber === cleanQuery).experienceId;
      let hasExp = false;
      if (test.strategy.type === 'mabExp') {
        if (test.strategy.mabExp.dapi.options.find(exp => exp.experienceId === experienceIdMatch) !== undefined) hasExp = true;
      }
      if (test.strategy.type === 'mvt') {
        if (test.strategy.mvt.experiences.find(exp => exp.experienceId === experienceIdMatch) !== undefined) hasExp = true;
      }

      return hasExp;
    },
    /**
     * Get the next page of data from the API
     * @param {Number} integer - postive or negative 1
     */
    getPage(integer) {
      this.page += integer;
    },
    /**
     * Get tooltip message when hovering over the tab options
     * @param {String} tab - 'All, Active, Inactive, Archived
     */
    getTabTip(tab) {
      let tipMsg;
      switch (tab) {
        case 'All':
          tipMsg = 'Test enrolled in a traffic flow, does not necessitate it is getting volume.';
          break;
        case 'Active':
          tipMsg = 'Test enrolled in a traffic flow, does not necessitate it is getting volume.';
          break;
        case 'Inactive':
          tipMsg = 'Tests are either in a traffic flow and paused or not in a traffic flow.';
          break;
        case 'Ended':
          tipMsg = 'Test has been ended as a possible test across all of traffic.';
          break;
        default:
          tipMsg = '';
          break;
      }
      return tipMsg;
    },
    async getTestInsights() {
      if (!hasLength(this.tests)) return;

      const payload = {
        filters: {}
      };

      // Dynamically build the payload for elasticsearch
      for (const test of this.tests) {
        payload.filters[test.testId] = {
          match: {
            testId: test.testId
          }
        };
      }

      this.isLoadingInsights = true;

      try {
        const res = await this.$axios.post(`/sites/${this.siteId}/insights/breakdowns/${this.lookbackRange}`, payload);
        this.insights = res.data;
      } catch (err) {
        this.$store.commit('error', err);
      }

      this.isLoadingInsights = false;
    },
    getTestHitData(testId) {
      const result = {
        percentage: 0,
        precisePercentage: 0
      };

      // if able, return the hit percentage for the test
      if (this.insights && this.insights.counts[testId]) {
        const rawPercent = this.insights.counts[testId] / this.insights.total;
        result.percentage = Math.round(rawPercent * 100);
        result.precisePercentage = Math.round(rawPercent * 10000) / 100;
      }

      return result;
    },
    handleTestCreated(test, updated = false) {
      this.isNewTestModalActive = false;

      if (updated) this.editingTest = null;

      this.$buefy.toast.open({
        message: `${test.name || 'Test'} was successfully created. Refreshing test list...`,
        type: 'is-success'
      });

      this.getDependencies();
    },
    handleCancelTestForm() {
      this.isNewTestModalActive = false;
      this.editingTest = null;
    },
    handleSiteLockLost() {
      this.isNewTestModalActive = false;
    },
    handleTabSelection(clickEvent, tab) {
      this.elementClick(clickEvent);
      this.tabSelection = tab;
    },
    registerEventListeners() {
      bus.$on('site-changed', this.getDependencies);
      bus.$on('site-lock-lost-user', this.handleSiteLockLost);
    }
  }
};
</script>

<style lang="scss" scoped>
.tests-table {
  ::v-deep td {
    vertical-align: middle;
  }
}
.pause-test-switch[disabled] {
  cursor: wait;
}
</style>
