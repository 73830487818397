<template>
  <div class="asset">
    <div class="main-view-header">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div
              class="breadcrumb is-medium"
              aria-label="breadcrumbs"
            >
              <ul>
                <li><router-link to="/assets">Assets</router-link></li>
                <li class="is-active">
                  <router-link
                    v-if="asset
                      && asset.assetId
                      && asset.name"
                    :to="`/assets/${asset.assetId}`"
                  >
                    {{ asset.name }}
                  </router-link>
                  <a
                    v-else
                    href="javascript:void(0)"
                  >{{ isLoading ? 'Loading...' : 'Unknown Asset' }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-view-content">
      <Asset
        v-if="siteId && asset && asset.assetId && !asset.version || asset.version === 1"
        :key="asset.assetId"
        :asset="asset"
        :allAssets="allAssets"
        :siteId="siteId"
        @updatedAsset="handleUpdatedAsset"
        @updatedVariants="handleUpdateAssets"
      />
      <AssetV2
        v-if="siteId && asset && asset.assetId && asset.version === 2"
        :key="asset.assetId"
        :asset="asset"
        :allAssets="allAssets"
        :siteId="siteId"
        @updatedAsset="handleUpdatedAsset"
        @updatedVariants="handleUpdateAssets"
      />

      <b-loading
        :active.sync="isLoading"
        :is-full-page="false"
      />
    </div>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';
import { bus } from '@/main';
import Asset from '@/components/Assets/Asset';
import AssetV2 from '@/components/Assets/v2/Asset';

export default {
  name: 'AssetView',
  components: {
    Asset,
    AssetV2
  },
  computed: {
    asset: sync('assets/focused'),
    allAssets: sync('assets/list'),
    isLoading: sync('assets/isLoading'),
    siteId() {
      return this.$store.state.siteId;
    },
    assetId() {
      return this.$route.params.assetId;
    }
  },
  watch: {
    assetId(id, oldId) {
      if (id !== oldId) {
        this.getDependencies();
      }
    }
  },
  created() {
    this.getDependencies();
    bus.$on('site-changed', this.getDependencies);
  },
  beforeDestroy() {
    // Reset the focused asset
    this.asset = {};
    bus.$off('site-changed', this.getDependencies);
  },
  methods: {
    async getDependencies() {
      if (this.siteId) {
        await Promise.all([
          this.$store.dispatch('assets/getFocused', this.assetId),
          this.$store.dispatch('assets/getList')
        ]);
        // If asset is bad, redirect to assets page
        if (!this.asset || this.asset.siteId !== this.siteId) {
          this.asset = {};
          if (this.$route.path !== '/assets') {
            this.$router.push('/assets');
          }
        }
      }
    },
    handleUpdateAssets() {
      this.getDependencies();
    },
    handleUpdatedAsset() {
      this.asset = {};
      this.getDependencies();
    }
  }
};
</script>
