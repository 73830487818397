<template>
  <b-modal v-model="isBulkDuplicatingModalActive" :width="550" data-cy-test="bulk-duplicate-modal">
    <div class="card">
      <div class="card-content">
        <h4 class="title is-4" data-cy-test="bulk-duplicate-modal-title">
          Bulk Asset Creation
        </h4>
        <section>
          <p data-cy-test="bulk-duplicate-modal-description">Download the asset <a @click="handleTemplateDownload">template</a> to get started. Then, review the
            <a href="https://redventures.atlassian.net/wiki/spaces/COHSN/pages/99538208124/CSV+Bulk+Asset+Uploading"
               target="_blank"
            >instructions</a>, fill in the values for each asset you'd like to create and upload the completed .csv file below.</p>
          <p class="pt-4"><span class="has-text-weight-bold	">Note:</span> This template's asset variables are based on the original base asset.</p>
        </section>
        <div>
        </div>
        <section class="upload-content">
          <b-field v-if="file === null" class="file is-primary">
            <b-upload
              v-model="file"
              accept=".csv"
              drag-drop
              data-cy-test="bulk-duplicate-modal-upload-input"
              @input="handleCSVUpload"
            >
              <section class="upload-csv-section" data-cy-test="bulk-duplicate-modal-upload">
                <div class="content has-text-centered">
                  <span class="icon is-medium"><i class="fa fa-upload"></i></span>
                  <span data-cy-test="csv-file-message">Drop a .csv file here or Click to upload.</span>
                </div>
              </section>
            </b-upload>
          </b-field>
          <b-field v-else>
            <b-upload
              drag-drop
              type="is-danger"
            >
              <section class="upload-csv-section" data-cy-test="bulk-duplicate-modal-upload-input">
                <div class="tags is-justify-content-center">
                  <span v-if="file" class="tag is-info" data-cy-test="bulk-duplicate-modal-upload">
                    {{ file.name }}
                    <button
                      class="delete is-small"
                      type="button"
                      @click="deleteDroppedFile"
                    >
                    </button>
                  </span>
                </div>
              </section>
            </b-upload>
          </b-field>
          <section>
            <b-message
              v-if="invalidCSV"
              type="is-danger"
              has-icon
              data-cy-test="bulk-duplicate-modal-error"
              size="is-small"
              class="bulk-duplicate-error"
            >
              {{ errorMsg }}
            </b-message>
          </section>

        </section>
        <div class="level mt-3">
          <div class="level-left"></div>
          <div class="level-right">
            <div class="level-item">
              <b-button
                data-cy-test="bulk-duplicate-modal-cancel-button"
                @click="isBulkDuplicatingModalActive = false"
              >
                Cancel
              </b-button>
            </div>
            <div class="level-item">
              <b-button
                type="is-info"
                data-cy-test="bulk-duplicate-modal-create-button"
                :disabled="parsed === false || invalidCSV"
                @click="handleBulkDuplicationClick"
              >
                Create Assets
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="loading-bar">
        <div class="percentage"></div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Papa from 'papaparse';
import { downloadCsv, getDuplicateArrayValues } from '../../modules/utilities';

export default {
  name: 'BulkAssetCSVModal',
  props: {
    asset: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    formattedPreampVars: {
      type: Object,
      required: false
    },
    allAssets: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      file: null,
      csvContent: [],
      parsed: false,
      isDownloadingCsv: false,
      invalidCSV: false,
      errorMsg: '',
      csvAssets: [],
      nameError: false,
      variantError: false
    };
  },
  computed: {
    isBulkDuplicatingModalActive: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    areAssetsValid() {
      if (this.nameError || this.variantError) return false;

      return true;
    },
    isV2Asset() {
      return this.asset.version === 2;
    }
  },
  watch: {
    csvContent(newVal) {
      if (newVal) {
        this.handleNameValidation();
        this.checkVariantKeyValidity();
        this.createAssetVariablesToDuplicate();
      }
    }
  },
  methods: {
    deleteDroppedFile() {
      this.file = null;
      this.resetErrors();
    },
    resetErrors() {
      this.nameError = false;
      this.invalidCSV = false,
      this.variantError = false;
      this.errorMsg = '';
    },
    handleTemplateDownload() {
      this.isDownloadingCsv = true;
      this.asset.tags = !this.asset.tags ? 'n/a' : this.asset.tags;
      this.asset.variantKey = this.asset.variantKey === undefined ? 'n/a' : this.asset.variantKey;

      // create a comma separated list for the CSV if the tags are an array
      const tags = Array.isArray(this.asset.tags) ? this.asset.tags.join(',') : this.asset.tags;
      const csvItemsObj = {
        name: this.asset.name,
        assetTags: tags,
        variantKey: this.asset.variantKey
      };

      if (this.isV2Asset) {
        const variables = JSON.parse(this.asset.variables);
        for (const [varName, varValue] of Object.entries(variables)) {
          csvItemsObj[`${varName} (${varValue.type})`] = varValue.value;

          // for analyst convenience, we list out the options in the csv. these are ignored during parsing
          if (varValue.type === 'enum') {
            csvItemsObj[`${varName} OPTIONS - DO NOT MODIFY`] = varValue.options.join(', ');
          }
        }
      } else {
        // get v1 variables
        for (const fileType in this.formattedPreampVars.variables) {
          for (const [key, value] of Object.entries(this.formattedPreampVars.variables[fileType])) {
            csvItemsObj[`${fileType}-var:${key}`] = value;
          }
        }

        // get all SAE conditional variables and if they're enabled as the value
        for (const fileType in this.formattedPreampVars.optionalSections) {
          for (const section in this.formattedPreampVars.optionalSections[fileType]) {
            const sectionKey = `${fileType}-optional-section:${section}`;
            // create the section item
            csvItemsObj[sectionKey] = this.formattedPreampVars.optionalSections[fileType][section].enabled;
            // get and add the variables used in the section in a comma separated list
            csvItemsObj[`${fileType}-${section}-variables-used:${section}`] = this.formattedPreampVars.optionalSections[fileType][section].variablesUsed.join(',');
          }
        }
      }

      downloadCsv([csvItemsObj], Object.keys(csvItemsObj), `bulk-duplicate-${this.asset.name}.csv`);

      this.isDownloadingCsv = false;
    },
    async handleCSVUpload() {
      try {
        await Papa.parse(this.file, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            this.csvContent = results;
            this.csvAssets = this.csvContent.data;
            this.parsed = true;
          }
        });
      }
        catch (err) {
        err.title = `Could not get data for ${this.asset.name} from csv file`;
        this.$store.commit('error', err);
      }
    },
    handleNameValidation() {
      // check for name duplicates within CSV
      const csvAssetNames = this.csvAssets.map(a => a.name.toLowerCase());
      const duplicateNamesInCsv = getDuplicateArrayValues(csvAssetNames);
      // check for name duplicates - CSV vs all existing assets
      const allAssetNames = this.allAssets.map(a => a.name.toLowerCase());
      const duplicateNamesInPreamp = csvAssetNames.filter(val => allAssetNames.includes(val));
      const duplicateNames = duplicateNamesInCsv.concat(duplicateNamesInPreamp);

      // if there are any duplicates, set the error message
      if (duplicateNames.length > 0) {
        const duplicateExists = `The asset name "${duplicateNames}" already exists.\n`;
        const multipleDuplicates = `These asset names already exist: "${duplicateNames.join(', ')}"".\n`;

        this.invalidCSV = true;
        this.nameError = true;
        this.errorMsg = duplicateNames.length > 1 ? multipleDuplicates : duplicateExists;
      }

      // check for empty names in CSV
      if (csvAssetNames.some(a => !a || a === '')) {
        const missingNamesErrorMsg = 'All assets must have a name. Check your file for any blank name values';

        this.nameError = true;
        this.invalidCSV = true;
        this.errorMsg = this.errorMsg ? `${this.errorMsg} \n${missingNamesErrorMsg}` : missingNamesErrorMsg;
      }
    },
    getTypeVarErrors(variableObj, name, value) {
      const { type, options } = variableObj;
      switch(type) {
        case 'boolean':
          if (value.toLowerCase() !== 'true' && value.toLowerCase() !== 'false') return `${name} variable value must be TRUE or FALSE.`;
          return '';
        case 'number':
          if (isNaN(parseFloat(value))) return `${name} variable value must be a number`;
          return '';
        case 'enum':
          if (!options.includes(value)) return `Enum value ${value} must be included in options array.`;
          if (!value.length) return `${name} variable value must not be blank`;
          return '';
        case 'string':
          if (!value.length) return `${name} variable value must not be blank`;
          return '';
      }
    },
    createAssetsToBulkDuplicate() {
      const assetsToDuplicate = [];
      const assetArr = [ ...this.csvAssets ];

      for (const asset of assetArr) {
        const assetBaseObj = {...this.asset};
        assetBaseObj.name = asset.name;

        if (asset.variantKey === 'none') {
          assetBaseObj.variantKey = '';
        } else {
          assetBaseObj.variantKey = asset.variantKey;
        }

        if (asset.assetTags === 'none') {
          assetBaseObj.tags = [];
        } else if (asset.assetTags) {
          assetBaseObj.tags = asset.assetTags.split(',');
        }

        assetsToDuplicate.push(assetBaseObj);
      }

      return assetsToDuplicate;
    },
    getTypedVarValue(type, value) {
      switch(type) {
        case 'boolean':
          return value.toLowerCase() === 'true';
        case 'number':
          return parseFloat(value);
        case 'enum':
          return value && isNaN(value) ? value.toString() : parseFloat(value);
        default:
          return value.toString();
      }
    },
    createAssetVariablesToDuplicate() {
      const assetsArr = [...this.csvAssets];
      let allEditingVars = {};

      if (this.isV2Asset) {
        // loop over assets from array and create variables object
        for (const asset of assetsArr) {
          const editingVariables = {};
          const originalVariables = JSON.parse(this.asset.variables);

          for (const [key, value] of Object.entries(asset)) {
            const propsToSkip = ['name', 'assetTags', 'variantKey'];
            // skip enum options convenience values
            if (key.includes('DO NOT MODIFY') || propsToSkip.includes(key)) continue;

            // get the variable name from the CSV header
            const variableName = key.split(' ')[0];

            // display type to value variable errors if they exist on the csv
            const error = this.getTypeVarErrors(originalVariables[variableName], variableName, value);
            if (error.length) {
              this.invalidCSV = true;
              this.errorMsg = this.errorMsg.concat(error, '\n');
            }

            // create the new variable object
            editingVariables[variableName] = {
              type: originalVariables[variableName].type,
              description: originalVariables[variableName].description,
              value: this.getTypedVarValue(originalVariables[variableName].type, value)
            };

            // add options from original variable if it is an enum
            if (originalVariables[variableName].type === 'enum') {
              editingVariables[variableName].options = originalVariables[variableName].options;
            }
          }

          // finally add the asset name key and it's associated variables to the final object
          allEditingVars[asset.name] = editingVariables;
        }

      } else {
        // loop over all assets in the CSV
        for (const asset of assetsArr) {
          const varsUsed = {};
          const editingVariables = {
            variables: {},
            conditionals: {}
          };

          for (const [key, value] of Object.entries(asset)) {
            // check for regular variables and split into var name and value
            if (key.indexOf('-var:') !== -1) {
              const varName = key.split(':')[1];
              editingVariables.variables = {
                ...editingVariables.variables,
                [varName]:value
              };
            }
            // check for optional sections and build object
            if (key.indexOf('optional-section') !== -1) {
              const sectionName = key.split(':')[1];
              editingVariables.conditionals = {
                ...editingVariables.conditionals,
                [sectionName]: {
                  conditionals: {},
                  value: value.toLowerCase(), // PapaParse parses booleans to all uppercase
                  variablesUsed: []
                }
              };
            }
            // check for any optional variables create/add to array of the variables used in section
            if (key.indexOf('variables-used') !== -1) {
              const sectionName = key.split(':')[1];
              if (varsUsed[sectionName] && varsUsed[sectionName].length) {
                varsUsed[sectionName] = varsUsed[sectionName].concat(value.split(','));
              } else {
                varsUsed[sectionName] = value.split(',') ?? value;
              }
            }
          }

          // add the variables used to the appropriate section
          for (const section in editingVariables.conditionals) {
            if (editingVariables.conditionals[section] && varsUsed[section]) {
              editingVariables.conditionals[section].variablesUsed = varsUsed[section];
            }
          }

          // finally add the asset name key and it's associated variables to the final object
          allEditingVars[asset.name] = editingVariables;
        }
      }

      return allEditingVars;
    },
    checkVariantKeyValidity() {
      const status = {
        patternMismatch: false,
        notUnique: false,
        charLimitExceeded: false,
        emptyCells: false
      };

      const csvAssetVariants = this.csvAssets.map(a => a.variantKey);

      // only getting the assets that contain variant keys
      const assetsWithVariantKeys = csvAssetVariants.filter(v => v !== 'none');
      // variant key can not be duplicate within CSV or across all assets
      const duplicateVariantKeysInCsv = getDuplicateArrayValues(assetsWithVariantKeys);
      const duplicateVariantKeysinPreamp = assetsWithVariantKeys.filter(val => this.allAssets.map(a => a.variantKey).includes(val));
      const duplicateVariantKeys = duplicateVariantKeysInCsv.concat(duplicateVariantKeysinPreamp);

      const variantErrorMsg = duplicateVariantKeys.length > 1 ? `These variant keys: "${duplicateVariantKeys.join(', ')}" are not unique.` : duplicateVariantKeys.length === 1 ? `The variant key "${duplicateVariantKeys}" already exists.\n` : '';
      if (duplicateVariantKeys.length) {
        status.notUnique = true;
        this.invalidCSV = true;

        this.errorMsg = this.errorMsg ? `${this.errorMsg} \n${variantErrorMsg}` : variantErrorMsg;
      }

      // Explicity tell them about empty spaces
      const emptyVariantKeysPresent = csvAssetVariants.indexOf('') !== -1;
      const emptyCellsForVariantKeyErrMsg = 'No empty cells permitted, if you do not want a variant key please specify as "none" in the cell.';
      if (emptyVariantKeysPresent) {
        status.emptyCells = true;
        this.invalidCSV = true;

        this.errorMsg = this.errorMsg ? `${this.errorMsg} \n${emptyCellsForVariantKeyErrMsg}` : emptyCellsForVariantKeyErrMsg;
      }

      // variant key pattern mismatch
      const matches = csvAssetVariants.map(a => a.match(/^\w+$/g));
      const patternMismatchErrorMsg = 'Only letters, numbers, and underscores are accepted for variant keys. Spaces, hyphens, and special characters are not permitted.';
      if (matches.includes(null)) {
        status.patternMismatch = true;
        this.invalidCSV = true;

        this.errorMsg = this.errorMsg ? `${this.errorMsg} \n${patternMismatchErrorMsg}` : patternMismatchErrorMsg;
      }

      // variant key cannot exceed a character limit
      const varKeysOverCharLimit = csvAssetVariants.map(a => a.length > 128);
      const charLimitExceededErrorMsg = 'Check that no variant key exceeds a 128 character limit.';
      if (varKeysOverCharLimit.includes(true)) {
        status.charLimitExceeded = true;
        this.invalidCSV = true;

        this.errorMsg = this.errorMsg ? `${this.errorMsg} \n${charLimitExceededErrorMsg}` : charLimitExceededErrorMsg;
      }

      // if any of the status errors are true, add a variant error
      if (Object.values(status).some(v => v === true)) {
        this.variantError = true;
      }
    },
    async handleBulkDuplicationClick() {
      const assetsToDuplicate = await this.createAssetsToBulkDuplicate();
      const editingVariablesArr = await this.createAssetVariablesToDuplicate();

      if (!this.areAssetsValid) {
        return;
      }

      this.$emit('bulkDuplicateAsset', assetsToDuplicate, editingVariablesArr, this.asset.version);
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-csv-section {
  width: 90% !important;
  padding: 15px;
}
.upload-content {
  padding-top: 10px;
}

.bulk-duplicate-error {
  white-space: pre-line;
}
</style>
