import getAppEnv from '@/modules/getAppEnv';
import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import pathify, { make } from 'vuex-pathify';
import storage from '../modules/storage';
import { axiosInstance as axios } from '../plugins/axios';
import { experienceBuilderRoutes } from '../router';

// Modules
import addOns from './modules/addOns';
import assets from './modules/assets';
import experiences from './modules/experiences';
import folders from './modules/folders';
import integrations from './modules/integrations';
import placements from './modules/placements';
import tests from './modules/tests';

// Tell Vue to use the store
Vue.use(Vuex);

const state = {
  user: null,
  userRequested: false,
  userNotFound: false,
  userFailed: false,
  siteId: null,
  activeAccount: null,
  activeAccountSites: [],
  trafficFlowId: null,
  placements: null,
  siteLock: {
    owner: null,
    siteId: null,
    source: null
  },
  modals: {
    createAddOn: false,
    duplicateAddOn: false,
    editAddOn: false
  },
  errors: [],
  axiosActive: false,
  maintenanceMode: 0,
  edits: [],
  hasUnsavedChanges: null,
  editing: {
    test: null
  },
  archivables: null
};

// Export Store
export default new Store({
  state,
  mutations: {
    ...make.mutations(state),
    /**
     * Set the user data on login
     * @param {Object} data - user data from Preamp API
     */
    setUser(state, data) {
      state.user = data;
    },
    /**
     * Set the selected account
     * @param {String} account - account Id
     */
    setActiveAccount(state, account) {
      state.activeAccount = account;
      state.activeAccountSites = [];
      state.siteId = null;
    },
    /**
     * Set active account site list
     * @param {Array} sites - all sites from api
     */
    setAccountSites(state, sites) {
      const activeSites = sites.filter(function (site) {
        return site.archived !== 1;
      });
      state.activeAccountSites = activeSites;
    },
    /**
     * Set the selected site
     * @param {Object} data - site data from site list
     */
    setSiteId(state, data) {
      state.siteId = data;
    },
    setPlacements(state, data) {
      state.placements = data.sort(function (a, b) {
        return a.name < b.name ? -1 : 1;
      });
    },
    setAllAccounts(state, data) {
      state.allAccounts = data;
    },
    setAllUsers(state, data) {
      state.allUsers = data;
    },
    /**
     * Set the current lock for a site
     * @param {Object} lock - lock response
     */
    setSiteLock(state, lock) {
      state.siteLock = lock;
    },
    /**
     * Set the flag showing if a user has edited a test with unsaved changes
     * @param {Object} data - true/false
     */
    setHasUnsavedChanges(state, data) {
      state.hasUnsavedChanges = data;
    },
    /**
     * Set the status of an axios request
     * @param {Bool} status - true/false
     */
    setAxiosActive(state, status) {
      state.axiosActive = status;
    },
    setTrafficFlowId(state, id) {
      state.trafficFlowId = id;
    },
    error(state, error, title) {
      console.dir(error); // eslint-disable-line

      let errTitle = error.title;
      if (!error.title) {
        if (title !== undefined) {
          errTitle = title;
        } else {
          errTitle = 'Something went wrong...';
        }
      }

      const errObj = {
        time: Date.now(),
        title: errTitle,
        stack: error.stack || null,
        message: error.message || null
      };
      if (error.response) {
        errObj.status = error.response.status;
        errObj.statusText = error.response.statusText;
        errObj.data = error.response.data;
      }
      state.errors.push(errObj);
    },
    removeError(state, error) {
      state.errors = state.errors.filter(e => e.time !== error.time);
    },
    removeAllErrors(state) {
      state.errors = [];
    },
    /**
     * Toggle the maintenance mode state
     * @param {Number} status - 1 or 0 for active/inactive
     */
    setMaintenanceMode(state, status) {
      state.maintenanceMode = status;
    },
    /**
     * Add an item to the edit list
     * @param {Boolean} item - add item
     */
    addEditItem(state, item) {
      state.edits = state.edits.concat(item);
    },
    /**
     * Remove an item from teh edit list
     * @param {Boolean} item - remove item
     */
    removeEditItem(state, item) {
      state.edits = state.edits.filter(function (edit) {
        return edit !== item;
      });
    },
    /**
     * Remove all items from the edited list
     */
    clearEditItems(state) {
      state.edits = [];
    }
  },
  getters: {
    // Admin Env
    env() {
      return getAppEnv(location.host);
    },
    // Get whether a user is a super user
    isSuperUser(state) {
      return state.user && state.user.isSuper;
    },
    // Get whether a user is an admin
    isAdminUser(state) {
      return state.user && state.user.isAdmin;
    },
    // Get the users role for the current site
    userRoleForAccount(state) {
      if (!state.activeAccount) return null;
      if (state.user && state.user.isSuper) return 'admin';

      if (state.user !== null && state.user.accountRoles && Object.keys(state.user.accountRoles).length) {
        return state.user.accountRoles[state.activeAccount].toLowerCase();
      }
      return null;
    },
    // Check if user's role for currently selected site allows editing
    userCanEdit(state, getters) {
      if (state.siteId === null) return false;
      return !getters.userRoleForAccount.match(/guest|developer/i);
    },
    // Check if a user is a super user, an admin of any account, or a developer of any account
    canDownloadCdk(state) {
      if (!state.user) return false;
      if (state.user.isSuper) return true;
      const { accountRoles } = state.user;
      return Object.keys(accountRoles).some((accountId) => {
        return ['admin', 'developer'].includes(accountRoles[accountId].toLowerCase());
      });
    },
    // Check to see if the user owns the site lock
    siteLockOwner(state) {
      if (state.siteLock && state.user) {
        return state.siteLock.owner === state.user.email;
      }
      return false;
    },
    activeSite(state) {
      if (!state.activeAccountSites || !state.siteId) return null;
      return state.activeAccountSites.find(site => site.siteId === state.siteId);
    },
    siteDomain(state) {
      if (!state.activeAccountSites || !state.siteId) return null;
      return state.activeAccountSites.find(site => site.siteId === state.siteId).name;
    },
    siteTenantId(state) {
      if (state.siteId && state.activeAccountSites) {
        const site = state.activeAccountSites.find(s => s.siteId === state.siteId);
        if (site && site.tenantId) return site.tenantId;
      }
      return null;
    },
    // analytics is only available in US currently to sites which are not HIPAA compliant
    isInsightsAvailable(state, getters) {
      if (!state.activeAccountSites || !state.siteId) return false;
      if (getters.isSiteHIPAA) return false;

      const site = state.activeAccountSites.find(site => site.siteId === state.siteId);

      if (site.hasInsightsEnabled === 0) return false;

      const { regions: siteRegions } = site;

      let result = false;

      // sites have no regions property by default
      if (!site.regions) {
        result = true;
      } else if (Array.isArray(site.regions)) {
        if (site.regions.length === 0 || site.regions.indexOf('US') !== -1) {
          result = true;
        }
      }

      return result;
    },
    isSiteHIPAA(state) {
      if (state.siteId) {
        const activeSite = state.activeAccountSites.find(site => site.siteId === state.siteId);
        return activeSite.isHIPAA === 1;
      } else {
        return false;
      }
    },
    hasSiteDestination: state => (destinationType) => {
      if (state.siteId) {
        const activeSite = state.activeAccountSites.find(site => site.siteId === state.siteId);
        const siteDestinationType = activeSite.destination?.type ?? 'make'; // default is make
        return siteDestinationType === destinationType;
      } else {
        return false;
      }
    },
    isExperienceBuilder: () => (routeName) => {
      return experienceBuilderRoutes.includes(routeName);
    },
    canCreateMabExpTest(state, getters) {
      return !getters.isSiteHIPAA;
    },
    isDMPEnabled(state, getters) {
      return !getters.isSiteHIPAA;
    },
    isMakeEnabled(state, getters) {
      return !getters.isSiteHIPAA && getters.hasSiteDestination('make');
    },
    isSegmentEnabled(state, getters) {
      return !getters.isSiteHIPAA;
    },
    isJarvisEnabled(state, getters) {
      return !getters.isSiteHIPAA;
    },
    isContentEnabled(state, getters) {
      return !getters.isSiteHIPAA;
    },
    isTIPEnabled(state, getters) {
      return !getters.isSiteHIPAA;
    },
    isTaggyEnabled(state, getters) {
      return !getters.isSiteHIPAA;
    }
  },
  actions: {
    // deferActionForSiteID should be dispatched when a referring action is dispatched before a store dependency
    //  has been hydrated from local storage, resulting in a null value being passed to the referring action for siteID.
    //  deferActionForSiteID will watch the store for a siteID, and then dispatch the action at the refPath that is passed in.
    async deferActionForSiteID({ dispatch }, { refPath, payload } ) {
      window.vm.$store.watch(state => state.siteId, (siteID) => {
        if (siteID) {
          dispatch(refPath, payload);
        }
      });
    },

    async updateUser({ state, dispatch }, user) {
      const payload = {
        user: {
          email: user.email,
          accountRoles: user.accountRoles,
          isSuper: user.isSuper
        }
      };

      const res = await axios.put(`/user/${user.email}`, payload);

      window.vm.$buefy.toast.open({
        message: `Successfully updated ${res.data.user.email}`,
        type: 'is-success'
      });

      // Refresh the current user if "self" was updated
      if (user.email === state.user.email) {
        try {
          await dispatch('getCurrentUser');
        } catch (err) {
          console.log(err);
        }
      }

      return res.data.user;
    },

    async getCurrentUser({ commit }) {
      try {
        let res = null;
        try {
          res = await axios.get('/info/me');
        } catch (err) {
          if (!err.response) throw err;
          // If the user is unauthorized, stop and let App.vue show login page
          if (err.response.status === 401) return;
          // If the token was authenticated, but unauthorized, let App.vue render blank app
          if (err.response.status === 403) {
            commit('SET_USER_NOT_FOUND', true);
            return;
          }
          // With any other status code, throw the error
          throw err;
        }
        const userData = { ...res.data.user };
        userData.isPreampUser = true;
        userData.isAdmin = false;
        userData.accounts = [];

        if (Array.isArray(res.data.accounts)) {
          userData.accounts = res.data.accounts.filter(a => !a.archived);
        }

        // Is this user an admin of any accounts
        for (const account in userData.accountRoles) {
          if (userData.accountRoles[account].toLowerCase() === 'admin') {
            userData.isAdmin = true;
            break;
          }
        }

        // Check stored account validity
        const storedAccount = storage.exists('selectedAccount') ? storage.get('selectedAccount') : null;
        if (storedAccount !== null && !userData.accounts.find(obj => obj.id === storedAccount)) {
          // account is not part of users list, remove stored data
          storage.remove('selectedAccount', 'selectedSite');
        }

        commit('SET_USER', userData);
        return userData;
      } catch (err) {
        commit('SET_USER_FAILED', true);
        commit('error', err, '[actions.getCurrentUser] Could not get current user');
      }
    },

    async getArchivables({ commit, state, dispatch }) {
      try {
        if (!state.siteId) {
          dispatch('deferActionForSiteId', { refPath: 'getArchivables' });
          return;
        }

        const { data: archivables } = await axios.get(`/sites/${state.siteId}/archivables`);
        commit('SET_ARCHIVABLES', archivables);
        state.archivables = archivables;
      } catch (err) {
        commit('error', err, '[actions.getArchivables] Could not get archivables');
      }
    }
  },
  strict: ['prod', 'production'].indexOf(process.env.NODE_ENV) === -1,
  plugins: [pathify.plugin],
  modules: {
    experiences,
    placements,
    tests,
    addOns,
    assets,
    integrations,
    folders
  }
});
