<template>
  <div class="experience-builder" data-cy-test="experience-builder">
    <nav
      class="navbar is-white is-fixed-top"
    >
      <div class="navbar-brand">
        <img
          src="../assets/preamp-logo.svg"
          alt="Preamp"
          style="height: 30px"
        >
      </div>
      <div class="navbar-menu is-active">
        <div class="url">
          <b-field>
            <b-tooltip
              class="url-tooltip"
              position="is-bottom"
              type="is-info"
              label="Trying to navigate to a specific page? Use the Experience URL field in the sidebar."
              multilined
              expanded
            >
              <b-input
                :placeholder="displayUrl"
                label="tring to navigate"
                data-cy-test="navbar-url"
                icon="lock"
                disabled
              >
              </b-input>
            </b-tooltip>
          </b-field>
        </div>
        <div class="share">
          <button
            v-clipboard="() => clipboardUrl"
            v-clipboard:success="handleClipboardSuccess"
            class="button is-info"
            data-cy-test="share-preview"
          > Share Preview
          </button>
        </div>
        <div class="navbar-end">
          <button
            class="button"
            data-cy-test="sidebar-toggle"
            @click="toggleSidebar"
          >
            <i :class="sidebar ? 'fa fa-expand' : 'fa fa-compress'"></i>
          </button>
        </div>
      </div>
    </nav>
    <div class="experience">
      <ExperienceSidebar
        v-if="!isDuplicating || Object.keys(experience).length"
        :isCreating="!isEditing"
        :isDuplicating="isDuplicating"
        :experience="experience"
        :initialExperience="initialExperience"
        :sidebarOpen="sidebar"
        :previewUrl="previewUrl"
        :displayUrl="displayUrl"
        @routingUrl="getRoutingUrl"
        @experienceUrl="handlePreview"
        @handleUpdatePreview="handlePreview"
        @handleNoSelections="handlePreview"
        @createExperience="createExperience"
        @updateExperience="updateExperience"
      />

      <div class="experience-iframe">
        <iframe
          v-if="siteDomain"
          :key="previewId"
          data-cy-test="iframe-source"
          :src="previewUrl" class="iframe"
        >
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
import getAppEnv from '@/modules/getAppEnv';
import shortUUID from 'short-uuid';
import { sync } from 'vuex-pathify';
import ExperienceSidebar from '../components/ExperienceBuilder/ExperienceSidebar';
import auth from '../modules/auth';
import { clone } from '../modules/utilities';

const appEnv = getAppEnv(window.location.host);

export default {
  name: 'ExperienceBuilder',
  components: {
    ExperienceSidebar
  },
  data() {
    return {
      experience: null,
      sidebar: true,
      hasRoutingAddOn: false,
      routingUrl: '',
      previewId: null,
      displayUrl: '',
      isSavingExperience: false
    };
  },
  computed: {
    initialExperience: sync('experiences/focused'),
    siteDomain() {
      if (this.$store.getters.siteDomain) {
        return `https://${this.$store.getters.siteDomain}`;
      }
      else return localStorage.getItem('siteDomain');
    },
    siteId() {
      return this.$store.state.siteId;
    },
    getProxyUrl() {
      if (appEnv == 'STAGING') {
        return 'staging.proxy.cohesionapps.com';
      }
      else if (appEnv == 'PROD') {
        return 'proxy.cohesionapps.com';
      }
      else {
        return 'development.proxy.cohesionapps.com';
      }
    },
    previewUrl() {
      const url = new URL(this.displayUrl);

      url.searchParams.set('proxy_origin', url.origin);
      url.searchParams.set('jwt', auth.getJwt());
      url.searchParams.set('previewHide', 'true');

      if (this.hasAssets && this.previewId) {
        url.searchParams.set('!preamp', this.previewId);
      } else {
        url.searchParams.set('exp', '0');
      }

      url.hostname = this.getProxyUrl;

      return url.toString();
    },
    clipboardUrl() {
      const url = new URL(this.displayUrl.toString());
      if (this.hasAssets && this.previewId) {
        url.searchParams.set('!preamp', this.previewId);
      } else {
        url.searchParams.set('exp', '0');
      }

      return url.toString();
    },
    experienceId() {
      return this.$route.params.experienceId;
    },
    isEditing() {
      return !!this.experienceId;
    },
    isDuplicating() {
      return window.location.href.includes('duplicate');
    },
    hasAssets() {
      const hasValidPlacement = this.experience.assetPlacements?.every(selection => selection.placement);
      return this.experience.assetPlacements?.length > 0 && hasValidPlacement;
    }
  },
  created() {
    this.resetExperience();
    if (this.initialExperience.previewUrl) {
      this.displayUrl = this.initialExperience.previewUrl;
    } else {
      this.displayUrl = this.siteDomain;
    }
    this.handlePreview();
    if (this.isEditing) {
      this.$store.dispatch('experiences/getFocused');
    }
  },
  methods: {
    toggleSidebar() {
      this.sidebar ? this.sidebar=false : this.sidebar=true;
    },
    handleClipboardSuccess() {
      this.$buefy.toast.open({
        message: 'copied to clipboard',
        type: 'is-success'
      });
    },
    resetExperience() {
      // New Experience Template
      let newExp = {
        name: '',
        tags: [],
        assetPlacements: []
      };
      // Editing or Duplicating; Modify template
      if (this.isEditing || this.isDuplicating) {
        newExp = clone(this.initialExperience);
        if (this.isDuplicating) {
          // append a string to the name to tell user it's the copy
          newExp.name = newExp.name ? `${newExp.name} Copy` : `Experience ${newExp.experienceNumber} Copy`;
          // remove unneeded properties
          delete newExp.experienceId;
          delete newExp.isChampion;
          delete newExp.isHoldOut;
          delete newExp.experienceNumber;
        }
      }
      this.experience = clone(newExp);
    },
    getRoutingUrl(hasRouting, url) {
      this.hasRoutingAddOn = hasRouting;
      if (this.hasRoutingAddOn) {
        this.displayUrl = url;
      } else if (this.experience.previewUrl) {
        this.displayUrl = this.experience.previewUrl;
      } else {
        this.displayUrl = this.siteDomain;
      }
    },
    handlePreview() {
      // Set display URL for the preview.
      if (this.experience.previewUrl) {
        this.displayUrl = this.experience.previewUrl;
      }

      // Only create/update previews if we have assets to show.
      if (!this.hasAssets) {
        return;
      }

      if (!this.previewId) {
        this.handleCreatePreview();
      } else {
        this.handleUpdatePreview();
      }
    },
    // Store a preview of the configured placements/assets.
    async handleCreatePreview() {
      this.previewLoading = true;
      const submission = {
        previewId: shortUUID.generate(),
        payload: []
      };
      if (this.hasAssets) {
        submission.payload = this.experience.assetPlacements.map(function (s) {
          return {
            assetId: s.asset.assetId,
            placementId: s.placement.placementId
          };
        });
      }

      try {
        await this.$axios.post(`/sites/${this.siteId}/preview`, submission);
        this.previewId = submission.previewId;
      } catch (err) {
        err.title = 'There was a problem creating a new preview';
        this.$store.commit('error', err);
      }
      this.previewLoading = false;
    },
    async handleUpdatePreview() {
      this.previewLoading = true;
      const submission = {
        newPreviewId: shortUUID.generate(),
        payload: []
      };
      if (this.hasAssets) {
        submission.payload = this.experience.assetPlacements.map(function (s) {
          return {
            assetId: s.asset.assetId,
            placementId: s.placement.placementId
          };
        });
      }
      try {
        await this.$axios.put(`/sites/${this.siteId}/preview/${this.previewId}`, submission);
        this.previewId = submission.newPreviewId;
      } catch (err) {
        err.title = 'There was a problem creating a new preview';
        this.$store.commit('error', err);
      }
      this.previewLoading = false;
    },
    formatSelections() {
      return this.experience.assetPlacements.map(function (selection) {
        return {
          placementId: selection.placement.placementId,
          assetId: selection.asset.assetId
        };
      });
    },
    formatExperiencePayload() {
      // format selections for POST
      this.experience.assetPlacements = this.formatSelections();
      this.experience.siteId = this.siteId;
    },
    async updateExperience() {
      this.isSavingExperience = true;
      try {
        if (this.hasAssets || this.experience.addOns) {
          this.formatExperiencePayload();
        }
        await this.$store.dispatch('experiences/updateExperience', this.experience);
      } finally {
        this.isSavingExperience = false;
        this.$router.push(`/experiences/${this.experience.experienceId}`);
      }
    },
    async createExperience() {
      try {
        this.isSavingExperience = true;
        if (this.hasAssets || this.experience.addOns) {
          this.formatExperiencePayload();
          if (this.isDuplicating) {
            // remove unneeded properties
            delete this.experience.experienceId;
            delete this.experience.isChampion;
            delete this.experience.isHoldOut;
            delete this.experience.experienceNumber;
          }
          delete this.experience.isActive;
          delete this.experience.createdAt;
          await this.$store.dispatch('experiences/createExperience', this.experience);
        }
      } finally {
        this.isSavingExperience = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.experience-builder {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  border: none;
  margin: 0;
  .navbar {
    height: 60px;
    border-bottom: 1px solid #EDF0F3;
    .share {
      padding-top: 10px;
    }
    &-brand {
      padding: 15px 51px 0px 15px;
      min-width: 300px;
      border-right: 1px solid #EDF0F3;
    }
    &-end {
      padding: 12px 15px 3px;
      .button {
        background-color: #EDEFF3;
      }
    }
    .url {
      padding: 12px 15px 3px;
      flex-grow: 1;
      flex-shrink: 1;
      &-tooltip {
        min-width: 100%;
      }
    }
  }
  .experience {
    position: relative;
    padding-top: 60px;
    width: 100%;
    height: 100%;
    .experience-iframe {
      display: flex;
      height: 100%;
      .iframe {
      height: 100%;
      width: 100%;
      }
    }
  }
}
</style>
