<template>
  <div class="dropdown-box">
    <b-dropdown
      v-model="filters"
      multiple
      :scrollable="true"
      :max-height="400"
      @active-change="updateSelected"
    >
      <b-button slot="trigger">
        <div class="level">
          <span
            v-if="filters.length === 0"
            class="level-left"
          >All {{ filterItem }}s Selected</span>
          <span
            v-else-if="filters.length === 1"
            class="level-left"
          >{{ filters[0] }}</span>
          <span
            v-else
            class="level-left"
          >{{ filters[0] }}, +{{ filters.length - 1 }} more</span>
          <b-icon
            icon="angle-down"
            type="is-info"
            class="level-right"
          />
        </div>
      </b-button>
      <!-- Promote previously selected to the top -->
      <template v-for="selection in selected">
        <b-dropdown-item
          :key="selection"
          :value="selection"
        >
          <span>{{ selection }}</span>
        </b-dropdown-item>
      </template>
      <!-- Render items not selected after -->
      <template v-for="item in filterOptions">
        <b-dropdown-item
          v-if="!selected.includes(item)"
          :key="item"
          :value="item"
        >
          <span>{{ item }}</span>
        </b-dropdown-item>
      </template>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'FilterMultiDropdown',
  props: ['value', 'filterOptions', 'filterItem'],
  data() {
    return {
      selected: []
    };
  },
  computed: {
    filters: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },
  methods: {
    /**
     * To promote selected elements to the top on dropdown event
     */
    updateSelected(open) {
      if (open) {
        this.selected = [...this.filters];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-box {
  div, button {
    min-width: 22rem;
  }
}
</style>
