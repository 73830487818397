<template>
  <div class="field is-grouped">
    <div class="control is-expanded">
      <div class="select is-fullwidth">
        <select
          v-model="selectedAccount"
          :disabled="accounts.length === 0"
        >
          <option
            :value="null"
            disabled
          >Select an Account</option>
          <option
            v-for="(account) in accounts"
            :key="`account-${account.accountId}`"
            :value="account.accountId"
          >
            {{ account.name }}
          </option>
        </select>
      </div>
    </div><!-- /.control -->

    <div class="control is-expanded">
      <div class="select is-fullwidth">
        <select
          v-model="selectedRole"
          :disabled="selectedAccount === null"
        >
          <option
            :value="null"
            disabled
          >Select a Role</option>
          <option
            v-for="(role, index) in roles"
            :key="index"
            :value="role"
          >
            {{ role }}
          </option>
        </select>
      </div>
    </div><!-- /.control -->

    <div class="control">
      <button
        class="button is-info"
        :disabled="!isValid"
        @click.stop="saveUserRole"
      >
        <span class="icon">
          <i class="fa fa-plus"></i>
        </span>
        <span>Add Role</span>
      </button>
    </div><!-- /.control -->
  </div><!-- /.field -->
</template>

<script>
export default {
  name: 'NewUserRole',
  props: {
    accounts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedAccount: null,
      selectedRole: null,
      roles: [
        'Analyst',
        'Developer',
        'Admin',
        'Guest'
      ]
    };
  },
  computed: {
    // Validate both fields before allowing emission
    isValid() {
      return this.selectedAccount !== null && this.selectedRole !== null;
    }
  },
  methods: {
    // Emit the selected account/role
    saveUserRole() {
      const payload = {
        id: this.selectedAccount,
        role: this.selectedRole
      };
      this.$emit('addRole', payload);

      // Reset
      this.selectedRole = null;
      this.selectedAccount = null;
    }
  }
};
</script>
