import { make } from 'vuex-pathify';
import { axiosInstance as axios } from '@/plugins/axios';

const state = {
  tree: {},
  openFolder: {},
  folderContents: [],
  isLoading: false
};

const mutations = make.mutations(state);

const actions = {
  async getTree({ dispatch, commit, rootState }) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'folders/getTree' }, { root: true });
      return;
    }

    try {
      commit('SET_IS_LOADING', true);

      const { data: folderTree } = await axios.get(`/sites/${rootState.siteId}/folders`);

      commit('SET_TREE', folderTree);

      // set open folder to Unassigned (root) if openFolder is not set
      if (folderTree && !state.openFolder.folderId) {
        commit('SET_OPEN_FOLDER', folderTree.root);
      }
    } catch (e) {
      e.title = 'There was a problem retrieving folders for this site.';
      commit('error', e, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async getFolderById({ commit, rootState, dispatch }, folder) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'folders/getFolderById', payload: folder }, { root: true });
      return;
    }

    const { folderId } = folder;

    try {
      commit('SET_IS_LOADING', true);
      const res = await axios.get(`/sites/${rootState.siteId}/folders/${folderId}`);

      commit('SET_OPEN_FOLDER', res.data);
    } catch (e) {
      e.title = 'There was a problem retrieving details for this folder.';
      commit('error', e, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async createFolder({ commit, rootState, dispatch }, folderName) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'folders/createFolder', payload: folderName }, { root: true });
      return;
    }

    try {
      const response = await axios.post(`/sites/${rootState.siteId}/folders`, {name: folderName, parentFolderId: 'root'});
      const { data: tree } = response;
      commit('SET_TREE', tree);

      window.vm.$buefy.toast.open({
        message: `New Folder ${folderName} created`,
        type: 'is-success'
      });

      return response;
    } catch (error) {
      error.title = 'There was a problem creating a new folder.';
      error.message = error.response.data || 'An error has occurred.';
      commit('error', error, { root: true });
      return error.response;
    }
  },
  async updateFolder({ commit, rootState, dispatch, state }, params) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'folders/updateFolder', payload: params }, { root: true });
      return;
    }

    const { folder, name } = params;

    try {
      const response = await axios.put(`/sites/${rootState.siteId}/folders/${folder.folderId}`, params);
      const { folderTree } = response?.data ?? {};

      commit('SET_TREE', folderTree);

      const updatedFolder = folderTree.root.folders.find(updated => updated.folderId === state.openFolder.folderId)
        || folderTree.root;

      commit('SET_OPEN_FOLDER', updatedFolder);

      // moving assets alert
      if (params.newContents) {
        window.vm.$buefy.toast.open({
          message: `Selected assets were moved to ${folder.name}.`,
          type: 'is-success'
        });
      } else { // updating folder name alert
        window.vm.$buefy.toast.open({
          message: `Folder ${name} successfully updated.`,
          type: 'is-success'
        });
      }

      return updatedFolder;
    } catch (error) {
      error.title = `Could not update folder: ${folder.name}`;
      error.message = error.response.data || 'An error has occurred.';
      commit('error', error, { root: true });
      return error.response;
    }
  },
  async moveItemsToFolder({ commit, rootState, dispatch, state }, params) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'folders/moveItemsToFolder', payload: params }, { root: true });
      return;
    }

    const { destinationFolder, contents } = params;

    try {
      const response = await axios.patch(`/sites/${rootState.siteId}/folders/${destinationFolder.folderId}/contents`, { contents });
      const updatedFolderTree = response.data;
      commit('SET_TREE', updatedFolderTree);

      // moving assets alert
      window.vm.$buefy.toast.open({
        message: `${contents.length} asset(s) were moved to ${destinationFolder.name}.`,
        type: 'is-success'
      });

      return updatedFolderTree;
    } catch (error) {
      error.title = `Could not update folder: ${destinationFolder.name}`;
      error.message = error.response.data || 'An error has occurred.';
      commit('error', error, { root: true });
      return error.response;
    }
  },
  async deleteFolder({ commit, rootState, dispatch }, folder) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'folders/deleteFolder', payload: folder }, { root: true });
      return;
    }

    try {
      const { data: tree } = await axios.delete(`/sites/${rootState.siteId}/folders/${folder.folderId}`);
      commit('SET_TREE', tree);
      commit('SET_OPEN_FOLDER', tree.root);

      window.vm.$buefy.toast.open({
        message: `Folder ${folder.name} successfully deleted. Contained assets moved to Unassigned`,
        type: 'is-success'
      });

      return tree;
    } catch (error) {
      error.title = `Could not delete folder: ${folder.name}`;
      error.message = error.response.data || null;

      commit('error', error, { root: true });
    } finally {
      dispatch('getTree');
    }
  },
  async getFolderContents({ commit, rootState, dispatch }, folderId) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'folders/getFolderContents', payload: folderId }, { root: true });
      return;
    }

    try {
      commit('SET_IS_LOADING', true);
      const { data: contents } = await axios.get(`/sites/${rootState.siteId}/folders/${folderId}/contents`);

      commit('SET_FOLDER_CONTENTS', contents);
      return contents;
    } catch (e) {
      e.title = 'There was a problem getting assets for this folder.';
      commit('error', e, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  }
};

const getters = {
  allFolders(state) {
    return (state.tree.root && state.tree.root.folders) ? state.tree.root.folders.concat(state.tree.root) : [];
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
