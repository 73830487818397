<template>
  <section class="insights__test-rankings">
    <div class="tile is-ancestor">
      <div class="tile is-4 is-vertical is-parent">
        <div class="tile is-child notification is-info">
          <div
            v-if="rank.metrics"
            class="insights__test-rankings-rank-divider"
          >
            <h2
              v-if="isLoading"
              class="title is-2"
            >Loading...</h2>
            <h2
              v-else-if="rank.position > 0"
              class="title is-2 insights__test-rankings-rank bottom0"
            >
              <sup class="pound">#</sup>
              <span class="rank-number">{{ formatRank(rank.position) }}</span>
            </h2>
            <p
              v-if="ranks"
              class="heading has-text-right"
              style="font-size: 16px;"
            >
              Out of <strong>{{ ranks.length }}</strong> sites
            </p>
          </div>

          <h6
            v-if="!isLoading && !rank.metrics"
            class="title is-6"
          >No data found for {{ siteDomain }}</h6>

          <hr>

          <h5 class="title is-5">How does your site compare?</h5>

          <table
            v-if="rank.metrics"
            class="table is-fullwidth insights__test-rankings-table"
          >
            <thead>
              <tr>
                <th></th>
                <th><span class="heading">You</span></th>
                <th><span class="heading">RV Average</span></th>
                <th><span class="heading">RV Max</span></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th><span class="heading">Test Percent</span></th>
                <td><span class="title">{{ typeof rank.metrics.ratio === 'number' ? formatRatio(rank.metrics.ratio) : 'N/A' }}<sup>%</sup></span></td>
                <td><span class="title">{{ formatRatio(avgTestPercentage / 100) || 'N/A' }}<sup>%</sup></span></td>
                <td><span class="title">{{ formatRatio(maxTestPercentage) || 'N/A' }}<sup>%</sup></span></td>
              </tr>
              <tr>
                <th><span class="heading">Test Count</span></th>
                <td><span class="title">{{ typeof rank.metrics.runningTestCount === 'number' ? rank.metrics.runningTestCount : 'N/A' }}</span></td>
                <td><span class="title">{{ Math.floor(avgTestCount) || 'N/A' }}</span></td>
                <td><span class="title">{{ maxTestCount || 'N/A' }}</span></td>
              </tr>
              <tr>
                <th><span class="heading">Test Score</span></th>
                <td><span class="title">{{ formatRatio(rank.score) }}</span></td>
                <td><span class="title">{{ formatRatio(avgTestScore) }}</span></td>
                <td><span class="title">{{ formatRatio(topScore) }}</span></td>
              </tr>
            </tbody>
          </table>

          <div class="content">
            <p>The <strong>Test Score</strong> is calculated using each site's <strong>percentage of traffic going through a test</strong>, combined with their <strong>total number of running tests</strong>.</p>
          </div>
        </div>
      </div>
      <div class="tile is-parent">
        <div class="tile is-child box">
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <h5 class="title is-5">Testing Ranks</h5>
              </div>
            </div>

            <div class="level-right"></div>
          </div><!-- /.level -->

          <div
            id="testing-rank-table"
            class="is-relative chart-parent insights__test-rankings-table-container"
          >
            <table class="table is-narrow is-fullwidth is-bordered">
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Site</th>
                  <th style="width: 180px;">% Volume Tested</th>
                  <th style="width: 150px;">Running Tests</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody v-if="ranks">
                <tr
                  v-for="(site, index) in sortedRanks"
                  :key="`insights-${site.site}-${index}`"
                  :class="{ 'is-selected': rank.metrics && rank.position === index + 1 }"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ site.site }}</td>
                  <td>{{ Math.floor(site.ratio * 100) }}%</td>
                  <td>{{ site.runningTestCount }}</td>
                  <td>{{ formatRatio(site.wRatio) }}</td>
                </tr>
              </tbody>
            </table>

            <b-loading
              :active.sync="isLoading"
              :is-full-page="false"
            ></b-loading>
          </div>

          <p
            class="help has-text-right"
            style="margin-top: 10px; margin-bottom: 0;"
          ><em>&ast;Only sites with a weighted score above <strong>0</strong> are listed in the testing ranks.</em></p>
          <p
            class="help has-text-right"
            style="margin: 0;"
          ><em>&ast;MVT tests containing only one experience are excluded from the score calculation.</em></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TestingRank',
  data() {
    return {
      avgTestCount: 0,
      maxTestCount: 0,
      avgTestPercentage: 0,
      maxTestPercentage: 0,
      avgTestScore: 0,
      ranks: null,
      rank: {
        position: 0,
        score: 0,
        metrics: null
      },
      isLoading: false
    };
  },
  computed: {
    siteId() {
      return this.$store.state.siteId;
    },
    siteDomain() {
      return this.$store.getters.siteDomain;
    },
    sortedRanks() {
      if (!this.ranks) return [];

      const ranksAbove0 = this.ranks.filter(function (rank) {
        return rank.wRatio > 0;
      });

      return ranksAbove0.sort(function (a, b) {
        return b.wRatio - a.wRatio;
      });
    },
    topScore() {
      if (this.sortedRanks.length === 0) return 0;

      return this.sortedRanks[0].wRatio;
    }
  },
  watch: {
    // this watcher will update charts if the site changes
    siteId(val) {
      if (val) {
        this.getSiteRankings();
      } else {
        this.chartData = null;
        this.totalTraffic = false;
      }
    }
  },
  created() {
    if (this.siteId) {
      this.getSiteRankings();
    }
  },
  methods: {
    async getSiteRankings() {
      this.isLoading = true;

      try {
        const res = await this.$axios.get(`/sites/${this.siteId}/insights/testing-ranks`);
        this.avgTestCount = res.data.avgTestCount;
        this.maxTestCount = res.data.maxTestCount;
        this.avgTestPercentage = res.data.avgTestPercentage;
        this.maxTestPercentage = res.data.maxTestPercentage;
        this.avgTestScore = res.data.avgTestScore;
        this.ranks = res.data.metrics;

        // wait until the call stack is cleared to process rank data
        setTimeout(() => {
          this.getCurrentSiteRank(this.sortedRanks);
        }, 0);
      } catch (error) {
        this.$store.commit('error', error);
      }

      this.isLoading = false;
    },
    // take the current selected site and find it's rank among the rank results
    getCurrentSiteRank(allRankings) {
      this.rank = {
        position: 0,
        score: 0,
        metrics: null
      };

      for (let i = 0, len = allRankings.length; i < len; i++) {
        const siteRank = allRankings[i];

        if (siteRank.site === this.siteDomain) {
          this.rank.position = i + 1;
          this.rank.score = siteRank.wRatio;
          this.rank.metrics = siteRank;
          break;
        }
      }

      // give time for vue to render these table rows
      if (this.rank.score > 0) {
        setTimeout(this.scrollRankTable, 500);
      }
    },
    formatRatio(ratio) {
      if (ratio === 0) return ratio;

      return Math.round(ratio * 1000) / 10;
    },
    // prepend top ranks with 0 to keep more-consistent spacing. #1 -> #001
    formatRank(rankNumber) {
      const maxRankLength = String(this.sortedRanks.length).length;
      const rankLength = String(rankNumber).length;

      if ((maxRankLength === 2 && rankLength === 1) || (maxRankLength === 3 && rankLength === 2)) {
        return `0${rankNumber}`;
      } else if (maxRankLength === 3 && rankLength === 1) {
        return `00${rankNumber}`;
      } else {
        return rankNumber;
      }
    },
    // scroll the table so that the user's selected site is in the view
    scrollRankTable() {
      // this.$el is defined if component has rendered
      if (this.$el) {
        const $scrollbox = document.getElementById('testing-rank-table');
        const $table = $scrollbox.children[0];
        const $selectedRow = $table.querySelector('tbody tr.is-selected');
        const heightCorrection = $selectedRow.clientHeight * 3;
        const ot = $selectedRow.offsetTop;

        let scrollTop = 0;
        if (heightCorrection > ot) {
          // don't scroll, element is near top
          return;
        } else if (ot + heightCorrection >= $table.clientHeight) {
          // don't scroll, element is near bottom
          scrollTop = Math.floor(ot + $scrollbox.clientHeight);
        } else {
          scrollTop = Math.round(ot / 2 + heightCorrection);
        }

        $scrollbox.scrollBy({ top: scrollTop, left: 0, behavior: 'smooth' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.insights__test-rankings {
  align-items: stretch;
  flex-wrap: wrap-reverse;

  &-chart {
    flex: 1;
    margin: 5px;
    // min-width: 680px;
  }

  &-info {
    flex: 1;
    // max-width: 370px;
  }

  &-rank {
    padding-left: 35px;
    position: relative;

    .pound {
      font-size: 50px;
      position: absolute;
      left: 0;
      top: -5px;
    }

    .rank-number {
      font-size: 85px;
    }

    &-divider {
      max-width: 350px;
    }
  }

  &-table-container {
    height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &-table {
    background-color: transparent;
    color: white;
    min-width: 350px;

    th {
      color: white;
      text-align: left;
      vertical-align: middle;
    }

    thead {
      th:nth-child(2) {
        border-bottom: 2px solid white;
      }
    }

    tbody {
      td, th {
        padding: 0.5rem;
        text-align: left;
      }

      tr {
        th:first-child {
          text-align: right;
          width: 55px;
        }

        td:nth-child(2) {
          border-left: 2px solid white;
          border-right: 2px solid white;
        }
      }

      tr:last-child {
        td:nth-child(2) {
          border-bottom: 2px solid white;
        }
      }
    }

    th, td {
      border-color: transparent;
      vertical-align: middle;

      .title {
        font-size: 24px;

        sup {
          font-size: 0.65em;
        }
      }
    }
  }
}
.notification.is-info {
  hr {
    margin: .75em 0;
  }

  blockquote {
    background-color: darken(#2398e8, 4%);
    border-color: whitesmoke;
    padding: 1em;
  }
}

table.table tbody tr.is-selected {
  background-color: #1987e8;
}
</style>
