<template>
  <section>
    <div class="optionalVariableSection">
      <div class="level">

        <div class="level-left">
          <div class="level-item">
            <div class="mb-2">
              <strong>{{ sectionName }}</strong>
            </div>
          </div>
        </div>

        <div class="level-right">
          <div class="level-item" :data-cy-test="`optional-section-${sectionName}-enabled`">
            <b-switch
              v-if="showToggle"
              v-model="section.value"
              size="is-small"
            >
              Enabled
            </b-switch>
          </div>
        </div>
      </div>

      <div v-if="section.value">
        <variable-input
          v-for="varName of section.variablesUsed"
          :key="varName"
          v-model="assetVariables.variables[varName]"
          :data-cy-test="`optional-section-${sectionName}-input-${varName}`"
          :name="varName"
          :nullable="!section.value"
          :disabled="disabledInputs"
        />
      </div>

    </div>
  </section>
</template>

<script>
import VariableInput from './VariableInput.vue';

export default {
  name: 'optional-variable-section',
  components: {
    'variable-input': VariableInput
  },
  props: {
    sectionName: {
      type: String,
      required: true
    },
    section: {
      type: Object,
      required: true
    },
    assetVariables: {
      type: Object,
      required: true
    },
    showToggle: {
      type: Boolean,
      required: false,
      default: true
    },
    disabledInputs: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    errorMessage: ''
  }),
  methods: {
    resetError() {
      this.errorMessage = '';
    }
  }
};
</script>

<style scoped>
.optionalVariableSection {
  background-color: #FAFAFA;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
}
.greyout {
  opacity: 0.6; /* Real browsers */
  filter: alpha(opacity = 60); /* MSIE */
}
</style>
