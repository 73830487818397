<template>
  <!-- Choose Reward Strategy (session vs pageview) -->
  <section>
    <div class="level">
      <div class="level-left">
        <h2 class="level-item subtitle is-4" data-cy-test="test-subtitle">
          Manage Reward IDs
        </h2>
      </div>
      <div class="level-right">
        <span class="level-item">
          <b-checkbox v-model="hasRealRewards">Enable Real Rewards</b-checkbox>
        </span>
      </div>
    </div>
    <b-field v-for="rewardObj, index in rewards" :key="`rewardId-${index}`" grouped>
      <b-field label="Reward ID" expanded>
        <b-autocomplete
          :ref="`autocomplete-${rewardObj.rewardId}`"
          v-model.trim="rewardObj.rewardId"
          placeholder="email_subscription_cta"
          data-cy-test="select-test-reward-ids"
          :data="filterAllRewardIds(rewardObj.rewardId)"
          group-field="type"
          group-options="items"
          expanded
          clearable
          open-on-focus
        />
      </b-field>
      <b-field label="Reward Value">
        <b-input
          v-model.number="rewardObj.value"
          :disabled="!hasRealRewards"
          type="number"
          min="1"
        ></b-input>
      </b-field>
      <b-field>
        <template slot="label">&nbsp;</template>
        <button class="button" @click="rewards.splice(index, 1)">
          <b-icon icon="trash" class="is-hover-danger"></b-icon>
        </button>
      </b-field>
    </b-field>
    <div class="is-block top15">
      <button
        class="button is-info is-inverted is-inline-flex"
        :class="{ 'is-disabled': validity.hasEmptyRewards }"
        :disabled="validity.hasEmptyRewards"
        data-cy-test="add-reward-id-link"
        @click.prevent="addEmptyRewardId"
      >
        +&nbsp;Add Another Reward ID
      </button>
    </div>
    <hr class="test-form-modal__divider">
    <b-collapse
      v-model="showAdvancedOptions"
      aria-id="test-rewards-advanced-options-toggle"
    >
      <template #trigger>
        <h5 class="title is-5 bottom15" aria-id="asset-model-advanced-options-toggle">
          <b-icon :icon="(showAdvancedOptions) ? 'caret-down' : 'caret-right'"></b-icon>
          {{ !showAdvancedOptions ? 'Show Advanced Options' : 'Hide Advanced Options' }}
        </h5>
      </template>
      <div class="my-4 pl-4">
        <label class="label">
          Choose Reward Strategy
        </label>
        <b-message type="is-warning" has-icon size="is-small">
          <p>Be mindful when changing the test reward strategy. If you are unsure which strategy is better for your business, the <a href="slack://channel?team=T029AV3QX&id=C718VV878">#cohesion-support</a> Slack channel is the best place to ask.</p>
          <div class="top15">
            <b-field>
              <b-field>
                <b-radio
                  v-model="clickOptimization.rewardStrategy"
                  native-value="session"
                >Reward By Sessions</b-radio>
              </b-field>
              <b-field>
                <b-radio
                  v-model="clickOptimization.rewardStrategy"
                  native-value="pageview"
                >Reward By Page Views</b-radio>
              </b-field>
            </b-field>
          </div>
        </b-message>

      </div>
    </b-collapse>
  </section>
</template>

<script>
export default {
  name: 'RewardConfiguration',
  props: {
    siteId: String,
    clickOptimization: Object,
    isEditing: Boolean,
    valid: Boolean,
    value: Array
  },
  data() {
    return {
      existingRewardIds: [],
      hasRealRewards: false,
      showAdvancedOptions: false
    };
  },
  computed: {
    rewards: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    validity() {
      const validity = {
        hasDuplicateRewards: false,
        hasEmptyRewards: false
      };

      this.rewards.reduce(function (obj, rewardObj) {
        if (!rewardObj.rewardId) {
          validity.hasEmptyRewards = true;
        } else if (obj[rewardObj.rewardId]) {
          validity.hasDuplicateRewards = true;
        } else {
          obj[rewardObj.rewardId] = rewardObj.value;
        }
        return obj;
      }, {});

      this.$emit('update:valid', !validity.hasDuplicateRewards && !validity.hasEmptyRewards);

      return validity;
    },
    selectedRewardIds() {
      return this.rewards.map(r => r.rewardId);
    }
  },
  created() {
    // add an empty reward ID on the end to allow additions to the list
    if (!this.isEditing) {
      this.addEmptyRewardId();
    }

    this.getExistingRewardIdsForSite(this.siteId);
  },
  methods: {
    filterAllRewardIds(query) {
      // dont show already selected rewards from full list
      let availableSelections = this.existingRewardIds.filter(r => this.selectedRewardIds.indexOf(r) === -1);

      // if searching, filter selections by search string match
      if (query) {
        availableSelections = availableSelections.filter(rid => rid.indexOf(query) !== -1);
      }

      return [{ type: 'Previously Used', items: availableSelections }];
    },
    addEmptyRewardId() {
      this.rewards.push({
        rewardId: '',
        value: 1
      });
    },
    async getExistingRewardIdsForSite(siteId) {
      try {
        const res = await this.$axios.get(`/sites/${siteId}/external/reward-ids`);

        const { rewardIds } = res.data;
        if (Array.isArray(rewardIds) && rewardIds.length) {
          this.existingRewardIds = rewardIds;
          this.$emit('set-existing-reward-ids', rewardIds);
        }
      } catch (err) {
        this.$store.commit('error', err, 'Could not get previously used Reward IDs');
      }
    }
  }
};
</script>
