/**
 * Validate that a given list item whether new or existing
 * is in a valid state to be saved.
 * @param {String} name
 * @param {File} file
 * @param {Boolean} valid
 * @return {Object} validation messages
 */
export default function validate(name, file, valid) {
  switch (true) {
    case name.length === 0:
      return { valid: false, message: 'List must have a valid name.' };
    case file === null:
      return { valid: false, message: 'List must have at least one item.' };
    case valid === false:
      return { valid: false, message: 'List must be a valid collection.' };
    case file.size > 398000:
      return { valid: false, message: 'List has exceeded the size limit of 398kb.' };
    default:
      return { valid: true, message: '' };
  }
}
