<template>
  <section>
    <div
      class="subtitle is-size-4"
      data-cy-test="reward-subtitle"
    >Reward Options</div>

    <b-field label="DAPI Client Token" class="mb-3">
      <b-input
        v-model="mabExp.dapi.clientToken"
        :disabled="isEditing"
        placeholder="Enter your DAPI client token provided by the Cohesion team"
        data-cy-test="reward-client-token"
      />
    </b-field>
    <b-field v-if="isEditing" label="DAPI Agent Name" class="mb-3">
      <b-input v-model="testId" disabled />
    </b-field>
    <b-field label="Expiration (seconds)">
      <b-input
        v-model="expirationStr"
        placeholder="Enter how long DAPI should wait before applying decisions in seconds"
      />
    </b-field>
    <p class="mb-5 help">{{ mabExp.dapi.expiration | filterSecondsToReadableTime }}</p>
    <div class="subtitle is-size-5" data-cy-test="sample-dapi-outcome">Sample DAPI Outcome</div>
    <p><a href="https://redventures.atlassian.net/wiki/spaces/COHSN/pages/486933183/V1+Outcomes" target="_blank" rel="noreferrer noopener">
      DAPI V1 Outcomes Documentation
    </a></p>
    <code>POST https://dapi.cohesionapps.com/api/v1/outcome</code><br>
    <code>Authorization: Bearer CLIENT_TOKEN</code><br>
    <code>Content-Type: application/json</code>
    <pre>{{ sampleDapiOutcomePayload }}</pre>
  </section>
</template>

<script>
export default {
  name: 'RewardStep',
  props: {
    testId: {
      type: String,
      required: false
    },
    mabExp: Object,
    isEditing: Boolean,
    valid: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      expirationStr: '0'
    };
  },
  computed: {
    siteId() {
      return this.$store.state.siteId;
    },
    tenantId() {
      return this.$store.getters.siteTenantId;
    },
    // Write key if it exists
    siteWriteKey() {
      const { destination = {} } = this.$store.getters.activeSite;
      return destination[destination.type]?.writeKey;
    },
    sampleDapiOutcomePayload() {
      return JSON.stringify({
        agentName: this.isEditing ? this.testId : 'KNOWN_AFTER_CREATION',
        tenantId: this.mabExp.dapi.tenantId ?? this.siteWriteKey ?? this.tenantId,
        writeKey: this.siteWriteKey ?? 'WRITE_KEY',
        sessionId: 'SESSION_ID',
        anonymousId: 'ANONYMOUS_ID',
        outcomeName: 'OUTCOME_NAME',
        errored: false,
        success: true
      }, null, 2);
    }
  },
  watch: {
    'mabExp.dapi.clientToken': function () {
      this.determineValidity();
    },
    expirationStr() {
      this.determineValidity();
      if (Number.isInteger(+this.expirationStr) && +this.expirationStr > 0) {
        this.mabExp.dapi.expiration = +this.expirationStr;
      }
    }
  },
  mounted() {
    this.expirationStr = `${this.mabExp.dapi.expiration}`;
    this.determineValidity();
  },
  methods: {
    determineValidity() {
      const isClientTokenValid = this.mabExp.dapi.clientToken?.length > 0;
      const isExpirationValid = Number.isInteger(+this.expirationStr) && +this.expirationStr > 0;
      const isValid = isClientTokenValid && isExpirationValid;
      this.$emit('update:valid', isValid);
    }
  }
};
</script>
