<template>
  <aside
    class="menu side-nav"
    role="navigation"
  >
    <div class="side-nav-header">
      <b-dropdown
        class="side-nav-brand"
        :mobile-modal="false"
      >
        <a slot="trigger">
          <div class="navbar-item">
            <img
              src="./assets/preamp-logo.svg"
              alt="Preamp"
              style="height: 25px"
            >
            <span class="icon">
              <i class="fa fa-chevron-down"></i>
            </span>
          </div>
        </a>

        <b-dropdown-item
          v-if="isTaggyEnabled"
          has-link
        >
          <a
            href="#"
            @click="openTaggy"
          >
            <img
              src="./assets/taggy-logo.svg"
              alt="Taggy"
              style="height: 20px"
            >
          </a>
        </b-dropdown-item>

        <b-dropdown-item
          has-link
        >
          <a
            href="#"
            @click="openMonarch"
          >
            <img
              src="./assets/monarch-logo.svg"
              alt="Monarch"
              style="height: 14px"
            >
          </a>
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <div class="side-nav-main">
      <div
        v-for="(navgroup, i) in navigation"
        :key="i"
        class="side-nav-group"
      >
        <p
          v-if="shouldShowGroupLabel(navgroup)"
          class="menu-label"
        >{{ navgroup.group }}</p>
        <ul class="menu-list">
          <template v-for="(route, j) in navgroup.links">
            <li
              v-if="routePerms(route)"
              :id="`mn-item-${formatElementId(route.label)}`"
              :key="j"
              :data-cy-test="`${formatElementId(route.label)}`"
              @click="elementClick"
            >
              <router-link
                v-if="route.path"
                :to="route.path"
                :class="{ 'is-active' : matchActiveRoute(route), 'u-flex-center': route.svg }"
                :disabled="routeStatus(route, navgroup.group)"
              >
                <v-icon
                  v-if="route.svg"
                  :icon="route.svg"
                  :color="matchActiveRoute(route) ? '#fff' : '#4a4a4a'"
                  :flip-x="route.flipX || false"
                ></v-icon>
                <span
                  v-else
                  class="icon"
                ><i
                  class="fa"
                  :class="`fa-${route.icon}`"
                ></i></span>
                <span>{{ route.label }}</span>
              </router-link>

              <a
                v-else-if="route.link"
                target="_blank"
                :href="route.link"
              >
                <span class="icon">
                  <i
                    class="fa"
                    :class="`fa-${route.icon}`"
                  ></i>
                </span>
                <span>{{ route.label }}</span>
              </a>
            </li>
          </template>
        </ul>
      </div>
    </div>

    <div class="side-nav-footer">
      <p>{{ version }}</p>
    </div>
  </aside>
</template>

<script>
import navs from '@/router/navigation';
import { bus } from '@/main';
import { sync } from 'vuex-pathify';
import config from '@/config';
import getAppEnv from '@/modules/getAppEnv';

const appEnv = getAppEnv(window.location.host);
const { MONARCH_URL } = config[appEnv];
const appVersion = process.env.VUE_APP_CORE_VERSION || 'local';

export default {
  name: 'AppSideNav',
  props: ['siteId'],
  computed: {
    // Sync the site lock
    siteLock: sync('siteLock'),
    // Check for Super User
    isSuper() {
      return this.$store.getters.isSuperUser;
    },
    // Check for Admin User
    isAdmin() {
      if (this.isSuper) return true;
      return this.$store.getters.isAdminUser;
    },
    // Check if the user is an admin for the selected account
    isAdminForAccount() {
      return this.$store.getters.userRoleForAccount === 'admin';
    },
    currentlySelectedAccount() {
      return this.$store.state.activeAccount;
    },
    // Check that the user has a lock
    hasLock() {
      return this.$store.getters.siteLockOwner;
    },
    // Active route
    activePath() {
      return this.$route.path;
    },
    version() {
      return appVersion.replace('preamp-admin_', '');
    },
    navigation() {
      return [ ...navs ];
    },
    isTaggyEnabled() {
      return this.$store.getters.isTaggyEnabled;
    }
  },
  methods: {
    // Open taggy in a new tab
    openTaggy() {
      const { siteDomain, env } = this.$store.getters;
      const siteDomainIsURL = siteDomain.includes('/'); // Handles edge case where teams enter an entire URL as their site's domain
      let siteURL = siteDomainIsURL ? `${siteDomain}/?taggy=true` : `https://${siteDomain}/?taggy=true`;
      if (env !== 'PROD') siteURL += `&env=${env}`;
      window.open(siteURL);
    },
    // open Monarch in a new tab
    openMonarch() {
      window.open(MONARCH_URL);
    },

    // Hides group label if all links are hidden
    shouldShowGroupLabel(group) {
      return group.links
        .map(route => this.routePerms(route))
        .reduce((acc, curr) => acc && curr);
    },
    /**
     * Display permission based routes
     * @param {Object} route - route to evaluate
     * @return {Bool} whtether it should be displayed
     */
    routePerms(route) {
      if (route.isSuper) return route.isSuper === this.isSuper;
      if (route.isAdmin) return route.isAdmin === this.isAdmin;
      if (route.isAdminForAccount) return route.isAdminForAccount === this.isAdminForAccount;
      if (Array.isArray(route.isAllowedAccount)) return route.isAllowedAccount.indexOf(this.currentlySelectedAccount) !== -1 || this.isSuper;
      return true;
    },
    /**
     * Disabled status based on route
     * @param {Object} route - route to evaluate
     * @param {String} group - the group the route belongs to
     * @return {Bool} whether it should be disabled
     */
    routeStatus(route, group) {
      return (
        (this.siteId === null && group !== 'Manage'&& group !== 'Support')
        // Disable the route if it requires lock and someone else owns it
        || (!this.hasLock && this.siteLock.owner && route.isLocked)
        || (route.isLocked && !this.$store.getters.userCanEdit)
      );
    },
    /**
     * Emit element click event
     * @param {Object} e - element click event
     */
    elementClick(e) {
      bus.$emit('elem-click', e);
    },
    formatElementId(value) {
      let id = '';

      if (value) {
        id = value.replace(/ /g, '-').toLowerCase();
      }

      return id;
    },
    matchActiveRoute(route) {
      // this will evaluate to true if the current path exactly matches a route
      if (route.path === this.activePath) return true;

      // we still need to check child routes, such as routes ending with an ID
      return route.childRouteNames && route.childRouteNames.includes(this.$route.name);
    }
  }
};
</script>
