<template>
  <section>
    <section
      v-if="$route.name === 'Preamp'"
      class="hero is-info is-bold"
      style="margin: 15px;"
    >
      <div class="hero-body">
        <h1 class="title">
          Welcome to Preamp
        </h1>
        <h2 class="subtitle">
          An audience-based creative testing platform
        </h2>
      </div><!-- /.hero-body -->
    </section>

    <router-view :user="user"></router-view>
  </section>
</template>

<script>
export default {
  name: 'Preamp',
  props: ['user']
};
</script>
