<template>
  <div class="asset-variables">
    <div
      class="variables-table"
      data-cy-test="variables-table"
    >
      <b-table
        ref="assets-table"
        class="assets-table"
        :data="tableData"
        paginated
        :pagination-simple="true"
        :per-page="10"
        hoverable
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
      >
        <b-table-column
          v-slot="props"
          field="name"
          label="Variable Name"
          :th-attrs="() => {return {'data-cy-test': 'table-column-name'}}"
          :td-attrs="(row) => {return {'data-cy-test': `table-cell-name-${row.name}`}}"
        >
          {{ props.row.name }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="type"
          label="Type"
          :th-attrs="() => {return {'data-cy-test': 'table-column-type'}}"
          :td-attrs="(row) => {return {'data-cy-test': `table-cell-type-${row.name}`}}"
        >
          {{ props.row.type }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="value"
          label="Value"
          :th-attrs="() => {return {'data-cy-test': 'table-column-value'}}"
          :td-attrs="(row) => {return {'data-cy-test': `table-cell-value-${row.name}`}}"
        >
          {{ props.row.value }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="description"
          label="Developer Comments"
          :th-attrs="() => {return {'data-cy-test': 'table-column-description'}}"
          :td-attrs="(row) => {return {'data-cy-test': `table-cell-description-${row.name}`}}"
        >
          {{ props.row.description }}
        </b-table-column>
        <template #empty>
          <div class="empty-state" data-cy-test="empty-state-variables-table">
            <div>
              <b-icon
                icon="frown-o"
                class="empty-state__icon"
                data-cy-test="empty-state-icon"
              ></b-icon>
            </div>

            <h3 class="empty-state__title" data-cy-test="empty-state-title">Your search returned no results.</h3>
            <p data-cy-test="empty-state-description">Clear your search query to try again</p>
            <b-button
              type="is-info"
              data-cy-test="clear-variable-search-button"
              @click="handleClearVariableSearch"
            >
              Clear Search
            </b-button>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VariableTable',
  props: {
    variables: {
      type: String,
      required: false
    },
    filterQuery: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      currentPage: 1
    };
  },
  computed: {
    tableData() {
      const parsedVariables =  JSON.parse(this.variables);
      const formatted = Object.entries(parsedVariables).map(([k,v]) => {
          return {
            name: k,
            ...v
          };
        });

        if (this.filterQuery) {
          return formatted.filter((x) => {
            return Object.values(x).some(v => v.toString().toLowerCase().includes(this.filterQuery.toLowerCase()));
          });
        } else {
          return formatted;
        }
    }
  },
  methods: {
    handleClearVariableSearch() {
      this.$emit('clearVariableSearch');
    }
  }
};
</script>

<style scoped lang="scss">
.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 24px;
  min-height: 250px;
  margin: 24px;
  padding-top: 24px;

  &__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: -12px;
  }
}
.empty-state__icon {
  font-size: 100px;
  color: #0696EF40;
}
</style>
