<template>
  <section>
    <div class="main-view-header is-bordered">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h4 class="title is-4">Regions</h4>
          </div>
        </div><!-- /.level-left -->

        <div
          v-if="isSuper"
          class="level-right"
        >
          <div class="level-item">
            <button
              class="button is-info"
              :disabled="addingRegion"
              @click.stop="toggleNewRegion"
            >
              <span class="icon">
                <i class="fa fa-plus"></i>
              </span>
              <span>Create Region</span>
            </button>
          </div><!-- /.level-item -->
        </div><!-- /.level-right -->
      </div><!-- /.level -->
    </div>

    <div class="main-view-content">
      <NewRegion
        v-if="addingRegion"
        :regionNames="regionNames"
        @addRegionCancel="toggleNewRegion"
        @addedRegion="getRegions"
      />

      <template v-if="regions && regions.length">
        <Region
          v-for="(region) in regions"
          :key="`region-${region.name}`"
          :initial-region="region"
          :regionNames="regionNames"
          @updatedRegion="getRegions"
        />
      </template>

      <b-loading
        :active.sync="isLoading"
        :is-full-page="false"
      ></b-loading>
    </div><!-- /.main-view-content -->
  </section>
</template>

<script>
import Region from '@/components/Regions/Region';
import NewRegion from '@/components/Regions/NewRegion';

export default {
  name: 'Regions',
  components: {
    Region,
    NewRegion
  },
  data() {
    return {
      regions: [],
      addingRegion: false,
      isLoading: false
    };
  },
  computed: {
    // Return account names array
    regionNames() {
      if (this.regions.length === 0) return [];
      return this.regions.map(function (region) {
        return region.name.toLowerCase();
      });
    },
    // Is the user a super user
    isSuper() {
      return this.$store.getters.isSuperUser;
    }
  },
  created() {
    // Get Regions
    this.getRegions();
  },
  methods: {
    // Get and assign region data
    async getRegions() {
      try {
        const res = await this.$axios.get('/regions');
        this.regions = res.data;
      } catch (err) {
        this.$store.commit('error', err, 'There was a problem getting regions');
      }
    },
    // Toggle new region form
    toggleNewRegion() {
      this.addingRegion = !this.addingRegion;
    }
  }
};
</script>
