<template>
  <section>
    <div class="main-view-header">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h4 class="title is-4">Users</h4>
          </div>
        </div><!-- /.level-left -->

        <div class="level-right">
          <div class="level-item">
            <b-field>
              <p class="control">
                <b-select v-model="accountFilter">
                  <option
                    :value="null"
                    :disabled="!accountFilter"
                  >{{ accountFilter ? 'All Accounts' : 'Filter by Account' }}</option>
                  <option
                    v-for="account in accounts"
                    :key="account.accountId"
                    :value="account.accountId"
                  >
                    {{ account.name }}
                  </option>
                </b-select>
              </p>
              <b-input
                v-model="filterQuery"
                placeholder="Search..."
              ></b-input>
            </b-field>
          </div><!-- /.level-item -->

          <div class="level-item">
            <button
              id="btn-create-user"
              class="button is-info"
              :disabled="addingUser"
              @click="toggleNewUser"
            >
              <span class="icon">
                <i class="fa fa-user-plus"></i>
              </span>
              <span>Create User</span>
            </button>
          </div><!-- /.level-item -->
        </div><!-- /.level-right -->
      </div><!-- /.level -->
    </div>

    <div class="main-view-sub-header">
      <div class="tabs is-boxed">
        <ul>
          <li
            :class="{ 'is-active': filterView === 'all' }"
            @click="filterView = 'all'"
          >
            <a>All</a>
          </li>

          <li
            :class="{ 'is-active': filterView === 'admin' }"
            @click="filterView = 'admin'"
          >
            <a>Admins</a>
          </li>

          <li
            :class="{ 'is-active': filterView === 'analyst' }"
            @click="filterView = 'analyst'"
          >
            <a>Analysts</a>
          </li>

          <li
            :class="{ 'is-active': filterView === 'developer' }"
            @click="filterView = 'developer'"
          >
            <a>Developers</a>
          </li>

          <li
            :class="{ 'is-active': filterView === 'guest' }"
            @click="filterView = 'guest'"
          >
            <a>Guests</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="main-view-content expanded-header has-pagination-footer">
      <NewUser
        v-if="addingUser"
        :users="users"
        :accounts="adminAccountAddRoles"
        @updatedUser="updateUser"
        @addedUser="addUser"
        @addUserCancel="toggleNewUser"
      />

      <template v-if="users && usersFiltered.length > 0">
        <User
          v-for="currentUser in usersPage"
          :key="currentUser.email"
          :user="currentUser"
          :isUser="currentUser.email === user.email"
          :accounts="(currentUser.email !== user.email) ? adminAccountAddRoles : accounts"
          @updatedUser="updateUser"
          @deletedUser="deleteUser"
        />
      </template>

      <div v-if="usersFiltered.length === 0">
        <article class="message is-warning">
          <div class="message-body">
            No {{ (filterView === 'all') ? 'user' : filterView }}s found for your accounts.
          </div>
        </article>
      </div>

      <b-loading
        :active.sync="isLoading"
        :is-full-page="false"
      ></b-loading>
    </div><!-- /.main-view-content -->

    <footer class="main-view-footer">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <button
              class="button"
              :disabled="page === 0"
              @click.stop="getPage(-1)"
            >Previous</button>
          </div>
          <div class="level-item">
            <button
              class="button"
              :disabled="nextPage >= usersFiltered.length"
              @click.stop="getPage(1)"
            >Next</button>
          </div>
        </div>

        <div class="level-right">
          <div class="level-item">
            <strong>{{ userRangeText }} of {{ usersFiltered.length }} Users</strong>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
import User from '@/components/Users/User';
import NewUser from '@/components/Users/NewUser';
import { bus } from '@/main';

export default {
  name: 'Users',
  components: {
    User,
    NewUser
  },
  data() {
    return {
      accountFilter: null,
      accounts: [],
      addingUser: false,
      filterQuery: null,
      filterView: 'all',
      users: [],
      page: 0,
      offset: 20,
      isLoading: false
    };
  },
  computed: {
    // The user that is currently logged in
    user() {
      return this.$store.state.user;
    },
    // Accounts the user can add people to
    adminAccountAddRoles() {
      if (this.user.isSuper) return this.accounts;
      return this.accounts.filter((account) => {
        return this.userAdminAccounts.indexOf(account.accountId) >= 0;
      });
    },
    // Get accounts the logged in user is an admin of
    userAdminAccounts() {
      return Object.keys(this.user.accountRoles)
        .filter((account) => {
          return this.user.accountRoles[account] === 'Admin';
        });
    },
    // List of all user emails for validation
    userEmails() {
      return this.users.map(function (user) {
        return user.email.toLowerCase();
      });
    },
    // Filtered list of users
    usersFiltered() {
      let userList = [...this.users];

      // filter on the logged in users accounts if they are admin
      if (this.user && !this.user.isSuper) {
        userList = userList.filter((user) => {
          if (user.accountRoles && Object.keys(user.accountRoles).length) {
            for (let i = 0; i < this.userAdminAccounts.length; i++) {
              if (Object.keys(user.accountRoles).includes(this.userAdminAccounts[i])) return true;
            }
          }
          return false;
        });
      }

      // filter by account
      if (this.accountFilter) {
        userList = userList.filter((user) => {
          return (
            user.accountRoles
            && Object.keys(user.accountRoles).length > 0
            && Object.keys(user.accountRoles).includes(this.accountFilter)
          );
        });
      }

      // Check if we should only display admin users
      if (this.filterView !== 'all') {
        userList = userList.filter((user) => {
          if (this.filterView === 'admin' && user.isSuper) return true;

          if (user.accountRoles && Object.keys(user.accountRoles).length) {
            for (const account in user.accountRoles) {
              if (this.accountFilter) {
                return user.accountRoles[this.accountFilter].toLowerCase() === this.filterView;
              } else if (user.accountRoles[account].toLowerCase() === this.filterView) {
                return true;
              }
            }
          }
          return false;
        });
      }

      // filter by search
      if (this.filterQuery) {
        userList = userList.filter((user) => {
          return user.email.includes(this.filterQuery);
        });
      }

      return userList;
    },
    // Paginate
    usersPage() {
      if (this.usersFiltered.length > this.offset && this.page * this.offset < this.usersFiltered.length) {
        return this.usersFiltered.slice(this.page * this.offset, this.nextPage);
      } else {
        return this.usersFiltered;
      }
    },
    // Next pag enumber
    nextPage() {
      if (this.page === 0) return this.offset;
      const nextOffset = (this.page * this.offset) + this.offset;
      return nextOffset > this.usersFiltered.length ? this.usersFiltered.length : nextOffset;
    },
    // Display Text
    userRangeText() {
      if (this.usersFiltered.length < this.offset) {
        return this.usersFiltered.length;
      } else {
        return `${this.page * this.offset} - ${this.nextPage}`;
      }
    }
  },
  created() {
    // Get Users
    this.getUsers();
  },
  async mounted() {
    try {
      // get all accounts for user form
      const res = await this.$axios.get('/accounts/roles');
      this.accounts = res.data;
    } catch (e) {
      e.title = 'There was a problem retrieving account data.';
      this.$store.commit('error', e);
    }
  },
  methods: {
    /**
     * Emit element click event
     * @param {Object} e - element click event
     */
    elementClick(e) {
      bus.$emit('elem-click', e);
    },
    /**
     * Get the next page of data from the API
     * @param {Number} integer - postive or negative 1
     */
    getPage(integer) {
      this.page += integer;
    },
    // Get and assign user data
    async getUsers() {
      this.isLoading = true;

      try {
        const res = await this.$axios.get('/users');
        this.$set(this, 'users', res.data.users);
      } catch (err) {
        err.title = 'There was a problem getting users.';
        this.$store.commit('error', err);
      }

      this.isLoading = false;
    },
    // Toggle new user form
    toggleNewUser(e) {
      this.addingUser = !this.addingUser;

      // track click event
      if (e) {
        this.elementClick(e);
      }
    },
    addUser(user) {
      this.users.push(user);
    },
    updateUser(user) {
      this.users = this.users.map((u) => {
        if (u.email === user.email) {
          return user;
        } else {
          return u;
        }
      });
    },
    deleteUser(email) {
      this.users = this.users.filter(u => u.email !== email);
    }
  }
};
</script>
