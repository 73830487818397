import { IntegrationTypeEnum, SegmentIdTypes } from './integrationTypeEnum';
import segmentIntegration from './segment';
import segmentXIntegration from './segmentxs';

export const newIntegrationSchema = {
    name: null,
    integrationType: '',
    endpoint: '',
    data: {},
    options: {
      urlMatch: []
    }
};

export function getAllIntegrationTypes() {
  return [
    IntegrationTypeEnum.SEGMENT,
    IntegrationTypeEnum.SEGMENT_XS
  ];
}

export function getSupportedIntegrationTypes() {
  return [
    IntegrationTypeEnum.SEGMENT
  ];
}

export function getIntegrationInstance(integrationType, integration = null) {
  let modifiedIntegrationType = integrationType;

  if (integrationType === IntegrationTypeEnum.SEGMENT && integration) {
    modifiedIntegrationType = getSegmentIntegrationType(integration);
  }

  switch (modifiedIntegrationType) {
    case IntegrationTypeEnum.SEGMENT:
      return segmentIntegration;
    case IntegrationTypeEnum.SEGMENT_XS:
      return segmentXIntegration;
    default:
      throw new Error(`Invalid integration type: ${modifiedIntegrationType}`);
  }
}

export function filterIntegrationsByType(integrationType, allIntegrations) {
  switch (integrationType) {
    case IntegrationTypeEnum.SEGMENT:
    case IntegrationTypeEnum.SEGMENT_XS:
      return filterSegmentIntegrations(integrationType, allIntegrations);
    default:
      return allIntegrations.filter((integration) => {
        return integration.integrationType === integrationType;
      });
  }
}

export function validateIntegration(integration) {
  const { integrationType, name } = integration || {};
  const commonPropertiesInvalid = !integrationType || !name;

  if (commonPropertiesInvalid) return false;

  const integrationInstance = getIntegrationInstance(integrationType);

  return !commonPropertiesInvalid && integrationInstance.validateIntegration(integration);
}

function filterSegmentIntegrations(integrationType, allIntegrations) {
  const externalIdType = SegmentIdTypes[integrationType];

  return allIntegrations.filter((integration) => {
    const integrationExternalIdType = integration.data.segment.idType;

    // Segment and SegmentXS integrations are both of the type 'segment'
    // The only difference is the externalId type and only Preamp Admin
    // cares about that as of now.
    return integration.integrationType === IntegrationTypeEnum.SEGMENT
    && externalIdType === integrationExternalIdType;
  });
}

function getSegmentIntegrationType(integration) {
  if (!integration) return;

  const { data } = integration || {};
  const { segment } = data || {};
  const { idType } = segment || {};

  if (idType === SegmentIdTypes[IntegrationTypeEnum.SEGMENT_XS]) {
    return IntegrationTypeEnum.SEGMENT_XS;
  }

  return IntegrationTypeEnum.SEGMENT;
}
