<template>
  <div
    class="card experience"
    :class="{ 'experience--control': experience.isControl, 'is-expandable': isExpandable, 'bottom10': !isExpandable }"
  >
    <header
      class="card-header"
      @click="(isExpandable) ? toggleExperience() : () => {}"
    >
      <p
        class="card-header-title is-relative"
      >
        <span
          class="card-header-box"
        >
          <span data-cy-test="experience-number">
            {{ experience.experienceNumber }}
          </span>
        </span>

        <span data-cy-test="experience-name">
          {{ (experience.name) ? experience.name : `Experience #${experience.experienceNumber}` }}
        </span>
      </p>

      <!-- Experience Tags -->
      <span
        v-if="experience.isControl"
        class="card-header-icon"
      >
        <span
          class="tag is-light"
        >
          <span class="icon">
            <i class="fa fa-star"></i>
          </span>
          <span data-cy-test="control-icon">Control</span>
        </span>
      </span>

      <span
        v-if="experience.isActive || experience.isChampion"
        class="card-header-icon"
      >
        <span class="tag is-success" data-cy-test="exp-active-icon">Active</span>
      </span>

      <!-- Visitor Percentage -->
      <div
        v-if="experience.weight !== undefined"
        class="card-header-item"
      >
        <span class="tags has-addons">
          <span
            class="tag is-info"
            data-cy-test="visitor-icon"
          >Visitors</span>
          <span
            class="tag is-percentage"
            data-cy-test="percentage"
          >{{ experience.weight }}%</span>
        </span>
      </div>

      <span class="card-header-icon">
        <a
          :href="previewUrl"
          target="_blank"
          aria-label="Preview"
          data-cy-test="exp-preview"
          class="icon has-text-info"
        ><i
          class="fa fa-external-link"
          aria-hidden="true"
        ></i></a>
      </span>

      <div class="card-header-icon">
        <span
          v-show="isExpandable"
          class="icon"
          :class="{ 'is-rotated': isExpanded }"
          aria-label="Expand Experience"
        >
          <i
            class="fa fa-angle-down"
            aria-hidden="true"
          ></i>
        </span>
      </div>
    </header><!-- /.card-header -->

    <div
      v-if="isExpanded"
      class="card-content"
    >
      <section>
        <div>
          <div	class="column">
            <h6
              class="title is-6"
              data-cy-test="title"
            >Experience Details</h6>

            <table class="table is-bordered is-hoverable is-fullwidth is-narrow">
              <tr>
                <th>Name</th>
                <td>
                  <span data-cy-test="exp-name">{{ (experience.name) ? experience.name : 'N/A' }}</span>
                </td>
              </tr>
              <tr>
                <th>Number</th>
                <td data-cy-test="exp-number">{{ experience.experienceNumber }}</td>
              </tr>
              <tr>
                <th data-cy-test="exp-id">
                  <span>ID</span>
                  <b-tooltip
                    label="Click on the ID to view experience details."
                    position="is-right"
                    type="is-light"
                    animated
                  >
                    <span class="icon has-text-info">
                      <i class="fa fa-info-circle"></i>
                    </span>
                  </b-tooltip>
                </th>
                <td>
                  <router-link :to="`/experiences/${experience.experienceId}`">
                    {{ experience.experienceId }}
                  </router-link>
                </td>
              </tr>
              <tr>
                <th data-cy-test="exp-created-at">Created At</th>
                <td>{{ formatTime(experience.createdAt) }}</td>
              </tr>
              <tr v-if="experience.archivedAt">
                <th>Archived At</th>
                <td>{{ formatTime(experience.archivedAt) }}</td>
              </tr>
              <tr v-if="experience.archivedBy">
                <th>Archived By</th>
                <td>{{ experience.archivedBy }}</td>
              </tr>
            </table>
          </div><!-- /.column -->

          <div class="column">
            <h6
              class="title is-6"
              data-cy-test="test-details-title"
            >Test Details</h6>
            <div v-if="tests.length">
              <table class="table is-bordered is-fullwidth is-narrow">
                <thead>
                  <th>
                    <span>Name</span>
                    <b-tooltip
                      label="Click on an active test name to view test details."
                      position="is-right"
                      type="is-light"
                      animated
                    >
                      <span class="icon has-text-info">
                        <i class="fa fa-info-circle"></i>
                      </span>
                    </b-tooltip>
                  </th>
                  <th>Status</th>
                </thead>
                <tbody>
                  <tr
                    v-for="test in tests"
                    :key="test.testId"
                  >
                    <td>
                      <router-link
                        v-if="!test.ended"
                        :to="`/tests/${test.testId}`"
                        data-cy-test="test-details-name"
                      >
                        {{ test.name }}
                      </router-link>
                      <span
                        v-else
                        class="is-disabled"
                      >
                        <span class="icon has-text-warning">
                          <i class="fa fa-archive"></i>
                        </span>
                        {{ test.name }}
                      </span>
                    </td>
                    <td>
                      <span
                        class="tag"
                        data-cy-test="test-status"
                        :class="{
                          'is-warning': test.isPaused && !test.ended,
                          'is-danger': test.ended,
                          'is-success': test.isActive
                        }"
                      >
                        {{ getTestStatus(test).status }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <article class="message top15">
                <div class="message-body">
                  <p>
                    This experience is not in any Tests.
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>

        <div>
          <div class="column">
            <section
              v-if="experience.assetPlacements && experience.assetPlacements.length"
              id="placement-details"
            >
              <h6 class="title is-6">Placement Details</h6>
              <table class="table is-bordered is-fullwidth is-narrow bottom0">
                <thead>
                  <th>
                    <span>Placement</span>
                    <b-tooltip
                      label="Click on the placement name to view placement details."
                      position="is-right"
                      type="is-light"
                      animated
                    >
                      <span class="icon has-text-info">
                        <i class="fa fa-info-circle"></i>
                      </span>
                    </b-tooltip>
                  </th>
                  <th>Asset</th>
                </thead>
                <tbody>
                  <tr
                    v-for="(ap, i) in experience.assetPlacements"
                    :key="i"
                  >
                    <td>
                      <p
                        v-if="ap.placement.archived"
                        class="is-disabled"
                        title="Archived"
                      >
                        <span class="icon has-text-warning">
                          <i class="fa fa-archive"></i>
                        </span>
                        <span>{{ ap.placement.name }}</span>
                      </p>
                      <router-link
                        v-else
                        :to="`/placements/${ap.placement.placementId}`"
                      >
                        {{ ap.placement.name }}
                      </router-link>
                    </td>
                    <td>
                      <p
                        :class="{ 'is-disabled': ap.asset.archived }"
                        title="Archived"
                      >
                        <span
                          v-if="ap.asset.archived"
                          class="icon has-text-warning"
                        >
                          <i class="fa fa-archive"></i>
                        </span>
                        <span>{{ ap.asset.name }}</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>

            <section
              v-if="experience.addOns && Object.keys(experience.addOns).length"
              class="add-ons"
            >
              <h6
                class="title is-6"
                data-cy-test="title"
              >Experience Add-Ons</h6>
              <table class="table is-bordered is-fullwidth is-narrow bottom0">
                <thead>
                  <th>
                    <span>Add-On Type</span>
                  </th>
                  <th>
                    <span>Add-On Name</span>
                    <b-tooltip
                      label="Click on the add-on name to view it's details."
                      position="is-right"
                      type="is-light"
                      animated
                    >
                      <span class="icon has-text-info">
                        <i class="fa fa-info-circle"></i>
                      </span>
                    </b-tooltip>
                  </th>
                </thead>
                <tbody>
                  <tr
                    v-for="(id, type) in experience.addOns"
                    :key="id"
                  >
                    <td>
                      <p>
                        <span data-cy-test="add-on-type">{{ type }}</span>
                      </p>
                    </td>
                    <td>
                      <router-link
                        :to="`/add-ons/${id}`"
                        data-cy-test="add-on-name"
                      >
                        {{ addOnsMap[id] ? addOnsMap[id].name : id }}
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </div>
        </div>
      </section>
    </div><!-- /.card-content -->
  </div><!-- /.card -->
</template>

<script>
import { formatTime } from '@/modules/utilities';
import { bus } from '@/main';

export default {
  name: 'NestedExperience',
  props: {
    experience: {
      type: Object,
      required: true
    },
    allAddOns: {
      type: Array,
      required: true
    },
    allTests: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    experienceToTests: {
      type: Object,
      required: true
    },
    isExpandable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      expName: '',
      expTags: [],
      archived: false,
      isExpanded: false
    };
  },
  computed: {
    testsMap() { // A map of test ID's to test data
      const result = {};

      for (const test of this.allTests) {
        if (!result[test.testId]) {
          result[test.testId] = test;
        }
      }
      return result;
    },
    tests() { // A collection of tests for the experience
      const result = [];
      if (this.experienceToTests[this.experience.experienceId]) {
        this.experienceToTests[this.experience.experienceId].forEach((testId) => {
          if (this.testsMap[testId]?.isActive === true) {
            result.push(this.testsMap[testId]);
          }
        });
      }
      return result;
    },
    addOnsMap() {
      const map = {};
      this.allAddOns.forEach(addOn => map[addOn.addOnId] = addOn);
      return map;
    },
    siteId() {
      return this.$store.state.siteId;
    },
    previewUrl() {
      return this.experience.previewUrl ? `${this.experience.previewUrl}?exp=${this.experience.experienceNumber}` : `//${this.$store.getters.siteDomain}?exp=${this.experience.experienceNumber}`;
    }
  },
  methods: {
    /**
     * Emit element click event
     * @param {Object} e - element click event
     */
    elementClick(e) {
      bus.$emit('elem-click', e);
    },
    toggleExperience() {
      this.isExpanded = !this.isExpanded;
    },
    // return test options for status
    getTestStatus(test) {
      if (test.ended) return { status: 'Ended' };
      if (test.isPaused) return { status: 'Paused' };
      if (test.isActive) return { status: 'Active' };
      return { status: 'Inactive' };
    },
    formatTime
  }
};
</script>

<style lang="scss" scoped>
.card-header-box {
  flex-direction: column;
  .stacked-text-top {
    font-size: 12px;
    line-height: 1;
  }
  .stacked-text-bottom {
    color: darken(#b5b5b5, 10%);
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.6;
    .is-inline-flex {
      align-self: center;
      font-size: 1rem;
    }
  }
}
</style>
