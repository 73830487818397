<template>
  <div
    class="loading-bar"
    style="background-color: #209cee"
  >
    <div
      class="loading-brick"
      :class="{'is-active' : isActive}"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingNotifier',
  props: ['isActive']
};
</script>

<style lang="scss" scoped>
.loading-bar {
  height: 3px;
  overflow: hidden;
  position: relative;
  width: 100%;

  .loading-brick {
    background-color: hsl(46, 93%, 52%);
    content: '';
    height: 3px;
    left: -200px;
    position: absolute;
    text-align: center;
    width: 200px;

    &.is-active {
      animation: loading-horizontal 2s linear infinite;
    }
  }
}

@keyframes loading-horizontal {
  from {
    left: -200px;
    width: 200px;
  }
  to {
    left: 120%;
    width: 600px;
  }
}
</style>
