import hljs from 'highlight.js/lib/highlight.js'; // eslint-disable-line

// Import these individually to reduce bundle size
import js from 'highlight.js/lib/languages/javascript';
import css from 'highlight.js/lib/languages/css';
import xml from 'highlight.js/lib/languages/xml';
import sql from 'highlight.js/lib/languages/sql';

import debounce from './debounce';
import throttle from './throttle';

// Register the Highlight.js languages we are using
hljs.registerLanguage('js', js);
hljs.registerLanguage('css', css);
hljs.registerLanguage('html', xml);
hljs.registerLanguage('sql', sql);

/**
 * Remove Duplicates from Array
 * ------------------------------------
 * @param {Array} arrayOfThings - ['thing', 'thing', 'thing2', 27]
 * @returns the filtered array - ['thing', 'thing2', 27]
 */
function removeDuplicates(arrayOfThings) {
  return arrayOfThings.filter(function (item, pos, self) {
    return self.indexOf(item) === pos;
  });
}

/**
 * Object.hasOwnProperty Helper
 * ------------------------------------
 * @param {Object} object to check against
 * @param {String} property to check for
 * @returns {Boolean}
 */
function hasProp(object, prop) {
  return Object.prototype.hasOwnProperty.call(object, prop);
}

/**
 * Foramt for standard date times
 * @param {String} time - date format
 * @return {String} formatted to (DAY, MO DAY, YEAR, TIME)
 */
function formatTime(time) {
  const date = new Date(time);
  return date.toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });
}

function hasLength(iterable) {
  return (
    iterable !== null
    && iterable !== undefined
    && iterable.length > 0
  );
}

/**
 * Move an item in an array
 * NOTE: https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
 * @param {Array} arr - array to modify
 * @param {Number} old_index - index of item to move
 * @param {Number} new_index - index of item to move to
 * @return {Array} Final Array
 */
/* eslint-disable */
function arrayMove(arr, old_index, new_index) {
  if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
          arr.push(undefined);
      }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};
/* eslint-enable */

/**
 * Clone an object, ignoring prototypes
 * @param {Object} target object to clone
 * @returns {Object} the cloned object
 */
function clone(target) {
  return JSON.parse(JSON.stringify(target));
}

/**
 * Takes a collection of rule groups and
 * creates a formatted string for easy comparison
 * @param {Array} array of rule groups
 * @returns {String} single string of rules
 */
function getRuleString(ruleset) {
  return ruleset.map(function (group) {
    return group.map(function (rule) {
      return `${rule.path || ''}.${rule.comparator || ''}.${rule.value || 'n/a'}`;
    }).sort().join('||');
  }).sort().join('&&');
}

/**
 * Get duplicates in an array
 * @param {Array} arr - array to find duplicates in
 * @returns {Number} count - number of duplicates
 */
function getDuplicates(arr) {
  const arrMatcher = [];
  let count = 0;
  for (let i = 0; i < arr.length; i++) {
    if (arrMatcher.includes(arr[i])) {
      // duplicate
      count += 1;
    } else {
      // original, push to arrMatcher
      arrMatcher.push(arr[i]);
    }
  }
  return count;
}

/**Get duplicate values in an array
 * @param {Array} arr - array to find duplicate values in
 * @returns {Array} arr - array of duplicate values
 */
function getDuplicateArrayValues(arr) {
  return arr.filter((item, index) => arr.indexOf(item) !== index);
}

/**
 * Remove all non-model props from a test
 * @param {Object} rawTest raw test object with non-model properties
 * @returns {Object} test object matching what admin server expects
 */
function formatTest(rawTest) {

  const test = clone(rawTest);

  if (test.strategy.mvt !== undefined) {
    test.strategy.mvt.experiences = test.strategy.mvt.experiences
      .map(function (exp) {
        return {
          experienceId: exp.experienceId,
          weight: exp.weight,
          isControl: exp.isControl || false
        };
      });
  } else if (test.strategy.mabExp !== undefined && test.strategy.mabExp.dapi) {
    if (hasLength(test.strategy.mabExp.dapi.options)) {
      test.strategy.mabExp.dapi.options = test.strategy.mabExp.dapi.options.map(function (option) {
        if (typeof option === 'object' && option.experienceId) {
          return option.experienceId;
        }

        return option;
      });
    }

    if (test.strategy.mabExp.holdOut) {
      delete test.strategy.mabExp.holdOut.experience;
    }
  }

  // properties not valid anymore. Can still be found on old tests
  delete test.type;
  delete test.isActive;
  delete test.versionId;

  return test;
}

/**
 * Add commas to numbers over 999
 * https://stackoverflow.com/a/2901298/10386830
 * @param {Number} num number to format
 * @returns {String} number with comma
 */
function numberWithCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Get the number of days it has been from the given date to today.
 * @param {String} a - date string
 * @param {String} b - date string
 * @return {Number}
 */
function countDaysBetweenDates(a, b) {
  let d1 = new Date(a);
  let d2 = new Date(b);

  if (d2 > d1) {
    const tmp = d1;
    d1 = d2;
    d2 = tmp;
  }

  return Math.floor((d1 - d2) / (1000 * 60 * 60 * 24));
}

/**
 * Compare two objects and return whether a is greater than b.
 * @param {Object} a - to compare against
 * @param {Object} b - to compare against
 * @param {String} key - compare on
 * @return {Number}
 */
function sortByKey(a, b, key) {
  if (a[key] < b[key]) return -1;
  if (a[key] > b[key]) return 1;
  return 0;
}

/**
 * Downloads a list of objects into a csv file
 * @param {Array} items - objects to download as csv
 * @param {Array} attributes - attribute names
 * @param {String} filename - name of csv file
 */
function downloadCsv(items, attributes, filename) {
  // Format objects into 2D array
  let csvRows = items.map((item) => {
    const record = [];
    // Build record
    for (const attr of attributes) {
      let value = item[attr];
      // Replace null or undefined with empty value
      if (value == null) {
        value = '';
      } else {
        // Convert non-strings into JSON string
        if (typeof value !== 'string') {
          value = JSON.stringify(value);
        }
        // If there are commas or line breaks, surround with double quotes
        if (value.includes(',') || value.includes('\n')) {
          // First escape existing double quotes with another double quote
          value = value.replace(/"/gi, '""');
          value = `"${value}"`;
        }
      }
      record.push(value);
    }
    return record;
  });

  // Prepend attributes as headers
  csvRows = [attributes, ...csvRows];

  let data = '';
  csvRows.forEach(record => data += record.join(',') + '\n');

  // Create hidden <a> tag to download data as csv file
  const hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
  hiddenElement.target = '_blank';
  hiddenElement.download = filename;
  hiddenElement.click();
}

/**
 * Awaitable timeout
 * @param {Number} ms time in milliseconds to sleep
 */
function sleep(ms) {
  return new Promise(function (res) {
    setTimeout(function () {
      res();
    }, ms);
  });
}

/**
 * Check whether a string is a valid URL.
 * Only accepts HTTP and HTTPS protocols.
 * @param  {String} str
 * @return {Boolean}
 */
function isValidUrl(str) {
  try {
    const url = new URL(str);
    return url.protocol.indexOf('http') === 0;
  } catch {
    return false;
  }
}

export {
  debounce,
  throttle,
  removeDuplicates,
  hasProp,
  formatTime,
  hasLength,
  arrayMove,
  clone,
  getRuleString,
  getDuplicates,
  getDuplicateArrayValues,
  hljs,
  formatTest,
  numberWithCommas,
  countDaysBetweenDates,
  sortByKey,
  downloadCsv,
  sleep,
  isValidUrl
};
