<template>
  <div v-if="sidebarOpen" class="sidebar-layout">
    <b-sidebar
      ref="sidebar"
      data-cy-test="experience-sidebar"
      position="static"
      type="is-white"
      fullwidth
      fullheight
      :open="sidebarOpen"
    >
      <div class="back-button bottom5 top8">
        <button
          class="invisible-button"
          data-cy-test="cancel-button"
          @click="handleAbandonChanges"
        >
          <i class="fa fa-long-arrow-left"></i>
          <span v-if="isCreating"> Experiences </span>
          <span v-else> Cancel </span>
        </button>
      </div>

      <div class="segment-border">
        <div class="bottom16" data-cy-test="card-header-title">
          <p v-if="isCreating || isDuplicating" class="title mb-2 is-size-5">New Experience</p>
          <p v-else class="title mb-2 is-size-5">Edit Experience</p>
        </div>
        <b-field
          label="Experience Name"
          :type="isValidName.nameErrMsg ? 'is-danger' : ''"
          :message="isValidName.nameErrMsg"
        >
          <b-input
            v-model="experience.name"
            data-cy-test="experience-name"
            maxlength="500"
            placeholder="Ex. Main Mobile"
            @input="validateExperienceName"
            @blur="validateExperienceName"
          />
        </b-field>
        <section class="bottom20">
          <b-field label="Tags">
            <b-taginput
              v-model="experience.tags"
              data-cy-test="experience-tags"
              :data="filteredTags"
              attached
              placeholder="Add a tag (optional)"
              icon="tag"
              @typing="getFilteredTags"
              @add="handleSavedTags"
              @remove="handleRemoveTags"
            />
          </b-field>
          <b-field
            class="top24 bottom5"
            label="Experience Url (optional)"
            horizontal
          >
            <b-tooltip
              label="Navigate to and set a URL to preview this experience on. URL must include https://"
              position="is-top"
              type="is-info"
              multilined
              animated
            >
              <span class="icon has-text">
                <i class="fa fa-question-circle"></i>
              </span>
            </b-tooltip>
          </b-field>
          <b-field
            grouped
            :type="experienceUrlErr ? 'is-danger' : ''"
            :message="experienceUrlErr"
          >
            <b-input
              v-model="experience.previewUrl"
              data-cy-test="experience-url"
              :placeholder="siteDomain"
              expanded
              @keyup.enter.native="validateExperienceUrl"
            />
            <b-button
              class="preview"
              data-cy-test="experience-url-button"
              icon-left="long-arrow-right"
              @click="validateExperienceUrl"
            >
            </b-button>
          </b-field>
        </section>
      </div>

      <div class="segment top8">
        <b-tabs
          v-model="activeTab"
          expanded
          data-cy-test="tab-item"
        >
          <b-tab-item>
            <template #header>
              <span>Assets<b-tag rounded data-cy-test="asset-number">{{ chosenAssets ? chosenAssets.length : '0' }}</b-tag></span>
            </template>
            <div class="segment">
              <p class="title bottom8 mb-2 is-size-5">Assets</p>
              <div class="bottom24">
                <b-field
                  v-if="!chosenAssets?.length"
                  label="No assets have been defined. You are previewing the base site."
                ></b-field>
                <b-field
                  v-else
                  label="Assets configure the content and behavior on the page."
                ></b-field>
              </div>
              <b-field
                v-for="(asset, index) of chosenAssets"
                :key="asset.assetId"
                class="selections bottom10"
              >
                <template #label>
                  <div class="asset-title">
                    <span>
                      <p>{{ asset.name }}</p>
                    </span>
                    <span class="deleteAsset">
                      <i class="fa fa-trash"
                         data-cy-test="delete-selection"
                         @click="handleDeletedAsset(index)"
                      ></i>
                    </span>
                  </div>
                </template>
                <b-select
                  :value="experience.assetPlacements[index].placement ? experience.assetPlacements[index].placement.placementId : null"
                  data-cy-test="placements-name"
                  expanded
                  placeholder="Select Placement"
                  icon-right="chevron-down"
                  @input="handleSelectedPlacement($event, index)"
                >
                  <option
                    v-for="placement of filteredPlacements(asset, index)"
                    :key="placement.placementId"
                    :value="placement.placementId"
                  >
                    {{ placement.name }}
                  </option>
                  <template #empty>No results found</template>
                </b-select>
              </b-field>
              <div :class="{'bottom50' : !isValidSelection.errMsg}">
                <b-autocomplete
                  v-model="assetNameQuery"
                  class="bottom15 top24"
                  placeholder="Add Asset"
                  :data="filteredAssets"
                  field="name"
                  icon-right="chevron-down"
                  dropdown-position="bottom"
                  open-on-focus
                  clear-on-select
                  clearable
                  data-cy-test="select-an-asset"
                  @select="option => option && handleSelectedAsset(option)"
                >
                  <template #empty>No results found</template>
                </b-autocomplete>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item>
            <template #header>
              <span> Add-Ons <b-tag rounded data-cy-test="routing-number"> {{ chosenRouting ? '1' : '0' }} </b-tag> </span>
            </template>
            <div class="segment">
              <p class="title mb-2 is-size-5">Routing Add-On</p>
              <b-field class="bottom20" label="Route traffic from this page to a different destination URL."></b-field>
              <div class="selections bottom15">
                <b-field class="bottom20" label="Name">
                  <b-autocomplete
                    v-model="addOnNameQuery"
                    icon-right="angle-down"
                    placeholder="Select One"
                    open-on-focus
                    :data="filteredAddOns"
                    field="name"
                    value="addOnId"
                    expanded
                    clearable
                    data-cy-test="select-add-on"
                    @input="selectedRouting($event)"
                  >
                    <template #empty>No results found</template>
                  </b-autocomplete>
                </b-field>
                <b-field v-if="chosenRouting">
                  <b-tooltip
                    :active="!isValidRoutingAddOn(chosenRouting) && !toggleRouting"
                    position="is-top"
                    type="is-info"
                    label="Current page URL does not match this routing add-on, try navigating to the correct URL using the Experience URL field."
                    class="addOn"
                    multilined
                    expanded
                  >
                    <b-button
                      class="routing"
                      data-cy-test="preview-routing-button"
                      expanded
                      :label="toggleRouting ? 'Reset' : 'Preview Routing'"
                      :icon-left="toggleRouting ? 'undo' : 'long-arrow-right'"
                      :disabled="!isValidRoutingAddOn(chosenRouting) && !toggleRouting"
                      @click="handleSelectedRouting(chosenRouting)"
                    >
                    </b-button>
                  </b-tooltip>
                </b-field>
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
        <b-message
          v-if="isValidSelection.errMsg"
          class="top8 bottom50"
          type="is-info"
          data-cy-test="error-message"
        >
          {{ isValidSelection.errMsg }}
        </b-message>
      </div>
      <div>
        <b-button
          class="save-button is-info"
          data-cy-test="save-button"
          expanded
          :disabled="!isValidSelection.valid"
          :label="isCreating || isDuplicating ? 'Create Experience' : 'Save Changes'"
          @click="saveExperience"
        >
        </b-button>
      </div>
    </b-sidebar>
    <AbandonExperienceModal
      :isActive="showModal"
      :isCreating="isCreating"
      :experienceId="initialExperience.experienceId"
      @closeExperienceModal="cancelModal"
    />
  </div>
</template>

<script>
import md5 from 'crypto-js/md5';
import { sync } from 'vuex-pathify';
import { bus } from '../../main';
import { hasLength, sortByKey } from '../../modules/utilities';
import AbandonExperienceModal from './AbandonExperience';

export default {
  name: 'ExperienceSidebar',
  components: {
    AbandonExperienceModal
  },
  props: {
    isCreating: {
      required: true,
      type: Boolean,
      default: true
    },
    isDuplicating: {
      required: true,
      type: Boolean,
      default: false
    },
    experience: {
      required: true,
      type: Object
    },
    initialExperience: {
      required: false,
      type: Object
    },
    sidebarOpen: {
      required: true,
      type: Boolean,
      default: true
    },
    previewUrl: {
      required: true,
      type: String
    },
    displayUrl: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      filteredTags: [],
      toggleRouting: false,
      chosenRouting: '',
      addOnNameQuery: '',
      addOns: {},
      addOnType: 'routing',
      assetNameQuery: '',
      isSavingExperience: false,
      showModal: false,
      errors: null,
      experienceUrlErr: null,
      activeTab: 0
    };
  },
  computed: {
    ...sync([
      'placements/placements',
      'placements/assets'
    ]),
    allExperiences: sync('experiences/list'),
    allExperienceTags() {
      return this.$store.getters['experiences/allExperienceTags'];
    },
    allExperienceNames() {
      return this.$store.getters['experiences/allExperienceNames'];
    },
    allExperienceHashes() {
      return this.$store.getters['experiences/allExperienceHashes'];
    },
    siteId() {
      return this.$store.state.siteId;
    },
    siteDomain() {
      return `https://${this.$store.getters.siteDomain}`;
    },
    allAddOns: sync('addOns/list'),
    addOnsMap() {
      const map = {};
      this.allAddOns.forEach(addOn => map[addOn.addOnId] = addOn);
      return map;
    },
    unselectedAddOns() {
      return this.allAddOns.filter((addOn) => {
        const selectedAddOnIds = Object.values(this.addOns);
        return !selectedAddOnIds.includes(addOn.addOnId) && addOn.type === this.addOnType;
      });
    },
    filteredAddOns() {
      return this.unselectedAddOns.filter(addOn =>
        addOn.name.toLowerCase().includes(this.addOnNameQuery.toLowerCase()) &&
        addOn.isArchived === 0
      );
    },
    chosenAssets() {
      // map through selections assets only
      return this.experience.assetPlacements?.filter(o => !!o.asset).map(o => o.asset);
    },
    chosenPlacements() {
      // map through selections placements only
      return this.experience.assetPlacements?.filter(o => !!o.placement).map(o => o.placement);
    },
    // returns object of the placement ids that correspond to a specific asset id
    assetPlacements() {
      const result = {};
      for (const placement of this.placements) {
        for (const aId of placement.assetIds) {
          if (!result[aId]) result[aId] = [];
          result[aId].push(placement.placementId);
        }
      }
      return result;
    },
    /**
     * Filter the assets based on if they have available placements
     * @return {Array} - collection of filtered assets.
     */
    filteredAssets() {
      // Checks if asset name matches asset query in autocomplete field
      const queryMatch = a => a.name.toLowerCase().includes(this.assetNameQuery.toLowerCase());
      // return true if given asset has an available placement
      const availableAssets = (a) => {
        const placementIds = this.assetPlacements[a.assetId];
        const hasEveryPlacementIds = placementIds?.every(id => this.chosenPlacements?.find(p => p.placementId === id));
        return !hasEveryPlacementIds;
      };
      const result =  this.assets?.filter(a => (queryMatch(a) && availableAssets(a)));
      return result.sort((a, b) => sortByKey(a, b, 'name'));
    },
    // Validate the inputs for the form
    isValidSelection() {
      let errMsg;
      let valid = false;
      const hashCheck = this.checkExperienceHash();
      const number = (hashCheck) ? hashCheck.number : '';
      const archived = (hashCheck && hashCheck.archived) ? 'archived' : '';
      switch (true) {
        case !hasLength(this.experience.assetPlacements) && !hasLength(Object.keys(this.addOns)):
          errMsg = 'Experiences must contain at least one placement and asset or an add-on.';
          break;
        case hashCheck !== false:
          errMsg = `An ${archived} experience with this combination already exists, Experience Number: ${number}.`;
          break;
        default:
          valid = true;
      }
      return {
        errMsg,
        valid
      };
    },
    isValidName() {
      let nameErrMsg;
      let valid = false;
      switch(true) {
        case !this.experience.name:
          nameErrMsg = 'Experience name cannot be blank';
          break;
        case hasLength(this.errors):
          nameErrMsg = this.errors;
          break;
        default:
          valid = true;
      }
      return {
        nameErrMsg,
        valid
      };
    }
  },
  created() {
    this.$store.dispatch('experiences/getList');
    this.$store.dispatch('addOns/getList');
    this.$store.dispatch('placements/getAllPlacementsAndAssets');
    bus.$on('closeExperienceModal', this.cancelModal);
    if (this.experience.addOns) {
      this.chosenRouting = this.filteredAddOns.find(addOn => addOn.addOnId === this.experience.addOns.routing);
      this.addOns = this.experience.addOns;
      this.addOnNameQuery = this.chosenRouting.name;
    }
  },
  beforeDestroy() {
    bus.$off('closeExperienceModal', this.cancelModal);
  },
  methods: {
    handleAbandonChanges() {
      this.showModal = true;
    },
    cancelModal() {
      this.showModal = false;
    },
    generateHash(assetPlacements, addOns = {}) {
      const hashArray = [];
      assetPlacements?.forEach(function (selection) {
        hashArray.push(selection.placement?.placementId + selection.asset.assetId);
      });

      Object.values(addOns).forEach((addOnId) => {
        hashArray.push(addOnId);
      });

      hashArray.sort();
      const hashString = hashArray.join('');
      const md5hash = md5(hashString).toString();
      return md5hash;
    },
    // Check the selection against existing experience hashes
    checkExperienceHash() {
      if (this.experience.assetPlacements) {
        const experienceHash = this.generateHash(this.experience.assetPlacements, this.addOns);
        if (!this.isCreating && !this.isDuplicating) {
          const initialExperienceHash = this.generateHash(this.initialExperience.assetPlacements, this.initialExperience.addOns);
          if (experienceHash === initialExperienceHash) return false;
        }

        const match = this.allExperienceHashes.find((exp) => {
          if (this.experience.experienceNumber === exp.number) return false;
          return exp.hash === experienceHash;
        });
        if (match) {
          return match;
        }
        // no match, return false
        return false;
      }
      return false;
    },
    saveExperience() {
      if (this.chosenRouting) {
        this.experience.addOns = this.addOns;
      }
      if (this.experience.addOns && !this.chosenRouting) {
        delete this.experience.addOns;
      }
      if (this.isCreating || this.isDuplicating) {
        this.$emit('createExperience');
      } else {
        this.$emit('updateExperience');
      }
    },
    // Generates error messages if experience name is not a valid name
    validateExperienceName() {
      const duplicateName = this.allExperiences.find(e => e.name?.toLowerCase() === this.experience.name?.trim().toLowerCase());
      if (duplicateName) {
        if (!this.isCreating && !this.isDuplicating && this.initialExperience?.name.toLowerCase() === duplicateName.name.toLowerCase()) {
          this.errors = null;
        } else {
          this.errors = `${this.experience.name} already exists`;
        }
      } else {
        this.errors = null;
      }
    },
    // Tag Management Functions
    // -------------------
    getFilteredTags(text) {
      const filtered = this.allExperienceTags.filter(function (tag) {
        return tag.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
      if (!filtered.includes(text)) filtered.push(text);
      this.filteredTags = filtered;
    },
    handleSavedTags(tag) {
      if (!this.filteredTags.includes(tag)) this.filteredTags.push(String(tag).toLowerCase());
    },
    handleRemoveTags(tag) {
      this.filteredTags = this.filteredTags.filter(tagItem => tagItem !== tag);
    },
    validateExperienceUrl() {
      if (!this.experience.previewUrl?.includes('https://')) {
        this.experienceUrlErr = 'Invalid url, please include https://';
      } else {
        this.experienceUrlErr = null;
        this.$emit('experienceUrl');
      }
    },
    selectedRouting(routing) {
      if (routing) {
        this.chosenRouting = this.filteredAddOns.find(addOn => addOn.name === routing);
        this.$set(this.addOns, this.addOnType, this.chosenRouting.addOnId);
      }
      else {
        this.addOns = {};
        this.chosenRouting = '';
      }
    },
    handleSelectedRouting(route) {
      this.toggleRouting = !this.toggleRouting;
      this.$emit('routingUrl', this.toggleRouting, route.config.destinationURL);
    },
    isValidRoutingAddOn(routing) {
      const urlRegex = routing.config.regexp;
      const url = new URL(this.displayUrl);
      return new RegExp(urlRegex).test(`${url.origin}${url.pathname}`);
    },
    handleSelectedAsset(asset) {
      // adds selected asset into selections and initialized placement
      this.experience.assetPlacements.push({ asset, placement:null });
    },
    handleSelectedPlacement(pId, index) {
      // overrides null placement with the selected placement into selections
      if (!pId) return;
      this.experience.assetPlacements[index].placement = this.placements.find(placement => placement.placementId === pId);
      this.$emit('handleUpdatePreview');
    },
    handleDeletedAsset(index) {
      this.experience.assetPlacements.splice(index, 1);
      if (!this.experience.assetPlacements.length) {
        this.$emit('handleNoSelections');
      }
      else {
        this.$emit('handleUpdatePreview');
      }
    },
    /**
     * Filter the placements based on the given conditions.
     * @return {Array} - collection of filtered placements.
     */
     filteredPlacements(asset, index) {
      // 1. Get all of the placementIds that have already been selected
      const sPlacementIds = this.experience.assetPlacements.map(ap => ap.placement?.placementId).filter(pId => !!pId);
      sPlacementIds.splice(index, 1);
      // 2. Asset is already selected, get the placementIds it contains.
      const sAssetPlacementIds = (asset) ?
        this.placements
        .filter(({
          assetIds
        }) => assetIds.indexOf(asset.assetId) !== -1)
        .map(({
          placementId
        }) => placementId) : [];
      // 3. Filter in our placements and sort them by name.
      const result = this.placements.filter(({
        placementId
      }) => {
        return (
          sPlacementIds.indexOf(placementId) === -1 &&
          (sAssetPlacementIds.length === 0 || sAssetPlacementIds.indexOf(placementId) !== -1)
        );
      });
      return result.sort((a, b) => sortByKey(a, b, 'name'));
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar-layout {
  display: flex;
  float: left;
  height: 100%;
  .b-sidebar {
    height: 100%;
    max-width: 300px;
    border-right: 1px solid #EDF0F3;
    position: relative;
    ::v-deep .sidebar-content.is-fullheight {
      padding-left: 16px;
      padding-right: 16px;
    }
    ::v-deep .label {
      font-size: 14px;
      font-weight: normal;
      color: #7E90A5;
    }
    ::v-deep .field {
      margin-bottom: 0px;
      &-label {
        margin-right: 0;
        min-width: fit-content;
      }
    }
    .invisible-button {
      padding: 5px 5px 5px;
      color: #0696EF;
    }
    ::v-deep .tab-content {
      padding: 0;
      padding-top: 8px;
    }
    .addOn {
      min-width: 100%;
    }
    .icon {
      color: #626D7A;
    }
    .routing {
      background-color: #F6F7F9;
      color: #626D7A;
      font-weight: bold;
      min-width: 100%;
    }
    .preview {
      background-color: #F6F7F9;
      color: #626D7A;
    }
    .segment {
      padding: 2px 5px;
      margin-bottom: 10px;
      &-border {
        border-bottom: 1px solid #EDF0F3;
      }
    }
    .title {
      padding-top: 5px;
      margin-bottom: 8px !important;
    }
    .selections {
      background-color: #F6F7F9;
      padding: 8px 8px;
      border-radius: 5px;
      .deleteAsset {
        margin-right: 6px;
        color: #626D7A;
      }
    }
    .asset-title {
      justify-content: space-between;
      display: flex;
    }
    .save-button {
      bottom: 16px;
      margin-left: 3px;
      position: absolute;
      max-width: 260px;
    }
    ::v-deep a.dropdown-item:hover {
      white-space: break-spaces;
    }
    ::v-deep .control.has-icons-right .input {
     font-weight: 500;
    }
    ::v-deep .tooltip-content {
      width: 220px;
    }
  }
}
</style>
