<template>
  <div
    v-if="showDiff"
    class="ff-variable-section__column"
  >
    <h5 class="title is-5" :data-cy-test="`ff-${fileType}-title`">{{ variableTypeTitle }} Variables</h5>
    <!-- REMOVED -->
    <div
      v-if="showRemoved"
      :data-cy-test="`ff-${fileType}-removed`"
      class="removed-variables variable-section"
    >
      <div
        v-for="(removedVarName, i) of Object.values(assetsDiff.variables.removed[fileType])"
        :key="`removed-${fileType}-${i}`"
        class="removed-variable"
      >
        <b-field
          :key="`removed-${removedVarName}`"
          :label="`{${removedVarName}}`"
          :data-cy-test="`ff-removed-variable-name-${removedVarName}`"
        >
          <b-input
            :value="duplicateAssetVars.variables[fileType][removedVarName]"
            expanded
            placeholder="Enter a value"
            disabled
            :data-cy-test="`ff-${fileType}-removed-input-${removedVarName}`"
          />
        </b-field>

        <article class="message is-danger" :data-cy-test="`ff-${fileType}-removed-variable-message-${removedVarName}`">
          <div class="message-body">
            <p>{{ bracketWrap(removedVarName) }} has been removed from the base asset</p>
          </div>
        </article>
      </div>

      <div v-if="assetsDiff.conditionals && Object.keys(assetsDiff.conditionals.removed).length">
        <div
          class="removed-variable"
        >
          <span v-for="[sectionName, value] in Object.entries(baseAssetVars.conditionals[fileType])" :key="`conditional-${fileType}-${sectionName}`">
            <optional-variable-section
              :sectionName="sectionName"
              :section="value"
              :assetVariables="preampVars"
              :showToggle="false"
              :disabledInputs="true"
              class="mb-3"
            />
            <article class="message is-danger" :data-cy-test="`ff-${fileType}-removed-section-message-${sectionName}`">
              <div class="message-body">
                <p>{{ sectionName }} has been removed from the base asset</p>
              </div>
            </article>
          </span>

        </div>
      </div>
    </div>

    <!-- ADDED -->
    <div
      v-if="showAdded"
      class="added-variables variable-section"
      :data-cy-test="`ff-${fileType}-added`"
    >
      <div v-if="assetsDiff.variables && assetsDiff.variables.added && Object.keys(assetsDiff.variables.added).length">
        <div
          v-for="(addedVarName, i) of assetsDiff.variables.added[fileType]" :key="`added-${fileType}-${i}`"
          class="added-variable"
        >
          <span v-if="isNotConditionalVar(addedVarName)">
            <variable-input
              :key="`addedvar-${addedVarName}`"
              v-model="addedVars.variables[fileType][addedVarName]"
              :data-cy-test="`ff-${fileType}-variable-input-${addedVarName}`"
              :name="addedVarName"
            />
            <article class="message is-success" :data-cy-test="`ff-${fileType}-added-variable-message-${addedVarName}`">
              <div class="message-body">
                <p>{{ bracketWrap(addedVarName) }} has been added from the base asset</p>
              </div>
            </article>
          </span>
        </div>
      </div>

      <div v-if="assetsDiff.conditionals && assetsDiff.conditionals.added && Object.keys(assetsDiff.conditionals.added).length">
        <div class="added-variable">
          <span v-for="[sectionName, value] in Object.entries(baseAssetVars.conditionals[fileType])" :key="`conditional-${sectionName}`">
            <optional-variable-section
              :key="sectionName"
              :data-cy-test="`ff-${fileType}-optional-section-${sectionName}`"
              :sectionName="sectionName"
              :section="value"
              :assetVariables="proposedVariables"
              class="mb-3"
            />
            <article class="message is-success" :data-cy-test="`ff-${fileType}-added-section-message-${sectionName}`">
              <div class="message-body">
                <p>{{ sectionName }} has been added from the base asset</p>
              </div>
            </article>
          </span>
        </div>
      </div>
    </div>

    <!-- CARRY OVER -->
    <div v-if="carryOverGlobalVariables[fileType]" class="carry-over-variables variable-section">
      <div
        v-for="varName in carryOverGlobalVariables[fileType]"
        :key="varName"
        class="carry-over-variable"
      >
        <variable-input
          v-model="carryOverVars.variables[fileType][varName]"
          :name="varName"
        />
      </div>
      <div
        v-for="(section, sectionName) in carryOverVars.conditionals[fileType]"
        :key="sectionName"
        class="carry-over-variable"
      >
        <optional-variable-section
          :sectionName="sectionName"
          :section="section"
          :assetVariables="proposedVariables"
          class="mb-3"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import VariableInput from '../VariableInput.vue';
  import OptionalVariableSection from '../OptionalVariableSection.vue';
  export default {
    name: 'FastForwardVariables',
    components: {
      'variable-input': VariableInput,
      'optional-variable-section': OptionalVariableSection
    },
    props: {
      duplicateAssetVars: {
        type: Object,
        required: true
      },
      baseAssetVars: {
        type: Object,
        required: true
      },
      preampVars: {
        type: Object,
        required: true
      },
      assetsDiff: {
        type: Object,
        required: true
      },
      carryOverVars: {
        type: Object,
        required: true
      },
      fileType: {
        type: String,
        required: true
      },
      proposedVariables: {
        type: Object,
        required:true
      },
      addedVars: {
        type: Object,
        required:true
      }
    },
    computed: {
      variableTypeTitle() {
        if (this.fileType === 'metadata') {
            return 'JSON';
        }

        return this.fileType.toUpperCase();
      },
      showDiff() {
        return this.showAdded || this.showRemoved;
      },
      showAdded() {
        return (this.assetsDiff && this.assetsDiff.variables && this.assetsDiff.variables.added && this.assetsDiff.variables.added[this.fileType] && Object.values(this.assetsDiff.variables.added[this.fileType]).length)
          || (this.assetsDiff && this.assetsDiff.conditionals && this.assetsDiff.conditionals.added && this.assetsDiff.conditionals.added[this.fileType] && Object.values(this.assetsDiff.conditionals.added[this.fileType]).length);
      },
      showRemoved() {
        return (this.assetsDiff && this.assetsDiff.variables && this.assetsDiff.variables.removed && this.assetsDiff.variables.removed[this.fileType] && Object.values(this.assetsDiff.variables.removed[this.fileType]).length)
          || (this.assetsDiff && this.assetsDiff.conditionals && this.assetsDiff.conditionals.removed && this.assetsDiff.conditionals.removed[this.fileType] && Object.values(this.assetsDiff.conditionals.removed[this.fileType]).length);
      },
      baseGlobalVariables() {
        // variables in the base asset that are not part of an optional section
        let allOptionalSectionVariables = [];
        let uniqueSectionVariables = [];

        if (this.baseAssetVars.conditionals) {
          for (const fileType in this.baseAssetVars.conditionals) {
            for (const section in this.baseAssetVars.conditionals[fileType]) {
              allOptionalSectionVariables = [ ...allOptionalSectionVariables, ...this.baseAssetVars.conditionals[fileType][section].variablesUsed ];
            }
          }
          uniqueSectionVariables = [ ...new Set(allOptionalSectionVariables) ];
        }

        let baseGlobalVariables = {};

        if (this.baseAssetVars.variables) {
          for (const fileType in this.baseAssetVars.variables) {
            baseGlobalVariables[fileType] = Object.keys(this.baseAssetVars.variables[fileType]).filter((varName) => {
              return !uniqueSectionVariables.includes(varName);
            });
          }
        }

        return baseGlobalVariables;
      },
      carryOverGlobalVariables() {
        if (!Object.keys(this.baseGlobalVariables).length) return {};
        let carryOverVarsByType = {};
        for (const fileType in this.baseGlobalVariables) {
          const carryOvers = this.carryOverVars.variables[fileType] ? Object.keys(this.carryOverVars.variables[fileType]) : [];
          const baseGlobals = this.baseGlobalVariables[fileType] ?? [];
          carryOverVarsByType = {
            ...carryOverVarsByType,
            [fileType]: this.getArrayIntersection(carryOvers, baseGlobals)
          };
        }
        return carryOverVarsByType;
      }
    },
    methods: {
      bracketWrap(varName) {
          return `{${varName}}`;
      },
      getArrayIntersection(array1, array2) {
          return array1.filter(value => array2.includes(value));
      },
      isNotConditionalVar(addedVariableName) {
        if (!this.baseAssetVars.conditionals) return true;

        for (const fileType in this.baseAssetVars.conditionals) {
          if (!fileType || !this.baseAssetVars.conditionals[fileType]) continue;

          for (const value of Object.values(this.baseAssetVars.conditionals[fileType])) {
            if (value && value.variablesUsed && value.variablesUsed.includes(addedVariableName)) {
              return false;
            }
          }
        }
        return true;
      }
    }
  };
</script>
