import { axiosInstance as axios } from '@/plugins/axios';
import { make } from 'vuex-pathify';
import store from '../index';

const state = {
  list: [],
  isLoading: false,
  liveIntegrations: []
};

const mutations = {
   ...make.mutations(state)
};

const actions = {
  async getList({ dispatch, commit, rootState }) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'integrations/getList' }, { root: true });
      return;
    }

    // if no token is available on the site, set the list to an empty array
    if (!getters.enrichmentServiceToken()) {
      commit('SET_LIST', []);
      return;
    }

    try {
      commit('SET_IS_LOADING', true);

      const response = await axios.get(`/sites/${rootState.siteId}/integrations`);

      commit('SET_LIST', response.data);
    } catch (e) {
      e.title = 'There was a problem retrieving integration data.';
      commit('error', e, { root: true });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async createIntegration({ commit, rootState, dispatch }, integration) {
    try {
      const url = `/sites/${rootState.siteId}/integrations`;
      const response = await axios.post(url, { integration });

      window.vm.$buefy.toast.open({
        message: `Integration ${response.data.name} created`,
        type: 'is-success'
      });
    } catch (error) {
      error.title = 'There was a problem creating a new integration';
      error.message = error.response.data || null;

      commit('error', error, { root: true });
    } finally {
      dispatch('getList');
    }
  },
  async updateIntegration({ commit, rootState, dispatch }, integration) {
    try {
      const url = `/sites/${rootState.siteId}/integrations/${integration.integrationId}`;
      const response = await axios.put(url, { integration });

      window.vm.$buefy.toast.open({
        message: `Integration ${response.data.name} successfully updated.`,
        type: 'is-success'
      });
    } catch (error) {
      error.title = `Could not update integration: ${integration.name}`;
      error.message = error.response.data || null;

      commit('error', error, { root: true });
    } finally {
      dispatch('getList');
    }
  },
  async deleteIntegration({ commit, rootState, dispatch }, integration) {
    try {
      const url = `/sites/${rootState.siteId}/integrations/${integration.integrationId}`;

      await axios.delete(url, {data: { integration }});

      window.vm.$buefy.toast.open({
        message: `Integration ${integration.name} successfully deleted.`,
        type: 'is-success'
      });
    } catch (error) {
      error.title = `Could not delete integration: ${integration.name}`;
      error.message = error.response.data || null;

      commit('error', error, { root: true });
    } finally {
      dispatch('getList');
    }
  },
  async getLiveIntegrations({ commit, rootState, dispatch }) {
    if (!rootState.siteId) {
      dispatch('deferActionForSiteID', { refPath: 'integrations/getLiveIntegrations' }, { root: true });
      return;
    }

    // if no token is available for the site, keep default state
    if (!getters.enrichmentServiceToken()) {
      commit('SET_LIVE_INTEGRATIONS', []);
      return;
    }

    try {
      const url = `/sites/${rootState.siteId}/traffic-flows`;
      const response = await axios.get(url);

      // get just the audiences from the traffic flows
      const audiences = response.data.reduce((acc, dataObj) => {
        acc.push(...dataObj.audiences);
        return acc;
      }, []);

      // comb over audiences to get all integration ids that are currently used on a rule
      const liveIntegrationIds = audiences.reduce((acc, audience) =>{
        // get and flatten out rules, which is an array of arrays
        const flattened = audience.rules.flat();
        // get all integration ids from all rule options properties
        const ids = flattened.reduce((acc2, rule) => {
          if (rule.options && rule.options.integrationId) {
            acc2.push(rule.options.integrationId);
          }

          return acc2;
        }, []);

        // add these id's to the final return array
        acc.push(...ids);
        return acc;
      }, []);

      commit('SET_LIVE_INTEGRATIONS', liveIntegrationIds);
    } catch (error) {
      error.title = `Could not get live integrations for site: ${rootState.siteId}`;

      commit('error', error, { root: true });
    }
  }
};

const getters = {
  isIntegrationConfigEnabled() {
    // only show integration config on traffic flows if user is a super user
    // or if the site has a provisioned enrichment token
    return store.get('isSuperUser') || getters.enrichmentServiceToken() !== null;
  },
  /**
   * gets the required enrichment service token from site data
   * @returns token UUID or null
   */
  enrichmentServiceToken() {
    const activeSite = store.get('activeSite');
    return activeSite ? activeSite.enrichmentServiceToken || null : null;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
