<template>
  <div
    class="box"
    style="height: 100%; margin-bottom: 0;"
  >
    <div class="level">
      <div class="level-left">
        <div
          v-if="deviceSelection || browserSelection"
          class="level-item"
        >
          <button
            class="button"
            @click="goToPreviousView"
          ><span class="icon"><i class="fa fa-arrow-left"></i></span></button>
        </div>
        <div class="level-item">
          <div
            class="is-flex is-relative"
            style="flex-direction: column; overflow-y: hidden;"
          >
            <transition name="slide-up">
              <div
                v-if="deviceSelection"
                style="font-size: .75rem;"
              >
                <span>{{ deviceSelection }}</span>
                <span
                  v-if="browserSelection"
                  class="icon is-small"
                ><i class="fa fa-angle-right"></i></span>
                <transition name="slide-up">
                  <span v-if="browserSelection">{{ browserSelection }}</span>
                </transition>
              </div>
            </transition>
            <div><span class="title is-5">{{ title }} Breakdown</span></div>
          </div>
        </div>
      </div><!-- /.level-left -->

      <div class="level-right">
        <div class="level-item">
          <LookbackRangePicker
            @format-selection="val => hasNumbersViewSelected = val"
            @selection="val => lookbackRange = val"
          />
        </div>
      </div><!-- /.level-right -->
    </div><!-- /.level -->

    <div class="insights__nested-chart-container">
      <transition :name="firstTransitionName">
        <ChildPieChart
          v-if="showFirstView"
          :chart-data="chartData"
          :has-numbers-view-selected="hasNumbersViewSelected"
          table-heading="Device Type"
          :table-data="tableData"
          :is-loading="isLoading"
          @selection="handleDeviceSelection"
        >
          <p
            slot="help"
            class="help"
          >
            <span class="icon"><i class="fa fa-info-circle"></i></span>
            Click on a device type to view it's browser breakdown
          </p>
        </ChildPieChart>
      </transition>

      <transition :name="middleTransitionName">
        <ChildPieChart
          v-if="deviceSelection && !browserSelection"
          :chart-data="chartData"
          :has-numbers-view-selected="hasNumbersViewSelected"
          table-heading="Browser Name"
          :table-data="tableData"
          :is-loading="isLoading"
          @selection="handleBrowserSelection"
        >
          <p
            slot="help"
            class="help"
          >
            <span class="icon"><i class="fa fa-info-circle"></i></span>
            Click on a browser to view it's version breakdown
          </p>
        </ChildPieChart>
      </transition>

      <transition :name="browserSelection ? 'slide-left' : 'slide-right'">
        <ChildPieChart
          v-if="deviceSelection && browserSelection"
          :chart-data="chartData"
          :has-numbers-view-selected="hasNumbersViewSelected"
          table-heading="Browser Version"
          :table-data="tableData"
          :has-disabled-links="true"
          :is-loading="isLoading"
        />
      </transition>
    </div>

  </div>
</template>

<script>
import LookbackRangePicker from '@/components/Insights/LookbackRangePicker';
import payloads from '@/modules/insightsPayloads';

// relative components
import ChildPieChart from './ChildPieChart';

export default {
  name: 'DeviceBrowserParentChart',
  components: {
    LookbackRangePicker,
    ChildPieChart
  },
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      lookbackRange: null,
      rawChartData: null,
      totalHits: 0,
      deviceSelection: null,
      browserSelection: null,
      firstTransitionName: 'slide-left',
      middleTransitionName: 'slide-left',
      // flags
      isLoading: false,
      hasNumbersViewSelected: false
    };
  },
  computed: {
    showFirstView() {
      return !this.deviceSelection && !this.browserSelection;
    },
    title() {
      if (this.showFirstView) {
        return 'Device';
      } else if (!this.browserSelection) {
        return 'Browser';
      } else {
        return 'Version';
      }
    },
    // returns insights data in percent or number format
    tableData() {
      if (!this.rawChartData) {
        return [];
      } else {
        return this.convertToTableFormat(this.rawChartData);
      }
    },
    // modifies the table data to pass to the chart
    chartData() {
      const result = {};

      if (!this.tableData.length) return result;

      for (const item of this.tableData) {
        result[item.prop] = item.value;
      }

      return result;
    },
    browserInsightsPayload() {
      const payload = {
        queries: [],
        terms: {
          size: 10,
          order: { _count: 'desc' }
        }
      };

      if (this.deviceSelection) {
        payload.terms.field = 'userAgent.browserName.keyword';
        payload.queries.push({
          // ex: isDesktop: true
          term: { [`userAgent.${this.deviceSelection}`]: true }
        });
      }

      if (this.browserSelection) {
        payload.terms.field = 'userAgent.browserVersion.keyword';
        payload.terms.size = 5; // top 5 versions
        payload.queries.push({
          // ex: userAgent.browserName: Chrome Mobile
          term: { 'userAgent.browserName.keyword': this.browserSelection }
        });
      }

      return payload;
    }
  },
  watch: {
    siteId(val) {
      if (val) {
        this.getInsights();
      }
    },
    lookbackRange(newRange) {
      if (newRange) {
        this.getInsights();
      }
    }
  },
  methods: {
    getInsights() {
      if (!this.deviceSelection && !this.browserSelection) {
        this.getDeviceInsights();
      } else {
        this.getBrowserInsights();
      }
    },
    async getDeviceInsights() {
      if (!this.lookbackRange) return;
      this.isLoading = true;

      try {
        const res = await this.$axios.post(`/sites/${this.siteId}/insights/breakdowns/${this.lookbackRange}`, {
          filters: payloads.device
        });

        this.rawChartData = res.data.counts;
        this.totalHits = res.data.total;
      } catch (error) {
        this.$store.commit('error', error);
      }

      this.isLoading = false;
    },
    async getBrowserInsights() {
      if (!this.lookbackRange || !this.deviceSelection) return;

      this.isLoading = true;

      try {
        const res = await this.$axios.post(`/sites/${this.siteId}/insights/aggregates/${this.lookbackRange}`, this.browserInsightsPayload);

        this.rawChartData = res.data.counts;
        this.totalHits = res.data.total;
      } catch (error) {
        this.$store.commit('error', error);
      }

      this.isLoading = false;
    },
    goToPreviousView() {
      this.middleTransitionName = 'slide-right';
      // we only need to reset browser if we are in last view
      if (this.browserSelection) {
        this.browserSelection = null;
      } else {
        this.firstTransitionName = 'slide-right';
        this.deviceSelection = null;
      }

      this.getInsights();
    },
    convertToTableFormat(data) {
      const result = [];

      for (const key in data) {
        const dataPoint = {
          prop: key,
          value: data[key]
        };

        if (dataPoint.value && this.hasNumbersViewSelected === false) {
          dataPoint.value = Math.round((dataPoint.value / this.totalHits) * 100);
        }

        result.push(dataPoint);
      }

      return result.sort(function (a, b) {
        return b.value - a.value;
      });
    },
    handleDeviceSelection(selection) {
      this.middleTransitionName = 'slide-left';
      this.deviceSelection = selection;
      this.getInsights();
    },
    handleBrowserSelection(selection) {
      this.browserSelection = selection;
      this.getInsights();
    }
  }
};
</script>

<style lang="scss">
.tags .tag.is-link {
  background-color: #3273dc;
  color: white;
}

.insights {
  &__nested-chart-container {
    overflow-x: hidden; // contain animations to inside the chart
    position: relative;
    width: 100%;
  }
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 300ms ease-out, opacity 50ms ease-out;
}
.slide-up-enter {
  opacity: 0;
  transform: translate3d(0, -50%, 0);
  position: absolute;
  width: 100%;
  height: 100%;
}
.slide-up-leave-to {
  opacity: 0;
  transform: translate3d(0, 10%, 0);
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
