<template>
  <div class="card is-expandable">
    <header
      class="card-header"
      @click="isExpanded = !isExpanded"
    >
      <p class="card-header-title">
        <span data-cy-test="card-header-title">{{ name }}</span>
      </p>

      <span
        v-if="audiences.length"
        class="card-header-icon"
      >
        <span class="tag is-success">Active</span>
      </span>

      <span class="card-header-icon">
        <span
          class="icon"
          :class="{ 'is-rotated': isExpanded }"
        >
          <i class="fa fa-angle-down"></i>
        </span>
      </span>
    </header>

    <div
      v-if="isExpanded"
      class="card-content"
    >
      <div class="columns is-multiline">
        <div class="column">
          <section class="bottom20">
            <h6
              class="title is-6"
              data-cy-test="title"
            >List Details</h6>

            <table class="table is-bordered is-fullwidth is-narrow">
              <tr>
                <th>Name</th>
                <td>
                  <input
                    v-if="isEditing"
                    v-model.trim="list.name"
                    type="text"
                    class="input"
                    data-cy-test="rule-list-name"
                  >
                  <span
                    v-else
                    data-cy-test="name"
                  >{{ list.name }}</span>
                </td>
              </tr>
              <tr>
                <th data-cy-test="list-id">List ID</th>
                <td><span>{{ list.listId }}</span></td>
              </tr>
              <tr>
                <th data-cy-test="created-at">Created At</th>
                <td><span>{{ list.createdAt | formatTime }}</span></td>
              </tr>
              <tr v-if="list.updatedAt">
                <th data-cy-test="updated-at">Updated At</th>
                <td><span>{{ list.updatedAt | formatTime }}</span></td>
              </tr>
            </table>
          </section>

          <section class="bottom20">
            <h6
              class="title is-6"
              data-cy-test="title"
            >Audience Details</h6>

            <table
              v-if="audiences.length"
              class="table is-bordered is-fullwidth is-narrow"
            >
              <tbody>
                <tr
                  v-for="(audience, i) in audiences"
                  :key="i"
                >
                  <td>{{ audience }}</td>
                </tr>
              </tbody>
            </table>

            <div v-else>
              <article class="message">
                <div
                  class="message-body"
                  data-cy-test="message-body"
                >
                  This list is not used in any Audiences.
                </div>
              </article>
            </div>
          </section>
        </div>

        <div class="column">
          <AudienceRuleListUpload
            :file.sync="file"
            :valid.sync="valid"
            :listId="list.listId"
            :siteId="list.siteId"
            :stacked="true"
            :editable="isEditing"
          />
        </div>
      </div>
    </div>

    <footer
      v-if="isExpanded"
      class="card-footer"
    >
      <div class="level">
        <div class="level-left">
          <div
            v-if="!isEditing && isExpanded"
            class="level-item"
            data-cy-test="edit-button"
          >
            <base-button-edit @click="handleEdit" />
          </div>

          <div
            v-if="isEditing"
            class="level-item"
            data-cy-test="save-button"
          >
            <base-button-save
              :loading="isSaving"
              :disabled="!validation.valid"
              @click="save"
            />
          </div>

          <div
            v-if="isEditing"
            class="level-item"
          >
            <button
              class="button"
              @click.stop="reset(true)"
            >Cancel</button>
          </div>

          <div
            v-if="!validation.valid && isEditing"
            class="level-item"
          >
            <p class="has-text-danger">
              <span class="icon">
                <i class="fa fa-exclamation-circle"></i>
              </span>
              <span>{{ validation.message }}</span>
            </p>
          </div>
        </div>

        <div class="level-right">
          <div class="level-item">
            <b-tooltip
              type="is-dark"
              position='is-left'
              data-cy-test="delete-button"
              :label="(audiences.length > 0)
                ? 'List must be removed from audiences before deleting.'
                : 'Permanently delete Rule List.'
              "
            >
              <base-button-delete
                :disabled="audiences.length > 0"
                @click="confirmDelete"
              />
            </b-tooltip>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import sanitize from 'sanitize-filename';
import { formatTime } from '@/modules/utilities';
import AudienceRuleListUpload from '@/components/AudienceRuleLists/AudienceRuleListUpload';
import validate from '@/components/AudienceRuleLists/validation';
import { bus } from '@/main';

export default {
  name: 'AudienceRuleList',
  components: {
    AudienceRuleListUpload
  },
  filters: {
    formatTime
  },
  props: ['name', 'listId', 'siteId', 'audiences'],
  data() {
    return {
      list: {},
      file: null,
      valid: false,
      isLoading: false,
      isExpanded: false,
      isEditing: false,
      isSaving: false
    };
  },
  computed: {
    // Check that the user has a lock on the site.
    hasLock() {
      return this.$store.getters.siteLockOwner;
    },
    // Validate the changes made to the list.
    validation() {
      return validate(this.list.name, this.file, this.valid);
    }
  },
  watch: {
    isExpanded(bool) {
      if (bool) {
        this.getAudienceRuleList();
      }
    }
  },
  created() {
    this.reset();
    this.registerEventListeners();
  },
  methods: {
    handleEdit() {
      this.$root.establishSiteLock().then(() => {
        this.isEditing = true;
      });
    },
    /**
     * Set the file in state.
     * @param {String} name
     * @param {Array} values
     */
    setFile(name, values = this.list.value) {
      const n = `${sanitize(this.list.name.toLowerCase().replace(/(\s)/g, '-'))}.csv`;
      const v = values.join('\n');
      this.file = new File([v], n);
    },
    // Save the rule list to the DB.
    async save() {
      this.isSaving = true;
      try {
        const data = new FormData();
        data.append('csv', this.file);
        data.append('name', this.list.name);

        const res = await this.$axios.post(`/sites/${this.siteId}/audience-rule-list/${this.listId}`, data);
        this.list = res.data;

        this.setFile(this.list.name, this.list.value);

        this.$emit('list-updated');
      } catch (err) {
        err.title = 'There was a problem updating this list.';
        this.$store.commit('error', err);
      }
      this.isSaving = false;
      this.isEditing = false;
    },
    /**
     * Reset the audience rule list back to it's original state.
     * @param {Boolean} manual - whether this was triggered via a user.
     */
    reset(manual) {
      this.list.name = this.name;
      this.isEditing = false;

      if (manual) {
        this.setFile(this.list.name, this.list.values);
      }
    },
    // Fetch the audience rule lists data.
    async getAudienceRuleList() {
      this.isLoading = true;
      try {
        const res = await this.$axios.get(`/sites/${this.siteId}/audience-rule-list/${this.listId}`);
        this.list = res.data;

        this.setFile(this.list.name, this.list.value);
      } catch (err) {
        err.title = `There was a problem getting audience rule list "${this.listId}."`;
        this.$store.commit('error', err);
      }
      this.isLoading = false;
    },
    // Delete the audience rule list.
    async delete() {
      try {
        await this.$root.establishSiteLock();
        await this.$axios.delete(`/sites/${this.siteId}/audience-rule-list/${this.listId}`);
        this.$emit('list-deleted');
      } catch (err) {
        err.title = 'There was a problem deleting this list item.';
        this.$store.commit('error', err);
      }
    },
    // Confirm deletion of the rule list.
    confirmDelete() {
      this.$root.establishSiteLock().then(() => {
        // Store the prompt for programmatic close
        this.deletePrompt = this.$buefy.dialog.confirm({
          title: `Deleting "${this.list.name}"`,
          message: `
            Are you sure you want to delete the list "${this.list.name}"?
            This action cannot be reversed.
          `,
          confirmText: 'Delete',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.delete();
          }
        });
      });
    },
    handleSiteLockLost() {
      this.reset();
      if (this.deletePrompt) this.deletePrompt.close();
    },
    registerEventListeners() {
      bus.$on('site-lock-lost-user', this.handleSiteLockLost);
    }
  }
};
</script>
