<template>
  <td>
    <pre
      v-for="(item, x) in diff"
      v-if="item.value.trim() !== '{}'"
      :key="x"
      :class="{
        'added': item.added,
        'removed': item.removed
      }"
    ><code>{{ item.value }}</code></pre>
  </td>
</template>

<script>
import { diffJson } from 'diff';

export default {
  name: 'DiffObject',
  props: ['prev', 'next'],
  computed: {
    // Return the diff object
    diff() {
      const prev = this.prev || {};
      return diffJson(prev, this.next);
    }
  }
};
</script>

<style lang="scss" scoped>
pre {
  padding: 0 1.5rem;
  white-space: pre-wrap; // pre-wrap prevents overflow issues

  &:first-child {
    padding-top: 1.25rem;
  }

  &:last-child {
    padding-bottom: 1.25rem;
  }
}
</style>
