<template>
  <section>
    <div v-if="dapi.clientToken">
      <div class="title is-6 bottom10 top25" data-cy-test="sample-dapi-outcome">Sample DAPI Outcome</div>
      <p><a href="https://redventures.atlassian.net/wiki/spaces/COHSN/pages/486933183/V1+Outcomes" target="_blank" rel="noreferrer noopener">
        DAPI V1 Outcomes Documentation
      </a></p>
      <code>POST https://dapi.cohesionapps.com/api/v1/outcome</code><br>
      <code>Authorization: Bearer CLIENT_TOKEN</code><br>
      <code>Content-Type: application/json</code>
      <pre>{{ sampleDapiOutcomePayload }}</pre>
    </div>

    <h6
      class="title is-6 bottom10 top25"
      data-cy-test="title"
    >Experience Options</h6>
    <table class="table is-narrow is-fullwidth is-bordered">
      <thead>
        <tr>
          <th
            colspan="2"
            class="heading"
          >Experience Number &amp; Name</th>
        </tr>
      </thead>
      <tbody v-if="dapi && dapi.options">
        <tr
          v-for="(experience, index) in dapi.options"
          :key="`dapi-display-${index}-${experience.experienceId}`"
        >
          <td style="width: 60px; text-align: center;">{{ experience.experienceNumber }}</td>
          <td
            colspan="2"
            data-cy-test="experience-name-in-exp-option"
          >
            <router-link :to="`/experiences/${experience.experienceId}`">
              {{ experience.name }}
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
export default {
  name: 'MabExpDisplay',
  props: {
    testId: {
      type: String,
      required: true
    },
    dapi: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      isExpanded: false,
      rewardQuery: null,
      queryString: null
    };
  },
  computed: {
    siteId() {
      return this.$store.state.siteId;
    },
    tenantId() {
      return this.$store.getters.siteTenantId;
    },
    // Write key if it exists
    siteWriteKey() {
      const { destination = {} } = this.$store.getters.activeSite;
      return destination[destination.type]?.writeKey;
    },
    sampleDapiOutcomePayload() {
      return JSON.stringify({
        agentName: this.testId,
        tenantId: this.dapi.tenantId ?? this.siteWriteKey ?? this.tenantId,
        writeKey: this.siteWriteKey ?? 'WRITE_KEY',
        sessionId: 'SESSION_ID',
        anonymousId: 'ANONYMOUS_ID',
        outcomeName: 'OUTCOME_NAME',
        errored: false,
        success: true
      }, null, 2);
    }
  }
};
</script>
