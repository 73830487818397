<template>
  <section class="card is-expandable experience">
    <header
      class="card-header"
      @click="isExpanded = !isExpanded"
    >
      <p class="card-header-title is-relative">
        <span class="card-header-box">
          <span data-cy-test="experience-number">
            {{ experience.experienceNumber }}
          </span>
        </span>

        <span data-cy-test="experience-name">
          {{ (experience.name) ? experience.name : `Experience #${experience.experienceNumber}` }}
        </span>
      </p>

      <!-- Experience Tags -->
      <span
        v-if="experience.isControl"
        class="card-header-icon"
      >
        <span
          class="tag is-light"
        >
          <span class="icon">
            <i class="fa fa-star"></i>
          </span>
          <span data-cy-test="control-icon">Control</span>
        </span>
      </span>

      <!-- Win Probability -->
      <span
        v-if="winProbability"
        class="card-header-icon"
      >
        <span v-if="isBestOption" class="tag is-winning-icon"><span class="icon"><i class="fa fa-trophy"></i></span></span>
        <div class="tags has-addons">
          <span class="tag is-option">Win Probability</span>
          <span class="tag">{{ Math.round(winProbability * 100) }}%</span>
        </div>
      </span>

      <div class="card-header-icon">
        <span
          class="icon"
          :class="{ 'is-rotated': isExpanded }"
          aria-label="Expand Experience"
        >
          <i
            class="fa fa-angle-down"
            aria-hidden="true"
          ></i>
        </span>
      </div>
    </header><!-- /.card-header -->

    <section v-show="isExpanded" class="card-content experience-metrics">
      <h6 class="title is-6">Experience Details</h6>
      <div class="level">
        <div class="level-item has-text-centered">
          <div>
            <span class="heading">TOTAL REWARDS</span>
            <span class="title">{{ (typeof rewards?.value === 'number') ? rewards.value : 0 }}</span>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <span class="heading">TOTAL TIMES SERVED</span>
            <span class="title">{{ totalTimesServed }}</span>
          </div>
        </div>
      </div>

      <h6 class="title is-6">Reward ID Details</h6>
      <b-table
        :columns="[
          {
            field: 'rewardId',
            label: 'Reward ID'
          },
          {
            field: 'value',
            label: 'Total For Experience',
            numeric: true
          }
        ]"
        :data="experienceRewards"
      >
        <template slot="empty">
          <em>No Reward IDs have been reported for this experience yet</em>
        </template>
      </b-table>
    </section>
  </section>

</template>

<script>
export default {
  name: 'TestExperienceMetrics',
  props: {
    experience: {
      type: Object,
      required: true
    },
    rewards: {
      type: Object,
      default() {
        return {};
      }
    },
    optionRewards: {
      type: Object,
      default() {
        return {};
      }
    },
    winProbability: {
      type: Number,
      required: false
    },
    isBestOption: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isExpanded: false
    };
  },
  computed: {
    totalTimesServed() {
      if (!this.rewards) return 0;

      return (
        (this.rewards.success || 0)
        + (this.rewards.failure || 0)
        + (this.rewards.inferredFailure || 0)
      );
    },
    experienceRewards() {
      if (!this.optionRewards || Object.keys(this.optionRewards).length === 0) return [];

      return Object.entries(this.optionRewards).map(([rewardId, value]) => ({ rewardId, value })).sort(function (rvA, rvB) {
        if (rvA.value > rvB.value) return -1;
        if (rvA.value < rvB.value) return 1;
        return 0;
      });
    }
  }
};
</script>
