<template>
  <div class="experiences">
    <div class="main-view-header">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h4 class="title is-4" data-cy-test="title">Experiences</h4>
          </div>
        </div>

        <div class="level-right">
          <div
            v-if="isInsightsAvailable"
            class="level-item"
          >
            <b-field>
              <b-switch
                v-model="hasInsightsMetricsSelected"
                type="is-success"
              >
                <b-tooltip
                  type="is-dark"
                  position="is-bottom"
                  data-cy-test="insights-radio-button"
                  :label="`Click to ${hasInsightsMetricsSelected ? 'hide' : 'show' } Insights data`"
                >
                  <span v-if="isLoadingInsights">Loading...</span>
                  <span v-else>{{ hasInsightsMetricsSelected ? 'Hide' : 'Show' }} Insights</span>
                </b-tooltip>
              </b-switch>
            </b-field>
          </div>

          <div
            v-if="isInsightsAvailable"
            class="level-item"
          >
            <transition
              name="custom-classes-transition"
              enter-active-class="animated zoomIn faster"
            >
              <LookbackRangePicker
                v-if="hasInsightsMetricsSelected"
                :options="['1d', '7d', '30d']"
                :has-refresh="false"
                :formatter="false"
                :is-loading="isLoadingInsights"
                @selection="val => lookbackRange = val"
              />
            </transition>
          </div>

          <div class="level-item">
            <b-field>
              <p class="control">
                <b-dropdown>
                  <button
                    slot="trigger"
                    class="button"
                    data-cy-test="search-dropdown-button"
                  >
                    <span>{{ filterBy ? filterBy.name : 'Filters' }}</span>
                    <span class="icon"><i class="fa fa-caret-down"></i></span>
                  </button>

                  <b-dropdown-item
                    v-for="(option, optIndex) in filterOptions"
                    :key="optIndex"
                    :data-cy-test="option.name"
                    @click="filterBy = option"
                  >{{ option.name }}</b-dropdown-item>
                </b-dropdown>
              </p>

              <b-input
                v-model="filterQuery"
                type="search"
                placeholder="Search..."
                data-cy-test="search-input"
              ></b-input>
            </b-field>
          </div>

          <div class="level-item">
            <button
              class="button is-info"
              :disabled="!userCanEdit"
              data-cy-test="create-experience-button"
              @click.stop="handleCreateExperience"
            >
              <span class="icon">
                <i class="fa fa-plus"></i>
              </span>
              <span>Create Experience</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="main-view-sub-header">
      <div class="tabs is-boxed">
        <ul>
          <li
            v-for="(tab, index) in ['Active', 'Inactive', 'Archived']"
            :id="`tab-experiences-item-${tab.toLowerCase()}`"
            :key="index"
            :data-cy-test="`tab-${tab.toLowerCase()}`"
            :class="{ 'is-active': tabSelection === tab }"
            @click="(evt) => handleTabSelection(evt, tab)"
          >
            <a :title="getTabTip(tab)">{{ tab }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="main-view-content is-white">

      <div
        v-if="filtered.length > 0"
        class="level ml-4"
      >
        <div class="level-left">
          <div class="level-item">
            <b-checkbox
              v-model="masterCheckboxModel"
              type="is-info"
              :indeterminate="isMasterCheckboxIndeterminate"
            />
          </div>
          <div class="level-item">
            <b-button
              icon-left="download"
              type="is-info"
              :disabled="!canDownload"
              :loading="isDownloadingCsv"
              data-cy-test="download-csv-button"
              @click="handleCsvDownload"
            >
              Download CSV
            </b-button>
          </div>
          <div
            v-if="tabSelection === 'Active' || tabSelection === 'Inactive'"
            class="level-item"
          >
            <b-button
              icon-left="archive"
              type="is-info"
              :disabled="!canArchive"
              :loading="isArchiving"
              data-cy-test="archive-selected-button"
              @click="handleBulkArchive"
            >
              Archive Selected
            </b-button>
          </div>
        </div>
      </div>

      <b-table
        :data="filtered"
        :loading="isLoading"
        :paginated="true"
        :pagination-simple="true"
        :current-page.sync="currentPage"
        :per-page="pageSize"
        pagination-size="is-hidden"
        sort-icon="caret-up"
        hoverable
      >
        <b-table-column
          v-slot="props"
          width="50"
        >
          <b-checkbox
            :value="selectedIds.includes(props.row.experienceId)"
            type="is-info"
            data-cy-test="select-checkbox"
            @input="handleExperienceChecked(props.row.experienceId)"
          />
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="name"
          sortable
          label="Experience Name"
        >
          <router-link :to="`/experiences/${props.row.experienceId}`" data-cy-test="experience-name-in-table">
            {{ props.row.name || `Experience #${props.row.experienceNumber}` }}
          </router-link>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="experienceNumber"
          sortable
          label="Experience Number"
        >
          {{ props.row.experienceNumber }}
        </b-table-column>

        <b-table-column
          v-if="hasInsightsMetricsSelected"
          v-slot="props"
          field="insights"
          sortable
          label="Insights"
        >
          {{ props.row.insights }}%
        </b-table-column>

        <b-table-column
          v-slot="props"
          :visible="tabSelection === 'Archived'"
          field="archivedBy"
          sortable
          label="Archived By"
        >
          {{ props.row.archivedBy }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          :visible="tabSelection === 'Archived'"
          field="archivedAt"
          sortable
          label="Archived At"
        >
          {{ props.row.archivedAt | filterArchiveDateTime }}
        </b-table-column>

        <template
          v-if="!isLoading"
          slot="empty"
        >
          <article class="message is-warning">
            <div class="message-body" data-cy-test="message-body">
              No {{ tabSelection.toLowerCase() }} experiences were found for this site.
            </div>
          </article>
        </template>
      </b-table>
    </div><!-- /.main-view-content -->

    <footer class="main-view-footer">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <button
              class="button"
              :disabled="currentPage === 1"
              @click.stop="getPage(-1)"
            >Previous</button>
          </div>
          <div class="level-item">
            <button
              class="button"
              :disabled="(pageSize * currentPage) >= filtered.length"
              @click.stop="getPage(1)"
            >Next</button>
          </div>
        </div>

        <div class="level-right">
          <div
            v-if="filtered.length"
            class="level-item"
          >
            <strong>{{ experienceRangeText }} of {{ filtered.length }} Experiences</strong>
          </div>
        </div>
      </div>
    </footer><!-- /.main-view-footer -->

  </div>
</template>

<script>
import LookbackRangePicker from '@/components/Insights/LookbackRangePicker';
import { bus } from '@/main';
import { downloadCsv, hasLength, hasProp } from '@/modules/utilities';
import { sync } from 'vuex-pathify';

export default {
  name: 'Experiences',
  components: {
    LookbackRangePicker
  },
  data() {
    return {
      hasInsightsMetricsSelected: false,
      tabSelection: 'Active',
      filterBy: { name: 'Experience Name', val: 'name' },
      filterOptions: [
        { name: 'Experience Name', val: 'name' },
        { name: 'Experience Number', val: 'experienceNumber' },
        { name: 'Experience ID', val: 'experienceId' }
      ],
      filterQuery: '',
      currentPage: 1,
      pageSize: 20,
      isDownloadingCsv: false,
      isArchiving: false,
      selectedIds: [],
      csvAttributes: [
        'siteId',
        'experienceId',
        'assetPlacements',
        'archived',
        'createdAt',
        'experienceNumber',
        'hash',
        'name',
        'tags',
        'updatedAt',
        'archivedAt',
        'archivedBy'
      ]
    };
  },
  computed: {
    experiences: sync('experiences/list'),
    ...sync([
      'experiences/isLoading',
      'experiences/lookbackRange',
      'experiences/insights',
      'experiences/isLoadingInsights'
    ]),
    allTests: sync('tests/list'),
    experienceToTests: sync('experiences/experienceToTests'),
    allHitData() {
      return this.$store.getters['experiences/allHitData'];
    },
    userCanEdit() {
      return this.$store.getters.userCanEdit;
    },
    isInsightsAvailable() {
      return this.$store.getters.isInsightsAvailable;
    },
    filtered() {
      let result = this.experiences ? this.experiences : [];
      if (hasLength(result)) {
        result = this.filterExperiences(result);
      }
      if (this.hasInsightsMetricsSelected) {
        result.forEach((exp) => {
          exp.insights = this.allHitData[exp.experienceId] ? this.allHitData[exp.experienceId].percentage : 0;
        });
      }
      return result;
    },
    experienceRangeText() {
      return `${(this.pageSize * this.currentPage) - (this.pageSize - 1)} / `
      + `${this.pageSize * this.currentPage > this.filtered.length ? this.filtered.length : this.pageSize * this.currentPage}`;
    },
    // Get the site id
    siteId() {
      return this.$store.state.siteId;
    },
    canDownload() {
      return this.selectedIds.length > 0 && !this.isDownloadingCsv && !this.isArchiving;
    },
    canArchive() {
      return this.userCanEdit && this.selectedIds.length > 0 && !this.isDownloadingCsv && !this.isArchiving;
    },
    masterCheckboxModel: {
      get() {
        return this.selectedIds.length === this.filtered.length;
      },
      set(val) {
        if (val) {
          this.selectedIds = this.filtered.map(experience => experience.experienceId);
        } else {
          this.selectedIds = [];
        }
      }
    },
    isMasterCheckboxIndeterminate() {
      return this.selectedIds.length > 0 && this.selectedIds.length !== this.filtered.length;
    }
  },
  watch: {
    hasInsightsMetricsSelected(bool) {
      if (bool) {
        this.$store.dispatch('experiences/getInsights');
      }
    },
    lookbackRange() {
      if (this.isInsightsAvailable) {
        this.$store.dispatch('experiences/getInsights');
      }
    }
  },
  created() {
    this.getDependencies();
    this.registerEventListeners();
  },
  beforeDestroy() {
    this.deregisterEventListeners();
  },
  methods: {
    handleCreateExperience() {
      this.$root.establishSiteLock().then(() => {
        localStorage.setItem('siteDomain', `https://${this.$store.getters.siteDomain}`);
        this.$router.push(`/experiences/new`);
      });
    },
    getPage(int) {
      this.currentPage += int;
    },
    /**
     * Emit element click event
     * @param {Object} e - element click event
     */
    elementClick(e) {
      bus.$emit('elem-click', e);
    },
    async getDependencies() {
      this.selectedIds = [];
      await this.$store.dispatch('experiences/getList', []);
      await this.$store.dispatch('placements/getAllPlacementsAndAssets');
      await this.$store.dispatch('tests/getAllTests');
    },
    /**
     * Get tooltip message when hovering over the tab options
     * @param {String} tab - 'All, Active, Inactive, Archived
     */
    getTabTip(tab) {
      switch (tab) {
        case 'Active':
          return 'Experiences enrolled in an active test or champion.';
        case 'Inactive':
          return 'Experiences enrolled in an inactive test.';
        case 'Archived':
          return 'Experiences that have been archived and are not eligible to be in a test unless unarchived.';
        default:
          return '';
      }
    },
    filterExperiences(experiences) {
      let exps = experiences;

      // Handle Tab Filtering
      switch (this.tabSelection) {
        case 'Active':
          exps = exps.filter(exp => exp.isActive || exp.isChampion);
          break;
        case 'Inactive':
          exps = exps.filter(exp => !exp.isActive && !exp.archived && !exp.isChampion);
          break;
        case 'Archived':
          exps = exps.filter(exp => exp.archived);
          break;
        default:
          exps = exps.filter(exp => !exp.archived);
      }

      // Filter by query
      if (!this.filterBy || !hasLength(this.filterQuery)) {
        return exps;
      }

      return exps.filter((exp) => {
        if (this.filterBy.val === 'experienceNumber') {
          return exp.experienceNumber === parseInt(this.filterQuery, 10);
        }
        return (
          hasProp(exp, this.filterBy.val)
          && exp[this.filterBy.val].toLowerCase().indexOf(this.filterQuery.toLowerCase()) > -1
        );
      });
    },
    handleTabSelection(clickEvent, tab) {
      this.selectedIds = [];
      this.elementClick(clickEvent);
      this.tabSelection = tab;
    },
    registerEventListeners() {
      bus.$on('site-changed', this.getDependencies);
    },
    deregisterEventListeners() {
      bus.$off('site-changed', this.getDependencies);
    },
    handleExperienceChecked(id) {
      if (this.selectedIds.includes(id)) {
        this.selectedIds = this.selectedIds.filter(selectedId => selectedId !== id);
      } else {
        this.selectedIds.push(id);
      }
    },
    async handleCsvDownload() {
      if (this.selectedIds.length === 0) {
        return;
      }
      this.isDownloadingCsv = true;

      const res = await this.$axios.get(`/sites/${this.siteId}/experiences`, {
        params: { status: 'all', attributes: this.csvAttributes }
      });

      const csvItems = res.data.experiences.filter(exp =>
        this.selectedIds.includes(exp.experienceId));

      const filename =
        this.$store.state.activeAccountSites.find(s => s.siteId === this.siteId).name.replace(/\./g, '-')
        + '_experiences_'
        + new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-')
        + '.csv';

      downloadCsv(csvItems, this.csvAttributes, filename);

      this.isDownloadingCsv = false;
    },
    async handleBulkArchive() {
      if (this.selectedIds.length === 0) {
        return;
      }
      this.isArchiving = true;
      // Dispatch with empty array of attributes to tell
      // server to respond with all attributes, isActive, and isChampion props
      await this.$store.dispatch('experiences/getList', []);
      const experiences = this.experiences.filter(exp =>
        this.selectedIds.includes(exp.experienceId));
      // Make sure all experiences are archivable
      this.$store.commit('experiences/updateExperienceTestMap', this.allTests);
      for (const exp of experiences) {
        if (exp.isActive || exp.isChampion) {
          const identity = exp.name || `Experience #${exp.experienceNumber}`;
          const error = exp.isChampion ? 'a champion' : 'an active';
          this.$buefy.toast.open({
            message: `Archive failed. ${identity} is ${error} experience.`,
            type: 'is-danger',
            position: 'is-bottom',
            duration: 2500
          });
          this.isArchiving = false;
          return;
        }
        if (this.experienceToTests[exp.experienceId]) {
          for (const testId of this.experienceToTests[exp.experienceId]) {
            if (this.allTests.find(test => test.testId === testId).ended === 0) {
              const identity = exp.name || `Experience #${exp.experienceNumber}`;
              this.$buefy.toast.open({
                message: `Archive failed. ${identity} is part of at least one test.`,
                type: 'is-danger',
                position: 'is-bottom',
                duration: 2500
              });
              this.isArchiving = false;
              return;
            }
          }
        }
      }
      // At this point, we know that all experiences can be archived
      this.$root.establishSiteLock().then(() => {
        const updatedExperiences = experiences.map((exp) => {
          const obj = {
            ...exp,
            archived: 1
          };
          delete obj.assetPlacements;
          return obj;
        });
        let message;
        if (updatedExperiences.length === 1) {
          const identity = updatedExperiences[0].name || `Experience #${updatedExperiences[0].experienceNumber}`;
          message = `Are you sure you want to archive ${identity}?`;
        } else {
          message = `Are you sure you want to archive all ${updatedExperiences.length} experiences?`;
        }
        this.archivePrompt = this.$buefy.dialog.confirm({
          title: `Archiving Experiences`,
          message,
          confirmText: 'Archive',
          type: 'is-warning',
          hasIcon: true,
          onConfirm: async () => {
            await this.$store.dispatch('experiences/updateExperiences', updatedExperiences);
            this.selectedIds = [];
            this.isArchiving = false;
          },
          onCancel: () => {
            this.isArchiving = false;
          }
        });
      });
    }
  }
};
</script>
