<template>
  <div class="card-footer">
    <div class="level">
      <div class="level-left"></div> <!-- Needs to be here to force level-right to do its job -->
      <div class="level-right">
        <div class="level-item">
          <div class="level-item">
            <b-button
              data-cy-test="cancel-button"
              @click="$emit('cancel')"
            >
              Cancel
            </b-button>
          </div>
        </div>
        <div class="level-item">
          <b-button
            :disabled="!allStepsValid"
            type="is-success"
            :class="{ 'is-loading': isSubmittingTest }"
            data-cy-test="create-test-button"
            @click="handleTestFormSubmit"
          >
            {{ isEditing ? 'Update Test' : 'Create Test' }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';

export default {
  name: 'TestFormFooter',
  props: {
    commonTest: Object,
    mvt: Object,
    mabExp: Object,
    clickOptimization: Object,
    experiences: Array,
    rewards: Array,
    stepsValid: Object,
    trafficFlows: Object,
    isEditing: Boolean
  },
  data() {
    return {
      isSubmittingTest: false
    };
  },
  computed: {
    allExperiences: sync('experiences/list'),
    siteId() {
      return this.$store.state.siteId;
    },
    accountId() {
      return this.$store.state.activeAccount;
    },
    testType() {
      return this.commonTest.strategy.type;
    },
    allStepsValid() {
      for (const step in this.stepsValid.common) {
        if (!this.stepsValid.common[step]) {
          return false;
        }
      }

      if (this.stepsValid[this.testType]) {
        for (const step in this.stepsValid[this.testType]) {
          if (!this.stepsValid[this.testType][step]) {
            return false;
          }
        }
      }

      return true;
    }
  },
  methods: {
    async handleTestFormSubmit() {
      this.isSubmittingTest = true;

      // Construct test from common properties
      const test = { ...this.commonTest, trafficFlowId: this.trafficFlows.trafficFlowId };

      let newTest;
      if (this.testType === 'mvt') {
        // Add experiences to MVT test
        test.strategy.mvt = {
          ...this.mvt,
          experiences: this.experiences.map(exp => ({
            experienceId: exp.experienceId,
            isControl: exp.isControl,
            weight: exp.weight
          }))
        };
        if (!this.isEditing) {
          newTest = await this.$store.dispatch('tests/createMVT', test);
        } else {
          newTest = await this.$store.dispatch('tests/updateMVT', test);
        }
      } else if (this.testType === 'mabExp') {
        // Add experiences to mabExp test and format payload
        test.strategy.mabExp = { ...this.mabExp };
        test.strategy.mabExp.dapi.options = this.experiences.map(exp => exp.experienceId);
        const payload = this.createMabExpPayload(test);

        if (!this.isEditing) {
          newTest = await this.$store.dispatch('tests/createMabExp', payload);
        } else {
          newTest = await this.$store.dispatch('tests/updateMabExp', payload);
        }
      } else if (this.testType === 'clickOptimization') {
        test.strategy.clickOptimization = { ...this.clickOptimization };

        if (!test.strategy.clickOptimization.holdout?.option) {
          delete test.strategy.clickOptimization.holdout;
        }

        // add experience IDs as test options
        test.strategy.clickOptimization.options = this.experiences.map(e => e.experienceId);

        // transform rewards from array to object on external object
        const external = {
          dapi: {
            agent: {}
          }
        };
        external.dapi.agent.rewards = this.rewards.reduce(function (obj, rewardObj) {
          obj[rewardObj.rewardId] = rewardObj.value;

          return obj;
        }, {});

        if (!this.isEditing) {
          newTest = await this.$store.dispatch('tests/createClickOptimization', { test, external });
        } else {
          newTest = await this.$store.dispatch('tests/updateClickOptimization', { test, external });
        }
      }

      if (!this.isEditing) {
        this.$emit('test-created', newTest);
      } else {
        this.$emit('test-updated', newTest);
      }

      this.isSubmittingTest = false;
    },
    createMabExpPayload(test) {
      const payload = {
        test: {
          ...{
            ...test,
            strategy: {
              ...test.strategy,
              mabExp: {
                ...test.strategy.mabExp,
                dapi: { ...test.strategy.mabExp.dapi }
              }
            }
          },
          siteId: this.siteId
        }
      };
      return payload;
    },
    getTestOptions(test) {
      const testOptions = [];
      const experienceOptions = [
        ...this.allExperiences,
        // Add the default experience
        { name: 'Default', experienceId: '0', experienceNumber: 0 }
      ];

      if (test.strategy.type === 'mabExp') {
        test.strategy.mabExp.dapi.options.forEach((option) => {
          const matchedExperience = experienceOptions.find(expOpt => option === expOpt.experienceId);
          testOptions.push({
            optionName: matchedExperience.name
              ? matchedExperience.name
              : matchedExperience.experienceNumber.toString(),
            optionId: matchedExperience.experienceId
          });
        });
      } else if (test.strategy.type === 'mvt') {
        // Add default experience if sum of exp weights is less than 100%
        const totalExpWeightPct = test.strategy.mvt.experiences.reduce((sum, exp) => sum + exp.weight, 0);
        if (totalExpWeightPct < 100) {
          testOptions.push({
            optionName: 'Default',
            optionId: '0'
          });
        }

        test.strategy.mvt.experiences.forEach((exp) => {
          const matchedExperience = experienceOptions.find(expOpt => exp.experienceId === expOpt.experienceId);
          testOptions.push({
            optionName: matchedExperience.name
              ? matchedExperience.name
              : matchedExperience.experienceNumber.toString(),
            optionId: matchedExperience.experienceId
          });
        });
      }

      return testOptions;
    }
  }
};
</script>
