/**
 * These objects contain payloads sent in POST routes to the server
 * which then make subsequent requests to elasticsearch. Having these live
 * on the client side allows for dynamic routing in server/routes/insights.js
 */
const channel = {
  isDirectEntry: { match: { 'channel.isDirectEntry': 'true' } },
  isPaid: { match: { 'channel.isPaid': 'true' } },
  isNatural: { match: { 'channel.isNatural': 'true' } },
  isReferrer: { match: { 'channel.isReferrer': 'true' } }
};

const device = {
  isDesktop: { match: { 'userAgent.isDesktop': 'true' } },
  isMobile: { match: { 'userAgent.isMobile': 'true' } },
  isTablet: { match: { 'userAgent.isTablet': 'true' } }
};

const browser = {
  terms: {
    field: 'userAgent.browserName.keyword',
    order: {
      _count: 'desc'
    }
  }
};

const browserVersion = {
  terms: {
    field: 'userAgent.browserVersion.keyword',
    size: 5,
    order: {
      _count: 'desc'
    }
  }
};

const audiences = {
  terms: {
    field: 'audienceName.keyword',
    order: {
      _count: 'desc'
    }
  }
};

const experiences = {
  terms: {
    field: 'experienceId.keyword',
    order: {
      _count: 'desc'
    }
  }
};

const tests = {
  terms: {
    field: 'testName.keyword',
    order: {
      _count: 'desc'
    }
  }
};

export default {
  channel, device, browser, browserVersion, audiences, experiences, tests
};
