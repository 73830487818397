// Globally register all base components for convenience, because they will be used very frequently.
// https://vuejs.org/v2/guide/components-registration.html

export default {
  install(Vue) {

    const requireComponent = require.context(
      '../components/Base', // The relative path of the components folder
      true, // Whether or not to look in subfolders
      /[A-Z]\w+\.(vue|js)$/ // The regular expression used to match base component filenames
    );

    requireComponent.keys().forEach(function (fileName) {

      // Get component config.
      const componentConfig = requireComponent(fileName);

      // Get name of the component:
      // /Base/Button/Save.vue = BaseButtonSave
      const componentName = fileName
        .replace(/^\./, '')
        .replace(/\.\w+$/, '')
        .split('/')
        .join('');

      // Register component globally.
      Vue.component(
        `Base${componentName}`,
        componentConfig.default || componentConfig
      );
    });
  }
};
