<template>
  <div class="icon">
    <svg
      :style="`height: ${height}px; width: ${width}px; ${flipX ? 'transform: rotate(-180deg);' : ''}`"
      viewBox="0 0 24 24"
    >
      <path
        v-if="typeof iconData === 'string'"
        :fill="color"
        :d="iconData"
      />

      <path
        v-if="typeof iconData === 'object' && iconData.primary && iconData.secondary /* eslint-disable-line vue/no-use-v-if-with-v-for */"
        v-for="(value, key) in iconData"
        :key="`icon-${icon}-${key}`"
        :fill="color"
        :class="`fa-${key}`"
        :d="value"
      />
    </svg>
  </div>
</template>

<script>

import iconPathData from './icon-data.json';

export default {
  name: 'v-icon-brain',
  props: {
    icon: {
      type: String,
      default: 'image',
      required: true
    },
    color: {
      type: String,
      default: '#4a4a4a'
    },
    height: {
      type: Number,
      default: 14
    },
    width: {
      type: Number,
      default: 14
    },
    flipX: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconData: null
    };
  },
  created() {
    this.iconData = iconPathData[this.icon];
  }
};
</script>

<style lang="scss" scoped>
  // include fa-secondary control for duotone icons
  .fa-secondary {
    opacity: 0.4;
  }
</style>
