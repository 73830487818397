import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import { bus } from '@/main';

// Preamp
import AccountHelp from '@/views/AccountHelp';
import Accounts from '@/views/Accounts';
import AddOn from '@/views/AddOn';
import AddOns from '@/views/AddOns';
import Asset from '@/views/Asset';
import Assets from '@/views/Assets';
import AudienceRuleLists from '@/views/AudienceRuleLists';
import Experience from '@/views/Experience';
import Experiences from '@/views/Experiences';
import ExperienceBuilder from '@/views/ExperienceBuilder';
import History from '@/views/History';
import Insights from '@/views/Insights';
import InsightsTesting from '@/views/InsightsTesting';
import InsightsUsage from '@/views/InsightsUsage';
import Integrations from '@/views/Integrations';
import Placement from '@/views/Placement';
import Placements from '@/views/Placements';
import Preamp from '@/views/Preamp';
import Regions from '@/views/Regions';
import Tests from '@/views/Tests';
import Test from '@/views/Test';
import TrafficFlows from '@/views/TrafficFlows';
import UserAudit from '@/views/UserAudit';
import Users from '@/views/Users';

// Set up Routing
Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      meta: { readOnly: true },
      name: 'Preamp',
      component: Preamp,
      children: [
        {
          path: 'insights',
          name: 'Insights',
          component: Insights
        },
        {
          path: 'insights/testing',
          name: 'Testing Insights',
          component: InsightsTesting
        },
        {
          path: 'insights/usage',
          name: 'Usage Insights',
          component: InsightsUsage,
          meta: { isSuper: true }
        },
        {
          path: 'traffic-flows',
          name: 'Traffic Flows',
          component: TrafficFlows
        },
        {
          path: 'audience-rule-lists',
          name: 'Audience Rule Lists',
          component: AudienceRuleLists
        },
        {
          path: 'tests/',
          name: 'Tests',
          component: Tests
        },
        {
          path: 'tests/:testId',
          name: 'Test',
          component: Test
        },
        {
          path: 'placements',
          name: 'Placements',
          component: Placements
        },
        {
          path: 'placements/:placementId',
          name: 'Placement',
          component: Placement,
          meta: { readOnly: true }
        },
        {
          path: 'experiences',
          name: 'Experiences',
          component: Experiences
        },
        {
          path: 'experiences/new',
          name: 'ExperienceBuilder',
          component: ExperienceBuilder
        },
        {
          path: 'experiences/:experienceId',
          name: 'Experience',
          component: Experience
        },
        {
          path: 'experiences/:experienceId/edit',
          name: 'ExperienceBuilderEdit',
          component: ExperienceBuilder
        },
        {
          path: 'experiences/:experienceId/duplicate',
          name: 'ExperienceBuilderDuplicate',
          component: ExperienceBuilder
        },
        {
          path: 'assets',
          name: 'Assets',
          component: Assets
        },
        {
          path: 'assets/:assetId',
          name: 'Asset',
          component: Asset
        },
        {
          path: 'add-ons',
          name: 'AddOns',
          component: AddOns
        },
        {
          path: 'add-ons/:addOnId?',
          name: 'AddOn',
          component: AddOn
        },
        {
          path: 'users',
          name: 'Users',
          component: Users,
          meta: { readOnly: true }
        },
        {
          path: 'user-audit',
          name: 'User Audit',
          component: UserAudit,
          meta: { readOnly: true }
        },
        {
          path: 'history',
          name: 'History',
          component: History,
          meta: { readOnly: true }
        },
        {
          path: 'accounts',
          name: 'Accounts',
          component: Accounts
        },
        {
          path: 'account-help',
          name: 'Account Help',
          component: AccountHelp
        },
        {
          path: 'regions',
          name: 'Regions',
          component: Regions
        },
        {
          path: 'integrations',
          name: 'Integrations',
          component: Integrations
        }
      ]
    }
  ]
});

export const experienceBuilderRoutes = [
  'ExperienceBuilder',
  'ExperienceBuilderEdit',
  'ExperienceBuilderDuplicate'
];

router.afterEach(function () {
  Vue.nextTick(function () {
    if (window.tagular) {
      window.tagular('pageView');
    }
  });
});

/**
 * Check unsaved changes before advancing
 * @param {Object} to - next location
 * @param {Object} from - prev location
 * @param {Function} next - cb
 */
router.beforeEach(function (to, from, next) {
  // Exclude any paths that are read only
  if (from.meta.readOnly) return next();

  // Prevent navigating to super routes as non super
  if (to.meta.isSuper && !store.getters.isSuperUser) return next('/');

  // Remove global event listeners that previous view may have set
  bus.$off(['site-lock-modal-exit', 'site-lock-lost-user', 'site-changed']);

  // Check if route requires site lock
  if (to.meta.requiresSiteLock && window.vm) {
    window.vm.$root.establishSiteLock().then(() => {
      next();
    })
      .catch(() => {
        next(from.path);
      });
  }

  // Check if any edits have been made and trigger modal
  if (store.state.edits.length || store.state.hasUnsavedChanges) {
    window.vm.$buefy.dialog.confirm({
      title: 'Unsaved Changes',
      message: 'You currently have unsaved changes, are you sure you want to leave?',
      confirmText: 'Yes',
      type: 'is-warning',
      hasIcon: true,
      onConfirm() {
        store.commit('clearEditItems');
        // Reset flag for unsaved changes after navigating away
        store.commit('setHasUnsavedChanges', false);
        // Reset editing flag to release editing state on a test after navigating away
        store.set('editing@test', null);
        return next();
      },
      onCancel() {
        return next(false);
      }
    });
  } else {
    return next();
  }
});
export default router;
