<template>
  <div class="card-content">
    <h4 class="title is-4" data-cy-test="ff-update-title">
      Update Asset
    </h4>

    <article
      v-if="hasLiveExperiences"
      class="message is-warning top15"
    >
      <div class="message-body">
        <p>This asset is live in an experience or test. By saving these changes, you will be modifying live experiences.</p>
      </div>
    </article>

    <b-field
      label="Name"
      :type="nameError.isError ? 'is-danger' : ''"
      :message="nameError.msg"
    >
      <b-input
        v-model="editingAsset.name"
        expanded
        placeholder="Enter a value"
        @input="resetNameErr"
        @blur="handleBlurName"
      />
    </b-field>

    <hr />

    <div class="ff-variable-section">
      <FastForwardVariables
        :assetsDiff="assetsDiff"
        :preampVars="preampVars"
        :addedVars="addedVars"
        :carryOverVars="carryOverVars"
        :duplicateAssetVars="duplicateAssetVars"
        :baseAssetVars="baseAssetVars"
        :proposedVariables="proposedVariables"
        fileType="html"
      />
      <FastForwardVariables
        :assetsDiff="assetsDiff"
        :preampVars="preampVars"
        :addedVars="addedVars"
        :carryOverVars="carryOverVars"
        :duplicateAssetVars="duplicateAssetVars"
        :baseAssetVars="baseAssetVars"
        :proposedVariables="proposedVariables"
        fileType="metadata"
      />
    </div>

    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <b-button
            :disabled="isMissingValues"
            data-cy-test="ff-update-button-preview"
            type="is-info"
            @click="$emit('forward')"
          >
            Preview Update
          </b-button>
        </div>
        <div class="level-item">
          <b-button
            type="is-text"
            data-cy-test="ff-update-button-cancel"
            @click="$emit('cancel')"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { extractValues } from '@redventures/preamp-variables';

import FastForwardVariables from './FastForwardVariables.vue';

export default {
  name: 'UpdateStep',
  components: {
    FastForwardVariables
},
  props: {
    baseAsset: {
      type: Object,
      required: true
    },
    duplicateAsset: {
      type: Object,
      required: true
    },
    allAssets: {
      type: Array,
      required: true
    },
    assetsDiff: {
      type: Object,
      required: true
    },
    preampVars: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hasLiveExperiences: false,
      nameError: {
        isError: false,
        msg: ''
      },
      editingAsset: {},
      addedVars: {
        variables: {},
        conditionals: {}
      },
      carryOverVars: {
        variables: {},
        conditionals: {}
      }
    };
  },
  computed: {
    isMissingValues() {
      this.handleBlurName();
      return Object.values(this.proposedVariables.variables).some(val => !val || !val.trim()) || this.nameError.isError;
    },
    proposedVariables() {
      // these are the values that will be used when the asset is updated
      const proposed = {
        variables: {},
        conditionals: {}
      };
      for (const [variableType, vars] of Object.entries(this.carryOverVars) ) {
        for (const variables of Object.values(vars)) {
          proposed[variableType] = {
            ...proposed[variableType],
            ...variables
          };
        }
      }

      for (const [variableType, vars] of Object.entries(this.addedVars) ) {
        for (const variables of Object.values(vars)) {
          proposed[variableType] = {
            ...proposed[variableType],
            ...variables
          };
        }
      }
      return proposed;
    },
    duplicateAssetVars() {
      return extractValues(this.duplicateAsset);
    },
    baseAssetVars() {
      return extractValues(this.baseAsset);
    },
    duplicateGlobalVariables() {
      // variables in the duplicate asset that are not part of an optional section
      let allOptionalSectionVariables = [];
      for (const fileType in this.duplicateAssetVars.conditionals) {
        for (const section in this.duplicateAssetVars.conditionals[fileType]) {
          allOptionalSectionVariables = [ ...allOptionalSectionVariables, ...this.duplicateAssetVars.conditionals[fileType][section].variablesUsed ];
        }
      }

      const uniqueSectionVariables = [ ...new Set(allOptionalSectionVariables) ];

      let duplicateGlobalVariables = [];
      for (const fileType in this.duplicateAssetVars.variables) {
        duplicateGlobalVariables[fileType] = Object.keys(this.duplicateAssetVars.variables[fileType]).filter((varName) => {
          return !uniqueSectionVariables.includes(varName);
        });
      }
      return duplicateGlobalVariables;
    }
  },
  watch: {
    proposedVariables(val) {
      this.$emit('change', val);
    },
    'editingAsset.name': function (name) {
      this.$emit('nameUpdate', name);
    }
  },
  created() {
    this.initEditingAsset();
  },
  methods: {
    initEditingAsset() {
      this.editingAsset = {...this.duplicateAsset};
      // Initialize carry over, and added vars objects
      if (this.assetsDiff.variables) {
        for (const fileType in this.assetsDiff.variables.added) {
          this.assetsDiff.variables.added[fileType].forEach((varName) => {
            this.addedVars.variables = {
              ...this.addedVars.variables,
              [fileType]: {
                ...this.addedVars.variables[fileType],
                [varName]: this.baseAssetVars.variables[fileType][varName]
              }
            };
          });
        }
      }

      if (this.assetsDiff.conditionals) {
        for (const fileType in this.assetsDiff.conditionals.added) {
          this.assetsDiff.conditionals.added[fileType].forEach((sectionName) => {
            this.addedVars.conditionals = {
              ...this.addedVars.conditionals,
              [fileType]: {
                ...this.addedVars.conditionals[fileType],
                [sectionName]: this.baseAssetVars.conditionals[fileType][sectionName]
              }
            };
          });
        }
      }

      for (const fileType in this.duplicateAssetVars.variables) {
        for (let varName in this.duplicateAssetVars.variables[fileType]) {
          if (this.baseAssetVars.variables && this.baseAssetVars.variables[fileType] !== undefined) {
            this.carryOverVars.variables = {
              ...this.carryOverVars.variables,
              [fileType]: {
                ...this.carryOverVars.variables[fileType],
                [varName]: this.duplicateAssetVars.variables[fileType][varName]
              }
            };
          }
        }
      }

      for (const fileType in this.duplicateAssetVars.conditionals) {
        for (let sectionName in this.duplicateAssetVars.conditionals[fileType]) {
          if (this.baseAssetVars.conditionals && this.baseAssetVars.conditionals[sectionName] !== undefined) {
            this.carryOverVars.conditionals = {
              ...this.carryOverVars.conditionals,
              [fileType]: {
                ...this.carryOverVars.conditionals[fileType],
                [sectionName]: this.duplicateAssetVars.conditionals[fileType][sectionName]
              }
            };
          }
        }
      }

    },
    handleBlurName() {
      if (!this.editingAsset.name) {
        this.nameError = {
          isError: true,
          msg: 'Name cannot be blank'
        };
        return;
      }
      if (this.editingAsset.name.length >= 128) {
          this.nameError = {
            isError: true,
            msg: 'Name must be fewer than 128 characters'
          };
        }
      const duplicateName = this.allAssets
        .find(a => (a.name !== this.duplicateAsset.name) && a.name.toLowerCase().trim() === this.editingAsset.name.toLowerCase().trim());
      if (duplicateName) {
        this.nameError = {
          isError: true,
          msg: `${duplicateName.name} already exists`
        };
      }
    },
    resetNameErr() {
      this.nameError = {
        isError: false,
        msg: ''
      };
    }
  }
};
</script>

<style lang="scss">
.variable-section {
  margin-bottom: 1em;
}

.removed-variables {
  border: 1px solid rgba(231, 76, 60, .50);
  border-radius: .25em;
  padding: .75em;
}

.added-variables {
  border: 1px solid rgba(48, 204, 113, .50);
  border-radius: .25em;
  padding: .75em;
}

.ff-variable-section {
  width: 100%;
  display: flex;
  column-gap: 16px;
  margin-bottom: 24px;

  &__column {
    width: 50%;
    flex: 1 1 100%;
  }
}
</style>
