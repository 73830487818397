<template>
  <b-field
    :type="validation.type"
    :message="validation.message"
  >
    <b-select
      v-model="rule.value"
      :loading="isLoading"
      :disabled="!isEditing"
      expanded
    >
      <!-- Have to do this in order to display a placeholder with rule.sync -->
      <option value="">Select a List</option>
      <option
        v-for="option in options"
        :key="option.listId"
        :value="option.listId"
      >
        {{ option.name }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
export default {
  name: 'ARLRules',
  props: {
    rule: {
      type: Object,
      required: true
    },
    isEditing: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      options: [],
      isLoading: false
    };
  },
  computed: {
    // Used to get the audience-rule-list to display.
    siteId() {
      return this.$store.state.siteId;
    },
    // Validate that there are options to choose and that one has been chosen.
    validation() {
      // Prevent FOC
      if (!this.isEditing || this.isLoading) return { type: '', message: '' };

      switch (true) {
        case this.options.length === 0:
          return { type: 'is-danger', message: 'No Rule Lists exist for this site.' };
        case this.rule.value === '':
          return { type: 'is-danger', message: 'This rule requires a list to be chosen.' };
        default:
          return { type: '', message: '' };
      }
    }
  },
  mounted() {
    this.getAudienceRuleLists();
  },
  methods: {
    // Load the audience rule list as options to select from.
    async getAudienceRuleLists() {
      this.isLoading = true;
      const res = await this.$axios.get(`/sites/${this.siteId}/audience-rule-lists`);
      this.options = res.data;
      this.isLoading = false;
    }
  }
};
</script>
