<template>
  <button
    class="integration-option"
    :class="{'integration-option--selected': isSelected}"
    @click="$emit('clickIntegrationType', integrationType)"
  >
    <img
      class="integration-option__logo"
      :src="getImgUrl()"
    />
    <h4 class="integration-option__title">
      {{ displayName }}
    </h4>
    <div class="integration-option__description">
      {{ description }}
    </div>
    <div class="integration-option__radio-btn">
      <b-icon
        :icon="isSelected ? 'dot-circle-o' : 'circle-o'"
      />
    </div>
  </button>
</template>

<script>
import { getIntegrationInstance } from '../../modules/integrations';

export default {
  name: 'IntegrationTypeOption',
  props: {
    isSelected: {
      required: true,
      type: Boolean
    },
    integrationType: {
      type: String,
      required: true
    }
  },
  computed: {
    integrationInstance() {
      return getIntegrationInstance(this.integrationType);
    },
    displayName() {
      return this.integrationInstance.displayName;
    },
    description() {
      return this.integrationInstance.description;
    },
    imageName() {
      return this.integrationInstance.imageName;
    }
  },
   methods: {
    getImgUrl() {
      return require.context('../../assets', false, /\.(png|jpg|jpeg|gif|svg)$/)(`./${this.imageName}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.integration-option {
  display: grid;
  grid-template:
  'l t r'
  'l d r'
  / 56px auto auto;
  width: 100%;
  text-align: left;
  padding: 16px 20px;
  background-color: #F6F7F9;
  cursor: pointer;
  border: 0;

  &:hover {
    background-color: #EDF0F3;

    &:not(.integration-option--selected) .integration-option__radio-btn {
      color: #016EB2;
    }
  }

  &--selected {
    background-color: #EDF0F3;

    & .integration-option__radio-btn {
      color: #016EB2;
    }
  }

   &:not(:last-of-type) {
    border-bottom: 1px solid darken(#F6F7F9, 10%);
  }

  &:first-of-type {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-of-type {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &__title {
       grid-area: t;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  &__description {
       grid-area: d;
    color: #5C6F84;
    font-size: 12px;
    line-height: 18px;
  }

  &__logo {
    grid-area: l;
    font-size: 36px;
    max-width: 40px;
    height: 36px;
    color: #B2BECC;
    margin-right: 16px;
  }

  &__radio-btn {
    grid-area: r;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    color: #5C6F84;
    font-size: 22px;
  }
}
</style>

