<template>
  <b-field grouped>
    <b-field v-if="hasRefresh">
      <b-tooltip
        type="is-dark"
        label="Refresh this graph"
        :position="tipPosition"
      >
        <button
          class="button"
          :class="{ 'is-loading': isLoading, [size]: true }"
          @click="$emit('refresh')"
        >
          <span class="icon"><i class="fa fa-refresh"></i></span>
        </button>
      </b-tooltip>
    </b-field>
    <b-field v-if="formatter">
      <div class="buttons has-addons">
        <button
          class="button"
          :class="{ 'is-info is-selected': !hasNumbersViewSelected }"
          @click="hasNumbersViewSelected = false"
        >
          <b-tooltip
            label="% of Total Decisions Created"
            type="is-dark"
            :position="tipPosition"
          >%</b-tooltip>
        </button>

        <button
          class="button"
          :class="{ 'is-info is-selected': hasNumbersViewSelected }"
          @click="hasNumbersViewSelected = true"
        >
          <b-tooltip
            label="Number of Decisions Created"
            type="is-dark"
            :position="tipPosition"
          >
            #
          </b-tooltip>
        </button>
      </div><!-- /.buttons -->
    </b-field>

    <b-field v-if="hasPicker">
      <b-tooltip
        type="is-dark"
        label="Lookback Range"
        data-cy-test="lookback-range"
        :position="tipPosition"
      >
        <b-select v-model="selected" :size="size">
          <option
            v-for="(range, index) in options"
            :key="index"
            :value="range"
          >{{ lookbackLabelMapping[range] }}</option>
        </b-select>
      </b-tooltip>
    </b-field>
  </b-field>
</template>

<script>
export default {
  name: 'LookbackRangePicker',
  props: {
    options: {
      type: Array,
      default() {
        return ['1h', '1d', '7d', '30d'];
      }
    },
    formatter: {
      type: Boolean,
      default: true
    },
    hasPicker: {
      type: Boolean,
      default: true
    },
    hasRefresh: {
      type: Boolean,
      default: true
    },
    tipPosition: {
      type: String,
      default: 'is-top'
    },
    initial: {
      type: String,
      default: '1d'
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: undefined,
      validator(value) {
        return [undefined, 'is-small', 'is-medium', 'is-large'].includes(value);
      }
    },
    lookbackLabelMapping: {
      type: Object,
      default() {
        // ['1h', '1d'] => { '1h': '1h', '1d': '1d' }
        return this.options.reduce(function (obj, lbOption) {
          obj[lbOption] = lbOption;
          return obj;
        }, {});
      }
    }
  },
  data() {
    return {
      hasNumbersViewSelected: false,
      selected: this.initial // default selection
    };
  },
  watch: {
    selected(val) {
      if (val) {
        this.$emit('selection', val);
      }
    },
    hasNumbersViewSelected(bool) {
      this.$emit('format-selection', bool);
    }
  },
  created() {
    // initial selection
    this.$emit('selection', this.selected);
  }
};
</script>

<style lang="scss" scoped>
.field {
  margin-bottom: 0;
}
</style>
