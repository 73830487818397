<template>
  <div class="integrations">
    <div class="main-view-header">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h4 class="title is-4">Integrations</h4>
          </div>
        </div>

        <div class="level-right">
          <div class="level-item">
            <b-field>
              <p class="control">
                <b-dropdown>
                  <button
                    slot="trigger"
                    class="button"
                  >
                    <span>{{ filterBy ? filterBy.name : 'Filters' }}</span>
                    <span class="icon"><i class="fa fa-caret-down"></i></span>
                  </button>

                  <b-dropdown-item
                    v-for="(option, optIndex) in filterOptions"
                    :key="optIndex"
                    @click="filterBy = option"
                  >{{ option.name }}</b-dropdown-item>
                </b-dropdown>
              </p>

              <b-input
                v-model="filterQuery"
                type="search"
                placeholder="Search..."
              ></b-input>
            </b-field>
          </div>

          <div class="level-item">
            <button
              class="button is-info"
              :disabled="!enrichmentServiceToken || !userCanEdit"
              @click.stop="handleOpenModal(null, false)"
            >
              <span class="icon">
                <i class="fa fa-plus"></i>
              </span>
              <span>Create Integration</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="main-view-sub-header">
    </div>

    <div class="main-view-content">
      <article
        v-if="!enrichmentServiceToken"
        class="message is-warning"
      >
        <div class="message-body">
          This feature is in development. Any related news can be found in the cohesion-support Slack channel.
          <br>
          No Enrichment Token was found for this site.
        </div>
      </article>
      <article
        v-if="enrichmentServiceToken && filteredIntegrations.length === 0"
        class="message is-warning"
      >
        <div class="message-body">
          No integrations were found for this site.
        </div>
      </article>
      <div v-if="enrichmentServiceToken" class="integrations-wrapper">
        <IntegrationCard
          v-for="integration in filteredIntegrations"
          :key="integration.integrationId"
          :integration="integration"
          @openIntegrationModal="handleOpenModal(integration, true)"
        >
        </IntegrationCard>
      </div>
    </div><!-- /.main-view-content -->

    <footer class="main-view-footer">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <button
              class="button"
              :disabled="currentPage === 1"
              @click.stop="getPage(-1)"
            >Previous</button>
          </div>
          <div class="level-item">
            <button
              class="button"
              :disabled="(pageSize * currentPage) >= filteredIntegrations.length"
              @click.stop="getPage(1)"
            >
              Next
            </button>
          </div>
        </div>

        <div class="level-right">
          <div
            v-if="filteredIntegrations.length"
            class="level-item"
          >
            <strong>{{ integrationRangeText }} of {{ filteredIntegrations.length }} Integrations</strong>
          </div>
        </div>
      </div>
    </footer><!-- /.main-view-footer -->
    <IntegrationModal
      v-if="isModalActive"
      v-model="isModalActive"
      :userCanEdit="userCanEdit"
      :isEditing="isEditing"
      :existingIntegration="selectedIntegration"
      :liveIntegrationIds="liveIntegrationIds"
      @createIntegration="handleCreateIntegration"
      @editIntegration="handleEditIntegration"
    />
  </div>
</template>

<script>
import { hasLength, hasProp } from '@/modules/utilities';
import { bus } from '@/main';
import { sync, get } from 'vuex-pathify';

import IntegrationModal from '@/components/Integrations/IntegrationModal.vue';
import IntegrationCard from '@/components/Integrations/IntegrationCard.vue';

export default {
  name: 'Integrations',
  components: {
    IntegrationModal,
    IntegrationCard
  },
  data() {
    return {
      filterBy: { name: 'Integration Name', val: 'name' },
      filterOptions: [
        { name: 'Integration Name', val: 'name' },
        { name: 'Integration Type', val: 'integrationType' },
        { name: 'Integration ID', val: 'integrationId' }
      ],
      filterQuery: '',
      currentPage: 1,
      pageSize: 20,
      isEditing: false,
      isModalActive: false,
      selectedIntegration: null
    };
  },
  computed: {
    enrichmentServiceToken: get('integrations/enrichmentServiceToken'),
    integrations: sync('integrations/list'),
    liveIntegrationIds: sync('integrations/liveIntegrations'),
    ...sync([
      'integrations/isLoading'
    ]),
    userCanEdit() {
      return this.$store.getters.userCanEdit;
    },
    filteredIntegrations() {
      let result = this.integrations ? this.integrations : [];
      if (hasLength(result)) {
        result = this.filterIntegrations(result);
      }
      return result;
    },
    integrationRangeText() {
      return `${(this.pageSize * this.currentPage) - (this.pageSize - 1)} / `
      + `${this.pageSize * this.currentPage > this.filteredIntegrations.length ? this.filteredIntegrations.length : this.pageSize * this.currentPage}`;
    },
    siteId() {
      return this.$store.state.siteId;
    }
  },
  watch: {
    siteId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getDependencies();
        } else {
          this.integrations = [];
        }
      }
    }
  },
  created() {
    this.registerEventListeners();
  },
  beforeDestroy() {
    this.deregisterEventListeners();
  },
  methods: {
    handleCreateIntegration() {
      this.$root.establishSiteLock().then(() => {
        this.isEditing = false;
      });
    },
    handleEditIntegration() {
      this.$root.establishSiteLock().then(() => {
        this.isEditing = true;
      });
    },
    handleOpenModal(integration, isEditing) {
      this.$root.establishSiteLock().then(() => {
        this.selectedIntegration = integration;
        this.isEditing = isEditing;
        this.isModalActive = true;
      });
    },
    getPage(int) {
      this.currentPage += int;
    },
    /**
     * Emit element click event
     * @param {Object} e - element click event
     */
    elementClick(e) {
      bus.$emit('elem-click', e);
    },
    async getAllIntegrations() {
      await this.$store.dispatch('integrations/getList', []);
    },
    filterIntegrations(integrations) {
      let ints = integrations;

      // Filter by query
      if (!this.filterBy || !hasLength(this.filterQuery)) {
        return ints;
      }

      return ints.filter((exp) => {
        return (
          hasProp(exp, this.filterBy.val)
          && exp[this.filterBy.val].toLowerCase().indexOf(this.filterQuery.toLowerCase()) > -1
        );
      });
    },
    async getLiveIntegrations() {
      await this.$store.dispatch('integrations/getLiveIntegrations', []);
    },
    getDependencies() {
      this.getAllIntegrations();
      this.getLiveIntegrations();
    },
    registerEventListeners() {
      bus.$on('site-changed', this.getDependencies);
    },
    deregisterEventListeners() {
      bus.$off('site-changed', this.getDependencies);
    }
  }
};
</script>

<style lang="scss" scoped>
.integrations-wrapper {
  display: flex;
  flex-wrap: wrap;
}
</style>
