<template>
  <div class="integration-card">
    <div class="integration-card__header">
      <img
        class="integration-card__logo"
        :src="getImgUrl()"
      />
      <h2 class="card-heading">
        {{ integrationDisplayName }}
      </h2>
    </div>
    <div class="integration-card__body">
      <span>{{ integration.name }}</span>
    </div>
    <div class="integration-card__buttons">
      <b-button
        label="View"
        type="is-info"
        @click.stop="handleClickView"
      >
        View
      </b-button>
      <b-tag v-if="externalId.type">
        {{ externalId.display }}
      </b-tag>
    </div>
  </div>
</template>

<script>
import { getIntegrationInstance } from '@/modules/integrations';

export default {
  name: 'IntegrationCard',
  props: {
    integration: {
      type: Object,
      required: true
    }
  },
  computed: {
    integrationInstance() {
      return getIntegrationInstance(this.integration.integrationType, this.integration);
    },
    integrationDisplayName() {
      return this.integrationInstance.displayName;
    },
    externalId() {
      return {
        type: this.integrationInstance.externalIdType,
        display: this.integrationInstance.externalIdDisplay
      };
    },
    integrationImage() {
      return this.integrationInstance.imageName;
    }
  },
  methods: {
    handleClickView() {
      this.$emit('openIntegrationModal', this.integration);
    },
    getImgUrl() {
      return require.context('../../assets', false, /\.(png|jpg|jpeg|gif|svg)$/)(`./${this.integrationImage}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.integration-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  width: 276px;
  min-height: 188px;
  background-color: white;
  border-radius: 5px;
  margin-right: 16px;
  margin-bottom: 16px;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }

  &__header {
    display: flex;
    align-items: center;

    h2 {
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      text-transform: capitalize;
    }
  }

  &__logo {
      align-items: flex-start;
      height: 36px;
      max-width: 40px;
      margin-right: 16px;
  }

  &__body {
    font-size: 16px;
    color: #5C6F84;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
