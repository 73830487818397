<template>
  <td>
    <table
      v-for="(audienceId) in changedAudiences"
      :key="`key-${audienceId}`"
      class="table is-fullwidth"
    >
      <tbody>
        <tr
          v-for="(value, prop, i) in nextAudience[audienceId]"
          v-if="nextAudience[audienceId] !== undefined && !prop.match(/(audienceId)/)"
          :key="`next-audience-${i}`"
        >
          <th style="width: 200px">{{ prop }}</th>

          <!-- Diff Objects/Arrays -->
          <DiffObject
            v-if="typeof value === 'object'"
            :prev="(validPrevAudience(audienceId)) ? prevAudience[audienceId][prop] : {}"
            :next="value"
          />

          <!-- Diff Props -->
          <td v-else-if="validPrevAudienceProp(audienceId, prop) && prevAudience[audienceId][prop] !== value">
            <span class="removed">{{ prevAudience[audienceId][prop] }}</span>
            <span class="added">{{ value }}</span>
          </td>

          <!-- Only New Props -->
          <td v-else-if="!validPrevAudienceProp(audienceId, prop)">
            <span class="added">{{ value }}</span>
          </td>

          <!-- Unchanged -->
          <td v-else>{{ value }}</td>
        </tr>

        <!-- Handle Audiences that have been removed -->
        <tr
          v-for="(value, prop, i) in prevAudience[audienceId]"
          v-if="nextAudience[audienceId] === undefined && !prop.match(/(audienceId)/)"
          :key="`prev-audience-${i}`"
        >
          <th style="width: 200px">{{ prop }}</th>

          <!-- Diff Objects/Arrays -->
          <DiffObject
            v-if="typeof value === 'object'"
            :prev="value"
            :next="(validNextAudience(audienceId)) ? nextAudience[audienceId][prop] : {}"
          />

          <!-- Diff Props -->
          <td v-else-if="validNextAudienceProp(audienceId, prop) && nextAudience[audienceId][prop] !== value">
            <span class="added">{{ nextAudience[audienceId][prop] }}</span>
            <span class="removed">{{ value }}</span>
          </td>

          <!-- Only Removed Props -->
          <td v-else-if="!validNextAudienceProp(audienceId, prop)">
            <span class="removed">{{ value }}</span>
          </td>

          <!-- Unchanged -->
          <td v-else>{{ value }}</td>
        </tr>

        <tr
          v-for="(value, prop, i) in prevAudience[audienceId]"
          v-if="nextAudience[audienceId] === undefined"
          :key="i"
        >
        </tr>
      </tbody>
    </table><!-- /.table -->

    <table
      v-if="reorderedAudiences.length > 0"
      class="table is-fullwidth"
    >
      <thead>
        <tr>
          <th
            class="has-text-centered"
            colspan="2"
          >Audiences Reordered</th>
        </tr>
        <tr>
          <td class="has-text-centered"><strong>Old</strong></td>
          <td class="has-text-centered"><strong>New</strong></td>
        </tr>
      </thead>
      <tbody>
        <!-- Previous Audiences -->
        <td>
          <ul>
            <li
              v-for="(audience, idx) in prev"
              :key="idx"
              class="reorder-item"
              :class="{ 'removed': reorderedAudiences.indexOf(audience.audienceId) > -1 }"
            >
              {{ audience.name }}
            </li>
          </ul>
        </td>

        <!-- New Audiences -->
        <td>
          <ul>
            <li
              v-for="(audience, idx) in next"
              :key="idx"
              class="reorder-item"
              :class="{ 'added': reorderedAudiences.indexOf(audience.audienceId) > -1 }"
            >
              {{ audience.name }}
            </li>
          </ul>
        </td>
      </tbody>
    </table>
  </td>
</template>

<script>
import DiffObject from '@/components/History/DiffObject';

export default {
  name: 'TrafficFlowDiff',
  components: {
    DiffObject
  },
  props: ['prev', 'next'],
  data() {
    return {
      prevAudience: {},
      nextAudience: {},
      allAudiences: [],
      changedAudiences: [],
      reorderedAudiences: []
    };
  },
  mounted() {
    this.setHashTable();
  },
  methods: {
    /**
     * Check whether the audience exists
     * @param {String} id - audience id
     * @return {Bool}
     */
    validPrevAudience(id) {
      return this.prevAudience[id] !== undefined;
    },
    validNextAudience(id) {
      return this.nextAudience[id] !== undefined;
    },
    /**
     * Check whether the audience has a valid prop
     * @param {String} id - audience id
     * @param {String} prop - audience property
     * @return {Bool}
     */
    validPrevAudienceProp(id, prop) {
      if (this.validPrevAudience(id)) {
        return this.prevAudience[id][prop] !== undefined;
      }
      return false;
    },
    validNextAudienceProp(id, prop) {
      if (this.validNextAudience(id)) {
        return this.nextAudience[id][prop] !== undefined;
      }
      return false;
    },
    // Generate a hash table for the audiences and removed unchanged audiences
    setHashTable() {
      const prevTmp = {};
      const nextTmp = {};

      this.prev.forEach((audience) => {
        prevTmp[audience.audienceId] = audience;

        if (this.allAudiences.indexOf(audience.audienceId) === -1) {
          this.allAudiences.push(audience.audienceId);
        }
      });

      this.next.forEach((audience) => {
        nextTmp[audience.audienceId] = audience;

        // championExperienceId will be undefined if a user removed a champion, set it as empty string for comparison
        if (!nextTmp[audience.audienceId].championExperienceId) {
          if (prevTmp[audience.audienceId] && prevTmp[audience.audienceId].championExperienceId) {
            nextTmp[audience.audienceId].championExperienceId = ' ';
          }
        }

        if (this.allAudiences.indexOf(audience.audienceId) === -1) {
          this.allAudiences.push(audience.audienceId);
        }
      });

      for (const id in nextTmp) {
        if (Object.hasOwnProperty.call(prevTmp, id)) {
          const nextStr = JSON.stringify(nextTmp[id]);
          const prevStr = JSON.stringify(prevTmp[id]);
          if (nextStr === prevStr) {
            delete prevTmp[id];
            delete nextTmp[id];
            delete this.allAudiences[id];
          }
        }
      }

      for (let i = 0; i < this.allAudiences.length; i++) {
        if (prevTmp[this.allAudiences[i]] !== undefined || nextTmp[this.allAudiences[i]] !== undefined) {
          this.changedAudiences.push(this.allAudiences[i]);
        }
      }

      this.prevAudience = prevTmp;
      this.nextAudience = nextTmp;

      // check to see if audience was reordered
      this.checkAudienceReorder();
    },
    checkAudienceReorder() {
      const prevIds = this.prev.map(a => a.audienceId);
      const nextIds = this.next.map(a => a.audienceId);
      for (let i = 0; i < prevIds.length; i++) {
        const oldId = prevIds[i];
        // if reordered, indexOf will not equal i
        if (i !== nextIds.indexOf(oldId)) {
          this.reorderedAudiences.push(oldId);
        }
      }
    }
  }
};
</script>

<style lang="scss">
.reorder-item {
  margin: 2px 0;
  padding: 3px 5px;
}
</style>
