<template>
  <section class="card is-section-block">
    <div class="card-header">
      <h5 class="card-header-title" data-cy-test="test-details-title">Test Details</h5>
    </div>

    <div class="card-content p0">
      <div class="columns">
        <div class="column is-one-third test-details">
          <b-field class="test-detail" label="Name">
            <span data-cy-test="name">{{ test.name }}</span>
          </b-field>
          <b-field class="test-detail" label="Test ID">
            <span data-cy-test="id">{{ test.testId }}</span>
          </b-field>
          <b-field class="test-detail" label="Type">
            <span data-cy-test="type">{{ testTypeText }}</span>
          </b-field>
          <b-field class="test-detail" label="Created On">
            <span data-cy-test="created-at">{{ formatTime(test.createdAt) }}</span>
          </b-field>
          <b-field v-if="test.strategy.type === 'clickOptimization'" class="test-detail" label="Agent ID">
            <span data-cy-test="co-agent-id">{{ test.strategy?.clickOptimization?.agentId || 'N/A' }}</span>
          </b-field>
          <b-field class="test-detail" label="Persistent">
            <span data-cy-test="persistent">{{ test.isPersisted === 1 ? 'True' : 'False' }}</span>
          </b-field>
          <b-field class="test-detail" label="Description">
            <b-input
              v-if="test.description"
              readonly
              :value="test.description"
              type="textarea"
              data-cy-test="description"
            ></b-input>
            <em v-else>&hyphen;</em>
          </b-field>
          <b-field label="Tags" class="test-detail" data-cy-test="tags">
            <b-taginput
              v-if="test.tags && test.tags.length"
              v-model="test.tags"
              disabled
            ></b-taginput>
            <em v-else>&hyphen;</em>
          </b-field>
          <b-field v-if="hasMinTrafficPct" label="Minimum Traffic Percentage" class="test-detail">
            <span data-cy-test="co-min-traffic-pct"> {{ test.strategy.clickOptimization.minTrafficPct + '%' }}</span>
          </b-field>
          <template v-if="test.ended === 1">
            <b-field class="test-detail" label="Ended">
              <p data-cy-test="ended">True</p>
            </b-field>
            <b-field class="test-detail" label="Ended On">
              <p data-cy-test="ended-at">{{ formatTime(test.endedAt) }}</p>
            </b-field>
            <b-field
              v-if="test.notes"
              class="test-detail"
              label="Test Notes"
            >
              <p data-cy-test="notes">{{ test.notes }}</p>
            </b-field>
            <b-field
              v-if="test.testOutcome != null"
              class="test-detail"
              label="Test Outcome"
            >
              <span>{{ test.testOutcome }}</span>
            </b-field>
          </template>
        </div>

        <!-- Click Optimization Chart -->
        <div class="column is-two-thirds">
          <RewardsOverTimeByOption
            :testId="test.testId"
            :siteId="siteId"
          />
        </div><!-- /.column -->
      </div><!-- /.columns -->
    </div><!-- /.card-content -->
  </section>
</template>

<script>
import RewardsOverTimeByOption from '../Insights/RewardsOverTimeByOption.vue';
import { formatTime } from '@/modules/utilities';

export default {
  name: 'TestDetails',
  components: {
    RewardsOverTimeByOption
},
props: {
    siteId: {
      type: String,
      required: true
    },
    test: {
      type: Object,
      required: true
    },
    testTypeText: {
      type: String,
      required: true
    }
  },
  computed: {
    hasMinTrafficPct() {
      const { clickOptimization } = this.test.strategy;
      return clickOptimization?.minTrafficPct != null && clickOptimization.minTrafficPct !== 0;
    }
  },
  methods: {
    formatTime
  }
};
</script>
