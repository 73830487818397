<template>
  <div class="site-form__enrichment-client-block">
    <b-field label="Enrichment Service Credentials"></b-field>

    <b-field
      v-if="hasEnrichmentClientId === true"
      label="Client ID"
      message="Your clientId is your public identifier within the Enrichment Service. It can be used from a browser to make enrich requests."
      label-position="on-border"
      custom-class="has-text-grey"
    >
      <b-input
        type="text"
        readonly
        :value="enrichmentServiceClientId"
      ></b-input>
    </b-field>

    <b-field
      v-if="hasEnrichmentToken === true"
      label="Client Token"
      message="Authorization token for Cohesion's enrichment service. A token is required in order to use Segment within Audience rules. While tokens can be regenerated, they should not be publicly exposed."
      label-position="on-border"
      custom-class="has-text-grey"
    >
      <b-input
        type="password"
        :value="enrichmentServiceToken"
        password-reveal
        readonly
      ></b-input>
    </b-field>

    <b-message v-if="hasEnrichmentToken === false" type="is-info">
      <p class="help">Upon creating an Enrichment client, a clientId and client token will be attached to your site settings. This provides the ability to create custom integrations in the Enrichment Service which can then be used in Audience rules. Please reach out to our team for any further assistance.</p>
      <br>
      <b-field label="Client Name" label-position="on-border" custom-class="has-text-grey">
        <b-input
          v-model.trim="clientName"
          size="is-small"
          type="text"
          expanded
        ></b-input>
        <p class="control">
          <b-button
            type="is-success"
            size="is-small"
            label="Create Client"
            :disabled="!hasValidClientName"
            @click="createEnrichmentClient"
          ></b-button>
        </p>
      </b-field>
    </b-message>
  </div>
</template>

<script>
export default {
  name: 'EnrichmentClient',
  props: {
    siteId: {
      type: String,
      required: true
    },
    siteName: {
      type: String,
      required: true
    },
    enrichmentServiceToken: {
      type: String,
      default() {
        return '';
      }
    },
    enrichmentServiceClientId: {
      type: String,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
      clientName: '',
      isOpened: false,
      isLoading: false
    };
  },
  computed: {
    hasEnrichmentToken() {
      return this.enrichmentServiceToken != null && this.enrichmentServiceToken.length !== 0;
    },
    // original enrichment adopters may not have their clientId attached
    hasEnrichmentClientId() {
      return (
        this.hasEnrichmentToken === true
        && this.enrichmentServiceClientId.length !== 0
      );
    },
    hasValidClientName() {
      return this.clientName.length !== 0;
    }
  },
  created() {
    if (this.hasEnrichmentToken === false) {
      this.clientName = this.siteName;
    }
  },
  methods: {
    async createEnrichmentClient() {
      this.isLoading = true;

      try {
        // this creates the client and updates the site with the credentials
        await this.$axios.post(`/sites/${this.siteId}/enrichment/clients`, {
          name: this.clientName
        });

        this.$emit('client-created');
      } catch (err) {
        this.$store.commit('error', err, 'Enrichment Service Error');
      }

      this.isLoading = false;
    }
  }
};
</script>
