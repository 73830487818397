<template>
  <nav
    class="navbar is-info"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-menu">
      <div class="navbar-start">
        <div
          class="navbar-item left1rem"
          data-cy-test="select-account"
        >
          <div class="select">
            <select v-model="selectedAccount">
              <option
                :value="null"
                disabled
              >Select Account</option>
              <option
                v-for="(account, index) in accounts"
                :key="index"
                :value="account.accountId"
              >
                {{ account.name }}
              </option>
            </select>
          </div>
        </div>

        <div
          class="navbar-item"
          data-cy-test="select-site"
        >
          <div class="select">
            <select
              v-model="selectedSite"
              :disabled="!selectedAccount"
            >
              <option
                :value="null"
                disabled
              >Select Site</option>
              <option
                v-for="(site, index) in sites"
                :key="index"
                :value="site.siteId"
              >
                {{ site.name }}
              </option>
            </select>
          </div>
        </div>

        <div
          v-if="canEdit"
          class="navbar-item"
          @click.stop="elementClick"
        >
          <button
            v-if="!siteLock.owner"
            id="nb-item-edit-site"
            class="button is-light is-outlined"
            data-cy-test="edit-site-button"
            @click="$root.establishSiteLock"
          >
            <span class="icon">
              <i class="fa fa-unlock-alt" data-cy-test="unlock-icon"></i>
            </span>
            <span>Edit Site</span>
          </button>

          <button
            v-else-if="siteLock.owner === user.email"
            class="button is-success"
            data-cy-test="done-editing-button"
            @click.stop="releaseSiteLock"
          >
            <span class="icon">
              <i class="fa fa-lock" data-cy-test="lock-icon"></i>
            </span>
            <span>Done Editing</span>
          </button>

          <button
            v-else-if="siteLock.owner !== user.email"
            class="button is-danger"
          >
            <span class="icon">
              <i class="fa fa-lock"></i>
            </span>
            <span class="">Locked by {{ siteLock.owner }}</span>
          </button>
        </div>

        <b-dropdown
          v-if="canDownloadCdk"
          class="navbar-item"
          :triggers="['hover', 'focus']"
          aria-role="list"
        >
          <template #trigger>
            <button class="button is-light is-outlined" data-cy-test="download-cdk-button">
              <span class="icon">
                <i class="fa fa-download"></i>
              </span>
              <span>Download CDK</span>
            </button>
          </template>
          <b-dropdown-item
            aria-role="listitem"
            @click="downloadCdk('darwin')"
          >
            <button class="invisible-button" data-cy-test="mac-os" @click.stop="downloadCdk('darwin')">
              <span class="icon">
                <i class="fa fa-apple"></i>
              </span>
              <span>MacOS</span>
            </button>
          </b-dropdown-item>
          <b-dropdown-item
            aria-role="listitem"
            @click="downloadCdk('win32')"
          >
            <button class="invisible-button" data-cy-test="windows-os" @click.stop="downloadCdk('win32')">
              <span class="icon">
                <i class="fa fa-windows"></i>
              </span>
              <span>Windows</span>
            </button>
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="navbar-end">
        <b-dropdown
          class="navbar-drop"
          data-cy-test="navbar-drop"
          :mobile-modal="false"
        >
          <a
            slot="trigger"
            class="navbar-item font11"
          >
            <p>
              <strong>SITE ID</strong><br>
              <span>{{ selectedSite }}</span>
            </p>
          </a>

          <b-dropdown-item
            :custom="true"
            class="navbar-drop-instruction-header font11"
          >
            <strong>CLICK AN ID TO COPY</strong>
          </b-dropdown-item>

          <b-dropdown-item :separator="true" />

          <b-dropdown-item
            v-clipboard="() => selectedSite"
            v-clipboard:success="handleClipboardSuccess"
            :custom="true"
            class="u-pointer"
          >
            <p class="font11" data-cy-test="site-id">
              <strong>SITE ID</strong><br />
              <span>{{ selectedSite }}</span>
            </p>
          </b-dropdown-item>

          <b-dropdown-item :separator="true" />

          <b-dropdown-item
            v-clipboard="() => selectedAccount"
            v-clipboard:success="handleClipboardSuccess"
            :custom="true"
            class="u-pointer"
          >
            <p class="font11" data-cy-test="account-id">
              <strong>ACCOUNT ID</strong><br />
              <span>{{ selectedAccount }}</span>
            </p>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="tenantId"
            :separator="true"
          />

          <b-dropdown-item
            v-if="tenantId"
            v-clipboard="() => tenantId"
            v-clipboard:success="handleClipboardSuccess"
            :custom="true"
            class="u-pointer"
          >
            <p class="font11">
              <strong>TENANT ID</strong><br />
              <span>{{ tenantId }}</span>
            </p>
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown
          class="navbar-drop"
          position="is-bottom-left"
          :mobile-modal="false"
        >
          <a
            slot="trigger"
            class="navbar-item right1rem"
          >
            <div class="avatar">
              <span class="initials">{{ userInitials }}</span>
            </div>
            <span class="icon">
              <i class="fa fa-caret-down"></i>
            </span>
          </a>

          <b-dropdown-item :custom="true">
            <p>
              <strong>{{ user.firstName }} {{ user.lastName }}</strong><br />
              {{ user.email }}
            </p>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="user.isSuper"
            :separator="true"
          />
          <b-dropdown-item
            v-if="user.isSuper"
            :custom="true"
          >
            <p>
              <span>
                <strong v-if="user.isSuper">Super</strong>
              </span>
              <span class="icon has-text-warning">
                <i class="fa fa-star"></i>
              </span>
            </p>
          </b-dropdown-item>
        </b-dropdown>

        <!-- extra navbar item for dropdown spacing -->
        <div class="navbar-item"></div>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from 'axios';
import { hasLength } from '@/modules/utilities';
import storage from '@/modules/storage';
import { bus } from '@/main';
import { JWT_KEY } from '@/config';

const isLocalEnv = window.location.hostname.startsWith('localhost');
const baseUrl = (isLocalEnv) ? 'http://localhost:4001/' : '/';

export default {
  name: 'AppTopNav',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedAccount: null,
      selectedSite: null,
      userMenuActive: false
    };
  },
  computed: {
    isSuper() {
      return this.$store.state.user.isSuper;
    },
    accounts() {
      return this.user.accounts;
    },
    sites() {
      return this.$store.state.activeAccountSites;
    },
    // Get what the users role is for the current account
    userRoleForAccount() {
      // if (this.isSuper) return 'admin';
      return this.$store.getters.userRoleForAccount;
    },
    // Get the current site lock
    siteLock() {
      return this.$store.state.siteLock;
    },
    // Check if the user has a role that can edit
    canEdit() {
      if (this.selectedSite === null) return false;
      return !this.userRoleForAccount.match(/guest|developer/i);
    },
    canDownloadCdk() {
      return this.$store.getters.canDownloadCdk;
    },
    tenantId() {
      return this.$store.getters.siteTenantId;
    },
    userInitials() {
      return this.user.firstName.substring(0, 1) + this.user.lastName.substring(0, 1);
    }
  },
  watch: {
    selectedAccount(accountId) {
      if (this.$store.getters.siteLockOwner) {
        this.$root.releaseSiteLock(
          this.$store.state.siteId,
          'userAction',
          this.$store.getters.siteDomain
        ).then(() => {
          this.handleAccountChange(accountId);
        });
      } else {
        this.handleAccountChange(accountId);
      }
    },
    selectedSite(siteId) {
      if (this.$store.getters.siteLockOwner) {
        this.$root.releaseSiteLock(
          this.$store.state.siteId,
          'userAction',
          this.$store.getters.siteDomain
        ).then(() => {
          this.handleSiteChange(siteId);
        });
      } else {
        this.handleSiteChange(siteId);
      }
    }
  },
  created() {
    // if account is stored, set it. otherwise, wait until selection
    if (storage.exists('selectedAccount')) {
      const storedAccountId = storage.get('selectedAccount');
      if (hasLength(this.accounts)) {
        const accountMatch = this.accounts.find(a => a.accountId === storedAccountId);

        if (accountMatch) {
          this.selectedAccount = storedAccountId;
        } else {
          storage.remove('selectedAccount');
        }
      }
    }
  },
  methods: {
    handleAccountChange(accountId) {
      this.selectedSite = null;
      this.$store.commit('setActiveAccount', accountId);
      storage.set('selectedAccount', accountId);
      storage.remove('selectedSite');
      this.getAccountSites(accountId);
      if (accountId) bus.$emit('account-changed', accountId);
    },
    handleSiteChange(siteId) {
      this.$store.commit('setSiteId', siteId);
      storage.set('selectedSite', siteId);
      if (siteId) bus.$emit('site-changed', siteId);
    },
    async getAccountSites(accountId) {
      try {
        // Get list of sites from api
        const res = await this.$axios.get(`/accounts/${accountId}/sites`);
        // Set in store for other components
        this.$store.commit('setAccountSites', res.data);
        // Check if stored site is part of site list
        this.checkStoredSite(res.data);
      } catch (e) {
        e.title = 'There was a problem getting site data for this account';
        this.$store.commit('error', e);
      }
    },
    checkStoredSite(accountSites) {
      // check local storage for a site id
      if (storage.exists('selectedSite')) {
        // site id is found, check valid account site array for stored id
        const storedSite = storage.get('selectedSite');
        if (accountSites.find(obj => obj.siteId === storedSite)) {
          // site is valid, save it locally and to store
          this.selectedSite = storedSite;
          this.$store.commit('setSiteId', storedSite);
        }
      }
    },
    /**
     * Emit element click event
     * @param {Object} e - element click event
     */
    elementClick(e) {
      bus.$emit('elem-click', e);
    },
    releaseSiteLock() {
      this.$root.releaseSiteLock();
    },
    async downloadCdk(clientOS) {
      try {
        const res = await axios.get(`${baseUrl}cdk/download/${clientOS}/latest`, {
          timeout: 120000,
          headers: {
            Authorization: `Bearer ${localStorage.getItem(JWT_KEY)}`
          }
        });
        const { url } = res.data;
        this.$buefy.toast.open({
          message: `Downloading CDK`,
          type: 'is-success'
        });
        window.open(url);
      } catch (err) {
        this.$store.commit('error', err, 'Could not get CDK download URL');
      }
    },
    handleClipboardSuccess() {
      this.$buefy.toast.open({
        message: 'copied to clipboard',
        type: 'is-success'
      });
    }
  }
};
</script>

<style lang="scss">
  .navbar {
    position: relative;
    z-index: 10;

    &-drop {
      &-instruction-header {
        outline: none;
        padding-bottom: 0;
        padding-top: 0;
        text-align: center;
      }
    }
  }
</style>
