<template>
  <div>
    <h2 class="integration-modal__title">
      {{ integrationDisplayName }} Integration Details
    </h2>
    <component
      :is="integrationComponentName"
      :integration="integration"
      :userCanEdit="userCanEdit"
      :isEditing="isEditing"
    />
    <b-field class="integration-modal__url-match" label="URL Match Patterns">
      <b-taginput
        v-model="integration.options.urlMatch"
        placeholder="Add URL Match Patterns"
        ellipsis
      >
      </b-taginput>
    </b-field>
    <div class="integration-modal__buttons level-right">
      <button
        class="button cancel is-ghost"
        @click.stop="handleClick('clickCancel')"
      >
        Cancel
      </button>
      <button
        v-if="!isEditing"
        class="button cancel"
        @click.stop="handleClick('clickBack')"
      >
        Back
      </button>
      <button
        v-if="isEditing && !disableDelete"
        class="button cancel"
        :disabled="!userCanEdit"
        @click.stop="handleClick('clickDelete')"
      >
        <b-icon
          icon="trash"
          class="delete-icon"
        />
        Delete
      </button>
      <button
        class="button is-info"
        @click.stop="handleClick('clickSubmit', integration)"
      >
        {{ submitButtonText }}
      </button>
    </div>
  </div>
</template>

<script>
import { getIntegrationInstance } from '../../modules/integrations';
import SegmentIntegrationForm from '../../components/Integrations/SegmentIntegrationForm.vue';

export default {
  name: 'IntegrationModalStep1',
  components: {
    SegmentIntegrationForm
  },
  props: {
    isEditing: {
      type: Boolean,
      required: true
    },
    integration: {
      type: Object,
      required: false
    },
     userCanEdit: {
      type: Boolean,
      required: true
    },
    disableDelete: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    integrationInstance() {
      return getIntegrationInstance(this.integration.integrationType);
    },
    submitButtonText() {
      return this.isEditing ? 'Save Changes' : 'Create Integration';
    },
    integrationComponentName() {
      return this.integrationInstance.componentName;
    },
    integrationDisplayName() {
      return this.integrationInstance.displayName;
    },
    urlMatch: {
      get() {
        return this.integration.options.urlMatch;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    handleClick(eventName, params = null) {
      this.$emit(eventName, params);
    }
  }
};
</script>

<style lang="scss" scoped>
.integration-modal {
  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 12px;
  }
  &__description {
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 20px;
  }
  &__buttons {
    margin-top: 24px;
  }
  &__url-match {
    margin-top: 12px;
  }
}

.cancel {
  margin-right: 12px;
}

.is-ghost {
  background: transparent;
  border: none;
}

.button .icon.delete-icon{
  margin-right: 0px;
}
</style>
