<template>
  <b-modal
    v-model="isIntegrationModalActive"
    width="600px"
  >
    <div class="card">
      <h2 class="integration-modal__title">
        Configure {{ integrationDisplayName }} Integration
      </h2>
      <b-field>
        <b-select
          :value="selectedIntegrationId"
          expanded
          @input="handleSelectIntegration"
        >
          <option
            disabled
            :value="null"
          >Select an integration</option>
          <option
            v-for="configuredIntegration in filteredIntegrations"
            :key="configuredIntegration.integrationId"
            :value="configuredIntegration.integrationId"
          >{{ configuredIntegration.name }}</option>
        </b-select>
      </b-field>
      <div class="level-right">
        <button
          class="button cancel is-ghost"
          @click.stop="handleCancel"
        >
          Cancel
        </button>
        <button
          class="button is-info"
          @click.stop="handleSave"
        >
          <b-icon
            icon="save"
            class="button-icon"
          />
          Save
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { getIntegrationInstance } from '@/modules/integrations';
import { bus } from '@/main';

export default {
  name: 'IntegrationSelectModal',
  components: {
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    ruleIndex: {
      type: Number,
      required: true
    },
    integrationId: {
      type: String,
      required: false
    },
    integrationType: {
      type: String,
      required: false
    },
    filteredIntegrations: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedIntegrationId: this.integrationId
    };
  },
  computed: {
    isIntegrationModalActive: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    integrationInstance() {
      if (!this.integrationType) {
        return {};
      }
      return getIntegrationInstance(this.integrationType);
    },
    integrationDisplayName() {
      return this.integrationInstance.displayName;
    }
  },
  methods: {
    handleCancel() {
       this.isIntegrationModalActive = false;
    },
    async handleSave() {
      await this.$root.establishSiteLock();
      const integrationName = this.filteredIntegrations.find(i => i.integrationId === this.selectedIntegrationId).name;

      // send new integration name to RuleForm component
      this.$emit('updateIntegrationName', integrationName);
      // send new integration data to RuleBlock component
      bus.$emit('updateRuleIntegrationId', {ruleIndex: this.ruleIndex, integrationId: this.selectedIntegrationId});

      this.isIntegrationModalActive = false;
    },
    handleSelectIntegration(selected) {
      this.selectedIntegrationId = selected;
    }
  }
};
</script>

<style lang="scss" scoped>
  .card {
    padding: 20px;
  }

  .integration-modal__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 12px;
  }
  .cancel {
    margin-right: 12px;
  }

  .button .icon.button-icon{
    margin-right: 0px;
  }
</style>

