<template>
  <b-modal
    v-model="isDeleteFolderModalActive"
    width="400px"
    data-cy-test="delete-folder-modal"
  >
    <div class="card">
      <h2 class="folder-modal__title" data-cy-test="delete-folder-modal-title">
        Delete "{{ folder.name }}" Folder?
      </h2>
      <p class="folder-modal__text" data-cy-test="delete-folder-modal-text">
        If you continue, this folder will be removed and any assets associated with it will be moved to the Unassigned folder. This action can't be undone.
      </p>
      <div class="level-left">
        <button
          class="button is-info"
          data-cy-test="delete-folder-modal-submit-button"
          @click.stop="handleDeleteFolder"
        >
          Delete Folder
        </button>
        <button
          class="button cancel is-ghost"
          data-cy-test="delete-folder-modal-cancel-button"
          @click.stop="handleCancel"
        >
          Cancel
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'DeleteFolderModal',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    folder: {
      type: Object,
      required: true
    }
  },
  computed: {
    isDeleteFolderModalActive: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    handleCancel() {
       this.isDeleteFolderModalActive = false;
    },
    async handleDeleteFolder() {
      await this.$root.establishSiteLock();

      this.$emit('deleteFolder', this.folder);

      this.handleCancel();
    }
  }
};
</script>
