<template>
  <section class="hero is-dark is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half has-text-centered">
            <img
              src="./assets/preamp-logo-white.svg"
              alt="preamp logo"
              style="height: 80px; width: auto;"
            >
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-half">
            <b-notification
              type="is-warning"
              size="is-medium"
              :closable="false"
              has-icon
            >
              Preamp is currently undergoing maintenance. Please see the #cohesion-support channel for status updates.
            </b-notification>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Maintenance'
};
</script>
