<template>
  <b-modal
    v-model="isMoveSelectedModalActive"
    width="400px"
    data-cy-test="moveselected-modal"
  >
    <div class="card">
      <h2 class="folder-modal__title" data-cy-test="moveselected-modal-title">
        Move Selected Assets
      </h2>
      <p class="folder-modal__text" data-cy-test="moveselected-modal-text">
        Select the folder you want to assign these assets to.
      </p>
      <b-field label="Folder" class="move-selected__folder-select">
        <b-select
          v-model="selectedFolder"
          placeholder="Select a folder"
          expanded
          :disabled="folders.length < 1"
          data-cy-test="moveselected-modal-select"
        >
          <option
            v-for="folder in folderList"
            :key="folder.folderId"
            :value="folder"
            data-cy-test="moveselected-modal-select-option"
          >{{ folder.name }}</option>
        </b-select>
      </b-field>
      <div class="level-left">
        <button
          class="button is-info"
          data-cy-test="moveselected-modal-submit-button"
          @click.stop="handleMoveAssets"
        >
          Move Assets
        </button>
        <button
          class="button cancel is-ghost"
          data-cy-test="moveselected-modal-cancel-button"
          @click.stop="handleCancel"
        >
          Cancel
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'MoveSelectedModal',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    folders: {
      type: Array,
      required: true
    },
    selectedContents: {
      type: Array,
      required: true
    },
    openFolder: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      selectedFolder: null
    };
  },
  computed: {
    isMoveSelectedModalActive: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    folderList() {
      return (this.openFolder) ? this.folders.filter(fol => fol.name !== this.openFolder.name) : this.folders;
    }
  },
  methods: {
    handleCancel() {
      this.isMoveSelectedModalActive = false;
    },
    async handleMoveAssets() {
      await this.$root.establishSiteLock();

      this.$emit('moveSelected', { folder: this.selectedFolder, newContents: this.selectedContents, originalFolder: this.openFolder });

      this.handleCancel();
    }
  }
};
</script>

<style lang="scss" scoped>
  .folder-modal ::v-deep {
    & .card {
      padding: 20px;
    }

    & h2.folder-modal__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      margin-bottom: 12px;
    }

    & p.folder-modal__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }
  .move-selected__folder-select {
    margin-bottom: 24px;
  }
</style>
